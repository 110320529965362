<template>
  <div class="update">
    <div class="update-top">
      <span class="update-text">编辑岗位</span>
      <el-input placeholder="姓名/身份证号" suffix-icon="el-icon-search" class="update-input"></el-input>
    </div>
    <div class="update-search">
      <span class="update-text">岗位名称</span>
      <el-input class="update-input" placeholder="请输入"></el-input>
    </div>
    <div class="update-scroll">
      <div class="update-scroll-text">
        <span>权限设置</span>
      </div>
      <div class="update-checkbox">
        <el-checkbox>工作台</el-checkbox>
      </div>
      <div class="update-tree">
        <el-tree :data="data" show-checkbox node-key="id" default-expand-all :expand-on-click-node="false">
          <span class="custom-tree-node" slot-scope="{ node }">
            <span class="tree-label">{{ node.label }}</span>
          </span>
        </el-tree>
        <el-tree :data="data1" show-checkbox node-key="id" default-expand-all :expand-on-click-node="false">
          <span class="custom-tree-node" slot-scope="{ node }">
            <span class="tree-label">{{ node.label }}</span>
          </span>
        </el-tree>
        <el-tree :data="data2" show-checkbox node-key="id" default-expand-all :expand-on-click-node="false">
          <span class="custom-tree-node" slot-scope="{ node }">
            <span class="tree-label">{{ node.label }}</span>
          </span>
        </el-tree>
        <el-tree :data="data3" show-checkbox node-key="id" default-expand-all :expand-on-click-node="false">
          <span class="custom-tree-node" slot-scope="{ node }">
            <span class="tree-label">{{ node.label }}</span>
          </span>
        </el-tree>
        <el-tree :data="data4" show-checkbox node-key="id" default-expand-all :expand-on-click-node="false">
          <span class="custom-tree-node" slot-scope="{ node }">
            <span class="tree-label">{{ node.label }}</span>
          </span>
        </el-tree>
        <el-tree :data="data5" show-checkbox node-key="id" default-expand-all :expand-on-click-node="false">
          <span class="custom-tree-node" slot-scope="{ node }">
            <span class="tree-label">{{ node.label }}</span>
          </span>
        </el-tree>
      </div>

    </div>
    <div class="update-btns">
      <el-button size="small" @click="close">取消</el-button>
      <el-button size="small" type="primary" @click="close">确定</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      data: [
        {
          id: 1,
          label: '出入院管理',
          show: 'hide',
          children: [{
            id: 11,
            label: '预定登记'
          }, {
            id: 12,
            label: '入住登记'
          }, {
            id: 13,
            label: '老人列表'
          }, {
            id: 14,
            label: '来访登记'
          }]
        }
      ],
      data1: [
        {
          id: 1,
          label: '膳食管理',
          show: 'hide',
          children: [{
            id: 11,
            label: '彩票管理'
          }, {
            id: 12,
            label: '配送管理'
          }, {
            id: 13,
            label: '套餐管理'
          }]
        }
      ],
      data2: [
        {
          id: 1,
          label: '服务管理',
          show: 'hide',
          children: [{
            id: 11,
            label: '服务项目管理'
          }, {
            id: 12,
            label: '服务等级管理'
          }, {
            id: 13,
            label: '排班方案管理'
          }, {
            id: 14,
            label: '护工排班'
          }, {
            id: 15,
            label: '护工服务查询'
          }]
        }
      ],
      data3: [
        {
          id: 1,
          label: '人事管理',
          show: 'hide',
          children: [{
            id: 11,
            label: '部门管理'
          }, {
            id: 12,
            label: '部门管理'
          }, {
            id: 13,
            label: '员工管理'
          }]
        }
      ],
      data4: [
        {
          id: 1,
          label: '基础数据',
          show: 'hide',
          children: [{
            id: 11,
            label: '房间设置'
          }, {
            id: 12,
            label: '房间类型管理'
          }, {
            id: 13,
            label: '床位类型管理'
          }, {
            id: 14,
            label: '服务类型管理'
          }]
        }
      ],
      data5: [
        {
          id: 1,
          label: '账号设置',
          show: 'hide',
          children: []
        }
      ],
      defaultProps: {
        children: 'children',
        label: 'label',
        show: 'show'
      }
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.update {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 88;
  padding: 0 40px;
  background-color: #fff;
  box-sizing: border-box;

  .update-top {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 60px;
    padding: 0 30px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    .update-text {
      color: #000;
      font-size: 22px;
    }

    .update-input {
      width: 246px;
    }
  }

  .update-search {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 60px;

    .update-text {
      color: #000;
      font-size: 22px;
    }

    .update-input {
      width: 180px;
      margin-left: 10px;
    }
  }

  .update-scroll {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 170px);

    &::-webkit-scrollbar {
      width: 0;
    }

    .update-scroll-text {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 40px;
      color: #000;
      font-size: 20px;
    }

    .update-checkbox {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 40px;
    }

    .update-tree {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 600px;
    }

    .el-tree {
      background-color: transparent;
      color: #000;
      font-size: 18px;

      .tree-label {
        display: inline-block;
        width: 140px;
      }
    }
  }

  .update-btns {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 50px;
    padding-left: 30px;
    box-sizing: border-box;
  }
}
</style>
