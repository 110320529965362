<template>
  <div class="write">
    <div class="title">
      <span @click="canvasCircle">围栏设置</span>
    </div>
    <div class="content">
      <div @click="closePopup" class="close">
        <span class="el-icon-close"></span>
      </div>
      <div class="box">
        <div class="table">
          <el-table :data="tableData" height="500" :header-cell-style="{ background: '#024276' }">
            <el-table-column prop="name" label="姓名">
            </el-table-column>
            <el-table-column prop="type" label="年龄">
            </el-table-column>
            <el-table-column prop="type1" label="性别">
            </el-table-column>
            <el-table-column prop="type2" label="所属机构">
            </el-table-column>
          </el-table>
        </div>
        <div class="right">
          <div class="search">
            <div class="tab">
              <span :class="{ span: tabIndex === 0 }" @click="tabClick(0)">圆形围栏</span>
              <span :class="{ span: tabIndex === 1 }" @click="tabClick(1)">多边形围栏</span>
            </div>
            <div class="search-box">
              <div class="item">
                <span>围栏名称</span>
                <el-input size="small" placeholder="请输入"></el-input>
              </div>
              <div class="item">
                <span>报警类型</span>
                <el-select v-model="value" placeholder="请选择" size="small">
                  <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </div>
              <div class="item">
                <span>报警时段</span>
                <el-time-picker is-range v-model="value1" size="small" range-separator="至" start-placeholder="开始时间"
                  end-placeholder="结束时间" placeholder="选择时间范围">
                </el-time-picker>

                <el-button size="mini">保存</el-button>
              </div>
              <div class="item">
                <span>维护围栏记录</span>
                <el-select v-model="value" placeholder="围栏名称" size="small">
                  <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
          <div class="map">
            <div class="slider">
              <div class="top">
                <span>半径设置</span>

              </div>
              <div class="center">
                <span>0米</span>
                <span>300米</span>
              </div>
              <el-slider v-model="radius">
              </el-slider>
            </div>
            <div class="map-box" id="map"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AMapLoader from '@amap/amap-jsapi-loader'
export default {
  data () {
    return {
      options: [{
        value: '1',
        label: '1'
      }],
      value: '',
      radio: '1',
      value1: 0,
      tableData: [
        { name: '1' }
      ],
      tabIndex: 0,
      mouseTool: null,
      radius: 1000
    }
  },
  mounted () {
    // this.canvasMap()
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    },
    closePopup () {
      this.$emit('close')
    },
    canvasMap () {
      AMapLoader.load({
        key: '', // 申请好的Web端开发者Key，首次调用 load 时必填
        version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ['AMap.Scale', 'AMap.Marker', 'AMap.event', 'AMap.InfoWindow', 'AMap.MouseTool', 'AMap.Circle'] // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      }).then((AMap) => {
        const map = new AMap.Map('map', {
          zoom: 11, // 级别
          center: [116.433322, 39.900256]
        })
        // this.mouseTool = new AMap.MouseTool(map)
        var circle = new AMap.Circle({
          center: [116.433322, 39.900255],
          radius: this.radius, // 半径
          borderWeight: 3,
          // strokeColor: '#FF33FF',
          strokeOpacity: 1,
          strokeWeight: 6,
          fillOpacity: 0.4,
          strokeStyle: 'solid',
          strokeDasharray: [10, 10],
          zIndex: 50
        })

        map.add(circle)
        // 缩放地图到合适的视野级别
        map.setFitView([circle])
      }).catch(e => {
        // console.log(e)
      })
    },
    canvasCircle () {
      this.mouseTool.circle({
        strokeColor: '#FF33FF',
        strokeOpacity: 1,
        strokeWeight: 6,
        fillColor: '#1791fc',
        fillOpacity: 0.4,
        strokeStyle: 'solid'
        // 线样式还支持 'dashed'
        // strokeDasharray: [30,10],
      })
    }
  }
}
</script>

<style lang="less" scoped>
.write {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 888;
  width: 100%;
  height: 100%;
  padding: 0 40px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, .9);

  .title {
    width: 267px;
    height: 53px;
    padding-top: 5px;
    box-sizing: border-box;
    line-height: 53px;
    text-align: center;
    background-image: url('../../img/ssjk/title.png');
    background-size: 100% 100%;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
  }

  .content {
    position: relative;
    width: 100%;
    height: 600px;
    margin-top: 10px;
    padding: 42px 70px;
    padding-right: 90px;
    background-image: url('../../img/ssjk/background4.png');
    background-size: 100% 100%;
    box-sizing: border-box;

    .close {
      position: absolute;
      top: 22px;
      right: 48px;
      color: #fff;
      font-size: 24px;
      cursor: pointer;
    }

    .box {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
    }

    .table {
      width: 500px;
    }

    .right {
      width: calc(100% - 510px);
      margin-left: 10px;

      .search {
        border: .025rem solid #124368;
        box-sizing: border-box;
        padding-bottom: 20px;
      }

      .tab {
        display: flex;
        align-items: center;
        height: 30px;

        span {
          padding: 0 5px;
          height: 30px;
          line-height: 30px;
          font-size: 12px;
          color: #017cdb;
          background-color: #fff;
          cursor: pointer;
        }

        .span {
          color: #fff;
          background-color: #017cdb;
        }
      }

      .search-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 4px;
        box-sizing: border-box;

        .item {
          display: flex;
          align-items: center;
          color: #01EEFD;
          margin-top: 10px;

          .el-input, .el-select {
            width: 120px;
            margin-left: 10px;
          }

          .el-button {
            margin-left: 20px;
            color: #fff;
            background-color: #66b1ff;
            border: none;
          }
        }
      }

      .map {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin-top: 10px;

        .slider {
          display: flex;
          flex-direction: column;
          width: 70%;

          .top {
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-size: 14px;
          }

          .center {
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #fff;
            font-size: 14px;
          }
        }

        .map-box {
          width: 100%;
          height: 240px;
          margin-top: 10px;
          background-color: #fff;
        }
      }
    }
  }

  ::v-deep .el-table__body-wrapper {
    &::-webkit-scrollbar {
      width: 0px;
    }
  }

  ::v-deep .el-table th.gutter {
    display: none;
    width: 0;
  }

  ::v-deep .el-table colgroup col[name='gutter'] {
    display: none;
    width: 0;
  }

  ::v-deep .el-table__body {
    width: 100% !important;
  }

  .el-table {
    background-color: #092B50;
    border: 4px #073F73 solid;
  }

  // 表头文字颜色
  /deep/.el-table__header-wrapper {
    thead {
      th {
        div {
          color: #fff;
          font-size: 17px;
          font-weight: 400;
        }
      }
    }
  }

  //表格内容颜色
  /deep/.el-table__body tbody tr {
    color: #fff;
    background-color: #092B50;
  }

  /deep/.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
    background-color: #092B50;
  }

  /deep/.el-table tbody tr:hover>td {
    background-color: transparent !important
  }

  // 改变边框颜色
  .el-table--border,
  .el-table--group {
    border: 2px solid #ddd !important;
  }

  /**
  改变表格内竖线颜色
   */
  .el-table--border td,
  .el-table--border th,
  .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
    border-right: 1px solid #ddd !important;
  }

  /deep/.el-table td {
    border-bottom: 1px solid #01EEFD;
  }

  /deep/.el-table thead tr th {
    border-color: transparent;
  }

  /deep/ .el-table--border::after,
  /deep/ .el-table--group::after,
  /deep/ .el-table::before {
    background-color: #073F73;
  }

  /deep/.el-table--border {
    border: 1px solid #073F73 !important;
  }

  /deep/.el-table .cell {
    text-align: center;
    color: #fff;
  }

  /deep/.el-checkbox__inner {
    background-color: transparent;
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 40px;

    ::v-deep.el-pagination__total,
    ::v-deep.el-pagination__jump {
      color: #01EEFD;

      .el-input__inner {
        color: #fff;
        background-color: transparent;
      }
    }

    ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: #01EEFD;
    }
  }

  .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    color: #0e75d4;
    font-size: 15px;

    span {
      cursor: pointer;
    }
  }
}
</style>
