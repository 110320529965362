<template>
  <div class="popup">
    <div class="popup-content">
      <div class="popup-box">
        <div class="content">
          <div class="close" @click="close">
            <i class="el-icon-close"></i>
          </div>
          <div class="left">
            <div class="scroll" v-if="!isUpdate">
              <div class="list">
                <div class="title">
                  <span>赵马医生排班详情</span>
                </div>
                <div class="date">
                  <span>2021月1月5日</span>
                </div>
                <div class="line">
                  <span>周三</span>
                </div>
                <div class="date">
                  <span>上午</span>
                </div>
                <div class="line">
                  <span>接诊时间：8:00-12:00</span>
                </div>
                <div class="line">
                  <span>号源数：20</span>
                </div>
                <div class="line">
                  <span>接诊人数：0</span>
                </div>
                <div class="date margin">
                  <span>下午</span>
                </div>
                <div class="line">
                  <span>接诊时间：14:00-18:00</span>
                </div>
                <div class="line">
                  <span>号源数：20</span>
                </div>
                <div class="line">
                  <span>接诊人数：0</span>
                </div>
              </div>
            </div>
            <div class="scroll" v-else-if="isUpdate">
              <div class="list">
                <div class="title">
                  <span>赵马医生排班详情</span>
                </div>
                <div class="date">
                  <span>2021月1月5日</span>
                </div>
                <div class="line">
                  <span>周三</span>
                </div>
                <div class="date date1">
                  <span>上午</span>
                  <div class="radio">
                    <span>{{ isUpdate ? '接诊' : '休息' }}</span>
                    <el-switch v-model="upRadio" active-color="#30F05D" inactive-color="#999999">
                    </el-switch>
                  </div>
                </div>
                <div class="line">
                  <el-time-select :picker-options="{
                    start: '00:00',
                    step: '00:30',
                    end: '23:59'
                  }" v-model="upStart" placeholder="选择时间">
                  </el-time-select>
                </div>
                <div class="line">
                  <el-time-select :picker-options="{
                    start: '00:00',
                    step: '00:30',
                    end: '23:59'
                  }" v-model="upEnd" placeholder="选择时间">
                  </el-time-select>
                </div>
                <div class="line">
                  <el-input placeholder=""></el-input>
                </div>
                <div class="date date1 margin">
                  <span>下午</span>
                  <div class="radio">
                    <span>{{ isUpdate ? '接诊' : '休息' }}</span>
                    <el-switch v-model="downRadio" active-color="#30F05D" inactive-color="#999999">
                    </el-switch>
                  </div>
                </div>
                <div class="line">
                  <el-time-select :picker-options="{
                    start: '00:00',
                    step: '00:30',
                    end: '23:59'
                  }" v-model="downStart" placeholder="选择时间">
                  </el-time-select>
                </div>
                <div class="line">
                  <el-time-select :picker-options="{
                    start: '00:00',
                    step: '00:30',
                    end: '23:59'
                  }" v-model="downEnd" placeholder="选择时间">
                  </el-time-select>
                </div>
                <div class="line">
                  <el-input placeholder=""></el-input>
                </div>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="head">
              <el-select v-model="value" placeholder="请选择月份">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
              <div class="btns" v-if="!isUpdate">
                <el-button class="blue" @click="isUpdate = true">设置排班</el-button>
                <el-button @click="openClose">关闭预约</el-button>
              </div>
              <div class="btns" v-else-if="isUpdate">
                <el-button class="blue" @click="isUpdate = false">保存设置</el-button>
              </div>
            </div>
            <div class="table">
              <div class="item" :class="{ active: index < 14 }" v-for="(item, index) in 31" :key="index">
                <span class="span">{{ index + 1 }}</span>
                <span>接诊10</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Close v-if="showClose" @close="closeClose"></Close>
  </div>
</template>

<script>
import Close from './close.vue'
export default {
  components: {
    Close
  },
  data () {
    return {
      options: [{
        value: '',
        label: ''
      }],
      value: '',
      upStart: '',
      upEnd: '',
      downStart: '',
      downEnd: '',
      showClose: false,
      upRadio: '',
      downRadio: '',
      isUpdate: false
    }
  },
  methods: {
    openClose () {
      this.showClose = true
    },
    closeClose () {
      this.showClose = false
    },
    close () {
      this.$emit('close')
    },
    submit () {
      this.$emit('close', this.input)
    }
  }
}
</script>

<style scoped lang="less">
.popup {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 88;
  background-color: rgba(0, 0, 0, .74);

  .popup-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1080px;
    height: 674px;

    .popup-box {
      width: 1080px;
      height: 674px;
      padding: 10px;
      background-color: rgba(3, 27, 44, .8);
      border-radius: 10px;
      border: 4px solid rgba(1, 49, 88, .5);
      box-sizing: border-box;

      .content {
        display: flex;
        flex-direction: row;
        position: relative;
        width: 100%;
        height: 100%;
        padding: 30px;
        border-radius: 10px;
        border: 4px solid rgba(1, 49, 88, .5);
        box-sizing: border-box;

        .close {
          display: flex;
          align-items: flex-end;
          position: absolute;
          top: 0;
          right: 0;
          width: 50px;
          height: 50px;
          background: linear-gradient(26.565deg, #2057a7 50%, transparent 0);
          transform: rotate(180deg);
          color: #fff;
          font-size: 24px;
          cursor: pointer;
        }

        .left {
          width: 260px;
          height: 100%;
          border-right: 1px solid #FFFFFF;
          box-sizing: border-box;

          .scroll {
            width: 208px;
            height: calc(100% - 50px);
            padding: 10px;
            background-color: #04B4D0;
            box-sizing: border-box;

            .list {
              display: flex;
              flex-direction: column;

              .title {
                margin-bottom: 20px;
                color: #fff;
                font-size: 18px;
              }

              .date {
                margin-top: 10px;
                color: #fff;
                font-size: 18px;
                font-weight: 600;
              }

              .date1 {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .radio {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  color: #fff;
                  font-size: 14px;

                  .el-switch {
                    margin-left: 7px;
                  }
                }
              }

              .line {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                margin-top: 10px;
                color: #fff;
                font-size: 16px;
              }

              .margin {
                margin-top: 30px;
              }
            }
          }
        }

        .right {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: calc(100% - 260px);
          height: 100%;
          padding: 0 20px;

          .head {
            width: 665px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            .btns {
              display: flex;
              flex-direction: row;

              .el-button {
                background-color: #555555;
                border: none;
                color: #fff;
              }

              .blue {
                background-color: #04B4D0;
              }
            }
          }

          .table {
            display: flex;
            flex-wrap: wrap;
            width: 665px;
            margin-top: 14px;

            .item {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              width: 95px;
              height: 95px;
              border: 1px solid #E3E3E3;
              background-color: #FAFAFA;
              box-sizing: border-box;
              color: #B3ABAB;
              font-size: 16px;

              .span {
                font-size: 28px;
              }
            }

            .active {
              background-color: #EAFCFF;
              color: #04B4D0 !important;
            }
          }
        }
      }
    }
  }
}
</style>
