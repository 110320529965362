<template>
  <div class="popup">
    <div class="popup-title">
      <div class="title">
        <span>其他设置</span>
      </div>
      <!-- <el-input class="postion" placeholder="姓名/身份证号" suffix-icon="el-icon-search">
      </el-input> -->
    </div>
    <div class="popup-box">
      <div class="popup-item">
        <span>修改密码</span>
        <el-input placeholder="请输入"></el-input>
      </div>
      <div class="popup-item">
        <span>重新输入</span>
        <el-input placeholder="请输入"></el-input>
      </div>
      <div class="popup-item">
        <span>banner</span>
        <el-upload class="avatar-uploader" action="https://jsonplaceholder.typicode.com/posts/" :show-file-list="false"
          :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
          <img v-if="imageUrl" :src="imageUrl" class="avatar">
          <div class="text" v-else>
            <i class="el-icon-plus icon"></i>
            <span>点击上传</span>
          </div>
        </el-upload>
      </div>
      <div class="btns">
        <el-button @click="close">清空</el-button>
        <el-button class="blue" @click="close">确定</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {

  },
  data () {
    return {
      options: [{
        value: '',
        label: ''
      }],
      imageUrl: '',
      value: ''
    }
  },
  methods: {
    handleAvatarSuccess (res, file) {
      this.imageUrl = URL.createObjectURL(file.raw)
    },
    beforeAvatarUpload (file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.popup {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #081C38;
  box-sizing: border-box;

  .popup-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;

    .title {
      height: 38px;
      line-height: 38px;
      padding: 0 9px;
      text-align: center;
      background-color: #01EEFD;
      box-sizing: border-box;
      color: #fff;
      font-size: 22px;
    }

    .postion {
      width: 246px;
      border-radius: 6px;
      background: linear-gradient(153deg, #0A477B 0%, #0E67AD 100%);
      border: 1px solid #1F72D3;

      /deep/.el-input__inner {
        background-color: transparent;
        color: #fff !important;
        font-size: 18px;
        border: none;

      }

      /deep/ .el-input__suffix {
        color: #fff;
        font-size: 18px;
      }

      /deep/ .el-icon-search {
        display: flex;
        align-items: center;
      }
    }
  }

  .popup-box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 12px;
    box-sizing: border-box;

    .popup-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      margin-top: 20px;
      color: #01EEFD;
      font-size: 18px;

      span {
        width: 110px;
      }

      .el-input {
        width: 550px;
      }

      .avatar-uploader {
        width: 166px;
        height: 73px;
        border: 1px solid #fff;
        .text {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-around;
          width: 100%;
          margin-top: 10px;
          padding-left: 10px;
          box-sizing: border-box;
          color: #fff;

          .icon {
            font-size: 26px;
          }
        }

        .avatar {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .btns {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 100px;

    .el-button {
      margin-right: 50px;
      background-color: #33465C;
      border: none;
      color: #fff;
    }

    .blue {
      background: linear-gradient(108deg, #0174C9 0%, #024176 100%);
    }
  }
}
</style>
