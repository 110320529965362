<template>
  <div class="son">
    <div class="son-title">
      <span class="title-box">老年管理</span>
      <el-input class="postion" placeholder="姓名/身份证号" suffix-icon="el-icon-search">
      </el-input>
    </div>
    <div class="son-search">
      <div class="son-item">
        <span class="key">老人姓名</span>
        <el-input placeholder="请输入" v-model="params.username"></el-input>
      </div>
      <div class="son-item">
        <span class="key">身份证号</span>
        <el-input placeholder="请输入" v-model="params.sfzh"></el-input>
      </div>
      <div class="son-item">
        <span class="key">床位</span>
        <el-select v-model="value" placeholder="请选择街道/镇">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="son-item">
        <span class="key">入住状态</span>
        <el-select v-model="value" placeholder="请选择社区/村">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>

      <el-button type="primary" @click="getList">搜索</el-button>
      <el-button type="primary">重置</el-button>
      <el-button type="primary" @click="openAdd">添加</el-button>
    </div>
    <div class="son-table">
      <el-table :data="tableData" height="100%" border ref="table">
      <el-table-column prop="id" label="专属编号" :width="flexWidth('id', tableData, '专属编号')">
      </el-table-column>
      <el-table-column prop="rqfl" label="人群分类" :width="flexWidth('rqfl', tableData, '人群分类')">
      </el-table-column>
      <el-table-column prop="name" label="姓名" :width="flexWidth('name', tableData, '姓名')">
      </el-table-column>
      <el-table-column prop="xb" label="性别" :width="flexWidth('xb', tableData, '性别')">
        <template slot-scope="scope">
          <span>{{ scope.row.xb | xbStyle }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="age" label="年龄" :width="flexWidth('age', tableData, '年龄')">
      </el-table-column>
      <el-table-column prop="sfzh" label="身份证号" :width="flexWidth('sfzh', tableData, '身份证号')">
      </el-table-column>
      <el-table-column prop="ssjgmc" label="机构名称" :width="flexWidth('ssjgmc', tableData, '机构名称')">
      </el-table-column>
      <el-table-column prop="fwdj" label="自理能力" :width="flexWidth('fwdj', tableData, '自理能力')">
      </el-table-column>
      <el-table-column prop="hldj" label="护理等级" :width="flexWidth('hldj', tableData, '护理等级')">
      </el-table-column>
      <el-table-column prop="cwzt" label="床位状态" :width="flexWidth('cwzt', tableData, '床位状态')">
      </el-table-column>
      <el-table-column label="床位信息" :width="flexWidth('louc', tableData, '床位信息')">
        <template slot-scope="scope">
          <span>{{ scope.row.louc }}{{ scope.row.fj }}{{ scope.row.cw }}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="louc" label="楼层">
      </el-table-column>
      <el-table-column prop="fj" label="房间">
      </el-table-column>
      <el-table-column prop="cw" label="床位">
      </el-table-column> -->
      <el-table-column prop="cyyycyzt" label="状态">
      </el-table-column>
      <el-table-column label="操作" width="620" fixed="right">
        <!-- slot-scope="scope" -->
        <template slot-scope="scope">

          <!-- <span @click="openFileRun">档案管理</span> -->
          <!-- <div class="flex">
            <span @click="openAdd">编辑</span>
            <span @click="openSeeInfo(scope.row.id)">护理等级</span>
            <span @click="openSchedulRun">排班管理</span>
            <span @click="openTask">计划任务</span>
            <span @click="openSet">护理等级</span>
            <span @click="openVideo">视频通话</span>
          </div> -->
          <div class="flex">
            <el-button type="primary" size="mini" @click="openUpdate(scope.row)">编辑</el-button>
          <el-button type="primary" size="mini" @click="openSeeInfo(scope.row.id)">护理等级</el-button>
          <el-button type="primary" size="mini" @click="openSchedulRun">排班管理</el-button>
          <el-button type="primary" size="mini" @click="openTask">计划任务</el-button>
          <el-button type="primary" size="mini" @click="openSet">护理等级</el-button>
          <el-button type="primary" size="mini" @click="openDischarge(scope.row)">出院</el-button>
          <el-button type="primary" size="mini" @click="openVideo">视频通话</el-button>
          </div>

        </template>
      </el-table-column>
    </el-table>
    </div>

    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="query1.pageNum" background :page-size="query1.pageSize" layout="total, prev, pager, next, jumper" :total="query1.total" hide-on-single-page></el-pagination>
    <Add v-if="showAdd" @close="closeAdd"></Add>
    <Set v-if="showSet" @close="closeSet"></Set>
    <Run v-if="showRun" @close="closeRun"></Run>
    <Delete v-if="showDelete" @close="closeDelete"></Delete>
    <!-- 排班管理 -->
    <SchedulRun v-if="showSchedulRun" @close="closeSchedulRun"></SchedulRun>
    <!-- 档案管理 -->
    <FileRun v-if="showFileRun" @close="closeFileRun"></FileRun>
    <SeeInfo v-if="showSeeInfo" :selectId="selectId" @close="closeSeeInfo"></SeeInfo>
    <!-- 计划任务 -->
    <Task v-if="showTask" @close="closeTask"></Task>
    <Video v-if="showVideo" @close="closeVideo"></Video>
    <Discharge v-if="showDischarge"></Discharge>
    <update v-if="showUpdate" :selectInfo="selectInfo" @close="closeUpdate"></update>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Add from './add.vue'
import Set from './set.vue'
import Run from './run.vue'
import Delete from './delete.vue'
import SchedulRun from './schedulRun.vue'
import FileRun from './fileRun.vue'
import Task from './task.vue'
import Video from './video.vue'
import SeeInfo from '../../../runkanban/seeInfo.vue'
import Discharge from './discharge.vue'
import Update from './update.vue'
export default {
  components: {
    Add,
    Set,
    Run,
    Delete,
    SchedulRun,
    FileRun,
    Task,
    Video,
    SeeInfo,
    Discharge,
    Update
  },
  data () {
    return {
      issynchronous: false,
      options: [{
        value: '',
        label: ''
      }],
      value: '',
      tableData: [
        // {
        //   name: '张三',
        //   fj: '1',
        //   cw: '2',
        //   cwzt: '3'
        // }
      ],
      total: 400,
      currentPage: 0,
      showAdd: false,
      showDelete: false,
      showSet: false,
      showRun: false,
      showSchedulRun: false,
      showFileRun: false,
      showTask: false,
      showVideo: false,
      showUpdate: false,
      showSeeInfo: false,
      showDischarge: false,
      selectInfo: {},
      selectId: '',
      // 分页数据
      query1: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      params: {
        username: '',
        sfzh: ''
      }
    }
  },
  computed: {
    ...mapState(['loginInfo'])
  },
  filters: {
    xbStyle (val) {
      if (!val) return ''
      const obj = { 1: '男', 2: '女' }
      return obj[val]
    }
  },
  created () {
    this.getList()
  },
  methods: {
    flexWidth (prop, tableData, title, num = 0) {
      if (tableData.length === 0) { // 表格没数据不做处理
        return title.length * 30 + 'px'
      }
      let flexWidth = 0// 初始化表格列宽
      let columnContent = ''// 占位最宽的内容
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')
      context.font = '16px Microsoft YaHei'
      if ((prop === '') && title) { // 标题长内容少的，取标题的值,
        columnContent = title
      } else { // 获取该列中占位最宽的内容
        let index = 0
        for (let i = 0; i < tableData.length; i++) {
          const nowTemp = tableData[i][prop] + ''
          const maxTemp = tableData[index][prop] + ''
          const nowTempW = context.measureText(nowTemp).width
          const maxTempW = context.measureText(maxTemp).width
          if (nowTempW > maxTempW) {
            index = i
          }
        }
        columnContent = tableData[index][prop]
        // 比较占位最宽的值跟标题、标题为空的留出四个位置
        const columnW = context.measureText(columnContent).width
        const titleW = context.measureText(title).width
        if (columnW < titleW) {
          columnContent = title || '留七'
        }
      }
      // 计算最宽内容的列宽
      const width = context.measureText(columnContent)
      flexWidth = width.width + 38 + num
      return flexWidth + 'px'
    },
    async getList () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-81591714
      try {
        // const start = this.query1.pageNum === 1 ? '10' : (this.query1.pageNum - 1) * 10 + 1 + ''
        const start = this.query1.pageNum === 1 ? '0' : (this.query1.pageNum - 1) * 10 + ''
        const obj = {
          start: start,
          length: this.query1.pageSize + '',
          ssjgbh: this.loginInfo.jgbh,
          jgbh: this.loginInfo.jgbh,
          username: this.params.username || '',
          sfzh: this.params.sfzh
        }
        const { data: res } = await this.$http.get('/ykdzhylfwlbs', { params: obj })
        console.log('智慧养老服务列表', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.tableData = res.data
        this.query1.total = res.recordsTotal
        this.$nextTick(() => {
          this.$refs.table.bodyWrapper.scrollTop = 0
        })
      } catch (error) {
        console.log('智慧养老服务列表请求失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    openAdd () {
      this.showAdd = true
    },
    closeAdd () {
      this.showAdd = false
    },
    openUpdate (info) {
      this.selectInfo = info
      this.showUpdate = true
    },
    closeUpdate () {
      this.showUpdate = false
    },
    openDelete () {
      this.showDelete = true
    },
    closeDelete () {
      this.showDelete = false
    },
    openSet () {
      this.showSet = true
    },
    closeSet () {
      this.showSet = false
    },
    openRun () {
      this.showRun = true
    },
    closeRun () {
      this.showRun = false
    },
    openSchedulRun () {
      this.showSchedulRun = true
    },
    closeSchedulRun () {
      this.showSchedulRun = false
    },
    openFileRun () {
      this.showFileRun = true
    },
    closeFileRun () {
      this.showFileRun = false
    },
    openTask () {
      this.showTask = true
    },
    closeTask () {
      this.showTask = false
    },
    openVideo () {
      this.showVideo = true
    },
    closeVideo () {
      this.showVideo = false
    },
    openSeeInfo (id) {
      this.selectId = id + ''
      this.showSeeInfo = true
    },
    closeSeeInfo () {
      this.showSeeInfo = false
      this.selectId = ''
    },
    openDischarge (info) {
      this.selectInfo = info
      this.showDischarge = true
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
      this.query1.pageNum = val
      this.getList()
    }
  }
}
</script>

<style lang="less" scoped>
.son {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0 10px;
  background-color: #fff;
  box-sizing: border-box;

  .son-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 60px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    .title-box {
      display: flex;
      flex-direction: row;
      height: 38px;
      line-height: 38px;
      padding: 0 9px;
      text-align: center;
      background-color: #409EFF;
      color: #fff;
      font-size: 22px;

    }

    .postion {
      width: 246px;
    }
  }

  .son-search {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    height: 80px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    .son-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 10px;

      .key {
        width: 110px;
        color: #000;
        font-size: 22px;
      }

      .el-input {
        width: 180px;
      }

      .add {
        width: 30px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        border-radius: 50%;
        background-color: #409EFF;
        color: #fff;
        font-size: 20px;
        cursor: pointer;
      }
    }

    .red {
      background-color: #FF0000;
    }

  }

  .son-table {
    width: 100%;
    height: calc(100% - 260px);

    /deep/.el-table__cell {
      text-align: center;
      color: #000;
    }

    .flex {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      width: 100%;

      span {
        cursor: pointer;
      }
    }
  }

  // 页码样式修改
  .el-pagination {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
}
</style>
