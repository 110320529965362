<template>
  <div class="page-box">
    <div class="tab">
      <span class="item" :class="{ active: tabIndex === index }" v-for="(item, index) in tabs" :key="index" @click="tabClick(index)">{{ item }}</span>
    </div>
    <div class="tab-box">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tabIndex: 0,
      tabs: Object.freeze(['任务看板'])
    }
  },
  created () {
    // this.tabIndex = window.sessionStorage.getItem('retirementIndex') - 0
  },
  methods: {
    tabClick (index) {
      if (index === this.tabIndex) return
      const routers = ['taskkanban', 'handoverrecord']
      this.tabIndex = index
      this.$router.push('/smartelderlycare/retirement/' + routers[index])
      console.log(routers[index])
      window.sessionStorage.setItem('retirementIndex', index)
    }
  }
}
</script>

<style lang="less" scoped>
  .page-box {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    background-color: #fff;

    .tab {
      display: flex;
      align-items: center;
      width: 100%;
      height: 78px;
      border-bottom: 1px solid #000;
      box-sizing: border-box;

      .item {
        width: 130px;
        height: 38px;
        line-height: 38px;
        text-align: center;
        background-color: #409EFF;
        color: #fff;
        font-size: 22px;
        cursor: pointer;

        &:first-child {
          margin-right: 30px;
        }
      }

      .active {
        background-color: #409EFF;
      }
    }

    .tab-box {
      width: 100%;
      height: calc(100% - 78px);
    }

  }
</style>
