<template>
  <div class="children-page">
    <div class="head">
      <el-button size="small" class="blue">医生排班</el-button>
    </div>
    <div class="input">
      <div class="son-tab">
        <div class="son-item" :class="{ 'son-active': tabIndex === 0 }" @click="tabClick(0)">
          <span>上一周</span>
        </div>
        <div class="son-item" :class="{ 'son-active': tabIndex === 1 }" @click="tabClick(1)">
          <span>本周</span>
        </div>
        <div class="son-item" :class="{ 'son-active': tabIndex === 2 }" @click="tabClick(2)">
          <span>下一周</span>
        </div>
      </div>
      <el-select placeholder="请选择科室" v-model="value">
        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
      <el-input placeholder="请输入医生/病人姓名"></el-input>
      <el-button class="blue" size="mini">搜索</el-button>
    </div>
    <el-table :data="tableData" style="width: 100%" height="400">
      <el-table-column prop="date" label="医生姓名" fixed width="100">
      </el-table-column>
      <el-table-column prop="name" label="就诊卡号" fixed width="120">
      </el-table-column>
      <el-table-column label="2021/01/01 星期一">
        <el-table-column prop="name" label="上午">
        </el-table-column>
        <el-table-column prop="name" label="下午">
        </el-table-column>
      </el-table-column>
      <el-table-column label="2021/01/01 星期二">
        <el-table-column prop="name" label="上午">
        </el-table-column>
        <el-table-column prop="name" label="下午">
        </el-table-column>
      </el-table-column>
      <el-table-column label="2021/01/01 星期三">
        <el-table-column prop="name" label="上午">
        </el-table-column>
        <el-table-column prop="name" label="下午">
        </el-table-column>
      </el-table-column>
      <el-table-column label="2021/01/01 星期四">
        <el-table-column prop="name" label="上午">
        </el-table-column>
        <el-table-column prop="name" label="下午">
        </el-table-column>
      </el-table-column>
      <el-table-column label="2021/01/01 星期五">
        <el-table-column prop="name" label="上午">
        </el-table-column>
        <el-table-column prop="name" label="下午">
        </el-table-column>
      </el-table-column>
      <el-table-column label="2021/01/01 星期六">
        <el-table-column prop="name" label="上午">
        </el-table-column>
        <el-table-column prop="name" label="下午">
        </el-table-column>
      </el-table-column>
      <el-table-column label="2021/01/01 星期日">
        <el-table-column prop="name" label="上午">
        </el-table-column>
        <el-table-column prop="name" label="下午">
        </el-table-column>
      </el-table-column>
      <el-table-column  label="操作" fixed="right" width="100">
        <template>
          <div class="icon" @click="openSet">
            <span class="el-icon-s-tools"></span>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
        background :page-size="10" layout="total, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>
    <Set v-if="showSet" @close="closeSet"></Set>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Set from './set'
export default {
  components: {
    Set
  },
  data () {
    return {
      total: 400,
      currentPage: 0,
      tableData: [{
        date: '2016-05-03',
        name: '王小虎',
        province: '上海',
        city: '普陀区',
        address: '上海市普陀区金沙江路 1518 弄',
        zip: 200333
      }, {
        date: '2016-05-03',
        name: '王小虎',
        province: '上海',
        city: '普陀区',
        address: '上海市普陀区金沙江路 1518 弄',
        zip: 200333
      }, {
        date: '2016-05-03',
        name: '王小虎',
        province: '上海',
        city: '普陀区',
        address: '上海市普陀区金沙江路 1518 弄',
        zip: 200333
      }, {
        date: '2016-05-03',
        name: '王小虎',
        province: '上海',
        city: '普陀区',
        address: '上海市普陀区金沙江路 1518 弄',
        zip: 200333
      }, {
        date: '2016-05-03',
        name: '王小虎',
        province: '上海',
        city: '普陀区',
        address: '上海市普陀区金沙江路 1518 弄',
        zip: 200333
      }, {
        date: '2016-05-03',
        name: '王小虎',
        province: '上海',
        city: '普陀区',
        address: '上海市普陀区金沙江路 1518 弄',
        zip: 200333
      }, {
        date: '2016-05-03',
        name: '王小虎',
        province: '上海',
        city: '普陀区',
        address: '上海市普陀区金沙江路 1518 弄',
        zip: 200333
      }, {
        date: '2016-05-03',
        name: '王小虎',
        province: '上海',
        city: '普陀区',
        address: '上海市普陀区金沙江路 1518 弄',
        zip: 200333
      }, {
        date: '2016-05-03',
        name: '王小虎',
        province: '上海',
        city: '普陀区',
        address: '上海市普陀区金沙江路 1518 弄',
        zip: 200333
      }, {
        date: '2016-05-03',
        name: '王小虎',
        province: '上海',
        city: '普陀区',
        address: '上海市普陀区金沙江路 1518 弄',
        zip: 200333
      }],
      options: [{
        value: '1',
        label: '1'
      }],
      value: '',
      date: '',
      input: '',
      tabIndex: 0,
      showSet: false
    }
  },
  computed: {
    ...mapState(['loginInfo'])
  },
  watch: {

  },
  created () {
    // this.getList()
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    },
    openSet () {
      this.showSet = true
    },
    closeSet () {
      this.showSet = false
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
    }
  }
}
</script>

<style lang="less" scoped>
.children-page {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0 30px;
  background-color: #081C38;
  box-sizing: border-box;

  .swich {
    display: flex;
    flex-direction: column;
  }

  .head {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;

    .right {
      display: flex;
      flex-direction: row;
      align-items: center;

      .el-button {
        width: 90px;
        font-size: 16px;
      }
    }

    .el-button {
      text-align: center;
      border-radius: 0;
      color: #fff;
      font-size: 22px;
      border: 0;
      margin-right: 20px;
      background-color: #04B4D0;
    }

    .width {
      width: 80px;
      font-size: 18px;
    }

    .el-input {
      width: 246px;
      height: 38px;
      border-radius: 6px;

      /deep/.el-input__inner {
        height: 38px;
        line-height: 38px;
      }
    }

    .postion {
      position: absolute;
      right: 0;
      background: linear-gradient(153deg, #0A477B 0%, #0E67AD 100%);
      border: 1px solid #1F72D3;

      /deep/.el-input__inner {
        background-color: transparent;
        color: #fff !important;
        font-size: 18px;
        border: none;

      }
    }

    /deep/ .el-input__suffix {
      color: #fff;
      font-size: 18px;
    }

    /deep/ .el-icon-search {
      display: flex;
      align-items: center;
    }
  }

  .input {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    height: 78px;
    border-bottom: 1px solid #01EEFD;

    .son-tab {
      display: flex;
      flex-direction: row;
      align-items: center;
      overflow: hidden;
      width: 240px;
      height: 36px;
      border: 1px solid #01EEFD;
      border-radius: 6px;
      box-sizing: border-box;

      .son-item {
        flex: 1;
        text-align: center;
        height: 36px;
        line-height: 36px;
        color: #01EEFD;
        font-size: 16px;
        cursor: pointer;
      }

      .son-active {
        background-color: #01EEFD;
        color: #fff;
      }
    }

    .el-button {
      width: 90px;
      height: 38px;
      text-align: center;
      border-radius: 0;
      color: #fff;
      font-size: 16px;
      border: 0;
    }

    .el-radio {
      color: #fff;
    }

    /deep/.el-radio__label {
      font-size: 18px;
    }

    /deep/.el-radio__input.is-checked+.el-radio__label {
      color: #01EEFD;
    }

    /deep/.el-radio__input.is-checked .el-radio__inner {
      background-color: #01EEFD;
      border-color: #01EEFD;
    }

    /deep/.el-radio__inner {
      background-color: transparent;
    }

    .el-select {
      margin-left: 20px;
    }

    .el-input {
      width: 334px;
      margin-left: 20px;
    }

    .el-date-editor {
      margin-left: 20px;
    }

    .blue {
      margin-left: 20px;
      background-color: #04B4D0;
    }

    .search {
      width: 300px;
    }

    .item {
      display: flex;
      align-items: center;
      padding: 10px 0;
      margin-right: 20px;
      color: #01EEFD;
      font-size: 18px;

      .el-radio {
        color: #fff;
      }

      /deep/.el-radio__label {
        font-size: 18px;
      }

      /deep/.el-radio__input.is-checked+.el-radio__label {
        color: #01EEFD;
      }

      /deep/.el-radio__input.is-checked .el-radio__inner {
        background-color: #01EEFD;
        border-color: #01EEFD;
      }

      .span {
        margin-right: 10px;
      }

      .el-input {
        width: 206px;
        height: 38px;
        line-height: 38px;
        background-color: #fff;

        /deep/.el-input__inner {
          height: 38px;
          line-height: 38px;
        }
      }
    }
  }

  /deep/.el-table {
    margin-top: 20px;
    border: 4px solid #073F73;
  }

  /deep/.el-table__body {
    width: 100% !important;
  }

  /deep/.el-table__header {
    width: 100% !important;
  }

  /deep/.el-table th.el-table__cell.is-leaf {
    border-bottom: 1px solid #01EEFD;
  }

  /deep/.el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell {
    background-color: #081C38;
  }

  /deep/.el-table__cell {
    background-color: #081C38 !important;
    border-bottom: 1px solid #01EEFD;
  }

  /deep/.el-table__fixed-header-wrapper .el-table__cell {
    text-align: center;
    border-right: 1px solid #01EEFD;
    color: #fff;
    box-sizing: border-box;
  }

  /deep/.el-table__cell {
    text-align: center;
    color: #fff;
    box-sizing: border-box;
  }

  /deep/.el-table--border .el-table__cell {
    border-right: 1px solid #01EEFD;
  }

  /deep/.el-table__fixed-header-wrapper .el-table__cell:last-child {
    border-left: 1px solid #01EEFD !important;
  }

  /deep/.el-table__fixed-body-wrapper .el-table__cell:last-child {
    border-left: 1px solid #01EEFD !important;
  }

  /deep/.el-table__row .el-table__cell {
    text-align: center;
    border-right: 1px solid #01EEFD !important;
    color: #fff;
    box-sizing: border-box;
  }

  /deep/.el-table__body-wrapper {
    background-color: #081C38 !important;
  }

  .icon {
    color: #01EEFD;
    font-size: 26px;
    cursor: pointer;
  }

  // ::v-deep .el-table__body-wrapper {
  //   &::-webkit-scrollbar {
  //     width: 0px;
  //   }
  // }

  // ::v-deep .el-table th.gutter {
  //   display: none;
  //   width: 0;
  // }

  // ::v-deep .el-table colgroup col[name='gutter'] {
  //   display: none;
  //   width: 0;
  // }

  // ::v-deep .el-table__body {
  //   width: 100% !important;
  // }

  // .el-table {
  //   margin-top: 20px;
  //   background-color: #092B50;
  //   border: 4px #073F73 solid;
  // }

  // // 表头文字颜色
  // /deep/.el-table__header-wrapper {
  //   thead {
  //     th {
  //       div {
  //         color: #01EEFD;
  //         font-size: 17px;
  //         font-weight: 400;
  //       }
  //     }
  //   }
  // }

  // //表格内容颜色
  // /deep/.el-table__body tbody tr {
  //   color: #fff;
  //   background-color: #092B50;
  // }

  // /deep/.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
  //   background-color: #092B50;
  // }

  // /deep/.el-table tbody tr:hover>td {
  //   background-color: transparent !important
  // }

  // // 改变边框颜色
  // .el-table--border,
  // .el-table--group {
  //   border: 2px solid #ddd !important;
  // }

  // /**
  // 改变表格内竖线颜色
  //  */
  // .el-table--border td,
  // .el-table--border th,
  // .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
  //   border-right: 1px solid #ddd !important;
  // }

  // /deep/.el-table td {
  //   border-bottom: 1px solid #01EEFD;
  // }

  // /deep/.el-table thead tr th {
  //   border-color: transparent;
  // }

  // /deep/ .el-table--border::after,
  // /deep/ .el-table--group::after,
  // /deep/ .el-table::before {
  //   background-color: #073F73;
  // }

  // /deep/.el-table--border {
  //   border: 1px solid #073F73 !important;
  // }

  // /deep/.el-table .cell {
  //   text-align: center;
  //   color: #01EEFD;
  // }

  // /deep/.el-checkbox__inner {
  //   background-color: transparent;
  // }

  // .flex {
  //   display: flex;
  //   justify-content: space-between;
  //   width: 100%;

  //   .img {
  //     width: 29px;
  //     height: 25px;
  //     cursor: pointer;
  //   }
  // }

  // .green {
  //   color: #06B400;
  // }

  // .yellow {
  //   color: #FF8014;
  // }

  // .red {
  //   color: #FF0000;
  // }

  // .purple {
  //   color: #073F73;
  //   cursor: pointer;
  // }

  // .gray {
  //   color: #D2D2D2;
  //   cursor: pointer;
  // }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 40px;

    ::v-deep.el-pagination__total,
    ::v-deep.el-pagination__jump {
      color: #01EEFD;

      .el-input__inner {
        color: #fff;
        background-color: transparent;
      }
    }

    ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: #01EEFD;
    }
  }
}
</style>
