<template>
  <div class="pdf">
    <iframe class="iframe" :src="url"></iframe>
    <el-button @click="close">关闭</el-button>
  </div>
</template>

<script>
export default {
  props: {
    url: {
      typy: String,
      default: ''
    }
  },
  data () {
    return {
      // url: 'http://oss.gwtjpt.com/rep/2023/130430101226/90522072637132124195407.pdf'
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.pdf {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .69);

  .iframe {
    width: 90%;
    height: 80%;
  }
}
</style>
