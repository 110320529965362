<template>
  <div class="write">
    <div class="title">
      <span>新增</span>
    </div>
    <div class="content">
      <div @click="close" class="close">
        <span class="el-icon-close"></span>
      </div>
      <div class="box">
        <div class="box-tab">
          <div class="tab-item" :class="{ 'tab-active': index === tabIndex }" v-for="(item, index) in tabs" :key="index"
            @click="tabClick(index)">
            <span>{{ item }}</span>
          </div>
        </div>
        <div class="box-scroll">
          <template v-if="tabIndex === 0">
            <div class="list">
              <div class="list-item">
                <span>长者编号</span>
                <el-input placeholder="长者编号" v-model="params.czbh">
                </el-input>
              </div>
              <div class="list-item">
                <span>长者姓名</span>
                <el-input placeholder="请输入" v-model="params.name">
                </el-input>
              </div>
              <div class="list-item">
                <span>别名</span>
                <el-input placeholder="请输入">
                </el-input>
              </div>
              <div class="list-item">
                <span>性别</span>
                <el-select v-model="params.xb" placeholder="性别">
                  <el-option v-for="item in xbs" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </div>
              <div class="list-item list-item1">
                <span>身份证号</span>
                <el-input placeholder="请输入" class="width" v-model="params.sfzh" maxlength="18">
                </el-input>
              </div>
            </div>

            <div class="list">
              <span>头像</span>
              <el-upload class="avatar-uploader" action="https://jsonplaceholder.typicode.com/posts/"
                :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                <img v-if="imageUrl" :src="imageUrl" class="avatar">
                <div class="text" v-else>
                  <i class="el-icon-plus avatar-uploader-icon"></i>
                  <span>点击上传</span>
                </div>
              </el-upload>
            </div>

            <div class="list">
              <div class="list-item">
                <span>固定电话</span>
                <el-input placeholder="请输入" v-model="params.gddh">
                </el-input>
              </div>
              <div class="list-item">
                <span>手机号码</span>
                <el-input placeholder="请输入" v-model="params.sjhm">
                </el-input>
              </div>
              <div class="list-item">
                <span>出生日期</span>
                <el-date-picker type="date" value-format="yyyy-MM-dd" placeholder="" v-model="params.csrq">
                </el-date-picker>
              </div>
              <div class="list-item">
                <span>农历生日</span>
                <el-date-picker type="date" placeholder="">
                </el-date-picker>
              </div>
              <div class="list-item list-item1">
                <span>民族</span>
                <el-select v-model="params.mz" placeholder="请选择">
                  <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </div>
            </div>

            <div class="list">
              <div class="list-item">
                <span>婚姻状况</span>
                <el-select v-model="params.hyzk" placeholder="请选择">
                  <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </div>
              <div class="list-item">
                <span>文化程度</span>
                <el-select v-model="params.whcd" placeholder="请选择">
                  <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </div>
              <div class="list-item">
                <span>职业</span>
                <el-select v-model="params.zhiye" placeholder="请选择">
                  <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </div>
              <div class="list-item">
              </div>
              <div class="list-item list-item1">
              </div>
            </div>

            <div class="list">
              <span>户籍地址</span>
              <el-select v-model="value" placeholder="省">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
              <el-select v-model="value" placeholder="请选择市">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
              <el-select v-model="value" placeholder="请选择区/县">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
              <el-select v-model="value" placeholder="请选择街道">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
              <el-select v-model="value" placeholder="请选择社区/村">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
              <el-select v-model="value" placeholder="门牌号">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>

            <div class="list">
              <span>现居地址</span>
              <el-select v-model="value" placeholder="省">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
              <el-select v-model="value" placeholder="请选择市">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
              <el-select v-model="value" placeholder="请选择区/县">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
              <el-select v-model="value" placeholder="请选择街道">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
              <el-select v-model="value" placeholder="请选择社区/村">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
              <el-select v-model="value" placeholder="门牌号">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>

            <div class="list">
              <div class="list-item">
                <span>是否退休</span>
                <el-select v-model="value" placeholder="请选择">
                  <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </div>
              <div class="list-item list-item2">
                <span>个体特长</span>
                <el-input placeholder="" class="width1">
                </el-input>
              </div>
              <div class="list-item">
                <span>性格</span>
                <el-select v-model="value" placeholder="性格">
                  <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </div>
              <div class="list-item">
                <span>爱好</span>
                <el-select v-model="value" placeholder="爱好">
                  <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </div>
              <div class="list-item"></div>
            </div>

            <div class="list">
              <div class="list-item">
                <span>子女数量</span>
                <el-input-number v-model="params.znsl" :min="1" :max="10"></el-input-number>
              </div>
              <div class="list-item">
                <span>是否健在</span>
                <el-radio-group v-model="params.sfjz">
                  <el-radio label="0">是</el-radio>
                  <el-radio label="1">否</el-radio>
                </el-radio-group>
              </div>
              <div class="list-item">
                <span>政府救助类型</span>
                <el-select v-model="params.zfjzlx" placeholder="请选择">
                  <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </div>
              <div class="list-item">
                <span>主要经济来源</span>
                <el-select v-model="params.zyjjly" placeholder="请选择">
                  <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </div>
              <div class="list-item"></div>
            </div>

          </template>
          <template v-if="tabIndex === 1">
            <div class="table">
              <el-table :data="tableData" height="400" :header-cell-style="{ background: '#024276' }">
                <el-table-column prop="name" label="与长者关系"></el-table-column>
                <el-table-column label="第一联系人"></el-table-column>
                <el-table-column label="姓名"></el-table-column>
                <el-table-column label="联系方式"></el-table-column>
                <el-table-column label="身份证号码"></el-table-column>
                <el-table-column label="其他联系方式"></el-table-column>
                <el-table-column label="工作单位"></el-table-column>
                <el-table-column label="单位住址">
                </el-table-column>
                <el-table-column label="现住址">
                </el-table-column>
                <el-table-column label="现住址类型">
                </el-table-column>
                <el-table-column label="操作">
                  <!-- slot-scope="scope" -->
                  <!-- <template>
                    <div class="flex">
                      <span>查看</span>
                      <span>视频通话</span>
                      <span>删除</span>
                      <span>修改</span>
                    </div>
                  </template> -->
                </el-table-column>
              </el-table>
            </div>
            <div class="pagination">
              <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="4"
                background :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper"
                :total="400">
              </el-pagination>
            </div>
          </template>
          <template v-if="tabIndex === 2">
            <div class="list">
              <span>居住情况</span>
              <el-radio-group v-model="radio">
                <el-radio label="0">孤寡</el-radio>
                <el-radio label="1">机构</el-radio>
                <el-radio label="2">空巢</el-radio>
                <el-radio label="3">与子女同住</el-radio>
                <el-radio label="4">与配偶同住</el-radio>
                <el-radio label="5">独住</el-radio>
              </el-radio-group>
            </div>

            <div class="list">
              <span>养老方式</span>
              <el-radio-group v-model="radio">
                <el-radio label="0">居家</el-radio>
                <el-radio label="1">机构</el-radio>
                <el-radio label="2">社区</el-radio>
                <el-radio label="3">家庭养老床位</el-radio>
              </el-radio-group>
            </div>

            <div class="list">
              <span>燃气类型</span>
              <el-radio-group v-model="radio">
                <el-radio label="0">液化气</el-radio>
                <el-radio label="1">煤</el-radio>
                <el-radio label="2">天然气</el-radio>
                <el-radio label="3">其他</el-radio>
              </el-radio-group>
            </div>

            <div class="list">
              <span>饮水类型</span>
              <el-radio-group v-model="radio">
                <el-radio label="0">自来水</el-radio>
                <el-radio label="1">进化过滤水</el-radio>
                <el-radio label="2">井水</el-radio>
                <el-radio label="3">河水</el-radio>
                <el-radio label="4">其他</el-radio>
              </el-radio-group>
            </div>

            <div class="list">
              <span>长者分类</span>
              <el-radio-group v-model="radio">
                <el-radio label="0">孤寡老人</el-radio>
                <el-radio label="1">健康老人</el-radio>
                <el-radio label="2">慢性病老人</el-radio>
                <el-radio label="3">重病老人</el-radio>
                <el-radio label="4">残疾老人</el-radio>
              </el-radio-group>
            </div>

            <div class="list">
              <span>处方排风措施</span>
              <el-radio-group v-model="radio">
                <el-radio label="0">无</el-radio>
                <el-radio label="1">油烟机</el-radio>
                <el-radio label="2">烟窗</el-radio>
                <el-radio label="3">换气扇+烟窗</el-radio>
                <el-radio label="4">油烟机+烟窗</el-radio>
                <el-radio label="5">油烟机+换气扇</el-radio>
                <el-radio label="6">油烟机+换气扇+烟窗</el-radio>
              </el-radio-group>
            </div>

            <div class="list">
              <span>宠物</span>
              <el-radio-group v-model="radio">
                <el-radio label="0">无</el-radio>
                <el-radio label="1">鸟</el-radio>
                <el-radio label="2">猫</el-radio>
                <el-radio label="3">狗</el-radio>
                <el-radio label="4">其他人</el-radio>
              </el-radio-group>
            </div>

          </template>
          <template v-if="tabIndex === 3">
            <div class="list">
              <span>补偿类型</span>
              <el-radio-group v-model="radio">
                <el-radio label="0">低偿</el-radio>
                <el-radio label="1">无偿</el-radio>
                <el-radio label="2">有偿</el-radio>
                <el-radio label="3">志愿服务</el-radio>
              </el-radio-group>
            </div>

            <div class="list">
              <span>政府补助金额度</span>
              <el-radio-group v-model="radio">
                <el-radio label="0">无</el-radio>
                <el-radio label="1">100-300</el-radio>
                <el-radio label="2">300-1000</el-radio>
                <el-radio label="3">1000-3000</el-radio>
                <el-radio label="4">3000以上</el-radio>
              </el-radio-group>
            </div>

          </template>
          <template v-if="tabIndex === 4">
            <div class="list">
              <span>生活需求</span>
              <el-checkbox-group v-model="checkList">
                <el-checkbox label="0">日用品配送</el-checkbox>
                <el-checkbox label="1">无需求</el-checkbox>
                <el-checkbox label="2">药品配送</el-checkbox>
              </el-checkbox-group>
            </div>

            <div class="list">
              <span>家政需求</span>
              <el-checkbox-group v-model="checkList">
                <el-checkbox label="0">餐饮配送</el-checkbox>
                <el-checkbox label="1">蛋糕</el-checkbox>
                <el-checkbox label="2">理发</el-checkbox>
                <el-checkbox label="3">牛羊奶</el-checkbox>
                <el-checkbox label="4">日用品配送</el-checkbox>
                <el-checkbox label="5">无需求</el-checkbox>
                <el-checkbox label="6">药品配送</el-checkbox>
              </el-checkbox-group>
            </div>

            <div class="list">
              <span>医疗需求</span>
              <el-checkbox-group v-model="checkList">
                <el-checkbox label="0">陪诊</el-checkbox>
                <el-checkbox label="1">无需求</el-checkbox>
              </el-checkbox-group>
            </div>

            <div class="list">
              <span>精神需求</span>
              <el-checkbox-group v-model="checkList">
                <el-checkbox label="0">无服务需求</el-checkbox>
                <el-checkbox label="1">心里辅导</el-checkbox>
              </el-checkbox-group>
            </div>

            <div class="list">
              <span>是否接受电话关怀精神慰问</span>
              <el-radio-group v-model="radio">
                <el-radio label="0">无服务需求</el-radio>
                <el-radio label="1">心里辅导</el-radio>
              </el-radio-group>
            </div>

            <div class="list">
              <span>分析总结</span>
              <el-input type="textarea" placeholder="请输入">
              </el-input>
            </div>

            <div class="list">
              <span class="span">分析总长者或其家庭情况说明结</span>
              <el-input type="textarea" placeholder="请输入">
              </el-input>
            </div>

          </template>

          <template v-if="tabIndex === 5">

            <div class="list-title">
              <span>基本情况</span>
            </div>

            <div class="list list1">
              <div class="list-item">
                <span>身高（cm）</span>
                <el-input placeholder="">
                </el-input>
              </div>
              <div class="list-item">
                <span>体重（kg）</span>
                <el-input placeholder="">
                </el-input>
              </div>
              <div class="list-item">
                <span>药物（cm）</span>
                <el-input placeholder="">
                </el-input>
              </div>
              <div class="list-item">
              </div>
            </div>

            <div class="list-title">
              <span>病史</span>
            </div>

            <div class="list list1">
              <div class="list-item">
                <span>收缩压</span>
                <el-input placeholder="">
                </el-input>
              </div>
              <div class="list-item">
                <span>舒张压</span>
                <el-input placeholder="">
                </el-input>
              </div>
              <div class="list-item">
                <span>心率</span>
                <el-input placeholder="">
                </el-input>
              </div>
              <div class="list-item">
                <span>餐前血糖</span>
                <el-input placeholder="">
                </el-input>
              </div>
            </div>

            <div class="list list1">
              <span>遗传病史</span>
              <el-input type="textarea" placeholder="请输入">
              </el-input>
            </div>

            <div class="list list1">
              <span class="span">既往病史</span>
              <el-input type="textarea" placeholder="请输入">
              </el-input>
            </div>

            <div class="list list1">
              <span class="span-width">药物过敏史</span>
              <el-checkbox-group v-model="checkList">
                <el-checkbox label="0">无</el-checkbox>
                <el-checkbox label="1">青霉素</el-checkbox>
                <el-checkbox label="2">磺胺</el-checkbox>
                <el-checkbox label="3">链霉素</el-checkbox>
                <el-checkbox label="4">其他</el-checkbox>
              </el-checkbox-group>
            </div>

            <div class="list list1">
              <span class="span-width">家族病史</span>
              <el-checkbox-group v-model="checkList">
                <el-checkbox label="0">无</el-checkbox>
                <el-checkbox label="1">高血压</el-checkbox>
                <el-checkbox label="2">糖尿病</el-checkbox>
                <el-checkbox label="3">糖尿病</el-checkbox>
                <el-checkbox label="4">慢性堵塞</el-checkbox>
                <el-checkbox label="5">性肺疾病</el-checkbox>
                <el-checkbox label="6">恶心肿瘤</el-checkbox>
                <el-checkbox label="7">脑卒中</el-checkbox>
                <el-checkbox label="8">重性精神疾病</el-checkbox>
                <el-checkbox label="9">结核病</el-checkbox>
                <el-checkbox label="10">肝炎</el-checkbox>
                <el-checkbox label="11">先天畸形</el-checkbox>
                <el-checkbox label="12">其他</el-checkbox>
              </el-checkbox-group>
            </div>

            <div class="list list1">
              <span class="span-width">慢性病</span>
              <el-checkbox-group v-model="checkList">
                <el-checkbox label="0">心机梗塞</el-checkbox>
                <el-checkbox label="1">无</el-checkbox>
                <el-checkbox label="2">高血压</el-checkbox>
                <el-checkbox label="3">糖尿病</el-checkbox>
              </el-checkbox-group>
            </div>

            <div class="list-title list1 list-top">
              <span>当前身体状况</span>
            </div>

            <div class="list list2">
              <span class="span-width">健康状况自评</span>
              <el-radio-group v-model="radio">
                <el-radio label="0">满意</el-radio>
                <el-radio label="1">基本满意</el-radio>
                <el-radio label="2">说不清楚</el-radio>
                <el-radio label="3">不太满意</el-radio>
                <el-radio label="4">不满意</el-radio>
              </el-radio-group>
            </div>

            <div class="list list2">
              <span class="span-width">自理能力自评</span>
              <el-radio-group v-model="radio">
                <el-radio label="0">不能自理</el-radio>
                <el-radio label="1">轻度依赖</el-radio>
                <el-radio label="2">中度依赖</el-radio>
                <el-radio label="3">可自理</el-radio>
              </el-radio-group>
            </div>

            <div class="list list2">
              <span class="span-width">身体状况</span>
              <el-checkbox-group v-model="checkList">
                <el-checkbox label="0">半瘫</el-checkbox>
                <el-checkbox label="1">自理</el-checkbox>
                <el-checkbox label="2">半自理</el-checkbox>
                <el-checkbox label="3">失能</el-checkbox>
                <el-checkbox label="4">瘫痪</el-checkbox>
              </el-checkbox-group>
            </div>

            <div class="list list2">
              <span class="span-width">心理特征</span>
              <el-checkbox-group v-model="checkList">
                <el-checkbox label="0">焦虑</el-checkbox>
                <el-checkbox label="1">无</el-checkbox>
                <el-checkbox label="2">恐惧</el-checkbox>
                <el-checkbox label="3">易怒</el-checkbox>
                <el-checkbox label="4">依赖</el-checkbox>
                <el-checkbox label="5">其他</el-checkbox>
                <el-checkbox label="6">孤独</el-checkbox>
              </el-checkbox-group>
            </div>

            <div class="list list2">
              <span class="span-width">痴呆前兆</span>
              <el-checkbox-group v-model="checkList">
                <el-checkbox label="0">行为异常</el-checkbox>
                <el-checkbox label="1">记忆障碍</el-checkbox>
                <el-checkbox label="2">迷路</el-checkbox>
                <el-checkbox label="3">其他</el-checkbox>
                <el-checkbox label="4">日常生活能力减退</el-checkbox>
                <el-checkbox label="5">呆坐</el-checkbox>
                <el-checkbox label="6">无</el-checkbox>
              </el-checkbox-group>
            </div>

            <div class="list list2">
              <span class="span-width">现存症状</span>
              <el-checkbox-group v-model="checkList">
                <el-checkbox label="0">无症状</el-checkbox>
                <el-checkbox label="1">头痛</el-checkbox>
                <el-checkbox label="2">头晕</el-checkbox>
                <el-checkbox label="3">心悸</el-checkbox>
                <el-checkbox label="4">胸闷</el-checkbox>
                <el-checkbox label="5">慢性咳嗽</el-checkbox>
                <el-checkbox label="6">咳痰</el-checkbox>
                <el-checkbox label="7">呼吸困难</el-checkbox>
                <el-checkbox label="8">多饮</el-checkbox>
                <el-checkbox label="9">多尿</el-checkbox>
                <el-checkbox label="10">体重下降</el-checkbox>
              </el-checkbox-group>
            </div>

            <div class="list-title list1 list-top">
              <span>生活习惯</span>
            </div>

            <div class="list list2">
              <span class="span-width">饮食习惯</span>
              <el-radio-group v-model="radio">
                <el-radio label="0">荤多</el-radio>
                <el-radio label="1">荤素均衡</el-radio>
                <el-radio label="2">素多</el-radio>
              </el-radio-group>
            </div>

            <div class="list list2">
              <span class="span-width">饮酒习惯</span>
              <el-radio-group v-model="radio">
                <el-radio label="0">偶尔</el-radio>
                <el-radio label="1">经常</el-radio>
                <el-radio label="2">从不</el-radio>
                <el-radio label="3">每天</el-radio>
              </el-radio-group>
            </div>

            <div class="list list2">
              <span class="span-width">日抽烟情况</span>
              <el-radio-group v-model="radio">
                <el-radio label="0">2包以上</el-radio>
                <el-radio label="1">5支</el-radio>
                <el-radio label="2">10支</el-radio>
                <el-radio label="3">2包</el-radio>
                <el-radio label="4">2支</el-radio>
                <el-radio label="5">无吸烟</el-radio>
                <el-radio label="6">1包</el-radio>
              </el-radio-group>
            </div>

            <div class="list list2">
              <span class="span-width">体育锻炼</span>
              <el-checkbox-group v-model="checkList">
                <el-checkbox label="0">健身</el-checkbox>
                <el-checkbox label="1">散步</el-checkbox>
                <el-checkbox label="2">无</el-checkbox>
                <el-checkbox label="3">慢跑</el-checkbox>
                <el-checkbox label="4">其他</el-checkbox>
                <el-checkbox label="5">游泳</el-checkbox>
                <el-checkbox label="6">门球</el-checkbox>
                <el-checkbox label="7">太极拳</el-checkbox>
              </el-checkbox-group>
            </div>

            <div class="list list2">
              <span class="span-width">日睡眠情况</span>
              <el-checkbox-group v-model="checkList">
                <el-checkbox label="0">3-5个小时</el-checkbox>
                <el-checkbox label="1">5-7个小时</el-checkbox>
                <el-checkbox label="2">7-9个小时</el-checkbox>
                <el-checkbox label="3">9-12个小时</el-checkbox>
              </el-checkbox-group>
            </div>

            <div class="list list2">
              <span class="span-width">是否长期用药</span>
              <el-radio-group v-model="radio">
                <el-radio label="0">是</el-radio>
                <el-radio label="1">否</el-radio>
              </el-radio-group>
            </div>

            <div class="list-title list1 list-title1 list-top">
              <span>用药情况</span>
              <div class="add" @click="openAddDrug">
                <span class="el-icon-plus"></span>
              </div>
            </div>
            <div class="table">
              <el-table :data="tableData" height="200" :header-cell-style="{ background: '#024276' }">
                <el-table-column prop="name" label="用药名"></el-table-column>
                <el-table-column label="每日用药量"></el-table-column>
                <el-table-column label="用药时间"></el-table-column>
                <el-table-column label="服药依从性"></el-table-column>
                <el-table-column label="操作">
                  <!-- slot-scope="scope" -->
                  <!-- <template>
                    <div class="flex">
                      <span>查看</span>
                      <span>视频通话</span>
                      <span>删除</span>
                      <span>修改</span>
                    </div>
                  </template> -->
                </el-table-column>
              </el-table>
            </div>
            <div class="pagination">
              <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="4"
                background :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper"
                :total="400">
              </el-pagination>
            </div>

            <div class="list-title list1 list-title1 list-top">
              <span>住院情况</span>
              <div class="add" @click="openAddHospital">
                <span class="el-icon-plus"></span>
              </div>
            </div>

            <div class="table">
              <el-table :data="tableData" height="200" :header-cell-style="{ background: '#024276' }">
                <el-table-column prop="name" label="最近第一次入院日期"></el-table-column>
                <el-table-column label="出院日期"></el-table-column>
                <el-table-column label="入院原因"></el-table-column>
                <el-table-column label="住院机构"></el-table-column>
                <el-table-column label="健康恢复情况"></el-table-column>
                <el-table-column label="操作">
                  <!-- slot-scope="scope" -->
                  <!-- <template>
                    <div class="flex">
                      <span>查看</span>
                      <span>视频通话</span>
                      <span>删除</span>
                      <span>修改</span>
                    </div>
                  </template> -->
                </el-table-column>
              </el-table>
            </div>
            <div class="pagination">
              <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="4"
                background :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper"
                :total="400">
              </el-pagination>
            </div>

          </template>
        </div>
      </div>
      <div class="btns">
        <el-button @click="close">取消</el-button>
        <el-button class="button" @click="submit">确定</el-button>
      </div>
    </div>
    <!-- 住院新增 -->
    <AddHospital v-if="showAddHospital" @close="closeAddHospital"></AddHospital>
    <!-- 用药新增 -->
    <AddDrug v-if="showAddDrug" @close="closeAddDrug"></AddDrug>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import AddHospital from './addHospital.vue'
import AddDrug from './addDrug.vue'
export default {
  components: {
    AddHospital,
    AddDrug
  },
  data () {
    return {
      tabIndex: 0,
      tabs: Object.freeze(['基本信息', '家属信息', '居住信息', '补助信息', '需求情况', '健康情况']),
      tableData: [{
        date: '2020-02-27 17:20',
        name: '2020031400',
        type: '1',
        value: false
      }, {
        date: '2020-02-27 17:20',
        name: '2020031400',
        type: '1',
        value: true
      }, {
        date: '2020-02-27 17:20',
        name: '2020031400',
        type: '1',
        value: true
      }, {
        date: '2020-02-27 17:20',
        name: '2020031400',
        type: '1',
        value: true
      }, {
        date: '2020-02-27 17:20',
        name: '2020031400',
        type: '1',
        value: true
      }, {
        date: '2020-02-27 17:20',
        name: '2020031400',
        type: '1',
        value: true
      }, {
        date: '2020-02-27 17:20',
        name: '2020031400',
        type: '1',
        value: true
      }, {
        date: '2020-02-27 17:20',
        name: '2020031400',
        type: '1',
        value: true
      }, {
        date: '2020-02-27 17:20',
        name: '2020031400',
        type: '1',
        value: true
      }, {
        date: '2020-02-27 17:20',
        name: '2020031400',
        type: '1',
        value: true
      }, {
        date: '2020-02-27 17:20',
        name: '2020031400',
        type: '1',
        value: true
      }],
      options: [{
        value: '1',
        label: '男'
      }, {
        value: '2',
        label: '女'
      }],
      xbs: [{
        value: '1',
        label: '男'
      }, {
        value: '2',
        label: '女'
      }],
      value: '',
      radio: '1',
      checkList: ['0'],
      imageUrl: '',
      showAddHospital: false,
      showAddDrug: false,
      params: {
        id: 0,
        czbh: '',
        lnrzsbh: '',
        name: '',
        age: '',
        lxfs: '',
        gddh: '',
        xb: '',
        csrq: '',
        hjdz: '',
        ssjgbh: '',
        ssjgmc: '',
        createtime: '',
        updatetime: '',
        rydjid: '',
        xzjdmcid: '',
        xzjdmc: '',
        cwhmcid: '',
        cwhmc: '',
        sfzh: '',
        sfzpic: '',
        sjhm: '',
        minz: '',
        hyzk: '',
        whcd: '',
        zhiye: '',
        xjdz: '',
        znsl: '',
        sfjz: '',
        zfjzlx: '',
        zyjjly: '',
        rylc: '',
        rylcid: '',
        ryfjid: '',
        ryfj: '',
        rycw: '',
        rycwid: '',
        ydrname: '',
        ydrdh: '',
        ylrgx: '',
        ydcwh: '',
        ydcwid: '',
        kssj: '',
        ydsc: '',
        djys: '',
        ydxylb: '11',
        ydzt: '',
        xzcwcwh: '',
        xzcwmc: '',
        xzcwcwdj: '',
        xzcwrzts: '',
        xzcwrzsj: '',
        xzcwjssj: '',
        cyhsfy: '',
        hlfwfy: '',
        hlfwlist: '',
        qtsfyj: '',
        qtsfycxshf: '',
        ryfyhdts: '',
        ddzfylb: '',
        ryfylb: '',
        scht: '',
        ydxy: '',
        hzzt: '',
        cysj: '',
        cyyy: '',
        cyczry: '',
        cyczryid: ''
      }
    }
  },
  computed: {
    ...mapState(['loginInfo'])
  },
  created () {
    console.log('选择信息：', this.$parent.selectedRow)
    const select = this.$parent.selectedRow
    for (const key in this.params) {
      this.params[key] = select[key]
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    },
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
    },
    handleAvatarSuccess (res, file) {
      this.imageUrl = URL.createObjectURL(file.raw)
    },
    beforeAvatarUpload (file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },
    openAddHospital () {
      this.showAddHospital = true
    },
    closeAddHospital () {
      this.showAddHospital = false
    },
    openAddDrug () {
      this.showAddDrug = true
    },
    closeAddDrug () {
      this.showAddDrug = false
    },
    async submit () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-111492378
      try {
        this.params.ssjgbh = this.$parent.loginInfo.jgbh
        console.log('ssjgbh  ' + this.$parent.loginInfo.jgbh)
        this.params.ssjgmc = this.$parent.loginInfo.jgmc
        console.log('请求参数', this.params)
        const { data: res } = await this.$http.post('/zhyyapi/ykdrydjadd', this.params)
        console.log('入院登记保存', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.$message.success('添加成功')
        this.close()
        this.$emit('close', 'loading')
      } catch (error) {
        console.log('入院登记保存失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    close () {
      this.$emit('close')
      this.$parent.closeAdd()
      this.$parent.getList()
    }
  }
}
</script>

<style lang="less" scoped>
.write {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  padding: 0 100px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, .9);

  .title {
    width: 160px;
    height: 40px;
    padding-top: 5px;
    box-sizing: border-box;
    line-height: 40px;
    text-align: center;
    background-image: url('../../../img/ssjk/title.png');
    background-size: 100% 100%;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
  }

  .content {
    position: relative;
    width: 100%;
    min-width: 800px;
    height: 750px;
    margin-top: 10px;
    padding: 50px;
    padding-top: 70px;
    background-image: url('../../../img/ssjk/background4.png');
    background-size: 100% 100%;
    box-sizing: border-box;

    .close {
      position: absolute;
      top: 30px;
      right: 30px;
      color: #fff;
      font-size: 26px;
      cursor: pointer;
    }

    .content-title {
      margin-top: 20px;
      color: #01EEFD;
      font-size: 18px;
    }

    .box {
      display: flex;
      width: 100%;
      height: calc(100% - 70px);

      .box-tab {
        display: flex;
        flex-direction: column;
        width: 80px;
        height: 100%;

        .tab-item {
          width: 100%;
          height: 40px;
          line-height: 40px;
          margin-bottom: 20px;
          text-align: center;
          background-color: #737373;
          color: #fff;
          font-size: 14px;
          cursor: pointer;
        }

        .tab-active {
          background: linear-gradient(103deg, #016AB9 0%, #02477D 100%);
        }
      }

      .box-scroll {
        overflow-y: scroll;
        width: calc(100% - 104px);
        height: 100%;
        margin-left: 24px;

        &::-webkit-scrollbar {
          width: 0;
        }

        .list-title {
          height: 50px;
          line-height: 50px;
          color: #01EEFD;
          font-size: 18px;
        }

        .list-top {
          border-top: 1px solid #01EEFD;
          box-sizing: border-box;
        }

        .list {
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          color: #01EEFD;
          font-size: 18px;

          .span-width {
            width: 120px;
          }

          .list-item {
            flex: 1;
            display: flex;
            align-items: center;
            margin-right: 20px;

            span {
              white-space: nowrap;
            }
          }

          .list-item1 {
            flex: 2;
          }

          .list-item2 {
            flex: 3;
          }

          .el-date-picker {
            width: 166px;
          }

          .span {
            width: 76px;
            white-space: pre-wrap;
          }

          .el-checkbox-group,
          .el-radio-group {
            margin-top: 4px;
            margin-left: 19px;
          }

          .el-textarea {
            width: 80%;
            margin-left: 19px;
          }

          /deep/.el-textarea__inner {
            border-radius: 0;
            resize: none;
          }

          .el-select {
            width: 166px;
            margin-left: 10px;
          }

          .el-input {
            width: 166px;
            margin-left: 10px;
          }

          .el-input-number {
            width: 166px;
            margin-left: 10px;
          }

          .width {
            width: 243px;
          }

          .width1 {
            width: calc(100% - 120px; )
          }

        }

        .list1 {
          padding-left: 9px;
          box-sizing: border-box;
        }

        .list2 {
          padding-left: 19px;
          box-sizing: border-box;
        }

        .list-title1 {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 78px;
          margin-top: 30px;

          .add {
            width: 30px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            background-color: #04B4D0;
            border-radius: 50%;
            color: #fff;
            cursor: pointer;
          }
        }
      }
    }

    .btns {
      position: absolute;
      bottom: 70px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 240px;
      height: 46px;
      margin: 0 auto;

      .el-button {
        height: 30px;
        line-height: 30px;
        padding: 0 10px;
        text-align: center;
        color: #fff;
        font-size: 14px;
        background-color: #33465C;
        border-radius: 0;
        border: none;
      }

      .button {
        background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
      }
    }
  }

  /deep/.el-radio__input.is-checked .el-radio__inner,
  /deep/.el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: #01EEFD;
    border-color: #01EEFD;
  }

  .el-radio,
  .el-checkbox {
    color: #fff;
  }

  /deep/.el-radio__input.is-checked+.el-radio__label,
  /deep/.el-checkbox__input.is-checked+.el-checkbox__label {
    color: #01EEFD;
  }

  .table {
    width: 99%;
    margin-top: 10px;
    border: 4px #073F73 solid;
  }

  ::v-deep .el-table__body-wrapper {
    &::-webkit-scrollbar {
      // 整个滚动条
      width: 0px; // 纵向滚动条的宽度
    }
  }

  ::v-deep .el-table th.gutter {
    display: none;
    width: 0;
  }

  ::v-deep .el-table colgroup col[name='gutter'] {
    display: none;
    width: 0;
  }

  ::v-deep .el-table__body {
    width: 100% !important;
  }

  /deep/.el-table__header-wrapper {
    thead {
      th {
        div {
          color: #01EEFD;
          font-size: 17px;
          font-weight: 400;
        }
      }
    }
  }

  /deep/.el-table__body tbody tr {
    color: #fff;
    font-size: 16px;
    background-color: #092B50;
  }

  /deep/.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
    background-color: #092B50;
  }

  /deep/.el-table tbody tr:hover>td {
    background-color: transparent !important
  }

  .el-table--border,
  .el-table--group {
    border: 2px solid #ddd !important;
  }

  .el-table--border td,
  .el-table--border th,
  .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
    border-right: 1px solid #ddd !important;
  }

  /deep/.el-table td {
    border-bottom: 1px solid #01EEFD;
  }

  /deep/.el-table thead tr th {
    border-color: transparent;
  }

  /deep/ .el-table--border::after,
  /deep/ .el-table--group::after,
  /deep/ .el-table::before {
    background-color: #073F73;
  }

  /deep/.el-table--border {
    border: 1px solid #073F73 !important;
  }

  /deep/.el-table .cell {
    text-align: center;
    color: #01EEFD;
  }

  /deep/.el-table th.gutter {
    display: none;
    width: 0px;
  }

  /deep/.el-table colgroup col[name='gutter'] {
    display: none;
    width: 0;
  }

  /deep/ .el-checkbox__inner {
    background-color: transparent;
  }

  /deep/ .el-table__body-wrapper {
    background-color: #092B50;
  }

  .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-size: 16px;

    span {
      color: #0077F9;
      cursor: pointer;
      letter-spacing: 0;
    }

    .red {
      color: #FF0000;
    }

    //按钮文字显示
    .el-switch {
      width: 70px !important;
      height: 25px !important;
    }

    ::v-deep .el-switch__core {
      width: 100% !important;
      height: 24px;
      border-radius: 100px;
      border: none;
    }

    ::v-deep .el-switch__core::after {
      width: 20px;
      height: 20px;
      top: 2px;
    }

    ::v-deep .el-switch.is-checked .el-switch__core::after {
      margin-left: -21px;
    }

    /*关闭时文字位置设置*/
    ::v-deep .el-switch__label--right {
      position: absolute;
      z-index: 1;
      right: 6px;
      margin-left: 0px;
      color: rgba(255, 255, 255, 0.9019607843137255);

      span {
        font-size: 12px;
      }
    }

    /* 激活时另一个文字消失 */
    ::v-deep .el-switch__label.is-active {
      display: none;
    }

    /*开启时文字位置设置*/
    ::v-deep .el-switch__label--left {
      position: absolute;
      z-index: 1;
      left: 5px;
      margin-right: 0px;
      color: rgba(255, 255, 255, 0.9019607843137255);

      span {
        font-size: 12px;
      }
    }

  }

  .flex1 {
    justify-content: center;
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 10px;

    ::v-deep.el-pagination__total,
    ::v-deep.el-pagination__jump {
      color: #01EEFD;

      .el-input__inner {
        color: #fff;
        background-color: transparent;
      }
    }

    ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: #01EEFD;
    }
  }

  ::v-deep.avatar-uploader .el-upload {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 166px;
    height: 73px;
    border: 1px solid #FFFFFF;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    margin-left: 10px;
  }

  .el-upload {}

  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }

  .avatar-uploader-icon {
    margin-right: 4px;
    font-size: 20px;
    color: #fff;
    text-align: center;
  }

  .avatar {
    width: 166px;
    height: 73px;
    display: block;
  }

  .text {
    display: flex;
    align-items: center;
    color: #FFFFFF;
    font-size: 12px;
  }
}
</style>
