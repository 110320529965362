<template>
  <div class="set">
    <div class="set-calendar">
      <div class="title">
        <div class="key">
          <span>{{ title }}</span>
        </div>
        <div class="close" @click="close">
          <span class="el-icon-close"></span>
        </div>
      </div>
      <Calendar></Calendar>
      <div class="box">
        <div class="text">
          <span>号源数量：</span>
          <el-input class="input" v-model="total" placeholder="请输入"></el-input>
        </div>
        <div class="total">
          <span>开放状态：</span>
          <el-switch
            v-model="status"
            active-text="开放"
            inactive-text="关闭">
          </el-switch>
        </div>

        <el-button type="primary" @click="submit">设置</el-button>
      </div>
    </div>
    <Select v-if="showSelect" @close="closeSelect"></Select>
  </div>
</template>

<script>
import Select from './select.vue'
import Calendar from './calendar/calendar.vue'
export default {
  components: {
    Select,
    Calendar
  },
  data () {
    return {
      date: new Date(),
      value: '',
      num: 1,
      showSelect: false,
      title: '',
      year: '',
      selectDate: '',
      status: false,
      total: ''
    }
  },
  created () {
    this.getDays()
  },
  methods: {
    getDays () {
      this.title = '设置-' + this.$parent.selectTitle.tjtcmc
      const now = new Date()
      this.year = now.getFullYear()
      var month = now.getMonth() + 1 > 9 ? now.getMonth() + 1 : '0' + (now.getMonth() + 1)
      var strDate = now.getDate() > 9 ? now.getDate() : '0' + now.getDate()
      this.selectDate = this.year + '-' + month + '-' + strDate
    },
    getWeekDay (year, month, day) {
      const date = new Date(year, month - 1, day)
      const weekDay = date.getDay()
      return weekDay
    },
    async submit () {
      try {
        // 接口地址 https://app.apifox.com/link/project/2726972/apis/api-200053823
        const { data: res } = await this.$http.post('/zhctapi/Uploadzhctjl', {
          jgbh: this.$store.state.loginInfo.jgbh || '',
          yysj: this.selectDate,
          yysl: this.total,
          ssxxid: '',
          type: 'xstj',
          amks: '',
          amjs: '',
          pmks: '',
          pmjs: '',
          amsl: '',
          pmsl: '',
          yyxeid: '',
          tcmc: this.$parent.selectTitle.tjtcmc || '',
          tcmcid: this.$parent.selectTitle.tjtcbh || ''
        })
        console.log('设置 ', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.$message.success('保存成功')
        this.$emit('close', 'loading')
      } catch (error) {
        console.log('医生简介保存 请求失败', error)
        // this.$message.error('系统异常，请稍后重试')
      }
    },
    openSelect () {
      this.showSelect = true
    },
    closeSelect () {
      this.showSelect = false
    },
    close () {
      this.$emit('close')
    },
    handleChange (value) {
      console.log(value)
    },
    dataChange (e) {
      console.log(e)
    },
    select (val) {
      console.log(val)
      this.showSelect = true
      this.selectDate = this.year + '-' + val
    }
  }
}
</script>

<style lang="less" scoped>
.set {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 8;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .7);

  .set-calendar {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 85%;
    padding: 0 41px;
    background-color: #fff;
    box-sizing: border-box;
  }

  .title {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 50px;

    .key {
      color: #333333;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 2px;
    }

    .close {
      color: #707070;
      font-size: 24px;
      cursor: pointer;
    }
  }

  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: 200px;

    .text {
      white-space: nowrap;
      color: #333333;
      font-size: 16px;
    }

    .el-input-number {
      margin-left: 11px;
    }

    .el-button {
      padding: 4px 28px;
      border: none;
      font-size: 30px;
    }
  }

  .total {
    display: flex;
    align-items: center;
  }
}
</style>
