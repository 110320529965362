<template>
  <div class="page-box">
    <div class="tab">
      <div class="item">审核设置</div>
    </div>
    <div class="tab-box">
      <div class="run">

        <el-table :data="tableData" height="100%" border>
          <el-table-column label="序号" type="index" width="100">
          </el-table-column>
          <el-table-column prop="name" label="审批流名称">
          </el-table-column>
          <el-table-column prop="address" label="更新日期">
          </el-table-column>
          <el-table-column prop="address" label="联系方式">
          </el-table-column>
          <el-table-column prop="address" label="身份证号">
          </el-table-column>
          <el-table-column prop="address" label="薪资合计">
          </el-table-column>
          <el-table-column prop="address" label="待结算薪资">
          </el-table-column>
          <el-table-column label="操作">
            <template>
              <!-- <span @click="openCareful">配置审批流</span> -->
              <el-button size="mini" type="primary" @click="openCareful">配置审批流</el-button>
            </template>
          </el-table-column>
        </el-table>

      </div>
      <div class="pagination">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage4"
          :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper" :total="400" background>
        </el-pagination>
      </div>

    </div>
    <Careful v-if="showCareful" @close="closeCareful"></Careful>
  </div>
</template>

<script>
import Careful from './careful.vue'
export default {
  components: {
    Careful
  },
  data () {
    return {
      tableData: [{
        date: '2016-05-02',
        name: '王小虎',
        address: '等待外出'
      }, {
        date: '2016-05-04',
        name: '王小虎',
        address: '未返回'
      }, {
        date: '2016-05-01',
        name: '王小虎',
        address: '逾期未返回'
      }],
      options: [{
        value: '',
        label: ''
      }],
      value: '',
      currentPage4: 4,
      tabIndex: 0,
      selectIndex: 0,
      showAdd: false,
      showCareful: false,
      showSee: false
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    },
    openAdd () {
      this.showAdd = true
    },
    closeAdd () {
      this.showAdd = false
    },
    openCareful () {
      this.showCareful = true
    },
    closeCareful () {
      this.showCareful = false
    },
    openSee () {
      this.showSee = true
    },
    closeSee () {
      this.showSee = false
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.page-box {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  background-color: #fff;

  .input {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 246px;

    /deep/.el-input__inner {
      border: none;
      background: linear-gradient(153deg, #0A477B 0%, #0E67AD 100%);
      color: #fff;
      font-size: 18px;
    }

    /deep/.el-input__inner::-webkit-input-placeholder {
      color: #fff;
    }

    /deep/.el-input__suffix {
      color: #fff;
      font-size: 18px;
    }
  }

  .tab {
    display: flex;
    align-items: center;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    .item {
      height: 38px;
      line-height: 38px;
      text-align: center;
      margin-left: 30px;
      padding: 0 10px;
      background-color: #409EFF;
      color: #fff;
      font-size: 22px;
      cursor: pointer;

      &:first-child {
        margin-left: 0px;
      }
    }

    .active {
      background-color: #409EFF;
    }
  }

  .tab-box {
    width: 100%;
    height: calc(100% - 78px);
  }

}

.run {
  width: 100%;
  height: calc(100% - 140px);

  .son-tab {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    .son-item {
      height: 38px;
      line-height: 38px;
      margin-right: 40px;
      padding: 0 20px;
      text-align: center;
      background-color: #284867;
      color: #fff;
      font-size: 16px;
      cursor: pointer;
    }

    .son-active {
      background-color: #04B4D0;
    }
  }

  .son-tab1 {
    border: none;
  }

  .son-add {
    display: flex;
    align-items: center;
    height: 50px;

    .son-add-icon {
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      background-color: #04B4D0;
      border-radius: 50%;
      color: #fff;
      font-size: 16px;
      cursor: pointer;
    }
  }

  /deep/.el-table .cell {
    text-align: center;
    color: #000;
  }
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
}
</style>
