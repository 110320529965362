<template>
  <div class="update">
    <div class="update-top">
      <span class="update-text">新增行政查房</span>
      <el-input placeholder="姓名/身份证号" suffix-icon="el-icon-search" class="update-input"></el-input>
    </div>
    <div class="update-scroll">
      <div class="scroll-title">
        <span>老人信息</span>
      </div>
      <div class="scroll-line">
        <div class="scroll-line-key">
          <span>情况概要</span>
        </div>
        <div class="scroll-line-value">
          <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida
            dolor sit amet lacus accumsan et viverra justo commodo. Proin
            sodales pulvinar tempor. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.
            Nam fermentum, nulla luctus pharetra vulputate, felis tellus
            mollis orci, sed rhoncus sapien nunc eget odio. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            Aenean euismod bibendum laoreet. Proin gravida dolor sit</span>
        </div>
      </div>
      <div class="scroll-line">
        <div class="scroll-line-key">
          <span>处置意见</span>
        </div>
        <div class="scroll-line-value">
          <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida
            dolor sit amet lacus accumsan et viverra justo commodo. Proin
            sodales pulvinar tempor. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.
            Nam fermentum, nulla luctus pharetra vulputate, felis tellus
            mollis orci, sed rhoncus sapien nunc eget odio. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            Aenean euismod bibendum laoreet. Proin gravida dolor sit</span>
        </div>
      </div>
      <div class="scroll-title">
        <span>医疗护理</span>
      </div>
      <div class="scroll-line">
        <div class="scroll-line-key">
          <span>情况概要</span>
        </div>
        <div class="scroll-line-value">
          <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida
            dolor sit amet lacus accumsan et viverra justo commodo. Proin
            sodales pulvinar tempor. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.
            Nam fermentum, nulla luctus pharetra vulputate, felis tellus
            mollis orci, sed rhoncus sapien nunc eget odio. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            Aenean euismod bibendum laoreet. Proin gravida dolor sit</span>
        </div>
      </div>
      <div class="scroll-line">
        <div class="scroll-line-key">
          <span>处置意见</span>
        </div>
        <div class="scroll-line-value">
          <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida
            dolor sit amet lacus accumsan et viverra justo commodo. Proin
            sodales pulvinar tempor. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.
            Nam fermentum, nulla luctus pharetra vulputate, felis tellus
            mollis orci, sed rhoncus sapien nunc eget odio. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            Aenean euismod bibendum laoreet. Proin gravida dolor sit</span>
        </div>
      </div>
      <div class="scroll-title">
        <span>后勤保障</span>
      </div>
      <div class="scroll-line">
        <div class="scroll-line-key">
          <span>情况概要</span>
        </div>
        <div class="scroll-line-value">
          <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida
            dolor sit amet lacus accumsan et viverra justo commodo. Proin
            sodales pulvinar tempor. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.
            Nam fermentum, nulla luctus pharetra vulputate, felis tellus
            mollis orci, sed rhoncus sapien nunc eget odio. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            Aenean euismod bibendum laoreet. Proin gravida dolor sit</span>
        </div>
      </div>
      <div class="scroll-line">
        <div class="scroll-line-key">
          <span>处置意见</span>
        </div>
        <div class="scroll-line-value">
          <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida
            dolor sit amet lacus accumsan et viverra justo commodo. Proin
            sodales pulvinar tempor. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.
            Nam fermentum, nulla luctus pharetra vulputate, felis tellus
            mollis orci, sed rhoncus sapien nunc eget odio. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            Aenean euismod bibendum laoreet. Proin gravida dolor sit</span>
        </div>
      </div>
      <div class="scroll-title">
        <span>安全隐患</span>
      </div>
      <div class="scroll-line">
        <div class="scroll-line-key">
          <span>情况概要</span>
        </div>
        <div class="scroll-line-value">
          <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida
            dolor sit amet lacus accumsan et viverra justo commodo. Proin
            sodales pulvinar tempor. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.
            Nam fermentum, nulla luctus pharetra vulputate, felis tellus
            mollis orci, sed rhoncus sapien nunc eget odio. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            Aenean euismod bibendum laoreet. Proin gravida dolor sit</span>
        </div>
      </div>
      <div class="scroll-line">
        <div class="scroll-line-key">
          <span>处置意见</span>
        </div>
        <div class="scroll-line-value">
          <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida
            dolor sit amet lacus accumsan et viverra justo commodo. Proin
            sodales pulvinar tempor. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.
            Nam fermentum, nulla luctus pharetra vulputate, felis tellus
            mollis orci, sed rhoncus sapien nunc eget odio. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            Aenean euismod bibendum laoreet. Proin gravida dolor sit</span>
        </div>
      </div>
      <div class="scroll-title">
        <span> 意见或建议</span>
      </div>
      <div class="scroll-line">
        <div class="scroll-line-key scroll-line-key1">
          <span>意见或建议</span>
        </div>
        <div class="scroll-line-value scroll-line-value1">
          <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida
            dolor sit amet lacus accumsan et viverra justo commodo. Proin
            sodales pulvinar tempor. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.
            Nam fermentum, nulla luctus pharetra vulputate, felis tellus
            mollis orci, sed rhoncus sapien nunc eget odio. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            Aenean euismod bibendum laoreet. Proin gravida dolor sit</span>
        </div>
      </div>
    </div>
    <div class="update-btns">
      <el-button size="small" @click="close">返回</el-button>
      <el-button size="small" class="blue" @click="close">确定</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {}
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.update {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0 40px;
  background-color: #081C38;
  box-sizing: border-box;

  .update-top {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 60px;
    padding: 0 10px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;

    .update-text {
      color: #01EEFD;
      font-size: 20px;
    }

    .update-input {
      width: 246px;

      /deep/.el-input__inner {
        border: none;
        background: linear-gradient(153deg, #0A477B 0%, #0E67AD 100%);
        color: #fff;
        font-size: 18px;
      }

      /deep/.el-input__inner::-webkit-input-placeholder {
        color: #fff;
      }

      /deep/.el-input__suffix {
        color: #fff;
        font-size: 18px;
      }
    }
  }

  .update-scroll {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 120px);

    &::-webkit-scrollbar {
      width: 0;
    }

    .scroll-title {
      height: 40px;
      line-height: 40px;
      margin-top: 20px;
      border-bottom: 1px solid #01EEFD;
      box-sizing: border-box;
      color: #01EEFD;
      font-size: 20px;
    }

    .scroll-line {
      display: flex;
      flex-direction: row;
      margin-top: 20px;

      .scroll-line-key {
        width: 100px;
        color: #01EEFD;
        font-size: 20px;
      }

      .scroll-line-key1 {
        width: 120px;
      }

      .scroll-line-value {
        width: calc(100% - 100px);
        color: #01EEFD;
        font-size: 16px;
      }

      .scroll-line-value1 {
        width: calc(100% - 120px);
        color: #01EEFD;
        font-size: 16px;
      }
    }
  }

  .update-btns {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 50px;
    padding-left: 30px;
    box-sizing: border-box;

    .el-button {
      margin-right: 40px;
      background-color: #33465C;
      border: 0;
      color: #fff;
    }

    .blue {
      background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
    }
  }
}</style>
