<template>
  <div class="son"
    :class="{ son1: showSee }"
    v-loading="loading"
    element-loading-text="获取数据中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div class="son-head">
      <div class="son-head-item">
        <div class="son-head-left">
          <div class="son-head-open"></div>
          <el-input placeholder="请输入搜索内容"></el-input>
        </div>
        <div class="son-head-center">
          <span>切换机构：</span>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="son-head-right">
          <span class="el-icon-success success"></span>
          <div class="div">
            <div class="div1">
              <div class="blue"></div>
            </div>
          </div>
          <span class="el-icon-lock lock"></span>
          <span class="el-icon-view lock"></span>
          <span class="el-icon-rank rank"></span>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <!-- <div class="son-head-item">
        <div class="son-head-title">
          <span>查体管理</span>
        </div>
        <el-button type="primary">
          <span>更多</span>
          <span class="el-icon-arrow-down icon"></span>
        </el-button>
      </div> -->
    </div>
    <div class="son-scroll">
      <div class="son-search">
        <div class="search-item">
          <div class="key">
            <span>学院</span>
          </div>
          <el-select v-model="params.ssxxid" placeholder="" @change="xxChange">
            <el-option v-for="item in xxs" :key="item.ssxxid" :label="item.ssxx" :value="item.ssxxid">
            </el-option>
          </el-select>
        </div>
        <div class="search-item">
          <div class="key">
            <span>年级</span>
          </div>
          <el-select v-model="params.ssnj" placeholder="请选择" @change="njChange">
            <el-option v-for="item in njs" :key="item.k" :label="item.val" :value="item.val">
            </el-option>
          </el-select>
        </div>
        <div class="search-item">
          <div class="key">
            <span>班级</span>
          </div>
          <el-select v-model="params.ssbj" placeholder="请选择" @change="bjChange">
            <el-option v-for="item in bjs" :key="item.k" :label="item.val" :value="item.val">
            </el-option>
          </el-select>
        </div>
        <div class="search-item">
          <div class="key">
            <span>体检项目</span>
          </div>
          <el-select v-model="params.tcmc" placeholder="请选择" @change="testChange">
            <el-option v-for="item in tests" :key="item.id" :label="item.tclbmc" :value="item.tclbbm">
            </el-option>
          </el-select>
        </div>
        <div class="search-item">
          <div class="key">
            <span>套餐</span>
          </div>
          <el-select v-model="params.tcmc2" placeholder="请选择">
            <el-option v-for="item in tcmc2s" :key="item.id" :label="item.tcmc" :value="item.id">
            </el-option>
          </el-select>
        </div>
        <div class="search-item">
          <div class="key">
            <span>筛选</span>
          </div>
          <el-input placeholder="请输入" v-model="params.sxtj"></el-input>
        </div>
        <div class="search-item">
          <div class="key">
            <span>体检</span>
          </div>
          <el-select v-model="params.tmdy" placeholder="请选择">
            <el-option v-for="item in tmdys" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="search-item">
          <div class="key">
            <span>核查状态</span>
          </div>
          <el-select v-model="params.hcflag" placeholder="请选择">
            <el-option v-for="item in hczts" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="search-item">
          <div class="key">
            <span>缺项漏项</span>
          </div>
          <el-select v-model="params.qxlx" placeholder="请选择">
            <el-option v-for="item in qxlxs" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="search-item">
          <div class="key">
            <span>状态</span>
          </div>
          <el-select v-model="params.sftj" placeholder="请选择">
            <el-option v-for="item in zts" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>

        <div class="search-item">
          <div class="key">
            <span>体检日期</span>
          </div>
          <el-date-picker v-model="params.tjrq" value-format="yyyy-MM-dd" type="daterange" range-separator="至" start-placeholder="开始日期"
            end-placeholder="结束日期" @change="testDateChange">
          </el-date-picker>
        </div>
        <div class="search-item">
          <div class="key">
            <span>核查日期</span>
          </div>
          <el-date-picker v-model="params.hcrq" type="daterange" range-separator="至" start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </div>
        <div class="search-item">
          <div class="key">
            <span>体检类型</span>
          </div>
          <el-select v-model="params.tjlx" placeholder="请选择" @change="testChange">
            <el-option v-for="item in tests" :key="item.id" :label="item.tclbmc" :value="item.tclbbm">
            </el-option>
          </el-select>
        </div>
        <div class="search-item">
          <div class="key">
            <span>进度</span>
          </div>
          <el-select v-model="params.tjjdid" placeholder="请选择" @change="jdChange">
            <el-option v-for="item in jds" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="search-item">
          <div class="key">
            <span>条码</span>
          </div>
          <el-select v-model="params.tmsfdy" placeholder="请选择" @change="tmChange">
            <el-option v-for="item in tms" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="button">
          <el-button @click="getInfo">
            <span class="el-icon-search"></span>
            <span>查询</span>
          </el-button>
          <el-button @click="clean">
            <span class="el-icon-refresh"></span>
            <span>重置</span>
          </el-button>
          <el-button @click="exportClick">
            <span class="el-icon-upload"></span>
            <span>导出</span>
          </el-button>
          <el-button @click="openAdd">
            <span class="el-icon-plus"></span>
            <span>新增</span>
          </el-button>
          <el-button @click="printTmcode">
            <span>批量打印条码</span>
          </el-button>
          <el-button @click="printTest">
            <span>批量打印报告</span>
          </el-button>
          <el-button @click="exportAllClick">
            <span>批量导出</span>
          </el-button>
          <el-button @click="showList = true">
            <span>导出列表</span>
          </el-button>
        </div>
      </div>

      <div class="son-info">
        <div class="son-info-title">
          <div class="info-item info-item5">
            <span>序号</span>
          </div>
          <div class="info-item info-item1">
            <span>机构</span>
          </div>
          <div class="info-item info-item2">
            <span>套餐</span>
          </div>
          <div class="info-item info-item1">
            <span>体检编号</span>
          </div>
          <div class="info-item">
            <span>姓名</span>
          </div>
          <div class="info-item info-item6">
            <span>证件号码</span>
          </div>
          <div class="info-item info-item3">
            <span>检查日期</span>
          </div>
          <div class="info-item info-item3">
            <span>核查日期</span>
          </div>
          <div class="info-item info-item7">
            <span>状态</span>
          </div>
          <div class="info-item info-item1">
            <span>所属学校</span>
          </div>
          <div class="info-item">
            <span>年级</span>
          </div>
          <div class="info-item">
            <span>班</span>
          </div>
          <div class="info-item info-item4">
            <span>操作</span>
          </div>
        </div>
        <div class="son-info-scroll">
          <div class="scroll-list" v-for="(item, index) in tableData" :key="index">
            <div class="info-item info-item5">
              <span>{{ item.id || '-' }}</span>
            </div>
            <div class="info-item info-item1">
              <span></span>
            </div>
            <div class="info-item info-item2">
              <span></span>
            </div>
            <div class="info-item info-item1">
              <span>{{ item.tmcode || '-' }}</span>
            </div>
            <div class="info-item">
              <span>{{ item.name || '-' }}</span>
            </div>
            <div class="info-item info-item6">
              <span>{{ item.sfzh || '-' }}</span>
            </div>
            <div class="info-item info-item3">
              <span>{{ item.tjrq || '-' }}</span>
            </div>
            <div class="info-item info-item3">
              <span></span>
            </div>
            <div class="info-item info-item7">
              <span class="green"></span>
            </div>
            <div class="info-item info-item1">
              <span>{{ item.ssxx || '-' }}</span>
            </div>
            <div class="info-item">
              <span>{{ item.ssnj || '-' }}</span>
            </div>
            <div class="info-item">
              <span>{{ item.ssbj || '-' }}</span>
            </div>

            <div class="info-item info-item4">
              <span class="span" @click="openSchedule(item)">进度</span>
              <span class="span" @click="openSee(item)">查看</span>
              <span class="span" @click="openReport(item)">报告</span>
              <el-popover popper-class="popper" placement="bottom-start" width="86" trigger="click">
                <div class="popover" :style="style">
                  <div class="popover-item" :style="style1">
                    <span>打印条码</span>
                  </div>
                  <div class="popover-item" :style="style1">
                    <span>删除</span>
                  </div>
                </div>
                <span class="span" slot="reference">
                  <span>更多</span>
                  <span class="el-icon-caret-bottom"></span>
                </span>
              </el-popover>

            </div>
          </div>
        </div>
        <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="query.pageNum"
      background
      :page-size="query.pageSize"
      layout="total, prev, pager, next, jumper"
      :total="query.total"></el-pagination>
      </div>
    </div>
    <el-dialog title="批量打印" width="50%" :visible.sync="printResult" @close="close">
        <el-form>
          <el-row :span="24">
            <el-form-item label="打印内容">
              <el-checkbox-group v-model="checkList">
                <el-checkbox v-for="item in testInfo" :key="item.value" :disabled="item.disabled" :label="item.value">{{ item.label + " : " + item.key }}</el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button icon="el-icon-upload" size="small" type="primary" @click="checkPrintTest">批量打印</el-button>
          <el-button size="small" type="primary" @click="close">取消</el-button>
        </div>
      </el-dialog>
    <Add v-if="showAdd" @close="closeAdd"></Add>
    <Schedule v-if="showSchedule" @close="closeSchedule"></Schedule>
    <See v-if="showSee" @close="closeSee"></See>
    <Report v-if="showReport" @close="closeReport"></Report>
    <List v-if="showList"></List>
    <Popup v-if="showPopup"></Popup>
  </div>
</template>

<script>
import { getLodop } from '../../../../../utils/lodopFuns.js'
import axios from 'axios'
import { mapState } from 'vuex'
import Add from './add.vue'
import Schedule from './schedule.vue'
import See from './see/see.vue'
import Report from './report/report.vue'
import List from './list.vue'
import Popup from './popup.vue'
export default {
  components: {
    Add,
    Schedule,
    See,
    Report,
    List,
    Popup
  },
  data () {
    return {
      options: [],
      xxs: [],
      ssxx: '',
      tests: [],
      tcmc: '',
      tcmc2: '',
      testTypes: [
        { label: '学生体检', value: '01' }
      ],
      tjlx: '',
      bjs: [],
      bj: '',
      ssbjid: '',
      njs: [],
      nj: '',
      ssnjid: '',
      jds: [
        { label: '正常检查', value: '0' },
        { label: '尚未检查', value: '1' },
        { label: '无需检查', value: '2' },
        { label: '自愿放弃', value: '3' }
      ],
      jd: '',
      tjjdid: '',
      zts: [
        { label: '未提交', value: '0' },
        { label: '体检', value: '1' }
      ],
      sftj: '',
      drs: [
        { label: '是', value: '0' },
        { label: '否', value: '1' }
      ],
      dr: '',
      tms: [
        { label: '未打印', value: '0' },
        { label: '打印', value: '1' }
      ],
      tm: '',
      tmdy: '',
      tcmc2s: [],
      qxlxs: [
        { label: '是', value: '0' },
        { label: '否', value: '1' }
      ],
      hczts: [
        { label: '异常', value: '0' },
        { label: '无异常', value: '1' }
      ],
      tmdys: [{ label: '未体检', value: '0' },
        { label: '已体检', value: '1' }],
      params: {
        start: '0',
        length: '10',
        tjjgbh: '',
        sxtj: '',
        ssxxid: '',
        ssnjid: '',
        tcmcid: '',
        tjlxid: '',
        tjjd: '',
        tmsfdy: '',
        drflag: '',
        begtjsj: '',
        endtjsj: '',
        sftj: '',
        tjjdid: '',
        hcrq: '',
        tcmc2: '',
        tjrq: '',
        qxlx: '',
        tjlx: '',
        ssnj: '',
        ssbj: '',
        hcflag: ''
      },
      // 分页数据
      query: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      value: '',
      value1: '',
      value2: '',
      value3: '',
      total: 400,
      currentPage: 0,
      showSet: false,
      style: {
        width: '100%',
        height: '66px'
      },
      style1: {
        width: '100%',
        height: '31px',
        'line-height': '31px',
        'text-align': 'center',
        'border-bottom': '1px solid #D9D9D9',
        color: '#333333',
        'font-size': '14px',
        cursor: 'pointer'
      },
      style2: {
        width: '100%',
        height: '31px',
        'line-height': '31px',
        'text-align': 'center',
        color: '#333333',
        'font-size': '14px',
        cursor: 'pointer'
      },
      tableData: [
        // { jgbh: '150602000000', name: '李明明', type: '100', jgjb: '5', sfzh: '130434195212306931', tmcode: '725000530', pwd: '123' }
      ],
      showAdd: false,
      showSchedule: false,
      showSee: false,
      showReport: false,
      selectInfo: null,
      loading: false,
      printResult: false,
      showList: false,
      checkList: [],
      testInfo: [
        { label: '全部', value: 'all', key: '' },
        { label: '血常规缺失', value: 'xcgyc', key: '' },
        { label: '心电图缺失', value: 'xdtyc', key: '' },
        { label: 'B超缺失', value: 'bcyc', key: '' },
        { label: '尿常规缺失', value: 'ncgyc', key: '' },
        { label: '一般状况缺失', value: 'ybzkyc', key: '' }
      ],
      testData: null,
      isPrint: true,
      CancelToken: null,
      Source: null,
      LODOP: null,
      percentage: 0,
      showPopup: false,
      picurl: ''
    }
  },
  computed: {
    ...mapState(['loginInfo'])
  },
  created () {
    this.CancelToken = axios.CancelToken
    this.Source = this.CancelToken.source()
    this.params.tjjgbh = this.loginInfo.jgbh || ''
    this.params.jgbh = this.loginInfo.jgbh || ''
    this.getInfo()
    this.getBjInfo()
    this.getXxInfo()
    this.getTestInfoTwo()
    this.getNjInfo()
    this.getTestInfo()
  },
  methods: {
    async getXxInfo () {
      // 接口文档 https://app.apifox.com/project/2726972/apis/api-160594030
      try {
        const params = {
          start: '0',
          length: '10',
          tjjgbh: this.loginInfo.jgbh || ''
        }
        const { data: res } = await this.$http.post('/zhctapi/findssxx', params)
        console.log('根据机构获取学校列表', res)
        if (res.code !== 0 || res.data === null) return
        this.xxs = res.data
        // this.xxs = [
        //   { ssxx: 'dd', ssxxid: '123' }
        // ]
      } catch (err) {
        console.log('根据机构获取学校列表', err)
      }
    },
    xxChange (e) {
      const arr = this.xxs.filter(item => item.ssxxid === this.params.ssxxid)
      this.ssxx = arr[0].ssxx
      console.log(e, this.params.ssxxid, this.ssxx)
    },
    async getBjInfo () {
      // 接口文档 https://app.apifox.com/project/2726972/apis/api-118011488
      try {
        const params = {
          type: 'ssbj'
        }
        const { data: res } = await this.$http.post('/api/jkct/app/finddict', params)
        console.log('班级', res)
        if (res.code !== 0 || res.data === null) return
        this.bjs = res.data
      } catch (err) {
        console.log('班级', err)
      }
    },
    bjChange (e) {
      const arr = this.bjs.filter(item => item.k === this.ssbjid)
      console.log(e, arr)
      this.bj = arr[0].val
      console.log(e, arr)
    },
    async getNjInfo () {
      // 接口文档 https://app.apifox.com/project/2726972/apis/api-118011488
      try {
        const params = {
          type: 'ssnj'
        }
        const { data: res } = await this.$http.post('/api/jkct/app/finddict', params)
        console.log('年级', res)
        if (res.code !== 0 || res.data === null) return
        this.njs = res.data
      } catch (err) {
        console.log('年级', err)
      }
    },
    njChange (e) {
      const arr = this.njs.filter(item => item.k === this.params.ssnjid)
      console.log(e, arr)
      this.nj = arr[0].val
      console.log(e, arr)
    },
    async getTestInfo () {
      // 接口文档 https://app.apifox.com/project/2726972/apis/api-160594366
      try {
        const params = {
          start: '0',
          length: '10',
          tjjgbh: this.loginInfo.jgbh || ''
        }
        const { data: res } = await this.$http.post('/zhctapi/findtcxmzd', params)
        console.log('根据机构获取套餐字典', res)
        if (res.code !== 0 || res.data === null) return
        this.tests = res.data
      } catch (err) {
        console.log('根据机构获取套餐字典', err)
      }
    },
    async exportClick () {
      // 接口文档 https://doc.weixin.qq.com/doc/w3_AAMAewYpAFEq8gTvbIPRzyA2OryNy?scode=ACwAmwdjAAY288ebehAAMAewYpAFE
      try {
        const params = {
          tjjgbh: this.loginInfo.jgbh || '',
          ssxxid: this.params.ssxxid || '',
          ssnjid: this.params.ssnjid || '',
          ssxx: this.ssxx,
          ssbj: this.bj,
          ssbjid: this.ssbjid,
          ssnj: this.nj,
          sftj: this.sftj,
          begtjrq: this.params.begtjsj,
          endtjrq: this.params.endtjsj,
          tmdy: this.tmdy,
          tjjdid: this.tjjdid
        }
        const res = await this.$http.get('/excels/ExcelDownxsct', { params: params, responseType: 'blob' })
        console.log('导出按钮调用接口', res)
        if (res.status !== 200 || res.data === null) return
        // this.downloadFile(res.data)
        // 创建一个新的URL指向Blob对象
        const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/vnd.ms-excel' }))
        // 使用新创建的URL下载文件
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'filename.xls') // 设置下载文件名
        document.body.appendChild(link)
        link.click()

        // 清理并释放内存
        window.URL.revokeObjectURL(url)
        document.body.removeChild(link)
      } catch (err) {
        console.log('导出按钮调用接口', err)
      }
    },
    // 将Base64编码的字符串转换为Blob对象
    base64ToBlob (base64, mimeType) {
      const byteCharacters = atob(base64)
      const byteArrays = []
      for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512)
        const byteNumbers = new Array(slice.length)
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i)
        }
        const byteArray = new Uint8Array(byteNumbers)
        byteArrays.push(byteArray)
      }
      return new Blob(byteArrays, { type: mimeType })
    },
    clean () {
      this.params.ssxxid = ''
      this.params.ssnjid = ''
      this.params.ssbjid = ''
      this.params.tcmc = ''
      this.params.tcmc2 = ''
      this.params.sxtj = ''
      this.params.tmdy = ''
      this.params.hcflag = ''
      this.params.qxlx = ''
      this.params.sftj = ''
      this.params.tjrq = ''
      this.params.hcrq = ''
      this.params.tjlx = ''
      this.params.tjjdid = ''
      this.params.tmsfdy = ''
      this.params.ssnj = ''
      this.params.ssbj = ''
      this.getInfo()
    },
    // 下载文件
    downloadFile (data) {
      const blob = this.base64ToBlob(data, 'application/vnd.ms-excel')
      const url = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = 'application/vnd.ms-excel'
      document.body.appendChild(a)
      a.click()
      // setTimeout(() => {
      //   document.body.removeChild(a)
      //   window.URL.revokeObjectURL(url)
      // }, 0)
    },
    async exportAllClick () {
      // 接口文档 https://doc.weixin.qq.com/doc/w3_AAMAewYpAFEq8gTvbIPRzyA2OryNy?scode=ACwAmwdjAAY288ebehAAMAewYpAFE
      try {
        const date = new Date()
        const year = date.getFullYear()
        const params = {
          tjjgbh: this.loginInfo.jgbh || '',
          ssxxid: this.params.ssxxid || '',
          ssnjid: this.params.ssnjid || '',
          tjrq: year + '',
          ssxx: this.ssxx,
          ssbj: this.bj,
          ssbjid: this.ssbjid,
          ssnj: this.nj,
          sftj: this.sftj,
          begtjrq: this.params.begtjsj,
          endtjrq: this.params.endtjsj,
          tmdy: this.tmdy,
          tjjdid: this.tjjdid
        }
        const { data: res } = await this.$http.post('/exportReport/exportPdfallnmxstj', params)
        console.log('批量导出', res)
        if (res.code !== 0) return
        this.showPopup = true
        this.picurl = res.data.picurl || ''
      } catch (err) {
        console.log('批量导出', err)
      }
    },
    testChange (e) {
      console.log(e)
      this.params.tcmcid = e
    },
    testTypeChange (e) {
      console.log(e)
      this.params.tjlxid = e
    },
    jdChange (e) {
      console.log(e)
      this.params.tjjd = e
    },
    tmChange (e) {
      console.log(e)
      this.params.tmsfdy = e
    },
    drChange (e) {
      console.log(e)
      this.params.drflag = e
    },
    testDateChange (e) {
      console.log(e)
      this.params.begtjsj = e[0]
      this.params.endtjsj = e[1]
    },
    tabClick (index) {
      this.tabIndex = index
    },
    openAdd () {
      this.showAdd = true
    },
    closeAdd () {
      this.showAdd = false
    },
    openSchedule (info) {
      this.selectInfo = JSON.parse(JSON.stringify(info))
      this.showSchedule = true
    },
    closeSchedule () {
      this.showSchedule = false
    },
    openSee (info) {
      this.selectInfo = JSON.parse(JSON.stringify(info))
      this.showSee = true
    },
    closeSee () {
      this.selectInfo = null
      this.showSee = false
    },
    openReport (info) {
      this.selectInfo = JSON.parse(JSON.stringify(info))
      this.showReport = true
    },
    closeReport () {
      this.selectInfo = null
      this.showReport = false
    },
    async getInfo () {
      // 接口文档 https://app.apifox.com/project/2726972/apis/api-159158565
      console.log(this.params)
      try {
        this.params.start = this.query.pageNum === 1 ? '0' : (this.query.pageNum - 1) * 10 + ''
        this.params.length = this.query.pageSize + ''
        const { data: res } = await this.$http.get('/ykdzhctxstjbs', { params: this.params })
        console.log('体检列表', res)
        if (res.code !== 0 || res.data === null || res.data.length < 1) {
          return this.$message({
            message: res.msg || '暂无信息',
            type: 'warning'
          })
        }
        this.tableData = res.data
        this.query.total = res.recordsTotal
      } catch (err) {
        console.log('体检列表', err)
      }
    },
    reset () {
      this.ssxx = ''
      this.tcmc = ''
      this.tjlx = ''
      this.bj = ''
      this.nj = ''
      this.jd = ''
      this.dr = ''
      this.tm = ''
      this.params.sxtj = ''
      this.params.ssxxid = ''
      this.params.tcmcid = ''
      this.params.tjlxid = ''
      this.params.tjjd = ''
      this.params.tmsfdy = ''
      this.params.drflag = ''

      this.value1 = ''
      this.value2 = ''
      this.value = ''
      this.value3 = ''
    },
    change (e) {
      console.log(e)
      this.params.betime = e[0]
      this.params.entime = e[0]
    },
    async printTmcode () {
      this.loading = true
      try {
        const params = {
          // nowtime: '',
          // ip: '',
          // jgbh: this.params.jgbh || '',
          // cwhmcid: this.params.cwhmcid,
          // name: this.params.name,
          // sfzh: '',
          // age: '',
          // xb: '',
          // tmcode: '',
          // tjrq: '',
          // retmsg: '',
          // searchTime: '',
          // uploadFlag: '',
          // dnType: '',
          // betime: this.params.betime,
          // entime: this.params.entime,
          // isaged: ''

          ssxxid: this.params.ssxxid,
          ssbjid: '',
          ssnjid: '',
          ssxqid: '',
          tjlxid: this.params.tjlxid,
          tcmcid: this.params.tcmcid,
          tjjgbh: this.loginInfo.jgbh || '',
          tjrq: this.value1 || ''
        }
        // 接口文档 https://app.apifox.com/project/2726972/apis/api-169389512
        // const { data: res } = await this.$http.post('/api/jkct/findtjprilist', params)
        const { data: res } = await this.$http.post('/zhctapi/xstjtmlist', params)
        console.log('学生体检条码批量打印', res)
        this.loading = false
        if (res.code !== 0 || res.data === null || res.data === '') {
          return this.$message({
            message: res.msg || '暂无信息',
            type: 'warning'
          })
        }
        if (res.data && res.data.length < 1) {
          return this.$message({
            showClose: true,
            message: '暂无可打印数据',
            type: 'warning'
          })
        }
        this.$confirm('此操作将批量打印共 ' + res.data.length * 3 + '条,是否继续',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }
        ).then(() => {
          this.LODOP = getLodop()
          for (var j = 0; j < res.data.length; j++) {
            this.ssd(res.data[j])
          }
        })
      } catch (err) {
        console.log('学生体检条码批量打印', err)
        this.loading = false
        this.$message({
          message: '服务器异常',
          type: 'warning'
        })
      }
    },
    ssd (info) {
      // console.log(infos)

      console.log('条码', info.tmcode)
      this.LODOP.PRINT_INITA(0, 0, 400, 300, '条纹码')
      for (var j = 0; j < 3; j++) {
        this.LODOP.ADD_PRINT_TEXT(10, 1, 160, 34, '体检日期：' + '' + info.tjrq)
        this.LODOP.ADD_PRINT_TEXT(25, 1, 160, 34, info.name + ' ' + (info.xb === '1' ? '男' : '女') + ' ' + '年龄：' + info.age)
        this.LODOP.ADD_PRINT_BARCODE(40, 1, 140, 60, '128A', info.tmcode)
        this.LODOP.SET_PRINT_STYLEA(0, 'ShowBarText', 0) // 设置是否显示下方的文字
        this.LODOP.ADD_PRINT_TEXT(100, 1, 160, 34, ' ' + info.tmcode)
        this.LODOP.NewPage()
      }
      this.LODOP.PRINT()
    },
    async printTest () {
      this.loading = true
      try {
        const params = {
          grjbxxid: '',
          nowtime: '',
          ip: '',
          jgbh: this.params.jgbh || '',
          cwhmcid: this.params.cwhmcid,
          name: this.params.name,
          sfzh: '',
          age: '',
          xb: '',
          tmcode: '',
          tjrq: '',
          retmsg: '',
          searchTime: '',
          uploadFlag: '',
          dnType: '',
          betime: this.params.betime,
          entime: this.params.entime,
          isaged: '',
          start: 1,
          length: 20
        }
        const { data: res } = await this.$http.post('/api/jkct/findtjprilist', params)
        console.log('批量打印报告', res)
        this.loading = false
        if (res.code !== 0) {
          return this.$message({
            message: res.msg || '暂无信息',
            type: 'warning'
          })
        }
        this.testData = res.data
        this.testInfo.forEach((ele, index) => {
          ele.key = this.testData[ele.value]
        })
        this.testInfo[0].key = this.testData.sum
        this.printResult = true
        this.isPrint = true
      } catch (err) {
        console.log('批量打印报告', err)
        this.loading = false
      }
    },
    async checkPrintTest () {
      const sctype = this.checkList.join(',')
      this.LODOP = getLodop()
      let num = 0
      this.percentage = 0
      try {
        for (const ele of this.testData.tjlist) {
          if (!this.isPrint) {
            break
          }
          const params = {
            sctype: sctype,
            jgbh: this.loginInfo.jgbh || '',
            type: ele.type,
            jgjb: this.loginInfo.jgjb || '',
            sfzh: ele.sfzhm,
            tmcode: ele.tmcode
          }
          console.log(params)
          const { data: res } = await this.$http.post('/api/jkct/findtjprilistcall', params, {
            cancelToken: this.Source.token
          })
          console.log('打印数据：', res)
          console.log('打印内容')
          console.log('PDF打印：', ele.name)
          console.log('PDF：', res.data)
          if (res.code === 0) {
            console.log('------------')
            this.LODOP.PRINT_INIT('PDF打印:' + ele.name)
            this.LODOP.ADD_PRINT_PDF(0, 0, '100%', '100%', this.demoDownloadPDF(res.data))
            this.LODOP.PRINT()
          }
          console.log(Math.floor((num++ / this.testData.tjlist.length) * 100))
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request canceled', error.message)
        } else {
          // 处理错误
        }
      }
    },
    demoDownloadPDF (url) {
      if (!/^https?:/i.test(url)) return
      if (window.XMLHttpRequest) var xhr = new XMLHttpRequest()
      // eslint-disable-next-line no-undef
      else xhr = new ActiveXObject('MSXML2.XMLHTTP')
      xhr.open('GET', url, false) // 同步方式
      if (xhr.overrideMimeType) {
        try {
          xhr.responseType = 'arraybuffer'
          var arrybuffer = true
        } catch (err) {
          xhr.overrideMimeType('text/plain; charset=x-user-defined')
        }
      }
      xhr.send(null)
      var data = xhr.response || xhr.responseBody
      if (typeof Uint8Array !== 'undefined') {
        if (arrybuffer) var dataArray = new Uint8Array(data)
        else {
          dataArray = new Uint8Array(data.length)
          for (var i = 0; i < dataArray.length; i++) {
            dataArray[i] = data.charCodeAt(i)
          }
        }
      } else {
        // dataArray = VBS_BinaryToArray(data).toArray() // 兼容IE低版本
      }
      return this.demoGetBASE64(dataArray)
    },
    demoGetBASE64 (dataArray) {
      var digits =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/='
      var strData = ''
      for (var i = 0, ii = dataArray.length; i < ii; i += 3) {
        if (isNaN(dataArray[i])) break
        var b1 = dataArray[i] & 0xff
        var b2 = dataArray[i + 1] & 0xff
        var b3 = dataArray[i + 2] & 0xff
        var d1 = b1 >> 2
        var d2 = ((b1 & 3) << 4) | (b2 >> 4)
        var d3 = i + 1 < ii ? ((b2 & 0xf) << 2) | (b3 >> 6) : 64
        var d4 = i + 2 < ii ? b3 & 0x3f : 64
        strData +=
          digits.substring(d1, d1 + 1) +
          digits.substring(d2, d2 + 1) +
          digits.substring(d3, d3 + 1) +
          digits.substring(d4, d4 + 1)
      }
      return strData
    },
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
      this.query.pageNum = val
      this.getInfo()
    },
    async getTestInfoTwo () {
      try {
        const params2 = {
          start: '0',
          length: '10',
          ssjgbh: this.loginInfo.jgbh || ''
        }
        const { data: res } = await this.$http.post('/zhctapi/getlist', params2)
        console.log('套餐项目列表', res)
        if (res.code !== 0 || res.data === null) return
        this.tcmc2s = res.data
        console.log('+++++++' + this.tcmc2s)
      } catch (err) {
        console.log('套餐项目列表', err)
      }
    },
    close () {
      console.log('关闭')
      this.isPrint = false
      this.printResult = false
      this.Source.cancel('Operation canceled by the user.')
    }
  }
}
</script>

<style lang="less" scoped>
.son {
  width: 100%;
  height: 100%;
  background-color: #F0F0F0;

  .son-head {
    display: flex;
    align-items: center;
    width: 100%;
    height: 70px;
    margin-bottom: 10px;
    // padding-bottom: 20px;
    background-color: #fff;
    box-sizing: border-box;

    .son-head-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 0 14px;
      box-sizing: border-box;

      .son-head-left {
        display: flex;
        align-items: center;
        width: 33%;

        .son-head-open {
          width: 23px;
          height: 6px;
          border-top: 2px solid #595959;
          border-bottom: 2px solid #595959;
        }

        .el-input {
          margin-left: 50px;
        }

        /deep/.el-input__inner {
          border-color: #fff;
          font-size: 18px;
        }
      }

      .son-head-center {
        display: flex;
        align-items: center;
        flex: 1;
        color: #595959;
        font-size: 20px;

        /deep/.el-input__inner {
          font-size: 18px;
        }
      }

      .son-head-right {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex: 1;

        .success {
          color: #67C23A;
          font-size: 39px;
        }

        .lock {
          color: #595959;
          font-size: 30px;
        }

        .rank {
          font-size: 34px;
          transform: rotate(45deg);
        }

        .div {
          width: 41px;
          height: 40px;
          padding: 6px 7px;
          border: 1px solid #E6E6E6;
          border-radius: 6px;
          box-sizing: border-box;

          .div1 {
            width: 100%;
            height: 100%;
            padding-top: 3px;
            border: 1px solid #999999;
            box-sizing: border-box;

            .blue {
              width: 100%;
              height: 100%;
              background-color: #409EFF;
            }
          }
        }

        /deep/.el-input__inner {
          text-align: right;
          border: none;
          font-size: 18px;
        }
      }

      .son-head-title {
        width: 117px;
        height: 38px;
        line-height: 38px;
        text-align: center;
        border: 1px solid #409EFF;
        border-radius: 2px;
        box-sizing: border-box;
        color: #409EFF;
        font-size: 16px;
      }

      .el-button {
        width: 100px;

        .icon {
          margin-left: 10px;
        }
      }
    }
  }

  .son-scroll {
    width: 100%;
    height: calc(100% - 80px);
    padding: 0 10px;
    box-sizing: border-box;

    .son-search {
      display: flex;
      justify-content: space-evenly;
      align-content: space-evenly;
      flex-wrap: wrap;
      width: 100%;
      height: 200px;
      background-color: #fff;
      border-radius: 18px;
      box-sizing: border-box;

      .search-item {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: calc(25% - 40px);

        .el-select {
          width: 250px;
        }

        .el-input {
          width: 250px;
        }

        /deep/.el-date-editor {
          width: 250px;
        }
      }

      .key {
        margin-right: 20px;
        color: #17212B;
        font-size: 22px;
      }

      .el-button {
        background-color: #3FA1FF;
        color: #fff;
        font-size: 16px;
      }

      .button {
        display: flex;
        align-items: center;
        width: calc(75% - 60px);
      }
    }

    .son-info {
      width: 100%;
      height: calc(100% - 210px);
      margin-top: 10px;
      padding: 0 10px;
      background-color: #fff;
      border-radius: 18px;
      box-sizing: border-box;

      .son-info-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 58px;
        border-bottom: 1px solid #333333;
        box-sizing: border-box;

        .info-left {
          display: flex;
          align-items: center;
          height: 100%;
          color: #17212B;
          font-size: 20px;

          .el-button {
            margin-left: 20px;
            background-color: #3FA1FF;
            border: none;
            color: #fff;
          }
        }

        .info-right {
          width: 70px;
          height: 58px;
          line-height: 58px;
          text-align: center;
          color: #17212B;
          font-size: 20px;
        }
      }

      .son-info-scroll {
        overflow-y: scroll;
        width: 100%;
        height: calc(100% - 150px);
        margin: 13px 0;

        &::-webkit-scrollbar {
          width: 0;
        }

        .scroll-list {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          padding: 10px 0;
          margin-bottom: 10px;
          box-sizing: border-box;

          .info-item {
            font-size: 16px;
          }
        }
      }

      .info-item {
        // overflow: hidden;
        // text-overflow: ellipsis;
        // white-space: nowrap;
        width: 100px;
        text-align: center;
        word-wrap: break-word;
        color: #17212B;
        font-size: 20px;

        .red {
          color: #FF0000;
        }

        .green {
          color: #29CF00;
        }
      }

      .info-item1 {
        width: 160px;
      }

      .info-item2 {
        width: 230px;
      }

      .info-item3 {
        width: 140px;
      }

      .info-item4 {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        width: 200px;

        .span {
          cursor: pointer;
          color: #399BFB;
        }

        .popper {
          padding: 0 !important;
        }

        .popover {
          width: 86px;
          height: 186px;
          background-color: red;

          .popover-item {
            width: 100%;
            height: 31px;
            line-height: 31px;
            text-align: center;
            border-bottom: 1px solid #D9D9D9;
            color: #333333;
            font-size: 14px;
          }

          .popover-item:last-child {
            border: none;
          }
        }
      }

      .info-item5 {
        width: 55px;
      }

      .info-item6 {
        width: 190px;
      }

      .info-item7 {
        width: 110px;
      }

      // 页码样式修改
      .el-pagination {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.son1 {
  position: relative;
}

/deep/.el-loading-spinner i {
    color: #fff;
    font-size: 30px;
  }

  /deep/.el-loading-text {
    color: #fff;
    font-size: 20px;
  }
</style>
