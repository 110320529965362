<template>
  <div class="add">
    <div class="add-title">
      <div class="title">
        <span>修改</span>
      </div>
      <el-input placeholder="姓名/身份证号" suffix-icon="el-icon-search" class="input">
      </el-input>
    </div>
    <div class="add-box">
      <div class="add-box-title">
        <span>修改信息</span>
      </div>
      <div class="add-content">
        <div class="add-item">
          <div class="add-key">
            <span>预定人姓名：</span>
          </div>
          <el-input v-model="params.name"></el-input>
        </div>
        <div class="add-item">
          <div class="add-key">
            <span>预定人电话：</span>
          </div>
          <el-input v-model="params.gddh"></el-input>
        </div>
        <div class="add-item">
          <div class="add-key">
            <span>与老人关系：</span>
          </div>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="add-item">
          <div class="add-key">
            <span>预定床位：</span>
          </div>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="add-item">
          <div class="add-key">
            <span>开始日期：</span>
          </div>
              <el-date-picker v-model="params.kssj" type="datetime" placeholder="选择日期时间"> </el-date-picker>
        </div>
        <div class="add-item">
          <div class="add-key">
            <span>结束日期：</span>
          </div>
              <el-date-picker v-model="params.jssj" type="datetime" placeholder="选择日期时间"> </el-date-picker>
        </div>
        <div class="add-item">
          <div class="add-key">
            <span>预定时长(天)：</span>
          </div>
          <el-input v-model="params.ydsc"></el-input>
        </div>
        <div class="add-item add-item1">
          <div class="add-key">
            <span>定金应收： </span>
          </div>
          <el-input v-model="params.djys"></el-input>
        </div>
      </div>
      <div class="add-box-title1">
        <span>预定协议</span>
      </div>
      <div class="add-upload">
        <el-button size="small" class="upload">上传协议</el-button>
        <div class="add-tip">
          <span>下载预定协议</span>
        </div>
      </div>
      <div class="add-img">
        <img src="" alt="" class="img">
        <img src="" alt="" class="img">
        <img src="" alt="" class="img">
      </div>
    </div>
    <div class="add-btns">
      <el-button size="small">保存暂不提交</el-button>
      <el-button size="small" @click="submit">保存并提交</el-button>
      <el-button size="small" class="gray" @click="close">取消</el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    selectInfo: {
      type: Object
    }
  },
  data () {
    return {
      input: '',
      options: [{
        value: '',
        label: ''
      }],
      value: '',
      params: {
        id: 0,
        czbh: '',
        lnrzsbh: '',
        name: '',
        age: '',
        lxfs: '',
        gddh: '',
        xb: '',
        csrq: '',
        hjdz: '',
        ssjgbh: '',
        ssjgmc: '',
        createtime: '',
        updatetime: '',
        rydjid: '',
        xzjdmcid: '',
        xzjdmc: '',
        cwhmcid: '',
        cwhmc: '',
        sfzh: '',
        sfzpic: '',
        sjhm: '',
        minz: '',
        hyzk: '',
        whcd: '',
        zhiye: '',
        xjdz: '',
        znsl: '',
        sfjz: '',
        zfjzlx: '',
        zyjjly: '',
        rylc: '',
        rylcid: '',
        ryfjid: '',
        ryfj: '',
        rycw: '',
        rycwid: '',
        ydrname: '',
        ydrdh: '',
        ylrgx: '',
        ydcwh: '',
        ydcwid: '',
        kssj: '',
        jssj: '',
        ydsc: '',
        djys: '',
        ydxylb: [
          {
            id: '',
            name: '',
            xypic: '',
            bz: ''
          }
        ],
        ydzt: '',
        xzcwcwh: '',
        xzcwmc: '',
        xzcwcwdj: '',
        xzcwrzts: '',
        xzcwrzsj: '',
        xzcwjssj: '',
        cyhsfy: '',
        hlfwfy: '',
        hlfwlist: '',
        qtsfyj: '',
        qtsfycxshf: '',
        ryfyhdts: '',
        ddzfylb: '',
        ryfylb: '',
        scht: '',
        ydxy: ''
      }
    }
  },
  created () {
    console.log('所选信息', this.selectInfo)
    this.params = this.selectInfo
  },
  methods: {
    async submit () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-111166420
      try {
        console.log('请求参数', this.params)
        const { data: res } = await this.$http.post('/zhyyapi/ykdrydjxg', this.params)
        console.log('入院登记修改', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.$message.success('修改成功')
        this.$emit('close', 'loading')
      } catch (error) {
        console.log('入院登记修改失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    close () {
      this.$emit('close')
      this.$parent.showUpdate = false
    }
  }
}
</script>

<style scoped lang="less">
.add {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0 40px;
  box-sizing: border-box;
  z-index: 88;
  background-color: #081C38;

  .add-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;

    .title {
      height: 38px;
      line-height: 38px;
      padding: 0 20px;
      background-color: #04B4D0;
      text-align: center;
      color: #fff;
      font-size: 22px;
    }

    .input {
      width: 246px;

      /deep/.el-input__inner {
        border: none;
        background: linear-gradient(153deg, #0A477B 0%, #0E67AD 100%);
        color: #fff;
        font-size: 18px;
      }

      /deep/.el-input__inner::-webkit-input-placeholder {
        color: #fff;
      }

      /deep/.el-input__suffix {
        color: #fff;
        font-size: 18px;
      }
    }
  }

  .add-box {
    height: calc(100% - 140px);

    .add-box-title {
      height: 40px;
      line-height: 40px;
      padding: 0 10px;
      box-sizing: border-box;
      color: #01EEFD;
      font-size: 18px;
    }

    .add-content {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 0 20px;
      box-sizing: border-box;

      .add-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 25%;
        margin-bottom: 20px;

        .add-key {
          white-space: nowrap;
          color: #01EEFD;
          font-size: 18px;
        }

        .el-input {
          width: 180px;
        }
      }

      .add-item1 {
        width: 40%;
      }
    }

    .add-box-title1 {
      height: 40px;
      line-height: 40px;
      border-bottom: 1px solid #01EEFD;
      box-sizing: border-box;
      color: #01EEFD;
      font-size: 20px;
    }

    .add-upload {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 60px;
      padding: 0 10px;
      box-sizing: border-box;

      .el-button {
        background-color: #04B4D0;
        border: none;
        color: #fff;
        font-size: 18px;
      }

      .add-tip {
        margin-left: 30px;
        color: #fff;
        font-size: 22px;
      }
    }

    .add-img {
      display: flex;
      flex-direction: row;

      .img {
        width: 141px;
        height: 199px;
        margin-left: 20px;
        background-color: #fff;
      }
    }
  }

  .add-btns {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 60px;

    .el-button {
      margin-right: 30px;
      background-color: #024A83;
      border: none;
      color: #fff;
    }

    .gray {
      background-color: #33465C;
    }
  }

}
</style>
