<template>
  <div class="popup">
    <div class="popup-title">
      <div class="title">
        <span>管理员管理</span>
      </div>
      <!-- <el-input class="postion" placeholder="姓名/身份证号" suffix-icon="el-icon-search">
      </el-input> -->
    </div>
    <div class="popup-box">
      <div class="popup-item">
        <span>用户名：</span>
        <el-input placeholder="请输入"></el-input>
      </div>
      <div class="popup-item">
        <span>账号：</span>
        <el-input placeholder="请输入"></el-input>
      </div>
      <div class="popup-item">
        <span>选择角色：</span>
        <el-select placeholder="请选择" v-model="value">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="popup-item">
        <span>手机号码：</span>
        <el-input placeholder="请输入"></el-input>
      </div>
      <div class="popup-item">
        <span>密码：</span>
        <el-input placeholder="请输入"></el-input>
      </div>
      <div class="popup-item">
        <span>是否启用：</span>
        <el-switch v-model="value" active-color="#30F05D" inactive-color="#ff4949">
        </el-switch>
      </div>
      <div class="btns">
        <el-button class="blue" @click="close">保存</el-button>
        <el-button @click="close">取消</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {

  },
  data () {
    return {
      options: [{
        value: '',
        label: ''
      }],
      imageUrl: '',
      value: ''
    }
  },
  methods: {
    handleAvatarSuccess (res, file) {
      this.imageUrl = URL.createObjectURL(file.raw)
    },
    beforeAvatarUpload (file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.popup {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 888;
  width: 100%;
  height: 100%;
  background-color: #081C38;
  box-sizing: border-box;

  .popup-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;

    .title {
      height: 38px;
      line-height: 38px;
      padding: 0 9px;
      text-align: center;
      background-color: #01EEFD;
      box-sizing: border-box;
      color: #fff;
      font-size: 22px;
    }

    .postion {
      width: 246px;
      border-radius: 6px;
      background: linear-gradient(153deg, #0A477B 0%, #0E67AD 100%);
      border: 1px solid #1F72D3;

      /deep/.el-input__inner {
        background-color: transparent;
        color: #fff !important;
        font-size: 18px;
        border: none;

      }

      /deep/ .el-input__suffix {
        color: #fff;
        font-size: 18px;
      }

      /deep/ .el-icon-search {
        display: flex;
        align-items: center;
      }
    }
  }

  .popup-box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 12px;
    box-sizing: border-box;

    .popup-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      margin-top: 20px;
      color: #01EEFD;
      font-size: 18px;

      span {
        width: 110px;
      }

      .el-input {
        width: 180px;
      }

      .el-select {
        width: 180px;
      }
    }
  }

  .btns {
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 70px;

    .el-button {
      margin: 0 25px;
      background-color: #33465C;
      border: none;
      color: #fff;
    }

    .blue {
      background: linear-gradient(108deg, #0174C9 0%, #024176 100%);
    }
  }
}
</style>
