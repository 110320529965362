import { render, staticRenderFns } from "./testProjectRun.vue?vue&type=template&id=5d85c79a&scoped=true&"
import script from "./testProjectRun.vue?vue&type=script&lang=js&"
export * from "./testProjectRun.vue?vue&type=script&lang=js&"
import style0 from "./testProjectRun.vue?vue&type=style&index=0&id=5d85c79a&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d85c79a",
  null
  
)

export default component.exports