<template>
  <div class="page-box">
    <div class="tab">
      <span class="item active">薪资福利结算</span>
    </div>
    <el-input placeholder="姓名/身份证号" suffix-icon="el-icon-search" class="input">
    </el-input>
    <div class="tab-box">
      <div class="run">
        <div class="run-search">
          <div class="run-item">
            <div class="key">
              <span>护工姓名</span>
            </div>
            <el-input placeholder="请输入"></el-input>
          </div>
          <div class="run-item">
            <div class="key">
              <span>联系方式</span>
            </div>
            <el-input placeholder="请输入"></el-input>
          </div>
          <div class="run-item">
            <div class="key">
              <span>身份证号</span>
            </div>
            <el-input placeholder="请输入"></el-input>
          </div>
          <div class="run-item">
            <div class="key">
              <span>统计月份</span>
            </div>
            <el-input placeholder="请输入"></el-input>
          </div>
          <div class="run-item">
            <div class="key">
              <span>结算状态</span>
            </div>
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <el-button type="primary">搜索</el-button>
          <el-button type="primary">重置</el-button>
        </div>

        <div class="table">
          <el-table :data="tableData" height="100%" border>
          <el-table-column label="序号" type="index" width="100">
          </el-table-column>
          <el-table-column prop="name" label="头像">
          </el-table-column>
          <el-table-column prop="address" label="姓名">
          </el-table-column>
          <el-table-column prop="address" label="联系方式">
          </el-table-column>
          <el-table-column prop="address" label="身份证号">
          </el-table-column>
          <el-table-column prop="address" label="薪资合计">
          </el-table-column>
          <el-table-column prop="address" label="待结算薪资">
          </el-table-column>
          <el-table-column label="操作" width="220">
            <template>
              <!-- <div class="table-run">
                <span @click="openSee">薪资明细</span>
                <span @click="openSettlement">标记已结算</span>
              </div> -->
              <el-button size="mini" type="primary" @click="openSee">薪资明细</el-button>
              <el-button size="mini" type="primary" @click="openSettlement">标记已结算</el-button>
            </template>
          </el-table-column>
        </el-table>
        </div>

        <el-pagination @size-change="handleSizeChange" background @current-change="handleCurrentChange" :current-page="currentPage4"
          :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper" :total="400">
        </el-pagination>
      </div>
    </div>
    <See v-if="showSee" @close="closeSee"></See>
    <Settlement v-if="showSettlement" @close="closeSettlement"></Settlement>
  </div>
</template>

<script>
import See from './see.vue'
import Settlement from './settlement.vue'
export default {
  components: {
    See,
    Settlement
  },
  data () {
    return {
      tableData: [{
        date: '2016-05-02',
        name: '王小虎',
        address: '等待外出'
      }, {
        date: '2016-05-04',
        name: '王小虎',
        address: '未返回'
      }, {
        date: '2016-05-01',
        name: '王小虎',
        address: '逾期未返回'
      }],
      options: [{
        value: '',
        label: ''
      }],
      value: '',
      currentPage4: 4,
      tabIndex: 0,
      selectIndex: 0,
      showAdd: false,
      showSettlement: false,
      showSee: false
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    },
    openAdd () {
      this.showAdd = true
    },
    closeAdd () {
      this.showAdd = false
    },
    openSettlement () {
      this.showSettlement = true
    },
    closeSettlement () {
      this.showSettlement = false
    },
    openSee () {
      this.showSee = true
    },
    closeSee () {
      this.showSee = false
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.page-box {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  background-color: #fff;

  .input {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 246px;
  }

  .tab {
    display: flex;
    align-items: center;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    .item {
      height: 38px;
      line-height: 38px;
      text-align: center;
      margin-left: 30px;
      padding: 0 10px;
      background-color: #284867;
      color: #fff;
      font-size: 22px;
      cursor: pointer;

      &:first-child {
        margin-left: 0px;
      }
    }

    .active {
      background-color: #66b1ff;
    }
  }

  .tab-box {
    width: 100%;
    height: calc(100% - 78px);
  }

}

.run {
  width: 100%;
  height: 100%;

  .son-tab {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    .son-item {
      height: 38px;
      line-height: 38px;
      margin-right: 40px;
      padding: 0 20px;
      text-align: center;
      background-color: #284867;
      color: #fff;
      font-size: 16px;
      cursor: pointer;
    }

    .son-active {
      background-color: #04B4D0;
    }
  }

  .son-tab1 {
    border: none;
  }

  .son-add {
    display: flex;
    align-items: center;
    height: 50px;

    .son-add-icon {
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      background-color: #66b1ff;
      border-radius: 50%;
      color: #fff;
      font-size: 16px;
      cursor: pointer;
    }
  }

  .run-search {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    height: 136px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    .run-item {
      display: flex;
      align-items: center;
      margin-right: 50px;

      .key {
        margin-right: 10px;
        white-space: nowrap;
        color: #000;
        font-size: 22px;
      }
    }
  }

  .table {
    width: 100%;
    height: calc(100% - 240px);
  }

  /deep/.el-table .cell {
    text-align: center;
    color: #000;
  }

  .table-run {
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: space-between;

    span {
      cursor: pointer;
    }
  }

  .el-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
}
</style>
