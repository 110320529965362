<template>
  <div class="addDrug">
    <div class="title">
      <span>用药新增</span>
    </div>
    <div class="content">
      <div @click="close" class="close">
        <span class="el-icon-close"></span>
      </div>
      <div class="box">
        <div class="item">
          <span class="key">用药名</span>
          <el-input>
          </el-input>
        </div>

        <div class="item">
          <span class="key">每日用量</span>
          <el-select v-model="value" placeholder="">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>

        <div class="item">
          <span class="key">用药时间</span>
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="0">早</el-checkbox>
            <el-checkbox label="1">中</el-checkbox>
            <el-checkbox label="2">晚</el-checkbox>
          </el-checkbox-group>
        </div>

        <div class="item">
          <span class="key">服药依从性</span>
          <el-select v-model="value" placeholder="">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="btns">
        <el-button @click="close">取消</el-button>
        <el-button class="button" @click="close">确认</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      radio: '1',
      options: [{
        value: '1',
        label: '1'
      }],
      value: '',
      checkList: []
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.addDrug {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 888;
  width: 100%;
  height: 100%;
  padding: 0 400px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, .9);

  .title {
    width: 160px;
    height: 40px;
    padding-top: 5px;
    box-sizing: border-box;
    line-height: 40px;
    text-align: center;
    background-image: url('../../../img/ssjk/title.png');
    background-size: 100% 100%;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
  }

  .content {
    position: relative;
    width: 100%;
    min-width: 10rem;
    height: 433px;
    margin-top: 10px;
    padding: 50px;
    background-image: url('../../../img/ssjk/background4.png');
    background-size: 100% 100%;
    box-sizing: border-box;

    .close {
      position: absolute;
      top: 20px;
      right: 20px;
      color: #fff;
      font-size: 20px;
      cursor: pointer;
    }

    .content-title {
      margin-top: 20px;
      color: #01EEFD;
      font-size: 18px;
    }

    .box {
      display: flex;
      align-items: center;

      flex-wrap: wrap;
      width: 100%;
    }

    .item {
      display: flex;
      align-items: center;
      width: 50%;
      margin-top: 20px;
      color: #01EEFD;
      font-size: 18px;

      .key {
        margin-right: 10px;
        white-space: nowrap;
      }

      .key-width {
        width: 170px;
      }

      .value {
        color: #fff;
        font-size: 16px;
      }

      .red {
        color: #FF0000;
      }

      /deep/.el-textarea__inner {
        height: 116px;
        resize: none;
      }

      .el-input {
        width: 162px;
      }
    }

    .item1 {
      width: 100%;
    }

    .item2 {
      align-items: initial;
    }

    .btns {
      position: absolute;
      bottom: 50px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 240px;
      height: 46px;
      margin: 0 auto;

      .el-button {
        height: 30px;
        line-height: 30px;
        padding: 0 10px;
        text-align: center;
        color: #fff;
        font-size: 14px;
        background-color: #33465C;
        border-radius: 0;
        border: none;
      }

      .button {
        background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
      }
    }
  }

  .el-radio-group {
    margin-top: 6px;
  }

  /deep/.el-radio__input.is-checked .el-radio__inner {
    background-color: #01EEFD;
    border-color: #01EEFD;
  }

  .el-radio {
    color: #fff;
  }

  /deep/.el-radio__input.is-checked+.el-radio__label {
    color: #01EEFD;
  }

  /deep/.el-radio__input.is-checked .el-radio__inner,
  /deep/.el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: #01EEFD;
    border-color: #01EEFD;
  }

  .el-radio,
  .el-checkbox {
    color: #fff;
  }

  /deep/.el-radio__input.is-checked+.el-radio__label,
  /deep/.el-checkbox__input.is-checked+.el-checkbox__label {
    color: #01EEFD;
  }
}
</style>
