<template>
  <div class="run">
    <div class="run-search">
      <div class="run-item">
        <div class="key">
          <span>合作组织</span>
        </div>
        <el-input placeholder="请输入"></el-input>
      </div>
      <div class="run-item">
        <div class="key">
          <span>组织类型</span>
        </div>
        <el-select v-model="value" placeholder="请选择">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <el-button>搜索</el-button>
      <el-button>重置</el-button>
    </div>

    <div class="son-add">
      <div class="son-add-icon" @click="openAdd">
        <span class="el-icon-plus"></span>
      </div>
      <div class="son-set" @click="openSet">
        <span>积分奖励设置</span>
      </div>
    </div>

    <div class="table">
      <el-table :data="tableData" height="100%" border>
      <el-table-column label="序号" type="index" width="100">
      </el-table-column>
      <el-table-column prop="name" label="合作组织">
      </el-table-column>
      <el-table-column prop="address" label="组织类型">
      </el-table-column>
      <el-table-column prop="address" label="联系人">
      </el-table-column>
      <el-table-column prop="address" label="联系电话">
      </el-table-column>
      <el-table-column label="推荐人次">
        <template>
          <div class="run1">
            <span @click="openRun">5</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="address" label="推荐积分">
      </el-table-column>
      <el-table-column label="操作">
        <template>
          <!-- <div class="flex">
            <span @click="openAdd">编辑</span>
            <span @click="openDelete">删除</span>
          </div> -->
          <el-button size="mini" type="primary" @click="openAdd">编辑</el-button>
          <el-button size="mini" type="danger" @click="openDelete">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    </div>

    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage4"
      :page-sizes="[100, 200, 300, 400]"
      :page-size="100"
      background
      layout="total, prev, pager, next, jumper"
      :total="400">
    </el-pagination>
    <Add v-if="showAdd" @close="closeAdd"></Add>
    <Set v-if="showSet" @close="closeSet"></Set>
    <Run v-if="showRun" @close="closeRun"></Run>
    <Delete v-if="showDelete" @close="closeDelete"></Delete>
  </div>
</template>

<script>
import Add from './add.vue'
import Set from './set.vue'
import Run from './run.vue'
import Delete from './delete.vue'
export default {
  components: {
    Add,
    Set,
    Run,
    Delete
  },
  data () {
    return {
      tableData: [{
        date: '2016-05-02',
        name: '王小虎',
        address: '等待外出'
      }, {
        date: '2016-05-04',
        name: '王小虎',
        address: '未返回'
      }, {
        date: '2016-05-01',
        name: '王小虎',
        address: '逾期未返回'
      }],
      options: [{
        value: '',
        label: ''
      }],
      value: '',
      currentPage4: 4,
      tabIndex: 0,
      selectIndex: 0,
      showAdd: false,
      showDelete: false,
      showSet: false,
      showRun: false
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    },
    openAdd () {
      this.showAdd = true
    },
    closeAdd () {
      this.showAdd = false
    },
    openDelete () {
      this.showDelete = true
    },
    closeDelete () {
      this.showDelete = false
    },
    openSet () {
      this.showSet = true
    },
    closeSet () {
      this.showSet = false
    },
    openRun () {
      this.showRun = true
    },
    closeRun () {
      this.showRun = false
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.run {
  width: 100%;
  height: 100%;

  .son-tab {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    .son-item {
      height: 38px;
      line-height: 38px;
      padding: 0 20px;
      text-align: center;
      background-color: #284867;
      color: #fff;
      font-size: 16px;
      cursor: pointer;
    }

    .son-active {
      background-color: #409EFF;
    }

    .son-item:first-child {
      margin-right: 40px;
    }
  }

  .son-tab1 {
    border: none;
  }

  .son-add {
    display: flex;
    align-items: center;
    height: 50px;

    .son-add-icon {
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      background-color: #409EFF;
      border-radius: 50%;
      color: #fff;
      font-size: 16px;
      cursor: pointer;
    }

    .son-set {
      width: 120px;
      height: 30px;
      line-height: 30px;
      margin-left: 30px;
      text-align: center;
      background-color: #409EFF;
      color: #fff;
      font-size: 16px;
      cursor: pointer;
    }
  }

  .run-search {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    .run-item {
      display: flex;
      align-items: center;
      margin-right: 40px;

      .key {
        margin-right: 10px;
        white-space: nowrap;
        color: #000;
        font-size: 22px;
      }
    }
  }

  .table {
    width: 100%;
    height: calc(100% - 240px);
  }

  /deep/.el-table .cell {
    text-align: center;
    color: #000;
  }

  .flex {
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    span {
      cursor: pointer;
    }
  }

  .run1 {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .el-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
}
</style>
