<template>
  <div class="add">
    <div class="add-box">
      <div class="close" @click="close">
        <span class="el-icon-close"></span>
      </div>
      <div class="add-title">
        <span>基本信息</span>
      </div>
      <div class="add-line">
        <div class="line-left">
          <el-checkbox class="checkbox">保存后打印指引单</el-checkbox>
          <el-checkbox class="checkbox">保存后打印检验条码</el-checkbox>
          <el-button type="primary" size="mini">配置模板</el-button>
        </div>
        <div class="line-right">
          <el-button type="primary" size="mini">批量录入</el-button>
          <el-button type="primary" size="mini">预约导入</el-button>
          <el-button type="primary" size="mini">增加</el-button>
          <el-button type="primary" size="mini">保存</el-button>
          <el-button type="primary" size="mini">关闭</el-button>
        </div>
      </div>
      <div class="add-content">
        <div class="content-item">
          <div class="item">
            <div class="key">
              <span>档案编号</span>
            </div>
            <el-input class="input" v-model="input" placeholder="请输入"></el-input>
          </div>
          <div class="item">
            <div class="key">
              <span>姓名</span>
            </div>
            <el-input class="input" v-model="input" placeholder="请输入"></el-input>
          </div>
          <div class="item">
            <div class="key">
              <span>本人电话</span>
            </div>
            <el-input class="input" v-model="input" placeholder="请输入"></el-input>
          </div>
          <div class="item">
            <div class="key">
              <span>体检类型</span>
            </div>
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="content-item">
          <div class="item">
            <div class="key">
              <span>体检编号</span>
            </div>
            <el-input class="input" v-model="input" placeholder="请输入"></el-input>
          </div>
          <div class="item">
            <div class="key">
              <span>身份证号</span>
            </div>
            <el-input class="input" v-model="input" placeholder="请输入"></el-input>
          </div>
          <div class="item">
            <div class="key">
              <span>联系人</span>
            </div>
            <el-input class="input" v-model="input" placeholder="请输入"></el-input>
          </div>
          <div class="item">
            <div class="key">
              <span>体检日期</span>
            </div>
            <el-input class="input" v-model="input" placeholder="请输入"></el-input>
          </div>
        </div>
        <div class="content-item">
          <div class="item">
            <div class="key">
              <span>体检机构</span>
            </div>
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="item">
            <div class="key">
              <span>性别</span>
            </div>
            <el-input class="input1" v-model="input" placeholder=""></el-input>
            <div class="key key1">
              <span>年龄</span>
            </div>
            <el-input class="input1" v-model="input" placeholder=""></el-input>
            <div class="key key1">
              <span>民族</span>
            </div>
            <el-input class="input1" v-model="input" placeholder=""></el-input>
          </div>
          <div class="item">
            <div class="key">
              <span>联系电话</span>
            </div>
            <el-input class="input" v-model="input" placeholder="请输入"></el-input>
          </div>
          <div class="item">
            <div class="key">
              <span>所属学校</span>
            </div>
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="content-item">
          <div class="item item1">
            <el-button type="primary" size="mini">健康档案</el-button>
            <el-button type="primary" size="mini">身份证识别</el-button>
          </div>
          <div class="item">
            <div class="key">
              <span>出生年月</span>
            </div>
            <el-date-picker type="date" placeholder="选择日期">
            </el-date-picker>
          </div>
          <div class="item">
            <div class="key">
              <span>与本人关系</span>
            </div>
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="item">
            <div class="key">
              <span>所属班级</span>
            </div>
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="content-item">
          <div class="img"></div>
          <div class="item item1">
            <div class="key">
              <span>地址</span>
            </div>
            <el-input class="input" v-model="input" placeholder="请输入"></el-input>
          </div>
        </div>
      </div>
      <div class="add-table">
        <div class="table-item">
          <div class="table-title">
            <span>套餐选择</span>
          </div>
          <div class="table-box">
            <div class="table-head">
              <div class="flex">
                <span>科室</span>
              </div>
              <div class="flex flex2">
                <span>套餐名称</span>
              </div>
              <div class="flex">
                <span>选中</span>
              </div>
              <div class="flex flex2">
                <span>备注</span>
              </div>
            </div>
            <div class="table-scroll">
              <div class="table-list">
                <div class="flex">
                  <span>科室</span>
                </div>
                <div class="flex flex2">
                  <span>基本套餐</span>
                </div>
                <div class="flex">
                  <el-checkbox v-model="checkbox"></el-checkbox>
                </div>
                <div class="flex flex2">
                  <span>备注</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="table-item">
          <div class="table-title">
            <span>临床检查</span>
          </div>
          <div class="table-box">
            <div class="table-head">
              <div class="flex">
                <span>科室</span>
              </div>
              <div class="flex flex2">
                <span>项目检查</span>
              </div>
              <div class="flex">
                <span>选中</span>
              </div>
              <div class="flex flex2">
                <span>备注</span>
              </div>
            </div>
            <div class="table-scroll">
              <div class="table-list">
                <div class="flex">
                  <span>内科</span>
                </div>
                <div class="flex flex2">
                  <span>内科检查</span>
                </div>
                <div class="flex">
                  <el-checkbox v-model="checkbox"></el-checkbox>
                </div>
                <div class="flex flex2">
                  <span></span>
                </div>
              </div>
              <div class="table-list">
                <div class="flex">
                  <span></span>
                </div>
                <div class="flex flex2">
                  <span>测血压</span>
                </div>
                <div class="flex">
                  <el-checkbox v-model="checkbox"></el-checkbox>
                </div>
                <div class="flex flex2">
                  <span></span>
                </div>
              </div>
              <div class="table-list">
                <div class="flex">
                  <span>外科</span>
                </div>
                <div class="flex flex2">
                  <span>外科检查</span>
                </div>
                <div class="flex">
                  <el-checkbox v-model="checkbox"></el-checkbox>
                </div>
                <div class="flex flex2">
                  <span></span>
                </div>
              </div>
              <div class="table-list">
                <div class="flex">
                  <span>体检中心</span>
                </div>
                <div class="flex flex2">
                  <span>一般检查</span>
                </div>
                <div class="flex">
                  <el-checkbox v-model="checkbox"></el-checkbox>
                </div>
                <div class="flex flex2">
                  <span></span>
                </div>
              </div>
              <div class="table-list">
                <div class="flex">
                  <span></span>
                </div>
                <div class="flex flex2">
                  <span>妇科检查</span>
                </div>
                <div class="flex">
                  <el-checkbox v-model="checkbox"></el-checkbox>
                </div>
                <div class="flex flex2">
                  <span></span>
                </div>
              </div>
              <div class="table-list">
                <div class="flex">
                  <span></span>
                </div>
                <div class="flex flex2">
                  <span>盆底功能检查</span>
                </div>
                <div class="flex">
                  <el-checkbox v-model="checkbox"></el-checkbox>
                </div>
                <div class="flex flex2">
                  <span></span>
                </div>
              </div>
            </div>
            <div class="table-scroll">
              <div class="table-list">
                <div class="flex">
                  <span>内科</span>
                </div>
                <div class="flex flex2">
                  <span>内科检查</span>
                </div>
                <div class="flex">
                  <el-checkbox v-model="checkbox"></el-checkbox>
                </div>
                <div class="flex flex2">
                  <span></span>
                </div>
              </div>
              <div class="table-list">
                <div class="flex">
                  <span></span>
                </div>
                <div class="flex flex2">
                  <span>测血压</span>
                </div>
                <div class="flex">
                  <el-checkbox v-model="checkbox"></el-checkbox>
                </div>
                <div class="flex flex2">
                  <span></span>
                </div>
              </div>
              <div class="table-list">
                <div class="flex">
                  <span>外科</span>
                </div>
                <div class="flex flex2">
                  <span>外科检查</span>
                </div>
                <div class="flex">
                  <el-checkbox v-model="checkbox"></el-checkbox>
                </div>
                <div class="flex flex2">
                  <span></span>
                </div>
              </div>
              <div class="table-list">
                <div class="flex">
                  <span>体检中心</span>
                </div>
                <div class="flex flex2">
                  <span>一般检查</span>
                </div>
                <div class="flex">
                  <el-checkbox v-model="checkbox"></el-checkbox>
                </div>
                <div class="flex flex2">
                  <span></span>
                </div>
              </div>
              <div class="table-list">
                <div class="flex">
                  <span></span>
                </div>
                <div class="flex flex2">
                  <span>妇科检查</span>
                </div>
                <div class="flex">
                  <el-checkbox v-model="checkbox"></el-checkbox>
                </div>
                <div class="flex flex2">
                  <span></span>
                </div>
              </div>
              <div class="table-list">
                <div class="flex">
                  <span></span>
                </div>
                <div class="flex flex2">
                  <span>盆底功能检查</span>
                </div>
                <div class="flex">
                  <el-checkbox v-model="checkbox"></el-checkbox>
                </div>
                <div class="flex flex2">
                  <span></span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="table-item">
          <div class="table-title">
            <span>实验室检查</span>
          </div>
          <div class="table-box">
            <div class="table-head">
              <div class="flex">
                <span>科室</span>
              </div>
              <div class="flex flex2">
                <span>项目检查</span>
              </div>
              <div class="flex">
                <span>选中</span>
              </div>
              <div class="flex flex2">
                <span>备注</span>
              </div>
            </div>
            <div class="table-scroll">
              <div class="table-list">
                <div class="flex">
                  <span>血常规</span>
                </div>
                <div class="flex flex2">
                  <span>血液常规</span>
                </div>
                <div class="flex">
                  <el-checkbox v-model="checkbox"></el-checkbox>
                </div>
                <div class="flex flex2">
                  <span></span>
                </div>
              </div>
              <div class="table-list">
                <div class="flex">
                  <span>尿常规</span>
                </div>
                <div class="flex flex2">
                  <span>尿常规</span>
                </div>
                <div class="flex">
                  <el-checkbox v-model="checkbox"></el-checkbox>
                </div>
                <div class="flex flex2">
                  <span></span>
                </div>
              </div>
              <div class="table-list">
                <div class="flex">
                  <span>生化</span>
                </div>
                <div class="flex flex2">
                  <span>肝功能检查</span>
                </div>
                <div class="flex">
                  <el-checkbox v-model="checkbox"></el-checkbox>
                </div>
                <div class="flex flex2">
                  <span></span>
                </div>
              </div>
              <div class="table-list">
                <div class="flex">
                  <span></span>
                </div>
                <div class="flex flex2">
                  <span>肾功能检查</span>
                </div>
                <div class="flex">
                  <el-checkbox v-model="checkbox"></el-checkbox>
                </div>
                <div class="flex flex2">
                  <span></span>
                </div>
              </div>
              <div class="table-list">
                <div class="flex">
                  <span></span>
                </div>
                <div class="flex flex2">
                  <span>血脂分析</span>
                </div>
                <div class="flex">
                  <el-checkbox v-model="checkbox"></el-checkbox>
                </div>
                <div class="flex flex2">
                  <span></span>
                </div>
              </div>
              <div class="table-list">
                <div class="flex">
                  <span></span>
                </div>
                <div class="flex flex2">
                  <span>血糖</span>
                </div>
                <div class="flex">
                  <el-checkbox v-model="checkbox"></el-checkbox>
                </div>
                <div class="flex flex2">
                  <span></span>
                </div>
              </div>
              <div class="table-list">
                <div class="flex">
                  <span></span>
                </div>
                <div class="flex flex2">
                  <span>生化检查</span>
                </div>
                <div class="flex">
                  <el-checkbox v-model="checkbox"></el-checkbox>
                </div>
                <div class="flex flex2">
                  <span></span>
                </div>
              </div>
              <div class="table-list">
                <div class="flex">
                  <span></span>
                </div>
                <div class="flex flex2">
                  <span>血脂三项</span>
                </div>
                <div class="flex">
                  <el-checkbox v-model="checkbox"></el-checkbox>
                </div>
                <div class="flex flex2">
                  <span></span>
                </div>
              </div>
              <div class="table-list">
                <div class="flex">
                  <span></span>
                </div>
                <div class="flex flex2">
                  <span>肝功三酶</span>
                </div>
                <div class="flex">
                  <el-checkbox v-model="checkbox"></el-checkbox>
                </div>
                <div class="flex flex2">
                  <span></span>
                </div>
              </div>
              <div class="table-list">
                <div class="flex">
                  <span></span>
                </div>
                <div class="flex flex2">
                  <span>肾功两项</span>
                </div>
                <div class="flex">
                  <el-checkbox v-model="checkbox"></el-checkbox>
                </div>
                <div class="flex flex2">
                  <span></span>
                </div>
              </div>
              <div class="table-list">
                <div class="flex">
                  <span>免疫类</span>
                </div>
                <div class="flex flex2">
                  <span>乙肝标志物检查</span>
                </div>
                <div class="flex">
                  <el-checkbox v-model="checkbox"></el-checkbox>
                </div>
                <div class="flex flex2">
                  <span></span>
                </div>
              </div>
              <div class="table-list">
                <div class="flex">
                  <span></span>
                </div>
                <div class="flex flex2">
                  <span>肿瘤标志物检查</span>
                </div>
                <div class="flex">
                  <el-checkbox v-model="checkbox"></el-checkbox>
                </div>
                <div class="flex flex2">
                  <span></span>
                </div>
              </div>
              <div class="table-list">
                <div class="flex">
                  <span></span>
                </div>
                <div class="flex flex2">
                  <span>甲状腺素</span>
                </div>
                <div class="flex">
                  <el-checkbox v-model="checkbox"></el-checkbox>
                </div>
                <div class="flex flex2">
                  <span></span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="table-item">
          <div class="table-title">
            <span>影像学检查</span>
          </div>
          <div class="table-box">
            <div class="table-head">
              <div class="flex">
                <span>科室</span>
              </div>
              <div class="flex flex2">
                <span>项目检查</span>
              </div>
              <div class="flex">
                <span>选中</span>
              </div>
              <div class="flex flex2">
                <span>备注</span>
              </div>
            </div>
            <div class="table-scroll">
              <div class="table-list table-active">
                <div class="flex">
                  <span>心电图</span>
                </div>
                <div class="flex flex2">
                  <span>心电图检查</span>
                </div>
                <div class="flex">
                  <el-checkbox v-model="checkbox1"></el-checkbox>
                </div>
                <div class="flex flex2">
                  <span></span>
                </div>
              </div>
              <div class="table-list">
                <div class="flex">
                  <span>尿常规</span>
                </div>
                <div class="flex flex2">
                  <span>尿常规</span>
                </div>
                <div class="flex">
                  <el-checkbox v-model="checkbox"></el-checkbox>
                </div>
                <div class="flex flex2">
                  <span></span>
                </div>
              </div>
              <div class="table-list">
                <div class="flex">
                  <span>彩超室</span>
                </div>
                <div class="flex flex2">
                  <span>妇科彩超</span>
                </div>
                <div class="flex">
                  <el-checkbox v-model="checkbox"></el-checkbox>
                </div>
                <div class="flex flex2">
                  <span></span>
                </div>
              </div>
              <div class="table-list">
                <div class="flex">
                  <span></span>
                </div>
                <div class="flex flex2">
                  <span>乳腺彩超</span>
                </div>
                <div class="flex">
                  <el-checkbox v-model="checkbox"></el-checkbox>
                </div>
                <div class="flex flex2">
                  <span></span>
                </div>
              </div>
              <div class="table-list">
                <div class="flex">
                  <span></span>
                </div>
                <div class="flex flex2">
                  <span>肝胆胰脾彩超</span>
                </div>
                <div class="flex"></div>
                <div class="flex flex2">
                  <span></span>
                </div>
              </div>
              <div class="table-list">
                <div class="flex">
                  <span></span>
                </div>
                <div class="flex flex2">
                  <span>双肾输尿管膀胱彩超</span>
                </div>
                <div class="flex"></div>
                <div class="flex flex2">
                  <span></span>
                </div>
              </div>
              <div class="table-list">
                <div class="flex">
                  <span></span>
                </div>
                <div class="flex flex2">
                  <span>前列腺超声</span>
                </div>
                <div class="flex"></div>
                <div class="flex flex2">
                  <span></span>
                </div>
              </div>
              <div class="table-list">
                <div class="flex">
                  <span></span>
                </div>
                <div class="flex flex2">
                  <span>肝胆胰脾双肾彩超</span>
                </div>
                <div class="flex"></div>
                <div class="flex flex2">
                  <span></span>
                </div>
              </div>
              <div class="table-list">
                <div class="flex">
                  <span>放射科</span>
                </div>
                <div class="flex flex2">
                  <span>胸部透视</span>
                </div>
                <div class="flex">
                  <el-checkbox v-model="checkbox"></el-checkbox>
                </div>
                <div class="flex flex2">
                  <span></span>
                </div>
              </div>
              <div class="table-list">
                <div class="flex">
                  <span></span>
                </div>
                <div class="flex flex2">
                  <span>胸部拍片</span>
                </div>
                <div class="flex">
                  <el-checkbox v-model="checkbox"></el-checkbox>
                </div>
                <div class="flex flex2">
                  <span></span>
                </div>
              </div>
              <div class="table-list">
                <div class="flex">
                  <span></span>
                </div>
                <div class="flex flex2">
                  <span>颈椎拍片</span>
                </div>
                <div class="flex">
                  <el-checkbox v-model="checkbox"></el-checkbox>
                </div>
                <div class="flex flex2">
                  <span></span>
                </div>
              </div>
              <div class="table-list">
                <div class="flex">
                  <span></span>
                </div>
                <div class="flex flex2">
                  <span>颈椎拍片</span>
                </div>
                <div class="flex">
                  <el-checkbox v-model="checkbox"></el-checkbox>
                </div>
                <div class="flex flex2">
                  <span></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      options: [{
        value: '',
        label: ''
      }],
      input: '',
      value: '',
      checkbox: false,
      checkbox1: true
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.add {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .69);

  .add-box {
    position: relative;
    width: 96%;
    height: 94%;
    padding: 15px;
    background-color: #fff;
    border-radius: 6px;
    box-sizing: border-box;

    .close {
      position: absolute;
      top: 14px;
      right: 18px;
      color: #707070;
      font-size: 26px;
      cursor: pointer;
    }

    .add-title {
      width: 100%;
      height: 43px;
      line-height: 43px;
      padding-left: 12px;
      border-bottom: 1px solid #B1B1B1;
      box-sizing: border-box;
      color: #2A374D;
      font-size: 18px;
      font-weight: 600;
    }

    .add-line {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 55px;
      padding-left: 12px;
      border-bottom: 1px solid #B1B1B1;
      box-sizing: border-box;

      .line-left {
        display: flex;
        align-items: center;

        .checkbox {
          margin-right: 30px;
        }
      }

      .line-right {
        display: flex;
        align-items: center;

        .el-button {
          margin-left: 10px;
          margin-right: 10px;
        }
      }
    }

    .add-content {
      display: flex;
      justify-content: space-evenly;
      width: 100%;
      height: 300px;
      border-bottom: 1px solid #B1B1B1;
      box-sizing: border-box;

      .content-item {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        width: calc(20% - 24px);
        height: 100%;

        .item {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          width: 100%;

          .key {
            margin-right: 10px;
            white-space: nowrap;
            color: #17212B;
            font-size: 18px;
          }

          .key1 {
            margin-left: 15px;
          }

          .input {
            width: 228px;
          }

          .input1 {
            width: 40px;
          }

          .el-select {
            width: 228px;
          }

          /deep/.el-date-editor {
            width: 228px;
          }
        }

        .item1 {
          justify-content: flex-start;

          .el-button {
            margin-right: 20px;
          }
        }

        .img {
          width: 160px;
          height: 170px;
          margin-left: 20px;
          background-color: #CDCDCD;
        }
      }
    }

    .add-table {
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: calc(100% - 398px);

      .table-item {
        width: calc(25% - 20px);
        height: 100%;

        .table-title {
          width: 100%;
          height: 45px;
          line-height: 45px;
          padding-left: 8px;
          box-sizing: border-box;
          color: #17212B;
          font-size: 18px;
        }

        .table-box {
          width: 100%;
          height: calc(100% - 45px);
          border: 1px solid #707070;
          box-sizing: border-box;

          .table-head {
            display: flex;
            align-items: center;
            width: 100%;
            height: 30px;
            background-color: #CDCDCD;
          }

          .flex {
            flex: 1;
            text-align: center;
            color: #17212B;
            font-size: 16px;
          }

          .flex2 {
            flex: 2;
          }

          .table-scroll {
            overflow-y: scroll;
            width: 100%;
            height: calc(100% - 30px);

            &::-webkit-scrollbar {
              width: 0;
            }

            .table-list {
              display: flex;
              align-items: center;
              width: 100%;
              height: 36px;
            }

            .table-active {
              background-color: #C9F8C2;
            }
          }
        }
      }
    }
  }
}
</style>
