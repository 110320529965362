<template>
  <div class="see">
    <div class="userinfo">
      <div class="content">
        <el-input placeholder="姓名/身份证号" v-model="params.sxtj" suffix-icon="el-icon-search" size="small" @blur="getInfo">
        </el-input>
        <div class="scroll" id="scroll" @scroll="handleScroll">
          <div :id="'item' + index" class="scroll-item" :class="{ 'scroll-active': index === selectIndex }" v-for="(item, index) in list" :key="index" @click="select(index)">
            <div class="scroll-item-line">
              <div class="scroll-text">
                <span>序号：{{ item.id || '-' }}</span>
              </div>
              <div class="scroll-text">
                <span>姓名：{{ item.name || '-' }}</span>
              </div>
            </div>
            <div class="scroll-item-line">
              <div class="scroll-text">
                <span>年龄：{{ item.age || '-' }}</span>
              </div>
              <div class="scroll-text">
                <span>性别：{{ item.xb | xbType }}</span>
              </div>
            </div>
            <div class="scroll-item-line">
              <div class="scroll-text scroll-text1">
                <span>学校：{{ item.ssxx || '-' }}</span>
              </div>
            </div>
            <div class="scroll-item-line">
              <div class="scroll-text scroll-text1">
                <span>年级：{{ item.ssnj || '-' }}</span>
              </div>
            </div>
            <div class="scroll-item-line">
              <div class="scroll-text scroll-text1">
                <span>班级：{{ item.ssbj || '-' }}</span>
              </div>
            </div>
            <div class="scroll-item-line">
              <div class="scroll-text scroll-text1">
                <span>身份证号：{{ item.sfzh || '-' }}</span>
              </div>
            </div>
            <div class="scroll-item-line">
              <div class="scroll-text scroll-text1">
                <span>{{ item.tmdy | tjztType }} {{ item.tjsfyc | ycType }} {{ item.tjqk | qxlxType }} {{ item.hcflag | hcqkType }}</span>
              </div>
            </div>
            <!-- <div class="scroll-item-line">
              <div class="scroll-text scroll-text1">
                <span>体检状态：{{ item.tmdy | tjztType }} </span>
              </div>
            </div>
            <div class="scroll-item-line">
              <div class="scroll-text scroll-text1">
                <span>是否异常：{{ item.tjsfyc | ycType }}</span>
              </div>
            </div>
            <div class="scroll-item-line">
              <div class="scroll-text scroll-text1">
                <span>缺项漏项：{{ item.tjqk | qxlxType }}</span>
              </div>
            </div>
            <div class="scroll-item-line">
              <div class="scroll-text scroll-text1">
                <span>核查情况：{{ item.hcflag | hcqkType }}</span>
              </div>
            </div> -->
          </div>
        </div>
      </div>
      <div class="footer">
        <el-button type="success" size="small" icon="el-icon-plus" circle @click="add"></el-button>
        <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="query.pageNum"
      background
      :page-size="query.pageSize"
      layout="pager"
      :total="query.total" small :pager-count="5"></el-pagination>
      </div>

    </div>
    <div class="see-box">
      <div class="see-head">
        <div class="see-tab">
          <div class="see-item" :class="{'see-active': tabIndex === index}" v-for="(item, index) in tabs" :key="index" @click="tabClick(index)">
            <span>{{ item }}</span>
          </div>
        </div>
        <div class="return">
          <el-button type="primary" size="mini" @click="close">返回</el-button>
        </div>
      </div>
      <div class="see-son">
        <Tab1 v-if="tabIndex === 0" ref="tab1"></Tab1>
        <Tab2 v-if="tabIndex === 1" ref="tab2"></Tab2>
      </div>
    </div>
  </div>
</template>

<script>
import Tab1 from './tab1/tab1.vue'
import Tab2 from './tab2/tab2.vue'
export default {
  components: {
    Tab1,
    Tab2
  },
  data () {
    return {
      value: '',
      tabIndex: 0,
      tabs: Object.freeze(['健康体检卡', '健康体检项目']),
      params: {
        start: '0',
        length: '10',
        tjjgbh: '',
        sxtj: '',
        ssxxid: '',
        ssnjid: '',
        tcmcid: '',
        tjlxid: '',
        tjjd: '',
        tmsfdy: '',
        drflag: '',
        begtjsj: '',
        endtjsj: ''
      },
      // 分页数据
      query: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      list: [],
      selectTmcode: '',
      selectIndex: -1
    }
  },
  filters: {
    xbType (val) {
      if (!val) return '-'
      if (val === '1') {
        return '男'
      } else {
        return '女'
      }
    },
    tjztType (val) {
      if (val === '1') return '已体检'
      if (val === '0') return '未体检'
      return '-'
    },
    ycType (val) {
      if (val === '1') return '有异常'
      if (val === '0') return '无异常'
      return '-'
    },
    qxlxType (val) {
      if (val === '1') return '缺项漏项'
      if (val === '0') return '正常检查'
      return '-'
    },
    hcqkType (val) {
      if (val === '1') return '已核查'
      if (val === '0') return '未核查'
      return '-'
    }
  },
  created () {
    this.selectTmcode = this.$parent.selectInfo.tmcode
    this.getInfo()
  },
  methods: {
    async getInfo (type) {
      // 接口文档 https://app.apifox.com/project/2726972/apis/api-159158565
      console.log(this.params)
      try {
        this.params.start = this.query.pageNum === 1 ? '0' : (this.query.pageNum - 1) * 10 + ''
        this.params.length = this.query.pageSize + ''
        const { data: res } = await this.$http.get('/ykdzhctxstjbs', { params: this.params })
        console.log('体检列表', res)
        if (res.code !== 0 || res.data === null || res.data.length < 1) {
          return this.$message({
            message: res.msg || '暂无信息',
            type: 'warning'
          })
        }
        this.list = res.data
        this.query.total = res.recordsTotal

        if (!type) {
          const index = this.list.findIndex(item => item.tmcode === this.selectTmcode)
          console.log(index)
          this.selectIndex = index
          this.$nextTick(() => {
            document.getElementById('item' + this.selectIndex).scrollIntoView({
              behavior: 'smooth', block: 'start', inline: 'nearest'
            })
          })
        } else {
          this.selectIndex = -1
          this.query.pageNum++
          this.$nextTick(() => {
            document.getElementById('item0').scrollIntoView()
          })
        }
      } catch (err) {
        console.log('体检列表', err)
      }
    },
    handleScroll (e) {
      const { scrollTop, clientHeight, scrollHeight } = e.target
      if (scrollTop + clientHeight >= scrollHeight) {
        console.log('滚动到底部了！')
        this.getInfo('scroll')
      }
    },
    add () {
      // const target = document.getElementById('scroll')
      // const parent = document.getElementById('item' + this.selectIndex)
      // console.log(parent, target, 'item' + this.selectIndex)
      // parent.scrollTo(0, target.offsetTop - parent.offsetTop)
      // document.getElementById('item' + this.selectIndex).scrollIntoView({
      //   behavior: 'smooth', block: 'end', inline: 'nearest'
      // })
    },
    select (index) {
      if (index === this.selectIndex) return
      this.selectIndex = index
      this.selectTmcode = this.list[index].tmcode
      console.log(this.selectTmcode)
      if (this.tabIndex === 0) {
        this.$refs.tab1.getInfo()
      } else if (this.tabIndex === 1) {
        this.$refs.tab2.getInfo()
      }
    },
    tabClick (index) {
      this.tabIndex = index
    },
    close () {
      try {
        this.$parent.selectInfo.tmcode = ''
        this.$parent.selectInfo.sfzh = ''
      } catch (error) {

      }
      this.$emit('close')
    },
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
      this.query.pageNum = val
      this.getInfo()
    }
  }
}
</script>

<style lang="less" scoped>
.see {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 88;
    display: flex;
  width: 100%;
  height: 100%;
  background-color: #F0F0F0;

  .userinfo {
    width: 270px;
    height: 100%;
    margin-left: 10px;
    padding-top: 18px;
    background-color: #25A0FF;
    box-sizing: border-box;

    .content {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: calc(100% - 50px);
      border-radius: 6px;
      box-sizing: border-box;

      .el-input {
        width: 95%;

        /deep/.el-input__inner {
          background-color: #fff;
          border-radius: 6px;
          border: 1px solid #1F72D3;
        }

        /deep/.el-input__inner::placeholder {
          color: #999999;
        }

        /deep/.el-input__suffix {
          color: #999999;
        }
      }

      .scroll {
        overflow-y: scroll;
        width: calc(100% - 10px);
        height: calc(100% - 50px);
        margin: 0 auto;
        margin-top: 10px;
        background-color: #fff;

        &::-webkit-scrollbar {
          width: 0;
        }

        // &::-webkit-scrollbar {
        //   width: 6px;
        //   height: 6px;
        //   background-color: transparent;
        //   border-radius: 3px;
        // }

        &::-webkit-scrollbar-thumb {
          background-color: rgba(255, 255, 255, .4);
          border-radius: 10px;
        }

        &::-webkit-scrollbar-corner {
          background-color: transparent;
        }

        .scroll-item {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 100%;
          margin-bottom: 10px;
          padding: 10px 5px;
          background: rgb(226, 222, 222);
          box-sizing: border-box;

          .scroll-item-line {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin: 5px 0;

            .scroll-text {
              // overflow: hidden;
              // text-overflow: ellipsis;
              // white-space: nowrap;
              width: 50%;
              color: #333333;
              font-size: 14px;
            }

            .scroll-text1 {
              width: 100%;
            }
          }
        }

        .scroll-active {
          border: 1px solid #25A0FF;
        }
      }
    }
    .footer {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      width: 100%;
      height: 50px;
    }
  }

  .see-box {
    width: calc(100% - 300px);
    height: 100%;
    margin-left: 20px;
    padding: 20px;
    background-color: #fff;
    box-sizing: border-box;

    .see-head {
      display: flex;
      width: 100%;
      height: 50px;

      .see-tab {
        display: flex;
        width: calc(100% - 200px);
        height: 50px;
        background-color: #A2A2A2;

        .see-item {
          width: 178px;
          height: 50px;
          line-height: 50px;
          text-align: center;
          color: #fff;
          font-size: 18px;
          cursor: pointer;
        }
        .see-active {
          background-color: #25A0FF;
        }
      }

      .return {
        display: flex;
        flex-direction: row-reverse;
        width: 200px;
        height: 34px;

        .el-button {
          min-width: 95px;
        }
      }
    }

    .see-son {
      width: 100%;
      height: calc(100% - 70px);
      margin-top: 20px;
    }
  }
}
</style>
