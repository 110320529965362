<template>
  <div class="look">
    <div class="look-box">
      <div class="close" @click="close">
        <span class="el-icon-close"></span>
      </div>
      <div class="look-content">
        <div class="info">
          <span>编号：304076981</span>
          <span>姓名：李明明</span>
          <span>性别:男</span>
          <span>身份证:130429193205164852</span>
          <span>电话:17367759256</span>
        </div>
        <div class="head">
          <div class="flex flex2">
            <span>项目名称</span>
          </div>
          <div class="flex">
            <span>结果</span>
          </div>
          <div class="flex">
            <span>提示</span>
          </div>
          <div class="flex">
            <span>单位</span>
          </div>
          <div class="flex">
            <span>参考范围</span>
          </div>
        </div>
        <div class="list">
          <div class="flex flex2">
            <span>红细胞压积</span>
          </div>
          <div class="flex">
            <span>36.2</span>
          </div>
          <div class="flex">
            <span></span>
          </div>
          <div class="flex">
            <span>%</span>
          </div>
          <div class="flex">
            <span>39--48</span>
          </div>
        </div>
        <div class="list">
          <div class="flex flex2">
            <span>血红蛋白</span>
          </div>
          <div class="flex">
            <span>36.2</span>
          </div>
          <div class="flex">
            <span></span>
          </div>
          <div class="flex">
            <span>g/L</span>
          </div>
          <div class="flex">
            <span>110--160</span>
          </div>
        </div>
        <div class="list">
          <div class="flex flex2">
            <span>淋巴细胞比率</span>
          </div>
          <div class="flex">
            <span>36.2</span>
          </div>
          <div class="flex">
            <span></span>
          </div>
          <div class="flex">
            <span>%</span>
          </div>
          <div class="flex">
            <span>20--40</span>
          </div>
        </div>
        <div class="list">
          <div class="flex flex2">
            <span>平均血红蛋白量</span>
          </div>
          <div class="flex">
            <span>36.2</span>
          </div>
          <div class="flex">
            <span></span>
          </div>
          <div class="flex">
            <span>pg</span>
          </div>
          <div class="flex">
            <span>26--32</span>
          </div>
        </div>
        <div class="list">
          <div class="flex flex2">
            <span>平均血红蛋白浓度</span>
          </div>
          <div class="flex">
            <span>310.8</span>
          </div>
          <div class="flex">
            <span></span>
          </div>
          <div class="flex">
            <span>g/L</span>
          </div>
          <div class="flex">
            <span>320--450</span>
          </div>
        </div>
        <div class="list">
          <div class="flex flex2">
            <span>红细胞平均体积</span>
          </div>
          <div class="flex">
            <span>96.2</span>
          </div>
          <div class="flex">
            <span></span>
          </div>
          <div class="flex">
            <span>fL</span>
          </div>
          <div class="flex">
            <span>80--99</span>
          </div>
        </div>
        <div class="list">
          <div class="flex flex2">
            <span>平均血小板体积</span>
          </div>
          <div class="flex">
            <span>8.6</span>
          </div>
          <div class="flex">
            <span></span>
          </div>
          <div class="flex">
            <span>fL</span>
          </div>
          <div class="flex">
            <span>7.4--10.4</span>
          </div>
        </div>
        <div class="list">
          <div class="flex flex2">
            <span>血小板压积</span>
          </div>
          <div class="flex">
            <span>0.08</span>
          </div>
          <div class="flex">
            <span></span>
          </div>
          <div class="flex">
            <span>fL</span>
          </div>
          <div class="flex">
            <span>0.1--0.28</span>
          </div>
        </div>
        <div class="list">
          <div class="flex flex2">
            <span>血小板分布宽度</span>
          </div>
          <div class="flex">
            <span>24.3</span>
          </div>
          <div class="flex">
            <span></span>
          </div>
          <div class="flex">
            <span>fL</span>
          </div>
          <div class="flex">
            <span>10--17</span>
          </div>
        </div>
        <div class="list">
          <div class="flex flex2">
            <span>P-LCR</span>
          </div>
          <div class="flex">
            <span>24.3</span>
          </div>
          <div class="flex">
            <span></span>
          </div>
          <div class="flex">
            <span>fL</span>
          </div>
          <div class="flex">
            <span>10--17</span>
          </div>
        </div>
        <div class="info info1">
          <span>检查时间：2022.2.22</span>
          <span>检验人员：马依依</span>
          <span>机构：古城营镇卫生院</span>
        </div>
        <div class="buttons">
          <el-button @click="close">打印</el-button>
          <el-button @click="close">保存</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {

    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.look {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .69);

  .look-box {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 1136px;
    height: 90%;
    padding: 60px 94px;
    background-color: #fff;
    border-radius: 6px;
    box-sizing: border-box;

    .close {
      position: absolute;
      top: 14px;
      right: 18px;
      color: #707070;
      font-size: 26px;
      cursor: pointer;
    }

    .look-content {
      display: flex;
      flex-direction: column;
      width: 100%;
      background-color: #fff;

      .info {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        width: 100%;
        color: #2A374D;
        font-size: 18px;
      }

      .info1 {
        height: 70px;
        margin-top: 10px;
        border-top: 1px solid #B1B1B1;
      }

      .head {
        display: flex;
        align-items: center;
        width: 100%;
        height: 46px;
        margin-top: 6px;
        border-top: 1px solid #B1B1B1;
        border-bottom: 1px solid #B1B1B1;
        box-sizing: border-box;
      }

      .flex {
        flex: 1;
        height: 40px;
        line-height: 40px;
        text-align: center;
        color: #2A374D;
        font-size: 18px;
      }

      .flex2 {
        flex: 2;
      }

      .list {
        display: flex;
        width: 100%;
        margin-top: 4px;
      }

      .buttons {
        display: flex;
        justify-content: center;
        margin-top: 30px;

        .el-button {
          margin-left: 35px;
          margin-right: 35px;
          background-color: #3782F2;
          border: none;
          color: #fff;
          font-size: 22px;
        }
      }
    }
  }
}
</style>
