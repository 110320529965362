var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"add"},[_c('div',{staticClass:"add-title"},[_vm._m(0),_c('el-input',{staticClass:"input",attrs:{"placeholder":"姓名/身份证号","suffix-icon":"el-icon-search"}})],1),_c('div',{staticClass:"add-box"},[_vm._m(1),_c('div',{staticClass:"add-content"},[_c('div',{staticClass:"add-item"},[_vm._m(2),_c('el-input')],1),_c('div',{staticClass:"add-item"},[_vm._m(3),_c('el-input')],1),_c('div',{staticClass:"add-item"},[_vm._m(4),_c('el-select',{attrs:{"placeholder":"请选择"},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},_vm._l((_vm.options),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1),_c('div',{staticClass:"add-item"},[_vm._m(5),_c('el-select',{attrs:{"placeholder":"请选择"},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},_vm._l((_vm.options),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1),_c('div',{staticClass:"add-item"},[_vm._m(6),_c('el-date-picker',{attrs:{"type":"daterange","range-separator":"至","start-placeholder":"开始日期","end-placeholder":"结束日期"}})],1),_c('div',{staticClass:"add-item"},[_vm._m(7),_c('el-input')],1),_c('div',{staticClass:"add-item add-item1"},[_vm._m(8),_c('el-input')],1)]),_vm._m(9),_c('div',{staticClass:"add-upload"},[_c('el-button',{staticClass:"upload",attrs:{"size":"small"}},[_vm._v("上传协议")]),_vm._m(10)],1),_vm._m(11)]),_c('div',{staticClass:"add-btns"},[_c('el-button',{attrs:{"size":"small"}},[_vm._v("保存暂不提交")]),_c('el-button',{attrs:{"size":"small"},on:{"click":_vm.close}},[_vm._v("保存并提交")]),_c('el-button',{staticClass:"gray",attrs:{"size":"small"},on:{"click":_vm.close}},[_vm._v("取消")])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('span',[_vm._v("新增预定")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"add-box-title"},[_c('span',[_vm._v("新增信息")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"add-key"},[_c('span',[_vm._v("预定人姓名：")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"add-key"},[_c('span',[_vm._v("预定人电话：")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"add-key"},[_c('span',[_vm._v("与老人关系：")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"add-key"},[_c('span',[_vm._v("预定床位：")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"add-key"},[_c('span',[_vm._v("开始日期：")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"add-key"},[_c('span',[_vm._v("预定时长(天)：")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"add-key"},[_c('span',[_vm._v("定金应收： ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"add-box-title1"},[_c('span',[_vm._v("预定协议")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"add-tip"},[_c('span',[_vm._v("下载预定协议")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"add-img"},[_c('img',{staticClass:"img",attrs:{"src":"","alt":""}}),_c('img',{staticClass:"img",attrs:{"src":"","alt":""}}),_c('img',{staticClass:"img",attrs:{"src":"","alt":""}})])
}]

export { render, staticRenderFns }