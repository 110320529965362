<template>
  <div class="tab">
    <div class="tab-box">
      <div class="tab-title">
        <span class="left">实时数据统计</span>
        <div class="right">
          <el-button type="primary" @click="exportExcel">
            <span class="el-icon-download"></span>
            <span>导出</span>
          </el-button>
          <el-button @click="getInfo">刷新</el-button>
        </div>
      </div>
      <div class="tab-label">
        <div class="label-item">
          <span>体检总人数：{{ dataTotalInfo.tjrssum }}</span>
        </div>
        <div class="label-item">
          <span>剩余体检人数：{{ dataTotalInfo.sytjrs }}</span>
        </div>
        <div class="label-item">
          <span>体检任务总数：{{ dataTotalInfo.tjrwssum }}</span>
        </div>
        <div class="label-item">
          <span>已体检机构数：{{ dataTotalInfo.tjtssum }}</span>
        </div>
        <div class="label-item">
          <span>剩余体检机构：{{ dataTotalInfo.sytjts }}</span>
        </div>
      </div>
      <div class="tab-chart" id="echart"></div>
    </div>
    <div class="tab-search">
      <div class="search-line">
        <div class="search-item">
          <div class="key">
            <span>体检日期</span>
          </div>
          <el-date-picker v-model="searchParams.tjrq" type="daterange" value-format="yyyy-mm-dd" range-separator="至" start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </div>
        <div class="search-item">
          <div class="key">
            <span>姓名</span>
          </div>
          <el-input class="input" maxlength="5" v-model="searchParams.name"></el-input>
        </div>
        <div class="search-item">
          <div class="key">
            <span>身份证号</span>
          </div>
          <el-input class="input" maxlength="18" v-model="searchParams.sfzh"></el-input>
        </div>
      </div>
      <div class="search-line">
        <div class="search-item">
          <div class="key">
            <span>体检有无异常</span>
          </div>
          <el-radio-group v-model="searchParams.kpjnr">
            <el-radio label="1">正常</el-radio>
            <el-radio label="2">异常</el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="search-button">
        <el-button @click="getList">
          <span class="el-icon-search"></span>
          <span>查询</span>
        </el-button>
        <el-button @click="reset">
          <span class="el-icon-refresh"></span>
          <span>重置</span>
        </el-button>
        <el-button>
          <span class="el-icon-caret-bottom"></span>
          <span>高级</span>
        </el-button>
      </div>
    </div>

    <div class="tab-table">
      <el-table :data="tableData" height="190" style="width: 100%">
        <el-table-column prop="id" label="序号">
        </el-table-column>
        <el-table-column prop="tjrq" label="体检日期">
        </el-table-column>
        <el-table-column prop="name" label="姓名">
        </el-table-column>
        <el-table-column prop="sfzh" label="身份证号">
        </el-table-column>
        <el-table-column prop="nametage" label="人群分类">
        </el-table-column>
        <el-table-column prop="age" label="年龄" width="86">
        </el-table-column>
        <el-table-column prop="tjjgmc" label="机构">
        </el-table-column>
        <el-table-column prop="usercode" label="学校信息">
        </el-table-column>
        <el-table-column prop="usercode" label="班级信息">
        </el-table-column>
        <el-table-column prop="usercode" label="体检有无异常" width="200">
          <template slot-scope="scope">
            <el-tag :type="scope.row.kpjnr == '1' ? 'success' : 'danger'">{{ scope.row.kpjnr == '1' ? '是' : '否' }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="usercode" label="健康评价异常" width="200">
          <template slot-scope="scope">
            <el-tag :type="scope.row.xdtyzdesc == '1' ? 'success' : 'danger'">{{ scope.row.xdtyzdesc == '1' ? '是' : '否' }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="usercode" label="上传状态">
          <template slot-scope="scope">
            <el-tag :type="scope.row.upload_flag == '1' ? 'success' : 'danger'">{{ scope.row.upload_flag == '1' ? '已上传' : '未上传' }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="usercode" label="同步状态">
          <template slot-scope="scope">
            <el-tag :type="scope.row.sftbflag == '1' ? 'success' : 'danger'">{{ scope.row.sftbflag == '1' ? '已同步' : '未同步' }}</el-tag>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="query.pageNum" background :page-size="query.pageSize" layout="total, prev, pager, next, jumper"  :total="query.total"></el-pagination>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data () {
    return {
      dataTotalInfo: {
        tjrssum: '-',
        sytjrs: '-',
        tjrwssum: '-',
        tjtssum: '-',
        sytjts: '-'
      },
      xDatas: [],
      yDatas1: [],
      yDatas2: [],
      searchParams: {
        // 分页起始
        start: '0',
        // 分页长度
        length: '10',
        tjrq: '',
        name: '',
        sfzh: '',
        kpjnr: '',
        tjjgbh: '',
        czyid: ''
      },
      query: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      tableData: [],
      myChart4: null,
      myChart: null,
      date: '',
      value: '',
      card: '',
      radio: '',
      total: 400,
      currentPage: 0
    }
  },
  created () {
    this.getInfo()
    this.searchParams.tjjgbh = this.loginInfo.jgbh
    this.searchParams.czyid = this.loginInfo.usercode
    window.addEventListener('resize', this.getresize)
  },
  computed: {
    ...mapState(['loginInfo'])
  },
  methods: {
    getresize () {
      setTimeout(() => {
        this.myChart.resize()
      }, 500)
    },
    async getInfo () {
      // 接口地址
      try {
        const { data: res } = await this.$http.post('/api/jkct/findhometjall', {
          jgbh: this.loginInfo.jgbh || '',
          jgjb: this.loginInfo.jgjb || ''
        })
        console.log('实时数据统计', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.dataTotalInfo = res.data
        const list = this.dataTotalInfo.ykdjgxxtjList
        if (list.length < 1) return
        for (let index = 0; index < list.length; index++) {
          this.xDatas.push(list[index].xzjdmc + (list[index].tjrq || ''))
          this.yDatas1.push(list[index].tjrs)
          this.yDatas2.push(list[index].tjrws)
        }
        this.canvas()
      } catch (error) {
        console.log('实时数据统计请求失败', error)
      }
    },
    exportExcel () {
      window.location.href = '/api/excels/ExcelDownloads?jgbh=' + this.loginInfo.jgbh
    },
    canvas () {
      if (!document.getElementById('echart')) return
      var chartDom = document.getElementById('echart')
      this.myChart = this.$echarts.init(chartDom)
      const option = {
        color: ['#006699', '#4CABCE'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          orient: 'horizontal',
          x: '70%',
          y: '10%',
          // x: 'right',
          textStyle: {
            fontSize: 12,
            color: '#606266'
          }
          // padding: [25, 34, 0, 0]
        },
        dataZoom: [
          {
            xAxisIndex: 0,
            type: 'slider',
            height: 8,
            disabled: false,
            zoomLock: true,
            preventDefaultMouseMove: false,
            filterMode: 'empty',
            startValue: 0, // 从头开始。
            endValue: 3 // 一次性展示6个。
          }

        ],
        grid: {
          show: false,
          top: '20%',
          right: '14%',
          bottom: '20%',
          left: '14%'
        },
        xAxis: {
          type: 'category',
          data: this.xDatas,
          axisLabel: {
            show: true,
            color: '#909090',
            fontSize: 13,
            interval: 0
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#909090'
            },
            textStyle: {
              color: '#909090',
              fontSize: 13
            }
          },
          axisTick: {
            // y轴刻度线
            show: false
          }
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: true,
            lineStyle: {
              color: '#909090'
            }
          },
          axisLabel: {
            show: true,
            color: '#909090',
            fontSize: 14
          },
          splitLine: {
            lineStyle: {
              color: '#909090'
            }
          }
        },
        series: [
          {
            name: '总人数',
            data: this.yDatas1,
            type: 'bar',
            barWidth: '19px'
          },
          {
            name: '已体检数',
            data: this.yDatas2,
            type: 'bar',
            barWidth: '19px'
          }
        ]
      }
      option && this.myChart.setOption(option)
    },
    async getList () {
      // 接口地址
      try {
        this.tableData = []
        this.query.total = 0
        this.searchParams.start = this.query.pageNum === 1 ? '0' : (this.query.pageNum - 1) * 10 + ''
        console.log(this.searchParams)
        const { data: res } = await this.$http.get('/dockapi/findhomeyclist', {
          params: {
            ...this.searchParams,
            tjrq: this.searchParams.tjrq[0] + ',' + this.searchParams.tjrq[1]
          }
        })
        console.log('实时数据统计列表', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        if (res.data.length < 1) return this.$message.error('暂无数据')
        this.tableData = res.data
        this.query.total = res.recordsTotal
      } catch (error) {
        console.log('实时数据统计列表请求失败', error)
      }
    },
    reset () {
      this.searchParams.tjrq = ''
      this.searchParams.name = ''
      this.searchParams.sfzh = ''
      this.searchParams.kpjnr = ''
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
      this.query.pageNum = val
      this.getList()
    }
  }
}
</script>

<style lang="less" scoped>
.tab {
  display: flex;
  flex-direction: column;
  width: 100%;

  .tab-box {
    width: 100%;
    height: 575px;
    margin-top: 20px;
    padding: 20px;
    padding-top: 0;
    background-color: #fff;
    border-radius: 18px;
    box-sizing: border-box;

    .tab-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 90px;

      .left {
        color: #17212B;
        font-size: 30px;
        font-weight: 600;
      }

      .right {
        display: flex;
      }
    }

    .tab-label {
      display: flex;
      align-items: center;
      height: 40px;

      .label-item {
        min-width: 180px;
        height: 40px;
        line-height: 40px;
        margin-right: 40px;
        padding: 0 20px;
        text-align: center;
        background-color: #3FA1FF;
        border-radius: 6px;
        color: #fff;
        font-size: 22px;
      }

      .label-item:last-child {
        margin-right: 0;
      }
    }

    .tab-chart {
      width: 100%;
      height: calc(100% - 130px);
    }
  }

  .tab-search {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 276px;
    margin-top: 20px;
    padding: 30px 20px;
    background-color: #fff;
    border-radius: 18px;

    .search-line {
      display: flex;
      align-items: center;
      width: 100%;

      .search-item {
        display: flex;
        align-items: center;
        margin-right: 46px;

        .key {
          margin-right: 20px;
          white-space: nowrap;
          color: #17212B;
          font-size: 30px;
        }

        /deep/.el-range-input {
          font-size: 20px;
        }

        /deep/.el-range-separator {
          font-size: 20px;
        }

        /deep/.el-input__inner {
          font-size: 20px;
        }

        /deep/.el-radio-group {
          display: flex;
          align-items: center;
        }

        /deep/.el-radio {
          display: flex;
          align-items: center;
        }

        /deep/.el-radio__inner {
          width: 35px;
          height: 35px;
        }

        /deep/.el-radio__label {
          color: #17212B;
          font-size: 30px;
        }
      }

      .search-item:last-child {
        margin-right: 0;
      }
    }

    .search-button {
      display: flex;
      justify-content: center;
      width: 100%;

      .el-button {
        background-color: #3FA1FF;
        color: #fff;
        font-size: 30px;
      }
    }
  }

  .tab-table {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 260px;
    margin-top: 20px;
    background-color: #fff;
    border-radius: 18px;

    // 表格样式修改
    .el-table {
      background-color: #fff;

      &::before {
        background-color: transparent;
      }

      // 表头首行
      /deep/th.el-table__cell.is-leaf {
        border-bottom: none;
      }

      /deep/td.el-table__cell {
        border-bottom: 1px solid #fff;
      }

      // 表头背景颜色
      /deep/th.el-table__cell {
        background-color: #fff;
      }

      /deep/.el-table__cell {
        text-align: center;
        color: #17212B;
        font-size: 20px;
      }

      /deep/tr {
        background-color: #fff;
      }

      /deep/.el-table__body tr:hover>td.el-table__cell {
        background-color: transparent !important;
      }

      /deep/.el-checkbox__inner {
        background-color: transparent;
      }

      /deep/.is-indeterminate .el-checkbox__inner,
      /deep/.is-checked .el-checkbox__inner {
        background-color: #fff;
      }

      /deep/.el-table__body-wrapper::-webkit-scrollbar {
        width: 0;
      }

      /deep/.el-table__empty-text {
        color: #fff;
      }

      .flex {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        height: 300px;
        margin-top: 20px;
        background-color: #fff;
        box-sizing: border-box;

        .item {
          font-size: 14px;
          cursor: pointer;
        }

        .green {
          color: #17AF12;
        }

        .red {
          color: #FF6600;
        }

        .organ {
          color: #FF0000;
        }

        .icon {
          color: #0B75EF;
          font-size: 24px;
          cursor: pointer;
        }
      }
    }

    // 页码样式修改
    .el-pagination {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-top: 20px;

      /deep/.el-pagination__total,
      /deep/.el-pagination__jump {
        color: #000000;

        .el-input__inner {
          color: #fff;
          background-color: transparent;
        }
      }

      /deep/.el-pager li {
        background-color: #ADADAD;
        color: #fff;
      }

      /deep/.el-pager li:not(.disabled).active {
        background-color: #0077F9;
      }
    }
  }

}
</style>
