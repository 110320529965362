<template>
  <div class="registration">
    <div class="son-tab">
      <span class="son-item son-active">新增</span>
      <el-input placeholder="姓名/身份证号" suffix-icon="el-icon-search" class="see-search">
      </el-input>
    </div>
    <div class="run-search">
      <div class="run-item">
        <div class="key">
          <span>登记日期 ：</span>
        </div>
        <el-date-picker type="date" placeholder="请选择">
        </el-date-picker>
      </div>
      <div class="run-item">
        <div class="key">
          <span>家属姓名</span>
        </div>
        <el-input placeholder="请输入"></el-input>
      </div>
      <!-- <el-button>搜索</el-button>
      <el-button>重置</el-button> -->
    </div>

    <div class="son-add">
      <div class="son-add-icon" @click="openUpdate">
        <span class="el-icon-plus"></span>
      </div>
    </div>

    <el-table :data="tableData" border height="50%">
      <el-table-column label="序号" type="index" width="70">
      </el-table-column>
      <el-table-column prop="name" label="药品名称">
      </el-table-column>
      <el-table-column prop="address" label="数量">
      </el-table-column>
      <el-table-column prop="address" label="有效期">
      </el-table-column>
      <el-table-column prop="address" label="剂量">
      </el-table-column>
      <el-table-column prop="address" label="服法">
      </el-table-column>
      <el-table-column label="操作">
        <template>
          <!-- <div class="table-run">
            <span @click="openUpdate">编辑</span>
            <span>删除</span>
          </div> -->
          <el-button size="mini" type="primary" @click="openUpdate">编辑</el-button>
          <el-button size="mini" type="danger">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage4"
      :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper" :total="400" background>
    </el-pagination>
    <div class="see-close">
      <el-button size="small" @click="close">返回</el-button>
      <el-button size="small" type="primary" @click="close">保存</el-button>
    </div>
    <!-- 新增/编辑药品 -->
    <Update v-if="showUpdate" @close="closeUpdate"></Update>
  </div>
</template>

<script>
import Update from './update.vue'
export default {
  components: {
    Update
  },
  data () {
    return {
      tableData: [{
        date: '2016-05-02',
        name: '王小虎',
        address: '等待外出'
      }, {
        date: '2016-05-04',
        name: '王小虎',
        address: '未返回'
      }, {
        date: '2016-05-01',
        name: '王小虎',
        address: '逾期未返回'
      }],
      options: [{
        value: '',
        label: ''
      }],
      value: '',
      currentPage4: 4,
      tabIndex: 0,
      selectIndex: 0,
      showAdd: false,
      showUpdate: false,
      showSee: false
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    },
    openAdd () {
      this.showAdd = true
    },
    closeAdd () {
      this.showAdd = false
    },
    openUpdate () {
      this.showUpdate = true
    },
    closeUpdate () {
      this.showUpdate = false
    },
    openSee () {
      this.showSee = true
    },
    closeSee () {
      this.showSee = false
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.registration {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  padding: 0 20px;
  background-color: #409EFF;
  box-sizing: border-box;

  .son-tab {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    .son-item {
      height: 38px;
      line-height: 38px;
      margin-right: 40px;
      padding: 0 20px;
      text-align: center;
      background-color: #284867;
      color: #fff;
      font-size: 16px;
      cursor: pointer;
    }

    .son-active {
      background-color: #409EFF;
    }
  }

  .see-search {
    width: 246px;
  }

  .son-tab1 {
    border: none;
  }

  .son-add {
    display: flex;
    align-items: center;
    height: 70px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    .son-add-icon {
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      background-color: #409EFF;
      border-radius: 50%;
      color: #fff;
      font-size: 16px;
      cursor: pointer;
    }
  }

  .run-search {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    .run-item {
      display: flex;
      align-items: center;
      margin-right: 30px;

      .key {
        margin-right: 10px;
        white-space: nowrap;
        color: #000;
        font-size: 22px;
      }
    }
  }

  .el-table {
    margin-top: 10px;
  }

  /deep/.el-table .cell {
    text-align: center;
    color: #000;
  }

  .table-run {
    display: flex;
    align-items: center;
    justify-content: space-around;

    span {
      cursor: pointer;
    }
  }

  .el-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }

  .see-close {
    position: absolute;
    left: 40px;
    bottom: 0px;
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;
  }
}
</style>
