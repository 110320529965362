var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"details"},[_c('div',{staticClass:"details-scroll"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('el-checkbox-group',{model:{value:(_vm.checkList),callback:function ($$v) {_vm.checkList=$$v},expression:"checkList"}},[_c('el-checkbox',{attrs:{"label":"0"}},[_vm._v("辅助起床")]),_c('el-checkbox',{attrs:{"label":"1"}},[_vm._v("辅助洗漱")]),_c('el-checkbox',{attrs:{"label":"2"}},[_vm._v("辅助如厕")]),_c('el-checkbox',{attrs:{"label":"3"}},[_vm._v("辅助穿衣")])],1),_vm._m(6),_c('el-timeline',[_c('el-timeline-item',{attrs:{"timestamp":"系统创建并分配了晨起的任务给您，请尽快处理！"}},[_vm._v(" 10-20 15：00 ")]),_c('el-timeline-item',{attrs:{"timestamp":"系统创建并分配了晨起的任务给您，请尽快处理！"}},[_vm._v(" 10-20 15：00 ")])],1)],1),_c('div',{staticClass:"details-btns"},[_c('el-button',{attrs:{"size":"small"}},[_vm._v("清空")]),_c('el-button',{staticClass:"blue",attrs:{"size":"small"},on:{"click":_vm.close}},[_vm._v("提交")])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"details-title"},[_c('span',[_vm._v("任务详情")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"details-title1"},[_c('span',[_vm._v("任务信息")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"details-box"},[_c('div',{staticClass:"details-item"},[_c('span',{staticClass:"key"},[_vm._v("任务名称")]),_c('span',{staticClass:"value"},[_vm._v("晨起")])]),_c('div',{staticClass:"details-item"},[_c('span',{staticClass:"key"},[_vm._v("任务状态")]),_c('span',{staticClass:"value"},[_vm._v("进行中")])]),_c('div',{staticClass:"details-item"},[_c('span',{staticClass:"key"},[_vm._v("负责人")]),_c('span',{staticClass:"value"},[_vm._v("张敏")])]),_c('div',{staticClass:"details-item"},[_c('span',{staticClass:"key"},[_vm._v("任务描述")]),_c('span',{staticClass:"value"},[_vm._v("需要按照规定的程序给老人洗澡")])]),_c('div',{staticClass:"details-item"},[_c('span',{staticClass:"key"},[_vm._v("任务时间")]),_c('span',{staticClass:"value"},[_vm._v("8：00-8：30")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"details-title"},[_c('span',[_vm._v("老人信息")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"details-info"},[_c('img',{staticClass:"details-info-left",attrs:{"src":require("../../img/emr/user.png")}}),_c('div',{staticClass:"details-info-right"},[_c('span',[_vm._v("姓名：陈菊英")]),_c('span',[_vm._v("房间号：501/501-01")]),_c('span',[_vm._v("手机号：19996131427")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"details-title"},[_c('span',[_vm._v("子任务")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"details-title"},[_c('span',[_vm._v("老人信息")])])
}]

export { render, staticRenderFns }