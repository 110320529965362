<template>
  <div class="update">
    <div class="update-top">
      <div class="update-text">档案管理</div>
      <el-input placeholder="姓名/身份证号" suffix-icon="el-icon-search" class="update-input"></el-input>
    </div>
    <div class="update-scroll">
      <div class="info">
        <img src="../../../img/emr/user.png" class="info-img">
        <div class="info-box">
          <span class="info-line">老人姓名：李明明</span>
          <span class="info-line">身份证/护照号：男</span>
          <span class="info-line">床位号：23802304203</span>
        </div>
      </div>
      <div class="scroll-title border">
        <span>排班设置</span>
      </div>
      <el-table :data="tableData" height="400">
        <el-table-column label="序号" type="index" width="50">
        </el-table-column>
        <el-table-column prop="address" label="文件名">
        </el-table-column>
        <el-table-column prop="address" label="文件"></el-table-column>
        <el-table-column label="操作" width="140">
          <!-- slot-scope="scope" -->
          <template>
            <div class="run">
              <span>上传文件</span>
              <span>下载</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="update-btns">
      <el-button size="small" @click="close">返回</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tableData: [{
        date: '+选择',
        name: '白班',
        address: '李梅'
      }, {
        date: '+选择',
        name: '夜班',
        address: '李梅'
      }]
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.update {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0 40px;
  background-color: #081C38;
  box-sizing: border-box;

  .update-top {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 60px;
    padding: 0 10px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;

    .update-text {
      height: 38px;
      line-height: 38px;
      padding: 0 20px;
      background-color: #04B4D0;
      color: #fff;
      font-size: 22px;
    }

    .update-input {
      width: 246px;

      /deep/.el-input__inner {
        border: none;
        background: linear-gradient(153deg, #0A477B 0%, #0E67AD 100%);
        color: #fff;
        font-size: 18px;
      }

      /deep/.el-input__inner::-webkit-input-placeholder {
        color: #fff;
      }

      /deep/.el-input__suffix {
        color: #fff;
        font-size: 18px;
      }
    }
  }

  .update-scroll {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 120px);

    &::-webkit-scrollbar {
      width: 0;
    }

    .scroll-title {
      height: 40px;
      line-height: 40px;
      color: #01EEFD;
      font-size: 20px;
    }

    .border {
      margin-top: 10px;
      color: #04B4D0;
      font-size: 18px;
      border-bottom: 1px solid #01EEFD;
      box-sizing: border-box;
    }

    .info {
      display: flex;
      flex-direction: row;
      align-items: center;
      box-sizing: border-box;

      .info-img {
        width: 58px;
        height: 58px;
        margin-right: 18px;
      }

      .info-box {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        width: 50%;

        .info-line {
          margin-top: 16px;
          color: #01EEFD;
          font-size: 16px;
        }
      }

      .info-btns {
        display: flex;
        flex-direction: row;
        margin-top: 20px;

        .el-button {
          height: 38px;
          margin-left: 40px;
          background-color: #04B4D0;
          border: none;
          color: #fff;
          font-size: 16px;
        }
      }
    }

    .el-table {
      margin-top: 10px;
      border: 4px solid #073F73;
      background-color: #081C38;
    }

    .run {
      display: flex;
      justify-content: space-between;

      span {
        cursor: pointer;
      }
    }

    .input {
      width: 180px;
    }

    /deep/.el-table__body-wrapper {
      &::-webkit-scrollbar {
        // 整个滚动条
        width: 0; // 纵向滚动条的宽度
        background: rgba(213, 215, 220, 0.3);
        border: none;
      }

      &::-webkit-scrollbar-track {
        // 滚动条轨道
        border: none;
      }
    }

    el-table th.gutter {
      display: none;
      width: 0
    }

    /deep/.el-table colgroup col[name='gutter'] {
      display: none;
      width: 0;
    }

    /deep/.el-table__body {
      width: 100% !important;
    }

    /deep/.el-table__body-wrapper {
      background-color: #081C38;
    }

    /deep/.el-table th.el-table__cell.is-leaf {
      border-bottom: none;
    }

    /deep/.el-table th.el-table__cell {
      background-color: #024276;
    }

    /deep/.el-table .cell {
      text-align: center;
      color: #01EEFD;
    }

    /deep/.el-table tr {
      background-color: #081C38;
    }

    /deep/.el-table td.el-table__cell {
      border-bottom: 1px solid #01EEFD;
    }

    .el-table::before {
      height: 0;
    }

    /deep/.el-table tbody tr:hover>td {
      background-color: transparent;
    }

  }

  .update-btns {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 50px;
    padding-left: 30px;
    box-sizing: border-box;

    .el-button {
      margin-right: 100px;
      background-color: #33465C;
      border: 0;
      color: #fff;
    }

    .blue {
      background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
    }
  }
}
</style>
