<template>
  <div class="run">
    <div class="run-search">
      <span>任务奖励积分设置</span>
    </div>
    <div class="table">
      <el-table :data="tableData" height="100%" border>
        <el-table-column label="序号" type="index" width="100">
        </el-table-column>
        <el-table-column prop="name" label="任务名称">
        </el-table-column>
        <el-table-column prop="address" label="奖励积分">
          <template>
            <el-input class="input"></el-input>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="line">
      <span>积分与人民币比率设置</span>
    </div>
    <div class="line">
      <span>1积分 =</span>
      <el-input></el-input>
      <span>人民币</span>
    </div>
    <el-button size="small" type="primary">保存设置</el-button>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tableData: [{
        date: '2016-05-02',
        name: '王小虎',
        address: '等待外出'
      }, {
        date: '2016-05-04',
        name: '王小虎',
        address: '未返回'
      }, {
        date: '2016-05-01',
        name: '王小虎',
        address: '逾期未返回'
      }, {
        date: '2016-05-01',
        name: '王小虎',
        address: '逾期未返回'
      }, {
        date: '2016-05-01',
        name: '王小虎',
        address: '逾期未返回'
      }, {
        date: '2016-05-01',
        name: '王小虎',
        address: '逾期未返回'
      }],
      showSee: false
    }
  },
  methods: {
    openSee () {
      this.showSee = true
    },
    closeSee () {
      this.showSee = false
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.run {
  width: 100%;
  height: 100%;

  .son-tab {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    .son-item {
      height: 38px;
      line-height: 38px;
      padding: 0 20px;
      text-align: center;
      background-color: #284867;
      color: #fff;
      font-size: 16px;
      cursor: pointer;
    }

    .son-active {
      background-color: #409EFF;
    }

    .son-item:first-child {
      margin-right: 40px;
    }
  }

  .son-tab1 {
    border: none;
  }

  .son-add {
    display: flex;
    align-items: center;
    height: 50px;

    .son-add-icon {
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      background-color: #04B4D0;
      border-radius: 50%;
      color: #fff;
      font-size: 16px;
      cursor: pointer;
    }
  }

  .run-search {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    height: 60px;
    color: #000;
    font-size: 14px;
  }

  .table {
    width: 700px;
    height: calc(100% - 260px);
    margin-top: 10px;
  }

  .input {
    width: 180px;
  }

  /deep/.el-table .cell {
    text-align: center;
    color: #000;
  }

  .line {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;
    color: #000;
    font-size: 14px;

    .el-input {
      width: 180px;
    }
  }

  .button {
    position: absolute;
    left: 70px;
    bottom: 30px;
    background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
    border: none;
    color: #fff;
  }
}
</style>
