<template>
  <div class="son">
    <div class="son-search">
      <div class="son-item">
        <div class="key">
          <span>任务名称</span>
        </div>
        <el-input placeholder="请输入任务名称"></el-input>
      </div>
      <div class="son-item">
        <div class="key">
          <span>提交人姓名</span>
        </div>
        <el-input placeholder="请输入"></el-input>
      </div>
      <div class="son-item">
        <div class="key">
          <span>接收人姓名</span>
        </div>
        <el-input placeholder="请输入"></el-input>
      </div>
      <div class="son-item">
        <div class="key">
          <span>联系方式</span>
        </div>
        <el-input placeholder="请输入"></el-input>
      </div>
      <div class="son-item">
        <div class="key">
          <span>接受状态</span>
        </div>
        <el-select v-model="value" placeholder="请选择">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="son-item">
        <div class="key">
          <span>提交时间</span>
        </div>
        <el-date-picker type="date" placeholder="选择日期">
        </el-date-picker>
      </div>
      <el-button>搜索</el-button>
      <el-button>重置</el-button>
    </div>

    <el-table :data="tableData" height="50%">
      <el-table-column label="序号" type="index" width="50">
      </el-table-column>
      <el-table-column prop="tjsj" label="提交时间" width="180">
      </el-table-column>
      <el-table-column prop="tjrxm" label="提交人" width="180">
      </el-table-column>
      <el-table-column prop="jsrxm" label="接收人">
      </el-table-column>
      <el-table-column prop="jsrdh" label="接收人联系方式">
      </el-table-column>
      <el-table-column prop="rwsl" label="任务数量">
      </el-table-column>
      <el-table-column label="操作">
        <template>
          <div class="see" @click="openSee">
            <span>查看明细</span>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <See v-if="showSee" @close="closeSee"></See>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import See from './see.vue'
export default {
  components: {
    See
  },
  data () {
    return {
      tableData: [{
        date: '2016-05-02',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-04',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1517 弄'
      }, {
        date: '2016-05-01',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1519 弄'
      }, {
        date: '2016-05-03',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1516 弄'
      }],
      options: [{
        value: '',
        label: ''
      }],
      value: '',
      showSee: false,
      params: {
        start: '0',
        length: '10',
        ssjgbh: ''
      }
    }
  },
  computed: {
    ...mapState(['loginInfo'])
  },
  created () {
    this.params.ssjgbh = this.loginInfo.jgbh
    this.getList()
  },
  methods: {
    // 交接记录列表
    async getList () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-100423698
      try {
        const { data: res } = await this.$http.get('/ykdyljjjls', { params: this.params })
        console.log('交接记录列表', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.tableData = res.data
      } catch (error) {
        console.log('交接记录列表请求失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    openSee () {
      this.showSee = true
    },
    closeSee () {
      this.showSee = false
    }
  }
}
</script>

<style lang="less" scoped>
.son {
  position: relative;
  width: 100%;
  height: 100%;

  .son-search {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    height: 136px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;

    .son-item {
      display: flex;
      align-items: center;
      margin-right: 30px;

      .key {
        margin-right: 10px;
        white-space: nowrap;
        color: #01EEFD;
        font-size: 22px;
      }
    }

    .el-button {
      height: 38px;
      line-height: 38rpx;
      margin-left: 40px;
      border: none;
      background-color: #04B4D0;
      color: #fff;
    }
  }

  .el-table {
    margin-top: 20px;
    border: 4px solid #073F73;
  }

  /deep/.el-table__body-wrapper {
    background-color: #081C38;
  }

  /deep/.el-table th.el-table__cell.is-leaf {
    border-bottom: none;
  }

  /deep/.el-table th.el-table__cell {
    background-color: #024276;
  }

  /deep/.el-table .cell {
    text-align: center;
    color: #01EEFD;
  }

  /deep/.el-table tr {
    background-color: #081C38;
  }

  /deep/.el-table td.el-table__cell {
    border-bottom: 1px solid #01EEFD;
  }

  .el-table::before {
    height: 0;
  }

  /deep/.el-table tbody tr:hover>td {
    background-color: transparent;
  }

  .see {
    color: #0077F9;
    cursor: pointer;
  }

}
</style>
