<template>
  <div class="update">
    <div class="update-top">
      <div class="update-text">排班管理</div>
      <el-input placeholder="姓名/身份证号" suffix-icon="el-icon-search" class="update-input"></el-input>
    </div>
    <div class="update-scroll">
      <div class="info">
        <img src="../../../img/emr/user.png" class="info-img">
        <div class="info-box">
          <span class="info-line">老人姓名：李明明</span>
          <span class="info-line">身份证/护照号：男</span>
          <span class="info-line">床位号：23802304203</span>
        </div>
      </div>
      <div class="scroll-title border">
        <span>排班设置</span>
      </div>
      <el-table :data="tableData" border>
        <el-table-column prop="name" label="">
        </el-table-column>
        <el-table-column prop="address" label="周一">
          <template>
            <div class="span" @click="openAdd">李梅</div>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="周二"></el-table-column>
        <el-table-column prop="address" label="周三"></el-table-column>
        <el-table-column prop="address" label="周四"></el-table-column>
        <el-table-column prop="address" label="周五"></el-table-column>
        <el-table-column prop="address" label="周六"></el-table-column>
        <el-table-column prop="address" label="周日"></el-table-column>
        <el-table-column prop="date" label="身份证号">
          <template>
            <div>
              <el-button type="primary" size="mini">+选择</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="update-btns">
      <el-button size="small" @click="close">返回</el-button>
    </div>
    <Add v-if="showAdd" @close="closeAdd"></Add>
  </div>
</template>

<script>
import Add from './schedulAdd.vue'
export default {
  components: {
    Add
  },
  data () {
    return {
      tableData: [{
        date: '+选择',
        name: '白班',
        address: '李梅'
      }, {
        date: '+选择',
        name: '夜班',
        address: '李梅'
      }],
      showAdd: false
    }
  },
  methods: {
    openAdd () {
      this.showAdd = true
    },
    closeAdd () {
      this.showAdd = false
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.update {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0 20px;
  background-color: #fff;
  box-sizing: border-box;

  .update-top {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 60px;
    padding: 0 10px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    .update-text {
      height: 38px;
      line-height: 38px;
      padding: 0 20px;
      background-color: #409EFF;
      color: #fff;
      font-size: 22px;
    }

    .update-input {
      width: 246px;
    }
  }

  .update-scroll {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 120px);

    &::-webkit-scrollbar {
      width: 0;
    }

    .scroll-title {
      height: 40px;
      line-height: 40px;
      color: #000;
      font-size: 20px;
    }

    .border {
      margin-top: 10px;
      color: #000;
      font-size: 18px;
      border-bottom: 1px solid #000;
      box-sizing: border-box;
    }

    .info {
      display: flex;
      flex-direction: row;
      align-items: center;
      box-sizing: border-box;

      .info-img {
        width: 58px;
        height: 58px;
        margin-right: 18px;
      }

      .info-box {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        width: 50%;

        .info-line {
          margin-top: 16px;
          color: #000;
          font-size: 16px;
        }
      }

      .info-btns {
        display: flex;
        flex-direction: row;
        margin-top: 20px;

        .el-button {
          height: 38px;
          margin-left: 40px;
          background-color: #04B4D0;
          border: none;
          color: #fff;
          font-size: 16px;
        }
      }
    }

    .el-table {
      margin-top: 10px;
    }

    .input {
      width: 180px;
    }

    /deep/.el-table .cell {
      text-align: center;
      color: #000;
    }
  }

  .span {
    cursor: pointer;
  }

  .update-btns {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 50px;
  }
}
</style>
