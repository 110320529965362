<template>
  <div class="son-popup"
    v-loading="loading"
    element-loading-text="正在加载数据请稍后"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div class="son-box">
      <div class="son-title" @click="close">
        <span class="el-icon-close"></span>
      </div>
      <div class="son-content">
        <div class="son-top">
          <div class="left">
            <div class="item" v-for="(item, index) in tabs" :key="index" :class="{ active: index === tabIndex }"
              @click="tabClick(index)">
              <span>{{ item }}</span>
            </div>
          </div>
          <div class="right">
            <tab1 v-if="tabIndex === 0"></tab1>
          </div>
        </div>
        <div class="son-bottom">
          <el-button type="primary">打印当前页</el-button>
          <el-button type="primary" @click="test">打印全部</el-button>
          <el-button type="primary">审核</el-button>
        </div>
      </div>
    </div>
    <Pdf v-if="showPdf" :url="url" @close="closePdf"></Pdf>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Pdf from './pdf.vue'
import tab1 from './tab1/tab1.vue'
export default {
  props: {
    params: {
      type: Object,
      default () {
        return {
          jgbh: '',
          sfzh: '',
          tmcode: '',
          tjlx: ''
        }
      }
    }
  },
  components: {
    Pdf,
    tab1
  },
  data () {
    return {
      tabIndex: 0,
      tabs: Object.freeze(['健康体检表', '视力报告', '听力报告', '肺活量报告', '脊柱测完报告', '实验室报告', 'PPD报告', '体检结果评价']),
      info: {
        jgbh: '',
        type: '100',
        jgjb: '',
        sfzh: '',
        tmcode: '',
        tjtcid: '',
        pwd: ''
      },
      showPdf: false,
      url: '',
      loading: false
    }
  },
  computed: {
    ...mapState(['loginInfo'])
  },
  created () {
    this.info.jgbh = this.$parent.selectInfo.tjjgcmhid || this.$parent.selectInfo.tjjgbh || ''
    this.info.jgjb = this.loginInfo.jgjb || ''
    this.info.sfzh = this.$parent.selectInfo.sfzh || ''
    this.info.tmcode = this.$parent.selectInfo.tmcode || ''
    this.info.tjtcid = this.$parent.selectInfo.tjtcid || ''
    console.log(this.info)
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    },
    async test () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-163736165
      try {
        this.loading = true
        const { data: res } = await this.$http.post('/exportReport/app/exportxstjPdftm', this.info)
        console.log('体检报告pdf打印下载', res)
        if (res.code !== 0) {
          this.loading = false
          return this.$message.error(res.msg || res.message)
        }
        if (res.data === null || res.data === '') {
          this.loading = false
          return this.$message.error('暂无消息')
        }
        this.loading = false
        this.url = res.data.pdfurl
        this.showPdf = true
      } catch (error) {
        console.log('体检报告pdf打印下载请求失败', error)
        this.loading = false
      }
    },
    closePdf () {
      this.url = ''
      this.showPdf = false
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.son-popup {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 8;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .69);

  .son-box {
    width: 98%;
    height: 95%;
    background-color: #fff;
    border-radius: 12px;

    .son-title {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      width: 100%;
      height: 50px;
      padding-right: 20px;
      box-sizing: border-box;
      color: #D1D1D1;
      font-size: 30px;
      cursor: pointer;
    }

    .son-content {
      width: 100%;
      height: calc(100% - 50px);

      .son-top {
        display: flex;
        width: 100%;
        height: calc(100% - 100px);

        .left {
          overflow-y: scroll;
          width: 260px;
          height: 100%;
          background-color: #3782F2;

          &::-webkit-scrollbar {
            width: 0;
          }

          .item {
            width: 100%;
            height: 57px;
            line-height: 57px;
            text-align: center;
            color: #fff;
            font-size: 20px;
            font-weight: 600;
            cursor: pointer;
          }

          .active {
            background-color: #3470CB;
          }
        }

        .right {
          overflow-y: scroll;
          width: calc(100% - 300px);
          height: 100%;

          &::-webkit-scrollbar {
            width: 0;
          }
        }
      }

      .son-bottom {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100px;

        .el-button {
          min-width: 120px;
          margin-left: 15px;
          margin-right: 15px;
          padding-top: 6px;
          padding-bottom: 6px;
          background-color: #3782F2;
          border: none;
          color: #fff;
          font-size: 20px;
          font-weight: 600;
        }
      }
    }
  }

  /deep/.el-loading-text {
    color: #fff;
    font-size: 16px;
  }
}
</style>
