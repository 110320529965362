<template>
  <div class="popup">
    <div class="popup-content">
      <div class="popup-title">
        <span>在院编辑</span>
      </div>
      <div class="popup-box">
        <div class="content">
          <div class="close" @click="close">
            <i class="el-icon-close"></i>
          </div>
          <div class="info">
            <div class="info-line">
              <span class="span">姓名</span>
              <el-input placeholder="" v-model="params.name">
              </el-input>
            </div>
            <div class="info-line">
              <span class="span">人群分类</span>
              <el-input placeholder="" v-model="params.rqfl">
              </el-input>
            </div>
            <div class="info-line">
              <span class="span">性别</span>
              <el-input placeholder="" v-model="params.xb">
              </el-input>
            </div>
            <div class="info-line">
              <span class="span">年龄</span>
              <el-input placeholder="" v-model="params.age">
              </el-input>
            </div>
            <div class="info-line">
              <span class="span">自理能力</span>
              <el-input placeholder="" v-model="params.fwdj"></el-input>
            </div>
            <div class="info-line">
              <span class="span">护理等级</span>
              <el-input type="placeholder" placeholder="" v-model="params.hldj">
              </el-input>
            </div>
            <div class="info-line">
              <span class="span">床位信息</span>
              <el-input type="placeholder" placeholder="" v-model="params.cyyy">
              </el-input>
            </div>
          </div>
          <div class="btn">
            <el-button size="small" @click="close">取消</el-button>
            <el-button size="small" @click="submit">确定</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    selectInfo: {
      type: Object
    }
  },
  data () {
    return {
      input: '',
      options: [{
        value: '',
        label: ''
      }],
      value: '',
      showSelect: false,
      params: {
        id: 0,
        hldj: '',
        fwdj: '',
        age: '',
        xb: '',
        rqfl: '',
        name: '',
        cyyy: '',
        ssjgbh: '',
        ssjgmc: ''
      }
    }
  },
  created () {
    console.log('所选信息', this.$parent.selectInfo)
    this.params = this.$parent.selectInfo
  },
  computed: {
    ...mapState(['loginInfo'])
  },
  methods: {
    openSelect () {
      this.showSelect = true
    },
    closeSelect () {
      this.showSelect = false
    },
    close () {
      this.$emit('close')
    },
    async submit () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-111241236
      try {
        this.params.ssjgbh = this.loginInfo.jgbh
        this.params.ssjgmc = this.loginInfo.jgmc
        console.log('请求参数', this.params)
        const response = await this.$http.put('/ykdzhylfwlbs', this.params)
        const res = response.data
        console.log('在院修改', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.$message.success('修改成功')
        this.$parent.getList()
        this.$parent.showUpdate = false
      } catch (error) {
        console.log('在院修改保存失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    }
  }
}
</script>

<style scoped lang="less">
.popup {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 88;
  background-color: rgba(0, 0, 0, .74);

  .popup-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    height: 800px;

    .popup-title {
      width: 240px;
      height: 50px;
      line-height: 60px;
      background-image: url('../../../img/dialogTitleBg.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      text-align: center;
      color: #fff;
      font-size: 20px;
    }

    .popup-box {
      width: 600px;
      height: 600px;
      padding: 10px;
      background-color: rgba(3, 27, 44, .8);
      border-radius: 10px;
      border: 4px solid rgba(1, 49, 88, .5);
      box-sizing: border-box;

      .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        width: 100%;
        height: 100%;
        padding: 30px;
        border-radius: 10px;
        border: 4px solid rgba(1, 49, 88, .5);
        box-sizing: border-box;

        .close {
          display: flex;
          align-items: flex-end;
          position: absolute;
          top: 0;
          right: 0;
          width: 50px;
          height: 50px;
          background: linear-gradient(26.565deg, #2057a7 50%, transparent 0);
          transform: rotate(180deg);
          color: #fff;
          font-size: 24px;
          cursor: pointer;
        }

        .info {
          display: flex;
          flex-direction: column;
          width: 100%;

          .info-line {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            margin-bottom: 20px;
            color: #01EEFD;
            font-size: 18px;

            .input {
              display: flex;
              flex-direction: row-reverse;
              align-items: center;
              width: 246px;
              height: 38px;
              margin-left: 28px;
              padding: 0 5px;
              background-color: #fff;
              box-sizing: border-box;
              color: #A1A6BB;
              font-size: 14px;
            }

            .span {
              width: 90px;
            }

            .el-select {
              width: 246px;
              margin-left: 28px;
            }

            .el-input {
              width: 246px;
              margin-left: 28px;
            }

            .el-textarea {
              width: 246px;
              margin-left: 28px;
            }

            /deep/.el-textarea__inner {
              width: 246px;
              height: 104px;
              resize: none;
            }
          }

          .info-line:nth-child(5) {
            align-items: initial;
          }
        }

        .btn {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 240px;
          margin: 0 auto;
        }

        .el-button {
          border-radius: 0;
          border: none;
          background-color: #33465C;
          color: #fff;
        }

        .el-button:nth-child(2) {
          background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
        }
      }
    }
  }
}
</style>
