var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info"},[_c('div',{staticClass:"info-title"},[_c('span',[_vm._v("老人信息")]),_c('el-input',{staticClass:"input",attrs:{"placeholder":"姓名/身份证号","suffix-icon":"el-icon-search"}})],1),_c('div',{staticClass:"info-scroll"},[_c('div',{staticClass:"info-box"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_c('div',{staticClass:"info-content"},[_vm._m(7),_c('el-timeline',[_c('el-timeline-item',{attrs:{"timestamp":"李四 审批拒绝了张三提交的床位更换申请\n备注：不符合更换要求！"}},[_vm._v(" 10-20 15：00 ")]),_c('el-timeline-item',{attrs:{"timestamp":"李四 审批拒绝了张三提交的床位更换申请\n备注：不符合更换要求！"}},[_vm._v(" 10-20 15：00 ")])],1)],1)])]),_c('div',{staticClass:"info-btns"},[_c('el-button',{staticClass:"gray",attrs:{"size":"small"},on:{"click":_vm.close}},[_vm._v("返回")]),_c('el-button',{attrs:{"size":"small"},on:{"click":_vm.close}},[_vm._v("确定")])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info-line"},[_c('span',[_vm._v("老人信息")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info-tip"},[_c('span',[_vm._v("标题 食堂没有饭餐")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info-tip"},[_c('span',[_vm._v("状态 待处理")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info-tip"},[_c('span',[_vm._v("投诉反馈渠道 电话")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info-tip"},[_c('span',[_vm._v("投诉人 张三丰")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info-tip"},[_c('span',[_vm._v("投诉建议内容 如何更好的方式区解决")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info-title"},[_c('span',[_vm._v("日志")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info-content-span"},[_c('span',[_vm._v("合计：")])])
}]

export { render, staticRenderFns }