<template>
  <div class="page-box">
    <div class="tab">
      <span class="item active">院内活动</span>
    </div>
    <el-input placeholder="姓名/身份证号" suffix-icon="el-icon-search" class="input">
    </el-input>
    <div class="tab-box">
      <div class="run">
        <div class="son-tab">
          <span class="son-item" :class="{ 'son-active': tabIndex === 0 }" @click="tabClick(0)">精神慰籍</span>
          <span class="son-item" :class="{ 'son-active': tabIndex === 1 }" @click="tabClick(1)">老人文娱</span>
          <span class="son-item" :class="{ 'son-active': tabIndex === 2 }" @click="tabClick(2)">老人晨练</span>
        </div>
        <div class="run-search">
          <div class="run-item">
            <div class="key">
              <span>老人姓名</span>
            </div>
            <el-input placeholder="请输入"></el-input>
          </div>
          <div class="run-item">
            <div class="key">
              <span>上报时间</span>
            </div>
            <el-date-picker type="date" placeholder="请选择">
            </el-date-picker>
          </div>
          <el-button type="primary">搜索</el-button>
          <el-button type="primary">重置</el-button>
        </div>

        <div class="table">
          <el-table :data="tableData" border height="100%">
          <el-table-column type="selection" width="55">
          </el-table-column>
          <el-table-column label="序号" type="index" width="70">
          </el-table-column>
          <el-table-column prop="name" label="上报时间">
          </el-table-column>
          <el-table-column prop="address" label="上报人">
          </el-table-column>
          <el-table-column prop="address" label="老人姓名">
          </el-table-column>
          <el-table-column prop="address" label="床位号">
          </el-table-column>
          <el-table-column label="操作" width="140">
            <template>
              <!-- <div class="table-run">
                <span @click="openSee">查看详情</span>
              </div> -->
              <el-button type="primary" size="mini" @click="openSee">查看详情</el-button>
            </template>
          </el-table-column>
        </el-table>
        </div>

        <el-pagination @size-change="handleSizeChange" background @current-change="handleCurrentChange" :current-page="currentPage4"
          :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper" :total="400" hide-on-single-page>
        </el-pagination>
      </div>
    </div>
    <See v-if="showSee" @close="closeSee"></See>
  </div>
</template>

<script>
import See from './see.vue'
export default {
  components: {
    See
  },
  data () {
    return {
      tableData: [{
        date: '2016-05-02',
        name: '王小虎',
        address: '等待外出'
      }, {
        date: '2016-05-04',
        name: '王小虎',
        address: '未返回'
      }, {
        date: '2016-05-01',
        name: '王小虎',
        address: '逾期未返回'
      }],
      options: [{
        value: '',
        label: ''
      }],
      value: '',
      currentPage4: 4,
      tabIndex: 0,
      selectIndex: 0,
      showAdd: false,
      showExtension: false,
      showSee: false
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    },
    openAdd () {
      this.showAdd = true
    },
    closeAdd () {
      this.showAdd = false
    },
    openExtension () {
      this.showExtension = true
    },
    closeExtension () {
      this.showExtension = false
    },
    openSee () {
      this.showSee = true
    },
    closeSee () {
      this.showSee = false
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.page-box {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0 10px;
  background-color: #fff;
  box-sizing: border-box;

  .input {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 246px;
  }

  .tab {
    display: flex;
    align-items: center;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    .item {
      width: 130px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      margin-left: 30px;
      background-color: #409EFF;
      color: #fff;
      font-size: 22px;
      cursor: pointer;

      &:first-child {
        margin-left: 0px;
      }
    }

    .active {
      background-color: #409EFF;
    }
  }

  .tab-box {
    width: 100%;
    height: calc(100% - 78px);
  }

}
.run {
  width: 100%;
  height: 100%;

  .son-tab {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    .son-item {
      height: 38px;
      line-height: 38px;
      margin-right: 40px;
      padding: 0 20px;
      text-align: center;
      background-color: #284867;
      color: #fff;
      font-size: 16px;
      cursor: pointer;
    }

    .son-active {
      background-color: #409EFF;
    }
  }

  .son-tab1 {
    border: none;
  }

  .son-add {
    display: flex;
    align-items: center;
    height: 50px;

    .son-add-icon {
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      background-color: #409EFF;
      border-radius: 50%;
      color: #fff;
      font-size: 16px;
      cursor: pointer;
    }
  }

  .run-search {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    .run-item {
      display: flex;
      align-items: center;
      margin-right: 30px;

      .key {
        margin-right: 10px;
        white-space: nowrap;
        color: #000;
        font-size: 22px;
      }
    }
  }

  .table {
    width: 100%;
    height: calc(100% - 280px);

    /deep/.el-table .cell {
      text-align: center;
      color: #000;
    }

    .table-run {
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        cursor: pointer;
      }
    }
  }

  .el-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
}
</style>
