<!--
 * @Author: 小宝 zksukuo@yunkangda.com
 * @Date: 2024-03-18 11:16:04
 * @LastEditors: 小宝 zksukuo@yunkangda.com
 * @LastEditTime: 2024-04-16 11:25:25
 * @FilePath: \visions\src\views\zhyl\smartPhysicalExamination\systemConfig\testProjectRun\projectType\update.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="update">
    <div class="update-box">
      <div class="update-box-head">
        <span>新增</span>
      </div>
      <div class="update-box-top">
        <div class="update-item">
          <div class="update-key">
            <span>项目分类编码：</span>
          </div>
          <el-input v-model="params.tjxmbm" placeholder="请输入内容"></el-input>
        </div>
        <div class="update-item">
          <div class="update-key">
            <span>项目分类名称：</span>
          </div>
          <el-input v-model="params.tjxmmc" placeholder="请输入内容"></el-input>
        </div>
        <div class="update-item">
          <div class="update-key">
            <span>所属机构：</span>
          </div>
          <el-select v-model="params.ssjgmc" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="update-item">
          <div class="update-key">
            <span>启用状态：</span>
          </div>
          <el-switch v-model="params.flag" active-value="1" inactive-value="0" active-color="#13ce66" inactive-color="#ff4949">
          </el-switch>
        </div>
        <div class="update-item">
          <div class="update-key">
            <span>检查医生：</span>
          </div>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="update-item">
          <div class="update-key">
            <span>备注：</span>
          </div>
          <el-input type="textarea" placeholder="请输入内容" v-model="params.bz1">
          </el-input>
        </div>
      </div>
      <div class="update-box-bottom">
        <el-button type="primary" @click="submit">保存</el-button>
        <el-button @click="close">取消</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      input: '',
      options: [],
      value: '',
      flan: false,
      textarea: '',
      params: {
        id: '',
        tjxmmc: '',
        tjxmbm: '',
        bz1: '',
        bz2: '',
        ssjgbh: '',
        ssjgmc: '',
        tjlx: '',
        tjlxid: '',
        flag: '0',
        rsxz: ''
      }
    }
  },
  created () {
    this.params.ssjgmc = this.$store.state.loginInfo.jgmc
    this.params.ssjgbh = this.$store.state.loginInfo.jgbh
  },
  methods: {
    async submit () {
      // 接口文档 https://app.apifox.com/project/2726972/apis/api-159362576
      try {
        const { data: res } = await this.$http.post('/zhctapi/savetjxmzd', this.params)
        console.log('项目字典保存', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.$message.success('保存成功')
        this.close()
      } catch (error) {
      }
    },
    close () {
      this.$parent.showAdd = false
      // this.$parent.selectInfo = null
    }
  }
}
</script>

<style lang="less" scoped>
.update {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 888;
  background-color: rgba(0, 0, 0, .8);

  .update-box {
    overflow: hidden;
    width: 50%;
    height: 60%;
    border-radius: 10px;
    background-color: #fff;

    .update-box-head {
      width: 100%;
      height: 50px;
      line-height: 50px;
      text-align: center;
      background-color: #66b1ff;
      color: #fff;
      font-size: 20px;
    }

    .update-box-top {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;
      height: calc(100% - 120px);
      padding: 20px 30px;
      box-sizing: border-box;

      .update-item {
        display: flex;
        align-items: center;
        width: 45%;

        .update-key {
          white-space: nowrap;
          color: #000;
          font-size: 16px;
        }

        /deep/.el-textarea__inner {
          resize: none;
        }
      }
    }

    .update-box-bottom {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 70px;
    }
  }
}
</style>
