<template>
  <div class="page-box">
    <div class="tab">
      <span class="item active">服务质量</span>
    </div>
    <el-input placeholder="姓名/身份证号" suffix-icon="el-icon-search" class="input">
    </el-input>
    <div class="tab-box">
      <div class="run">
        <div class="son-tab">
          <span class="son-item" :class="{ 'son-active': tabIndex === 0 }" @click="tabClick(0)">行政查房</span>
          <span class="son-item" :class="{ 'son-active': tabIndex === 1 }" @click="tabClick(1)">投诉建议</span>
        </div>
        <div class="run-search" v-if="tabIndex === 0">
          <div class="run-item">
            <div class="key">
              <span>查房人 </span>
            </div>
            <el-input placeholder="请输入"></el-input>
          </div>
          <div class="run-item">
            <div class="key">
              <span>记录时间</span>
            </div>
            <el-date-picker type="date" placeholder="请选择">
            </el-date-picker>
          </div>
          <el-button type="primary">搜索</el-button>
          <el-button type="primary">重置</el-button>
        </div>
        <div class="run-search" v-else-if="tabIndex === 1">
          <div class="run-item">
            <div class="key">
              <span>标题 </span>
            </div>
            <el-input placeholder="请输入"></el-input>
          </div>
          <div class="run-item">
            <div class="key">
              <span>反馈渠道</span>
            </div>
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="run-item">
            <div class="key">
              <span>状态</span>
            </div>
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <el-button>搜索</el-button>
          <el-button>重置</el-button>
        </div>
        <div class="table">
          <el-table :data="tableData" height="100%" border v-if="tabIndex === 0">
          <el-table-column label="序号" type="index" width="100">
          </el-table-column>
          <el-table-column prop="name" label="查房人">
          </el-table-column>
          <el-table-column prop="address" label="记录时间">
          </el-table-column>
          <el-table-column label="操作" width="210">
            <!-- slot-scope="scope" -->
            <template>
              <!-- <div class="table-run table-run1" >
                <span @click="openSee">查看详情</span>
              </div> -->
              <el-button size="mini" type="primary" @click="openSee">查看详情</el-button>
            </template>
          </el-table-column>
        </el-table>

        <el-table :data="tableData" height="100%" border v-if="tabIndex === 1">
          <el-table-column label="序号" type="index" width="100">
          </el-table-column>
          <el-table-column prop="address" label="标题">
          </el-table-column>
          <el-table-column prop="address" label="反馈渠道">
          </el-table-column>
          <el-table-column prop="address" label="投诉建议人">
          </el-table-column>
          <el-table-column prop="address" label="提交时间">
          </el-table-column>
          <el-table-column prop="address" label="任务执行人">
          </el-table-column>
          <el-table-column prop="address" label="完成处理时间">
          </el-table-column>
          <el-table-column prop="address" label="状态">
          </el-table-column>
          <el-table-column label="操作" width="300">
            <!-- slot-scope="scope" -->
            <template>
              <!-- <div class="table-run" >
                <span>编辑</span>
                <span @click="openInfo">详情</span>
                <span>标记已处理</span>
              </div> -->
              <el-button size="mini" type="primary">编辑</el-button>
              <el-button size="mini" type="primary" @click="openInfo">查看详情</el-button>
              <el-button size="mini" type="primary">标记已处理</el-button>
            </template>
          </el-table-column>
        </el-table>
        </div>

        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage4"
          :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper" :total="400">
        </el-pagination>
      </div>
    </div>
    <!-- 查看详情 -->
    <See v-if="showSee" @close="closeSee"></See>
    <!-- 详情 -->
    <Info v-if="showInfo" @close="closeInfo"></Info>
  </div>
</template>

<script>
import See from './see.vue'
import Info from './info.vue'
export default {
  components: {
    See,
    Info
  },
  data () {
    return {
      tableData: [{
        date: '2016-05-02',
        name: '王小虎',
        address: '未签约'
      }, {
        date: '2016-05-04',
        name: '王小虎',
        address: '预定中'
      }, {
        date: '2016-05-01',
        name: '王小虎',
        address: '已入院'
      }],
      options: [{
        value: '',
        label: ''
      }],
      value: '',
      currentPage4: 4,
      tabIndex: 0,
      selectIndex: 0,
      showAdd: false,
      showSee: false,
      showInfo: false,
      showDelete: false,
      list: ['血压记录', '血糖记录', '体温记录'],
      listIndex: 0
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    },
    tab (index) {
      this.listIndex = index
    },
    openAdd () {
      this.showAdd = true
    },
    closeAdd () {
      this.showAdd = false
    },
    openSee () {
      this.showSee = true
    },
    closeSee () {
      this.showSee = false
    },
    openInfo () {
      this.showInfo = true
    },
    closeInfo () {
      this.showInfo = false
    },
    openDelete () {
      this.showDelete = true
    },
    closeDelete () {
      this.showDelete = false
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.page-box {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  background-color: #fff;

  .input {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 246px;
  }

  .tab {
    display: flex;
    align-items: center;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    .item {
      width: 130px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      margin-left: 30px;
      background-color: #284867;
      color: #fff;
      font-size: 22px;
      cursor: pointer;

      &:first-child {
        margin-left: 0px;
      }
    }

    .active {
      background-color: #409EFF;
    }
  }

  .tab-box {
    width: 100%;
    height: calc(100% - 78px);
  }

}

.run {
  width: 100%;
  height: 100%;

  .son-tab {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    .son-item {
      height: 38px;
      line-height: 38px;
      margin-right: 40px;
      padding: 0 20px;
      text-align: center;
      background-color: #284867;
      color: #fff;
      font-size: 16px;
      cursor: pointer;
    }

    .son-active {
      background-color: #409EFF;
    }
  }

  .run-tab {
    display: flex;
    align-items: center;
    height: 60px;

    .run-item {
      margin-right: 56px;
      color: #fff;
      font-size: 22px;
      border-bottom: 1px solid transparent;
      cursor: pointer;
    }

    .run-active {
      border-bottom: 1px solid #409EFF;
      color: #409EFF;
    }
  }

  .run-search {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    .run-item {
      display: flex;
      align-items: center;
      margin-right: 30px;

      .key {
        margin-right: 10px;
        white-space: nowrap;
        color: #000;
        font-size: 22px;
      }
    }
  }

  .run-scroll {
    width: 100%;
    height: calc(100% - 100px);
  }

  .table {
    width: 100%;
    height: calc(100% - 240px);
  }

  /deep/.el-table .cell {
    text-align: center;
    color: #000;
  }

  .table-run {
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      cursor: pointer;
    }
  }

  .table-run1 {
    justify-content: center;
  }

  .el-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
}
</style>
