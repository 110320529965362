<template>
  <div class="son">
    <div class="son-title">
      <div class="title">
        <span>体检套餐</span>
      </div>
      <!-- <div class="btns">
        <el-button>导入</el-button>
        <el-button>导出</el-button>
      </div> -->
    </div>
    <div class="son-tab">
      <span class="son-item" :class="{ 'son-active': tabIndex === index }" v-for="(item, index) in tabs" :key="index" @click="tabClick(index)">{{ item }}</span>
    </div>
    <div class="son-box">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {
      tabIndex: 0,
      tabs: Object.freeze(['体检套餐', '体检项目', '套餐类型'])
    }
  },
  methods: {
    tabClick (index) {
      if (this.tabIndex === index) return
      this.$router.push('/reservationservice/hospitalinfo/testpackage/tab' + (index + 1))
      this.tabIndex = index
    }
  }
}
</script>

<style lang="less" scoped>
.son {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #081C38;

  .son-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 78px;
    padding-right: 54px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;

    .title {
      width: 150px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      background-color: #01EEFD;
      color: #fff;
      font-size: 22px;
    }

    .btns {
      display: flex;
      flex-direction: row;

      .el-button {
        width: 90px;
        margin-left: 20px;
        background-color: #04B4D0;
        border: none;
        color: #fff;
      }
    }
  }

  .son-tab {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 40px;
    padding-left: 8px;
    box-sizing: border-box;

    .son-item {
      margin-right: 28px;
      color: #fff;
      font-size: 14px;
    }

    .son-active {
      color: #01EEFD;
    }
  }
}
</style>
