<template>
  <div class="page-box-sob">
    <div class="tab">
      <span class="item active">采购申请</span>
    </div>
    <el-input placeholder="姓名/身份证号" suffix-icon="el-icon-search" class="input">
    </el-input>
    <div class="tab-box">
      <div class="run">
        <div class="son-tab">
          <div class="total">
            <span>2</span>
          </div>
          <span class="son-item son-active">选择食材</span>
        </div>

        <el-table :data="tableData" border height="50%">
          <el-table-column label="序号" type="index" width="70">
          </el-table-column>
          <el-table-column prop="name" label="物料名称">
          </el-table-column>
          <el-table-column prop="address" label="单位">
          </el-table-column>
          <el-table-column prop="address" label="供应商">
          </el-table-column>
          <el-table-column prop="address" label="批发价">
          </el-table-column>
          <el-table-column prop="address" label="零售价">
          </el-table-column>
          <el-table-column prop="address" label="采购价">
          </el-table-column>
          <el-table-column label="采购数量">
            <template>
              <el-input class="table-input"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="100">
            <!-- slot-scope="scope" -->
            <template>
              <!-- <div class="table-run">
                <span>删除</span>
              </div> -->
              <el-button type="danger" size="mini">删除</el-button>
            </template>
          </el-table-column>
        </el-table>

        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage4"
          :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper" :total="400" background>
        </el-pagination>

        <div class="tip">
          <span>合计：采购品种数9，采购总成本：1000.00</span>
        </div>
        <div class="tip">
          <span>期望到货日期</span>
          <el-select v-model="value" placeholder="请选择" class="select">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div class="btns">
      <el-button size="small" @click="close">返回</el-button>
      <el-button size="small" type="primary" @click="close">保存</el-button>
      <el-button size="small" type="primary" @click="close">保存并提交</el-button>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {
      tableData: [{
        date: '2016-05-02',
        name: '王小虎',
        address: '等待外出'
      }, {
        date: '2016-05-04',
        name: '王小虎',
        address: '未返回'
      }, {
        date: '2016-05-01',
        name: '王小虎',
        address: '逾期未返回'
      }],
      options: [{
        value: '',
        label: ''
      }],
      value: '',
      currentPage4: 4,
      tabIndex: 0,
      selectIndex: 0,
      showAdd: false,
      showRegistration: false,
      showSet: false
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    },
    openAdd () {
      this.showAdd = true
    },
    closeAdd () {
      this.showAdd = false
    },
    openRegistration () {
      this.showRegistration = true
    },
    closeRegistration () {
      this.showRegistration = false
    },
    openSet () {
      this.showSet = true
    },
    closeSet () {
      this.showSet = false
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.page-box-sob {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 88;
  width: 100%;
  height: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  background-color: #fff;

  .input {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 246px;
  }

  .tab {
    display: flex;
    align-items: center;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    .item {
      width: 130px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      margin-left: 30px;
      background-color: #284867;
      color: #fff;
      font-size: 22px;
      cursor: pointer;

      &:first-child {
        margin-left: 0px;
      }
    }

    .active {
      background-color: #66b1ff;
    }
  }

  .tab-box {
    width: 100%;
    height: calc(100% - 78px);
  }

  .btns {
    position: absolute;
    left: 70px;
    bottom: 28px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

}

.run {
  width: 100%;
  height: 100%;

  .son-tab {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    .total {
      position: absolute;
      top: 4px;
      left: 86px;
      z-index: 8;
      width: 18px;
      height: 18px;
      line-height: 18px;
      text-align: center;
      background-color: #CAD10C;
      color: #000000;
      font-size: 15px;
    }

    .son-item {
      height: 38px;
      line-height: 38px;
      margin-right: 40px;
      padding: 0 20px;
      text-align: center;
      background-color: #284867;
      color: #fff;
      font-size: 16px;
      cursor: pointer;
    }

    .son-active {
      background-color: #66b1ff;;
    }
  }

  .son-tab1 {
    border: none;
  }

  .son-add {
    display: flex;
    align-items: center;
    height: 50px;

    .son-add-icon {
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      background-color: #04B4D0;
      border-radius: 50%;
      color: #fff;
      font-size: 16px;
      cursor: pointer;
    }
  }

  .run-search {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    .run-item {
      display: flex;
      align-items: center;
      margin-right: 30px;

      .key {
        margin-right: 10px;
        white-space: nowrap;
        color: #000;
        font-size: 22px;
      }
    }
  }

  .tip {
    margin-top: 20px;
    color: #000;
    font-size: 16px;

    .select {
      margin-left: 20px;
    }
  }

  .el-table {
    margin-top: 10px;
  }

  /deep/.el-table .cell {
    text-align: center;
    color: #000;
  }

  .table-run {
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    span {
      cursor: pointer;
    }
  }

  .el-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
}
</style>
