
<template>
  <div class="popup">
    <div class="popup-box">
      <div class="popup-title">
        <div class="popup-top"></div>
        <div class="popup-bottom">
          <div class="popup-bottom-text">
            <span>配置护理服务</span>
          </div>
        </div>
      </div>

      <div class="popup-content">
        <div class="popup-content-box">
          <div class="info-close" @click="close">
            <span class="el-icon-close"></span>
          </div>
          <div class="status">
            <span>待审批</span>
          </div>
          <div class="head">
            <span class="span">护理等级：重度护理</span>
            <span>护理服务-护理项目：</span>
            <el-checkbox-group v-model="checkList">
              <el-checkbox label="1">晨起</el-checkbox>
              <el-checkbox label="2">娱乐</el-checkbox>
              <el-checkbox label="3">就寝</el-checkbox>
            </el-checkbox-group>
            <span class="span1">服务配置：</span>
          </div>
          <div class="title">
            <span>晨起</span>
          </div>
          <div class="add">
            <div class="icon">
              <span class="el-icon-plus"></span>
            </div>
          </div>
          <el-table :data="tableData" height="140" style="width: 100%">
            <el-table-column prop="name" label="护理服务">
            </el-table-column>
          </el-table>
          <div class="title">
            <span>娱乐</span>
          </div>
          <div class="add">
            <div class="icon">
              <span class="el-icon-plus"></span>
            </div>
          </div>
          <el-table :data="tableData" height="140" style="width: 100%">
            <el-table-column prop="name" label="护理服务">
            </el-table-column>
          </el-table>
          <div class="info-btns">
            <el-button size="small" @click="close">取消</el-button>
            <el-button size="small" class="blue" @click="close">确定</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {
      tableData: [{
        date: '2016-05-03',
        name: '辅助起床',
        address: '上海市普陀区金沙江路 1518 弄',
        status: 1,
        show: false
      }, {
        date: '2016-05-02',
        name: '辅助就餐',
        address: '上海市普陀区金沙江路 1518 弄',
        status: 0,
        show: false
      }],
      checkList: []
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.popup {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 888;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .82);
  box-sizing: border-box;

  .popup-box {
    display: flex;
    flex-direction: column;
    width: 1673px;
  }

  .popup-title {
    display: flex;
    flex-direction: column;

    .popup-top {
      position: relative;
      width: 87px;
      height: 6px;
      transform: skew(30deg);
      background-color: #03BCD7;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: -6px;
        width: 10px;
        height: 6px;
        background-color: #081C38;
        transform: skew(-50deg);
      }
    }

    .popup-bottom {
      overflow: hidden;
      position: relative;
      width: 170px;
      height: 30px;
      background: linear-gradient(97deg, #0177CD 0%, #024073 100%);
      transform: skew(30deg);

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: -30px;
        width: 20px;
        height: 30px;
        background-color: #081C38;
        transform: skew(-55deg);
      }

      .popup-bottom-text {
        height: 30px;
        line-height: 30px;
        padding-left: 20px;
        box-sizing: border-box;
        transform: skew(-30deg);
        color: #fff;
        font-size: 20px;
        font-weight: bold;
      }
    }
  }

  .popup-content {
    width: 100%;
    height: 800px;
    margin-top: 11px;
    padding: 10px;
    background: linear-gradient(93deg, rgba(10, 71, 123, .27) 0%, rgba(17, 94, 156, .27) 100%);
    border: 4px solid rgba(1, 124, 219, .27);
    box-sizing: border-box;

    .popup-content-box {
      overflow-y: scroll;
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      padding: 10px 30px;
      border: 4px solid rgba(1, 124, 219, .27);
      box-sizing: border-box;

      &::-webkit-scrollbar {
        width: 0;
      }

      .status {
        position: absolute;
        top: 40px;
        right: 40px;
        color: #FD0101;
        font-size: 20px;
        font-weight: 600;
      }

      .info-close {
        position: absolute;
        top: -15px;
        right: -40px;
        width: 100px;
        height: 45px;
        line-height: 60px;
        text-align: center;
        transform: rotate(30deg);
        background: linear-gradient(117deg, #1E58A6 0%, #1C4EA1 53%, #2866B1 100%);
        box-shadow: inset 0px -13px 0px 1px #2661AB;
        color: #fff;
        font-size: 24px;
        box-sizing: border-box;
        cursor: pointer;

        span {
          transform: rotate(-30deg);
        }
      }

      .head {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        height: 50px;
        color: #fff;
        font-size: 16px;

        .span {
          margin-right: 46px;
          font-size: 20px;
        }

        .span1 {
          margin-left: 66px;
        }

        /deep/.el-checkbox__inner {
          background-color: transparent;
        }

        /deep/.el-checkbox__label {
          color: #fff;
          font-size: 16px;
        }
      }

      .title {
        width: 100%;
        height: 70px;
        line-height: 70px;
        border-bottom: 1px solid #01EEFD;
        box-sizing: border-box;
        color: #01EEFD;
        font-size: 20px;
      }

      .add {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        height: 70px;

        .icon {
          width: 30px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          border-radius: 50%;
          background-color: #04B4D0;
          color: #fff;
          font-size: 20px;
          cursor: pointer;
        }
      }

      .el-table {
        color: #fff;
        background-color: #081C38;
        border: 4px solid #073F73;
        box-sizing: border-box;

        &::before {
          background-color: transparent;
        }

        // 表头首行
        /deep/th.el-table__cell.is-leaf {
          border-bottom: none;
        }

        /deep/td.el-table__cell {
          border-bottom: 1px solid #01EEFD;
        }

        // 表头背景颜色
        /deep/th.el-table__cell {
          background-color: #024276;
        }

        /deep/.el-table__cell {
          text-align: center;
          color: #fff;
        }

        /deep/.el-table .cell {
          text-align: center;
          color: #fff !important;
        }

        /deep/tr {
          background-color: #081C38;
        }

        /deep/.el-table__body tr:hover>td.el-table__cell {
          background-color: transparent !important;
        }

        /deep/.el-checkbox__inner {
          background-color: transparent;
        }

        /deep/.is-indeterminate .el-checkbox__inner,
        /deep/.is-checked .el-checkbox__inner {
          background-color: #04B4D0;
        }

        /deep/.el-table__body-wrapper::-webkit-scrollbar {
          width: 0;
        }

        /deep/.el-table__empty-text {
          color: #fff;
        }

        .flex {
          display: flex;
          flex-direction: row;
          justify-content: center;
          width: 100%;
          color: #fff;

          span {
            cursor: pointer;
          }

          .item {
            font-size: 14px;
            cursor: pointer;
          }

          .icon {
            color: #fff;
            font-size: 24px;
            cursor: pointer;
          }
        }
      }

      .info-btns {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 70px;

        .el-button {
          margin: 0 50px;
          background-color: #33465C;
          border: none;
          color: #fff;
        }

        .blue {
          background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
        }
      }
    }
  }
}
</style>
