<template>
  <div class="son">
    <div class="son-title">
      <span class="title-box">护理等级变更</span>
      <el-input class="postion" placeholder="姓名/身份证号" suffix-icon="el-icon-search">
      </el-input>
    </div>
    <div class="son-search">
      <div class="son-item">
        <span class="key">老人姓名</span>
        <el-input placeholder="请输入"></el-input>
      </div>
      <div class="son-item">
        <span class="key">身份证号</span>
        <el-input placeholder="请输入"></el-input>
      </div>
      <div class="son-item">
        <span class="key">性别</span>
        <el-select v-model="value" placeholder="请选择街道/镇">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="son-item">
        <span class="key">状态</span>
        <el-select v-model="value" placeholder="请选择社区/村">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>

      <el-button type="primary">搜索</el-button>
      <el-button type="primary">重置</el-button>
    </div>
    <div class="add">
      <div class="add-icon" @click="openAdd">
        <span class="el-icon-plus"></span>
      </div>
    </div>
    <div class="son-table">
      <el-table :data="tableData" height="100%" border>
        <el-table-column label="序号" type="index">
        </el-table-column>
        <el-table-column prop="address" label="老人姓名">
        </el-table-column>
        <el-table-column prop="address" label="性别">
        </el-table-column>
        <el-table-column prop="address" label="身份证号">
        </el-table-column>
        <el-table-column prop="address" label="原护理等级">
        </el-table-column>
        <el-table-column prop="address" label="现护理等级">
        </el-table-column>
        <el-table-column prop="address" label="申请人">
        </el-table-column>
        <el-table-column prop="address" label="申请日期">
        </el-table-column>
        <el-table-column prop="address" label="状态">
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <div class="flex" v-if="scope.row.address === '待审批'">
              <!-- <span @click="openSee">查看详情</span> -->
              <el-button type="primary" size="mini" @click="openSee">查看详情</el-button>
            </div>
            <div class="flex" v-else-if="scope.row.address === '审批通过'">
              <!-- <span @click="openSee">查看详情</span> -->
              <el-button type="primary" size="mini" @click="openSee">查看详情</el-button>
            </div>
            <div class="flex" v-else-if="scope.row.address === '审批拒绝'">
              <!-- <span @click="openAdd">编辑</span>
              <span @click="openSee">查看详情</span> -->
              <el-button type="primary" size="mini" @click="openAdd">编辑</el-button>
              <el-button type="primary" size="mini" @click="openSee">查看详情</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
      background :page-size="10" layout="total, prev, pager, next, jumper" :total="total" hide-on-single-page>
    </el-pagination>
    <Add v-if="showAdd" @close="closeAdd"></Add>
    <See v-if="showSee" @close="closeSee"></See>
  </div>
</template>

<script>
import Add from './add.vue'
import See from './see.vue'
export default {
  components: {
    Add,
    See
  },
  data () {
    return {
      issynchronous: false,
      options: [{
        value: '',
        label: ''
      }],
      value: '',
      tableData: [{
        date: '2016-05-02',
        name: '王小虎',
        address: '待审批'
      }, {
        date: '2016-05-04',
        name: '王小虎',
        address: '审批通过'
      }, {
        date: '2016-05-01',
        name: '王小虎',
        address: '审批拒绝'
      }],
      total: 400,
      currentPage: 0,
      showAdd: false,
      showDelete: false,
      showSee: false,
      showRun: false,
      showSchedulRun: false,
      showFileRun: false,
      showTask: false
    }
  },
  methods: {
    openAdd () {
      this.showAdd = true
    },
    closeAdd () {
      this.showAdd = false
    },
    openDelete () {
      this.showDelete = true
    },
    closeDelete () {
      this.showDelete = false
    },
    openSee () {
      this.showSee = true
    },
    closeSee () {
      this.showSee = false
    },
    openRun () {
      this.showRun = true
    },
    closeRun () {
      this.showRun = false
    },
    openSchedulRun () {
      this.showSchedulRun = true
    },
    closeSchedulRun () {
      this.showSchedulRun = false
    },
    openFileRun () {
      this.showFileRun = true
    },
    closeFileRun () {
      this.showFileRun = false
    },
    openTask () {
      this.showTask = true
    },
    closeTask () {
      this.showTask = false
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
    }
  }
}
</script>

<style lang="less" scoped>
.son {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0 10px;
  background-color: #fff;
  box-sizing: border-box;

  .son-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 60px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    .title-box {
      display: flex;
      flex-direction: row;
      height: 38px;
      line-height: 38px;
      padding: 0 9px;
      text-align: center;
      background-color: #409EFF;
      color: #fff;
      font-size: 22px;

    }

    .postion {
      width: 246px;
    }
  }

  .son-search {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    height: 70px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    .son-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 30px;

      .key {
        margin-right: 20px;
        color: #000;
        font-size: 22px;
      }

      .el-input {
        width: 180px;
      }

    }

  }

  .add {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 70px;

    .add-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      border-radius: 50%;
      background-color: #409EFF;
      color: #fff;
      font-size: 20px;
      cursor: pointer;
    }

  }

  .son-table {
    width: 100%;
    height: calc(100% - 320px);

    /deep/.el-table__cell {
      text-align: center;
      color: #000;
    }

    .flex {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      width: 100%;

      span {
        cursor: pointer;
      }
    }
  }

  // 页码样式修改
  .el-pagination {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
}
</style>
