var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"son"},[_vm._m(0),_c('div',{staticClass:"son-scroll"},[_vm._m(1),_vm._m(2),_vm._m(3),_c('div',{staticClass:"son-box"},[_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12),_c('div',{staticClass:"son-item"},[_c('span',{staticClass:"son-key"},[_vm._v("报告时间")]),_c('div',{staticClass:"son-value"},[_c('span',[_vm._v("2020-12-12 10:09:09")]),_c('el-button',{on:{"click":_vm.openReport}},[_vm._v("查看报告")])],1)]),_c('div',{staticClass:"son-item"},[_c('span',{staticClass:"son-key"},[_vm._v("挂号备注")]),_c('el-input',{attrs:{"placeholder":"请输入内容"}})],1)])]),_c('div',{staticClass:"son-btns"},[_c('el-button',{staticClass:"blue",attrs:{"size":"small"}},[_vm._v("打印")]),_c('el-button',{staticClass:"blue",attrs:{"size":"small"},on:{"click":_vm.close}},[_vm._v("保存")]),_c('el-button',{attrs:{"size":"small"},on:{"click":_vm.close}},[_vm._v("取消")])],1),(_vm.showReport)?_c('Report',{on:{"close":_vm.closeReport}}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"son-head"},[_c('div',{staticClass:"son-text"},[_c('span',[_vm._v("预约详情")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"son-title"},[_c('span',[_vm._v("体检人信息")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"son-box"},[_c('div',{staticClass:"son-item"},[_c('span',{staticClass:"son-key"},[_vm._v("患者姓名")]),_c('span',{staticClass:"son-value"},[_vm._v("赖尚荣")])]),_c('div',{staticClass:"son-item"},[_c('span',{staticClass:"son-key"},[_vm._v("就诊卡号")]),_c('span',{staticClass:"son-value"})]),_c('div',{staticClass:"son-item"},[_c('span',{staticClass:"son-key"},[_vm._v("患者头像")]),_c('img',{staticClass:"son-img"})]),_c('div',{staticClass:"son-item"},[_c('span',{staticClass:"son-key"},[_vm._v("证件类型")]),_c('span',{staticClass:"son-value"},[_vm._v("身份证号")])]),_c('div',{staticClass:"son-item"},[_c('span',{staticClass:"son-key"},[_vm._v("证件号码")]),_c('span',{staticClass:"son-value"})]),_c('div',{staticClass:"son-item"},[_c('span',{staticClass:"son-key"},[_vm._v("微信昵称")]),_c('span',{staticClass:"son-value"})]),_c('div',{staticClass:"son-item"},[_c('span',{staticClass:"son-key"},[_vm._v("手机号码")]),_c('span',{staticClass:"son-value"})]),_c('div',{staticClass:"son-item"},[_c('span',{staticClass:"son-key"},[_vm._v("关系")]),_c('span',{staticClass:"son-value"})]),_c('div',{staticClass:"son-item"},[_c('span',{staticClass:"son-key"},[_vm._v("就诊人地址")]),_c('span',{staticClass:"son-value"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"son-title"},[_c('span',[_vm._v("预约信息")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"son-item"},[_c('span',{staticClass:"son-key"},[_vm._v("就诊医院")]),_c('span',{staticClass:"son-value"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"son-item"},[_c('span',{staticClass:"son-key"},[_vm._v("医院地址")]),_c('span',{staticClass:"son-value"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"son-item"},[_c('span',{staticClass:"son-key"},[_vm._v("报道科室")]),_c('span',{staticClass:"son-value"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"son-item"},[_c('span',{staticClass:"son-key"},[_vm._v("预约体检时间")]),_c('span',{staticClass:"son-value"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"son-item"},[_c('span',{staticClass:"son-key"},[_vm._v("候诊时间")]),_c('span',{staticClass:"son-value"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"son-item"},[_c('span',{staticClass:"son-key"},[_vm._v("体检套餐")]),_c('span',{staticClass:"son-value"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"son-item"},[_c('span',{staticClass:"son-key"},[_vm._v("体检费")]),_c('span',{staticClass:"son-value"},[_vm._v("¥")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"son-item"},[_c('span',{staticClass:"son-key"},[_vm._v("预约时间")]),_c('span',{staticClass:"son-value"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"son-item"},[_c('span',{staticClass:"son-key"},[_vm._v("状态")]),_c('span',{staticClass:"son-value"})])
}]

export { render, staticRenderFns }