<template>
  <div class="son">
    <div class="son-head">
      <div class="son-text">
        <span>预约详情</span>
      </div>
    </div>
    <div class="son-scroll">
      <div class="son-title">
        <span>体检人信息</span>
      </div>
      <div class="son-box">
        <div class="son-item">
          <span class="son-key">患者姓名</span>
          <span class="son-value">赖尚荣</span>
        </div>
        <div class="son-item">
          <span class="son-key">就诊卡号</span>
          <span class="son-value"></span>
        </div>
        <div class="son-item">
          <span class="son-key">患者头像</span>
          <img class="son-img">
        </div>
        <div class="son-item">
          <span class="son-key">证件类型</span>
          <span class="son-value">身份证号</span>
        </div>
        <div class="son-item">
          <span class="son-key">证件号码</span>
          <span class="son-value"></span>
        </div>
        <div class="son-item">
          <span class="son-key">微信昵称</span>
          <span class="son-value"></span>
        </div>
        <div class="son-item">
          <span class="son-key">手机号码</span>
          <span class="son-value"></span>
        </div>
        <div class="son-item">
          <span class="son-key">关系</span>
          <span class="son-value"></span>
        </div>
        <div class="son-item">
          <span class="son-key">就诊人地址</span>
          <span class="son-value"></span>
        </div>
      </div>
      <div class="son-title">
        <span>预约信息</span>
      </div>
      <div class="son-box">
        <div class="son-item">
          <span class="son-key">就诊医院</span>
          <span class="son-value"></span>
        </div>
        <div class="son-item">
          <span class="son-key">医院地址</span>
          <span class="son-value"></span>
        </div>
        <div class="son-item">
          <span class="son-key">报道科室</span>
          <span class="son-value"></span>
        </div>
        <div class="son-item">
          <span class="son-key">预约体检时间</span>
          <span class="son-value"></span>
        </div>
        <div class="son-item">
          <span class="son-key">候诊时间</span>
          <span class="son-value"></span>
        </div>
        <div class="son-item">
          <span class="son-key">体检套餐</span>
          <span class="son-value"></span>
        </div>
        <div class="son-item">
          <span class="son-key">体检费</span>
          <span class="son-value">¥</span>
        </div>
        <div class="son-item">
          <span class="son-key">预约时间</span>
          <span class="son-value"></span>
        </div>
        <div class="son-item">
          <span class="son-key">状态</span>
          <span class="son-value"></span>
        </div>
        <div class="son-item">
          <span class="son-key">报告时间</span>
          <div class="son-value">
            <span>2020-12-12 10:09:09</span>
            <el-button @click="openReport">查看报告</el-button>
          </div>
        </div>
        <div class="son-item">
          <span class="son-key">挂号备注</span>
          <el-input placeholder="请输入内容"></el-input>
        </div>
      </div>
    </div>
    <div class="son-btns">
      <el-button size="small" class="blue">打印</el-button>
      <el-button size="small" class="blue" @click="close">保存</el-button>
      <el-button size="small" @click="close">取消</el-button>
    </div>
    <Report v-if="showReport" @close="closeReport"></Report>
  </div>
</template>

<script>
import Report from './report.vue'
export default {
  components: {
    Report
  },
  props: {
    paramsInfo: {
      type: Object,
      default () {
        return {
          id: 0,
          // regsterpayid: '',
          hostcode: '',
          title: '',
          remark: '',
          name: '',
          sfzh: '',
          accountid: '',
          wxopenid: '',
          mpopenid: '',
          unioid: '',
          begtime: '',
          endtime: ''
        }
      }
    }
  },
  data () {
    return {
      info: {
        id: 0,
        createTime: '',
        updateTime: '',
        regsterpayid: '',
        hostcode: '',
        titletype: '',
        title: '',
        count: '',
        doctor: '',
        doctorcode: '',
        fee: '',
        reghour: '',
        reghalf: '',
        specialty: '',
        remark: '',
        extparam1: '',
        extparam: '',
        deptcode: '',
        deptname: '',
        marktype: '',
        cliniclabel: '',
        registerway: '',
        flagused: '',
        flagavailable: '',
        clinicposition: '',
        clinicfee: '',
        appointcount: '',
        regcount: '',
        dqcode: '',
        day: '',
        deptcode1: '',
        deptcode2: '',
        name: '',
        sfzh: '',
        accountid: ''
      },
      showReport: false
    }
  },
  created () {
    this.getInfo()
    console.log('选择人员信息', this.paramsInfo)
  },
  computed: {
    reghour () {
      const arr = ['全天', '上午', '下午', '晚间']
      if (this.info.reghour) return arr[this.info.reghour]
      return ''
    }
  },
  methods: {
    async getInfo () {
      // 接口文档 https://app.apifox.com/project/2726972/apis/api-106422960
      const { data: res } = await this.$http.post('/hitapp/findyyghjl', this.paramsInfo)
      console.log('预约挂号记录查询', res)
      if (res.code !== 0) return this.$message.error(res.msg)
      if (res.data[0]) {
        this.info = res.data[0]
      }
    },
    doctorType (val) {
      if (val !== null) return ['', '普通号', '专家号'][val]
      return ''
    },
    typeVlaue (val) {
      if (val !== null) return ['可预约', '预约成功', '正在支付', '取消'][val]
      return ''
    },
    async submit () {},
    openReport () {
      this.showReport = true
    },
    closeReport () {
      this.showReport = false
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.son {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 88;
  background-color: #081C38;

  .son-head {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 70px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;

    .son-text {
      height: 38px;
      line-height: 38px;
      padding: 0 9px;
      background-color: #01EEFD;
      box-sizing: border-box;
      color: #fff;
      font-size: 22px;
    }
  }

  .son-scroll {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 120px);

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  .son-title {
    width: 100%;
    height: 68px;
    line-height: 68px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;
    color: #01EEFD;
    font-size: 20px;
    font-weight: 600;
  }

  .son-box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 20px 10px;
    box-sizing: border-box;

    .son-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 33%;
      height: 70px;
      color: #fff;
      font-size: 18px;

      .son-key {
        margin-right: 20px;
      }

      .son-value {
        display: flex;
        flex-direction: row;
        align-items: center;

        .el-button {
          margin-left: 6px;
          background-color: #01EEFD;
          color: #fff;
          border: none;
        }
      }

      .son-img {
        width: 70px;
        height: 70px;
        border-radius: 50%;
        background-color: #01EEFD;
      }

      .el-input {
        width: 190px;
      }
    }
  }

  .son-btns {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;

    .el-button {
      margin-left: 25px;
      margin-right: 25px;
      background-color: #33465C;
      border: none;
      color: #fff;
      font-size: 18px;
    }

    .blue {
      background: linear-gradient(108deg, #0174C9 0%, #024176 100%);
    }
  }
}
</style>
