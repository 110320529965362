<!--
 * @Author: 小宝 zksukuo@yunkangda.com
 * @Date: 2023-11-29 17:49:38
 * @LastEditors: 小宝 zksukuo@yunkangda.com
 * @LastEditTime: 2024-03-07 17:41:03
 * @FilePath: \visions\src\views\zhyl\smartPhysicalExamination\bookingRun\appointmentRecordRun\appointmentRecordRun.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="son">
    <div class="son-head">
      <div class="son-head-item">
        <div class="son-head-left">
          <div class="son-head-open"></div>
          <el-input placeholder="请输入搜索内容"></el-input>
        </div>
        <div class="son-head-center">
          <span>切换机构：</span>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="son-head-right">
          <span class="el-icon-success success"></span>
          <div class="div">
            <div class="div1">
              <div class="blue"></div>
            </div>
          </div>
          <span class="el-icon-lock lock"></span>
          <span class="el-icon-view lock"></span>
          <span class="el-icon-rank rank"></span>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <!-- <div class="son-head-item">
        <div class="son-head-title">
          <span>预约管理</span>
        </div>
        <el-button type="primary">
          <span>更多</span>
          <span class="el-icon-arrow-down icon"></span>
        </el-button>
      </div> -->
    </div>
    <div class="son-scroll">
      <div class="son-search">
        <div class="search-item">
          <div class="key">
            <span>机构</span>
          </div>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="search-item">
          <div class="key">
            <span>套餐</span>
          </div>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="search-item">
          <div class="key">
            <span>预约人</span>
          </div>
          <el-select v-model="value" placeholder="预约人或证件号码">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="search-item">
          <div class="key">
            <span>状态</span>
          </div>
          <el-select v-model="value" placeholder="预约人或证件号码">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="search-item">
          <div class="key">
            <span>预约日期</span>
          </div>
          <el-date-picker type="date" placeholder="选择日期">
          </el-date-picker>
        </div>
        <div class="search-item">
          <div class="key">
            <span>所属学校</span>
          </div>
          <el-select v-model="value" placeholder="预约人或证件号码">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="search-item">
          <div class="key">
            <span>所属班级</span>
          </div>
          <el-select v-model="value" placeholder="预约人或证件号码">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>

        <div class="button">
          <el-button @click="getList">
            <span class="el-icon-search" ></span>
            <span>查询</span>
          </el-button>
          <el-button>
            <span class="el-icon-refresh"></span>
            <span>重置</span>
          </el-button>
        </div>
      </div>

      <div class="son-info">
        <div class="son-info-title">
          <div class="info-item info-item1">
            <span>预约机构</span>
          </div>
          <div class="info-item info-item2">
            <span>预约套餐</span>
          </div>
          <div class="info-item">
            <span>预约人</span>
          </div>
          <div class="info-item info-item1">
            <span>证件号码</span>
          </div>
          <div class="info-item info-item1">
            <span>所属学校</span>
          </div>
          <div class="info-item ">
            <span>所属班级</span>
          </div>
          <div class="info-item info-item3">
            <span>预约日期</span>
          </div>
          <div class="info-item ">
            <span>状态</span>
          </div>
          <div class="info-item info-item1">
            <span>状态描述</span>
          </div>
          <div class="info-item info-item4">
            <span>操作</span>
          </div>
        </div>
        <div class="son-info-scroll">
          <div v-for="(item, index) in list" :key="index" class="scroll-list">
            <div class="info-item info-item1">
              <span>古城营镇卫生院</span>
            </div>
            <div class="info-item info-item2">
              <span>儿童入园（所）健康检查</span>
            </div>
            <div class="info-item">
              <span>{{ item.name || '-' }}</span>
            </div>
            <div class="info-item info-item1">
              <span>{{ item.idcard || '-' }}</span>
            </div>
            <div class="info-item info-item1">
              <span>-</span>
            </div>
            <div class="info-item">
              <span>-</span>
            </div>
            <div class="info-item info-item3">
              <span>{{ item.regdate || '-' }}</span>
            </div>
            <div class="info-item ">
              <span class="red">-</span>
            </div>
            <div class="info-item info-item1">
              <span></span>
            </div>
            <div class="info-item info-item4">
              <span class="span" @click="openUpdate">编辑</span>
              <span class="span">删除</span>
            </div>
          </div>
          <!-- <div class="scroll-list">
            <div class="info-item info-item1">
              <span>古城营镇卫生院</span>
            </div>
            <div class="info-item info-item2">
              <span>儿童入园（所）健康检查</span>
            </div>
            <div class="info-item">
              <span>李明明</span>
            </div>
            <div class="info-item info-item1">
              <span>1***************38</span>
            </div>
            <div class="info-item info-item1">
              <span>石家庄第一中学</span>
            </div>
            <div class="info-item">
              <span>203班</span>
            </div>
            <div class="info-item info-item3">
              <span>2023-08-03</span>
            </div>
            <div class="info-item ">
              <span class="green">已完成</span>
            </div>
            <div class="info-item info-item1">
              <span></span>
            </div>
            <div class="info-item info-item4">
              <span class="span" @click="openUpdate">编辑</span>
              <span class="span">删除</span>
            </div>
          </div> -->
        </div>
        <el-pagination
        @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="query.pageNum"
      background
      :page-size="query.pageSize"
      layout="total, prev, pager, next, jumper"
      :total="query.total"></el-pagination>
      </div>
    </div>
    <Update v-if="showUpdate" @close="closeUpdate"></Update>
  </div>
</template>

<script>
import Update from './update.vue'
export default {
  components: {
    Update
  },
  data () {
    return {
      options: [{
        value: '',
        label: ''
      }],
      value: '',
      total: 400,
      currentPage: 0,
      showUpdate: false,
      list: [],
      query: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      params: {
        mpopenid: '',
        unioid: '',
        wxopenid: '',
        type: '',
        address: '',
        accountid: '',
        sfzh: '',
        name: '',
        xb: '',
        ybrgx: '',
        phoneno: '',
        age: '',
        start: '0',
        length: '10'
      }
    }
  },
  created () {
    this.getList()
  },
  methods: {
    async getList () {
      // 接口文档 https://app.apifox.com/project/2726972/apis/api-199832954
      try {
        this.params.start = this.query.pageNum === 1 ? '0' : (this.query.pageNum - 1) * 10 + ''
        this.params.length = this.query.pageSize + ''
        const { data: res } = await this.$http.get('/appRegsterPays', { params: this.params })
        console.log('获取预约记录接口', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.list = res.data
        this.query.total = res.recordsTotal
      } catch (error) {
        console.log('获取预约记录接口请求失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    tabClick (index) {
      this.tabIndex = index
    },
    openUpdate () {
      this.showUpdate = true
    },
    closeUpdate () {
      this.showUpdate = false
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
      this.query.pageNum = val
      this.getList()
    }
  }
}
</script>

<style lang="less" scoped>
.son {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #F0F0F0;

  .son-head {
    display: flex;
    align-items: center;
    width: 100%;
    height: 70px;
    margin-bottom: 20px;
    // padding-bottom: 20px;
    background-color: #fff;
    box-sizing: border-box;

    .son-head-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 0 14px;
      box-sizing: border-box;

      .son-head-left {
        display: flex;
        align-items: center;
        width: 33%;

        .son-head-open {
          width: 23px;
          height: 6px;
          border-top: 2px solid #595959;
          border-bottom: 2px solid #595959;
        }

        .el-input {
          margin-left: 50px;
        }

        /deep/.el-input__inner {
          border-color: #fff;
          font-size: 18px;
        }
      }

      .son-head-center {
        display: flex;
        align-items: center;
        flex: 1;
        color: #595959;
        font-size: 20px;

        /deep/.el-input__inner {
          font-size: 18px;
        }
      }

      .son-head-right {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex: 1;

        .success {
          color: #67C23A;
          font-size: 39px;
        }

        .lock {
          color: #595959;
          font-size: 30px;
        }

        .rank {
          font-size: 34px;
          transform: rotate(45deg);
        }

        .div {
          width: 41px;
          height: 40px;
          padding: 6px 7px;
          border: 1px solid #E6E6E6;
          border-radius: 6px;
          box-sizing: border-box;

          .div1 {
            width: 100%;
            height: 100%;
            padding-top: 3px;
            border: 1px solid #999999;
            box-sizing: border-box;

            .blue {
              width: 100%;
              height: 100%;
              background-color: #409EFF;
            }
          }
        }

        /deep/.el-input__inner {
          text-align: right;
          border: none;
          font-size: 18px;
        }
      }

      .son-head-title {
        width: 117px;
        height: 38px;
        line-height: 38px;
        text-align: center;
        border: 1px solid #409EFF;
        border-radius: 2px;
        box-sizing: border-box;
        color: #409EFF;
        font-size: 16px;
      }

      .el-button {
        width: 100px;

        .icon {
          margin-left: 10px;
        }
      }
    }
  }

  .son-scroll {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 90px);
    padding: 0 10px;
    box-sizing: border-box;

    &::-webkit-scrollbar {
      width: 0;
    }

    .son-search {
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;
      width: 100%;
      height: 130px;
      background-color: #fff;
      border-radius: 18px;
      box-sizing: border-box;

      .search-item {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: calc(25% - 40px);

        .el-select {
          width: 220px;
        }

        /deep/.el-date-editor {
          width: 220px;
        }
      }

      .key {
        margin-right: 20px;
        color: #17212B;
        font-size: 22px;
      }

      .el-button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        padding-top: 0;
        padding-bottom: 0;
        background-color: #3FA1FF;
        color: #fff;
        font-size: 30px;
      }

      .button {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: calc(25% - 40px);
      }
    }

    .son-info {
      width: 100%;
      height: calc(100% - 150px);
      margin-top: 20px;
      padding: 0 20px;
      background-color: #fff;
      border-radius: 18px;
      box-sizing: border-box;

      .son-info-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 58px;
        border-bottom: 1px solid #333333;
        box-sizing: border-box;

        .info-left {
          display: flex;
          align-items: center;
          height: 100%;
          color: #17212B;
          font-size: 20px;

          .el-button {
            margin-left: 20px;
            background-color: #3FA1FF;
            border: none;
            color: #fff;
          }
        }

        .info-right {
          width: 70px;
          height: 58px;
          line-height: 58px;
          text-align: center;
          color: #17212B;
          font-size: 20px;
        }
      }

      .son-info-scroll {
        overflow-y: scroll;
        width: 100%;
        height: calc(100% - 150px);
        margin: 13px 0;

        &::-webkit-scrollbar {
          width: 0;
        }

        .scroll-list {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          height: 40px;
          margin-bottom: 10px;
          background-color: #C9D8E7;
          box-sizing: border-box;

          .info-item {
            font-size: 16px;
          }
        }
      }

      .info-item {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 110px;
        text-align: center;
        color: #17212B;
        font-size: 20px;

        .red {
          color: #FF0000;
        }

        .green {
          color: #29CF00;
        }
      }

      .info-item1 {
        width: 200px;
      }

      .info-item2 {
        width: 300px;
      }

      .info-item3 {
        width: 140px;
      }

      .info-item4 {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        width: 140px;

        .span {
          cursor: pointer;
        }
      }

      // 页码样式修改
      .el-pagination {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        /deep/.el-pagination__total,
        /deep/.el-pagination__jump {
          color: #000000;

          .el-input__inner {
            color: #fff;
            background-color: transparent;
          }
        }

        /deep/.el-pager li {
          background-color: #ADADAD;
          color: #fff;
        }

        /deep/.el-pager li:not(.disabled).active {
          background-color: #0077F9;
        }
      }
    }
  }
}
</style>
