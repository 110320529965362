<template>
  <div class="add">
    <div class="content">
      <div class="box">
        <div class="item">
          <div class="text">
            <span>套餐名称：</span>
          </div>
          <el-select v-model="params.tjtcbh" placeholder="请选择" @change="change">
            <el-option v-for="item in infos" :key="item.tclbbm" :label="item.tclbmc" :value="item.tclbbm">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <div class="text">
            <span>所属机构名称：{{ this.$store.state.loginInfo.jgmc }}</span>
          </div>
        </div>
        <div class="item">
          <div class="text">
            <span>总人数：</span>
          </div>
          <el-input class="input" v-model="params.tjzrs" placeholder="请输入内容"></el-input>
        </div>
        <div class="item">
          <div class="text">
            <span>限额人数：</span>
          </div>
          <el-input class="input" v-model="params.tjxzrs" placeholder="请输入内容"></el-input>
        </div>
        <div class="item">
          <div class="text">
            <span>体检时间：</span>
          </div>
          <el-date-picker
            v-model="value1"
            @change="dataChange"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </div>
      </div>
      <div class="button">
        <el-button type="primary" @click="submit">确定</el-button>
        <el-button @click="close">取消</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      options: [{
        value: '',
        label: ''
      }],
      input: '',
      value: '',
      value1: '',
      infos: [],
      params: {
        tjtcbh: '',
        ssjgbh: '',
        ssjgmc: '',
        tjtcmc: '',
        tjzrs: '',
        tjxzrs: '',
        tjsj: ''
      }
    }
  },
  created () {
    this.params.ssjgbh = this.$store.state.loginInfo.jgbh || ''
    this.params.ssjgmc = this.$store.state.loginInfo.jgmc || ''
    this.getInfo()
  },
  methods: {
    async getInfo () {
      // 接口文档 https://app.apifox.com/project/2726972/apis/api-160594366
      try {
        const { data: res } = await this.$http.post('/zhctapi/findtcxmzdyqy', {
          start: '0',
          length: '10',
          tjjgbh: this.$store.state.loginInfo.jgbh,
          ssjgbh: this.$store.state.loginInfo.jgbh
        })
        console.log('根据机构获取套餐字典', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.infos = res.data
      } catch (error) {
      }
    },
    change (e) {
      console.log(e)
      console.log('++++++++++++' + this.params.tjtcbh)
      console.log(this.infos.filter(item => item.tclbbm === this.params.tjtcbh))
      const arr = this.infos.filter(item => item.tclbbm === this.params.tjtcbh)
      if (arr.length < 1) return
      console.log(arr[0].tclbmc)
      this.params.tjtcmc = arr[0].tclbmc
    },
    dataChange (e) {
      console.log(e)
      if (e < 0) return
      this.params.tjsj = e[0] + ',' + e[1]
    },
    close () {
      this.$emit('close')
    },
    async submit () {
      try {
        console.log('=====' + this.params)
        const { data: res } = await this.$http.post('/zhct/saveyyxe', this.params)
        console.log('保存新增预约套餐', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.$message.success('保存成功')
      } catch (error) {
      }
    }
  }
}
</script>

<style lang="less" scoped>
.add {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 8;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .7);

  .content {
    width: 60%;
    height: 50%;
    padding: 20px 35px;
    background-color: #fff;
    border-radius: 6px;
    box-sizing: border-box;

    .box {
      display: flex;
      flex-wrap: wrap;
      align-content: space-evenly;
      width: 100%;
      height: calc(100% - 100px);

      .item {
        display: flex;
        align-items: center;
        width: calc(50% - 20px);
        margin: 0 10px;

        .text {
          white-space: nowrap;
          color: #17212B;
          font-size: 20px;
        }

        .input {
          width: calc(50% - 10px);
          margin-left: 10px;
        }
      }
    }

    .button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100px;
    }
  }
}
</style>
