<template>
  <div class="popup">
    <div class="popup-content">
      <div class="popup-title">
        <span>视频通话</span>
      </div>
      <div class="popup-box">
        <div class="content">
          <div class="close" @click="close">
            <i class="el-icon-close"></i>
          </div>
          <div class="content">
            <div class="video">
              <div class="user"></div>
              <div class="doctor"></div>
              <div class="operate">
                <img class="sound" src="../../../../../assets/img/smartConsultation/sound.png">
                <img class="hang-up" src="../../../../../assets/img/smartConsultation/hang-up.png">
              </div>
            </div>
            <div class="news">
              <div class="doctor-info">
                <div class="title">
                  <span>医生资料</span>
                </div>
                <div class="date">
                  <span>开始时间：2019-09-12  12：30</span>
                  <span>有效期至：2019-09-12  12：30</span>
                </div>
                <div class="line">
                  <span>病情描述：近几个月睡眠很不好，失眠多梦，盗汗，早上醒来感觉浑上班也没有精神，整个人状态都不好了，吃了一身疲累.些西药，感觉效果也不明显，不知道怎么办些西药，感觉效果也不明显，不知道怎么办</span>
                </div>
                <div class="line">
                  <span>过敏史：有5年的过敏性皇炎历史特别害怕花粉、灰尘医类的逢季节交替时比较明明显流鼻涕打喷嚏不间断，一直靠皇炎喷剂维持，没有做过手术</span>
                </div>
                <div class="line">
                  <span>患者处方：请根据患者病情描述和问诊单反馈.，为患者开出诊断建议方</span>
                </div>
                <div class="btns">
                  <el-button size="small">在线开方</el-button>
                  <el-button size="small">健康档案</el-button>
                  <el-button size="small">辅助监测</el-button>
                  <el-button size="small">会诊</el-button>
                </div>
              </div>
              <div class="sort">
                <div class="item" v-for="(item, index) in list" :key="index">
                  <img :src="item.url" class="img">
                  <div class="sort-info">
                    <span class="label">{{ item.label }}</span>
                    <span>{{ item.value }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      list: Object.freeze([
        { label: '心率', url: require('../../../../../assets/img/smartConsultation/item0.png'), value: '75次/分' },
        { label: '血压', url: require('../../../../../assets/img/smartConsultation/item1.png'), value: '75/75mmHg' },
        { label: '血氧', url: require('../../../../../assets/img/smartConsultation/item2.png'), value: '96.2%SpO2' },
        { label: '血糖', url: require('../../../../../assets/img/smartConsultation/item3.png'), value: '8.1mmol/L' },
        { label: '体温', url: require('../../../../../assets/img/smartConsultation/item4.png'), value: '37℃' },
        { label: '尿糖', url: require('../../../../../assets/img/smartConsultation/item5.png'), value: '131mg/dl' },
        { label: '尿酸', url: require('../../../../../assets/img/smartConsultation/item6.png'), value: '210umol/L' },
        { label: '胆固醇', url: require('../../../../../assets/img/smartConsultation/item7.png'), value: '8.1mmol/L' },
        { label: '心电图', url: require('../../../../../assets/img/smartConsultation/item8.png'), value: '11-25 8:10' },
        { label: '尿常规', url: require('../../../../../assets/img/smartConsultation/item9.png'), value: '210umol/L' },
        { label: '血脂', url: require('../../../../../assets/img/smartConsultation/item10.png'), value: '8.1mmol/L' },
        { label: '更多检测', url: require('../../../../../assets/img/smartConsultation/item11.png'), value: '11-25 8:10' }
      ])
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    submit () {
      this.$emit('close', this.input)
    }
  }
}
</script>

<style scoped lang="less">
.popup {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 88;
  background-color: rgba(0, 0, 0, .74);

  .popup-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1600px;
    height: 850px;

    .popup-title {
      width: 200px;
      height: 50px;
      line-height: 60px;
      background-image: url('../../../img/dialogTitleBg.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      text-align: center;
      color: #fff;
      font-size: 20px;
    }

    .popup-box {
      width: 1600px;
      height: 800px;
      padding: 10px;
      background-color: rgba(3, 27, 44, .8);
      border-radius: 10px;
      border: 4px solid rgba(1, 49, 88, .5);
      box-sizing: border-box;

      .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        width: 100%;
        height: 100%;
        padding: 30px;
        border-radius: 10px;
        border: 4px solid rgba(1, 49, 88, .5);
        box-sizing: border-box;

        .close {
          display: flex;
          align-items: flex-end;
          position: absolute;
          top: 0;
          right: 0;
          width: 50px;
          height: 50px;
          background: linear-gradient(26.565deg, #2057a7 50%, transparent 0);
          transform: rotate(180deg);
          color: #fff;
          font-size: 24px;
          cursor: pointer;
        }

        .content {
          display: flex;
          flex-direction: row;
          width: 100%;
          height: 100%;

          .video {
            width: 637px;
            height: 100%;

            .user,
            .doctor {
              width: 100%;
              height: 280px;
              background-color: #707070;
            }

            .doctor {
              margin-top: 10px;
              background-color: #333333;
            }

            .operate {
              display: flex;
              align-items: center;
              justify-content: space-evenly;
              height: 60px;
              margin-top: 10px;

              .sound {
                width: 24px;
                height: 40px;
              }

              .hang-up {
                width: 40px;
                height: 40px;
              }
            }
          }

          .news {
            width: calc(100% - 655px);
            height: 100%;
            margin-left: 18px;

            .doctor-info {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: space-between;
              width: 100%;
              height: 270px;
              padding: 12px;
              background-color: #fff;
              border-radius: 8px;
              box-sizing: border-box;

              .title {
                width: 100%;
                text-align: center;
                color: #2A374D;
                font-size: 20px;
                font-weight: 600;
              }

              .date {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 100%;
                color: #409EFF;
                font-size: 18px;
                font-weight: 600;
              }

              .line {
                width: 100%;
                color: #14487D;
                font-size: 18px;
                -webkit-line-clamp: 2;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              .btns {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;

                .el-button {
                  background-color: #409EFF;
                  color: #fff;
                }
              }
            }

            .sort {
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              width: 100%;
              height: 340px;
              margin-top: 10px;

              .item {
                display: flex;
                align-items: center;
                width: 227px;
                height: 75px;
                padding: 20px;
                background-color: #fff;
                border-radius: 8px;
                box-sizing: border-box;

                .img {
                  width: 41px;
                  height: 41px;
                }

                .sort-info {
                  display: flex;
                  flex-direction: column;
                  margin-left: 18px;
                  color: #A1A6BB;
                  font-size: 16px;

                  .label {
                    color: #2A374D;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
