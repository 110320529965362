<template>
  <div class="test">
    <div class="head">
      <span class="head-title">体检排班</span>
    </div>
    <div class="content">
      <div class="left">
        <div class="scroll" v-if="!isUpdate">
          <div class="list">
            <div class="title">
              <span>体检排班详情</span>
            </div>
            <div class="date">
              <span>2021月1月5日</span>
            </div>
            <div class="line">
              <span>周三</span>
            </div>
            <div class="date">
              <span>上午</span>
            </div>
            <div class="line">
              <span>接诊时间：8:00-12:00</span>
            </div>
            <div class="line">
              <span>号源数：20</span>
            </div>
            <div class="line">
              <span>接诊人数：0</span>
            </div>
            <div class="date margin">
              <span>下午</span>
            </div>
            <div class="line">
              <span>接诊时间：14:00-18:00</span>
            </div>
            <div class="line">
              <span>号源数：20</span>
            </div>
            <div class="line">
              <span>接诊人数：0</span>
            </div>
          </div>
        </div>
        <div class="scroll" v-else-if="isUpdate">
          <div class="list">
            <div class="title">
              <span>赵马医生排班详情</span>
            </div>
            <div class="date">
              <span>2021月1月5日</span>
            </div>
            <div class="line">
              <span>周三</span>
            </div>
            <div class="date date1">
              <span>上午</span>
              <div class="radio">
                <span>{{ isUpdate ? '接诊' : '休息' }}</span>
                <el-switch v-model="upRadio" active-color="#30F05D" inactive-color="#999999">
                </el-switch>
              </div>
            </div>
            <div class="line">
              <el-time-select :picker-options="{
          start: '00:00',
          step: '00:30',
          end: '23:59'
        }" v-model="upStart" placeholder="选择时间">
              </el-time-select>
            </div>
            <div class="line">
              <el-time-select :picker-options="{
          start: '00:00',
          step: '00:30',
          end: '23:59'
        }" v-model="upEnd" placeholder="选择时间">
              </el-time-select>
            </div>
            <div class="line">
              <el-input placeholder=""></el-input>
            </div>
            <div class="date date1 margin">
              <span>下午</span>
              <div class="radio">
                <span>{{ isUpdate ? '接诊' : '休息' }}</span>
                <el-switch v-model="downRadio" active-color="#30F05D" inactive-color="#999999">
                </el-switch>
              </div>
            </div>
            <div class="line">
              <el-time-select :picker-options="{
          start: '00:00',
          step: '00:30',
          end: '23:59'
        }" v-model="downStart" placeholder="选择时间">
              </el-time-select>
            </div>
            <div class="line">
              <el-time-select :picker-options="{
          start: '00:00',
          step: '00:30',
          end: '23:59'
        }" v-model="downEnd" placeholder="选择时间">
              </el-time-select>
            </div>
            <div class="line">
              <el-input placeholder=""></el-input>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="head">
          <el-select v-model="params.fwyf" placeholder="请选择月份" @change="change">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <el-select v-model="params.titletype" placeholder="请选择" @change="change">
            <el-option v-for="item in types" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <div class="btns" v-if="!isUpdate">
            <el-button class="blue" @click="isUpdate = true">设置排班</el-button>
            <el-button @click="openClose">关闭预约</el-button>
          </div>
          <div class="btns" v-else-if="isUpdate">
            <el-button class="blue" @click="isUpdate = false">保存设置</el-button>
          </div>
        </div>
        <div class="table">
          <div class="item" :class="{ active: index < 14 }" v-for="(item, index) in 31" :key="index">
            <span class="span">{{ index + 1 }}</span>
            <span>接诊10</span>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {
      options: [
        { value: '1', label: '1' },
        { value: '2', label: '2' },
        { value: '3', label: '3' },
        { value: '4', label: '4' },
        { value: '5', label: '5' },
        { value: '6', label: '6' },
        { value: '7', label: '7' },
        { value: '8', label: '8' },
        { value: '9', label: '9' },
        { value: '10', label: '10' },
        { value: '11', label: '11' },
        { value: '121', label: '12' }
      ],
      types: [
        { label: '儿童', value: '01' },
        { label: '慢病', value: '02' },
        { label: '老年人', value: '03' },
        { label: '其他', value: '04' }
      ],
      value: '',
      value1: '',
      upStart: '',
      upEnd: '',
      downStart: '',
      downEnd: '',
      showClose: false,
      upRadio: '',
      downRadio: '',
      isUpdate: false,
      params: {
        hostcode: '',
        openid: '',
        unioid: '',
        fwsj: '',
        fwyf: '',
        titletype: '03',
        marktype: 'tj'
      }
    }
  },
  created () {
    this.getInfo()
  },
  methods: {
    async getInfo () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-172503106
      try {
        console.log('请求参数', this.params)
        const { data: res } = await this.$http.post('/zhylapi/gettjxmyylb', this.params)
        console.log('获取体检预约列表接口', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
      } catch (error) {
        console.log('获取体检预约列表接口', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    change (e) {
      console.log(e)
      this.getInfo()
    },
    openClose () {
      this.showClose = true
    },
    closeClose () {
      this.showClose = false
    },
    close () {
      this.$emit('close')
    },
    submit () {
      this.$emit('close', this.input)
    }
  }
}
</script>

<style scoped lang="less">
.test {
  width: 100%;
  height: 100%;
  background-color: #fff;

  .head {
    display: flex;
    align-items: center;
    width: 100%;
    height: 70px;

    .head-title {
      padding: 5px 10px;
      background-color: #3FA1FF;
      color: #fff;
      font-size: 20px;
    }
  }

  .content {
    display: flex;
    align-items: center;
    width: 100%;
    height: calc(100% - 70px);
  }

  .left {
    width: 260px;
    height: 100%;
    border-right: 1px solid #FFFFFF;
    box-sizing: border-box;

    .scroll {
      width: 208px;
      height: calc(100% - 50px);
      padding: 10px;
      background-color: #3FA1FF;
      box-sizing: border-box;

      .list {
        display: flex;
        flex-direction: column;

        .title {
          margin-bottom: 20px;
          color: #fff;
          font-size: 18px;
        }

        .date {
          margin-top: 10px;
          color: #fff;
          font-size: 18px;
          font-weight: 600;
        }

        .date1 {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .radio {
            display: flex;
            flex-direction: row;
            align-items: center;
            color: #fff;
            font-size: 14px;

            .el-switch {
              margin-left: 7px;
            }
          }
        }

        .line {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          margin-top: 10px;
          color: #fff;
          font-size: 16px;
        }

        .margin {
          margin-top: 30px;
        }
      }
    }
  }

  .right {
    display: flex;
    flex-direction: column;
    width: calc(100% - 260px);
    height: 100%;
    padding: 0 20px;

    .head {
      width: 665px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .btns {
        display: flex;
        flex-direction: row;

        .el-button {
          background-color: #555555;
          border: none;
          color: #fff;
        }

        .blue {
          background-color: #3FA1FF;
        }
      }
    }

    .table {
      display: flex;
      flex-wrap: wrap;
      width: 665px;
      margin-top: 14px;

      .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 95px;
        height: 95px;
        border: 1px solid #E3E3E3;
        background-color: #FAFAFA;
        box-sizing: border-box;
        color: #B3ABAB;
        font-size: 16px;

        .span {
          font-size: 28px;
        }
      }

      .active {
        background-color: #EAFCFF;
        color: #3FA1FF !important;
      }
    }
  }
}
</style>
