<template>
  <div class="chilren">
    <div class="chilren-line">
      <div class="chilren-item">
        <span>日期</span>
        <el-date-picker type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
        </el-date-picker>
      </div>
    </div>
    <div class="chilren-week">
      <span class="el-icon-arrow-left chilren-active"></span>
      <span :class="{ 'chilren-active': index === weekIndex }" v-for="(item, index) in weeks" :key="index"
        @click="weekClick(index)">{{ item }}</span>
      <span class="el-icon-arrow-right"></span>
    </div>
    <div class="table">
      <div class="table-flex">
        <div class="table-item table-background">
          <span>疫苗类目</span>
        </div>
        <div class="table-item">
          <span>新冠疫苗</span>
        </div>
        <div class="table-item">
          <span>流感疫苗</span>
        </div>
        <div class="table-item">
          <span>HVP疫苗</span>
        </div>
        <div class="table-item">
          <span>狂犬疫苗</span>
        </div>
        <div class="table-item">
          <span>乙肝疫苗</span>
        </div>
      </div>
      <div class="table-scroll">
        <div class="table-line" v-for="(item, index) in 7" :key="index">
          <div class="table-item" v-for="(item, index) in 6" :key="index" @click="openAdd">
            <span>2022/2/1</span>
            <span>周二</span>
          </div>
        </div>
      </div>
    </div>
    <Add v-if="showAdd" @close="closeAdd"></Add>
  </div>
</template>

<script>
import Add from './add.vue'
export default {
  components: {
    Add
  },
  data () {
    return {
      weekIndex: 0,
      weeks: Object.freeze(['本周', '下周', '大下周']),
      tableData: [{
        date: '2016-05-03',
        name: '王小虎',
        province: '上海',
        city: '普陀区',
        address: '上海市普陀区金沙江路 1518 弄',
        zip: 200333
      }, {
        date: '2016-05-03',
        name: '王小虎',
        province: '上海',
        city: '普陀区',
        address: '上海市普陀区金沙江路 1518 弄',
        zip: 200333
      }, {
        date: '2016-05-03',
        name: '王小虎',
        province: '上海',
        city: '普陀区',
        address: '上海市普陀区金沙江路 1518 弄',
        zip: 200333
      }, {
        date: '2016-05-03',
        name: '王小虎',
        province: '上海',
        city: '普陀区',
        address: '上海市普陀区金沙江路 1518 弄',
        zip: 200333
      }, {
        date: '2016-05-03',
        name: '王小虎',
        province: '上海',
        city: '普陀区',
        address: '上海市普陀区金沙江路 1518 弄',
        zip: 200333
      }, {
        date: '2016-05-03',
        name: '王小虎',
        province: '上海',
        city: '普陀区',
        address: '上海市普陀区金沙江路 1518 弄',
        zip: 200333
      }, {
        date: '2016-05-03',
        name: '王小虎',
        province: '上海',
        city: '普陀区',
        address: '上海市普陀区金沙江路 1518 弄',
        zip: 200333
      }, {
        date: '2016-05-03',
        name: '王小虎',
        province: '上海',
        city: '普陀区',
        address: '上海市普陀区金沙江路 1518 弄',
        zip: 200333
      }, {
        date: '2016-05-03',
        name: '王小虎',
        province: '上海',
        city: '普陀区',
        address: '上海市普陀区金沙江路 1518 弄',
        zip: 200333
      }, {
        date: '2016-05-03',
        name: '王小虎',
        province: '上海',
        city: '普陀区',
        address: '上海市普陀区金沙江路 1518 弄',
        zip: 200333
      }],
      showAdd: false
    }
  },
  methods: {
    weekClick (index) {
      if (index === this.weekIndex) return
      this.weekIndex = index
    },
    openAdd () {
      this.showAdd = true
    },
    closeAdd () {
      this.showAdd = false
    }
  }
}
</script>

<style lang="less" scoped>
.chilren {
  position: relative;
  width: 100%;
  height: 100%;

  .chilren-line {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;

    .chilren-item {
      display: flex;
      flex-direction: row;
      align-items: center;

      span {
        color: #01EEFD;
        font-size: 16px;
        margin-right: 10px;
      }
    }
  }

  .chilren-week {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 54px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;
    color: #fff;
    font-size: 16px;

    span {
      margin-left: 63px;
    }

    .chilren-active {
      color: #01EEFD;
    }
  }

  .table {
    display: flex;
    flex-direction: row;
    width: 1480px;
    height: 360px;
    margin-top: 10px;
    border: 4px solid #073F73;

    .table-flex {
      display: flex;
      flex-direction: column;
      width: 185px;
      height: 360px;

      .table-item {
        width: 100%;
        height: 60px;
        line-height: 60px;
        text-align: center;
        border-bottom: 1px solid #01EEFD;
        box-sizing: border-box;
        color: #01EEFD;
        font-size: 17px;
      }

      .table-item:last-child {
        border-bottom: none;
      }

      .table-background {
        background-color: #073F73;
      }
    }

    .table-scroll {
      display: flex;
      flex-direction: row;
      width: calc(100% - 185px);
      height: 360px;

      .table-line {
        display: flex;
        flex-direction: column;
        width: 185px;
        height: 100%;

        .table-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 60px;
          text-align: center;
          border-left: 1px solid #01EEFD;
          border-bottom: 1px solid #01EEFD;
          box-sizing: border-box;
          color: #01EEFD;
          font-size: 17px;
        }

        .table-item:first-child {
          background-color: #073F73;
          border-left: none;
        }

        .table-item:last-child {
          border-bottom: none;
        }
      }

      .table-line:first-child {
        border-bottom: none;
      }

      .table-line:last-child {
        border-bottom: none;
      }
    }
  }
}
</style>
