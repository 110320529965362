<template>
  <div class="son" :class="{ son1: showSee }">
    <div class="son-tab">
      <div class="tab-item" :class="{ 'tab-active': index === tabIndex }" v-for="(item, index) in tabs" :key="index"
        @click="tabClick(index)">
        <span>{{ item }}</span>
      </div>
    </div>
    <div class="son-scroll">
      <div class="son-search">
        <div class="search-item">
          <div class="key">
            <span>机构</span>
          </div>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="search-item">
          <div class="key">
            <span>套餐</span>
          </div>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="search-item">
          <div class="key">
            <span>体检编号</span>
          </div>
          <el-input placeholder="请输入" suffix-icon="el-icon-caret-bottom"></el-input>
        </div>
        <div class="search-item">
          <div class="key">
            <span>姓名</span>
          </div>
          <el-select v-model="value" placeholder="姓名或证件号码">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="search-item">
          <div class="key">
            <span>状态</span>
          </div>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="search-item">
          <div class="key">
            <span>检查日期</span>
          </div>
          <el-date-picker v-model="value1" type="daterange" range-separator="至" start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </div>
        <div class="search-item">
          <div class="key">
            <span>核查日期</span>
          </div>
          <el-date-picker v-model="value1" type="daterange" range-separator="至" start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </div>
        <div class="search-item">
          <div class="key">
            <span>登记类型</span>
          </div>
          <el-input placeholder="请输入" suffix-icon="el-icon-caret-bottom"></el-input>
        </div>
        <div class="search-item">
          <div class="key">
            <span>所属</span>
          </div>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="button">
          <el-button>
            <span class="el-icon-search"></span>
            <span>查询</span>
          </el-button>
          <el-button>
            <span class="el-icon-refresh"></span>
            <span>重置</span>
          </el-button>
          <el-button>
            <span class="el-icon-upload"></span>
            <span>导出</span>
          </el-button>
          <el-button @click="openAdd">
            <span class="el-icon-plus"></span>
            <span>新增</span>
          </el-button>
        </div>
      </div>

      <div class="son-info">
        <div class="son-info-title">
          <div class="info-item info-item5">
            <span>序号</span>
          </div>
          <div class="info-item info-item1">
            <span>机构</span>
          </div>
          <div class="info-item info-item2">
            <span>套餐</span>
          </div>
          <div class="info-item info-item1">
            <span>体检编号</span>
          </div>
          <div class="info-item">
            <span>姓名</span>
          </div>
          <div class="info-item info-item6">
            <span>证件号码</span>
          </div>
          <div class="info-item info-item3">
            <span>检查日期</span>
          </div>
          <div class="info-item info-item3">
            <span>核查日期</span>
          </div>
          <div class="info-item info-item7">
            <span>状态</span>
          </div>
          <div class="info-item info-item1">
            <span>所属学校</span>
          </div>
          <div class="info-item ">
            <span>所属班级</span>
          </div>
          <div class="info-item info-item4">
            <span>操作</span>
          </div>
        </div>
        <div class="son-info-scroll">
          <div v-for="(item, index) in tableData" :key="index" class="scroll-list">
            <div class="info-item info-item5">
              <span>{{ item.id || '-' }}</span>
            </div>
            <div class="info-item info-item1">
              <span>-</span>
            </div>
            <div class="info-item info-item2">
              <span>-</span>
            </div>
            <div class="info-item info-item1">
              <span>{{ item.tmcode || '-' }}</span>
            </div>
            <div class="info-item">
              <span>{{ item.name || '-' }}</span>
            </div>
            <div class="info-item info-item6">
              <span>{{ item.sfzh || '-' }}</span>
            </div>
            <div class="info-item info-item3">
              <span>{{ item.tjrq || '-' }}</span>
            </div>
            <div class="info-item info-item3">
              <span>-</span>
            </div>
            <div class="info-item info-item7">
              <span class="green"></span>
            </div>
            <div class="info-item info-item1">
              <span>{{ item.ssxx || '-' }}</span>
            </div>
            <div class="info-item">
              <span>{{ item.ssbj || '-' }}</span>
            </div>

            <div class="info-item info-item4">
              <span class="span" @click="openSchedule">进度</span>
              <span class="span" @click="openSee">查看</span>
              <span class="span" @click="openReport">报告</span>
              <el-popover popper-class="popper" placement="bottom-start" width="86" trigger="click">
                <div class="popover" :style="style">
                  <div class="popover-item" :style="style1">
                    <span>查看上传</span>
                  </div>
                  <div class="popover-item" :style="style1">
                    <span>查看同步</span>
                  </div>
                  <div class="popover-item" :style="style1">
                    <span>同步</span>
                  </div>
                  <div class="popover-item" :style="style1">
                    <span>上传</span>
                  </div>
                  <div class="popover-item" :style="style1">
                    <span>删除</span>
                  </div>
                  <div class="popover-item" :style="style2">
                    <span>汇总</span>
                  </div>
                </div>
                <span class="span" slot="reference">
                  <span>更多</span>
                  <span class="el-icon-caret-bottom"></span>
                </span>
              </el-popover>

            </div>
          </div>
        </div>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="query.pageNum" background :page-size="query.pageSize" layout="total, prev, pager, next, jumper"
          :total="query.total"></el-pagination>
      </div>
    </div>
    <Add v-if="showAdd" @close="closeAdd"></Add>
    <Schedule v-if="showSchedule" @close="closeSchedule"></Schedule>
    <See v-if="showSee" @close="closeSee"></See>
    <Report v-if="showReport" @close="closeReport"></Report>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Add from '../physicalExaminationInfoRecordRun/add.vue'
import Schedule from '../physicalExaminationInfoRecordRun/schedule.vue'
import See from '../physicalExaminationInfoRecordRun/see/see.vue'
import Report from '../physicalExaminationInfoRecordRun/report.vue'
export default {
  components: {
    Add,
    Schedule,
    See,
    Report
  },
  data () {
    return {
      tabIndex: 0,
      tabs: Object.freeze(['内科', '外科', '脊柱侧弯', '眼科', '耳鼻喉科', '口腔科', '形态指标', '血压', '听力筛查', '肺功能检测', '血常规', '血生化', '尿常规', '心理测评']),
      options: [{
        value: '',
        label: ''
      }],
      tableData: [],
      value: '',
      value1: '',
      total: 400,
      currentPage: 0,
      showSet: false,
      style: {
        width: '100%',
        height: '186px'
      },
      style1: {
        width: '100%',
        height: '31px',
        'line-height': '31px',
        'text-align': 'center',
        'border-bottom': '1px solid #D9D9D9',
        color: '#333333',
        'font-size': '14px'
      },
      style2: {
        width: '100%',
        height: '31px',
        'line-height': '31px',
        'text-align': 'center',
        color: '#333333',
        'font-size': '14px'
      },
      selectInfo: null,
      // 分页数据
      query: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      params: {
        start: '0',
        length: '10',
        tjjgbh: '',
        sxtj: '',
        ssxxid: '',
        ssnjid: '',
        tcmcid: '',
        tjlxid: '',
        tjjd: '',
        tmsfdy: '',
        drflag: '',
        begtjsj: '',
        endtjsj: '',
        sftj: '',
        tjjdid: '',
        hcrq: '',
        tcmc2: '',
        tjrq: '',
        qxlx: '',
        tjlx: '',
        hcflag: ''
      },
      showAdd: false,
      showSchedule: false,
      showSee: false,
      showReport: false
    }
  },
  computed: {
    ...mapState(['loginInfo'])
  },
  created () {
    this.params.tjjgbh = this.loginInfo.jgbh || ''
    this.params.jgbh = this.loginInfo.jgbh || ''
    this.getInfo()
  },
  methods: {
    async getInfo () {
      // 接口文档 https://app.apifox.com/project/2726972/apis/api-159158565
      console.log(this.params)
      try {
        this.tableData = []
        this.params.start = this.query.pageNum === 1 ? '0' : (this.query.pageNum - 1) * 10 + ''
        this.params.length = this.query.pageSize + ''
        const { data: res } = await this.$http.get('/ykdzhctxstjbs', { params: this.params })
        console.log('体检列表', res)
        if (res.code !== 0 || res.data === null || res.data.length < 1) {
          return this.$message({
            message: res.msg || '暂无信息',
            type: 'warning'
          })
        }
        this.tableData = res.data
        this.query.total = res.recordsTotal
      } catch (err) {
        console.log('体检列表', err)
      }
    },
    tabClick (index) {
      this.tabIndex = index
    },
    openAdd () {
      this.showAdd = true
    },
    closeAdd () {
      this.showAdd = false
    },
    openSchedule (info) {
      this.selectInfo = JSON.parse(JSON.stringify(info))
      this.showSchedule = true
    },
    closeSchedule () {
      this.showSchedule = false
      this.selectInfo = null
    },
    openSee (info) {
      this.selectInfo = JSON.parse(JSON.stringify(info))
      this.showSee = true
    },
    closeSee () {
      this.showSee = false
      this.selectInfo = null
    },
    openReport (info) {
      this.selectInfo = JSON.parse(JSON.stringify(info))
      this.showReport = true
    },
    closeReport () {
      this.showReport = false
      this.selectInfo = null
    },
    handleSizeChange (val) {},
    handleCurrentChange (val) {
      this.query.pageNum = val
      this.getInfo()
    }
  }
}
</script>

<style lang="less" scoped>
.son {
  width: 100%;
  height: 100%;
  padding-top: 20px;
  background-color: #F0F0F0;
  box-sizing: border-box;

  .son-tab {
    overflow: hidden;
    display: flex;
    width: 100%;
    height: 30px;
    border-radius: 15px;
    background-color: #B0B0B0;

    .tab-item {
      flex: 1;
      height: 30px;
      line-height: 30px;
      text-align: center;
      color: #fff;
      font-size: 18px;
      cursor: pointer;
    }

    .tab-active {
      background-color: #3FA1FF;
    }
  }

  .son-scroll {
    width: 100%;
    height: calc(100% - 40px);
    padding: 10px;
    box-sizing: border-box;

    .son-search {
      display: flex;
      justify-content: space-evenly;
      align-content: space-evenly;
      flex-wrap: wrap;
      width: 100%;
      height: 170px;
      background-color: #fff;
      border-radius: 18px;
      box-sizing: border-box;

      .search-item {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: calc(25% - 40px);

        .el-select {
          width: 250px;
        }

        .el-input {
          width: 250px;
        }

        /deep/.el-date-editor {
          width: 250px;
        }
      }

      .key {
        margin-right: 20px;
        color: #17212B;
        font-size: 22px;
      }

      .el-button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        padding-top: 0;
        padding-bottom: 0;
        background-color: #3FA1FF;
        color: #fff;
        font-size: 30px;
      }

      .button {
        display: flex;
        align-items: center;
        width: calc(75% - 60px);
      }
    }

    .son-info {
      width: 100%;
      height: calc(100% - 180px);
      margin-top: 10px;
      padding: 0 10px;
      background-color: #fff;
      border-radius: 18px;
      box-sizing: border-box;

      .son-info-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 58px;
        border-bottom: 1px solid #333333;
        box-sizing: border-box;

        .info-left {
          display: flex;
          align-items: center;
          height: 100%;
          color: #17212B;
          font-size: 20px;

          .el-button {
            margin-left: 20px;
            background-color: #3FA1FF;
            border: none;
            color: #fff;
          }
        }

        .info-right {
          width: 70px;
          height: 58px;
          line-height: 58px;
          text-align: center;
          color: #17212B;
          font-size: 20px;
        }
      }

      .son-info-scroll {
        overflow-y: scroll;
        width: 100%;
        height: calc(100% - 150px);
        margin: 13px 0;

        &::-webkit-scrollbar {
          width: 0;
        }

        .scroll-list {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          padding-top: 10px;
          padding-bottom: 10px;
          margin-bottom: 10px;
          background-color: #C9D8E7;
          box-sizing: border-box;

          .info-item {
            font-size: 15px;
          }
        }
      }

      .info-item {
        width: 100px;
        word-break: break-all;
        text-align: center;
        color: #17212B;
        font-size: 20px;

        .red {
          color: #FF0000;
        }

        .green {
          color: #29CF00;
        }
      }

      .info-item1 {
        width: 160px;
      }

      .info-item2 {
        width: 230px;
      }

      .info-item3 {
        width: 140px;
      }

      .info-item4 {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        width: 200px;

        .span {
          cursor: pointer;
          color: #399BFB;
        }

        .popper {
          padding: 0 !important;
        }

        .popover {
          width: 86px;
          height: 186px;
          background-color: red;

          .popover-item {
            width: 100%;
            height: 31px;
            line-height: 31px;
            text-align: center;
            border-bottom: 1px solid #D9D9D9;
            color: #333333;
            font-size: 14px;
          }

          .popover-item:last-child {
            border: none;
          }
        }
      }

      .info-item5 {
        width: 55px;
      }

      .info-item6 {
        width: 190px;
      }

      .info-item7 {
        width: 110px;
      }

      // 页码样式修改
      .el-pagination {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        /deep/.el-pagination__total,
        /deep/.el-pagination__jump {
          color: #000000;

          .el-input__inner {
            color: #fff;
            background-color: transparent;
          }
        }

        /deep/.el-pager li {
          background-color: #ADADAD;
          color: #fff;
        }

        /deep/.el-pager li:not(.disabled).active {
          background-color: #0077F9;
        }
      }
    }
  }
}

.son1 {
  position: relative;
}
</style>
