<template>
  <div class="page-box">
    <div class="tab">
      <span class="item active">采购申请</span>
    </div>
    <el-input placeholder="姓名/身份证号" suffix-icon="el-icon-search" class="input">
    </el-input>
    <div class="tab-box">
      <div class="run">
        <div class="son-tab">
          <span class="son-item son-active">创建采购申请</span>
        </div>
        <div class="run-search">
          <div class="run-item">
            <div class="key">
              <span>老人</span>
            </div>
            <el-input placeholder="请输入"></el-input>
          </div>
          <div class="run-item">
            <div class="key">
              <span>床位</span>
            </div>
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="run-item">
            <div class="key">
              <span>登记时间</span>
            </div>
            <el-date-picker type="date" placeholder="请选择">
            </el-date-picker>
          </div>
          <el-button type="primary">搜索</el-button>
          <el-button type="primary">重置</el-button>
        </div>

        <div class="table">
          <el-table :data="tableData" height="100%" border>
          <el-table-column label="序号" type="index" width="100">
          </el-table-column>
          <el-table-column prop="name" label="创建时间">
          </el-table-column>
          <el-table-column prop="address" label="申请人">
          </el-table-column>
          <el-table-column prop="address" label="品种数">
          </el-table-column>
          <el-table-column prop="address" label="实际采购成本">
          </el-table-column>
          <el-table-column prop="address" label="状态">
          </el-table-column>
          <el-table-column label="操作" width="440">
            <template>
              <!-- <div class="table-run" v-if="scope.row.address === '未返回'">
                <span @click="openUpload">编辑</span>
                <span @click="openDelete">删除</span>
                <span>提交</span>
                <span @click="openSee">查看详情</span>
              </div>
              <div class="table-run" v-else>
                <span @click="openUpload">编辑</span>
                <span @click="openCheckAccept">收货验收</span>
              </div> -->
              <el-button type="primary" size="mini" @click="openUpload">编辑</el-button>
              <el-button type="primary" size="mini" @click="openDelete">删除</el-button>
              <el-button type="primary" size="mini">提交</el-button>
              <el-button type="primary" size="mini" @click="openSee">查看详情</el-button>
              <el-button type="primary" size="mini" @click="openCheckAccept">收货验收</el-button>
            </template>
          </el-table-column>
        </el-table>
        </div>

        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage4"
          :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper" :total="400">
        </el-pagination>
      </div>
    </div>
    <!-- 编辑 -->
    <Upload v-if="showUpload" @close="closeUpload"></Upload>
    <!-- 删除 -->
    <Delete v-if="showDelete" @close="closeDelete"></Delete>
    <!-- 查看详情 -->
    <See v-if="showSee" @close="closeSee"></See>
    <!-- 收货验收 -->
    <CheckAccept v-if="showCheckAccept" @close="closeCheckAccept"></CheckAccept>
  </div>
</template>

<script>
import Upload from './upload.vue'
import Delete from './delete.vue'
import See from './see.vue'
import CheckAccept from './checkAccept.vue'
export default {
  components: {
    Upload,
    Delete,
    See,
    CheckAccept
  },
  data () {
    return {
      tableData: [{
        date: '2016-05-02',
        name: '王小虎',
        address: '等待外出'
      }, {
        date: '2016-05-04',
        name: '王小虎',
        address: '未返回'
      }, {
        date: '2016-05-01',
        name: '王小虎',
        address: '逾期未返回'
      }],
      options: [{
        value: '',
        label: ''
      }],
      value: '',
      currentPage4: 4,
      tabIndex: 0,
      selectIndex: 0,
      showSee: false,
      showUpload: false,
      showDelete: false,
      showCheckAccept: false
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    },
    openSee () {
      this.showSee = true
    },
    closeSee () {
      this.showSee = false
    },
    openUpload () {
      this.showUpload = true
    },
    closeUpload () {
      this.showUpload = false
    },
    openDelete () {
      this.showDelete = true
    },
    closeDelete () {
      this.showDelete = false
    },
    openCheckAccept () {
      this.showCheckAccept = true
    },
    closeCheckAccept () {
      this.showCheckAccept = false
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.page-box {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  background-color: #fff;

  .input {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 246px;
  }

  .tab {
    display: flex;
    align-items: center;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    .item {
      width: 130px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      margin-left: 30px;
      background-color: #284867;
      color: #fff;
      font-size: 22px;
      cursor: pointer;

      &:first-child {
        margin-left: 0px;
      }
    }

    .active {
      background-color: #66b1ff;
    }
  }

  .tab-box {
    width: 100%;
    height: calc(100% - 78px);
  }

}

.run {
  width: 100%;
  height: 100%;

  .son-tab {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    .son-item {
      height: 38px;
      line-height: 38px;
      margin-right: 40px;
      padding: 0 20px;
      text-align: center;
      background-color: #284867;
      color: #fff;
      font-size: 16px;
      cursor: pointer;
    }

    .son-active {
      background-color: #66b1ff;
    }
  }

  .son-tab1 {
    border: none;
  }

  .son-add {
    display: flex;
    align-items: center;
    height: 50px;

    .son-add-icon {
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      background-color: #04B4D0;
      border-radius: 50%;
      color: #fff;
      font-size: 16px;
      cursor: pointer;
    }
  }

  .run-search {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    .run-item {
      display: flex;
      align-items: center;
      margin-right: 30px;

      .key {
        margin-right: 10px;
        white-space: nowrap;
        color: #000;
        font-size: 22px;
      }
    }
  }

  .table {
    width: 100%;
    height: calc(100% - 240px);
  }

  /deep/.el-table .cell {
    text-align: center;
    color: #000;
  }

  .table-run {
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    span {
      cursor: pointer;
    }
  }

  .el-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
}
</style>
