<template>
  <div class="run-box">
    <div class="tab" @click="close">
      <span class="item active">老人管理</span>
    </div>
    <el-input placeholder="姓名/身份证号" suffix-icon="el-icon-search" class="input">
    </el-input>
    <div class="tab-box">
      <div class="run">
        <div class="run-search">
          <div class="run-item">
            <div class="key">
              <span>老人姓名</span>
            </div>
            <el-input placeholder="请输入"></el-input>
          </div>
          <div class="run-item">
            <div class="key">
              <span>身份证号码</span>
            </div>
            <el-input placeholder="请输入"></el-input>
          </div>
          <div class="run-item">
            <div class="key">
              <span>床位</span>
            </div>
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="run-item">
            <div class="key">
              <span>入住状态</span>
            </div>
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <el-button>搜索</el-button>
          <el-button>重置</el-button>
        </div>

        <div class="run-search">
          <div class="run-add">
            <span class="el-icon-plus"></span>
          </div>
        </div>

        <el-table :data="tableData" height="50%">
          <el-table-column label="序号" type="index" width="50">
          </el-table-column>
          <el-table-column prop="name" label="头像">
          </el-table-column>
          <el-table-column prop="address" label="老人姓名">
          </el-table-column>
          <el-table-column prop="address" label="性别">
          </el-table-column>
          <el-table-column prop="address" label="身份证号">
          </el-table-column>
          <el-table-column prop="address" label="床位">
          </el-table-column>
          <el-table-column prop="address" label="状态">
          </el-table-column>
          <el-table-column label="操作" width="280">
            <template slot-scope="scope">
              <div class="table-run" v-if="scope.row.address === '未签约'">
                <span>编辑</span>
                <span>档案管理</span>
                <span @click="openRunDelete">删除</span>
              </div>
              <div class="table-run" v-else-if="scope.row.address === '预定中'">
                <span>编辑</span>
                <span>档案管理</span>
              </div>
              <div class="table-run" v-else-if="scope.row.address === '已入院'">
                <span>编辑</span>
                <span>档案管理</span>
                <span>排班管理</span>
                <span>计划任务</span>
              </div>
            </template>
          </el-table-column>
        </el-table>

        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage4"
          :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper" :total="400">
        </el-pagination>
      </div>
    </div>
    <RunDelete v-if="showRunDelete" @close="closeRunDelete"></RunDelete>
  </div>
</template>

<script>
import RunDelete from './runDelete.vue'
export default {
  components: {
    RunDelete
  },
  data () {
    return {
      tableData: [{
        date: '2016-05-02',
        name: '王小虎',
        address: '未签约'
      }, {
        date: '2016-05-04',
        name: '王小虎',
        address: '预定中'
      }, {
        date: '2016-05-01',
        name: '王小虎',
        address: '已入院'
      }],
      options: [{
        value: '',
        label: ''
      }],
      value: '',
      currentPage4: 4,
      tabIndex: 0,
      selectIndex: 0,
      showAdd: false,
      showRunDelete: false,
      showSee: false
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    },
    openAdd () {
      this.showAdd = true
    },
    closeAdd () {
      this.showAdd = false
    },
    openRunDelete () {
      this.showRunDelete = true
    },
    closeRunDelete () {
      this.showRunDelete = false
    },
    openSee () {
      this.showSee = true
    },
    closeSee () {
      this.showSee = false
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.run-box {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 88;
  width: 100%;
  height: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  background-color: #081C38;

  .input {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 246px;

    /deep/.el-input__inner {
      border: none;
      background: linear-gradient(153deg, #0A477B 0%, #0E67AD 100%);
      color: #fff;
      font-size: 18px;
    }

    /deep/.el-input__inner::-webkit-input-placeholder {
      color: #fff;
    }

    /deep/.el-input__suffix {
      color: #fff;
      font-size: 18px;
    }
  }

  .tab {
    display: flex;
    align-items: center;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;

    .item {
      height: 38px;
      line-height: 38px;
      text-align: center;
      margin-left: 30px;
      padding: 0 10px;
      background-color: #284867;
      color: #fff;
      font-size: 22px;
      cursor: pointer;

      &:first-child {
        margin-left: 0px;
      }
    }

    .active {
      background-color: #04B4D0;
    }
  }

  .tab-box {
    width: 100%;
    height: calc(100% - 78px);
  }

}

.run {
  width: 100%;
  height: 100%;
  background-color: #081C38;

  .son-tab {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;

    .son-item {
      height: 38px;
      line-height: 38px;
      margin-right: 40px;
      padding: 0 20px;
      text-align: center;
      background-color: #284867;
      color: #fff;
      font-size: 16px;
      cursor: pointer;
    }

    .son-active {
      background-color: #04B4D0;
    }
  }

  .son-tab1 {
    border: none;
  }

  .son-add {
    display: flex;
    align-items: center;
    height: 50px;

    .son-add-icon {
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      background-color: #04B4D0;
      border-radius: 50%;
      color: #fff;
      font-size: 16px;
      cursor: pointer;
    }
  }

  .run-search {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;

    .run-add {
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      border-radius: 50%;
      background-color: #04B4D0;
      color: #fff;
      font-size: 20px;
    }

    .run-item {
      display: flex;
      align-items: center;
      margin-right: 50px;

      .key {
        margin-right: 10px;
        white-space: nowrap;
        color: #01EEFD;
        font-size: 22px;
      }
    }

    .el-button {
      height: 38px;
      line-height: 38rpx;
      margin-right: 40px;
      border: none;
      background-color: #04B4D0;
      color: #fff;
    }
  }

  .el-table {
    margin-top: 10px;
    border: 4px solid #073F73;
    background-color: #081C38;
  }

  /deep/.el-table__body-wrapper {
    background-color: #081C38;
  }

  /deep/.el-table th.el-table__cell.is-leaf {
    border-bottom: none;
  }

  /deep/.el-table th.el-table__cell {
    background-color: #024276;
  }

  /deep/.el-table .cell {
    text-align: center;
    color: #01EEFD;
  }

  /deep/.el-table tr {
    background-color: #081C38;
  }

  /deep/.el-table td.el-table__cell {
    border-bottom: 1px solid #01EEFD;
  }

  .el-table::before {
    height: 0;
  }

  /deep/.el-table tbody tr:hover>td {
    background-color: transparent;
  }

  /deep/.el-checkbox__inner {
    background-color: transparent;
  }

  .table-run {
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    span {
      cursor: pointer;
    }
  }

  .el-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;

    /deep/.el-pagination__total {
      color: #01EEFD;
    }

    /deep/.el-pagination__jump {
      color: #01EEFD;
    }

    /deep/.el-input__inner {
      background-color: transparent;
      color: #01EEFD;
    }

    /deep/.number {
      margin-left: 10px;
      background-color: #ADADAD;
      color: #fff;
    }

    /deep/.active {
      background-color: #01EEFD;
    }

    /deep/.el-pagination .btn-prev {
      background-color: #ADADAD !important;
      padding-right: 0;
    }

    /deep/.btn-next {
      margin-left: 10px;
      padding: 0;
    }
  }
}
</style>
