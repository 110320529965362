<template>
  <div class="add">
    <div class="add-head">
      <span>新增表单</span>
      <el-input placeholder="姓名/身份证号" suffix-icon="el-icon-search" class="input"></el-input>
    </div>
    <div class="add-box">
      <div class="add-flex">
        <div class="add-left">
          <div class="title">
            <span>选择题</span>
          </div>
          <div class="select">
            <span class="item" :class="{ active: index === selectIndex }" v-for="(item, index) in selects1" :key="index"
              @click="selectClick(index)">{{ item }}</span>
          </div>
          <div class="title">
            <span>填空题</span>
          </div>
          <div class="select">
            <span class="item" :class="{ active: index + 4 === selectIndex }" v-for="(item, index) in selects2"
              :key="index" @click="selectClick(index + 4)">{{ item }}</span>
          </div>
          <div class="title">
            <span>评分题</span>
          </div>
          <div class="select">
            <span class="item" :class="{ active: index + 6 === selectIndex }" v-for="(item, index) in selects3"
              :key="index" @click="selectClick(index + 6)">{{ item }}</span>
          </div>
        </div>
        <div class="add-right">
          <el-carousel direction="vertical" ref="remarkCarusel" :autoplay="false">
            <el-carousel-item>
              <div class="list">
                <div class="list-title">
                  <el-input placeholder="请输入表单名称"></el-input>
                </div>
                <div class="list-box">
                  <div class="list-box-title">
                    <span>1、 标题</span>
                  </div>
                  <el-radio-group v-model="radio">
                    <el-radio label="1">
                      <span>选项1（分值：1）</span>
                      <div class="icon">
                        <span class="size el-icon-edit"></span>
                        <span class="size el-icon-delete-solid"></span>
                        <span class="icons el-icon-arrow-down"></span>
                        <span class="icons el-icon-arrow-up"></span>
                      </div>
                    </el-radio>
                    <el-radio label="2">
                      <span>选项2（分值：2）</span>
                    </el-radio>
                    <el-radio label="3">
                      <span>选项3（分值：3）</span>
                    </el-radio>
                    <el-radio label="4">
                      <span>选项4（分值：4）</span>
                    </el-radio>
                  </el-radio-group>
                </div>
                <div class="list-content">
                  <div class="list-line">
                    <span class="list-key">标题</span>
                    <el-input placeholder=" 请输入"></el-input>
                  </div>
                  <div class="list-line">
                    <span class="list-key"></span>
                    <el-input type="textarea" placeholder="">
                    </el-input>
                  </div>
                </div>
                <div class="list-select">
                  <div class="list-select-title">
                    <span>选项</span>
                  </div>
                  <el-table :data="tableData" border max-height="140">
                    <el-table-column label="选项文字">
                      <template>
                        <el-input></el-input>
                      </template>
                    </el-table-column>
                    <el-table-column prop="address" label="选项内容">
                    </el-table-column>
                    <el-table-column label="操作" width="120">
                      <template>
                        <div class="table-run">
                          <span>删除</span>
                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
                <div class="list-tip">
                  <span>请输入选项内容</span>
                </div>
                <div class="list-btns">
                  <el-button size="small" type="primary">新增选项</el-button>
                  <el-button size="small" type="primary">批量增加</el-button>
                  <el-button size="small" type="primary">添加选项</el-button>
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="list">
                <div class="list-title">
                  <el-input placeholder="请输入表单名称"></el-input>
                </div>
                <div class="list-box">
                  <div class="list-box-title">
                    <span>2、 标题</span>
                  </div>
                  <el-radio-group v-model="radio">
                    <el-radio label="1">
                      <span>选项1（分值：1）</span>
                      <div class="icon">
                        <span class="size el-icon-edit"></span>
                        <span class="size el-icon-delete-solid"></span>
                        <span class="icons el-icon-arrow-down"></span>
                        <span class="icons el-icon-arrow-up"></span>
                      </div>
                    </el-radio>
                    <el-radio label="2">
                      <span>选项2（分值：2）</span>
                    </el-radio>
                    <el-radio label="3">
                      <span>选项3（分值：3）</span>
                    </el-radio>
                    <el-radio label="4">
                      <span>选项4（分值：4）</span>
                    </el-radio>
                  </el-radio-group>
                </div>
                <div class="list-content">
                  <div class="list-line">
                    <span class="list-key">标题</span>
                    <el-input placeholder=" 请输入"></el-input>
                  </div>
                  <div class="list-line">
                    <span class="list-key"></span>
                    <el-input type="textarea" placeholder="">
                    </el-input>
                  </div>
                </div>
                <div class="list-select">
                  <div class="list-select-title">
                    <span>选项</span>
                  </div>
                  <el-table :data="tableData" border max-height="140">
                    <el-table-column label="选项文字">
                      <template>
                        <el-input></el-input>
                      </template>
                    </el-table-column>
                    <el-table-column prop="address" label="选项内容">
                    </el-table-column>
                    <el-table-column label="操作" width="120">
                      <template>
                        <div class="table-run">
                          <span>删除</span>
                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
                <div class="list-tip">
                  <span>请输入选项内容</span>
                </div>
                <div class="list-btns">
                  <el-button size="small" type="primary">新增选项</el-button>
                  <el-button size="small" type="primary">批量增加</el-button>
                  <el-button size="small" type="primary">添加选项</el-button>
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="list">
                <div class="list-title">
                  <el-input placeholder="请输入表单名称"></el-input>
                </div>
                <div class="list-content list-border">
                  <div class="list-content-title">
                    <span>3、 标题</span>
                  </div>
                  <div class="list-line">
                    <span class="list-key"></span>
                    <el-select v-model="value" placeholder="请选择">
                      <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                      </el-option>
                    </el-select>
                    <div class="list-positon-icon">
                      <span class="size el-icon-edit"></span>
                      <span class="size el-icon-delete-solid"></span>
                      <span class="icons el-icon-arrow-up"></span>
                      <span class="icons el-icon-arrow-down"></span>
                    </div>
                  </div>
                </div>
                <div class="list-content">
                  <div class="list-content-title">
                    <span>标题</span>
                    <span class="size">(单/多选题/下拉框题的选项范围从2到20)</span>
                  </div>
                  <div class="list-line">
                    <span class="list-key"></span>
                    <el-input placeholder=" 请输入"></el-input>
                    <div class="list-icon">
                      <span class="size el-icon-delete-solid"></span>
                      <span class="icons el-icon-arrow-up"></span>
                      <span class="icons el-icon-arrow-down"></span>
                    </div>
                  </div>
                  <div class="list-line">
                    <span class="list-key"></span>
                    <el-input placeholder=" 请输入"></el-input>
                    <div class="list-icon">
                      <span class="size el-icon-delete-solid"></span>
                      <span class="icons el-icon-arrow-up"></span>
                      <span class="icons el-icon-arrow-down"></span>
                    </div>
                  </div>
                  <div class="list-line">
                    <span class="list-key"></span>
                    <el-input placeholder=" 请输入"></el-input>
                    <div class="list-icon">
                      <span class="size el-icon-delete-solid"></span>
                      <span class="icons el-icon-arrow-up"></span>
                      <span class="icons el-icon-arrow-down"></span>
                    </div>
                  </div>
                  <div class="list-line">
                    <span class="list-key"></span>
                    <el-input placeholder=" 请输入"></el-input>
                    <div class="list-icon">
                      <span class="size el-icon-delete-solid"></span>
                      <span class="icons el-icon-arrow-up"></span>
                      <span class="icons el-icon-arrow-down"></span>
                    </div>
                  </div>
                  <div class="list-line">
                    <span class="list-key">其他</span>
                    <el-input placeholder=" 请输入"></el-input>
                  </div>
                </div>
                <div class="list-tip"></div>
                <div class="list-btns">
                  <el-button size="small" type="primary">新增选项</el-button>
                  <el-button size="small" type="primary">批量增加</el-button>
                  <el-button size="small" type="primary">添加选项</el-button>
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="list">
                <div class="list-title">
                  <el-input placeholder="请输入表单名称"></el-input>
                </div>
                <div class="list-box">
                  <div class="list-box-title">
                    <span>4、 标题</span>
                  </div>
                  <div class="list-upload">
                    <el-upload class="avatar-uploader" action="https://jsonplaceholder.typicode.com/posts/"
                      :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                      <img v-if="imageUrl" :src="imageUrl" class="avatar">
                      <div v-else class="upload-text">
                        <span class="upload-icon el-icon-upload"></span>
                        <span>选择文件(不超过14MB)</span>
                      </div>
                    </el-upload>
                    <div class="icon">
                      <span class="size el-icon-edit"></span>
                      <span class="size el-icon-delete-solid"></span>
                      <span class="icons el-icon-arrow-up"></span>
                      <span class="icons el-icon-arrow-down"></span>
                    </div>
                  </div>
                </div>
                <div class="list-content">
                  <div class="list-line">
                    <span class="list-key">标题</span>
                    <el-input placeholder=" 请输入"></el-input>
                  </div>
                  <div class="list-line">
                    <span class="list-key"></span>
                    <el-input type="textarea" placeholder="">
                    </el-input>
                  </div>
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="list">
                <div class="list-title">
                  <el-input placeholder="请输入表单名称"></el-input>
                </div>
                <div class="list-content list-border">
                  <div class="list-content-title">
                    <span>5、 标题</span>
                  </div>
                  <div class="list-line">
                    <span class="list-key"></span>
                    <el-select v-model="value" placeholder="请选择">
                      <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                      </el-option>
                    </el-select>
                    <div class="list-positon-icon">
                      <span class="size el-icon-edit"></span>
                      <span class="size el-icon-delete-solid"></span>
                      <span class="icons el-icon-arrow-up"></span>
                      <span class="icons el-icon-arrow-down"></span>
                    </div>
                  </div>
                </div>
                <div class="list-content">
                  <div class="list-line">
                    <span class="list-key">标题</span>
                    <el-input placeholder=" 请输入"></el-input>
                  </div>
                  <div class="list-line">
                    <span class="list-key"></span>
                    <el-input type="textarea" placeholder="">
                    </el-input>
                  </div>
                </div>
                <div class="list-box list-box1">
                  <div class="list-box-title">
                    <span>填空属性</span>
                  </div>
                  <el-radio-group v-model="radio">
                    <el-radio label="1">
                      <span>文本</span>
                      <div class="label">
                        <span>最小字数</span>
                        <el-input placeholder="请输入"></el-input>
                        <span>最大字数</span>
                        <el-input placeholder="请输入"></el-input>
                      </div>
                    </el-radio>
                    <el-radio label="2">
                      <span>整数</span>
                      <div class="label">
                        <span>最小值</span>
                        <el-input placeholder="请输入"></el-input>
                        <span>最大值</span>
                        <el-input placeholder="请输入"></el-input>
                      </div>
                    </el-radio>
                    <el-radio label="3">
                      <span>日期</span>
                    </el-radio>
                    <el-radio label="4">
                      <span>手机</span>
                    </el-radio>
                    <el-radio label="5">
                      <span>邮箱</span>
                    </el-radio>
                    <el-radio label="6">
                      <span>省市区</span>
                    </el-radio>
                    <el-radio label="7">
                      <span>身份证号</span>
                    </el-radio>
                  </el-radio-group>
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="list">
                <div class="list-title">
                  <el-input placeholder="请输入表单名称"></el-input>
                </div>
                <div class="list-content list-border">
                  <div class="list-content-title">
                    <span>6、</span>
                  </div>
                  <div class="list-line">
                    <div class="list-line-left">
                      <div class="list-line-item">
                        <span>姓名：</span>
                        <el-input></el-input>
                      </div>
                      <div class="list-line-item">
                        <span>年龄：</span>
                        <el-input></el-input>
                      </div>
                      <div class="list-line-item">
                        <span>电话：</span>
                        <el-input></el-input>
                      </div>
                    </div>
                    <div class="list-positon-icon">
                      <span class="size el-icon-edit"></span>
                      <span class="size el-icon-delete-solid"></span>
                      <span class="icons el-icon-arrow-up"></span>
                      <span class="icons el-icon-arrow-down"></span>
                    </div>
                  </div>
                </div>
                <div class="list-content">
                  <div class="list-line">
                    <span class="list-key">标题</span>
                    <el-input placeholder="请输入标题描述"></el-input>
                  </div>
                  <div class="list-line">
                    <span class="list-key"></span>
                    <div class="textarea">
                      <div class="textarea-line">
                        <span class="span">电话：</span>
                        <el-input></el-input>
                      </div>
                      <div class="textarea-line">
                        <span class="span">年龄：</span>
                        <el-input></el-input>
                      </div>
                      <div class="textarea-line">
                        <span class="span">电话：</span>
                        <el-input></el-input>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </el-carousel-item>
            <!-- 量表题 -->
            <el-carousel-item>
              <div class="list">
                <div class="list-title">
                  <el-input placeholder="请输入表单名称"></el-input>
                </div>
                <div class="list-content list-border">
                  <div class="list-content-title">
                    <span>7、 标题</span>
                  </div>
                  <div class="list-line">
                    <div class="list-line-left">
                      <div class="list-line-item">
                        <span>很不满意</span>
                        <el-radio-group class="icons" v-model="radio">
                          <el-radio label="1">1</el-radio>
                          <el-radio label="2">2</el-radio>
                          <el-radio label="3">3</el-radio>
                          <el-radio label="4">4</el-radio>
                          <el-radio label="5">5</el-radio>
                        </el-radio-group>
                        <span>很满意</span>
                      </div>
                      <div class="list-line-item">
                        <span>很不满意</span>
                        <div class="icons">
                          <span class="el-icon-star-off color"></span>
                          <span class="el-icon-star-off color"></span>
                          <span class="el-icon-star-off color"></span>
                          <span class="el-icon-star-off color"></span>
                          <span class="el-icon-star-off color"></span>
                        </div>
                        <span>很满意</span>
                      </div>
                      <div class="list-line-item">
                        <span>很不满意</span>
                        <div class="icons">
                          <img class="img" src="../.././../../../assets/img/like.png" alt="">
                          <img class="img" src="../.././../../../assets/img/like.png" alt="">
                          <img class="img" src="../.././../../../assets/img/like.png" alt="">
                          <img class="img" src="../.././../../../assets/img/like.png" alt="">
                          <img class="img" src="../.././../../../assets/img/like.png" alt="">
                        </div>
                        <span>很满意</span>
                      </div>
                      <div class="list-line-item">
                        <span>很不满意</span>
                        <div class="icons">
                          <div class="label">
                            <span class="item">1</span>
                            <span class="item">2</span>
                            <span class="item">3</span>
                            <span class="item">4</span>
                            <span class="item">5</span>
                          </div>
                        </div>
                        <span>很满意</span>
                      </div>
                    </div>
                    <div class="list-positon-icon">
                      <span class="size el-icon-edit"></span>
                      <span class="size el-icon-delete-solid"></span>
                      <span class="icons el-icon-arrow-up"></span>
                      <span class="icons el-icon-arrow-down"></span>
                    </div>
                  </div>
                </div>
                <div class="list-content">
                  <div class="list-line">
                    <span class="list-key">标题</span>
                    <el-input placeholder="请输入标题描述"></el-input>
                  </div>
                  <div class="list-line">
                    <span class="list-key"></span>
                    <div class="textarea">
                      <div class="textarea-line">
                        <span class="span">电话：</span>
                        <el-input></el-input>
                      </div>
                      <div class="textarea-line">
                        <span class="span">年龄：</span>
                        <el-input></el-input>
                      </div>
                      <div class="textarea-line">
                        <span class="span">电话：</span>
                        <el-input></el-input>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="list-select">
                  <div class="list-select-title list-select-title1">
                    <span>选项</span>
                    <div class="list-select-item">
                      <span>量表类型</span>
                      <el-select size="small" v-model="value" placeholder="请选择">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                      </el-select>
                      <el-radio-group class="icons" v-model="radio">
                        <span class="color">样式</span>
                        <el-radio label="1">
                          <span class="color">123</span>
                        </el-radio>
                        <el-radio label="2">
                          <span class="el-icon-star-off icon"></span>
                        </el-radio>
                        <el-radio label="3">
                          <img class="img" src="../.././../../../assets/img/like.png" alt="">
                        </el-radio>
                        <el-radio label="4">
                          <div class="label">
                            <span>1</span>
                          </div>
                        </el-radio>
                      </el-radio-group>
                    </div>
                  </div>
                  <el-table :data="tableData" border max-height="140">
                    <el-table-column label="选项文字">
                      <template>
                        <el-input></el-input>
                      </template>
                    </el-table-column>
                    <el-table-column prop="address" label="选项内容">
                    </el-table-column>
                    <el-table-column label="分数">
                      <template>
                        <div class="table-flex">
                          <el-input></el-input>
                          <el-checkbox>不计分</el-checkbox>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column label="操作" width="160">
                      <template>
                        <div class="table-run">
                          <span class="size el-icon-delete-solid"></span>
                          <span class="icons el-icon-arrow-down"></span>
                          <span class="icons el-icon-arrow-up"></span>
                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
                <div class="list-tip"></div>
                <div class="list-btns">
                  <el-button size="small" type="primary">新增选项</el-button>
                  <el-button size="small" type="primary">批量增加</el-button>
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="list">
                <div class="list-title">
                  <el-input placeholder="请输入表单名称"></el-input>
                </div>
                <div class="list-box">
                  <div class="list-box-title">
                    <span>8、 标题</span>
                  </div>
                  <el-radio-group v-model="radio">
                    <el-radio label="1">
                      <span>选项1（分值：1）</span>
                      <div class="icon">
                        <span class="size el-icon-edit"></span>
                        <span class="size el-icon-delete-solid"></span>
                        <span class="icons el-icon-arrow-down"></span>
                        <span class="icons el-icon-arrow-up"></span>
                      </div>
                    </el-radio>
                    <el-radio label="2">
                      <span>选项2（分值：2）</span>
                    </el-radio>
                    <el-radio label="3">
                      <span>选项3（分值：3）</span>
                    </el-radio>
                    <el-radio label="4">
                      <span>选项4（分值：4）</span>
                    </el-radio>
                  </el-radio-group>
                </div>
                <div class="list-content">
                  <div class="list-line">
                    <span class="list-key">标题</span>
                    <el-input placeholder=" 请输入"></el-input>
                  </div>
                  <div class="list-line">
                    <span class="list-key"></span>
                    <el-input type="textarea" placeholder="">
                    </el-input>
                  </div>
                </div>
                <div class="list-select">
                  <div class="list-select-title">
                    <span>选项</span>
                  </div>
                  <el-table :data="tableData" border max-height="140">
                    <el-table-column label="选项文字">
                      <template>
                        <el-input></el-input>
                      </template>
                    </el-table-column>
                    <el-table-column prop="address" label="选项内容">
                    </el-table-column>
                    <el-table-column label="分数">
                      <template>
                        <div class="table-flex">
                          <el-input></el-input>
                          <el-checkbox>不计分</el-checkbox>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column label="操作" width="160">
                      <template>
                        <div class="table-run">
                          <span class="size el-icon-delete-solid"></span>
                          <span class="icons el-icon-arrow-down"></span>
                          <span class="icons el-icon-arrow-up"></span>
                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
                <div class="list-tip">
                  <span>请输入选项内容</span>
                </div>
                <div class="list-btns">
                  <el-button size="small" type="primary">新增选项</el-button>
                  <el-button size="small" type="primary">批量增加</el-button>
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="list">
                <div class="list-title">
                  <el-input placeholder="请输入表单名称"></el-input>
                </div>
                <div class="list-box">
                  <div class="list-box-title">
                    <span>9、 标题</span>
                  </div>
                  <el-radio-group v-model="radio">
                    <el-radio label="1">
                      <span>选项1（分值：1）</span>
                      <div class="icon">
                        <span class="size el-icon-edit"></span>
                        <span class="size el-icon-delete-solid"></span>
                        <span class="icons el-icon-arrow-down"></span>
                        <span class="icons el-icon-arrow-up"></span>
                      </div>
                    </el-radio>
                    <el-radio label="2">
                      <span>选项2（分值：2）</span>
                    </el-radio>
                    <el-radio label="3">
                      <span>选项3（分值：3）</span>
                    </el-radio>
                    <el-radio label="4">
                      <span>选项4（分值：4）</span>
                    </el-radio>
                  </el-radio-group>
                </div>
                <div class="list-content">
                  <div class="list-line">
                    <span class="list-key">标题</span>
                    <el-input placeholder=" 请输入"></el-input>
                  </div>
                  <div class="list-line">
                    <span class="list-key"></span>
                    <el-input type="textarea" placeholder="">
                    </el-input>
                  </div>
                </div>
                <div class="list-select">
                  <div class="list-select-title">
                    <span>选项</span>
                  </div>
                  <el-table :data="tableData" max-height="140">
                    <el-table-column label="选项文字">
                      <template>
                        <el-input></el-input>
                      </template>
                    </el-table-column>
                    <el-table-column prop="address" label="选项内容">
                    </el-table-column>
                    <el-table-column label="分数">
                      <template>
                        <div class="table-flex">
                          <el-input></el-input>
                          <el-checkbox>不计分</el-checkbox>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column label="操作" width="160">
                      <template>
                        <div class="table-run">
                          <span class="size el-icon-delete-solid"></span>
                          <span class="icons el-icon-arrow-down"></span>
                          <span class="icons el-icon-arrow-up"></span>
                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
                <div class="list-tip">
                  <span>请输入选项内容</span>
                </div>
                <div class="list-btns">
                  <el-button size="small" type="primary">新增选项</el-button>
                  <el-button size="small" type="primary">批量增加</el-button>
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="list">
                <div class="list-title">
                  <el-input placeholder="请输入表单名称"></el-input>
                </div>
                <div class="list-content list-border">
                  <div class="list-content-title">
                    <span>10、 标题</span>
                  </div>
                  <div class="list-line">
                    <div class="list-table">
                      <div class="list-table-tr">
                        <span class="list-table-td"></span>
                        <span class="list-table-td">1</span>
                        <span class="list-table-td">2</span>
                        <span class="list-table-td">3</span>
                        <span class="list-table-td">4</span>
                        <span class="list-table-td">5</span>
                      </div>
                      <div class="list-table-tr">
                        <span class="list-table-td">分值</span>
                        <span class="list-table-td">1</span>
                        <span class="list-table-td">2</span>
                        <span class="list-table-td">3</span>
                        <span class="list-table-td">4</span>
                        <span class="list-table-td">5</span>
                      </div>
                      <el-radio-group class="list-table-tr">
                        <span class="list-table-td">外观</span>
                        <el-radio label="1" class="list-table-td"></el-radio>
                        <el-radio label="2" class="list-table-td"> </el-radio>
                        <el-radio label="3" class="list-table-td"> </el-radio>
                        <el-radio label="4" class="list-table-td"> </el-radio>
                        <el-radio label="5" class="list-table-td"> </el-radio>
                      </el-radio-group>
                      <el-radio-group class="list-table-tr">
                        <span class="list-table-td">功能</span>
                        <el-radio label="1" class="list-table-td"></el-radio>
                        <el-radio label="2" class="list-table-td"> </el-radio>
                        <el-radio label="3" class="list-table-td"> </el-radio>
                        <el-radio label="4" class="list-table-td"> </el-radio>
                        <el-radio label="5" class="list-table-td"> </el-radio>
                      </el-radio-group>
                    </div>
                    <div class="list-positon-icon">
                      <span class="size el-icon-edit"></span>
                      <span class="size el-icon-delete-solid"></span>
                      <span class="icons el-icon-arrow-up"></span>
                      <span class="icons el-icon-arrow-down"></span>
                    </div>
                  </div>
                </div>
                <div class="list-content">
                  <div class="list-line">
                    <span class="list-key">标题</span>
                    <el-input placeholder=" 请输入"></el-input>
                  </div>
                  <div class="list-line">
                    <span class="list-key"></span>
                    <el-input type="textarea" placeholder="">
                    </el-input>
                  </div>
                </div>
                <div class="list-select">
                  <div class="list-select-title">
                    <span>选项</span>
                  </div>
                  <el-table :data="tableData" max-height="140">
                    <el-table-column label="选项文字">
                      <template>
                        <el-input></el-input>
                      </template>
                    </el-table-column>
                    <el-table-column prop="address" label="选项内容">
                    </el-table-column>
                    <el-table-column label="分数">
                      <template>
                        <div class="table-flex">
                          <el-input></el-input>
                          <el-checkbox>不计分</el-checkbox>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column label="操作" width="160">
                      <template>
                        <div class="table-run">
                          <span class="size el-icon-delete-solid"></span>
                          <span class="icons el-icon-arrow-down"></span>
                          <span class="icons el-icon-arrow-up"></span>
                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
                <div class="list-tip">
                  <span>请输入选项内容</span>
                </div>
                <div class="list-btns">
                  <el-button size="small" type="primary">新增选项</el-button>
                  <el-button size="small" type="primary">批量增加</el-button>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>
    <div class="add-btns">
      <el-button size="small">取消</el-button>
      <el-button size="small" type="primary">确定</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      selectIndex: 0,
      selects1: Object.freeze(['单选', '多选', '下拉框', '文件上传']),
      selects2: Object.freeze(['单项填空', '多项填空']),
      selects3: Object.freeze(['量表题', '评分单选', '评分多选', '矩阵量表']),
      radio: '',
      tableData: [{
        date: '2016-05-02',
        name: '王小虎',
        address: '等待外出'
      }, {
        date: '2016-05-04',
        name: '王小虎',
        address: '未返回'
      }, {
        date: '2016-05-01',
        name: '王小虎',
        address: '逾期未返回'
      }, {
        date: '2016-05-01',
        name: '王小虎',
        address: '逾期未返回'
      }, {
        date: '2016-05-01',
        name: '王小虎',
        address: '逾期未返回'
      }, {
        date: '2016-05-01',
        name: '王小虎',
        address: '逾期未返回'
      }, {
        date: '2016-05-01',
        name: '王小虎',
        address: '逾期未返回'
      }],
      options: [{
        value: '',
        label: ''
      }],
      value: '',
      imageUrl: ''
    }
  },
  methods: {
    selectClick (index) {
      this.selectIndex = index
      this.$refs.remarkCarusel.setActiveItem(index)
    },
    handleAvatarSuccess (res, file) {
      this.imageUrl = URL.createObjectURL(file.raw)
    },
    beforeAvatarUpload (file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 14

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 14MB!')
      }
      return isJPG && isLt2M
    }
  }
}
</script>

<style lang="less" scoped>
.add {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 88;
  width: 100%;
  height: 100%;
  padding: 0 20px;
  background-color: #fff;
  box-sizing: border-box;

  .add-head {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 60px;
    padding: 0 10px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;
    color: #000;
    font-size: 20px;

    .input {
      width: 246px;
    }
  }

  .add-box {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 130px);
    margin-top: 10px;

    &::-webkit-scrollbar {
      width: 0;
    }

    .add-flex {
      display: flex;
      flex-direction: row;
      height: 100%;

      .add-left {
        width: 256px;
        height: 600px;
        padding: 9px 12px;
        background-color: #0C4969;
        border-radius: 6px;
        box-sizing: border-box;

        .title {
          height: 40px;
          line-height: 40px;
          color: #fff;
          font-size: 18px;
        }

        .select {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          align-content: space-between;
          justify-content: space-between;
          width: 190px;
          height: 80px;
          margin: 10px auto;

          .item {
            width: 80px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            background-color: #707070;
            color: #fff;
            font-size: 16px;
            cursor: pointer;
          }

          .active {
            background-color: #04B4D0;
          }
        }
      }

      .add-right {
        width: calc(100% - 280px);
        height: 100%;
        margin-left: 20px;

        .el-carousel {
          height: 100%;

          /deep/.el-carousel__container {
            height: 100% !important;
          }

          /deep/.el-carousel__indicators {
            display: none;
          }

          .list {
            overflow-y: scroll;
            width: 100%;
            height: 100%;

            &::-webkit-scrollbar {
              width: 0;
            }

            .list-title {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              width: 100%;
              height: 50px;
              border-bottom: 1px solid #000;
              box-sizing: border-box;

              .el-input {
                width: 200px;
              }
            }

            .list-box {
              display: flex;
              flex-direction: column;
              border-bottom: 1px solid #000;
              box-sizing: border-box;

              .list-box-title {
                width: 100%;
                height: 60px;
                line-height: 60px;
                color: #000;
                font-size: 18px;
              }

              .list-upload {
                display: flex;
                flex-direction: row;
                width: 100%;
                margin-bottom: 20px;

                .avatar-uploader {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  justify-content: center;
                  width: 860px;
                  height: 80px;
                  border: 1px dotted #FFFFFF;
                }

                .upload-text {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  justify-content: center;
                  color: #000;
                  font-size: 18px;

                  .upload-icon {
                    margin-right: 12px;
                    color: #000;
                    font-size: 18px;
                  }
                }
              }

              .el-radio-group {
                display: flex;
                flex-direction: column;
              }

              .el-radio {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-bottom: 10px;
              }

              /deep/.el-radio__label {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
              }

              .label {
                display: flex;
                flex-direction: row;
                align-items: center;
                width: calc(100% - 150px);
                color: #000;
                font-size: 18px;

                .el-input {
                  width: 120px;
                  margin: 0 10px;
                }
              }

              .icon {
                .size {
                  color: #000;
                  font-size: 24px;
                }

                span {
                  margin-left: 20px;
                }
              }

              .icons {
                width: 26px;
                height: 26px;
                line-height: 26px;
                border-radius: 50%;
                border: 1px solid #000;
                box-sizing: border-box;
                text-align: center;
                color: #000;
                font-size: 14px;
              }
            }

            .list-box1 {
              border: none;
            }

            .list-content {
              display: flex;
              flex-direction: column;

              .list-content-title {
                height: 50px;
                line-height: 50px;
                color: #000;
                font-size: 18px;

                .size {
                  font-size: 16px;
                }
              }

              .list-line {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-top: 10px;

                .list-table {
                  display: flex;
                  flex-direction: column;
                  width: 800px;
                  border-top: 1px solid #000;
                  border-left: 1px solid #000;
                  box-sizing: border-box;

                  .list-table-tr {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    width: 800px;
                    height: 40px;

                    .list-table-td {
                      flex: 1;
                      height: 40px;
                      line-height: 40px;
                      border-bottom: 1px solid #000;
                      border-right: 1px solid #000;
                      box-sizing: border-box;
                      text-align: center;
                      color: #000;
                      font-size: 16px;
                    }

                    .el-radio {
                      margin-right: 0;
                    }

                    /deep/.el-radio__inner {
                      background-color: transparent;
                    }
                  }
                }

                .list-line-left {
                  display: flex;
                  flex-direction: column;
                  padding-left: 35px;
                  box-sizing: border-box;

                  .list-line-item {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin-bottom: 5px;
                    color: #000;
                    font-size: 18px;

                    /deep/.el-input__inner {
                      width: 206px;
                      border: none;
                      border-radius: 0;
                      background-color: transparent;
                      border-bottom: 1px solid #000;
                    }

                    .icons {
                      display: flex;
                      flex-direction: row;
                      width: 340px;
                      margin-left: 11px;

                      .img {
                        width: 20px;
                        height: 20px;
                        margin-right: 10px;
                      }

                      .label {
                        display: flex;
                        flex-direction: row;
                        width: 216px;
                        height: 24px;
                        border: 1px solid #000;
                        border-right: none;
                        box-sizing: border-box;

                        .item {
                          flex: 1;
                          height: 24px;
                          line-height: 24px;
                          text-align: center;
                          border-right: 1px solid #000;
                          box-sizing: border-box;
                          color: #000;
                          font-size: 16px;
                        }
                      }
                    }
                  }
                }

                .list-positon-icon {
                  position: absolute;
                  right: 10px;

                  .size {
                    margin-left: 20px;
                    color: #000;
                    font-size: 24px;
                  }

                  .icons {
                    width: 26px;
                    height: 26px;
                    line-height: 26px;
                    margin-left: 20px;
                    border-radius: 50%;
                    border: 1px solid #000;
                    box-sizing: border-box;
                    text-align: center;
                    color: #000;
                    font-size: 14px;
                  }
                }

                .list-key {
                  width: 50px;
                  text-align: center;
                  color: #000;
                  font-size: 18px;
                }

                .el-input {
                  width: 254px;
                }

                .el-select {
                  width: 254px;
                }

                /deep/.el-textarea__inner {
                  width: 254px;
                  height: 104px;
                  margin-left: 2px;
                  resize: none;
                }

                .textarea {
                  width: 254px;
                  padding: 10px 8px;
                  box-sizing: border-box;

                  .textarea-line {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    color: #000;
                    font-size: 18px;

                    .span {
                      white-space: nowrap;
                    }

                    /deep/.el-input__inner {
                      width: 165px;
                      border: none;
                      border-radius: 0;
                      background-color: transparent;
                      border-bottom: 1px solid;
                    }
                  }
                }

                .list-icon {
                  display: flex;
                  flex-direction: row;
                  align-items: center;

                  .size {
                    margin-left: 20px;
                    color: #000;
                    font-size: 24px;
                  }

                  .icons {
                    width: 26px;
                    height: 26px;
                    line-height: 26px;
                    margin-left: 20px;
                    border-radius: 50%;
                    border: 1px solid #000;
                    box-sizing: border-box;
                    text-align: center;
                    color: #000;
                    font-size: 14px;
                  }
                }
              }
            }

            .list-select {
              display: flex;
              flex-direction: column;

              .list-select-title {
                display: flex;
                align-items: center;
                width: 50px;
                height: 50px;
                line-height: 50px;
                text-align: center;
                color: #000;
                font-size: 18px;

                .list-select-item {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  color: #000;
                  font-size: 18px;
                  margin-left: 20px;

                  .icons {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin-left: 10px;

                    .color {
                      color: #000;
                      font-size: 18px;
                    }

                    .icon {
                      color: #000;
                      font-size: 18px;
                    }

                    .img {
                      width: 20px;
                      height: 20px;
                    }

                    .label {
                      display: inline-block;
                      width: 38px;
                      height: 24px;
                      line-height: 24px;
                      text-align: center;
                      border: 1px solid #000;
                      box-sizing: border-box;
                      color: #000;
                      font-size: 16px;
                    }
                  }
                }
              }

              .list-select-title1 {
                width: 100%;
              }

              .el-table {
                width: 720px;
                margin-top: 10px;

                .el-input {
                  width: 120px;
                }
              }

              /deep/.el-table .cell {
                text-align: center;
                color: #000;
              }

              .table-run {
                display: flex;
                align-items: center;
                justify-content: center;

                span {
                  cursor: pointer;
                }

                .size {
                  margin-left: 20px;
                  color: #000;
                  font-size: 24px;
                }

                .icons {
                  width: 26px;
                  height: 26px;
                  line-height: 26px;
                  margin-left: 20px;
                  border-radius: 50%;
                  border: 1px solid #000;
                  box-sizing: border-box;
                  text-align: center;
                  color: #000;
                  font-size: 14px;
                }
              }

              .table-flex {
                display: flex;
                align-items: center;

                .el-checkbox {
                  margin-left: 10px;
                }
              }
            }

            .list-border {
              padding-bottom: 10px;
              border-bottom: 1px solid #000;
            }

            .list-tip {
              height: 40px;
              line-height: 40px;
              color: #FD0101;
              font-size: 14px;
            }

            .list-btns {
              display: flex;
              flex-direction: row;
              align-items: center;
            }
          }
        }
      }
    }
  }

  .add-btns {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
  }
}
</style>
