<template>
  <div class="update">
    <div class="update-top">
      <span class="update-text">薪资明细</span>
      <el-input placeholder="姓名/身份证号" suffix-icon="el-icon-search" class="update-input"></el-input>
    </div>
    <div class="update-scroll">
      <div class="scroll-title">
        <span>投诉建议信息</span>
      </div>
      <div class="info">
        <div class="info-box">
          <span class="info-line">老人  李明明</span>
          <span class="info-line">床位  601-1</span>
          <span class="info-line">入驻时间   2020-02-02</span>
          <span class="info-line">结算月份    2020-11</span>
          <span class="info-line">结算天数     30</span>
          <span class="info-line">费用合计      9000.00</span>
        </div>
      </div>
      <el-table :data="tableData" border height="294">
        <el-table-column prop="name" label="收费项目">
        </el-table-column>
        <el-table-column prop="address" label="金额"></el-table-column>
      </el-table>
      <div class="info">
        <div class="info-box">
          <span class="info-line">应收金额：7000.00</span>
          <span class="info-line">实收金额：7000.00</span>
          <span class="info-line">缴费日期：2020-03-03</span>
        </div>
      </div>
    </div>
    <div class="update-btns">
      <el-button size="small" @click="close">返回</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tableData: [{
        date: '2016-05-02',
        name: '床位费',
        address: '3000.00'
      }, {
        date: '2016-05-04',
        name: '服务费',
        address: '3000.00'
      }, {
        date: '2016-05-01',
        name: '伙食费',
        address: '3000.00'
      }, {
        date: '2016-05-01',
        name: '余额抵扣',
        address: '3000.00'
      }, {
        date: '2016-05-01',
        name: '长护险抵扣',
        address: '3000.00'
      }]
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.update {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0 40px;
  background-color: #fff;
  box-sizing: border-box;

  .update-top {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 60px;
    padding: 0 10px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    .update-text {
      color: #000;
      font-size: 22px;
    }

    .update-input {
      width: 246px;
    }
  }

  .update-scroll {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 120px);

    &::-webkit-scrollbar {
      width: 0;
    }

    .scroll-title {
      height: 50px;
      line-height: 50px;
      color: #000;
      font-size: 20px;
    }

    .info {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      padding: 0 30px;
      box-sizing: border-box;

      .info-box {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        width: 50%;

        .info-line {
          margin-top: 16px;
          color: #000;
          font-size: 16px;
        }
      }

      .info-btns {
        display: flex;
        flex-direction: row;
        margin-top: 20px;
      }
    }

    .el-table {
      width: 500px;
      margin-top: 10px;
    }

    .input {
      width: 180px;
    }

    /deep/.el-table .cell {
      text-align: center;
      color: #000;
    }
  }

  .update-btns {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 50px;
    padding-left: 30px;
    box-sizing: border-box;
  }
}
</style>
