<template>
  <div class="son">
    <div class="son-title">
      <div class="title">
        <span>病案配送通知</span>
      </div>
      <!-- <div class="btns">
        <el-button>导入</el-button>
        <el-button>导出</el-button>
      </div> -->
    </div>
    <div class="son-line">
      <span>内容*：</span>
      <el-input type="textarea" placeholder="请输入">
      </el-input>
    </div>
    <div class="son-btns">
      <el-button>保存</el-button>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {
      options: [{
        value: '',
        label: ''
      }],
      value: '',
      tableData: [{
        date: '2016-05-03',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-02',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-04',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-01',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-08',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-06',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-07',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-07',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-07',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-07',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-07',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }],
      total: 400,
      currentPage: 0,
      showUpdate: false,
      showDelete: false
    }
  },
  methods: {
    openUpdate () {
      this.showUpdate = true
    },
    closeUpdate () {
      this.showUpdate = false
    },
    openDelete () {
      this.showDelete = true
    },
    closeDelete () {
      this.showDelete = false
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
    }
  }
}
</script>

<style lang="less" scoped>
.son {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #081C38;

  .son-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 78px;
    padding-right: 54px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;

    .title {
      height: 38px;
      line-height: 38px;
      padding: 0 9px;
      text-align: center;
      background-color: #01EEFD;
      color: #fff;
      font-size: 22px;
    }

    .btns {
      display: flex;
      flex-direction: row;

      .el-button {
        width: 90px;
        margin-left: 20px;
        background-color: #04B4D0;
        border: none;
        color: #fff;
      }
    }
  }

  .son-line {
    display: flex;
    flex-direction: row;
    margin-top: 21px;
    color: #01EEFD;
    font-size: 18px;

    span {
      white-space: nowrap;
    }

    /deep/.el-textarea__inner {
      height: 604px;
      resize: none;
    }
  }

  .son-btns {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100px;

    .el-button {
      margin: 0;
      background: linear-gradient(108deg, #0174C9 0%, #024176 100%);
      color: #fff;
      border: none;
    }
  }
}
</style>
