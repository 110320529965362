<template>
  <div class="son">
    <div class="son-head">
      <div class="son-head-item">
        <div class="son-head-left">
          <div class="son-head-open"></div>
          <el-input placeholder="请输入搜索内容"></el-input>
        </div>
        <div class="son-head-center">
          <span>切换机构：</span>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="son-head-right">
          <span class="el-icon-success success"></span>
          <div class="div">
            <div class="div1">
              <div class="blue"></div>
            </div>
          </div>
          <span class="el-icon-lock lock"></span>
          <span class="el-icon-view lock"></span>
          <span class="el-icon-rank rank"></span>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <!-- <div class="son-head-item">
        <div class="son-head-title">
          <span>预约管理</span>
        </div>
        <el-button type="primary">
          <span>更多</span>
          <span class="el-icon-arrow-down icon"></span>
        </el-button>
      </div> -->
    </div>
    <div class="son-scroll">
      <div class="son-search">
        <div class="key">
          <span>机构</span>
        </div>
        <el-select v-model="value" placeholder="请选择">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-button>
          <span class="el-icon-search" @click="getList"></span>
          <span>查询</span>
        </el-button>
        <el-button>
          <span class="el-icon-refresh"></span>
          <span>重置</span>
        </el-button>
      </div>
      <div class="son-info">
        <div class="son-info-title">
          <div class="info-left">
            <span>套餐</span>
            <el-button size="mini" @click="openAdd">添加</el-button>
          </div>
          <div class="info-right">操作</div>
        </div>
        <div class="son-info-scroll">
          <div v-for="(item, index) in list1" :key="index" class="scroll-list">
            <div class="left">{{ item.tjtcmc || '-' }}</div>
            <div class="right" @click="openSet(item)">
              <span>设置</span>
            </div>
          </div>
        </div>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
          background :page-size="query1.pageSize" layout="total, prev, pager, next, jumper" :total="query1.total">
        </el-pagination>
      </div>
    </div>
    <Set v-if="showSet" @close="closeSet"></Set>
    <Add v-if="showAdd" @close="closeAdd"></Add>
  </div>
</template>

<script>
import Set from './set.vue'
import Add from './add.vue'
export default {
  components: {
    Set,
    Add
  },
  data () {
    return {
      options: [{
        value: '',
        label: ''
      }],
      value: '',
      currentPage: 0,
      showSet: false,
      selectTitle: '',
      showAdd: false,
      showUpdate: false,
      list1: [],
      params: {
        // 分页起始
        start: '0',
        // 分页长度
        length: '10',
        ssjgbh: '',
        name: ''
      },
      query1: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      }
    }
  },
  created () {
    this.params.ssjgbh = this.$store.state.loginInfo.value || ''
    this.getList()
  },
  methods: {
    async getList () {
      try {
        this.params.start = this.query1.pageNum === 1 ? '0' : (this.query1.pageNum - 1) * 10 + ''
        this.params.length = this.query1.pageSize + ''
        const { data: res } = await this.$http.get('/zhct/getzhctyylb', { params: this.params })
        console.log('预约列表', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.list1 = res.data
        this.query1.total = res.recordsTotal || 0
      } catch (error) {
        console.log('预约列表请求失败', error)
        // this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    tabClick (index) {
      this.tabIndex = index
    },
    openSet (info) {
      this.selectTitle = info
      this.showSet = true
    },
    openUpdate (info) {
      this.selectTitle = info
      this.showUpdate = true
    },
    closeUpdate () {
      this.showUpdate = false
    },
    closeSet () {
      this.showSet = false
    },
    openAdd () {
      this.showAdd = true
    },
    closeAdd () {
      this.showAdd = false
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
    }
  }
}
</script>

<style lang="less" scoped>
.son {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #F0F0F0;

  .son-head {
    display: flex;
    align-items: center;
    width: 100%;
    height: 70px;
    margin-bottom: 20px;
    background-color: #fff;
    box-sizing: border-box;

    .son-head-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 0 14px;
      box-sizing: border-box;

      .son-head-left {
        display: flex;
        align-items: center;
        width: 33%;

        .son-head-open {
          width: 23px;
          height: 6px;
          border-top: 2px solid #595959;
          border-bottom: 2px solid #595959;
        }

        .el-input {
          margin-left: 50px;
        }

        /deep/.el-input__inner {
          border-color: #fff;
          font-size: 18px;
        }
      }

      .son-head-center {
        display: flex;
        align-items: center;
        flex: 1;
        color: #595959;
        font-size: 20px;

        /deep/.el-input__inner {
          font-size: 18px;
        }
      }

      .son-head-right {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex: 1;

        .success {
          color: #67C23A;
          font-size: 39px;
        }

        .lock {
          color: #595959;
          font-size: 30px;
        }

        .rank {
          font-size: 34px;
          transform: rotate(45deg);
        }

        .div {
          width: 41px;
          height: 40px;
          padding: 6px 7px;
          border: 1px solid #E6E6E6;
          border-radius: 6px;
          box-sizing: border-box;

          .div1 {
            width: 100%;
            height: 100%;
            padding-top: 3px;
            border: 1px solid #999999;
            box-sizing: border-box;

            .blue {
              width: 100%;
              height: 100%;
              background-color: #409EFF;
            }
          }
        }

        /deep/.el-input__inner {
          text-align: right;
          border: none;
          font-size: 18px;
        }
      }

      .son-head-title {
        width: 117px;
        height: 38px;
        line-height: 38px;
        text-align: center;
        border: 1px solid #409EFF;
        border-radius: 2px;
        box-sizing: border-box;
        color: #409EFF;
        font-size: 16px;
      }

      .el-button {
        width: 100px;

        .icon {
          margin-left: 10px;
        }
      }
    }
  }

  .son-scroll {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 90px);
    padding: 0 20px;
    box-sizing: border-box;

    &::-webkit-scrollbar {
      width: 0;
    }

    .son-search {
      display: flex;
      align-items: center;
      width: 100%;
      height: 130px;
      padding-left: 23px;
      background-color: #fff;
      border-radius: 18px;
      box-sizing: border-box;

      .key {
        margin-right: 20px;
        color: #17212B;
        font-size: 22px;
      }

      .el-button {
        margin-left: 42px;
        background-color: #3FA1FF;
        color: #fff;
        font-size: 30px;
      }
    }

    .son-info {
      width: 100%;
      height: calc(100% - 150px);
      margin-top: 20px;
      padding: 0 30px;
      background-color: #fff;
      border-radius: 18px;
      box-sizing: border-box;

      .son-info-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 58px;
        border-bottom: 1px solid #333333;
        box-sizing: border-box;

        .info-left {
          display: flex;
          align-items: center;
          height: 100%;
          color: #17212B;
          font-size: 20px;

          .el-button {
            margin-left: 20px;
            background-color: #3FA1FF;
            border: none;
            color: #fff;
          }
        }

        .info-right {
          width: 70px;
          height: 58px;
          line-height: 58px;
          text-align: center;
          color: #17212B;
          font-size: 20px;
        }
      }

      .son-info-scroll {
        overflow-y: scroll;
        width: 100%;
        height: calc(100% - 150px);
        margin: 13px 0;

        &::-webkit-scrollbar {
          width: 0;
        }

        .scroll-list {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          height: 40px;
          margin-bottom: 10px;
          padding-left: 10px;
          background-color: #C9D8E7;
          box-sizing: border-box;

          .right {
            width: 70px;
            height: 58px;
            line-height: 58px;
            text-align: center;
            color: #464646;
            font-size: 16px;
            cursor: pointer;
          }
        }
      }

      // 页码样式修改
      .el-pagination {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        /deep/.el-pagination__total,
        /deep/.el-pagination__jump {
          color: #000000;

          .el-input__inner {
            color: #fff;
            background-color: transparent;
          }
        }

        /deep/.el-pager li {
          background-color: #ADADAD;
          color: #fff;
        }

        /deep/.el-pager li:not(.disabled).active {
          background-color: #0077F9;
        }
      }
    }
  }
}
</style>
