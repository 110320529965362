<template>
  <div class="calendar">
    <div class="calendar-head">
      <div class="calendar-head-left">
        <span>{{ date }}</span>
      </div>
      <div class="calendar-head-right">
        <div @click="up" class="calendar-head-button">
          <span>上个月</span>
        </div>
        <div @click="initDate" class="calendar-head-button">
          <span>今天</span>
        </div>
        <div @click="down" class="calendar-head-button">
          <span>下个月</span>
        </div>
      </div>
    </div>
    <div class="calendar-week">
      <div class="calendar-week-item">
        <span>一</span>
      </div>
      <div class="calendar-week-item">
        <span>二</span>
      </div>
      <div class="calendar-week-item">
        <span>三</span>
      </div>
      <div class="calendar-week-item">
        <span>四</span>
      </div>
      <div class="calendar-week-item">
        <span>五</span>
      </div>
      <div class="calendar-week-item">
        <span>六</span>
      </div>
      <div class="calendar-week-item">
        <span>日</span>
      </div>
    </div>
    <div class="calendar-date">
      <div v-for="(item, index) in showDate" :key="index" :class="{ 'border-none': item === '', 'calendar-date-active': selectDate.indexOf(item.day) > -1 }" @click="select(item.day)" class="calendar-date-item">
        <span>{{ item.day | dateType }}</span>
        <span v-if="item.day" class="span">总号数: {{ item.total || '-' }} </span>
        <span v-if="item.day" class="span">已预约: {{ item.used || '-' }}  剩余：{{ item.remaining || '-' }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      year: '',
      month: '',
      year1: '',
      month1: '',
      showDate: [],
      selectDate: [],
      sources: []
    }
  },
  created () {
    this.initDate()
  },
  computed: {
    date () {
      return this.year + '年' + this.month + '月'
    }
  },
  filters: {
    dateType (value) {
      if (!value) return ''
      const arr = value.split('-')
      return arr[1] + '-' + arr[2]
    }
  },
  methods: {
    initDate () {
      const date = new Date()
      const year = date.getFullYear()
      this.year = year
      const month = date.getMonth() + 1
      this.month = month
      this.getDaysInMonth(year, month)
    },
    getDaysInMonth (year, month) {
      this.showDate = []
      const day = new Date(year, month, 0).getDate()
      month = month > 9 ? month + '' : '0' + month
      this.year1 = year
      this.month1 = month
      let week = this.getWeek(year + '-' + month + '-' + '01')
      if (week === 0) {
        week = 7
      }
      for (let index = 0; index < week - 1; index++) {
        const obj = { day: '', total: '', used: '', remaining: '' }
        this.showDate.push(JSON.parse(JSON.stringify(obj)))
      }
      for (let index = 1; index < day + 1; index++) {
        index = index > 9 ? index + '' : '0' + index
        const val = year + '-' + month + '-' + index
        const obj = { day: val, total: '', used: '', remaining: '' }
        this.showDate.push(JSON.parse(JSON.stringify(obj)))
      }
      this.getInfo()
    },
    getWeek (val) {
      const date = new Date(val)
      return date.getDay()
    },
    async getInfo () {
      if (this.sources.length > 0) {
        for (let index = 0; index < this.sources.length; index++) {
          this.sources[index]('请求被手动取消')
        }
        this.sources = []
      }
      console.log(this.sources)
      try {
        // 接口地址 https://app.apifox.com/project/2726972/apis/api-210326799
        const { data: res } = await this.$http.post('/appRegsterMarks/getyyrqxx', { hostcode: 'wx470b939679e30d36', ny: this.year1 + '-' + this.month1 }, {
          cancelToken: new this.$http.CancelToken((c) => {
            this.sources.push(c)
          })
        })
        console.log('获取预约日期信息-总号源', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        const list = res.data
        for (let i = 0; i < this.showDate.length; i++) {
          for (let j = 0; j < list.length; j++) {
            if (this.showDate[i].day === list[j].day) {
              this.showDate[i] = list[j]
            }
          }
        }
        this.showDate = JSON.parse(JSON.stringify(this.showDate))
      } catch (error) {
        console.log('获取预约日期信息-总号源请求失败', error)
      }
    },
    up () {
      if (this.month === 1) {
        this.month = 13
        this.year--
      }
      this.month--
      this.getDaysInMonth(this.year, this.month)
    },
    down () {
      if (this.month === 12) {
        this.month = 0
        this.year++
      }
      this.month++
      this.getDaysInMonth(this.year, this.month)
    },
    select (val) {
      console.log(val)
      if (this.selectDate.findIndex(item => item === val) === -1) {
        this.selectDate.push(val)
      } else {
        this.selectDate = this.selectDate.filter(item => item !== val)
      }
      console.log('所选时间：', this.selectDate)
      this.$parent.selectDate = this.selectDate.join(',')
      console.log('所选时间：', this.$parent.selectDate)
    }
  }
}
</script>

<style lang="less" scoped>
  .calendar {
    display: flex;
    flex-direction: column;
    width: 100%;

    .calendar-head {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 50px;

      .calendar-head-left {
        font-size: 20px;
      }

      .calendar-head-right {
        display: flex;
        flex-direction: row;
        align-items: center;
        border-radius: 5px;
        border: 1px solid #dcdfe6;

        .calendar-head-button {
          padding: 5px 10px;
          font-size: 16px;
          cursor: pointer;
          user-select: none;
        }

        .calendar-head-button:nth-child(2) {
          border-left: 1px solid #dcdfe6;
          border-right: 1px solid #dcdfe6;
        }
      }
    }

    .calendar-week {
      display: flex;
      align-items: center;
      width: 100%;
      height: 50px;

      .calendar-week-item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: calc(100% / 7);
        height: 50px;
        font-size: 20px;
      }
    }

    .calendar-date {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      border: 1px solid #dcdfe6;
      box-sizing: border-box;

      .calendar-date-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: calc(100% / 7);
        height: 80px;
        border: 1px solid #dcdfe6;
        box-sizing: border-box;
        font-size: 16px;
        cursor: pointer;
        user-select: none;

        .span {
          font-size: 13px;
        }
      }

      .border-none {
        border: none;
      }

      .calendar-date-active {
        background-color: #f2f8fe;
        color: #66b1ff;
        font-weight: bold;
        font-size: 20px;
      }
    }
  }
</style>
