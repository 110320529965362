<!--
 * @Author: 小宝 zksukuo@yunkangda.com
 * @Date: 2024-03-18 11:16:04
 * @LastEditors: 小宝 zksukuo@yunkangda.com
 * @LastEditTime: 2024-03-18 14:08:18
 * @FilePath: \visions\src\views\zhyl\smartPhysicalExamination\systemConfig\testProjectRun\projectType\update.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="update">
    <div class="update-box">
      <div class="update-box-head">
        <span>编辑：核查(exxxxxxxxxxxxxxxxxxxx)</span>
      </div>
      <div class="update-box-top">
        <div class="update-item">
          <div class="update-key">
            <span>*名称：</span>
          </div>
          <el-input placeholder=""></el-input>
        </div>
        <div class="update-item">
          <div class="update-key">
            <span>*编码：</span>
          </div>
          <el-input placeholder=""></el-input>
        </div>
        <div class="update-item">
          <div class="update-key">
            <span>*所属机构：</span>
          </div>
          <el-select v-model="value" placeholder="">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="update-item">
          <div class="update-key">
            <span>*状态：</span>
          </div>
          <el-select v-model="value" placeholder="">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="update-item">
          <div class="update-key">
            <span>备注：</span>
          </div>
          <el-input type="textarea" placeholder=""></el-input>
        </div>
      </div>
      <div class="update-box-bottom">
        <el-button @click="close">返回</el-button>
        <el-button type="warning">重置</el-button>
        <el-button type="primary">保存</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      input: '',
      options: [],
      value: '',
      flan: false,
      textarea: '',
      num: 1
    }
  },
  methods: {
    close () {
      this.$parent.showUpdate = false
    }
  }
}
</script>

<style lang="less" scoped>
.update {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 888;
  background-color: rgba(0, 0, 0, .8);

  .update-box {
    overflow: hidden;
    width: 40%;
    height: 50%;
    border-radius: 10px;
    background-color: #fff;

    .update-box-head {
      width: 100%;
      height: 50px;
      line-height: 50px;
      text-align: center;
      background-color: #66b1ff;
      color: #fff;
      font-size: 20px;
    }

    .update-box-top {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      height: calc(100% - 120px);
      padding: 20px 30px;
      box-sizing: border-box;

      .update-item {
        display: flex;
        align-items: center;
        width: 100%;

        .update-key {
          white-space: nowrap;
          color: #000;
          font-size: 16px;
        }

        .el-input {
          width: 400px;
        }

        /deep/.el-textarea__inner {
          width: 300px;
          resize: none;
        }
      }

      .update-item:last-child {
        width: 100%;
      }
    }

    .update-box-bottom {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 70px;
    }
  }
}
</style>
