<template>
  <div class="popup">
    <div class="popup-content">
      <div class="popup-title">
        <span>新增出院申请</span>
      </div>
      <div class="popup-box">
        <div class="content">
          <div class="close" @click="close">
            <i class="el-icon-close"></i>
          </div>
          <div class="info">
            <div class="info-line">
              <span class="span">老人</span>
              <div @click="openSelect" class="input">
                <span class="el-icon-arrow-down"></span>
              </div>
            </div>
            <div class="info-line">
              <span class="span">出院原因</span>
              <el-input type="textarea" placeholder="">
              </el-input>
            </div>
            <div class="info-line">
              <span class="span">预计时间</span>
              <el-date-picker type="date" placeholder="选择日期">
              </el-date-picker>
            </div>
          </div>
          <div class="btn">
            <el-button size="small" @click="close">取消</el-button>
            <el-button size="small" @click="submit">确定</el-button>
          </div>
        </div>
      </div>
    </div>
    <Select v-if="showSelect" @close="closeSelect"></Select>
  </div>
</template>

<script>
import Select from './select.vue'
export default {
  components: {
    Select
  },
  data () {
    return {
      tableData: [{
        date: '2016-05-02',
        name: '王小虎',
        address: '等待外出'
      }, {
        date: '2016-05-04',
        name: '王小虎',
        address: '未返回'
      }, {
        date: '2016-05-01',
        name: '王小虎',
        address: '逾期未返回'
      }],
      input: '',
      options: [{
        value: '',
        label: ''
      }],
      value: '',
      showSelect: false
    }
  },
  methods: {
    openSelect () {
      this.showSelect = true
    },
    closeSelect () {
      this.showSelect = false
    },
    close () {
      this.$emit('close')
    },
    submit () {
      this.$emit('close', this.input)
    }
  }
}
</script>

<style scoped lang="less">
.popup {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 88;
  background-color: rgba(0, 0, 0, .74);

  .popup-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    height: 600px;

    .popup-title {
      width: 240px;
      height: 50px;
      line-height: 60px;
      background-image: url('../../../../img/dialogTitleBg.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      text-align: center;
      color: #fff;
      font-size: 20px;
    }

    .popup-box {
      width: 600px;
      height: 400px;
      padding: 10px;
      background-color: rgba(3, 27, 44, .8);
      border-radius: 10px;
      border: 4px solid rgba(1, 49, 88, .5);
      box-sizing: border-box;

      .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        width: 100%;
        height: 100%;
        padding: 30px;
        border-radius: 10px;
        border: 4px solid rgba(1, 49, 88, .5);
        box-sizing: border-box;

        .close {
          display: flex;
          align-items: flex-end;
          position: absolute;
          top: 0;
          right: 0;
          width: 50px;
          height: 50px;
          background: linear-gradient(26.565deg, #2057a7 50%, transparent 0);
          transform: rotate(180deg);
          color: #fff;
          font-size: 24px;
          cursor: pointer;
        }

        .info {
          display: flex;
          flex-direction: column;
          width: 100%;

          .info-line {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            margin-bottom: 20px;
            color: #01EEFD;
            font-size: 18px;

            .input {
              display: flex;
              flex-direction: row-reverse;
              align-items: center;
              width: 246px;
              height: 38px;
              margin-left: 28px;
              padding: 0 5px;
              background-color: #fff;
              box-sizing: border-box;
              color: #A1A6BB;
              font-size: 14px;
            }

            .span {
              width: 90px;
            }

            .el-select {
              width: 246px;
              margin-left: 28px;
            }

            .el-input {
              width: 246px;
              margin-left: 28px;
            }

            .el-textarea {
              width: 246px;
              margin-left: 28px;
            }

            /deep/.el-textarea__inner {
              width: 246px;
              height: 104px;
              resize: none;
            }
          }

          .info-line:nth-child(5) {
            align-items: initial;
          }
        }

        .btn {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 240px;
          margin: 0 auto;
        }

        .el-button {
          border-radius: 0;
          border: none;
          background-color: #33465C;
          color: #fff;
        }

        .el-button:nth-child(2) {
          background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
        }
      }
    }
  }
}
</style>
