var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info"},[_c('div',{staticClass:"info-title"},[_c('span',[_vm._v("老人信息")]),_c('el-input',{staticClass:"input",attrs:{"placeholder":"姓名/身份证号","suffix-icon":"el-icon-search"}})],1),_c('div',{staticClass:"info-scroll"},[_c('div',{staticClass:"info-box"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('el-timeline',[_c('el-timeline-item',{attrs:{"timestamp":"李四 审批拒绝了张三提交的床位更换申请\n备注：不符合更换要求！"}},[_vm._v(" 10-20 15：00 ")]),_c('el-timeline-item',{attrs:{"timestamp":"李四 审批拒绝了张三提交的床位更换申请\n备注：不符合更换要求！"}},[_vm._v(" 10-20 15：00 ")])],1)],1)]),_c('div',{staticClass:"info-btns"},[_c('el-button',{attrs:{"size":"small","type":"primary"},on:{"click":_vm.openApproved}},[_vm._v("审批通过")]),_c('el-button',{attrs:{"size":"small","type":"primary"},on:{"click":_vm.openApprovalRejection}},[_vm._v("审批拒绝")]),_c('el-button',{staticClass:"gray",attrs:{"size":"small"},on:{"click":_vm.close}},[_vm._v("返回")])],1),(_vm.showApproved)?_c('Approved',{on:{"close":_vm.closeApproved}}):_vm._e(),(_vm.showApprovalRejection)?_c('ApprovalRejection',{on:{"close":_vm.closeApprovalRejection}}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info-line"},[_c('span',[_vm._v("老人信息")]),_c('div',{staticClass:"info-border"},[_c('span',{staticClass:"info-item"},[_vm._v("头像：")]),_c('span',{staticClass:"info-item info-item1"},[_vm._v("身份证号： 130730199999885848")]),_c('span',{staticClass:"info-item info-item2"},[_vm._v("房间号：1楼501室")]),_c('span',{staticClass:"info-item"},[_vm._v("姓名：张萌萌")]),_c('span',{staticClass:"info-item info-item1"},[_vm._v("联系电话：15803339264")]),_c('span',{staticClass:"info-item info-item2"},[_vm._v("床位号：501-1")]),_c('span',{staticClass:"info-item"},[_vm._v("性别：女")]),_c('span',{staticClass:"info-item info-item3"},[_vm._v("家庭住址：上海市普陀区1000号")])]),_c('div',{staticClass:"info-status"},[_c('span',[_vm._v("待审批")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info-tip"},[_c('span',[_vm._v("* 更换床位为：605-02")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info-tip"},[_c('span',[_vm._v("* 更换原因：室友睡觉打呼噜，严重影响睡眠质量，需要更换房间！")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info-title"},[_c('span',[_vm._v("审批流")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info-user"},[_c('div',{staticClass:"info-user-item"},[_c('img',{staticClass:"img",attrs:{"src":require("../../../img/emr/user.png")}}),_c('span',{staticClass:"text"},[_vm._v("李明明")])]),_c('span',{staticClass:"icon el-icon-arrow-right"}),_c('div',{staticClass:"info-user-item"},[_c('img',{staticClass:"img",attrs:{"src":require("../../../img/emr/user.png")}}),_c('span',{staticClass:"text"},[_vm._v("李明明")])]),_c('span',{staticClass:"icon el-icon-arrow-right"}),_c('div',{staticClass:"info-user-item"},[_c('img',{staticClass:"img",attrs:{"src":require("../../../img/emr/user.png")}}),_c('span',{staticClass:"text"},[_vm._v("李明明")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info-title"},[_c('span',[_vm._v("日志")])])
}]

export { render, staticRenderFns }