<template>
  <div class="info">
    <div class="info-title">
      <span>老人信息</span>
      <el-input placeholder="姓名/身份证号" suffix-icon="el-icon-search" class="input"></el-input>
    </div>
    <div class="info-scroll">
      <div class="info-box">
        <div class="info-line">
          <span>老人信息</span>
        </div>
        <div class="info-tip">
          <span>标题 食堂没有饭餐</span>
        </div>
        <div class="info-tip">
          <span>状态 待处理</span>
        </div>
        <div class="info-tip">
          <span>投诉反馈渠道 电话</span>
        </div>
        <div class="info-tip">
          <span>投诉人 张三丰</span>
        </div>
        <div class="info-tip">
          <span>投诉建议内容 如何更好的方式区解决</span>
        </div>
        <div class="info-title">
          <span>日志</span>
        </div>
        <div class="info-content">
          <div class="info-content-span">
            <span>合计：</span>
          </div>
          <el-timeline>
            <el-timeline-item timestamp="李四 审批拒绝了张三提交的床位更换申请
备注：不符合更换要求！">
              10-20 15：00
            </el-timeline-item>
            <el-timeline-item timestamp="李四 审批拒绝了张三提交的床位更换申请
备注：不符合更换要求！">
              10-20 15：00
            </el-timeline-item>
          </el-timeline>
        </div>

      </div>
    </div>
    <div class="info-btns">
      <el-button size="small" class="gray" @click="close">返回</el-button>
      <el-button size="small" @click="close">确定</el-button>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {
      showApproved: false,
      showApprovalRejection: false
    }
  },
  methods: {
    openApproved () {
      this.showApproved = true
    },
    closeApproved () {
      this.showApproved = false
    },
    openApprovalRejection () {
      this.showApprovalRejection = true
    },
    closeApprovalRejection () {
      this.showApprovalRejection = false
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.info {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 888;
  width: 100%;
  height: 100%;
  padding: 0 20px;
  background-color: #081C38;
  box-sizing: border-box;

  .info-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    padding: 0 10px;
    border-bottom: 1px solid #01EEFD;
    color: #01EEFD;
    font-size: 20px;
    box-sizing: border-box;

    .input {
      width: 246px;

      /deep/.el-input__inner {
        border: none;
        background: linear-gradient(153deg, #0A477B 0%, #0E67AD 100%);
        color: #fff;
        font-size: 18px;
      }

      /deep/.el-input__inner::-webkit-input-placeholder {
        color: #fff;
      }

      /deep/.el-input__suffix {
        color: #fff;
        font-size: 18px;
      }
    }
  }

  .info-content {
    display: flex;
    flex-direction: row;
    align-items: center;

    .info-content-span {
      margin-left: 18px;
      color: #01EEFD;
      font-size: 20px;
    }
  }

  .info-scroll {
    width: 100%;
    height: calc(100% - 140px);
    margin-top: 10px;

    .info-box {
      display: flex;
      flex-direction: column;

      .info-line {
        position: relative;
        display: flex;
        flex-direction: row;
        width: 100%;
        color: #01EEFD;
        font-size: 20px;

        .info-border {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-around;
          align-content: space-around;
          width: 776px;
          height: 133px;
          margin-left: 3px;
          border: 1px solid #01EEFD;
          box-sizing: border-box;

          .info-item {
            width: 140px;
            color: #01EEFD;
            font-size: 16px;
          }

          .info-item1 {
            width: 300px;
          }

          .info-item2 {
            width: 200px;
          }

          .info-item3 {
            width: 545px;
          }
        }

        .info-status {
          position: absolute;
          top: 10px;
          right: 114px;
          color: #FD0101;
          font-size: 20px;
          font-weight: 600;
        }
      }

      .info-tip {
        margin-top: 16px;
        padding-left: 36px;
        box-sizing: border-box;
        color: #01EEFD;
        font-size: 16px;
      }

      .info-user {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 90px;
        padding-left: 13px;
        box-sizing: border-box;

        .info-user-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          height: 60px;

          .img {
            width: 28px;
            height: 28px;
          }

          .text {
            color: #01EEFD;
            font-size: 16px;
          }
        }

        .icon {
          margin: 0 24px;
          color: #01EEFD;
          font-size: 20px;
        }
      }

      .el-timeline {
        margin-top: 20px;
        margin-left: 71px;
      }

      /deep/.el-timeline-item__content {
        color: #01EEFD;
        font-size: 20px;
      }

      /deep/.el-timeline-item__timestamp {
        width: 500px;
        color: #01EEFD;
        font-size: 20px;
      }

      /deep/.el-timeline-item__node {
        width: 28px;
        height: 28px;
        left: -9px;
        background-image: url('../../../img/emr/user.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }

      /deep/.el-timeline-item__tail {
        border-left: 1px dashed #01EEFD;
      }
    }
  }

  .info-btns {
    position: absolute;
    left: 70px;
    bottom: 28px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .el-button {
      margin-right: 30px;
      background-color: #024A83;
      border: 0;
      color: #fff;
    }

    .gray {
      background-color: #33465C;
    }
  }
}
</style>
