<!--
 * @Author: 小宝 zksukuo@yunkangda.com
 * @Date: 2024-01-06 09:41:36
 * @LastEditors: 小宝 zksukuo@yunkangda.com
 * @LastEditTime: 2024-04-24 09:56:01
 * @FilePath: \visions\src\views\zhyl\smartPublicGuard\systemRun\organUserRun\tab1\update.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="update">
    <el-form class="update-box" v-loading="loading" element-loading-text="上传图片中"
      element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
      <div class="update-head">
        <span>编辑/新增</span>
        <span class="el-icon-close update-close" @click="close"></span>
      </div>
      <div class="update-scroll">
        <div class="update-flex">
          <div class="update-item">
            <div class="update-key">
              <span>用户名</span>
            </div>
            <el-input placeholder="请输入" v-model="params.username"></el-input>
          </div>
          <div class="update-item">
            <div class="update-key">
              <span>密码</span>
            </div>
            <el-input placeholder="密码" v-model="params.password" show-password></el-input>
          </div>
          <div class="update-item">
            <div class="update-key">
              <span>姓名</span>
            </div>
            <el-input placeholder="请输入" v-model="params.nickname"></el-input>
          </div>
          <div class="update-item">
            <div class="update-key">
              <span>身份证号</span>
            </div>
            <el-input placeholder="请输入" v-model="params.sfzh"></el-input>
          </div>
          <div class="update-item">
            <div class="update-key">
              <span>手机号码</span>
            </div>
            <el-input placeholder="请输入" v-model="params.phone"></el-input>
          </div>
          <div class="update-item">
            <div class="update-key">
              <span>平台密码</span>
            </div>
            <el-input placeholder="请输入" v-model="params.ptcode" show-password></el-input>
          </div>
          <div class="update-item">
            <div class="update-key">
              <span>机构级别</span>
            </div>
            <el-input placeholder="请输入" v-model="params.jgjb"></el-input>
          </div>
          <div class="update-item">
            <div class="update-key">
              <span>机构编号</span>
            </div>
            <el-input placeholder="请输入" v-model="params.jgbh"></el-input>
          </div>
          <div class="update-item">
            <div class="update-key">
              <span>机构名称</span>
            </div>
            <el-input placeholder="请输入" v-model="params.cwhmc"></el-input>
          </div>
          <div class="update-item">
            <div class="update-key">
              <span>下属机构</span>
            </div>
            <el-select v-model="params.townids" placeholder="">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="update-item">
            <div class="update-key">
              <span>所属分类</span>
            </div>
            <el-input placeholder="请输入"></el-input>
          </div>
          <div class="update-item">
            <div class="update-key">
              <span>所属职责</span>
            </div>
            <el-input placeholder="请输入"></el-input>
          </div>
          <div class="update-item">
            <div class="update-key">
              <span>总检审核</span>
            </div>
            <el-input placeholder="请输入"></el-input>
          </div>
          <div class="update-item">
            <div class="update-key">
              <span>状态</span>
            </div>
            <el-radio-group v-model="params.status">
              <el-radio :label="0">关闭</el-radio>
              <el-radio :label="1">启动</el-radio>
            </el-radio-group>
          </div>
          <div class="update-item">
            <div class="update-key">
              <span>签名地址</span>
            </div>
            <el-input placeholder="请输入" v-model="params.headImgUrl"></el-input>
          </div>
          <div class="update-item">
            <div class="update-key">
              <span>责任医生</span>
            </div>
            <el-select v-model="params.lrr" placeholder="">
              <el-option v-for="item in doctors" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="update-sign">
            <div class="update-sign-left">
              <div class="update-sign-button">
                <el-button size="mini" class="color1" @click="reset">签名重写</el-button>
                <el-button size="mini" class="color2" @click="saveSignature">生成签名</el-button>
                <el-button size="mini" class="color3">保存</el-button>
              </div>
              <canvas v-if="!params.headImgUrl" class="update-sign-box" id="canvas" width="289" height="88" @mousedown="startDrawing"
                @touchstart="startDrawing" @mouseup="stopDrawing" @touchend="stopDrawing" @mousemove="draw"
                @touchmove="draw" @mouseleave="stopDrawing">
              </canvas>
              <img v-if="params.ysqm" :src="params.ysqm" class="update-sign-img">
              <img v-if="params.headImgUrl" :src="params.headImgUrl" class="update-sign-img">
            </div>
            <div class="update-sign-right">
              <div class="update-sign-img" v-if="!imageUrl"></div>
              <img v-else :src="imageUrl" class="update-sign-img">
              <input type="file" style="display: none" ref="fileInput" @change="onSelectImage" />
              <el-button size="mini" class="color1" @click="update">上传签名</el-button>
            </div>
          </div>
        </div>
      </div>
      <div class="update-bottom">
        <el-button @click="close">取消</el-button>
        <el-button class="blue" @click="submit">确定</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
export default {
  data () {
    return {
      input: '',
      value: '',
      options: [],
      canvas: null,
      ctx: null,
      isDrawing: false,
      lastX: 0,
      lastY: 0,
      imageUrl: '',
      stutas: [
        { label: '关闭', value: '0' },
        { label: '启动', value: '1' }
      ],
      doctors: [],
      loading: false,
      // info: {
      //   id: 5230,
      //   createTime: '2024-03-26 20:03:04',
      //   updateTime: '2024-04-13 13:18:33',
      //   username: 'dsqfy001',
      //   password: '$2a$10$ePckiFHz3eeZLfMjtFdZlOm.ss8zsqGVh2g7iM.uopqQLbOHnlc9K',
      //   j_username: '',
      //   j_password: '',
      //   ptusername: '',
      //   ptpassword: '',
      //   nickname: '东胜区妇幼',
      //   headImgUrl: '',
      //   phone: '',
      //   telephone: '',
      //   email: '',
      //   birthday: '',
      //   sex: 0,
      //   status: 1,
      //   intro: '',
      //   openid: '',
      //   hostcode: '',
      //   flag: '',
      //   codeno: '',
      //   doctorno: '',
      //   jgbh: '150602000000',
      //   cwhmc: '',
      //   cwhmcid: '',
      //   ptcode: '',
      //   pttoken: '',
      //   jddw: '东胜区妇幼',
      //   bchaory: '',
      //   hyry: '',
      //   province: '150',
      //   city: '150600000000',
      //   country: '150602000000',
      //   town: '',
      //   village: '',
      //   jgjb: '3',
      //   jglist: '',
      //   ptflag: '',
      //   roleId: '',
      //   ysqm: '',
      //   msg: '',
      //   name: '',
      //   tjly: '',
      //   beiy1: '',
      //   beiy2: '',
      //   rolenames: '',
      //   rolename: '',
      //   ptdnflag: '1',
      //   pttjflag: '',
      //   sfzh: '',
      //   personInfoId: '',
      //   tmcode: '',
      //   tjrs: '',
      //   ptscrs: '',
      //   wscrs: '',
      //   zrws: '',
      //   sjjgmc: '',
      //   xzjdmc: '',
      //   ptuser: '',
      //   unionid: '',
      //   retgwflag: '',
      //   ptsqms: '',
      //   tjms: '',
      //   tbms: '',
      //   sjscms: '',
      //   pdssjg: '',
      //   provinceids: '内蒙古自治区',
      //   cityids: '鄂尔多斯市',
      //   countryids: '东胜区',
      //   townids: '',
      //   villageids: '',
      //   xtxzsj: '',
      //   xtxzzt: '',
      //   xtxzts: '',
      //   xtxzbt: '',
      //   scgz: '',
      //   districtId: '',
      //   districtCode: '',
      //   ptdistrictId: '',
      //   userRealName: '',
      //   ptlevel: '',
      //   orgId: '',
      //   orgname: '',
      //   orgCode: '',
      //   createOrgId: '',
      //   modifiedOrgId: '',
      //   modifiedOrgName: '',
      //   respdoctorname: '',
      //   respdoctorId: '',
      //   creator: '',
      //   creatorid: '',
      //   lytype: '',
      //   pttbdnflag: '',
      //   districtlevel: '',
      //   newpassword: '$2a$10$ePckiFHz3eeZLfMjtFdZlOm.ss8zsqGVh2g7iM.uopqQLbOHnlc9K',
      //   oldpassword: '',
      //   ptscflag: '',
      //   fwrq: '',
      //   newptusername: '',
      //   newptcode: '',
      //   cookies: '',
      //   cookie: '',
      //   lrr: '',
      //   lrrid: '',
      //   imageurl: '',
      //   zrys: '',
      //   zrysid: '',
      //   doctotname: '',
      //   doctorname: '',
      //   townss: '',
      //   villagess: '',
      //   villagesmc: '',
      //   townsmc: '',
      //   sessionKey: ''
      // },
      params: {
        id: '',
        username: '',
        jgjb: '',
        nickname: '',
        imageurl: '',
        ysqm: '',
        phone: '',
        password: '',
        sfzh: '',
        status: '',
        ptcode: '',
        jgbh: '',
        cwhmc: '',
        lrr: '',
        townids: '',
        headImgUrl: ''
      }
    }
  },
  mounted () {
    this.canvas = document.getElementById('canvas')
    this.params = this.$parent.selectInfo
    this.ctx = this.canvas.getContext('2d')
    this.getInfo()
  },
  methods: {
    async getInfo () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-167938855
      try {
        const params = {
          username: this.$parent.selectInfo.username || '',
          jgbh: this.$parent.selectInfo.jgbh || ''
        }
        const { data: res } = await this.$http.post('/users/finduserinfo', params)
        console.log('用户信息详情', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.info = res.data
      } catch (error) {
        console.log('请求失败', error)
      }
    },
    startDrawing (e) {
      this.isDrawing = true;
      [this.lastX, this.lastY] = [e.offsetX, e.offsetY]
    },
    async submit () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-167938855
      try {
        this.params.jgbh = this.$parent.selectInfo.jgbh || ''
        const { data: res } = await this.$http.post('/users/updateusertwo', this.params)
        console.log('用户信息详情', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.info = res.data
        this.$parent.closeUpdate()
        this.$parent.getList()
        // 修改后刷新页面，该方法为暂时解决图片上传修改后页面缓存显示旧图片的问题
        // this.$router.go(0)
      } catch (error) {
        console.log('请求失败', error)
      }
    },
    draw (e) {
      if (!this.isDrawing) return
      this.ctx.beginPath()
      this.ctx.moveTo(this.lastX, this.lastY)
      this.ctx.lineTo(e.offsetX, e.offsetY)
      this.ctx.stroke();
      [this.lastX, this.lastY] = [e.offsetX, e.offsetY]
    },
    stopDrawing () {
      this.isDrawing = false
    },
    reset () {
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)
    },
    saveSignature () {
      this.imageUrl = this.canvas.toDataURL()
      // var link = document.createElement('a')
      // link.href = dataURL
      // link.download = 'signature.png'
      // link.click()
    },
    update (e) {
      this.$refs.fileInput.click()
    },
    onSelectImage (e) {
      var file = e.target.files[0]
      var reader = new FileReader()
      reader.readAsDataURL(file)
      console.log(reader)
      reader.onload = res => {
        console.log(res.target.result)
        this.imageUrl = res.target.result
        this.uploadImg()
      }
    },
    async uploadImg () {
      console.log('---------------')
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-81591699
      try {
        this.loading = true
        const params = {
          qmtype: 'ysqm',
          qmid: this.info.id || '',
          jgbh: this.$store.state.loginInfo.jgbh || '',
          username: this.$store.state.loginInfo.username || '',
          openid: '',
          unionid: '',
          fileData: this.imageUrl
        }
        console.log('-+++++++++++++-' + params)
        const { data: res } = await this.$http.post('/files/uploadfilehsysqm', params)
        console.log('签名信息上传：', res)
        this.loading = false
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.$message.success('保存成功')
        this.params.headImgUrl = res.data.hsjcurl
        console.log('签名地址', this.params.headImgUrl)
      } catch (error) {
        this.loading = false
        console.log('请求失败', error)
      }
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.update {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .82);

  /deep/.el-loading-spinner i {
    color: #fff;
    font-size: 30px;
  }

  /deep/.el-loading-text {
    color: #fff;
    font-size: 20px;
  }

  .update-box {
    overflow: hidden;
    width: 80%;
    height: 90%;
    background-color: #fff;
    border-radius: 6px;

    .update-head {
      position: relative;
      width: 100%;
      height: 45px;
      line-height: 45px;
      text-align: center;
      background-color: #006EFF;
      color: #fff;
      font-size: 18px;
      font-weight: 600;

      .update-close {
        position: absolute;
        top: 50%;
        right: 18px;
        transform: translateY(-50%);
        color: #fff;
        font-size: 24px;
        cursor: pointer;
      }
    }

    .update-scroll {
      overflow-y: scroll;
      width: 100%;
      height: calc(100% - 125px);

      &::-webkit-scrollbar {
        width: 0;
      }

      .update-flex {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        padding: 0 24px;
        box-sizing: border-box;

        .update-item {
          display: flex;
          align-items: center;
          width: 24%;
          margin-top: 20px;

          .update-key {
            margin-right: 10px;
            white-space: nowrap;
            color: #17212B;
            font-size: 18px;
          }

          .el-input {
            width: 250px;
          }

          .el-select {
            width: 250px;
          }
        }

        .update-sign {
          display: flex;
          justify-content: center;
          width: 100%;
          margin-top: 20px;

          .update-sign-left {
            display: flex;
            flex-direction: column;
            width: 286px;
            margin-right: 79px;

            .update-sign-button {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 100%;
            }

            .update-sign-box {
              width: 289px;
              height: 88px;
              margin-top: 20px;
              background-color: #BDBDBD;
            }
          }

          .update-sign-right {
            display: flex;
            align-items: center;
            flex-direction: column;
            width: 517px;

            .update-sign-img {
              width: 100%;
              height: 218px;
              margin-bottom: 30px;
              object-fit: contain;
              background-color: #BDBDBD;
            }
          }

          .el-button {
            min-width: 70px;
            border: none;
            color: #fff;
          }

          .color1 {
            background-color: #FD5401;
          }

          .color2 {
            background-color: #2B01FD;
          }

          .color3 {
            background-color: #013CFD;
          }
        }
      }
    }

    .update-bottom {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 80px;

      .el-button {
        min-width: 113px;
        height: 40px;
        line-height: 40px;
        margin: 0 10px;
        padding-top: 0;
        padding-bottom: 0;
        color: #fff;
        font-size: 22px;
        background-color: #999999;
      }

      .blue {
        background-color: #006EFF;
      }
    }
  }
}
</style>
