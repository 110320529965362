<template>
  <div class="knowledgeBase">
    <div class="tab">
      <span class="title">入院登记</span>
    </div>
    <div class="content">
      <div class="intervene">
        <div class="info-title">
          <div class="info-box">
            <div class="left">
              <el-input class="el-input-width" placeholder="省"></el-input>
              <el-select v-model="value" placeholder="请选择城市">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
              <el-select v-model="value" placeholder="请选择区县">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
              <el-select v-model="value" placeholder="请选择街道/镇">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
              <el-select v-model="value" placeholder="请选择社区/村">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
              <el-input class="el-input-width" placeholder="请输入手机号"></el-input>
              <el-select v-model="value" placeholder="是否健在">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div class="right" @click="openAdd">
              <span class="el-icon-plus"></span>
            </div>
          </div>
          <div class="info-box">
            <div class="left">
              <el-button class="blue">搜索</el-button>
              <el-button class="blue">导出</el-button>
              <el-button class="gray red">删除</el-button>
              <el-button class="blue">重置</el-button>
              <el-button class="blue">模板导入</el-button>
              <el-button class="blue">模板下载</el-button>
            </div>
          </div>
        </div>
        <div class="table">
          <el-table :data="tableData" height="100%" :header-cell-style="{ background: '#024276' }">
            <el-table-column type="selection" width="42"></el-table-column>
            <el-table-column prop="name" label="长者编号"></el-table-column>
            <el-table-column label="姓名"></el-table-column>
            <el-table-column label="年龄"></el-table-column>
            <el-table-column label="联系方式"></el-table-column>
            <el-table-column label="固定电话"></el-table-column>
            <el-table-column label="性别"></el-table-column>
            <el-table-column label="出生日期"></el-table-column>
            <el-table-column label="户籍地址">
            </el-table-column>
            <el-table-column label="操作" width="240" >
              <!-- slot-scope="scope" -->
              <template>
                <div class="flex">
                  <span @click="openSee">查看</span>
                  <span>视频通话</span>
                  <span @click="openDelete">删除</span>
                  <span @click="openUpdate">修改</span>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="pagination">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="4"
            background :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper"
            :total="400">
          </el-pagination>
        </div>
      </div>
    </div>
    <Add v-if="showAdd" @close="closeAdd"></Add>
    <Update v-if="showUpdate" @close="closeUpdate"></Update>
    <Delete v-if="showDelete" @close="closeDelete"></Delete>
    <See v-if="showSee" @close="closeSee"></See>
  </div>
</template>

<script>
import Add from './add.vue'
import Delete from './delete.vue'
import See from './see.vue'
import Update from './update.vue'
export default {
  components: {
    Add,
    Delete,
    See,
    Update
  },
  data () {
    return {
      tableData: [{
        date: '2020-02-27 17:20',
        name: '2020031400',
        type: '1',
        value: false
      }, {
        date: '2020-02-27 17:20',
        name: '2020031400',
        type: '1',
        value: true
      }, {
        date: '2020-02-27 17:20',
        name: '2020031400',
        type: '1',
        value: true
      }, {
        date: '2020-02-27 17:20',
        name: '2020031400',
        type: '1',
        value: true
      }, {
        date: '2020-02-27 17:20',
        name: '2020031400',
        type: '1',
        value: true
      }, {
        date: '2020-02-27 17:20',
        name: '2020031400',
        type: '1',
        value: true
      }, {
        date: '2020-02-27 17:20',
        name: '2020031400',
        type: '1',
        value: true
      }, {
        date: '2020-02-27 17:20',
        name: '2020031400',
        type: '1',
        value: true
      }, {
        date: '2020-02-27 17:20',
        name: '2020031400',
        type: '1',
        value: true
      }, {
        date: '2020-02-27 17:20',
        name: '2020031400',
        type: '1',
        value: true
      }, {
        date: '2020-02-27 17:20',
        name: '2020031400',
        type: '1',
        value: true
      }],
      options: [{
        value: '',
        label: ''
      }],
      value: '',
      showAdd: false,
      showUpdate: false,
      showSee: false,
      showDelete: false
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    },
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
    },
    openAdd () {
      this.showAdd = true
    },
    closeAdd () {
      this.showAdd = false
    },
    openSee () {
      this.showSee = true
    },
    closeSee () {
      this.showSee = false
    },
    openUpdate () {
      this.showUpdate = true
    },
    closeUpdate () {
      this.showUpdate = false
    },
    openDelete () {
      this.showDelete = true
    },
    closeDelete () {
      this.showDelete = false
    }
  }
}
</script>

<style lang="less" scoped>
.knowledgeBase {
  position: relative;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  padding: 20px;
  border: 4px solid rgba(1, 124, 219, .27);
  box-sizing: border-box;
  background-color: #08223D;

  .tab {
    display: flex;
    align-items: center;
    width: 100%;
    height: 60px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;

    span {
      height: 38px;
      line-height: 38px;
      margin-right: 16px;
      padding: 0 12px;
      text-align: center;
      color: #fff;
      font-size: 22px;
      background-color: #D5D5D5;
      cursor: pointer;
    }

    .title {
      background-color: #01EEFD;
    }
  }

  .content {
    width: 100%;
    height: calc(100% - 80px);

    .intervene {
      position: relative;
      width: 100%;
      height: 100%;

      .info-title {
        width: 100%;
        height: 120px;
        border-bottom: 1px solid #01EEFD;
        box-sizing: border-box;
        color: #01EEFD;
        font-size: 20px;
        font-weight: bold;
      }

      .info-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 50px;
        margin-top: 10PX;
        padding: 0 10px;
        box-sizing: border-box;

        .left {
          display: flex;
          align-items: center;

          .search-input {
            width: 260px;
          }

          .el-select,
          .el-input {
            width: 180px;
            margin-right: 20px;
          }

          .date {
            margin-right: 10px;
          }

          .blue,
          .gray {
            margin-right: 29px;
            background-color: #04B4D0;
            color: #fff;
            border: none;
            border-radius: 0;
          }

          .gray {
            background-color: #284867;
          }

          .red {
            background-color: #FF0000;
          }
        }

        .right {
          width: 30px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          border-radius: 50%;
          background-color: #04B4D0;
          color: #fff;
          font-size: 18px;
          font-weight: 600;
          cursor: pointer;
        }
      }

      .table {
        width: 99%;
        height: 60%;
        margin-top: 10px;
        border: 4px #073F73 solid;
      }

      ::v-deep .el-table__body-wrapper {
        &::-webkit-scrollbar {
          // 整个滚动条
          width: 0px; // 纵向滚动条的宽度
        }
      }

      ::v-deep .el-table th.gutter {
        display: none;
        width: 0;
      }

      ::v-deep .el-table colgroup col[name='gutter'] {
        display: none;
        width: 0;
      }

      ::v-deep .el-table__body {
        width: 100% !important;
      }

      /deep/.el-table__header-wrapper {
        thead {
          th {
            div {
              color: #01EEFD;
              font-size: 17px;
              font-weight: 400;
            }
          }
        }
      }

      /deep/.el-table__body tbody tr {
        color: #fff;
        font-size: 16px;
        background-color: #092B50;
      }

      /deep/.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
        background-color: #092B50;
      }

      /deep/.el-table tbody tr:hover>td {
        background-color: transparent !important
      }

      .el-table--border,
      .el-table--group {
        border: 2px solid #ddd !important;
      }

      .el-table--border td,
      .el-table--border th,
      .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
        border-right: 1px solid #ddd !important;
      }

      /deep/.el-table td {
        border-bottom: 1px solid #01EEFD;
      }

      /deep/.el-table thead tr th {
        border-color: transparent;
      }

      /deep/ .el-table--border::after,
      /deep/ .el-table--group::after,
      /deep/ .el-table::before {
        background-color: #073F73;
      }

      /deep/.el-table--border {
        border: 1px solid #073F73 !important;
      }

      /deep/.el-table .cell {
        text-align: center;
        color: #01EEFD;
      }

      /deep/.el-table th.gutter {
        display: none;
        width: 0px;
      }

      /deep/.el-table colgroup col[name='gutter'] {
        display: none;
        width: 0;
      }

      /deep/ .el-checkbox__inner {
        background-color: transparent;
      }

      /deep/ .el-table__body-wrapper {
        background-color: #092B50;
      }

      .flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        font-size: 16px;

        span {
          color: #0077F9;
          cursor: pointer;
          letter-spacing: 0;
        }

        .red {
          color: #FF0000;
        }

        //按钮文字显示
        .el-switch {
          width: 70px !important;
          height: 25px !important;
        }

        ::v-deep .el-switch__core {
          width: 100% !important;
          height: 24px;
          border-radius: 100px;
          border: none;
        }

        ::v-deep .el-switch__core::after {
          width: 20px;
          height: 20px;
          top: 2px;
        }

        ::v-deep .el-switch.is-checked .el-switch__core::after {
          margin-left: -21px;
        }

        /*关闭时文字位置设置*/
        ::v-deep .el-switch__label--right {
          position: absolute;
          z-index: 1;
          right: 6px;
          margin-left: 0px;
          color: rgba(255, 255, 255, 0.9019607843137255);

          span {
            font-size: 12px;
          }
        }

        /* 激活时另一个文字消失 */
        ::v-deep .el-switch__label.is-active {
          display: none;
        }

        /*开启时文字位置设置*/
        ::v-deep .el-switch__label--left {
          position: absolute;
          z-index: 1;
          left: 5px;
          margin-right: 0px;
          color: rgba(255, 255, 255, 0.9019607843137255);

          span {
            font-size: 12px;
          }
        }

      }

      .flex1 {
        justify-content: center;
      }

      .pagination {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 10px;

        ::v-deep.el-pagination__total,
        ::v-deep.el-pagination__jump {
          color: #01EEFD;

          .el-input__inner {
            color: #fff;
            background-color: transparent;
          }
        }

        ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
          background-color: #01EEFD;
        }
      }
    }
  }
}
</style>
