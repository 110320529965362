<template>
  <div class="run">
    <div class="son-tab">
      <span class="son-item" :class="{ 'son-active': tabIndex === 0 }" @click="tabClick(0)">医生查房记录</span>
      <span class="son-item" :class="{ 'son-active': tabIndex === 1 }" @click="tabClick(1)">护士测量记录</span>
    </div>

    <div class="run-scroll" v-show="tabIndex === 0">
      <div class="run-search">
        <div class="run-item">
          <div class="key">
            <span>老人</span>
          </div>
          <el-input placeholder="请输入"></el-input>
        </div>
        <div class="run-item">
          <div class="key">
            <span>床位</span>
          </div>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="run-item">
          <div class="key">
            <span>时间</span>
          </div>
          <el-date-picker type="date" placeholder="请选择">
          </el-date-picker>
        </div>
        <el-button>搜索</el-button>
        <el-button>重置</el-button>
      </div>

      <el-table :data="tableData" height="50%">
        <el-table-column type="selection" width="55">
        </el-table-column>
        <el-table-column label="序号" type="index" width="50">
        </el-table-column>
        <el-table-column prop="name" label="记录时间">
        </el-table-column>
        <el-table-column prop="address" label="记录人">
        </el-table-column>
        <el-table-column prop="address" label="老人姓名">
        </el-table-column>
        <el-table-column prop="address" label="床位">
        </el-table-column>
        <el-table-column label="操作" width="140">
          <template>
            <div class="table-run">
              <span @click="openSee">查看记录</span>
            </div>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage4"
        :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper" :total="400">
      </el-pagination>
    </div>
    <div class="run-scroll" v-show="tabIndex === 1">
      <div class="run-tab">
        <span class="run-item" :class="{ 'run-active': index === listIndex }" v-for="(item, index) in list" :key="index" @click="tab(index)">{{ item }}</span>
      </div>
      <div class="run-search">
        <div class="run-item">
          <div class="key">
            <span>老人</span>
          </div>
          <el-input placeholder="请输入"></el-input>
        </div>
        <div class="run-item">
          <div class="key">
            <span>床位</span>
          </div>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <el-button>搜索</el-button>
        <el-button>重置</el-button>
      </div>

      <el-table :data="tableData" height="50%">
        <el-table-column type="selection" width="55">
        </el-table-column>
        <el-table-column label="序号" type="index" width="50">
        </el-table-column>
        <el-table-column prop="name" label="统计日期">
        </el-table-column>
        <el-table-column prop="address" label="记录人">
        </el-table-column>
        <el-table-column prop="address" label="老人姓名">
        </el-table-column>
        <el-table-column prop="address" label="床位">
        </el-table-column>
        <el-table-column prop="address" label="血压" v-if="listIndex === 0">
        </el-table-column>
        <el-table-column prop="address" label="脉搏" v-if="listIndex === 0">
        </el-table-column>
        <el-table-column prop="address" label="血糖" v-if="listIndex === 1">
        </el-table-column>
        <el-table-column prop="address" label="体温" v-if="listIndex === 2">
        </el-table-column>
      </el-table>

      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage4"
        :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper" :total="400">
      </el-pagination>
    </div>
    <See v-if="showSee" @close="closeSee"></See>
  </div>
</template>

<script>
import See from './see.vue'
export default {
  components: {
    See
  },
  data () {
    return {
      tableData: [{
        date: '2016-05-02',
        name: '王小虎',
        address: '等待外出'
      }, {
        date: '2016-05-04',
        name: '王小虎',
        address: '未返回'
      }, {
        date: '2016-05-01',
        name: '王小虎',
        address: '逾期未返回'
      }],
      options: [{
        value: '',
        label: ''
      }],
      value: '',
      currentPage4: 4,
      tabIndex: 0,
      selectIndex: 0,
      showAdd: false,
      showSee: false,
      showSet: false,
      list: ['血压记录', '血糖记录', '体温记录'],
      listIndex: 0
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    },
    tab (index) {
      this.listIndex = index
    },
    openAdd () {
      this.showAdd = true
    },
    closeAdd () {
      this.showAdd = false
    },
    openSee () {
      this.showSee = true
    },
    closeSee () {
      this.showSee = false
    },
    openSet () {
      this.showSet = true
    },
    closeSet () {
      this.showSet = false
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.run {
  width: 100%;
  height: 100%;
  background-color: #081C38;

  .son-tab {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;

    .son-item {
      height: 38px;
      line-height: 38px;
      margin-right: 40px;
      padding: 0 20px;
      text-align: center;
      background-color: #284867;
      color: #fff;
      font-size: 16px;
      cursor: pointer;
    }

    .son-active {
      background-color: #04B4D0;
    }
  }

  .run-tab {
    display: flex;
    align-items: center;
    height: 60px;

    .run-item {
      margin-right: 56px;
      color: #fff;
      font-size: 22px;
      border-bottom: 1px solid transparent;
      cursor: pointer;
    }

    .run-active {
      border-bottom: 1px solid #04B4D0;
      color: #04B4D0;
    }
  }

  .run-search {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;

    .run-item {
      display: flex;
      align-items: center;
      margin-right: 30px;

      .key {
        margin-right: 10px;
        white-space: nowrap;
        color: #01EEFD;
        font-size: 22px;
      }
    }

    .el-button {
      height: 38px;
      line-height: 38rpx;
      margin-left: 40px;
      border: none;
      background-color: #04B4D0;
      color: #fff;
    }
  }

  .run-scroll {
    width: 100%;
    height: calc(100% - 100px);
  }

  .el-table {
    margin-top: 10px;
    border: 4px solid #073F73;
    background-color: #081C38;
  }

  /deep/.el-table__body-wrapper {
    background-color: #081C38;
  }

  /deep/.el-table th.el-table__cell.is-leaf {
    border-bottom: none;
  }

  /deep/.el-table th.el-table__cell {
    background-color: #024276;
  }

  /deep/.el-table .cell {
    text-align: center;
    color: #01EEFD;
  }

  /deep/.el-table tr {
    background-color: #081C38;
  }

  /deep/.el-table td.el-table__cell {
    border-bottom: 1px solid #01EEFD;
  }

  .el-table::before {
    height: 0;
  }

  /deep/.el-table tbody tr:hover>td {
    background-color: transparent;
  }

  /deep/.el-checkbox__inner {
    background-color: transparent;
  }

  .table-run {
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      cursor: pointer;
    }
  }

  .el-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;

    /deep/.el-pagination__total {
      color: #01EEFD;
    }

    /deep/.el-pagination__jump {
      color: #01EEFD;
    }

    /deep/.el-input__inner {
      background-color: transparent;
      color: #01EEFD;
    }

    /deep/.number {
      margin-left: 10px;
      background-color: #ADADAD;
      color: #fff;
    }

    /deep/.active {
      background-color: #01EEFD;
    }

    /deep/.el-pagination .btn-prev {
      background-color: #ADADAD !important;
      padding-right: 0;
    }

    /deep/.btn-next {
      margin-left: 10px;
      padding: 0;
    }
  }
}
</style>
