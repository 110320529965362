<template>
  <div class="children-page">
    <div class="capsule">
      <div class="title">
        <div class="top"></div>
        <div class="bottom">
          <div class="text">
            <span>健康知识宣传栏</span>
          </div>
        </div>
        <div class="line"></div>
        <div class="line"></div>
      </div>
      <div class="capsule-box">
        <div class="head">
          <span>今日宣传</span>
        </div>
        <div class="content">
          <div class="h1">
            <span>高血压患者的饮食禁忌事项介绍</span>
          </div>
          <div class="h2">
            <span>1、高血压患者不能吃油腻和辛辣的食物</span>
          </div>
          <div class="h2">
            <span>1、高血压患者不能吃油腻和辛辣的食物</span>
          </div>
          <div class="h2">
            <span>1、高血压患者不能吃油腻和辛辣的食物</span>
          </div>
          <div class="h2">
            <span>1、高血压患者不能吃油腻和辛辣的食物</span>
          </div>
          <div class="h2">
            <span>1、高血压患者不能吃油腻和辛辣的食物</span>
          </div>
          <div class="h2">
            <span>1、高血压患者不能吃油腻和辛辣的食物</span>
          </div>
          <div class="h2">
            <span>1、高血压患者不能吃油腻和辛辣的食物</span>
          </div>
          <div class="h2">
            <span>1、高血压患者不能吃油腻和辛辣的食物</span>
          </div>
          <div class="h2">
            <span>1、高血压患者不能吃油腻和辛辣的食物</span>
          </div>
          <div class="h2">
            <span>1、高血压患者不能吃油腻和辛辣的食物</span>
          </div>
        </div>
      </div>
    </div>
    <div class="capsule">
      <div class="title">
        <div class="top"></div>
        <div class="bottom">
          <div class="text">
            <span>健康讲座时间发布</span>
          </div>
        </div>
        <div class="line"></div>
        <div class="line"></div>
      </div>
      <div class="capsule-box">
        <div class="head">
          <span>时间发布</span>
        </div>
        <div class="table">
          <div class="tr">
            <span class="flex">讲座类型</span>
            <span class="flex">讲座地点</span>
            <span class="flex flex2">讲座时间</span>
          </div>

          <div class="capsule-scroll">
            <div class="tr" v-for="(item, index) in 10" :key="index">
              <span class="flex">讲座类型</span>
              <span class="flex">讲座地点</span>
              <span class="flex flex2">讲座时间</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="capsule">
      <div class="title">
        <div class="top"></div>
        <div class="bottom">
          <div class="text">
            <span>健康教育视频</span>
          </div>
        </div>
        <div class="line"></div>
        <div class="line"></div>
      </div>
      <div class="video">
        <div class="video-item" v-for="(item, index) in 6" :key="index">
          <div class="video-title">
            <span>高血压知识讲座</span>
          </div>
          <div class="start">
            <span class="el-icon-caret-right"></span>
          </div>
        </div>
      </div>
    </div>
    <div class="capsule">
      <div class="title">
        <div class="top"></div>
        <div class="bottom">
          <div class="text">
            <span>政策发布</span>
          </div>
        </div>
        <div class="line"></div>
        <div class="line"></div>
      </div>
      <div class="capsule-box">
        <!-- <div class="head">
          <span>今日宣传</span>
        </div> -->
        <div class="content">
          <div class="h1">
            <span>最新国家医疗政策介绍</span>
          </div>
          <div class="h2">
            <span>在各级人民代表大会下设立专门医疗机构管理委员会，管理区域内公立医院，公立医院的领导
              和财务都由该委员会确定，并对人大常委会负责。各级卫生行政机构转变职能，强化监督责任
              ，非营利医疗机构的财政拨款等不再通过卫生行政机构或投资方，并建立医疗服务价格调整听
              证制度。
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="capsule1">
      <div class="title">
        <div class="top"></div>
        <div class="bottom">
          <div class="text">
            <span>就诊记录</span>
          </div>
        </div>
        <div class="line"></div>
        <div class="line"></div>
      </div>
      <div class="tab">
        <div class="tab-item tab-active">
          <span>机构</span>
        </div>
        <div class="tab-item">
          <span>医生</span>
        </div>
      </div>
      <div class="chart" id="charts"></div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      chartDom: null,
      isCollapse: false,
      activePath: '/runkanban'
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.canvasCharts()
    })
  },
  methods: {
    saveNavState (path) {
      window.sessionStorage.setItem('activePath', path)
      this.activePath = path
    },
    addColor (color1, color2) {
      return new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
        offset: 0,
        color: color1
      }, {
        offset: 1,
        color: color2
      }])
    },

    canvasCharts () {
      this.chartDom = document.getElementById('charts')
      var myChart = this.$echarts.init(this.chartDom)
      var option
      var colors = []
      colors.push(this.addColor('#F7903C', '#423635'))
      colors.push(this.addColor('#02DFF1', '#074261'))
      colors.push(this.addColor('#3CF787', '#074261'))
      const axisLabel = {
        show: true,
        textStyle: {
          color: '#fff',
          fontSize: 13
        }
      }
      const series = []
      const seriesTitles = ['小孩', '妇女', '老年人']
      seriesTitles.forEach(item => {
        series.push(
          {
            type: 'bar',
            name: item,
            itemStyle: {
              normal: {
                barBorderRadius: [4, 4, 0, 0],
                label: {
                  show: true,
                  position: 'top',
                  textStyle: {
                    color: '#ffffff',
                    fontSize: 10
                  }
                }
              }
            },
            barWidth: 18
          }
        )
      })
      option = {
        color: colors,
        legend: {
          show: true,
          data: seriesTitles,
          x: 'right',
          textStyle: {
            color: '#ffffff'
          }
        },
        tooltip: {},
        dataset: {
          source: [
            ['白家庄村卫生室', 43.3, 85.8, 93.7, 58],
            ['东阳台村卫生室', 83.1, 73.4, 55.1, 42],
            ['西安庄四村卫生室', 86.4, 65.2, 82.5, 33],
            ['东安庄一村卫生室', 72.4, 53.9, 39.1, 22],
            ['大魏村卫生室', 72.4, 53.9, 39.1, 22],
            ['西安庄一村卫生室', 72.4, 53.9, 39.1, 22],
            ['小魏村卫生室', 72.4, 53.9, 39.1, 22]
          ]
        },
        xAxis: {
          type: 'category',
          axisLabel,
          axisLine: {
            show: true,
            lineStyle: {
              color: ['#01EEFD']
            }
          }
        },
        yAxis: {
          type: 'value',
          name: '服务人数',
          nameTextStyle: {
            color: '#ffffff',
            nameLocation: 'start'
          },
          axisLabel,

          axisLine: {
            show: true,
            lineStyle: {
              color: ['#447DC3']
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ['#447DC3']
            }
          }
        },
        dataZoom: [
          {
            xAxisIndex: 0, // 这里是从X轴的0刻度开始
            show: false, // 是否显示滑动条，不影响使用
            type: 'slider', // 这个 dataZoom 组件是 slider 型 dataZoom 组件
            startValue: 0, // 从头开始。
            endValue: 6 // 一次性展示多少个。
          }
        ],
        series
      }
      myChart.setOption(option)
    }
  }
}
</script>

<style lang="less" scoped>
.children-page {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  height: 100%;

  .capsule {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 32%;
    height: 386px;
    margin-bottom: 20px;
    padding: 20px;
    box-sizing: border-box;
    border: 2px solid #016DBD;
    border-radius: 50px;
    background: #0A2F56;
    box-shadow: inset 0px 0px 13px 1px #0174C8;

    .title {
      position: relative;
      width: 169px;
      height: 35px;

      .top {
        width: 72px;
        margin-left: 4px;
        border-left: 3px solid transparent;
        border-right: 3px solid transparent;
        border-bottom: 6px solid #03BCD7;
      }

      .bottom {
        position: relative;
        width: 150px;
        height: 30px;
        line-height: 30px;
        background: linear-gradient(97deg, #0177CD 0%, #024073 100%);
        transform: skewX(20deg);
        color: #fff;
        font-size: 16px;
        font-weight: bold;

        &::before {
          position: absolute;
          top: -7px;
          left: -8px;
          content: '';
          width: 0;
          height: 0;
          border-color: transparent transparent #0A2F56 transparent;
          border-width: 6px;
          border-style: solid;
          transform: rotate(-35deg);
        }

        .text {
          width: 100%;
          text-align: center;
          transform: skewX(-20deg);
        }
      }

      .line {
        position: absolute;
        bottom: -2px;
        right: 10px;
        width: 2px;
        height: 35px;
        background-color: #032142;
        transform: rotate(-30deg);
      }

      .line:last-child {
        right: 0;
      }
    }

    .capsule-box {
      width: 100%;
      height: calc(100% - 50px);
      padding: 15px;
      background: linear-gradient(180deg, #094273 0%, #125F9E 100%);
      border: 1px solid #017CDB;
      box-sizing: border-box;

      .head {
        width: 122px;
        height: 29px;
        line-height: 29px;
        margin: 0 auto;
        text-align: center;
        background-color: #03BCD7;
        color: #fff;
        font-size: 20px;
      }

      .content {
        overflow-y: scroll;
        width: 100%;
        height: calc(100% - 29px);
        padding: 10px;
        background: linear-gradient(180deg, #094273 0%, #125F9E 100%);
        border: 1px solid #017CDB;
        box-sizing: border-box;

        &::-webkit-scrollbar {
          width: 0;
        }

        .h1 {
          color: #01EEFD;
          font-size: 16px;
        }

        .h2 {
          margin-top: 9px;
          color: #01EEFD;
          font-size: 12px;
        }
      }

      .table {
        display: flex;
        flex-direction: column;
        height: 100%;

        .tr {
          display: flex;
          align-items: center;

          .flex {
            flex: 1;
            text-align: center;
            color: #01EEFD;
            font-size: 16px;
          }

          .flex2 {
            flex: 2;
          }
        }

        .capsule-scroll {
          overflow-y: scroll;
          width: 100%;
          height: calc(100% - 30px);

          &::-webkit-scrollbar {
            width: 0;
          }

          .tr:nth-child(even) {

            background-color: rgb(6, 33, 61, .4);
          }
        }
      }
    }

    .video {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      width: 100%;
      height: calc(100% - 10px);
      margin-top: 10px;
      padding: 20px 8px;
      background: linear-gradient(180deg, #094273 0%, #125F9E 100%);
      border: 1px solid #017CDB;
      box-sizing: border-box;

      .video-item {
        position: relative;
        width: 32%;
        height: 105px;
        padding: 7px 9px;
        background-color: #000;
        border-radius: 6px;
        box-sizing: border-box;

        .video-title {
          color: #fff;
          font-size: 14px;
        }

        .start {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 32px;
          height: 32px;
          line-height: 32px;
          text-align: center;
          border-radius: 50%;
          background-color: rgba(255, 255, 255, .6);
          color: #fff;
          font-size: 20px;
        }
      }
    }
  }

  .capsule1 {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 386px;
    margin-bottom: 20px;
    padding: 20px;
    box-sizing: border-box;
    border: 2px solid #016DBD;
    border-radius: 50px;
    background: #0A2F56;
    box-shadow: inset 0px 0px 13px 1px #0174C8;

    .title {
      position: relative;
      width: 159px;
      height: 35px;

      .top {
        width: 72px;
        margin-left: 4px;
        border-left: 3px solid transparent;
        border-right: 3px solid transparent;
        border-bottom: 6px solid #03BCD7;
      }

      .bottom {
        position: relative;
        width: 140px;
        height: 30px;
        line-height: 30px;
        background: linear-gradient(97deg, #0177CD 0%, #024073 100%);
        transform: skewX(20deg);
        color: #fff;
        font-size: 16px;
        font-weight: bold;

        &::before {
          position: absolute;
          top: -7px;
          left: -8px;
          content: '';
          width: 0;
          height: 0;
          border-color: transparent transparent #0A2F56 transparent;
          border-width: 6px;
          border-style: solid;
          transform: rotate(-35deg);
        }

        .text {
          width: 100%;
          text-align: center;
          transform: skewX(-20deg);
        }
      }

      .line {
        position: absolute;
        bottom: -2px;
        right: 10px;
        width: 2px;
        height: 35px;
        background-color: #032142;
        transform: rotate(-30deg);
      }

      .line:last-child {
        right: 0;
      }
    }

    .tab {
      position: absolute;
      top: 30px;
      left: 200px;
      display: flex;
      align-items: center;

      .tab-item {
        margin-right: 20px;
        padding: 2px 19px;
        background-color: #7C7C7C;
        color: #fff;
        font-size: 18px;
        cursor: pointer;
      }

      .tab-active {
        background-color: #04B4D0;
      }
    }

    .chart {
      width: 100%;
      height: calc(100% - 40px);
    }
  }
}
</style>
