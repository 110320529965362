<template>
  <div class="children-page">
    <div class="head">
      <el-button size="small" class="blue">预约体检</el-button>
      <div class="right">
        <el-button size="small" class="blue">导入</el-button>
        <el-button size="small" class="blue">导出</el-button>
      </div>
    </div>
    <div class="input">
      <el-radio-group v-model="params.fwzt">
        <el-radio label="0">全部</el-radio>
        <el-radio label="1">待体检</el-radio>
        <el-radio label="2">已完成</el-radio>
        <el-radio label="3">已取消</el-radio>
      </el-radio-group>
      <el-date-picker v-model="date" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
        format="yyyy-MM-dd" value-format="yyyy-MM-dd" @change="change">
      </el-date-picker>
      <el-button class="blue" size="mini" @click="getList">搜索</el-button>
      <el-button class="blue" size="mini" @click="openAdd">添加</el-button>
    </div>
    <el-table :data="tableData" height="400" :header-cell-style="{ background: '#024276' }">
      <el-table-column type="selection" width="50">
      </el-table-column>
      <el-table-column prop="name" label="姓名">
      </el-table-column>
      <el-table-column label="性别">
        <template slot-scope="scope">
          <span>{{ scope.row.xb | typeSex }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="age" label="年龄">
      </el-table-column>
      <el-table-column prop="sfzh" label="身份证号">
      </el-table-column>
      <el-table-column prop="type" label="手机号码">
      </el-table-column>
      <el-table-column prop="deptname" label="套餐">
      </el-table-column>
      <el-table-column prop="type2" label="费用">
      </el-table-column>
      <el-table-column label="状态">
        <template slot-scope="scope">
          <div class="green">
            <span>{{ typeVlaue(scope.row.flagused) }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="day" label="体检日期">
      </el-table-column>
      <el-table-column prop="extparam" label="预约时间">
      </el-table-column>
      <el-table-column label="操作" width="90">
        <!-- slot-scope="scope" -->
        <template slot-scope="scope">
          <div class="flex">
            <img class="img" src="../../../img/reservationService/list.png" alt="" @click="openSee(scope.row)">
            <img class="img" src="../../../img/reservationService/print.png" alt="" @click="openSelect(scope.row)">
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="query1.pageNum" background :page-size="query1.pageSize"
              layout="total, prev, pager, next, jumper" :total="query1.total"></el-pagination>
    </div>
    <See v-if="showSee" @close="closeSee"></See>
    <Add v-if="showAdd"></Add>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import See from './see.vue'
import Add from './add.vue'
export default {
  components: {
    See,
    Add
  },
  data () {
    return {
      total: 400,
      currentPage: 0,
      tableData: [],
      options: [{
        value: '1',
        label: '1'
      }],
      value: '',
      radio: '',
      date: '',
      input: '',
      // 分页数据
      query1: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      params: {
        // 分页起始
        start: '0',
        // 分页长度
        length: '10',
        ssjgbh: '',
        xzjdmcid: '',
        cwhmcid: '',
        fwrq: '',
        fwzt: '0',
        begtime: '',
        endtime: '',
        deptno: '',
        parstr: ''
      },
      showSee: false,
      showSelect: false,
      showAdd: false
    }
  },
  computed: {
    ...mapState(['loginInfo'])
  },
  filters: {
    typeSex (val) {
      if (val === '1') return '男'
      if (val === '2') return '女'
      return ''
    }
  },
  created () {
    this.params.ssjgbh = this.$store.state.loginInfo.value
    this.getList()
  },
  methods: {
    async getList () {
      // 接口文档 https://app.apifox.com/project/2726972/apis/api-173319987
      try {
        this.params.start = this.query1.pageNum === 1 ? '0' : (this.query1.pageNum - 1) * 10 + ''
        this.params.length = this.query1.pageSize + ''
        const { data: res } = await this.$http.get('/appRegsterMarks', { params: this.params })
        console.log('预约体检记录列表查询', res)
        if (res.code !== 0) return
        this.tableData = res.data
        this.query1.total = res.recordsTotal
      } catch (error) {
        console.log('预约体检记录列表查询请求失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    click () {
    },
    typeVlaue (val) {
      if (val !== null) return ['可预约', '预约成功', '正在支付', '取消'][val]
      return ''
    },
    change (e) {
      console.log(e)
      this.params.begtime = e[0]
      this.params.cwhmcid = e[1]
    },
    openAdd () {
      this.showAdd = true
    },
    openSee (item) {
      this.showSee = true
    },
    closeSee () {
      this.showSee = false
    },
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
      this.query1.pageNum = val
      this.getList()
    },
    openSelect (item) {
      this.showSelect = true
    },
    closeSelect (type) {
      this.showSelect = false
    }
  }
}
</script>

<style lang="less" scoped>
.children-page {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0 30px;
  background-color: #081C38;
  box-sizing: border-box;

  .swich {
    display: flex;
    flex-direction: column;
  }

  .head {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;

    .right {
      display: flex;
      flex-direction: row;
      align-items: center;

      .el-button {
        width: 90px;
        font-size: 16px;
      }
    }

    .el-button {
      text-align: center;
      border-radius: 0;
      color: #fff;
      font-size: 22px;
      border: 0;
      margin-right: 20px;
      background-color: #04B4D0;
    }

    .width {
      width: 80px;
      font-size: 18px;
    }

    .el-input {
      width: 246px;
      height: 38px;
      border-radius: 6px;

      /deep/.el-input__inner {
        height: 38px;
        line-height: 38px;
      }
    }

    .postion {
      position: absolute;
      right: 0;
      background: linear-gradient(153deg, #0A477B 0%, #0E67AD 100%);
      border: 1px solid #1F72D3;

      /deep/.el-input__inner {
        background-color: transparent;
        color: #fff !important;
        font-size: 18px;
        border: none;

      }
    }

    /deep/ .el-input__suffix {
      color: #fff;
      font-size: 18px;
    }

    /deep/ .el-icon-search {
      display: flex;
      align-items: center;
    }
  }

  .input {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    height: 78px;
    border-bottom: 1px solid #01EEFD;

    .el-button {
      width: 90px;
      height: 38px;
      text-align: center;
      border-radius: 0;
      color: #fff;
      font-size: 16px;
      border: 0;
    }

    .el-radio {
      color: #fff;
    }

    /deep/.el-radio__label {
      font-size: 18px;
    }

    /deep/.el-radio__input.is-checked+.el-radio__label {
      color: #01EEFD;
    }

    /deep/.el-radio__input.is-checked .el-radio__inner {
      background-color: #01EEFD;
      border-color: #01EEFD;
    }

    /deep/.el-radio__inner {
      background-color: transparent;
    }

    .el-select {
      margin-left: 20px;
    }

    .el-input {
      width: 334px;
      margin-left: 20px;
    }

    .el-date-editor {
      margin-left: 20px;
    }

    .blue {
      margin-left: 20px;
      background-color: #04B4D0;
    }

    .search {
      width: 300px;
    }

    .item {
      display: flex;
      align-items: center;
      padding: 10px 0;
      margin-right: 20px;
      color: #01EEFD;
      font-size: 18px;

      .el-radio {
        color: #fff;
      }

      /deep/.el-radio__label {
        font-size: 18px;
      }

      /deep/.el-radio__input.is-checked+.el-radio__label {
        color: #01EEFD;
      }

      /deep/.el-radio__input.is-checked .el-radio__inner {
        background-color: #01EEFD;
        border-color: #01EEFD;
      }

      .span {
        margin-right: 10px;
      }

      .el-input {
        width: 206px;
        height: 38px;
        line-height: 38px;
        background-color: #fff;

        /deep/.el-input__inner {
          height: 38px;
          line-height: 38px;
        }
      }
    }
  }

  ::v-deep .el-table__body-wrapper {
    &::-webkit-scrollbar {
      width: 0px;
    }
  }

  ::v-deep .el-table th.gutter {
    display: none;
    width: 0;
  }

  ::v-deep .el-table colgroup col[name='gutter'] {
    display: none;
    width: 0;
  }

  ::v-deep .el-table__body {
    width: 100% !important;
  }

  .el-table {
    margin-top: 20px;
    background-color: #092B50;
    border: 4px #073F73 solid;
  }

  // 表头文字颜色
  /deep/.el-table__header-wrapper {
    thead {
      th {
        div {
          color: #fff;
          font-size: 17px;
          font-weight: 400;
        }
      }
    }
  }

  //表格内容颜色
  /deep/.el-table__body tbody tr {
    color: #fff;
    background-color: #092B50;
  }

  /deep/.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
    background-color: #092B50;
  }

  /deep/.el-table tbody tr:hover>td {
    background-color: transparent !important
  }

  // 改变边框颜色
  .el-table--border,
  .el-table--group {
    border: 2px solid #ddd !important;
  }

  /**
  改变表格内竖线颜色
   */
  .el-table--border td,
  .el-table--border th,
  .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
    border-right: 1px solid #ddd !important;
  }

  /deep/.el-table td {
    border-bottom: 1px solid #01EEFD;
  }

  /deep/.el-table thead tr th {
    border-color: transparent;
  }

  /deep/ .el-table--border::after,
  /deep/ .el-table--group::after,
  /deep/ .el-table::before {
    background-color: #073F73;
  }

  /deep/.el-table--border {
    border: 1px solid #073F73 !important;
  }

  /deep/.el-table .cell {
    text-align: center;
    color: #fff;
  }

  /deep/.el-checkbox__inner {
    background-color: transparent;
  }

  .flex {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .img {
      width: 29px;
      height: 25px;
      cursor: pointer;
    }
  }

  .green {
    color: #06B400;
  }

  .yellow {
    color: #FF8014;
  }

  .red {
    color: #FF0000;
  }

  .purple {
    color: #073F73;
    cursor: pointer;
  }

  .gray {
    color: #D2D2D2;
    cursor: pointer;
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 40px;

    ::v-deep.el-pagination__total,
    ::v-deep.el-pagination__jump {
      color: #01EEFD;

      .el-input__inner {
        color: #fff;
        background-color: transparent;
      }
    }

    ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: #01EEFD;
    }
  }
}
</style>
