<template>
  <div class="add">
    <div class="add-box">
      <div class="add-head">
        <span class="text">新增</span>
        <span class="el-icon-close close" @click="close"></span>
      </div>
      <div class="add-content">
        <div class="left">
          <span class="el-icon-camera-solid icon"></span>
        </div>
        <div class="right">
          <div class="item">
            <div class="key">
              <span>人群分类</span>
            </div>
            <el-input placeholder="请输入"></el-input>
          </div>
          <div class="item">
            <div class="key">
              <span>姓名</span>
            </div>
            <el-input placeholder="请输入"></el-input>
          </div>
          <div class="item">
            <div class="key">
              <span>身份证号</span>
            </div>
            <el-input placeholder="请输入"></el-input>
          </div>
          <div class="item">
            <div class="key">
              <span>性别</span>
            </div>
            <el-input placeholder="" class="input"></el-input>
            <div class="key key1">
              <span>年龄</span>
            </div>
            <el-input placeholder="" class="input"></el-input>
            <div class="key key1">
              <span>民族</span>
            </div>
            <el-input placeholder="" class="input"></el-input>
          </div>
          <div class="item">
            <div class="key">
              <span>户籍地址</span>
            </div>
            <el-input placeholder="请输入"></el-input>
          </div>
          <div class="item">
            <div class="key">
              <span>现住址</span>
            </div>
            <el-input placeholder="请输入"></el-input>
          </div>
          <div class="item">
            <div class="key">
              <span>所属机构</span>
            </div>
            <el-input placeholder="请输入"></el-input>
          </div>
          <div class="item">
            <div class="key">
              <span>所属学校</span>
            </div>
            <el-input placeholder="请输入"></el-input>
          </div>
          <div class="item">
            <div class="key">
              <span>所属班级</span>
            </div>
            <el-input placeholder="请输入"></el-input>
          </div>
          <div class="item">
            <div class="key">
              <span>学生编号</span>
            </div>
            <el-input placeholder="请输入"></el-input>
          </div>
          <div class="item">
            <div class="key">
              <span>责任医生</span>
            </div>
            <el-input placeholder="请输入"></el-input>
          </div>
          <div class="item">
            <div class="key">
              <span>体检类型</span>
            </div>
            <el-input placeholder="请输入"></el-input>
          </div>
          <div class="item">
            <div class="key">
              <span>档案编号</span>
            </div>
            <el-input placeholder="请输入"></el-input>
          </div>
          <div class="item">
            <div class="key">
              <span>建档日期</span>
            </div>
            <el-date-picker type="date" placeholder="">
            </el-date-picker>
          </div>
          <div class="item">
            <div class="key">
              <span>联系人</span>
            </div>
            <el-input placeholder="请输入"></el-input>
          </div>
          <div class="item">
            <div class="key">
              <span>联系电话</span>
            </div>
            <el-input placeholder="请输入"></el-input>
          </div>
        </div>
      </div>
      <div class="add-button">
        <el-button type="primary">确认</el-button>
        <el-button type="info" @click="close">取消</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {

    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.add {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .69);

  .add-box {
    display: flex;
    flex-direction: column;
    width: 85%;
    padding: 0 16px;
    background-color: #fff;
    border-radius: 6px;
    box-sizing: border-box;

    .add-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 67px;
      padding: 0 12px;
      border-bottom: 1px solid #B1B1B1;
      box-sizing: border-box;

      .text {
        color: #2A374D;
        font-size: 18px;
        font-weight: 600;
      }

      .close {
        color: #707070;
        font-size: 24px;
        cursor: pointer;
      }
    }

    .add-content {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 0 12px;
      padding-top: 20px;
      box-sizing: border-box;

      .left {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 130px;
        height: 181px;
        border: 1px solid #707070;
        border-radius: 2px;

        .icon {
          color: #0077F9;
          font-size: 30px;
        }
      }

      .right {
        display: flex;
        flex-wrap: wrap;
        width: calc(100% - 150px);

        .item {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          width: 25%;
          margin-bottom: 20px;
          padding-right: 20px;
          box-sizing: border-box;

          .key {
            margin-right: 12px;
            white-space: nowrap;
            color: #17212B;
            font-size: 18px;
          }

          .key1 {
            margin-left: 8px;
          }

          .el-input {
            width: 228px;
          }

          .input {
            width: 50px;
          }

          /deep/.el-input__inner {
            padding: 10px;
          }
        }
      }
    }

    .add-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 112px;

      .el-button {
        min-width: 150px;
        margin-left: 15px;
        margin-right: 15px;
        font-size: 30px;
      }
    }
  }
}
</style>
