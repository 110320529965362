<!--
 * @Author: 小宝 zksukuo@yunkangda.com
 * @Date: 2024-03-18 11:05:41
 * @LastEditors: 小宝 zksukuo@yunkangda.com
 * @LastEditTime: 2024-03-18 13:16:12
 * @FilePath: \visions\src\views\zhyl\smartPhysicalExamination\systemConfig\testProjectRun\testProjectRun.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="page-box">
    <div class="tab">
      <span class="item" :class="{ active: tabIndex === index }" v-for="(item, index) in tabs" :key="index"
        @click="tabClick(index)">{{ item }}</span>
    </div>
    <el-input placeholder="姓名/身份证号" suffix-icon="el-icon-search" class="input"></el-input>
    <div class="tab-box">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tabIndex: 0,
      tabs: Object.freeze(['项目类型', '套餐类别', '套餐项目'])
    }
  },
  methods: {
    tabClick (index) {
      const routers = ['projecttype', 'packagetype', 'packageproject']
      this.tabIndex = index
      this.$router.push('/smartphysicalexamination/systemconfig/packagerun/' + routers[index])
      console.log(routers[index])
    }
  }
}
</script>

<style lang="less" scoped>
.page-box {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  background-color: #fff;

  .tab {
    display: flex;
    align-items: center;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    .item {
      width: 130px;
      height: 38px;
      line-height: 38px;
      margin-right: 30px;
      text-align: center;
      background-color: #284867;
      color: #fff;
      font-size: 22px;
      cursor: pointer;
    }

    .active {
      background-color: #409EFF;
    }
  }

  .tab-box {
    width: 100%;
    height: calc(100% - 80px);
  }

  .input {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 246px;
  }

}
</style>
