<template>
  <div class="son">
    <div class="son-tab">
      <span class="son-item" :class="{ 'son-active': tabIndex === index }" v-for="(item, index) in tabs" :key="index"
        @click="tabClick(index)">{{ item }}</span>
    </div>
    <div class="son-content">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tabIndex: 0,
      tabs: Object.freeze(['疫苗预约排期', '疫苗种类管理'])
    }
  },
  methods: {
    tabClick (index) {
      if (index === this.tabIndex) return
      this.tabIndex = index
      this.$router.push('/reservationservice/vaccineappointment/vaccinerun/tab' + (index + 1))
    }
  }
}
</script>

<style lang="less" scoped>
.son {
  width: 100%;
  height: 100%;
  background-color: #081C38;

  .son-tab {
    display: flex;
    flex-direction: row;
    height: 60px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;

    .son-item {
      height: 38px;
      line-height: 38px;
      margin-right: 20px;
      padding: 0 14px;
      background-color: #284867;
      color: #fff;
      font-size: 22px;
    }

    .son-active {
      background-color: #04B4D0;
    }
  }

  .son-content {
    width: 100%;
    height: calc(100% - 60px);
  }
}
</style>
