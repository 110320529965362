<template>
  <div class="dataOverview">
    <div class="info">
      <div class="item">
        <span>用户总数</span>
        <span>3825</span>
      </div>
      <div class="item">
        <span>现有预约</span>
        <span>3825</span>
      </div>
      <div class="item">
        <span>疫苗余量</span>
        <span>3825</span>
      </div>
      <div class="item">
        <span>文章总数</span>
        <span>3825</span>
      </div>
    </div>
    <div class="charts">
      <div class="left" id="charts1"></div>
      <div class="right" id="charts2"></div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      myChart1: null,
      myChart2: null
    }
  },
  mounted () {
    this.canvas1()
    this.canvas2()
    window.addEventListener('resize', this.getresize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.getresize)
  },
  methods: {
    getresize () {
      this.myChart1.resize()
      this.myChart2.resize()
    },
    canvas1 () {
      var chartDom = document.getElementById('charts1')
      this.myChart1 = this.$echarts.init(chartDom)
      var option = {
        tooltip: {
          trigger: 'axis'
        },
        xAxis: {
          type: 'category',
          data: ['1/4', '1/24', '1/31', '2/4', '2/14', '2/18', '3/1'],
          axisLabel: {
            show: true,
            color: '#01EEFD',
            fontSize: 15
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: ['#039CB0']
            }
          },
          axisTick: {
            // y轴刻度线
            show: false
          }
        },
        yAxis: [
          {
            type: 'value',
            axisLine: {
              show: true,
              lineStyle: {
                color: ['#039CB0']
              }
            },
            axisLabel: {
              show: true,
              color: '#01EEFD',
              fontSize: 15
            },
            splitLine: { // 表格里面Y轴线条
              show: true,
              lineStyle: {
                type: 'solid', // 虚线
                color: '#01EEFD' // 虚线颜色
              }
            }
          }, {
            type: 'value',
            axisLine: {
              show: true,
              lineStyle: {
                color: ['#039CB0']
              }
            },
            axisLabel: {
              show: true,
              color: '#01EEFD',
              fontSize: 15
            },
            splitLine: { // 表格里面Y轴线条
              show: true,
              lineStyle: {
                type: 'solid', // 虚线
                color: '#01EEFD' // 虚线颜色
              }
            }
          }
        ],
        grid: {
          left: '3%',
          right: '4%',
          top: '16%',
          bottom: '5%',
          containLabel: true
        },
        series: [
          {
            name: '体重',
            data: [150, 230, 224, 218, 135, 147, 260],
            type: 'line',
            SymbolSize: 30,
            itemStyle: {
              color: '#fff' // 改变折线点的颜色
            },
            lineStyle: {
              color: '#0FC4F6' // 改变折线颜色
            }
          }
        ]
      }
      option && this.myChart1.setOption(option)
    },
    canvas2 () {
      const chartDom = document.getElementById('charts2')
      this.myChart2 = this.$echarts.init(chartDom)
      const option = {
        calculable: true,
        xAxis: [
          {
            type: 'category',
            data: ['用户总数', '预约', '疫苗', '文章'],
            axisLine: { // x轴线的颜色以及宽度
              show: true, // 是否显示X轴
              lineStyle: {
                color: '#01EEFD'
              }
            },
            axisTick: {
              // 是否显示刻度线
              show: false
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            // name: '次/分',
            axisTick: {
              // y轴刻度线
              show: false
            },
            axisLine: {
              show: true, // Y轴
              lineStyle: {
                color: '#01EEFD'
              }
            },
            splitLine: { // 表格里面Y轴线条
              show: true,
              lineStyle: {
                type: 'solid', // 虚线
                color: '#01EEFD' // 虚线颜色
              }
            }
          },
          {
            type: 'value',
            // name: '次/分',
            axisTick: {
              // y轴刻度线
              show: false
            },
            axisLine: {
              show: true, // Y轴
              lineStyle: {
                color: '#01EEFD'
              }
            },
            splitLine: { // 表格里面Y轴线条
              show: true,
              lineStyle: {
                type: 'solid', // 虚线
                color: '#01EEFD' // 虚线颜色
              }
            }
          }
        ],
        // 整体大小
        grid: {
          left: '3%',
          right: '4%',
          top: '20%',
          bottom: '5%',
          containLabel: true
        },
        series: [
          {
            barWidth: 40, // 柱子宽度
            // name: '心率',
            type: 'bar',
            data: [
              135.6, 162.2, 32.6, 20.0, 6.4
            ],
            itemStyle: {
              // 颜色样式部分
              // barBorderRadius: [4, 4, 0, 0], // 圆角[上左，上右，下右，下左]
              label: {
                show: false, // 开启数字显示
                position: 'top', // 在上方显示数字
                color: '#fff', // 字体颜色
                fontSize: 10 // 字体大小
              },
              //   柱状图颜色渐变
              color: function (params) {
                const colors = ['#C21407', '#0C9723', '#B97E06', '#512CFF']
                return colors[params.dataIndex]
              }
            }

          }
        ]
      }

      option && this.myChart2.setOption(option)
    }
  }
}
</script>

<style lang="less" scoped>
.dataOverview {
  width: 100%;
  height: 100%;
  padding: 50px;
  background-color: #081C38;
  box-sizing: border-box;

  .info {
    display: flex;
    align-items: center;

    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 180px;
      height: 108px;
      margin-right: 80px;
      background-color: #C21407;
      border-radius: 6px;
      color: #FFFFFF;
      font-size: 23px;
    }

    .item:nth-child(2) {
      background-color: #0C9723;
    }

    .item:nth-child(3) {
      background-color: #B97E06;
    }

    .item:nth-child(4) {
      background-color: #512CFF;
    }
  }

  .charts {
    display: flex;
    align-items: center;
    width: 100%;

    .left {
      width: 45%;
      height: 326px;
    }

    .right {
      width: 45%;
      height: 326px;
    }
  }
}
</style>
