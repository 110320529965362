<template>
  <div class="registration">
    <div class="son-tab">
      <span class="son-item son-active">用药计划设置</span>
      <el-input placeholder="姓名/身份证号" suffix-icon="el-icon-search" class="see-search">
      </el-input>
    </div>

    <div class="son-info">
      <!-- <img src="../../../img/emr/user.png" class="son-info-img" /> -->
      <div class="son-news">
        <div class="son-news-item">
          <span>老人：张三</span>
        </div>
        <div class="son-news-item">
          <span>床位：501-1</span>
        </div>
      </div>
    </div>

    <!-- <div class="son-add">
      <div class="son-add-icon" @click="openPlan">
        <span class="el-icon-plus"></span>
      </div>
    </div> -->

    <el-table :data="tableData" height="50%">
      <el-table-column label="序号" type="index" width="50">
      </el-table-column>
      <el-table-column prop="name" label="用药名称">
      </el-table-column>
      <el-table-column prop="address" label="最近登记时间">
      </el-table-column>
      <el-table-column prop="address" label="数量">
      </el-table-column>
      <el-table-column prop="address" label="有效期">
      </el-table-column>
      <el-table-column prop="address" label="剂量">
      </el-table-column>
      <el-table-column prop="address" label="服法">
      </el-table-column>
      <el-table-column prop="address" label="服药计划">
      </el-table-column>
      <el-table-column prop="address" label="开始时间">
      </el-table-column>
      <el-table-column prop="address" label="结束时间">
      </el-table-column>
      <el-table-column label="操作" width="140">
        <template>
          <div class="table-run">
            <span @click="openPlan">设置</span>
            <span @click="openStop">停止用药</span>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage4"
      :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper" :total="400">
    </el-pagination>
    <div class="see-close">
      <el-button size="small" @click="close">返回</el-button>
      <el-button class="see-blue" size="small" @click="close">保存</el-button>
    </div>
    <!-- 设置服药计划 -->
    <Plan v-if="showPlan" @close="closePlan"></Plan>
    <!-- 停止用药 -->
    <Stop v-if="showStop" @close="closeStop"></Stop>
  </div>
</template>

<script>
import Plan from './plan.vue'
import Stop from './stop.vue'
export default {
  components: {
    Plan,
    Stop
  },
  data () {
    return {
      tableData: [{
        date: '2016-05-02',
        name: '王小虎',
        address: '等待外出'
      }, {
        date: '2016-05-04',
        name: '王小虎',
        address: '未返回'
      }, {
        date: '2016-05-01',
        name: '王小虎',
        address: '逾期未返回'
      }],
      options: [{
        value: '',
        label: ''
      }],
      value: '',
      currentPage4: 4,
      tabIndex: 0,
      selectIndex: 0,
      showPlan: false,
      showExtension: false,
      showStop: false
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    },
    openPlan () {
      this.showPlan = true
    },
    closePlan () {
      this.showPlan = false
    },
    openExtension () {
      this.showExtension = true
    },
    closeExtension () {
      this.showExtension = false
    },
    openStop () {
      this.showStop = true
    },
    closeStop () {
      this.showStop = false
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.registration {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  padding: 0 20px;
  background-color: #081C38;
  box-sizing: border-box;

  .son-tab {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;

    .son-item {
      height: 38px;
      line-height: 38px;
      margin-right: 40px;
      padding: 0 20px;
      text-align: center;
      background-color: #284867;
      color: #fff;
      font-size: 16px;
      cursor: pointer;
    }

    .son-active {
      background-color: #04B4D0;
    }

    .see-search {
      width: 246px;

      /deep/.el-input__inner {
        border: none;
        background: linear-gradient(153deg, #0A477B 0%, #0E67AD 100%);
        color: #fff;
        font-size: 18px;
      }

      /deep/.el-input__inner::-webkit-input-placeholder {
        color: #fff;
      }

      /deep/.el-input__suffix {
        color: #fff;
        font-size: 18px;
      }
    }
  }

  .son-info {
    display: flex;
    align-items: center;
    width: 100%;
    height: 84px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;

    .son-info-img {
      width: 58px;
      height: 58px;
      margin-left: 17px;
      margin-right: 10px;
    }

    .son-news {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      width: 700px;
      height: 100%;
      margin-left: 115px;

      .son-news-item {
        width: 100%;
        color: #01EEFD;
        font-size: 16px;
      }
    }
  }

  .son-add {
    display: flex;
    align-items: center;
    height: 70px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;

    .son-add-icon {
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      background-color: #04B4D0;
      border-radius: 50%;
      color: #fff;
      font-size: 16px;
      cursor: pointer;
    }
  }

  .el-table {
    margin-top: 10px;
    border: 4px solid #073F73;
    background-color: #081C38;
  }

  /deep/.el-table__body-wrapper {
    background-color: #081C38;
  }

  /deep/.el-table th.el-table__cell.is-leaf {
    border-bottom: none;
  }

  /deep/.el-table th.el-table__cell {
    background-color: #024276;
  }

  /deep/.el-table .cell {
    text-align: center;
    color: #01EEFD;
  }

  /deep/.el-table tr {
    background-color: #081C38;
  }

  /deep/.el-table td.el-table__cell {
    border-bottom: 1px solid #01EEFD;
  }

  .el-table::before {
    height: 0;
  }

  /deep/.el-table tbody tr:hover>td {
    background-color: transparent;
  }

  /deep/.el-checkbox__inner {
    background-color: transparent;
  }

  .table-run {
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      cursor: pointer;
    }
  }

  .el-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;

    /deep/.el-pagination__total {
      color: #01EEFD;
    }

    /deep/.el-pagination__jump {
      color: #01EEFD;
    }

    /deep/.el-input__inner {
      background-color: transparent;
      color: #01EEFD;
    }

    /deep/.number {
      margin-left: 10px;
      background-color: #ADADAD;
      color: #fff;
    }

    /deep/.active {
      background-color: #01EEFD;
    }

    /deep/.el-pagination .btn-prev {
      background-color: #ADADAD !important;
      padding-right: 0;
    }

    /deep/.btn-next {
      margin-left: 10px;
      padding: 0;
    }
  }

  .see-close {
    position: absolute;
    left: 40px;
    bottom: 0px;
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;

    .el-button {
      background-color: #33465C;
      border: none;
      color: #fff;
    }

    .see-blue {
      margin-left: 50px;
      background-color: #024A83;
    }
  }
}
</style>
