<template>
  <div class="popup">
    <div class="popup-title">
      <div class="title">
        <span>编辑套餐信息</span>
      </div>
      <el-input class="postion" placeholder="姓名/身份证号" suffix-icon="el-icon-search">
      </el-input>
    </div>
    <div class="popup-line">
      <span>基本信息</span>
    </div>
    <div class="popup-box">
      <div class="popup-item">
        <span>套餐名称：</span>
        <el-input placeholder="请输入"></el-input>
      </div>
      <div class="popup-item">
        <span>套餐类型：</span>
        <el-select placeholder="请选择院区" v-model="value">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="popup-item">
        <span>所属院区：</span>
        <el-select placeholder="请选择科室" v-model="value">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="popup-item">
        <span>套餐图片：</span>
        <el-upload class="avatar-uploader" action="https://jsonplaceholder.typicode.com/posts/" :show-file-list="false"
          :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
          <img v-if="imageUrl" :src="imageUrl" class="avatar">
          <div class="info" v-else>
            <i class="el-icon-plus avatar-uploader-icon"></i>
            <span>上传头像</span>
          </div>
        </el-upload>
      </div>
      <div class="popup-item">
        <span>套餐项目：</span>
        <el-select placeholder="请选择" v-model="value">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="popup-item">
        <span>套餐价格：</span>
        <el-input placeholder="请输入"></el-input>
      </div>
      <div class="popup-item popup-item1">
        <span>套餐介绍：</span>
        <el-input placeholder="请输入"></el-input>
      </div>
      <div class="popup-item popup-item1">
        <span>注意事项：</span>
        <el-input placeholder="请输入"></el-input>
      </div>
      <div class="popup-item">
        <span>套餐状态：</span>
        <el-switch v-model="value" active-color="#30F05D" inactive-color="#ff4949">
        </el-switch>
      </div>
    </div>
    <div class="btns">
      <el-button class="blue" @click="close">保存</el-button>
      <el-button @click="close">取消</el-button>
    </div>
  </div>
</template>

<script>
export default {
  components: {

  },
  data () {
    return {
      options: [{
        value: '',
        label: ''
      }],
      imageUrl: '',
      value: ''
    }
  },
  methods: {
    handleAvatarSuccess (res, file) {
      this.imageUrl = URL.createObjectURL(file.raw)
    },
    beforeAvatarUpload (file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.popup {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 888;
  width: 100%;
  height: 100%;
  background-color: #081C38;
  box-sizing: border-box;

  .popup-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;

    .title {
      height: 38px;
      line-height: 38px;
      padding: 0 9px;
      text-align: center;
      background-color: #01EEFD;
      box-sizing: border-box;
      color: #fff;
      font-size: 22px;
    }

    .postion {
      width: 246px;
      border-radius: 6px;
      background: linear-gradient(153deg, #0A477B 0%, #0E67AD 100%);
      border: 1px solid #1F72D3;

      /deep/.el-input__inner {
        background-color: transparent;
        color: #fff !important;
        font-size: 18px;
        border: none;

      }

      /deep/ .el-input__suffix {
        color: #fff;
        font-size: 18px;
      }

      /deep/ .el-icon-search {
        display: flex;
        align-items: center;
      }
    }
  }

  .popup-line {
    height: 80px;
    line-height: 80px;
    padding-left: 14px;
    box-sizing: border-box;
    color: #04B4D0;
    font-size: 22px;
  }

  .popup-box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 12px;
    box-sizing: border-box;

    .popup-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 25%;
      margin-bottom: 20px;
      color: #01EEFD;
      font-size: 18px;

      .el-input {
        width: 180px;
      }

      .avatar-uploader {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 180px;
        height: 106px;
        background-color: #FAFAFA;
      }

      .info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        color: #B5B5B5;
        font-size: 16px;
      }

      /deep/.avatar-uploader-icon {
        margin-bottom: 15px;
        font-size: 28px;
        color: #8c939d;
      }
    }

    .popup-item1 {
      width: 50%;
      .el-input {
        width: calc(100% - 200px);
      }
    }
  }

  .btns {
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 70px;

    .el-button {
      margin: 0 25px;
      background-color: #33465C;
      border: none;
      color: #fff;
    }

    .blue {
      background: linear-gradient(108deg, #0174C9 0%, #024176 100%);
    }
  }
}
</style>
