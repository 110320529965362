<template>
  <div class="popup-page">
    <div class="input">
      <div class="input-item">
        <span>疫苗名称：新冠疫苗</span>
      </div>
      <div class="input-item">
        <span>排期时间：2022/3/6  周二</span>
      </div>
      <div class="input-item">
        <span class="key">选择时间</span>
        <el-date-picker type="date" placeholder="选择日期">
        </el-date-picker>
      </div>
      <div class="input-item">
        <span class="key">数量</span>
        <el-input class="input-width" v-model="params.parstr" placeholder="请输入"></el-input>
      </div>
      <el-button class="blue" size="mini">添加</el-button>
    </div>
    <el-table :data="tableData" height="400" :header-cell-style="{ background: '#024276' }">
      <el-table-column prop="regsterpayid" label="时间段">
      </el-table-column>
      <el-table-column prop="sfzh" label="时间">
      </el-table-column>
      <el-table-column prop="type" label="数量">
      </el-table-column>
      <el-table-column label="操作" width="90">
        <!-- slot-scope="scope" -->
        <template>
          <div class="flex">
            <span>编辑</span>
            <span>删除</span>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- <div class="pagination">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
        background :page-size="10" layout="total, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div> -->
    <div class="btns">
      <el-button @click="close">返回</el-button>
      <el-button class="blue" @click="close">确定</el-button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  components: {},
  data () {
    return {
      params: {
        start: '0', // 分页起始
        length: '10', // 分页长度
        ssjgbh: '', // 所属机构编号
        xzjdmcid: '', // 乡镇社区id
        cwhmcid: '', // 村街道id
        fwrq: '2023', // 服务日期
        fwzt: '0', // 服务状态 0全部 1待就诊2已就诊3已取消
        begtime: '', // 开始时间
        endtime: '', // 结束时间
        deptno: '', // 科室
        parstr: '' // 姓名、身份证信息等
      },
      total: 400,
      currentPage: 0,
      tableData: [{}],
      options: [{
        value: '1',
        label: '1'
      }],
      value: '',
      date: '',
      input: '',
      showSee: false,
      selectInfo: {
        id: 0,
        regsterpayid: '',
        hostcode: '',
        title: '',
        remark: '',
        name: '',
        sfzh: '',
        accountid: '',
        wxopenid: '',
        mpopenid: '',
        unioid: '',
        begtime: '',
        endtime: ''
      },
      showUpdate: false,
      examineInfo: {
        id: 0,
        createTime: '',
        updateTime: '',
        regsterpayid: '',
        hostcode: '',
        titletype: '',
        title: '',
        count: '',
        doctor: '',
        doctorcode: '',
        fee: '',
        reghour: '',
        reghalf: '',
        specialty: '',
        remark: '',
        extparam1: '',
        extparam: '',
        deptcode: '',
        deptname: '',
        marktype: '',
        cliniclabel: '',
        registerway: '',
        flagused: '',
        flagavailable: '',
        clinicposition: '',
        clinicfee: '',
        appointcount: '',
        regcount: '',
        dqcode: '',
        day: '',
        deptcode1: '',
        deptcode2: '',
        name: '',
        sfzh: '',
        accountid: '',
        type: ''
      }
    }
  },
  computed: {
    ...mapState(['loginInfo'])
  },
  watch: {

  },
  created () { },
  methods: {
    click () {
    },
    async getList () {
      this.params.ssjgbh = this.loginInfo.jgbh || ''
      // 接口文档 https://app.apifox.com/project/2726972/apis/api-107641382
      const { data: res } = await this.$http.get('/appRegsterMarks', { params: this.params })
      console.log('预约挂号记录列表查询', res)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.tableData = res.data
      // this.tableData = [{
      //   id: 0,
      //   regsterpayid: '11111111',
      //   hostcode: '',
      //   title: '',
      //   remark: '',
      //   name: '这事',
      //   sfzh: '',
      //   accountid: '',
      //   wxopenid: '',
      //   mpopenid: '',
      //   unioid: '',
      //   begtime: '',
      //   endtime: ''
      // }]
      this.total = res.recordsTotal
    },
    typeVlaue (val) {
      if (val !== null) return ['可预约', '预约成功', '正在支付', '取消'][val]
      return ''
    },
    change (e) {
      this.params.begtime = e[0]
      this.params.endtime = e[1]
    },
    openSee (item) {
      this.selectInfo.id = item.id || ''
      // this.selectInfo.regsterpayid = item.regsterpayid || ''
      this.selectInfo.hostcode = item.hostcode || ''
      this.selectInfo.title = item.title || ''
      this.selectInfo.remark = item.remark || ''
      this.selectInfo.name = item.name || ''
      this.selectInfo.sfzh = item.sfzh || ''
      this.selectInfo.accountid = item.accountid || ''
      this.showSee = true
    },
    closeSee () {
      this.showSee = false
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
      this.currentPage = val
      this.params.start = val + ''
      this.getList()
    },
    openUpdate (item) {
      this.examineInfo = { ...this.examineInfo, ...item }
      this.showUpdate = true
    },
    closeUpdate (type) {
      this.showUpdate = false
      if (type === true) {
        this.getList()
      }
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.popup-page {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #081C38;
  box-sizing: border-box;

  .swich {
    display: flex;
    flex-direction: column;
  }

  .head {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;

    .right {
      display: flex;
      flex-direction: row;
      align-items: center;

      .el-button {
        width: 90px;
        font-size: 16px;
      }
    }

    .el-button {
      text-align: center;
      border-radius: 0;
      color: #fff;
      font-size: 22px;
      border: 0;
      margin-right: 20px;
      background-color: #04B4D0;
    }

    .width {
      width: 80px;
      font-size: 18px;
    }

    .el-input {
      width: 246px;
      height: 38px;
      border-radius: 6px;

      /deep/.el-input__inner {
        height: 38px;
        line-height: 38px;
      }
    }

    .postion {
      position: absolute;
      right: 0;
      background: linear-gradient(153deg, #0A477B 0%, #0E67AD 100%);
      border: 1px solid #1F72D3;

      /deep/.el-input__inner {
        background-color: transparent;
        color: #fff !important;
        font-size: 18px;
        border: none;

      }
    }

    /deep/ .el-input__suffix {
      color: #fff;
      font-size: 18px;
    }

    /deep/ .el-icon-search {
      display: flex;
      align-items: center;
    }
  }

  .input {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    height: 78px;
    border-bottom: 1px solid #01EEFD;

    .input-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 30px;
      color: #01EEFD;
      font-size: 16px;

      .key {
        margin-right: 10px;
      }
    }

    .el-button {
      width: 90px;
      height: 38px;
      text-align: center;
      border-radius: 0;
      color: #fff;
      font-size: 16px;
      border: 0;
    }

    .el-radio {
      color: #fff;
    }

    /deep/.el-radio__label {
      font-size: 18px;
    }

    /deep/.el-radio__input.is-checked+.el-radio__label {
      color: #01EEFD;
    }

    /deep/.el-radio__input.is-checked .el-radio__inner {
      background-color: #01EEFD;
      border-color: #01EEFD;
    }

    /deep/.el-radio__inner {
      background-color: transparent;
    }

    .el-select {
      margin-left: 20px;
    }

    .input-width {
      width: 120px;
      margin-right: 20px;
    }

    .el-date-editor {
      margin-left: 20px;
    }

    .blue {
      margin-left: 20px;
      background-color: #04B4D0;
    }

    .search {
      width: 300px;
    }

    .item {
      display: flex;
      align-items: center;
      padding: 10px 0;
      margin-right: 20px;
      color: #01EEFD;
      font-size: 18px;

      .el-radio {
        color: #fff;
      }

      /deep/.el-radio__label {
        font-size: 18px;
      }

      /deep/.el-radio__input.is-checked+.el-radio__label {
        color: #01EEFD;
      }

      /deep/.el-radio__input.is-checked .el-radio__inner {
        background-color: #01EEFD;
        border-color: #01EEFD;
      }

      .span {
        margin-right: 10px;
      }

      .el-input {
        width: 206px;
        height: 38px;
        line-height: 38px;
        background-color: #fff;

        /deep/.el-input__inner {
          height: 38px;
          line-height: 38px;
        }
      }
    }
  }

  ::v-deep .el-table__body-wrapper {
    &::-webkit-scrollbar {
      width: 0px;
    }
  }

  ::v-deep .el-table th.gutter {
    display: none;
    width: 0;
  }

  ::v-deep .el-table colgroup col[name='gutter'] {
    display: none;
    width: 0;
  }

  ::v-deep .el-table__body {
    width: 100% !important;
  }

  .el-table {
    margin-top: 20px;
    background-color: #092B50;
    border: 4px #073F73 solid;
  }

  // 表头文字颜色
  /deep/.el-table__header-wrapper {
    thead {
      th {
        div {
          color: #fff;
          font-size: 17px;
          font-weight: 400;
        }
      }
    }
  }

  //表格内容颜色
  /deep/.el-table__body tbody tr {
    color: #01EEFD;
    background-color: #092B50;
  }

  /deep/.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
    background-color: #092B50;
  }

  /deep/.el-table tbody tr:hover>td {
    background-color: transparent !important
  }

  // 改变边框颜色
  .el-table--border,
  .el-table--group {
    border: 2px solid #ddd !important;
  }

  /**
  改变表格内竖线颜色
   */
  .el-table--border td,
  .el-table--border th,
  .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
    border-right: 1px solid #ddd !important;
  }

  /deep/.el-table td {
    border-bottom: 1px solid #01EEFD;
  }

  /deep/.el-table thead tr th {
    border-color: transparent;
  }

  /deep/ .el-table--border::after,
  /deep/ .el-table--group::after,
  /deep/ .el-table::before {
    background-color: #073F73;
  }

  /deep/.el-table--border {
    border: 1px solid #073F73 !important;
  }

  /deep/.el-table .cell {
    text-align: center;
    color: #01EEFD;
  }

  /deep/.el-checkbox__inner {
    background-color: transparent;
  }

  .flex {
    display: flex;
    justify-content: space-between;
    width: 100%;
    color: #0077F9;

    .img {
      width: 29px;
      height: 25px;
      cursor: pointer;
    }
  }

  .green {
    color: #06B400;
  }

  .yellow {
    color: #FF8014;
  }

  .red {
    color: #FF0000;
  }

  .purple {
    color: #073F73;
    cursor: pointer;
  }

  .gray {
    color: #D2D2D2;
    cursor: pointer;
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 40px;

    ::v-deep.el-pagination__total,
    ::v-deep.el-pagination__jump {
      color: #01EEFD;

      .el-input__inner {
        color: #fff;
        background-color: transparent;
      }
    }

    ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: #01EEFD;
    }
  }

  .btns {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100px;

    .el-button {
      margin-right: 50px;
      background-color: #33465C;
      border: none;
      color: #fff;
    }

    .blue {
      background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
    }
  }
}
</style>
