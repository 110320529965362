var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"popup"},[_c('div',{staticClass:"popup-title"},[_vm._m(0),_c('el-input',{staticClass:"postion",attrs:{"placeholder":"姓名/身份证号","suffix-icon":"el-icon-search"}})],1),_c('div',{staticClass:"popup-scroll"},[_vm._m(1),_c('div',{staticClass:"popup-box"},[_c('div',{staticClass:"popup-item"},[_c('span',[_vm._v("院区名称：")]),_c('el-input',{attrs:{"placeholder":"请输入"}})],1),_c('div',{staticClass:"popup-item"},[_c('span',[_vm._v("联系电话：")]),_c('el-input',{attrs:{"placeholder":"请输入"}})],1),_c('div',{staticClass:"popup-item"},[_c('span',[_vm._v("公交：")]),_c('el-input',{attrs:{"placeholder":"请输入"}})],1),_c('div',{staticClass:"popup-item"},[_c('span',[_vm._v("院区地址：")]),_c('el-input',{attrs:{"placeholder":"请输入"}})],1),_vm._m(2),_vm._m(3),_c('div',{staticClass:"popup-item popup-item1"},[_c('div',{staticClass:"table"},[_vm._m(4),_c('div',{staticClass:"table-scroll"},_vm._l((4),function(item,index){return _c('div',{key:index,staticClass:"tr"},[_c('div',{staticClass:"flex flex1"},[_c('span',[_vm._v(_vm._s(item))])]),_vm._m(5,true),_vm._m(6,true),_vm._m(7,true)])}),0)])]),_c('div',{staticClass:"popup-item popup-item1"},[_c('span',[_vm._v("院区状态：")]),_c('el-switch',{attrs:{"active-color":"#30F05D","inactive-color":"#ff4949"},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1)])]),_c('div',{staticClass:"btns"},[_c('el-button',{staticClass:"blue",on:{"click":_vm.close}},[_vm._v("保存")]),_c('el-button',{on:{"click":_vm.close}},[_vm._v("取消")])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('span',[_vm._v("编辑院区信息")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"popup-line"},[_c('span',[_vm._v("基本信息")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"popup-item"},[_c('span',{staticClass:"icon el-icon-circle-plus"}),_c('span',[_vm._v("新增联系电话")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"popup-item popup-item1"},[_c('div',{staticClass:"map"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"head"},[_c('div',{staticClass:"flex flex1"},[_c('span',[_vm._v("序号")])]),_c('div',{staticClass:"flex flex1"},[_c('span',[_vm._v("楼层索引")])]),_c('div',{staticClass:"flex flex3"},[_c('span',[_vm._v("楼层")])]),_c('div',{staticClass:"flex flex1"},[_c('span',{staticClass:"icon el-icon-circle-plus"}),_c('span',[_vm._v("操作")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex1"},[_c('span',[_vm._v("1楼")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex3"},[_c('span',[_vm._v("总服务台 警务室 收费处 急诊室 综合门诊")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex1 flex2 border"},[_c('span',{staticClass:"icon el-icon-circle-plus"}),_c('span',{staticClass:"icon el-icon-remove"})])
}]

export { render, staticRenderFns }