<template>
  <div class="write">
    <div class="title">
      <span>新增</span>
    </div>
    <div class="content">
      <div @click="close" class="close">
        <span class="el-icon-close"></span>
      </div>
      <div class="box">
        <div class="item item1">
          <span class="span">报警时间</span>
          <el-select v-model="info.date" placeholder="">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <span class="span">设备类型</span>
          <el-select v-model="info.sblb" placeholder="">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <span class="span">位置</span>
          <el-select v-model="info.wz" placeholder="">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item item1">
          <span class="span">距离上次时间 ></span>
          <el-input-number v-model="info.jlscsj" controls-position="right" :min="1" :max="60"></el-input-number>
          <el-select v-model="value" placeholder="" class="width">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item item1">
          <span class="span">报警描述</span>
          <el-input type="textarea" v-model="info.bjms">
          </el-input>

        </div>
      </div>
      <div class="btns">
        <el-button @click="close">取消</el-button>
        <el-button class="button" @click="submit">确认</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      num: 1,
      radio: '1',
      options: [],
      value: '',
      info: {
        id: 0,
        xh: '',
        pzgz: '',
        sblb: '',
        sblbbm: '',
        wz: '',
        bjgz1: '',
        bjgz2: '',
        bjgz3: '',
        bjms: '',
        jlscsj: '',
        jlscsjdw: '',
        state: '',
        ssjgbh: '',
        ssjgmc: '',
        createtime: '',
        updatetime: '',
        zdybjid: '',
        xzjdmc: '',
        xzjdmcid: '',
        cwhmcid: '',
        cwhmc: '',
        sfzh: '',
        date: ''
      }
    }
  },
  methods: {
    // 自定义报警保存
    async submit () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-89057377
      try {
        const { data: res } = await this.$http.post('/zhyyapi/ykdzdybjadd', this.info)
        console.log('自定义报警保存', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.$message.success('保存成功')
      } catch (error) {
        console.log('自定义报警保存请求失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.write {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 888;
  width: 100%;
  height: 100%;
  padding: 0 400px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, .9);

  .title {
    width: 167px;
    height: 53px;
    padding-top: 5px;
    box-sizing: border-box;
    line-height: 53px;
    text-align: center;
    background-image: url('../../img/ssjk/title.png');
    background-size: 100% 100%;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
  }

  .content {
    position: relative;
    width: 100%;
    min-width: 800px;
    height: 500px;
    padding: 50px 65px;
    background-image: url('../../img/ssjk/background4.png');
    background-size: 100% 100%;
    box-sizing: border-box;

    .close {
      position: absolute;
      top: 13px;
      right: 15px;
      color: #fff;
      font-size: 24px;
      cursor: pointer;
    }

    .content-title {
      margin-top: 20px;
      color: #01EEFD;
      font-size: 18px;
    }

    .box {
      display: flex;
      align-items: center;
      justify-content: space-between;

      flex-wrap: wrap;
      width: 100%;
    }

    .item {
      display: flex;
      align-items: center;
      width: 50%;
      margin-top: 20px;
      color: #01EEFD;
      font-size: 16px;

      .span {
        margin-right: 10px;
        white-space: nowrap;
      }

      .span1 {
        width: 108px;
      }

      .el-input {
        width: 150px;
        height: 38px;
      }
    }

    .item1 {
      width: 100%;
    }

    .btns {
      position: absolute;
      bottom: 50px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 240px;
      height: 46px;
      margin: 0 auto;

      .el-button {
        height: 30px;
        line-height: 30px;
        padding: 0 10px;
        text-align: center;
        color: #fff;
        font-size: 14px;
        background-color: #33465C;
        border-radius: 0;
        border: none;
      }

      .button {
        background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
      }
    }
  }

  .el-radio-group {
    margin-top: 6px;
  }

  /deep/.el-radio__input.is-checked .el-radio__inner {
    background-color: #01EEFD;
    border-color: #01EEFD;
  }

  .el-radio {
    color: #fff;
  }

  /deep/.el-radio__input.is-checked+.el-radio__label {
    color: #01EEFD;
  }

  .el-select {
    width: calc(100% - 200px);
  }

  .el-input-number {
    width: 90px;
  }

  .width {
    width: 94px;
    margin-left: 30px;
  }

  /deep/.el-textarea__inner {
    resize: none;
  }

}
</style>
