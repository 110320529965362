<template>
  <div class="schedule">
    <div class="schedule-box">
      <div class="schedule-head">
        <span>查看{{ name }}（{{ sfzh | sfzhType }}）进度</span>
        <span class="el-icon-close close" @click="close"></span>
      </div>
      <div class="schedule-scroll">
        <el-collapse accordion v-model="activeNames" @change="handleChange">
          <el-collapse-item title="既往史" name="1">
            <template slot="title">
              <div class="title">
                <span>既往史</span>
                <span>{{ info.jwsjcqk | scheduleType }}</span>
              </div>
            </template>
            <div class="test">
              <div class="test-item">
                <span>创建时间：{{ info.jwstjrq || '-' }}</span>
              </div>
              <div class="test-item">
                <span>更新时间：{{ info.jwstjrq || '-' }}</span>
              </div>
              <div class="test-item">
                <span>完成时间：{{ info.jwstjrq || '-' }}</span>
              </div>
            </div>
            <div class="test">
              <div class="key">
                <span>先天性心脏病：</span>
              </div>
              <div class="value">
                <span>{{ info.jwsxtxxz | jwsxtxxzType }}</span>
              </div>
            </div>
            <div class="test">
              <div class="key">
                <span>过敏史：</span>
              </div>
              <div class="value">
                <span>{{ info.jwsgms | jwsSelectType(info.gmsqt) }}</span>
              </div>
            </div>
            <div class="test">
              <div class="key">
                <span>传染史：</span>
              </div>
              <div class="value">
                <span>{{ info.jwscrbs | jwsSelectType(info.crsqt) }}</span>
              </div>
            </div>
          </el-collapse-item>
          <el-collapse-item title="内科" name="2">
            <template slot="title">
              <div class="title">
                <span>内科</span>
                <span>{{ info.nkjcqk | scheduleType }}</span>
              </div>
            </template>
            <div class="test">
              <div class="test-item">
                <span>创建时间：{{ info.nktjrq || '-' }}</span>
              </div>
              <div class="test-item">
                <span>更新时间：{{ info.nktjrq || '-' }}</span>
              </div>
              <div class="test-item">
                <span>完成时间：{{ info.nktjrq || '-' }}</span>
              </div>
            </div>
            <div class="test">
              <div class="key">
                <span>肺：</span>
              </div>
              <div class="value">
                <span>{{ info.nkfei | nkSelectType(info.nkfeiqtdes) }}</span>
              </div>
            </div>
            <div class="test">
              <div class="key">
                <span>心脏：</span>
              </div>
              <div class="value">
                <span>{{ info.nkxz | xzSelectType(info.nkxzqtdes) }}</span>
              </div>
            </div>
            <div class="test">
              <div class="key">
                <span>肝：</span>
              </div>
              <div class="value">
                <span>{{ info.nkgz | nkSelectType1 }}</span>
              </div>
            </div>
            <div class="test">
              <div class="key">
                <span>脾：</span>
              </div>
              <div class="value">
                <span>{{ info.nkpz | nkSelectType1 }}</span>
              </div>
            </div>
          </el-collapse-item>
          <el-collapse-item title="外科" name="3">
            <template slot="title">
              <div class="title">
                <span>外科</span>
                <span>{{ info.wkjcqk | scheduleType }}</span>
              </div>
            </template>
            <div class="test">
              <div class="test-item">
                <span>创建时间：{{ info.wktjrq || '-' }}</span>
              </div>
              <div class="test-item">
                <span>更新时间：{{ info.wktjrq || '-' }}</span>
              </div>
              <div class="test-item">
                <span>完成时间：{{ info.wktjrq || '-' }}</span>
              </div>
            </div>
            <div class="test">
              <div class="key">
                <span>头部：</span>
              </div>
              <div class="value">
                <span>{{ info.wktb | tbSelectType(info.wktbqtdes) }}</span>
              </div>
            </div>
            <div class="test">
              <div class="key">
                <span>颈椎：</span>
              </div>
              <div class="value">
                <span>{{ info.wkjz | jzSelectType(info.wkjzqtdes) }}</span>
              </div>
            </div>
            <div class="test">
              <div class="key">
                <span>胸部：</span>
              </div>
              <div class="value">
                <span>{{ info.wkxb | xbSelectType(info.wkxbqtdes) }}</span>
              </div>
            </div>
            <div class="test">
              <div class="key">
                <span>四肢：</span>
              </div>
              <div class="value">
                <span>{{ info.wkjzsz | szSelectType(info.wkjzszqtdes) }}</span>
              </div>
            </div>
            <div class="test">
              <div class="key">
                <span>皮肤：</span>
              </div>
              <div class="value">
                <span>{{ info.wkpf | pfSelectType(info.wkpfqtdes) }}</span>
              </div>
            </div>
            <div class="test">
              <div class="key">
                <span>淋巴结：</span>
              </div>
              <div class="value">
                <span>{{ info.wklbj | lbjSelectType(info.wklbjqtdes) }}</span>
              </div>
            </div>
            <div class="test">
              <div class="key">
                <span>脊柱：</span>
              </div>
              <div class="value">
                <span>{{ info.jzcwflag | wkSelectType1 }}</span>
              </div>
            </div>
          </el-collapse-item>
          <el-collapse-item title="脊柱侧弯" name="4">
            <template slot="title">
              <div class="title">
                <span>脊柱侧弯</span>
                <span>{{ info.jzcwjcqk | scheduleType }}</span>
              </div>
            </template>
            <div class="test">
              <div class="test-item">
                <span>创建时间：{{ info.jzcwtjrq || '-' }}</span>
              </div>
              <div class="test-item">
                <span>更新时间：{{ info.jzcwtjrq || '-' }}</span>
              </div>
              <div class="test-item">
                <span>完成时间：{{ info.jzcwtjrq || '-' }}</span>
              </div>
            </div>
            <div class="test">
              <div class="key">
                <span>躯干旋转角（ATR）胸段：</span>
              </div>
              <div class="unit">
                <span>{{ info.jzcwqgxzjxd || '-' }}°---</span>
              </div>
              <div class="value">
                <span>{{ info.jzcwqgxzjxdzt | jzcwSelectType }}</span>
              </div>
            </div>
            <div class="test">
              <div class="key">
                <span>躯干旋转角（ATR）胸腰段：</span>
              </div>
              <div class="unit">
                <span>{{ info.jzcwqgxzjxyd || '-' }}°---</span>
              </div>
              <div class="value">
                <span>{{ info.jzcwqgxzjxydzt | jzcwSelectType }}</span>
              </div>
            </div>
            <div class="test">
              <div class="key">
                <span>躯干旋转角（ATR）腰段：</span>
              </div>
              <div class="unit">
                <span>{{ info.jzcwqgxzjyd || '-' }}°---</span>
              </div>
              <div class="value">
                <span>{{ info.jzcwqgxzjydzt | jzcwSelectType }}</span>
              </div>
            </div>
          </el-collapse-item>
          <el-collapse-item title="眼科" name="5">
            <template slot="title">
              <div class="title">
                <span>眼科</span>
                <span>{{ info.yankejjcqk | scheduleType }}</span>
              </div>
            </template>
            <div class="test">
              <div class="test-item">
                <span>创建时间：{{ info.yktjrq || '-' }}</span>
              </div>
              <div class="test-item">
                <span>更新时间：{{ info.yktjrq || '-' }}</span>
              </div>
              <div class="test-item">
                <span>完成时间：{{ info.yktjrq || '-' }}</span>
              </div>
            </div>
            <div class="test">
              <div class="key">
                <span>眼外观：</span>
              </div>
              <div class="value">
                <span>{{ info.yankewg | ywgType(info.ywgqt) }}</span>
              </div>
            </div>
            <div class="test">
              <div class="key">
                <span>右眼视力：</span>
              </div>
              <div class="value">
                <span>{{ info.yankeyylysl || '-' }}</span>
              </div>
            </div>
            <div class="test">
              <div class="key">
                <span>左眼视力：</span>
              </div>
              <div class="value">
                <span>{{ info.yankecylysl || '-' }}</span>
              </div>
            </div>
            <div class="test">
              <div class="key">
                <span>右眼矫正视力：</span>
              </div>
              <div class="value">
                <span>{{ info.yankesljzyy || '-' }}</span>
              </div>
            </div>
            <div class="test">
              <div class="key">
                <span>左眼矫正视力：</span>
              </div>
              <div class="value">
                <span>{{ info.yankesljzzy || '-' }}</span>
              </div>
            </div>
            <div class="test">
              <div class="key">
                <span>右眼：</span>
              </div>
              <div class="value">
                <span>{{ info.yankeyy | ykSelectType(info.yankeyyqtdes) }}</span>
              </div>
            </div>
            <div class="test">
              <div class="key">
                <span>左眼：</span>
              </div>
              <div class="value">
                <span>{{ info.yankezy | ykSelectType(info.yankezyqtdes) }}</span>
              </div>
            </div>
            <div class="test">
              <div class="key">
                <span>色盲：</span>
              </div>
              <div class="value">
                <span>{{ info.yankesm | ykSelectType(info.yankesmqtdes) }}</span>
              </div>
            </div>
            <div class="test">
              <div class="key">
                <span>色觉：</span>
              </div>
              <div class="value">
                <span>{{ info.yankesej | ykSelectType(info.yankesjqtdes) }}</span>
              </div>
            </div>
            <div class="test">
              <div class="key">
                <span>右眼屈光球镜s：</span>
              </div>
              <div class="value">
                <span>{{ info.yankeyyqgqjs || '-' }}-</span>
              </div>
              <div class="value">
                <span>{{ info.yankeyyqgqjspj | qkqjSelectType }}</span>
              </div>
            </div>
            <div class="test">
              <div class="key">
                <span>左眼屈光球镜s：</span>
              </div>
              <div class="value">
                <span>{{ info.yankecyqgqjs || '-' }}-</span>
              </div>
              <div class="value">
                <span>{{ info.yankecyqgqjspj | qkqjSelectType }}</span>
              </div>
            </div>
            <div class="test">
              <div class="key">
                <span>右眼屈光柱镜c：</span>
              </div>
              <div class="value">
                <span>{{ info.yankeyyqgzjs || '-' }}-</span>
              </div>
              <div class="value">
                <span>{{ info.yankeyyqgzjspj | qkzjSelectType }}</span>
              </div>
            </div>
            <div class="test">
              <div class="key">
                <span>左眼屈光柱镜c：</span>
              </div>
              <div class="value">
                <span>{{ info.yankecyqgzjs || '-' }}-</span>
              </div>
              <div class="value">
                <span>{{ info.yankecyqgzjspj | qkzjSelectType }}</span>
              </div>
            </div>
            <div class="test">
              <div class="key">
                <span>右眼屈光轴位A：</span>
              </div>
              <div class="value">
                <span>{{ info.yankeyyqtzwa || '-' }}</span>
              </div>
            </div>
            <div class="test">
              <div class="key">
                <span>左眼屈光轴位A：</span>
              </div>
              <div class="value">
                <span>{{ info.yankecyqtzwa || '-' }}</span>
              </div>
            </div>
          </el-collapse-item>
          <el-collapse-item title="耳鼻喉科" name="6">
            <template slot="title">
              <div class="title">
                <span>耳鼻喉科</span>
                <span>{{ info.ebhjcqk | scheduleType }}</span>
              </div>
            </template>
            <div class="test">
              <div class="test-item">
                <span>创建时间：{{ info.ebhktjrq || '-' }}</span>
              </div>
              <div class="test-item">
                <span>更新时间：{{ info.ebhktjrq || '-' }}</span>
              </div>
              <div class="test-item">
                <span>完成时间：{{ info.ebhktjrq || '-' }}</span>
              </div>
            </div>
            <div class="test">
              <div class="key">
                <span>听力(左耳)：</span>
              </div>
              <div class="value">
                <span>{{ info.ebhkzr | tlType(info.ebhkzrqtdes) }}</span>
              </div>
            </div>
            <div class="test">
              <div class="key">
                <span>听力(右耳)：</span>
              </div>
              <div class="value">
                <span>{{ info.ebhkyr | tlType(info.ebhkyrqtdes) }}</span>
              </div>
            </div>
            <div class="test">
              <div class="key">
                <span>外耳道与鼓膜：</span>
              </div>
              <div class="value">
                <span>{{ info.ebhwedygm | wedType(info.wedygmqt) }}</span>
              </div>
            </div>
            <div class="test">
              <div class="key">
                <span>外鼻：</span>
              </div>
              <div class="value">
                <span>{{ info.ebhwb | wbType(info.wbqt) }}</span>
              </div>
            </div>
            <div class="test">
              <div class="key">
                <span>嗅觉：</span>
              </div>
              <div class="value">
                <span>{{ info.ebhxj | xjType(info.xjqt) }}</span>
              </div>
            </div>
          </el-collapse-item>
          <el-collapse-item title="口腔科" name="7">
            <template slot="title">
              <div class="title">
                <span>口腔科</span>
                <span>{{ info.kqjcqk | scheduleType }}</span>
              </div>
            </template>
            <div class="test">
              <div class="test-item">
                <span>创建时间：{{ info.kqktjrq || '-' }}</span>
              </div>
              <div class="test-item">
                <span>更新时间：{{ info.kqktjrq || '-' }}</span>
              </div>
              <div class="test-item">
                <span>完成时间：{{ info.kqktjrq || '-' }}</span>
              </div>
            </div>
            <div class="test">
              <div class="key">
                <span>扁桃体：</span>
              </div>
              <div class="value">
                <span>{{ info.kqbtt | bttType(info.bttqt) }}</span>
              </div>
            </div>
            <div class="test">
              <div class="key">
                <span>牙周：</span>
              </div>
              <div class="value">
                <span>{{ info.kqkkqyz | yzType(info.kqkkqyzqtdes) }}</span>
              </div>
            </div>
            <div class="test">
              <div class="key">
                <span>口腔牙齿数：</span>
              </div>
              <div class="value">
                <span>{{ info.kqkkqyacs || '-' }}颗</span>
              </div>
            </div>
            <div class="test">
              <div class="key">
                <span>缺齿：</span>
              </div>
              <div class="value">
                <span>{{ info.kqkqy | jwsxtxxzType }}</span>
              </div>
            </div>
            <div class="test">
              <div class="key">
                <span>多生牙：</span>
              </div>
              <div class="value">
                <span>{{ info.kqkdsy | jwsxtxxzType }}</span>
              </div>
            </div>
            <div class="test">
              <div class="key">
                <span>口腔龋齿数：</span>
              </div>
              <div class="value">
                <span>{{ info.kqkkqrcs || '-' }}颗</span>
              </div>
            </div>
          </el-collapse-item>
          <el-collapse-item title="形态指标" name="8">
            <template slot="title">
              <div class="title">
                <span>形态指标</span>
                <span>{{ info.xitcqk | scheduleType }}</span>
              </div>
            </template>
            <div class="test">
              <div class="test-item">
                <span>创建时间：{{ info.xtzbtjrq || '-' }}</span>
              </div>
              <div class="test-item">
                <span>更新时间：{{ info.xtzbtjrq || '-' }}</span>
              </div>
              <div class="test-item">
                <span>完成时间：{{ info.xtzbtjrq || '-' }}</span>
              </div>
              <div class="test-item">
                <span>备注：</span>
              </div>
            </div>
            <div class="test">
              <div class="key">
                <span>体重：</span>
              </div>
              <div class="value">
                <span>{{ info.xtzbtz || '-' }}</span>
              </div>
              <div class="unit">
                <span>kg</span>
              </div>
            </div>
            <div class="test">
              <div class="key">
                <span>评价：</span>
              </div>
              <div class="value">
                <span>{{ info.tzpj | tzType }}</span>
              </div>
            </div>
            <div class="test">
              <div class="key">
                <span>身长(高)：</span>
              </div>
              <div class="value">
                <span>{{ info.xtzbsg || '-' }}</span>
              </div>
              <div class="unit">
                <span>cm</span>
              </div>
            </div>
            <div class="test">
              <div class="key">
                <span>评价：</span>
              </div>
              <div class="value">
                <span>{{ info.sgpj | sgType }}</span>
              </div>
            </div>
            <div class="test">
              <div class="key">
                <span>体格发育评价：</span>
              </div>
              <div class="value">
                <span>-</span>
              </div>
            </div>
            <div class="test">
              <div class="key">
                <span>腰围：</span>
              </div>
              <div class="value">
                <span>{{ info.xtzbyw || '-' }}</span>
              </div>
              <div class="unit">
                <span>cm</span>
              </div>
            </div>
            <div class="test">
              <div class="key">
                <span>臀围：</span>
              </div>
              <div class="value">
                <span>{{ info.xtzbtww || '-' }}</span>
              </div>
              <div class="unit">
                <span>cm</span>
              </div>
            </div>
          </el-collapse-item>
          <el-collapse-item title="血压测量" name="9">
            <template slot="title">
              <div class="title">
                <span>血压测量</span>
                <span>{{ info.xyjcqk | scheduleType }}</span>
              </div>
            </template>
            <div class="test">
              <div class="test-item">
                <span>创建时间：{{ info.xytjrq || '-' }}</span>
              </div>
              <div class="test-item">
                <span>更新时间：{{ info.xytjrq || '-' }}</span>
              </div>
              <div class="test-item">
                <span>完成时间：{{ info.xytjrq || '-' }}</span>
              </div>
            </div>
            <div class="test">
              <div class="key">
                <span>收缩压：</span>
              </div>
              <div class="value">
                <span>{{ info.xyssy || '-' }}</span>
              </div>
            </div>
            <div class="test">
              <div class="key">
                <span>评价：</span>
              </div>
              <div class="value">
                <span>{{ info.ssypj | xyType }}</span>
              </div>
            </div>
            <div class="test">
              <div class="key">
                <span>舒张压：</span>
              </div>
              <div class="value">
                <span>{{ info.xyszy || '-' }}</span>
              </div>
            </div>
            <div class="test">
              <div class="key">
                <span>评价：</span>
              </div>
              <div class="value">
                <span>{{ info.szypj | xyType }}</span>
              </div>
            </div>
          </el-collapse-item>
          <el-collapse-item title="肺功能检测" name="10">
            <template slot="title">
              <div class="title">
                <span>肺功能检测</span>
                <span>{{ info.fggjcqk | scheduleType }}</span>
              </div>
            </template>
            <div class="test">
              <div class="test-item">
                <span>创建时间：{{ info.fgntjrq || '-' }}</span>
              </div>
              <div class="test-item">
                <span>更新时间：{{ info.fgntjrq || '-' }}</span>
              </div>
              <div class="test-item">
                <span>完成时间：{{ info.fgntjrq || '-' }}</span>
              </div>
            </div>
            <div class="test">
              <div class="key">
                <span>肺活量：</span>
              </div>
              <div class="value">
                <span>{{ info.fggnjcfhl || '-' }}L</span>
              </div>
            </div>
            <div class="test">
              <div class="key">
                <span>肺功能评估指标：</span>
              </div>
              <div class="value">
                <span>{{ info.fgnpgzb || '-' }}%</span>
              </div>
              <div class="value">
                <span>{{ info.fggpj | fggType }}</span>
              </div>
            </div>
          </el-collapse-item>
          <el-collapse-item title="结合菌素实验" name="11">
            <template slot="title">
              <div class="title">
                <span>结合菌素实验</span>
                <span>{{ info.jhjssyjcqk | scheduleType }}</span>
              </div>
            </template>
            <div class="test">
              <div class="test-item">
                <span>创建时间：{{ info.ppdtjrq || '-' }}</span>
              </div>
              <div class="test-item">
                <span>更新时间：{{ info.ppdtjrq || '-' }}</span>
              </div>
              <div class="test-item">
                <span>完成时间：{{ info.ppdtjrq || '-' }}</span>
              </div>
            </div>
            <div class="test">
              <div class="key">
                <span>PPD结核菌素试验：</span>
              </div>
              <div class="value">
                <span>{{ info.jhjssy | ppdType }}</span>
              </div>
            </div>
          </el-collapse-item>
          <el-collapse-item title="血常规" name="12">
            <template slot="title">
              <div class="title">
                <span>血常规</span>
                <span>{{ info.xcgjcqk | scheduleType }}</span>
              </div>
            </template>
            <div class="test">
              <div class="test-item">
                <span>创建时间：{{ info.xcgtjrq || '-' }}</span>
              </div>
              <div class="test-item">
                <span>更新时间：{{ info.xcgtjrq || '-' }}</span>
              </div>
              <div class="test-item">
                <span>完成时间：{{ info.xcgtjrq || '-' }}</span>
              </div>
            </div>
            <div class="test">
              <div class="key">
                <span>白细胞：</span>
              </div>
              <div class="value">
                <span>{{ info.xcgbxb || '-' }}</span>
              </div>
            </div>
            <div class="test">
              <div class="key">
                <span>红细胞：</span>
              </div>
              <div class="value">
                <span>{{ info.xcghxb || '-' }}</span>
              </div>
            </div>
            <div class="test">
              <div class="key">
                <span>血红蛋白：</span>
              </div>
              <div class="value">
                <span>{{ info.xcgxhdb || '-' }}</span>
              </div>
            </div>
            <div class="test">
              <div class="key">
                <span>血小板：</span>
              </div>
              <div class="value">
                <span>{{ info.xcgxxb || '-' }}</span>
              </div>
            </div>
          </el-collapse-item>
          <el-collapse-item title="血生化" name="13">
            <template slot="title">
              <div class="title">
                <span>血生化</span>
                <span>{{ info.xshjcqk | scheduleType }}</span>
              </div>
            </template>
            <div class="test">
              <div class="test-item">
                <span>创建时间：{{ info.xshtjrq || '-' }}</span>
              </div>
              <div class="test-item">
                <span>更新时间：{{ info.xshtjrq || '-' }}</span>
              </div>
              <div class="test-item">
                <span>完成时间：{{ info.xshtjrq || '-' }}</span>
              </div>
            </div>
            <div class="test">
              <div class="key">
                <span>血清谷丙转氨酶：</span>
              </div>
              <div class="value">
                <span>{{ info.xshxqgb || '-' }}U/L</span>
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      activeNames: [],
      name: '',
      sfzh: '',
      info: {
        id: '',
        name: '',
        tjrq: '',
        xuehao: '',
        xb: '',
        sfjh: '',
        ssxx: '',
        ssxxid: '',
        ssbj: '',
        ssbjid: '',
        ssnj: '',
        ssnjid: '',
        whcd: '',
        whcdid: '',
        ssmc: '',
        ssmcid: '',
        jg: '',
        lxdh: '',
        xzz: '',
        hjdz: '',
        jzxx: '',
        lxrid: '',
        lxr: '',
        lxrxm: '',
        lxrdh: '',
        rxnf: '',
        hcrq: '',
        hcwcrq: '',
        jwsqm: '',
        jwsg: '',
        jwsfjj: '',
        jwsxtxxz: '',
        jwss: '',
        jwsfs: '',
        jwsdfb: '',
        jwsgms: '',
        jwscrbs: '',
        jwsqt: '',
        jwsjcqk: '',
        jwsjcys: '',
        jwsjcysid: '',
        jwspj: '',
        jwstjrq: '',
        jwsflag: '',
        tmcode: '',
        tjbh: '',
        nkfei: '',
        nkqm: '',
        nkfeides: '',
        nkfeiqtdes: '',
        nkfeipj: '',
        nkjcqk: '',
        nkjcys: '',
        nkjcysid: '',
        nkxz: '',
        nkxzdes: '',
        nkxzqtdes: '',
        nkxzpj: '',
        nkfub: '',
        nkfubdes: '',
        nkfubqtdes: '',
        nkfubp: '',
        nkgz: '',
        nkgzdes: '',
        nkgzqtdes: '',
        nkgzpj: '',
        nkpz: '',
        nkpzdes: '',
        nkpzqtdes: '',
        nkpzpj: '',
        nktjrq: '',
        nktjjy: '',
        nkflag: '',
        wkqm: '',
        wkpf: '',
        wkpfdes: '',
        wkpfqtdes: '',
        wkpfpj: '',
        wkjz: '',
        wkjzdes: '',
        wkjzqtdes: '',
        wkjzpj: '',
        wkxb: '',
        wkxbdes: '',
        wkxbqtdes: '',
        wkxbpj: '',
        wkjzsz: '',
        wkjzszdes: '',
        wkjzszqtdes: '',
        wkjzszpj: '',
        wktb: '',
        wktbdes: '',
        wktbqtdes: '',
        wktbpj: '',
        wklbj: '',
        wklbjdes: '',
        wklbjqtdes: '',
        wklbjpj: '',
        wkwszq: '',
        wkwszqdes: '',
        wkwszqqtdes: '',
        wkwszqpj: '',
        wkfzjcqtdes: '',
        wkjcys: '',
        wkjcysid: '',
        wkjcqk: '',
        wktjrq: '',
        wktjjy: '',
        wkflag: '',
        jzcwqgxzjxd: '',
        jzcwqgxzjxdzt: '',
        jzcwqgxzjxdztdes: '',
        jzcwqgxzjxydztdes: '',
        jzcwqgxzjydztdes: '',
        jzcwqgxzjxydzt: '',
        jzcwqgxzjydzt: '',
        jzcwjcqk: '',
        jzcwtjjl: '',
        jzcwqm: '',
        jzcwqgxzjxyd: '',
        jzcwqgxzjyd: '',
        jzcwckjl: '',
        jzcwpj: '',
        jzcwys: '',
        jzcwysid: '',
        jzcwtjrq: '',
        jzcwtjjy: '',
        jzcwflag: '',
        yankesm: '',
        yankeqm: '',
        yankesmdes: '',
        yankesmqtdes: '',
        yankezy: '',
        yankezydes: '',
        yankezyqtdes: '',
        yankesej: '',
        yankesjdes: '',
        yankesjqtdes: '',
        yankeyy: '',
        yankeyydes: '',
        yankeyyqtdes: '',
        yankecyqgd: '',
        yankecyqgddes: '',
        yankecyqgdqtdes: '',
        yankeyyqgd: '',
        yankeyyqgddes: '',
        yankeyyqgdqtdes: '',
        yankesy: '',
        yankesydes: '',
        yankesyqtdes: '',
        yankecylysl: '',
        yankeyylysl: '',
        yankewg: '',
        yankewgdes: '',
        yankesljzzy: '',
        yankesljzyy: '',
        yankecyqgqjs: '',
        yankeyyqgqjspj: '',
        yankeyyqgqjspjdes: '',
        yankeyyqgzjspj: '',
        yankeyyqgzjspjdes: '',
        yankecyqgqjspj: '',
        yankecyqgqjspjdes: '',
        yankecyqgzjspj: '',
        yankecyqgzjspjdes: '',
        yankeyyqgqjs: '',
        yankecyqgzjs: '',
        yankeyyqgzjs: '',
        yankecyqtzwa: '',
        yankeyyqtzwa: '',
        yankejcys: '',
        yankejcysid: '',
        yankejjcqk: '',
        yktjrq: '',
        yktjjy: '',
        ykflag: '',
        ebhkzr: '',
        ebhkqm: '',
        ebhkzrdes: '',
        ebhkzrqtdes: '',
        ebhkyr: '',
        ebhkyrdes: '',
        ebhkyrqtdes: '',
        ebhyhb: '',
        ebhbk: '',
        ebhbkdes: '',
        ebhbkqtdes: '',
        ebhjcys: '',
        ebhjcysid: '',
        ebhjcqk: '',
        ebhwedygm: '',
        ebhwedygmdes: '',
        ebhwbdes: '',
        ebhxjdes: '',
        ebhwb: '',
        ebhxj: '',
        ebhktjrq: '',
        ebhktjjy: '',
        ebhkflag: '',
        kqkkqyacs: '',
        kqkqm: '',
        kqkkqrcs: '',
        kqqcsflag: '',
        kqkkqcs: '',
        kqbtt: '',
        kqbttdes: '',
        kqkrrs: '',
        kqkrrb: '',
        kqkhrs: '',
        kqkhrh: '',
        kqkhrb: '',
        kqkkqyz: '',
        kqkkqyzdes: '',
        kqkkqyzqtdes: '',
        kqkjcys: '',
        kqkjcysid: '',
        kqjcqk: '',
        kqktjrq: '',
        kqktjjy: '',
        kqktjjl: '',
        kqkflag: '',
        xtzbtz: '',
        xtzbqm: '',
        xtzbsg: '',
        xtzbtgfypj: '',
        bmi: '',
        xtzbyw: '',
        xtzbtww: '',
        xtzbytb: '',
        xtzbbml: '',
        xitcqk: '',
        xtjcys: '',
        xtjcysid: '',
        xtzbtjrq: '',
        xtzbtjjy: '',
        xtzbflag: '',
        xyssy: '',
        xyqm: '',
        xyszy: '',
        xyml: '',
        xyjcqk: '',
        xyjcys: '',
        xyjcysid: '',
        xytjrq: '',
        xytjjy: '',
        xyflag: '',
        tlsctlz: '',
        tlqm: '',
        tlsctlzdes: '',
        tlsctlzqtdes: '',
        tlsctly: '',
        tlsctlydes: '',
        tlsctlyqtdes: '',
        tljcys: '',
        tljcysid: '',
        tljcqk: '',
        fhlqm: '',
        fggnjcfhl: '',
        fgnpgzb: '',
        fggjcqk: '',
        fggpj: '',
        fggjcys: '',
        fggjcysid: '',
        fgntjrq: '',
        fgntjjy: '',
        fgnflag: '',
        xcgqm: '',
        xcgbxb: '',
        bxbpj: '',
        hxbpj: '',
        xhdbpj: '',
        xxbpj: '',
        xcgpj: '',
        xcgpjdes: '',
        xcgxhdb: '',
        xcgxhdbdes: '',
        xcgxxb: '',
        xcgxxbdes: '',
        xcghxb: '',
        xcghxbdes: '',
        xcgjcqk: '',
        xcgjcys: '',
        xcgjcysid: '',
        ppdqm: '',
        ppdtjrq: '',
        ppdtjjy: '',
        ppdtjdes: '',
        ppdflag: '',
        ppdjcys: '',
        ppdjcysid: '',
        xcgtjrq: '',
        xcgtjjy: '',
        xcgflag: '',
        xshqm: '',
        xshtjrq: '',
        xshtjjy: '',
        xshflag: '',
        xshpjdes: '',
        xshxqgb: '',
        xshxqgbpj: '',
        xshxqgbpjdes: '',
        xshxqgc: '',
        xshxqdhs: '',
        xshbasanzym: '',
        xshjcqk: '',
        xshjcys: '',
        xshjcysid: '',
        jhjssy: '',
        jhjssydes: '',
        jhjssyjcqk: '',
        ncgndb: '',
        ncgnt: '',
        ncgntt: '',
        ncgnqx: '',
        ncgjcys: '',
        ncgjcysid: '',
        ncgjcqk: '',
        xlcpsfcp: '',
        xlcpjg: '',
        xlcpjgdes: '',
        xlcpqk: '',
        xlcpys: '',
        xlcpysid: '',
        tjycxj: '',
        tjycxjys: '',
        tjycxjysid: '',
        tjycyj: '',
        tjjkzd: '',
        tjyyzd: '',
        tjydzn: '',
        tjwxts: '',
        tjtcid: '',
        tjtcmc: '',
        czry: '',
        czryid: '',
        tjjd: '',
        tjjdid: '',
        tjxmlb: '',
        tjxmlbid: '',
        tjzt: '',
        bgkfflag: '',
        unionid: '',
        openid: '',
        dnbh: '',
        gmsqt: '',
        crsqt: '',
        ywgqt: '',
        yktjjl: '',
        wedygmqt: '',
        wbqt: '',
        xjqt: '',
        bttqt: '',
        kqkqy: '',
        kqkdsy: '',
        sgpj: '',
        sgpjdes: '',
        tzpj: '',
        tzpjdes: '',
        bmipj: '',
        bmipjdes: '',
        ytbpj: '',
        ytbpjdes: '',
        ssypj: '',
        ssypjdes: '',
        szypj: '',
        szypjdes: '',
        mlpj: '',
        mlpjdes: '',
        age: '',
        sfzh: '',
        tjjgbh: '',
        tjjgmc: '',
        zjys: '',
        zjysqm: '',
        lssjsg: '',
        lssjtz: '',
        ssjgbh: '',
        ssjgmc: '',
        sfyy: '',
        bgpwd: '',
        bgurl: '',
        csrq: ''
      }
    }
  },
  filters: {
    sfzhType (val) {
      if (!val) return '-'
      return val.replace(/(\d{1})\d{15}(\d{2})/, '$1***************$2')
    },
    scheduleType (val) {
      if (val === '' || val === null) return '-'
      const arr = ['正常检查', '尚未检查', '无需检查', '自愿放弃']
      return arr[val]
    },
    sgType (val) {
      if (val === null || val === '') return '-'
      const arr = ['正常', '偏矮', '偏高']
      return arr[val]
    },
    tzType (val) {
      if (val === null || val === '') return '-'
      const arr = ['正常', '较轻', '超重']
      return arr[val]
    },
    jwsxtxxzType (val) {
      if (val === '1') return '是'
      return '否'
    },
    jwsSelectType (val, text) {
      if (val === '0') return '未发现'
      if (val === '99') return text
      return '-'
    },
    nkSelectType (val, text) {
      if (val === '0') return '未见异常'
      if (val === '1') return '异常'
      if (val === '99') return text
      return '-'
    },
    xzSelectType (val, text) {
      if (val === '0') return '未见异常'
      if (val === '1') return '心律异常'
      if (val === '2') return '异常心音'
      if (val === '3') return '有杂音'
      if (val === '99') return text
      return '-'
    },
    nkSelectType1 (val) {
      if (val === '0') return '未触及'
      if (val === '1') return '触及'
      return '-'
    },
    wkSelectType (val, text) {
      if (val === '0') return '未见异常'
      if (val === '1') return '异常'
      if (val === '99') return text
      return '-'
    },
    wkSelectType1 (val) {
      if (val === '0') return '未见异常'
      if (val === '1') return '异常'
      return '-'
    },
    tbSelectType (val, text) {
      if (val === '0') return '未见异常'
      if (val === '1') return '畸形'
      if (val === '99') return text
      return '-'
    },
    jzSelectType (val, text) {
      if (val === '0') return '未见异常'
      if (val === '1') return '颈部偏曲'
      if (val === '2') return '甲状腺肿大'
      if (val === '3') return '有结节'
      if (val === '99') return text
      return '-'
    },
    xbSelectType (val, text) {
      if (val === '0') return '未见异常'
      if (val === '1') return '胸廓畸形'
      if (val === '2') return '胸廓局部隆起'
      if (val === '3') return '胸廓压痛'
      if (val === '4') return '异常搏动'
      if (val === '5') return '器官左右偏'
      if (val === '99') return text
      return '-'
    },
    szSelectType (val, text) {
      if (val === '0') return '未见异常'
      if (val === '1') return '畸形'
      if (val === '2') return '杵状指'
      if (val === '3') return '关节红肿'
      if (val === '4') return '关节疼痛'
      if (val === '5') return '活动受限'
      if (val === '99') return text
      return '-'
    },
    pfSelectType (val, text) {
      if (val === '0') return '未见异常'
      if (val === '1') return '颜色（潮红、苍白、发绀、黄染、色素沉着）'
      if (val === '2') return '水肿'
      if (val === '3') return '出血'
      if (val === '4') return '皮疹'
      if (val === '5') return '有瘢痕'
      if (val === '99') return text
      return '-'
    },
    lbjSelectType (val, text) {
      if (val === '0') return '未见异常'
      if (val === '1') return '浅表淋巴结-颌下'
      if (val === '2') return '浅表淋巴结-颈部'
      if (val === '3') return '浅表淋巴结-腋窝'
      if (val === '99') return text
      return '-'
    },
    jzcwSelectType (val) {
      if (val === '0') return '正常'
      if (val === '1') return '异常'
      return '-'
    },
    ywgType (val, text) {
      if (val === '0') return '未见异常'
      if (val === '1') return '眼睑闭合不全'
      if (val === '2') return '睑内翻'
      if (val === '3') return '睑外翻'
      if (val === '4') return '结膜炎'
      if (val === '5') return '睑腺炎'
      if (val === '6') return '角膜炎'
      if (val === '99') return text
      return '-'
    },
    ykSelectType (val, text) {
      if (val === '0') return '未见异常'
      if (val === '1') return '异常'
      if (val === '99') return text
      return '-'
    },
    qkqjSelectType (val) {
      if (val === '0') return '正常'
      if (val === '1') return '近视'
      if (val === '2') return '远视'
      return '-'
    },
    qkzjSelectType (val) {
      if (val === '0') return '正常'
      if (val === '1') return '近视散光'
      if (val === '2') return '远视散光'
      return '-'
    },
    tlType (val, text) {
      if (val === '0') return '未见异常'
      if (val === '1') return '听力下降？'
      if (val === '99') return text
      return '-'
    },
    wedType (val, text) {
      if (val === '0') return '未见异常'
      if (val === '1') return '鼓膜穿孔'
      if (val === '2') return '真菌性鼓膜炎'
      if (val === '3') return '耳痛伴听力障碍'
      if (val === '4') return '耳溢液'
      if (val === '99') return text
      return '-'
    },
    wbType (val, text) {
      if (val === '0') return '未见异常'
      if (val === '1') return '外鼻畸形'
      if (val === '2') return '外鼻皮肤异常'
      if (val === '3') return '鼻出血'
      if (val === '99') return text
      return '-'
    },
    xjType (val, text) {
      if (val === '0') return '未见异常'
      if (val === '1') return '嗅觉减退'
      if (val === '2') return '嗅觉丧失'
      if (val === '3') return '嗅觉过敏'
      if (val === '4') return '嗅觉倒错'
      if (val === '5') return '幻嗅'
      if (val === '99') return text
      return '-'
    },
    bttType (val, text) {
      if (val === '0') return '未见异常'
      if (val === '1') return '扁桃体肿大I'
      if (val === '2') return '扁桃体肿大II'
      if (val === '3') return '扁桃体肿大III'
      if (val === '99') return text
      return '-'
    },
    yzType (val, text) {
      if (val === '0') return '未见异常'
      if (val === '1') return '牙龈炎'
      if (val === '99') return text
      return '-'
    },
    xyType (val) {
      if (val === '0') return '血压正常'
      if (val === '1') return '血压偏低'
      if (val === '2') return '血压偏高'
      return '-'
    },
    mlType (val) {
      if (val === '0') return '脉率正常'
      if (val === '1') return '脉率偏低'
      if (val === '2') return '脉率偏高'
      return '-'
    },
    fggType (val) {
      if (val === '0') return '正常'
      if (val === '1') return '肺活量不足'
      return '-'
    },
    ppdType (val) {
      if (val === '0') return '阴性(无反应或&lt5mm)'
      if (val === '1') return '一般阳性(≥5mm&lt10mm)'
      if (val === '2') return '中阳性(≥10mm&lt15mm)'
      if (val === '3') return '强阳性(≥15mm)'
      return '-'
    }
  },
  created () {
    this.name = this.$parent.selectInfo.name || ''
    this.sfzh = this.$parent.selectInfo.sfzh || ''
    this.getInfo()
  },
  methods: {
    async getInfo () {
      // 接口文档 https://app.apifox.com/project/2726972/apis/api-159174148
      try {
        const params = {
          tmcode: this.$parent.selectInfo.tmcode
        }
        const { data: res } = await this.$http.post('/zhctapi/findtmjl', params)
        console.log('根据条码获取信息', res)
        if (res.code !== 0) {
          return this.$message({
            message: res.msg || '暂无信息',
            type: 'warning'
          })
        }
        this.info = res.data
      } catch (err) {
        console.log('根据条码获取信息', err)
      }
    },
    handleChange (val) {
      console.log(val)
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.schedule {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .69);

  .schedule-box {
    width: 60%;
    height: 90%;
    padding: 0 30px;
    background-color: #fff;
    border-radius: 6px;
    box-sizing: border-box;

    .schedule-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 65px;
      border-bottom: 1px solid #B1B1B1;
      box-sizing: border-box;
      color: #2A374D;
      font-size: 18px;
      font-weight: 600;

      .close {
        color: #707070;
        font-size: 30px;
        cursor: pointer;
      }
    }

    .schedule-scroll {
      overflow-y: scroll;
      width: 100%;
      height: calc(100% - 90px);

      &::-webkit-scrollbar {
        width: 0;
      }

      .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: calc(100% - 40px);
        color: #2A374D;
        font-size: 18px;
      }

      /deep/.el-icon-arrow-right:before {
        font-size: 20px;
      }

      .test {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin-bottom: 10px;

        .test-item {
          display: flex;
          margin-right: 20px;
          font-size: 16px;
        }

        .test-item:last-child {
          width: 100%;
        }

        .key {
          width: 260px;
          font-size: 16px;
        }

        .value {
          font-size: 16px;
        }

        .unit {
          font-size: 16px;
        }
      }
    }
  }
}
</style>
