<template>
  <div class="registration">
    <div class="son-tab">
      <span class="son-item son-active">健康管理档案</span>
      <el-input placeholder="姓名/身份证号" suffix-icon="el-icon-search" class="see-search">
      </el-input>
    </div>
    <div class="son-tab1">
      <span class="son-item1" :class="{ 'son-active1': sonTabIndex === index }" v-for="(item, index) in sonTabs"
        :key="index" @click="sonTab(index)">{{ item }}</span>
    </div>
    <div class="son-scroll" v-show="sonTabIndex === 0">
      <div class="son-title">
        <span>基本信息</span>
      </div>
      <div class="son-line">
        <div class="key">
          <span>姓名</span>
        </div>
        <div class="value">
          <span>张三</span>
        </div>
      </div>
      <div class="son-line">
        <div class="key">
          <span>性别</span>
        </div>
        <div class="value">
          <span>男</span>
        </div>
      </div>
      <div class="son-line">
        <div class="key">
          <span>民族</span>
        </div>
        <div class="value">
          <span>汉</span>
        </div>
      </div>
      <div class="son-line">
        <div class="key">
          <span>籍贯</span>
        </div>
        <div class="value">
          <span>江苏 苏州</span>
        </div>
      </div>
      <div class="son-line">
        <div class="key">
          <span>出生年月</span>
        </div>
        <div class="value">
          <span>1923-11-11</span>
        </div>
      </div>
      <div class="son-line">
        <div class="key">
          <span>家庭住址</span>
        </div>
        <div class="value">
          <span>江苏 苏州</span>
        </div>
      </div>
      <div class="son-line">
        <div class="key">
          <span>联系方式</span>
        </div>
        <div class="value">
          <span>15803339264</span>
        </div>
      </div>
      <div class="son-line">
        <div class="key">
          <span>护理等级</span>
        </div>
        <div class="value">
          <span>重度</span>
        </div>
      </div>
      <div class="son-line">
        <div class="key">
          <span>入院日期</span>
        </div>
        <div class="value">
          <span>1923-11-11</span>
        </div>
      </div>
      <div class="son-line">
        <div class="key">
          <span>床位号</span>
        </div>
        <div class="value">
          <span>501-09</span>
        </div>
      </div>
      <div class="son-title son-title1">
        <span>疾病介绍</span>
      </div>
      <div class="son-title2">
        <span>现病史</span>
      </div>
      <el-checkbox-group v-model="checkList">
        <el-checkbox class="checkbox" v-for="(item, index) in list" :key="index" :label="index + ''">{{ item
        }}</el-checkbox>
      </el-checkbox-group>
      <div class="son-title2">
        <span>既往病史</span>
      </div>
      <el-checkbox-group v-model="checkList">
        <el-checkbox class="checkbox" v-for="(item, index) in list" :key="index" :label="index + ''">{{ item
        }}</el-checkbox>
      </el-checkbox-group>
      <div class="son-title2">
        <span>其他疾病</span>
      </div>
      <el-input type="textarea" placeholder="">
      </el-input>
      <div class="son-title2">
        <span>体格检查</span>
      </div>
      <div class="son-line">
        <div class="son-key">
          <span>T:</span>
        </div>
        <el-input placeholder="mmHg" class="son-input son-margin"></el-input>
        <div class="son-key">
          <span>R:</span>
        </div>
        <el-input placeholder="mmHg" class="son-input"></el-input>
      </div>
      <div class="son-line">
        <div class="son-key">
          <span>P:</span>
        </div>
        <el-input placeholder="mmHg" class="son-input son-margin"></el-input>
        <div class="son-key">
          <span>BP:</span>
        </div>
        <el-input placeholder="mmHg" class="son-input"></el-input>
      </div>
      <div class="son-title2">
        <span>一般情况</span>
      </div>
      <div class="son-line">
        <div class="son-key">
          <span>发育</span>
        </div>
        <el-input placeholder="mmHg" class="son-input son-margin"></el-input>
        <div class="son-key">
          <span>营养</span>
        </div>
        <el-input placeholder="mmHg" class="son-input son-margin"></el-input>
        <div class="son-key">
          <span>体味</span>
        </div>
        <el-input placeholder="mmHg" class="son-input son-margin"></el-input>
        <div class="son-key">
          <span>步态</span>
        </div>
        <el-input placeholder="mmHg" class="son-input son-margin"></el-input>
        <div class="son-key">
          <span>神态</span>
        </div>
        <el-input placeholder="mmHg" class="son-input"></el-input>
      </div>
      <div class="son-line">
        <div class="son-key">
          <span>发育</span>
        </div>
        <el-input placeholder="mmHg" class="son-input son-margin"></el-input>
        <div class="son-key">
          <span>神态</span>
        </div>
        <el-input placeholder="mmHg" class="son-input"></el-input>
      </div>
      <div class="son-line son-line1">
        <div class="son-key1">
          <span>全身皮肤黏膜皮疹</span>
        </div>
        <el-checkbox-group v-model="checkList">
          <el-checkbox label="0">是</el-checkbox>
          <el-checkbox label="1">否</el-checkbox>
        </el-checkbox-group>
      </div>
      <div class="son-line">
        <div class="son-key1">
          <span>出血点</span>
        </div>
        <el-checkbox-group v-model="checkList">
          <el-checkbox label="0">是</el-checkbox>
          <el-checkbox label="1">否</el-checkbox>
        </el-checkbox-group>
      </div>
      <div class="son-line">
        <div class="son-key1">
          <span>水肿</span>
        </div>
        <el-checkbox-group v-model="checkList">
          <el-checkbox label="0">是</el-checkbox>
          <el-checkbox label="1">否</el-checkbox>
        </el-checkbox-group>
      </div>
      <div class="son-line">
        <div class="son-key1">
          <span>黄染</span>
        </div>
        <el-checkbox-group v-model="checkList">
          <el-checkbox label="0">是</el-checkbox>
          <el-checkbox label="1">否</el-checkbox>
        </el-checkbox-group>
      </div>
      <div class="son-line">
        <div class="son-key1">
          <span>全身浅表淋巴结门及肿大</span>
        </div>
        <el-checkbox-group v-model="checkList">
          <el-checkbox label="0">是</el-checkbox>
          <el-checkbox label="1">否</el-checkbox>
        </el-checkbox-group>
      </div>
      <div class="son-line">
        <div class="son-key1">
          <span>眼睑水肿</span>
        </div>
        <el-checkbox-group v-model="checkList">
          <el-checkbox label="0">是</el-checkbox>
          <el-checkbox label="1">否</el-checkbox>
        </el-checkbox-group>
      </div>
      <div class="son-line">
        <div class="son-key1">
          <span>巩膜黄染</span>
        </div>
        <el-checkbox-group v-model="checkList">
          <el-checkbox label="0">是</el-checkbox>
          <el-checkbox label="1">否</el-checkbox>
        </el-checkbox-group>
      </div>
      <div class="son-line">
        <div class="son-key1">
          <span>口唇发绀</span>
        </div>
        <el-checkbox-group v-model="checkList">
          <el-checkbox label="0">是</el-checkbox>
          <el-checkbox label="1">否</el-checkbox>
        </el-checkbox-group>
      </div>
      <div class="son-line">
        <div class="son-key1">
          <span>双侧扁桃体肿大</span>
        </div>
        <el-checkbox-group v-model="checkList">
          <el-checkbox label="0">是</el-checkbox>
          <el-checkbox label="1">否</el-checkbox>
        </el-checkbox-group>
      </div>
      <div class="son-line">
        <div class="son-key1">
          <span>静脉怒张</span>
        </div>
        <el-checkbox-group v-model="checkList">
          <el-checkbox label="0">是</el-checkbox>
          <el-checkbox label="1">否</el-checkbox>
        </el-checkbox-group>
      </div>
      <div class="son-line">
        <div class="son-key1">
          <span>双侧甲状腺门及明显肿大</span>
        </div>
        <el-checkbox-group v-model="checkList">
          <el-checkbox label="0">是</el-checkbox>
          <el-checkbox label="1">否</el-checkbox>
        </el-checkbox-group>
      </div>
      <div class="son-line">
        <div class="son-key1">
          <span>文及干</span>
        </div>
        <el-checkbox-group v-model="checkList">
          <el-checkbox label="0">是</el-checkbox>
          <el-checkbox label="1">否</el-checkbox>
        </el-checkbox-group>
      </div>
      <div class="son-title2">
        <span>实验室检查</span>
      </div>
      <el-input type="textarea" placeholder=""></el-input>
      <div class="son-title2">
        <span>初步诊断</span>
      </div>
      <el-input type="textarea" placeholder="">
      </el-input>
      <div class="son-title2">
        <span>诊疗计划</span>
      </div>
      <el-input type="textarea" placeholder="">
      </el-input>
    </div>
    <div class="son-scroll" v-show="sonTabIndex === 1">
      <div class="son-title3">
        <span>新增入院记录</span>
      </div>
      <el-timeline>
        <el-timeline-item timestamp="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet
 lacus accumsan et viverra justo commodo. Proin sodales pulvinar tempor. Cum sociis natoque penatibus et magnis dis
parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra vulputate, felis tellus mollis orci, sed rhoncus
sapien nunc eget odio. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida
 dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar tempor. Cum sociis natoque penatibus et magnis
dis parturient montes,">
          2020-02-02 15：00 张三
        </el-timeline-item>
        <el-timeline-item timestamp="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet
 lacus accumsan et viverra justo commodo. Proin sodales pulvinar tempor. Cum sociis natoque penatibus et magnis dis
parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra vulputate, felis tellus mollis orci, sed rhoncus
sapien nunc eget odio. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida
 dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar tempor. Cum sociis natoque penatibus et magnis
dis parturient montes,">
          2020-02-02 15：00 张三
        </el-timeline-item>
      </el-timeline>
    </div>
    <div class="son-scroll" v-show="sonTabIndex === 2">
      <div class="son-title3">
        <span>新增出院记录</span>
      </div>
      <el-timeline>
        <el-timeline-item timestamp="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet
 lacus accumsan et viverra justo commodo. Proin sodales pulvinar tempor. Cum sociis natoque penatibus et magnis dis
parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra vulputate, felis tellus mollis orci, sed rhoncus
sapien nunc eget odio. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida
 dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar tempor. Cum sociis natoque penatibus et magnis
dis parturient montes,">
          2020-02-02 15：00 张三
        </el-timeline-item>
        <el-timeline-item timestamp="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet
 lacus accumsan et viverra justo commodo. Proin sodales pulvinar tempor. Cum sociis natoque penatibus et magnis dis
parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra vulputate, felis tellus mollis orci, sed rhoncus
sapien nunc eget odio. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida
 dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar tempor. Cum sociis natoque penatibus et magnis
dis parturient montes,">
          2020-02-02 15：00 张三
        </el-timeline-item>
      </el-timeline>
    </div>
    <div class="son-scroll" v-show="sonTabIndex === 3">
      <div class="run-search">
        <span>上传报告</span>
      </div>
      <el-table :data="tableData" height="50%">
        <el-table-column label="序号" type="index" width="120">
        </el-table-column>
        <el-table-column prop="name" label="上传时间">
        </el-table-column>
        <el-table-column prop="address" label="上传人">
        </el-table-column>
        <el-table-column label="操作" width="120">
          <template>
            <div class="table-run">
              <span @click="openSee">查看</span>
            </div>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage4"
        :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper" :total="400">
      </el-pagination>
    </div>
    <div class="see-close">
      <el-button size="small" @click="close">返回</el-button>
      <!-- <el-button class="see-blue" size="small" @click="close">保存</el-button> -->
    </div>
    <See v-if="showSee" @close="closeSee"></See>
  </div>
</template>

<script>
import See from './see.vue'
export default {
  components: {
    See
  },
  data () {
    return {
      tableData: [{
        date: '2016-05-02',
        name: '王小虎',
        address: '等待外出'
      }, {
        date: '2016-05-04',
        name: '王小虎',
        address: '未返回'
      }, {
        date: '2016-05-01',
        name: '王小虎',
        address: '逾期未返回'
      }],
      options: [{
        value: '',
        label: ''
      }],
      value: '',
      currentPage4: 4,
      tabIndex: 0,
      selectIndex: 0,
      showAdd: false,
      showExtension: false,
      showSee: false,
      sonTabIndex: 0,
      sonTabs: Object.freeze(['健康档案', '入院记录', '出院记录', '体检报告']),
      checkList: [],
      list: Object.freeze(['高血压', '脑血管意外', '心肌梗死', '帕金森氏症', '抑郁倾向', '老年性痴呆', '癫痫', '肺气肿', '慢性消化系统疾病', '泌尿系统', '糖尿病', '皮肤病', '肿瘤', '肢体骨折', '骨质疏松', '白内障', '青光眼', '弱视', '失视', '听力受损（中）', '听力受损（重）', '失聪'])
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    },
    sonTab (index) {
      this.sonTabIndex = index
    },
    openAdd () {
      this.showAdd = true
    },
    closeAdd () {
      this.showAdd = false
    },
    openExtension () {
      this.showExtension = true
    },
    closeExtension () {
      this.showExtension = false
    },
    openSee () {
      this.showSee = true
    },
    closeSee () {
      this.showSee = false
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.registration {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  padding: 0 20px;
  background-color: #081C38;
  box-sizing: border-box;

  .son-tab {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;

    .son-item {
      height: 38px;
      line-height: 38px;
      margin-right: 40px;
      padding: 0 20px;
      text-align: center;
      background-color: #284867;
      color: #fff;
      font-size: 16px;
      cursor: pointer;
    }

    .son-active {
      background-color: #04B4D0;
    }
  }

  .see-search {
    width: 246px;

    /deep/.el-input__inner {
      border: none;
      background: linear-gradient(153deg, #0A477B 0%, #0E67AD 100%);
      color: #fff;
      font-size: 18px;
    }

    /deep/.el-input__inner::-webkit-input-placeholder {
      color: #fff;
    }

    /deep/.el-input__suffix {
      color: #fff;
      font-size: 18px;
    }
  }

  .son-tab1 {
    display: flex;
    align-items: center;
    width: 100%;
    height: 60px;
    padding-left: 12px;
    box-sizing: border-box;

    .son-item1 {
      margin-right: 40px;
      border-bottom: 1px solid transparent;
      color: #fff;
      font-size: 22px;
      cursor: pointer;
    }

    .son-active1 {
      color: #04B4D0;
      border-bottom: 1px solid #04B4D0;
    }
  }

  .run-search {
    display: flex;
    align-items: center;
    width: 100%;
    height: 60px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;
    color: #04B4D0;
    font-size: 18px;
  }

  .el-table {
    margin-top: 10px;
    border: 4px solid #073F73;
    background-color: #081C38;
  }

  /deep/.el-table__body-wrapper {
    background-color: #081C38;
  }

  /deep/.el-table th.el-table__cell.is-leaf {
    border-bottom: none;
  }

  /deep/.el-table th.el-table__cell {
    background-color: #024276;
  }

  /deep/.el-table .cell {
    text-align: center;
    color: #01EEFD;
  }

  /deep/.el-table tr {
    background-color: #081C38;
  }

  /deep/.el-table td.el-table__cell {
    border-bottom: 1px solid #01EEFD;
  }

  .el-table::before {
    height: 0;
  }

  /deep/.el-table tbody tr:hover>td {
    background-color: transparent;
  }

  /deep/.el-checkbox__inner {
    background-color: transparent;
  }

  .table-run {
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      cursor: pointer;
    }
  }

  .el-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;

    /deep/.el-pagination__total {
      color: #01EEFD;
    }

    /deep/.el-pagination__jump {
      color: #01EEFD;
    }

    /deep/.el-input__inner {
      background-color: transparent;
      color: #01EEFD;
    }

    /deep/.number {
      margin-left: 10px;
      background-color: #ADADAD;
      color: #fff;
    }

    /deep/.active {
      background-color: #01EEFD;
    }

    /deep/.el-pagination .btn-prev {
      background-color: #ADADAD !important;
      padding-right: 0;
    }

    /deep/.btn-next {
      margin-left: 10px;
      padding: 0;
    }
  }

  .son-scroll {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 200px);
    padding-left: 12px;
    box-sizing: border-box;

    &::-webkit-scrollbar {
      width: 0;
    }

    .son-title {
      height: 30px;
      line-height: 30px;
      color: #fff;
      font-size: 18px;
    }

    .son-title1 {
      margin-top: 20px;
    }

    .son-title2 {
      margin-top: 15px;
      color: #fff;
      font-size: 16px;
    }

    .son-title3 {
      height: 40px;
      line-height: 40px;
      color: #04B4D0;
      font-size: 18px;
    }

    .son-line {
      display: flex;
      align-items: center;
      margin-top: 10px;

      .key {
        width: 110px;
        color: #fff;
        font-size: 15px;
      }

      .value {
        color: #fff;
        font-size: 15px;
      }

      .son-key {
        margin-right: 20px;
        color: #fff;
        font-size: 18px;
      }

      .son-input {
        width: 180px;
      }

      .son-margin {
        margin-right: 20px;
      }

      .son-key1 {
        width: 220px;
        color: #fff;
        font-size: 14px;
      }
    }

    .son-line1 {
      margin-top: 30px;
    }

    .checkbox {
      margin-top: 10px;
    }

    /deep/.el-checkbox__label {
      color: #fff;
    }

    /deep/.is-checked .el-checkbox__label {
      color: #01EEFD;
    }

    .el-textarea {
      margin-top: 10px;

      /deep/.el-textarea__inner {
        width: 50%;
        height: 60px;
        resize: none;
      }
    }

    .el-timeline {
      margin-top: 10px;

      /deep/.el-timeline-item__content {
        color: #fff;
        font-size: 15px;
      }

      /deep/.el-timeline-item__timestamp {
        width: 60%;
        line-height: 20px;
        color: #fff;
        font-size: 15px;
      }

      /deep/.el-timeline-item__node {
        width: 22px;
        height: 22px;
        background-color: #04B4D0;
        border: 2px solid #fff;
      }

      /deep/.el-timeline-item__tail {
        left: 11px;
      }

      /deep/.el-timeline-item__wrapper {
        padding-left: 40px;
      }
    }
  }

  .see-close {
    position: absolute;
    left: 40px;
    bottom: 0px;
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;

    .el-button {
      background-color: #33465C;
      border: none;
      color: #fff;
    }

    .see-blue {
      margin-left: 50px;
      background-color: #024A83;
    }
  }
}
</style>
