<template>
  <div class="details">
    <div class="details-scroll">
      <div class="details-title">
        <span>任务详情</span>
      </div>
      <div class="details-title1">
        <span>任务信息</span>
      </div>
      <div class="details-box">
        <div class="details-item">
          <span class="key">任务名称</span>
          <span class="value">晨起</span>
        </div>
        <div class="details-item">
          <span class="key">任务状态</span>
          <span class="value">进行中</span>
        </div>
        <div class="details-item">
          <span class="key">负责人</span>
          <span class="value">张敏</span>
        </div>
        <div class="details-item">
          <span class="key">任务描述</span>
          <span class="value">需要按照规定的程序给老人洗澡</span>
        </div>
        <div class="details-item">
          <span class="key">任务时间</span>
          <span class="value">8：00-8：30</span>
        </div>
      </div>
      <div class="details-title">
        <span>老人信息</span>
      </div>
      <div class="details-info">
        <img src="../../img/emr/user.png" class="details-info-left">
        <div class="details-info-right">
          <span>姓名：陈菊英</span>
          <span>房间号：501/501-01</span>
          <span>手机号：19996131427</span>
        </div>
      </div>
      <div class="details-title">
        <span>子任务</span>
      </div>
      <el-checkbox-group v-model="checkList">
        <el-checkbox label="0">辅助起床</el-checkbox>
        <el-checkbox label="1">辅助洗漱</el-checkbox>
        <el-checkbox label="2">辅助如厕</el-checkbox>
        <el-checkbox label="3">辅助穿衣</el-checkbox>
      </el-checkbox-group>
      <div class="details-title">
        <span>老人信息</span>
      </div>
      <el-timeline>
        <el-timeline-item timestamp="系统创建并分配了晨起的任务给您，请尽快处理！">
          10-20 15：00
        </el-timeline-item>
        <el-timeline-item timestamp="系统创建并分配了晨起的任务给您，请尽快处理！">
          10-20 15：00
        </el-timeline-item>
      </el-timeline>
    </div>
    <div class="details-btns">
      <el-button size="small">清空</el-button>
      <el-button class="blue" size="small" @click="close">提交</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      checkList: []
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.details {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: #081C38;
  box-sizing: border-box;

  .details-scroll {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 50px);

    &::-webkit-scrollbar {
      width: 0;
    }

    .details-title {
      width: 100%;
      height: 50px;
      line-height: 50px;
      padding-left: 10px;
      border-bottom: 1px solid #01EEFD;
      box-sizing: border-box;
      color: #01EEFD;
      font-size: 20px;
    }

    .details-title1 {
      width: 100%;
      height: 60px;
      line-height: 60px;
      color: #01EEFD;
      font-size: 20px;
    }

    .details-box {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 0 10px;
      box-sizing: border-box;

      .details-item {
        display: flex;
        align-items: center;
        width: 25%;
        margin-bottom: 16px;
        font-size: 20px;

        .key {
          color: #01EEFD;
        }

        .value {
          width: calc(100% - 100px);
          margin-left: 10px;
          color: #fff;
        }
      }
    }

    .details-info {
      display: flex;
      align-items: center;
      height: 137px;

      .details-info-left {
        width: 97px;
        height: 97px;
      }

      .details-info-right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 97px;
        margin-left: 16px;
        color: #01EEFD;
        font-size: 20px;
      }
    }

    .el-checkbox-group {
      display: flex;
      flex-direction: column;
      margin-left: 82px;

      /deep/.el-checkbox {
        margin-top: 20px;
      }

      /deep/.el-checkbox__inner {
        background-color: transparent;
      }

      /deep/.el-checkbox__label {
        color: #01EEFD;
        font-size: 20px;
      }
    }

    .el-timeline {
      margin-top: 20px;
      margin-left: 72px;

      /deep/.el-timeline-item__tail {
        left: 6px;
        border-left: 1px dashed #01EEFD;
      }

      /deep/.el-timeline-item__node {
        background-color: #081C38;
        border: 1px solid #01EEFD;
      }

      /deep/.el-timeline-item__content {
        color: #01EEFD;
        font-size: 20px;
      }

      /deep/.el-timeline-item__timestamp {
        color: #01EEFD;
        font-size: 20px;
      }
    }
  }

  .details-btns {
    display: flex;
    align-items: center;
    height: 50px;

    .el-button {
      background-color: #33465C;
      border: none;
      color: #fff;
    }

    .blue {
      background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
    }
  }

}
</style>
