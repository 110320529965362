<template>
  <div class="write">
    <div class="title">
      <span>新增</span>
    </div>
    <div class="content">
      <div @click="close" class="close">
        <span class="el-icon-close"></span>
      </div>
      <div class="box">
        <div class="item">
          <span class="span">身份证图片</span>
          <!-- <img src="" alt=""> -->
          <input type="file" ref="file" @change="handleFileChange" v-show="false">
          <span class="el-icon-camera-solid" @click="openImg"></span>
        </div>
        <div class="item">
          <span class="span">人群分类</span>
          <el-input placeholder="请输入人群分类" v-model="params.nametage"></el-input>
        </div>
        <div class="item">
          <span class="span">姓名</span>
          <el-input placeholder="" v-model="params.name"></el-input>
        </div>
        <div class="item">
          <span class="span">身份证号</span>
          <el-input class="input" placeholder="" maxlength="18" v-model="params.sfzhm" @blur="findsfzh"></el-input>
        </div>

        <div class="item">
          <span class="span">年龄</span>
          <el-input placeholder="" maxlength="3"  v-model="params.age"></el-input>
        </div>
        <div class="item">
          <span class="span">性别</span>
          <el-radio-group v-model="params.xb">
            <el-radio label="1">男</el-radio>
            <el-radio label="2">女</el-radio>
          </el-radio-group>
        </div>
        <div class="item">
          <span class="span">户籍地址</span>
          <el-input placeholder="请输入户籍地址" v-model="params.jkdnhjdz"></el-input>
        </div>
        <div class="item">
          <span class="span">现住址</span>
          <el-input placeholder="请输入现地址" v-model="params.jkdnxzz"></el-input>
        </div>
        <div class="item">
          <span class="span">乡镇/社区</span>
          <el-select placeholder="" v-model="params.xzjdmc" @change="change1">
            <el-option v-for="item in arr1" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <span class="span">乡镇/社区编码</span>
          <el-input placeholder="" v-model="params.xzjdmcid"></el-input>
        </div>
        <div class="item">
          <span class="span">村/街道</span>
          <el-select placeholder="" v-model="params.cwhmc" @change="change2">
            <el-option v-for="item in arr2" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <span class="span">村/街道编号</span>
          <el-input placeholder="" v-model="params.cwhmcid"></el-input>
        </div>
        <div class="item">
          <span class="span">责任医生</span>
          <el-select placeholder="" v-model="params.zeys" @change="ysChange">
            <el-option v-for="item in yss" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <span class="span">录入人</span>
          <el-input placeholder="" v-model="params.lrr"></el-input>
        </div>
        <div class="item">
          <span class="span">养老专属编号</span>
          <el-input placeholder="" v-model="params.ylzsbh"></el-input>
        </div>
        <div class="item">
          <span class="span">档案编号</span>
          <el-input placeholder="" v-model="params.dnbh"></el-input>
        </div>
        <div class="item">
          <span class="span">建档日期</span>
          <el-date-picker value-format="yyyy-MM-dd" type="date" placeholder="选择日期" v-model="params.jdrq">
          </el-date-picker>
        </div>
      </div>
      <div class="btns">
        <el-button @click="close">
          <span class="el-icon-bank-card"></span>
          <span>读身份证</span>
        </el-button>
        <el-button @click="submit">确认</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data () {
    return {
      radio: '1',
      options: [{
        value: '1',
        label: '1'
      }],
      arr1: [],
      arr2: [],
      value: '',
      yss: [],
      params: {
        createTime: '',
        updateTime: '',
        jkdnid: '',
        name: '',
        lxdh: '',
        dnbh: '',
        jdrq: '',
        sfzhm: '',
        grjbxxid: '',
        tmcode: '',
        jgbh: '',
        cwhmcid: '',
        age: '',
        personInfoId: '',
        isHypertension: '',
        isDiabetes: '',
        isSmi: '',
        isTuberculosis: '',
        isChd: '',
        isCa: '',
        isaged: '',
        isWomen: '',
        isOld: '',
        isChildren: '',
        isMaternal: '',
        isPhychosis: '',
        personInfoNo: '',
        manageOrgId: '',
        xb: '',
        sfztp: '',
        xzjdmcid: '',
        jddwid: '',
        tjlx: '',
        nametage: '',
        deflag: '',
        csrq: '',
        jkdnxzz: '',
        jkdnhjdz: '',
        xzjdmc: '',
        cwhmc: '',
        jdr: '',
        ylzsbh: '',
        ylfwdj: '',
        ylfwdjbm: '',
        ylhldj: '',
        ylhldjbm: '',
        yldjsj: '',
        lrr: '',
        lrrid: '',
        zeys: '',
        zeysid: ''
      }
      // params: {
      //   sfztp: '', // 身份证图片
      //   ocrtpbase64: '', // 图片二级制
      //   name: '', // 姓名
      //   sfzhm: '', // 身份证号
      //   age: '', // 年龄
      //   xb: '', // 性别
      //   jkdnhjdz: '', // 户籍地址
      //   jkdnxzz: '', // 现住址
      //   xzjdmc: '', // 乡镇社区名称
      //   xzjdmcid: '', // 乡镇社区编码
      //   cwhmc: '', // 村/街道名称
      //   cwhmcid: '', // 村/街道名称编码
      //   zeys: '', // 责任医生
      //   lrr: '', // 录入人
      //   healthExam: '', // 体检信息
      //   dnbh: '', // 档案编号
      //   jdrq: '', // 建档日期

      //   bcbz: '', // b超标志
      //   cmgOperation: '', // 中医辨识上传结果
      //   createTime: '', // 创建日期
      //   diabetesCard: '', // 糖尿病信息卡
      //   diabetesFlag: '', // 糖尿病上传标志
      //   diabetesFollowup: '', // 糖尿病随访记录
      //   elderInfo: '', // 老年人专挡
      //   elderSelfcareAbility: '', // 老年人评估
      //   elderTcmHealth: '', // 中医药健康
      //   flag: '', // 无
      //   grjbxxid: '', // 个人基本信息id
      //   hyperCard: '', // 高血压信息卡
      //   hyperFlag: '', // 高血压上传状态
      //   hyperFollowup: '', // 高血压随访记录
      //   id: 1, // 类型：Number  必有字段  备注：id
      //   isCa: '', // 是否脑卒中
      //   isChd: '', // 是否冠心病
      //   isDiabetes: '', // 是否糖尿病
      //   isHypertension: '', // 是否高血压
      //   isMaternal: '', // 是否是孕妇
      //   isSmi: '', // 是否精神病
      //   isTuberculosis: '', // 是否肺结核
      //   jddw: '', // 建档单位
      //   jdr: '', // 建档人
      //   jgbh: '', // 机构编号
      //   jkdnid: '', // 健康档案id
      //   jktjid: '', // 健康体检id
      //   lisbz: '', // lis标志
      //   lrrq: '', // 录入日期
      //   lxdh: '', // 联系电话
      //   manageOrgId: '', // 平台机构id
      //   manageOrgName: '', // 平台机构名称
      //   minzu: '', // 民族
      //   ocrtpurl: '', // ocr图片地址
      //   orgName: '', // 无
      //   person: '', // 基本信息
      //   personInfoId: '', // 平台个人档案ID
      //   personInfoNo: '', // 平台个人编号
      //   picId: '', // 图片id
      //   psyCard: '', // 精神病信息卡
      //   psyFlag: '', // 精神病上传结果
      //   psyRecord: '', // 精神病随访记录
      //   ptCard: '', // 肺结核第一次入户随访卡
      //   ptFlag: '', // 肺结核上传结果
      //   ptRecord: '', // 肺结核随访记录
      //   ptupdate: '', // 无
      //   sgtzbz: '', // 身高体重标志
      //   shbz: '', // 生化标志
      //   tjbh: '', // 体检编号
      //   tmcode: '', // 条码号
      //   updateTime: '', // 无
      //   uploadFlag: '', // 上传flag
      //   xqbz: '', // 血球标志
      //   ytjbz: '', // 一体机标志
      //   zppic: '' // 照片地址
      // }
    }
  },
  computed: {
    ...mapState(['loginInfo']),
    sfzh () {
      return this.params.sfzhm
    }
  },
  watch: {
    sfzh (newVal) {
      this.params.xb = parseInt(newVal.substr(-2, 1), 10) % 2 === 1 ? '1' : '2'
    }
  },
  created () {
    this.init()
    this.getYs()
  },
  methods: {
    init () {
      this.arr1 = this.loginInfo.cjrylist
      this.arr2 = this.loginInfo.children
      this.params.jgbh = this.loginInfo.jgbh || ''
      this.params.xzjdmcid = this.loginInfo.jgbh || ''
      this.params.jdrq = this.getDate()

      const code = parseInt(Math.random() * 1000) + ''
      console.log(this.getDates() + code.padStart(3, '0'))
      this.params.ylzsbh = this.getDates() + code.padStart(3, '0')
    },
    async getYs () {
      try {
        // 接口文档 https://app.apifox.com/project/2726972/apis/api-122319876
        const res = await this.$http.post('/users/getyslb', {
          jgbh: this.loginInfo.jgbh || '',
          cwhmcid: this.loginInfo.tjjgcwhmcid || '',
          lytype: '1'
        })
        console.log('获取医生列表', res)
        if (res.status !== 200) return this.$message.error(res.msg || res.message)
        this.yss = res.data.data
      } catch (error) {
        console.log('请求失败', error.response.data.msg)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    async findsfzh () {
      if (!(/^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(this.params.sfzhm))) return this.$message.error('输入有效身份证')
      try {
        // 接口文档 https://doc.weixin.qq.com/doc/w3_AAMAewYpAFExU0XgnJGQgK6jkgPcX?scode=ACwAmwdjAAY49p6HNMAAMAewYpAFE
        const res = await this.$http.post('/api/jkct/app/findsfzh', {
          sfzh: this.params.sfzhm,
          lytype: 'yl'
        })
        console.log('输入身份证号后触发接口', res)
      } catch (error) {
        console.log('请求失败', error.response.data.msg)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    ysChange (e) {
      console.log(e)
      this.params.zeysid = e
    },
    openImg () {
      this.$refs.file.click()
    },
    handleFileChange (event) {
      const file = event.target.files[0]
      if (file) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.params.sfztp = e.target.result
          console.log(e)
        }
        reader.readAsDataURL(file)
      }
    },
    change1 (e) {
      console.log(e)
      // this.params.xzjdmcid = e
    },
    change2 (e) {
      console.log(e)
      this.params.cwhmcid = e
    },
    getDate () {
      const date = new Date()
      const year = date.getFullYear()
      let month = date.getMonth() + 1
      let day = date.getDate()
      let h = date.getHours()
      h = h < 10 ? '0' + h : h
      let f = date.getMinutes()
      f = f < 10 ? '0' + f : f
      let s = date.getSeconds()
      s = s < 10 ? '0' + s : s
      if (month >= 1 && month <= 9) {
        month = '0' + month
      }
      if (day >= 0 && day <= 9) {
        day = '0' + day
      }
      return `${year}-${month}-${day} ${h}:${f}:${s}`
      // return `${year}-${month}-${day}`
    },
    getDates () {
      const date = new Date()
      const year = date.getFullYear()
      let month = date.getMonth() + 1
      let day = date.getDate()
      if (month >= 1 && month <= 9) {
        month = '0' + month
      }
      if (day >= 0 && day <= 9) {
        day = '0' + day
      }
      return `${year}${month}${day}`
      // return `${year}-${month}-${day}`
    },
    async submit () {
      try {
        // 接口文档 https://app.apifox.com/link/project/2726972/apis/api-123017941
        if (!(/^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(this.params.sfzhm))) return this.$message.error('输入有效身份证')
        console.log('请求参数', this.params)
        const res = await this.$http.post('/zhyyapi/ykdjcdnsave', this.params)
        console.log(res)
        if (res.status !== 200) return this.$message.error(res.msg || res.message)
        this.$message.success('保存成功')
        this.$emit('close', 'loading')
      } catch (error) {
        console.log('请求失败', error.response.data.msg)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.write {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 888;
  width: 100%;
  height: 100%;
  padding: 0 100px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, .9);

  .title {
    width: 167px;
    height: 53px;
    padding-top: 5px;
    box-sizing: border-box;
    line-height: 53px;
    text-align: center;
    background-image: url('../../../img/ssjk/title.png');
    background-size: 100% 100%;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
  }

  .content {
    position: relative;
    width: 100%;
    height: 631px;
    padding: 50px 65px;
    background-image: url('../../../img/ssjk/background4.png');
    background-size: 100% 100%;
    box-sizing: border-box;

    .close {
      position: absolute;
      top: 24px;
      right: 30px;
      color: #fff;
      font-size: 28px;
      cursor: pointer;
    }

    .content-title {
      margin-top: 20px;
      color: #01EEFD;
      font-size: 18px;
    }

    .box {
      display: flex;
      align-items: center;
      justify-content: space-between;

      flex-wrap: wrap;
      width: 100%;

      .tab {
        display: flex;
        width: 100%;
        height: 38px;

        .tab-item {
          width: 90px;
          height: 38px;
          line-height: 38px;
          margin-right: 30px;
          text-align: center;
          background-color: #616B73;
          color: #fff;
          font-size: 16px;
          cursor: pointer;
        }

        .tab-item:last-child {
          margin-right: 0;
        }

        .tab-active {
          background-color: #04B4D0;
        }
      }
    }

    .item {
      position: relative;
      display: flex;
      align-items: center;
      width: 25%;
      margin-top: 30px;
      color: #01EEFD;
      font-size: 18px;

      .span {
        margin-right: 10px;
        white-space: nowrap;
      }

      .span1 {
        width: 108px;
      }

      .el-input,
      .el-select {
        width: 167px;
        height: 38px;
      }

      .input {
        width: calc(100% - 140px);
      }
    }

    .btns {
      position: absolute;
      bottom: 70px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 400px;
      height: 46px;
      margin: 0 auto;

      .el-button {
        width: 150px;
        height: 30px;
        line-height: 30px;
        padding: 0 10px;
        text-align: center;
        color: #fff;
        font-size: 14px;
        background-color: #1E58A6;
        border-radius: 8px;
        border: none;
      }
    }
  }

  .el-radio-group {
    margin-top: 6px;
  }

  /deep/.el-radio__input.is-checked .el-radio__inner {
    background-color: #01EEFD;
    border-color: #01EEFD;
  }

  .el-radio {
    color: #fff;
  }

  /deep/.el-radio__input.is-checked+.el-radio__label {
    color: #01EEFD;
  }

  .el-select {
    width: calc(100% - 200px);
  }

  .el-input-number {
    width: 90px;
  }

  .width {
    width: 94px;
    margin-left: 30px;
  }

  /deep/.el-textarea__inner {
    resize: none;
  }

  ::v-deep .el-table__body-wrapper {
    &::-webkit-scrollbar {
      width: 0px;
    }
  }

  ::v-deep .el-table th.gutter {
    display: none;
    width: 0;
  }

  ::v-deep .el-table colgroup col[name='gutter'] {
    display: none;
    width: 0;
  }

  ::v-deep .el-table__body {
    width: 100% !important;
  }

  .el-table {
    background-color: #092B50;
    border: 4px #073F73 solid;
  }

  // 表头文字颜色
  /deep/.el-table__header-wrapper {
    thead {
      th {
        div {
          color: #01EEFD;
          font-size: 17px;
          font-weight: 400;
        }
      }
    }
  }

  //表格内容颜色
  /deep/.el-table__body tbody tr {
    color: #fff;
    background-color: #092B50;
  }

  /deep/.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
    background-color: #092B50;
  }

  /deep/.el-table tbody tr:hover>td {
    background-color: transparent !important
  }

  // 改变边框颜色
  .el-table--border,
  .el-table--group {
    border: 2px solid #ddd !important;
  }

  /**
  改变表格内竖线颜色
   */
  .el-table--border td,
  .el-table--border th,
  .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
    border-right: 1px solid #ddd !important;
  }

  /deep/.el-table td {
    border-bottom: 1px solid #01EEFD;
  }

  /deep/.el-table thead tr th {
    border-color: transparent;
  }

  /deep/ .el-table--border::after,
  /deep/ .el-table--group::after,
  /deep/ .el-table::before {
    background-color: #073F73;
  }

  /deep/.el-table--border {
    border: 1px solid #073F73 !important;
  }

  /deep/.el-table .cell {
    text-align: center;
    color: #01EEFD;
  }

  /deep/.el-checkbox__inner {
    background-color: transparent;
  }

  .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    color: #0e75d4;
    font-size: 15px;

    span {
      cursor: pointer;
    }
  }

  .flex1 {
    justify-content: center;
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 40px;

    ::v-deep.el-pagination__total,
    ::v-deep.el-pagination__jump {
      color: #01EEFD;

      .el-input__inner {
        color: #fff;
        background-color: transparent;
      }
    }

    ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: #01EEFD;
    }
  }

}
</style>
