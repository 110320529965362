<template>
  <div class="add-box">
    <div class="tab">
      <span class="item active">咨询登记</span>
    </div>
    <el-input placeholder="姓名/身份证号" suffix-icon="el-icon-search" class="input">
    </el-input>
    <div class="tab-box">
      <div class="run">
        <div class="run-search">
          <div class="run-item">
            <div class="key">
              <span>老人姓名：张国峰</span>
            </div>
          </div>
          <div class="run-item">
            <div class="key">
              <span>家属姓名：张笑天</span>
            </div>
          </div>
          <div class="run-item">
            <div class="key">
              <span>性别：男</span>
            </div>
          </div>
          <div class="run-item">
            <div class="key">
              <span>联系方式：1776288238</span>
            </div>
          </div>
          <div class="run-item">
            <div class="key">
              <span>身份证号：32030293023033</span>
            </div>
          </div>
          <div class="run-item">
            <div class="key">
              <span>房间需求：需要干净整洁的单人间</span>
            </div>
          </div>
          <div class="run-item">
            <div class="key">
              <span>老人状况：健康</span>
            </div>
          </div>
          <div class="run-item">
            <div class="key">
              <span>意向描述：尽快办理入住手续</span>
            </div>
          </div>
        </div>
        <div class="son-tab">
          <div class="blue" @click="openRegistrationUpdate">
            <span class="el-icon-plus"></span>
          </div>
        </div>
        <el-table :data="tableData" border height="50%">
          <el-table-column label="序号" type="index" width="70">
          </el-table-column>
          <el-table-column prop="name" label="咨询类型">
          </el-table-column>
          <el-table-column prop="address" label="咨询渠道">
          </el-table-column>
          <el-table-column prop="address" label="咨询人">
          </el-table-column>
          <el-table-column prop="address" label="联系电话">
          </el-table-column>
          <el-table-column prop="address" label="与老人关系">
          </el-table-column>
          <el-table-column prop="address" label="登记日期">
          </el-table-column>
          <el-table-column prop="address" label="登记人">
          </el-table-column>
          <el-table-column prop="address" label="回访状态">
          </el-table-column>
          <el-table-column label="操作" width="360">
            <!-- slot-scope="scope" -->
            <template >
              <!-- <div class="table-run">
                <span @click="openRegistrationUpdate">编辑</span>
                <span @click="openRegistrationDelete">删除</span>
                <span @click="openRegistrationSee">详情</span>
                <span @click="openRecord">回访记录</span>
              </div> -->
              <el-button size="mini" type="primary" @click="openRegistrationUpdate">编辑</el-button>
              <el-button size="mini" type="danger" @click="openRegistrationDelete">删除</el-button>
              <el-button size="mini" type="primary" @click="openRegistrationSee">详情</el-button>
              <el-button size="mini" type="primary" @click="openRecord">回访记录</el-button>
            </template>
          </el-table-column>
        </el-table>

      </div>
    </div>
    <div class="tab-btns">
      <el-button size="small" @click="close">返回</el-button>
      <el-button size="small" type="primary" @click="close">保存</el-button>
    </div>
    <!-- 编辑 -->
    <RegistrationUpdate v-if="showRegistrationUpdate" @close="closeRegistrationUpdate"></RegistrationUpdate>
    <!-- 删除 -->
    <RegistrationDelete v-if="showRegistrationDelete" @close="closeRegistrationDelete"></RegistrationDelete>
    <!-- 详情 -->
    <RegistrationSee v-if="showRegistrationSee" @close="closeRegistrationSee"></RegistrationSee>
    <!-- 回访记录 -->
    <Record v-if="showRecord" @close="closeRecord"></Record>
  </div>
</template>

<script>
import RegistrationUpdate from './registrationUpdate.vue'
import RegistrationDelete from './registrationDelete.vue'
import RegistrationSee from './registrationSee.vue'
import Record from './record.vue'
export default {
  components: {
    RegistrationUpdate,
    RegistrationDelete,
    RegistrationSee,
    Record
  },
  data () {
    return {
      tableData: [{
        date: '2016-05-02',
        name: '王小虎',
        address: '未签约'
      }, {
        date: '2016-05-04',
        name: '王小虎',
        address: '预定中'
      }, {
        date: '2016-05-01',
        name: '王小虎',
        address: '已入院'
      }],
      options: [{
        value: '',
        label: ''
      }],
      value: '',
      currentPage4: 4,
      tabIndex: 0,
      selectIndex: 0,
      showRegistrationUpdate: false,
      showRegistrationSee: false,
      showRegistrationDelete: false,
      showRecord: false,
      list: ['血压记录', '血糖记录', '体温记录'],
      listIndex: 0
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    },
    tab (index) {
      this.listIndex = index
    },
    openRegistrationUpdate () {
      this.showRegistrationUpdate = true
    },
    closeRegistrationUpdate () {
      this.showRegistrationUpdate = false
    },
    openRegistrationSee () {
      this.showRegistrationSee = true
    },
    closeRegistrationSee () {
      this.showRegistrationSee = false
    },
    openRegistrationDelete () {
      this.showRegistrationDelete = true
    },
    closeRegistrationDelete () {
      this.showRegistrationDelete = false
    },
    openRecord () {
      this.showRecord = true
    },
    closeRecord () {
      this.showRecord = false
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.add-box {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 88;
  padding: 0 20px;
  box-sizing: border-box;
  background-color: #fff;

  .input {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 246px;
  }

  .tab {
    display: flex;
    align-items: center;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    .item {
      width: 130px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      margin-left: 30px;
      background-color: #284867;
      color: #fff;
      font-size: 22px;
      cursor: pointer;

      &:first-child {
        margin-left: 0px;
      }
    }

    .active {
      background-color: #409EFF;
    }
  }

  .tab-box {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 150px);

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  .son-line {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    color: #000;
    font-size: 16px;

    .el-textarea {
      width: 376px;
      height: 120px;
      margin-left: 10px;
    }

    /deep/.el-textarea__inner {
      width: 376px;
      height: 120px;
      resize: none;
    }
  }

  .tab-btns {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;
  }

}

.run {
  width: 100%;
  height: 100%;

  .run-title {
    height: 40px;
    line-height: 40px;
    color: #000;
    font-size: 18px;
  }

  .son-title {
    height: 40px;
    line-height: 40px;
    color: #000;
    font-size: 18px;
  }

  .son-tab {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    .son-item {
      height: 38px;
      line-height: 38px;
      margin-right: 40px;
      padding: 0 20px;
      text-align: center;
      background-color: #284867;
      color: #fff;
      font-size: 16px;
      cursor: pointer;
    }

    .son-active {
      background-color: #04B4D0;
    }

    .blue {
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      border-radius: 50%;
      background-color: #409EFF;
      color: #fff;
      cursor: pointer;
    }
  }

  .run-search {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    padding-top: 10px;
    padding-right: 50%;
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    .run-item {
      display: flex;
      align-items: center;
      width: 50%;
      margin-bottom: 10px;

      .key {
        margin-right: 10px;
        white-space: nowrap;
        color: #000;
        font-size: 18px;
      }
    }
  }

  .run-scroll {
    width: 100%;
    height: calc(100% - 100px);
  }

  .el-table {
    margin-top: 10px;
  }

  /deep/.el-table .cell {
    text-align: center;
    color: #000;
  }

  .table-run {
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      cursor: pointer;
    }
  }

  .el-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
}
</style>
