<template>
  <div class="update">
    <div class="update-top">
      <span class="update-text">账号设置</span>
      <el-input placeholder="姓名/身份证号" suffix-icon="el-icon-search" class="update-input"></el-input>
    </div>
    <div class="update-scroll">
      <div class="update-line">
        <span class="update-text">员工头像</span>
        <img src="" class="update-img">
      </div>
      <div class="update-line">
        <span class="update-text">员工姓名</span>
        <el-input class="update-input" placeholder="请输入"></el-input>
      </div>
      <div class="update-line">
        <span class="update-text">联系方式</span>
        <el-input class="update-input" placeholder="请输入"></el-input>
      </div>
      <div class="update-line">
        <span class="update-text">身份证号</span>
        <el-input class="update-input" placeholder="请输入"></el-input>
      </div>
      <div class="update-line">
        <span class="update-text">所属部门</span>
        <el-input class="update-input" placeholder="请输入"></el-input>
      </div>
      <div class="update-line">
        <span class="update-text">所属机构</span>
        <el-input class="update-input" placeholder="请输入"></el-input>
      </div>
      <div class="update-line">
        <span class="update-text">所属岗位</span>
        <el-input class="update-input" placeholder="请输入"></el-input>
      </div>
      <div class="update-line">
        <span class="update-text">账号</span>
        <el-input class="update-input" placeholder="请输入"></el-input>
      </div>
      <div class="update-line">
        <span class="update-text">密码</span>
        <el-input class="update-input" placeholder="请输入"></el-input>
        <span class="update-text update-text1">修改密码</span>
      </div>
    </div>
    <!-- <div class="update-btns">
      <el-button size="small" @click="close">取消</el-button>
      <el-button size="small" class="blue" @click="close">确定</el-button>
    </div> -->
  </div>
</template>

<script>
export default {
  data () {
    return {
      radio: 1
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.update {
  width: 100%;
  height: 100%;
  padding: 0 40px;
  background-color: #fff;
  box-sizing: border-box;

  .update-top {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 60px;
    padding: 0 30px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    .update-text {
      color: #000;
      font-size: 22px;
    }

    .update-input {
      width: 246px;
    }
  }

  .update-scroll {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 110px);

    &::-webkit-scrollbar {
      width: 0;
    }

    .update-line {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      height: 60px;
      margin-top: 10px;

      .update-text {
        width: 98px;
        text-align-last: justify;
        color: #000;
        font-size: 22px;
      }

      .update-text {
        margin-left: 20px;
      }

      .update-input {
        width: 180px;
        margin-left: 10px;
      }

      .update-img {
        width: 56px;
        height: 56px;
        margin-left: 10px;
        background-color: #fff;
        border-radius: 50%;
      }

      .el-radio-group {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 10px;
      }

      .el-radio {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
  }

  .update-btns {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 50px;
    padding-left: 30px;
    box-sizing: border-box;

    .el-button {
      margin-right: 100px;
      background-color: #33465C;
      border: 0;
      color: #fff;
    }

    .blue {
      background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
    }
  }
}
</style>
