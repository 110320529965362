<template>
  <div class="page-box">
    <div class="tab">
      <span class="item active">潜在客户</span>
    </div>
    <el-input placeholder="姓名/身份证号" suffix-icon="el-icon-search" class="input">
    </el-input>
    <div class="tab-box">
      <div class="run-search">
        <div class="run-item">
          <div class="key">
            <span>老人</span>
          </div>
          <el-input placeholder="请输入"></el-input>
        </div>
        <div class="run-item">
          <div class="key">
            <span>身份证号</span>
          </div>
          <el-input placeholder="请输入"></el-input>
        </div>
        <div class="run-item">
          <div class="key">
            <span>创建日期</span>
          </div>
          <el-date-picker type="date" placeholder="请选择">
          </el-date-picker>
        </div>
        <div class="run-item">
          <div class="key">
            <span>状态</span>
          </div>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <el-button type="primary">搜索</el-button>
        <el-button type="primary">重置</el-button>
      </div>

      <div class="son-tab">
        <span class="son-item son-active" @click="openAdd">新增潜在客户</span>
        <span class="son-item">EXCEL导出</span>
      </div>
      <div class="table">
        <el-table :data="tableData" height="100%" border>
          <el-table-column label="序号" type="index" width="100">
          </el-table-column>
          <el-table-column prop="name" label="老人姓名">
          </el-table-column>
          <el-table-column prop="address" label="性别">
          </el-table-column>
          <el-table-column prop="address" label="身份证号">
          </el-table-column>
          <el-table-column prop="address" label="来源渠道">
          </el-table-column>
          <el-table-column prop="address" label="创建日期">
          </el-table-column>
          <el-table-column prop="address" label="状态">
          </el-table-column>
          <el-table-column label="操作" width="380">
            <template>
              <!-- <div class="table-run" v-if="scope.row.address === '未签约'">
                <span @click="openAdd">编辑</span>
                <span @click="openSee">详情</span>
                <span @click="openRegistration">咨询登记</span>
                <span @click="openDelete">删除</span>
              </div>
              <div class="table-run" v-else-if="scope.row.address === '预定中'">
                <span @click="openAdd">编辑</span>
                <span @click="openSee">详情</span>
                <span @click="openRegistration">咨询登记</span>
              </div>
              <div class="table-run" v-else-if="scope.row.address === '已入院'">
                <span @click="openAdd">编辑</span>
              </div> -->
              <el-button size="mini" type="primary" @click="openAdd">编辑</el-button>
              <el-button size="mini" type="primary" @click="openSee">详情</el-button>
              <el-button size="mini" type="primary" @click="openRegistration">咨询登记</el-button>
              <el-button size="mini" type="danger" @click="openDelete">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="currentPage4" :page-sizes="[100, 200, 300, 400]" :page-size="100"
        layout="total, prev, pager, next, jumper" :total="400">
      </el-pagination>
    </div>
    <!-- 编辑 -->
    <Add v-if="showAdd" @close="closeAdd"></Add>
    <!-- 详情 -->
    <See v-if="showSee" @close="closeSee"></See>
    <!-- 咨询登记 -->
    <Registration v-if="showRegistration" @close="closeRegistration"></Registration>
    <!-- 删除 -->
    <Delete v-if="showDelete" @close="closeDelete"></Delete>
  </div>
</template>

<script>
import Add from './add.vue'
import See from './see.vue'
import Registration from './registration.vue'
import Delete from './delete.vue'
export default {
  components: {
    Add,
    See,
    Registration,
    Delete
  },
  data () {
    return {
      tableData: [{
        date: '2016-05-02',
        name: '王小虎',
        address: '未签约'
      }, {
        date: '2016-05-04',
        name: '王小虎',
        address: '预定中'
      }, {
        date: '2016-05-01',
        name: '王小虎',
        address: '已入院'
      }],
      options: [{
        value: '',
        label: ''
      }],
      value: '',
      currentPage4: 4,
      tabIndex: 0,
      selectIndex: 0,
      showAdd: false,
      showSee: false,
      showRegistration: false,
      showDelete: false,
      list: ['血压记录', '血糖记录', '体温记录'],
      listIndex: 0
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    },
    tab (index) {
      this.listIndex = index
    },
    openAdd () {
      this.showAdd = true
    },
    closeAdd () {
      this.showAdd = false
    },
    openSee () {
      this.showSee = true
    },
    closeSee () {
      this.showSee = false
    },
    openRegistration () {
      this.showRegistration = true
    },
    closeRegistration () {
      this.showRegistration = false
    },
    openDelete () {
      this.showDelete = true
    },
    closeDelete () {
      this.showDelete = false
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.page-box {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  background-color: #fff;

  .input {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 246px;
  }

  .tab {
    display: flex;
    align-items: center;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    .item {
      width: 130px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      margin-left: 30px;
      background-color: #284867;
      color: #fff;
      font-size: 22px;
      cursor: pointer;

      &:first-child {
        margin-left: 0px;
      }
    }

    .active {
      background-color: #409EFF;
;
    }
  }

  .tab-box {
    width: 100%;
    height: calc(100% - 78px);

    .son-tab {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 100%;
      height: 78px;
      border-bottom: 1px solid #000;
      box-sizing: border-box;

      .son-item {
        height: 38px;
        line-height: 38px;
        margin-right: 40px;
        padding: 0 20px;
        text-align: center;
        background-color: #284867;
        color: #fff;
        font-size: 16px;
        cursor: pointer;
      }

      .son-active {
        background-color: #409EFF;
;
      }
    }

    .run-tab {
      display: flex;
      align-items: center;
      height: 60px;

      .run-item {
        margin-right: 56px;
        color: #fff;
        font-size: 22px;
        border-bottom: 1px solid transparent;
        cursor: pointer;
      }

      .run-active {
        border-bottom: 1px solid #409EFF;
;
        color: #409EFF;
;
      }
    }

    .run-search {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 100%;
      height: 78px;
      border-bottom: 1px solid #000;
      box-sizing: border-box;

      .run-item {
        display: flex;
        align-items: center;
        margin-right: 30px;

        .key {
          margin-right: 10px;
          white-space: nowrap;
          color: #000;
          font-size: 22px;
        }
      }
    }

    .run-scroll {
      width: 100%;
      height: calc(100% - 100px);
    }

    .table {
      width: 100%;
      height: calc(100% - 240px);
    }

    /deep/.el-table .cell {
      text-align: center;
      color: #000;
    }

    .table-run {
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        cursor: pointer;
      }
    }

    .el-pagination {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
    }
  }

}

.run {
  width: 100%;
  height: 100%;

}
</style>
