<template>
  <div class="son">
    <div class="son-head">
      <div class="son-head-item">
        <div class="son-head-left">
          <div class="son-head-open"></div>
          <el-input placeholder="请输入搜索内容"></el-input>
        </div>
        <div class="son-head-center">
          <span>切换机构：</span>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="son-head-right">
          <span class="el-icon-success success"></span>
          <div class="div">
            <div class="div1">
              <div class="blue"></div>
            </div>
          </div>
          <span class="el-icon-lock lock"></span>
          <span class="el-icon-view lock"></span>
          <span class="el-icon-rank rank"></span>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <!-- <div class="son-head-item">
        <div class="son-head-title">
          <span>预约管理</span>
        </div>
        <el-button type="primary">
          <span>更多</span>
          <span class="el-icon-arrow-down icon"></span>
        </el-button>
      </div> -->
    </div>
    <div class="son-scroll">
      <div class="son-search">
        <div class="search-item">
          <div class="key">
            <span>姓名</span>
          </div>
          <el-input placeholder="姓名"></el-input>
        </div>
        <div class="search-item">
          <div class="key">
            <span>身份证号</span>
          </div>
          <el-input placeholder="身份证号"></el-input>
        </div>
        <div class="search-item search-item1">
          <div class="key">
            <span>性别</span>
          </div>
          <el-select v-model="value" placeholder="">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="search-item search-item1">
          <div class="key">
            <span>体检结果</span>
          </div>
          <el-select v-model="value" placeholder="">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="search-item search-item1">
          <div class="key">
            <span>PPD</span>
          </div>
          <el-select v-model="value" placeholder="">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="search-item search-item1">
          <div class="key">
            <span>胸透</span>
          </div>
          <el-select v-model="value" placeholder="">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="search-item search-item1">
          <div class="key">
            <span>五官</span>
          </div>
          <el-select v-model="value" placeholder="">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="search-item search-item1">
          <div class="key">
            <span>色觉</span>
          </div>
          <el-select v-model="value" placeholder="">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="search-item search-item1">
          <div class="key">
            <span>内科</span>
          </div>
          <el-select v-model="value" placeholder="">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="search-item search-item1">
          <div class="key">
            <span>血压心率</span>
          </div>
          <el-select v-model="value" placeholder="">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="search-item search-item1">
          <div class="key">
            <span>眼科</span>
          </div>
          <el-select v-model="value" placeholder="">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="search-item search-item1">
          <div class="key">
            <span>外科</span>
          </div>
          <el-select v-model="value" placeholder="">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="search-item">
          <div class="key">
            <span>肝功能</span>
          </div>
          <el-select v-model="value" placeholder="">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="search-item">
          <div class="key">
            <span>全科体检状态</span>
          </div>
          <el-select v-model="value" placeholder="">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="search-item search-item1">
          <div class="key">
            <span>体检异常</span>
          </div>
          <el-select v-model="value" placeholder="">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="search-item search-item2">
          <div class="key">
            <span>学院</span>
          </div>
          <el-select v-model="value" placeholder="">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="search-item search-item2">
          <div class="key">
            <span>专业</span>
          </div>
          <el-select v-model="value" placeholder="">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="search-item search-item2">
          <div class="key">
            <span>班级</span>
          </div>
          <el-select v-model="value" placeholder="">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="search-item search-item2">
          <div class="key">
            <span>年度</span>
          </div>
          <el-select v-model="value" placeholder="">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="search-item">
          <div class="key">
            <span>体检日期</span>
          </div>
          <el-date-picker v-model="value1" type="daterange" range-separator="至" start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </div>
        <div class="search-item">
          <div class="key">
            <span>建议备注</span>
          </div>
          <el-input placeholder="请输入"></el-input>
        </div>

        <div class="button">
          <el-button>
            <span class="el-icon-search"></span>
            <span>查询</span>
          </el-button>
          <el-button>
            <span class="el-icon-refresh"></span>
            <span>重置</span>
          </el-button>
          <el-button>
            <span class="el-icon-upload"></span>
            <span>导出</span>
          </el-button>
        </div>
      </div>

      <div class="son-info">
        <div class="table">
          <el-table :data="tableData" height="100%" style="width: 100%">
            <el-table-column prop="zz" label="序号" width="70">
            </el-table-column>
            <el-table-column prop="name" label="姓名" width="100">
            </el-table-column>
            <el-table-column prop="zz" label="身份证号" width="200">
            </el-table-column>
            <el-table-column prop="zz" label="年龄" width="100">
            </el-table-column>
            <el-table-column prop="zz" label="性别" width="100">
            </el-table-column>
            <el-table-column prop="zz" label="所属机构" width="160">
            </el-table-column>
            <el-table-column prop="zz" label="条纹码" width="160">
            </el-table-column>
            <el-table-column prop="zz" label="体检年份" width="160">
            </el-table-column>
            <el-table-column prop="zz" label="体检表" width="160">
              <template>
                <div class="flex">
                  <span class="red">未上传</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="320" fixed="right">
              <template>
                <div class="flex">
                  <span class="blue">编辑</span>
                  <span class="blue">同步</span>
                  <span class="blue">上传</span>
                  <span class="blue">查看上传</span>
                  <span class="blue">报告预览</span>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
          background :page-size="10" layout="total, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {
      options: [{
        value: '',
        label: ''
      }],
      value: '',
      value1: '',
      tableData: [],
      total: 400,
      currentPage: 0,
      showSet: false,
      showAdd: false
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    },
    openSet () {
      this.showSet = true
    },
    clsoeSet () {
      this.showSet = false
    },
    openAdd () {
      this.showAdd = true
    },
    closeAdd () {
      this.showAdd = false
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
    }
  }
}
</script>

<style lang="less" scoped>
.son {
  width: 100%;
  height: 100%;
  background-color: #F0F0F0;

  .son-head {
    display: flex;
    align-items: center;
    width: 100%;
    height: 70px;
    margin-bottom: 10px;
    background-color: #fff;
    box-sizing: border-box;

    .son-head-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 0 14px;
      box-sizing: border-box;

      .son-head-left {
        display: flex;
        align-items: center;
        width: 33%;

        .son-head-open {
          width: 23px;
          height: 6px;
          border-top: 2px solid #595959;
          border-bottom: 2px solid #595959;
        }

        .el-input {
          margin-left: 50px;
        }

        /deep/.el-input__inner {
          border-color: #fff;
          font-size: 18px;
        }
      }

      .son-head-center {
        display: flex;
        align-items: center;
        flex: 1;
        color: #595959;
        font-size: 20px;

        /deep/.el-input__inner {
          font-size: 18px;
        }
      }

      .son-head-right {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex: 1;

        .success {
          color: #67C23A;
          font-size: 39px;
        }

        .lock {
          color: #595959;
          font-size: 30px;
        }

        .rank {
          font-size: 34px;
          transform: rotate(45deg);
        }

        .div {
          width: 41px;
          height: 40px;
          padding: 6px 7px;
          border: 1px solid #E6E6E6;
          border-radius: 6px;
          box-sizing: border-box;

          .div1 {
            width: 100%;
            height: 100%;
            padding-top: 3px;
            border: 1px solid #999999;
            box-sizing: border-box;

            .blue {
              width: 100%;
              height: 100%;
              background-color: #409EFF;
            }
          }
        }

        /deep/.el-input__inner {
          text-align: right;
          border: none;
          font-size: 18px;
        }
      }

      .son-head-title {
        width: 117px;
        height: 38px;
        line-height: 38px;
        text-align: center;
        border: 1px solid #409EFF;
        border-radius: 2px;
        box-sizing: border-box;
        color: #409EFF;
        font-size: 16px;
      }

      .el-button {
        width: 100px;

        .icon {
          margin-left: 10px;
        }
      }
    }
  }

  .son-scroll {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 80px);
    padding: 0 10px;
    box-sizing: border-box;

    &::-webkit-scrollbar {
      width: 0;
    }

    .son-search {
      display: flex;
      align-content: space-evenly;
      justify-content: space-evenly;
      flex-wrap: wrap;
      width: 100%;
      height: 320px;
      background-color: #fff;
      border-radius: 18px;
      box-sizing: border-box;

      .search-item {
        display: flex;
        align-items: center;
        width: calc(25% - 40px);

        .el-select {
          width: 100px;
        }
      }

      .search-item1 {
        width: calc(12.5% - 20px);
      }

      .search-item2 {
        width: calc(20% - 20px);

        .el-select {
          width: 200px;
        }
      }

      .key {
        margin-right: 20px;
        white-space: nowrap;
        color: #17212B;
        font-size: 22px;
      }

      .el-button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        margin-right: 20px;
        padding-top: 0;
        padding-bottom: 0;
        background-color: #3FA1FF;
        color: #fff;
        font-size: 22px;
      }

      .button {
        display: flex;
        align-items: center;
        width: calc(25% - 40px);
      }
    }

    .son-info {
      width: 100%;
      height: calc(100% - 330px);
      margin-top: 10px;
      padding: 0 20px;
      background-color: #fff;
      border-radius: 18px;
      box-sizing: border-box;

      .table {
        width: 100%;
        height: calc(100% - 90px);
      }

      // 表格样式修改
      .el-table {

        &::before {
          background-color: transparent;
        }

        // 表头首行
        /deep/th.el-table__cell.is-leaf {
          border-bottom: none;
        }

        /deep/.el-table__cell {
          text-align: center;
          color: #17212B;
        }

        /deep/tr {
          background-color: #C9D8E7;
        }

        /deep/.el-checkbox__inner {
          background-color: transparent;
        }

        /deep/.is-indeterminate .el-checkbox__inner,
        /deep/.is-checked .el-checkbox__inner {
          background-color: #04B4D0;
        }

        /deep/.el-table__empty-text {
          color: #fff;
        }

        .flex {
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;
          width: 100%;

          .item {
            font-size: 14px;
            cursor: pointer;
          }

          .green {
            color: #17AF12;
          }

          .organ {
            color: #FF6600;
          }

          .red {
            color: #FF0000;
          }

          .blue {
            color: #399BFB;
            font-size: 16px;
            cursor: pointer;
          }
        }
      }

      // 页码样式修改
      .el-pagination {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: 20px;

        /deep/.el-pagination__total,
        /deep/.el-pagination__jump {
          color: #000000;

          .el-input__inner {
            color: #000000;
            background-color: transparent;
          }
        }

        /deep/.el-pager li:not(.disabled).active {
          background-color: #01EEFD;
        }
      }
    }
  }
}
</style>
