var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"popup"},[_c('div',{staticClass:"popup-content"},[_vm._m(0),_c('div',{staticClass:"popup-box"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"close",on:{"click":_vm.close}},[_c('i',{staticClass:"el-icon-close"})]),_c('div',{staticClass:"content"},[_vm._m(1),_c('div',{staticClass:"news"},[_c('div',{staticClass:"doctor-info"},[_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_c('div',{staticClass:"btns"},[_c('el-button',{attrs:{"size":"small"}},[_vm._v("在线开方")]),_c('el-button',{attrs:{"size":"small"}},[_vm._v("健康档案")]),_c('el-button',{attrs:{"size":"small"}},[_vm._v("辅助监测")]),_c('el-button',{attrs:{"size":"small"}},[_vm._v("会诊")])],1)]),_c('div',{staticClass:"sort"},_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"item"},[_c('img',{staticClass:"img",attrs:{"src":item.url}}),_c('div',{staticClass:"sort-info"},[_c('span',{staticClass:"label"},[_vm._v(_vm._s(item.label))]),_c('span',[_vm._v(_vm._s(item.value))])])])}),0)])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"popup-title"},[_c('span',[_vm._v("视频通话")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"video"},[_c('div',{staticClass:"user"}),_c('div',{staticClass:"doctor"}),_c('div',{staticClass:"operate"},[_c('img',{staticClass:"sound",attrs:{"src":require("../../../../../assets/img/smartConsultation/sound.png")}}),_c('img',{staticClass:"hang-up",attrs:{"src":require("../../../../../assets/img/smartConsultation/hang-up.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('span',[_vm._v("医生资料")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"date"},[_c('span',[_vm._v("开始时间：2019-09-12 12：30")]),_c('span',[_vm._v("有效期至：2019-09-12 12：30")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"line"},[_c('span',[_vm._v("病情描述：近几个月睡眠很不好，失眠多梦，盗汗，早上醒来感觉浑上班也没有精神，整个人状态都不好了，吃了一身疲累.些西药，感觉效果也不明显，不知道怎么办些西药，感觉效果也不明显，不知道怎么办")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"line"},[_c('span',[_vm._v("过敏史：有5年的过敏性皇炎历史特别害怕花粉、灰尘医类的逢季节交替时比较明明显流鼻涕打喷嚏不间断，一直靠皇炎喷剂维持，没有做过手术")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"line"},[_c('span',[_vm._v("患者处方：请根据患者病情描述和问诊单反馈.，为患者开出诊断建议方")])])
}]

export { render, staticRenderFns }