<template>
  <div class="write">
    <div class="title">
      <span>详情</span>
    </div>
    <div class="content">
      <div @click="closePopup" class="close">
        <span class="el-icon-close"></span>
      </div>
      <div class="box">
        <div class="span span1">
          <span>老人：张国峰</span>
        </div>
        <div class="span">
          <span>精神慰藉内容：</span>
          <span class="width">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod
bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra
justo commodo. Proin sodales pulvinar sic tempor. Sociis natoque penatibus
et magnis dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla
 luctus pharetra vulputate, felis tellus mollis orci, sed rhoncus pronin sapien
nunc accuan eget.
          </span>
        </div>
      </div>
      <div class="btns">
        <el-button @click="closePopup">取消</el-button>
        <el-button class="button">确认</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {}
  },
  methods: {
    closePopup () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.write {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 888;
  width: 100%;
  height: 100%;
  padding: 0 400px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, .9);

  .title {
    width: 180px;
    height: 53px;
    padding-top: 5px;
    box-sizing: border-box;
    line-height: 53px;
    text-align: center;
    background-image: url('../../../img/ssjk/title.png');
    background-size: 100% 100%;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
  }

  .content {
    position: relative;
    width: 100%;
    height: 400px;
    margin-top: 10px;
    padding: 20px 65px;
    background-image: url('../../../img/ssjk/background4.png');
    background-size: 100% 100%;
    box-sizing: border-box;

    .close {
      position: absolute;
      top: 14px;
      right: 16px;
      color: #fff;
      font-size: 20px;
      cursor: pointer;
    }

    .content-title {
      margin-top: 20px;
      color: #01EEFD;
      font-size: 18px;
    }

    .box {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 100%;
      height: calc(100% - 70px);
      color: #01EEFD;
      font-size: 18px;

      .span {
        display: flex;

        span {
          white-space: nowrap;
          margin-right: 10px;
        }

        .width {
          width: 600px;
          white-space: pre-wrap;
        }
      }

      .span1 {
        align-items: center;
      }
    }

    .btns {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 240px;
      height: 46px;
      margin: 0 auto;

      .el-button {
        height: 30px;
        line-height: 30px;
        padding: 0 10px;
        text-align: center;
        color: #fff;
        font-size: 14px;
        background-color: #33465C;
        border-radius: 0;
        border: none;
      }

      .button {
        background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
      }
    }
  }
}
</style>
