<template>
  <div class="popup">
    <div class="popup-box">
      <div class="popup-title">
        <div class="popup-top"></div>
        <div class="popup-bottom">
          <div class="popup-bottom-text">
            <span>删除确认</span>
          </div>
        </div>
      </div>

      <div class="popup-content">
        <div class="popup-content-box">
          <div class="info-close" @click="close">
            <span class="el-icon-close"></span>
          </div>
          <div class="info-title">
            <span>删除确认</span>
          </div>
          <div class="info-tip">
            <span>删除后无法恢复，确定要删除此条信息吗？</span>
          </div>
          <div class="info-btns">
            <el-button size="small" @click="close">取消</el-button>
            <el-button size="small" class="blue" @click="close">确定</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {

  },
  data () {
    return {}
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.popup {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 888;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(7, 18, 36, .82);
  box-sizing: border-box;

  .popup-box {
    display: flex;
    flex-direction: column;
    width: 607px;
  }

  .popup-title {
    display: flex;
    flex-direction: column;

    .popup-top {
      position: relative;
      width: 87px;
      height: 6px;
      transform: skew(30deg);
      background-color: #03BCD7;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: -6px;
        width: 10px;
        height: 6px;
        background-color: #081C38;
        transform: skew(-50deg);
      }
    }

    .popup-bottom {
      overflow: hidden;
      position: relative;
      width: 148px;
      height: 30px;
      background: linear-gradient(97deg, #0177CD 0%, #024073 100%);
      transform: skew(30deg);

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: -30px;
        width: 20px;
        height: 30px;
        background-color: #081C38;
        transform: skew(-55deg);
      }

      .popup-bottom-text {
        height: 30px;
        line-height: 30px;
        padding-left: 20px;
        box-sizing: border-box;
        transform: skew(-30deg);
        color: #fff;
        font-size: 20px;
        font-weight: bold;
      }
    }
  }

  .popup-content {
    width: 100%;
    height: 322px;
    margin-top: 11px;
    padding: 10px;
    background: linear-gradient(93deg, rgba(10, 71, 123, .27) 0%, rgba(17, 94, 156, .27) 100%);
    border: 4px solid rgba(1, 124, 219, .27);
    box-sizing: border-box;

    .popup-content-box {
      overflow: hidden;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      width: 100%;
      height: 100%;
      border: 4px solid rgba(1, 124, 219, .27);
      box-sizing: border-box;

      .info-close {
        position: absolute;
        top: -15px;
        right: -40px;
        width: 100px;
        height: 45px;
        line-height: 60px;
        text-align: center;
        transform: rotate(30deg);
        background: linear-gradient(117deg, #1E58A6 0%, #1C4EA1 53%, #2866B1 100%);
        box-shadow: inset 0px -13px 0px 1px #2661AB;
        color: #fff;
        font-size: 24px;
        box-sizing: border-box;

        span {
          transform: rotate(-30deg);
        }
      }

      .info-title {
        width: 100%;
        text-align: center;
        color: #04B4D0;
        font-size: 20px;
        font-weight: 600;
      }

      .info-tip {
        width: 100%;
        text-align: center;
        color: #04B4D0;
        font-size: 18px;
      }

      .info-btns {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .el-button {
          margin: 0 40px;
          background-color: #33465C;
          border: none;
          color: #fff;
        }

        .blue {
          background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
        }
      }
    }
  }
}
</style>
