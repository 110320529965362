<template>
  <div class="popup">
    <div class="popup-content">
      <div class="popup-title">
        <span>缴费结算</span>
      </div>
      <div class="popup-box">
        <div class="content">
          <div class="close" @click="close">
            <i class="el-icon-close"></i>
          </div>
          <div class="scroll">
            <div class="tip">
              <div class="line">
                <span class="span">老人姓名：</span>
                <span>张三</span>
              </div>
              <div class="line">
                <span class="span">身份证/护照号：</span>
                <span>3293819982929212</span>
              </div>
              <div class="line">
                <span class="span">床位号：</span>
                <span>504/01</span>
              </div>
              <div class="line">
                <span class="span">入住时间：</span>
                <span>2020-03-03 至 2020-05-05</span>
              </div>
            </div>
            <div class="tip tip1">
              <div class="line">
                <span class="span">核定时间段: </span>
                <span>2020-03-03 至 2020-05-05</span>
              </div>
              <div class="line">
                <span class="span">核定天数：</span>
                <span>30天</span>
              </div>
              <div class="line line1">
                <span class="span">费用明细：</span>
                <div class="table">
                  <div class="head">
                    <span class="flex">序号</span>
                    <span class="flex">项目名称</span>
                    <span class="flex">金额</span>
                  </div>
                  <div class="tr">
                    <span class="flex">1</span>
                    <span class="flex">床位费</span>
                    <span class="flex flex1">3000.00</span>
                  </div>
                  <div class="tr">
                    <span class="flex">2</span>
                    <span class="flex">护理费</span>
                    <span class="flex flex1">3000.00</span>
                  </div>
                  <div class="tr">
                    <span class="flex">3</span>
                    <span class="flex">膳食费</span>
                    <span class="flex flex1">3000.00</span>
                  </div>
                  <div class="tr">
                    <span class="flex">4</span>
                    <span class="flex">押金</span>
                    <span class="flex flex1">3000.00</span>
                  </div>
                  <div class="tr">
                    <span class="flex">5</span>
                    <span class="flex">一次性生活费</span>
                    <span class="flex flex1">3000.00</span>
                  </div>
                  <div class="tr">
                    <span class="flex">6</span>
                    <span class="flex">定金抵扣</span>
                    <span class="flex flex1">3000.00</span>
                  </div>
                  <div class="tr tip1">
                    <span class="flex"></span>
                    <span class="flex">合计</span>
                    <span class="flex flex1">3000.00</span>
                  </div>
                </div>
              </div>
              <div class="line">
                <span>核定缴费金额：3000.00元</span>
              </div>
            </div>
          </div>

          <div class="btn">
            <el-button size="small" @click="close">取消</el-button>
            <el-button size="small" @click="submit">确认收款</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      input: ''
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    submit () {
      this.$emit('close', this.input)
    }
  }
}
</script>

<style scoped lang="less">
.popup {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 88;
  background-color: rgba(0, 0, 0, .74);

  .popup-content {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    width: 859px;
    height: 264px;

    .popup-title {
      width: 147px;
      height: 50px;
      line-height: 60px;
      background-image: url('../../../../img/dialogTitleBg.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      text-align: center;
      color: #fff;
      font-size: 20px;
    }

    .popup-box {
      width: 1000px;
      height: 760px;
      padding: 10px;
      background-color: rgba(3, 27, 44, .8);
      border-radius: 10px;
      border: 4px solid rgba(1, 49, 88, .5);
      box-sizing: border-box;

      .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        width: 100%;
        height: 100%;
        padding: 30px;
        border-radius: 10px;
        border: 4px solid rgba(1, 49, 88, .5);
        box-sizing: border-box;

        .close {
          display: flex;
          align-items: flex-end;
          position: absolute;
          top: 0;
          right: 0;
          width: 50px;
          height: 50px;
          background: linear-gradient(26.565deg, #2057a7 50%, transparent 0);
          transform: rotate(180deg);
          color: #fff;
          font-size: 24px;
          cursor: pointer;
        }

        .scroll {
          overflow-y: scroll;
          width: 100%;
          height: calc(100% - 40px);

          &::-webkit-scrollbar {
            width: 0;
          }
        }

        .tip {
          display: flex;
          flex-direction: column;
          text-align: center;
          border-bottom: 1px solid #01EEFD;
          box-sizing: border-box;
          color: #01EEFD;
          font-size: 16px;

          .span {
            margin-top: 6px;
            font-size: 16px;
          }

          .line {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            margin-bottom: 10px;
            box-sizing: border-box;
            color: #01EEFD;
            font-size: 16px;

            .span {
              width: 130px;
              text-align: left;
            }

            .table {
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 500px;
              border: 4px solid #073F73;
              box-sizing: border-box;

              .head {
                display: flex;
                flex-direction: row;
                width: 100%;
                height: 44px;
                background-color: #024276;
                color: #01EEFD;
                font-size: 17px;

                .flex {
                  flex: 1;
                  height: 44px;
                  line-height: 44px;
                  text-align: center;
                }
              }

              .tr {
                display: flex;
                flex-direction: row;
                width: 100%;
                height: 44px;
                border-bottom: 1px solid #01EEFD;
                box-sizing: border-box;
                color: #01EEFD;
                font-size: 17px;

                .flex {
                  flex: 1;
                  height: 44px;
                  line-height: 44px;
                  border-right: 1px solid #01EEFD;
                  box-sizing: border-box;
                  text-align: center;
                }

                .flex1 {
                  border: none;
                }
              }
            }
          }

          .line1 {
            align-items: flex-start;
          }
        }

        .tip1 {
          border: none !important;
        }

        .btn {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 240px;
          margin: 0 auto;
        }

        .el-button {
          border-radius: 0;
          border: none;
          background-color: #33465C;
          color: #fff;
        }

        .el-button:nth-child(2) {
          background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
        }
      }
    }
  }
}
</style>
