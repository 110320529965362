<template>
  <div class="popup">
    <div class="popup-box">
      <div class="popup-title">
        <div class="popup-top"></div>
        <div class="popup-bottom">
          <div class="popup-bottom-text">
            <span>新增</span>
          </div>
        </div>
      </div>

      <div class="popup-content">
        <div class="popup-content-box">
          <div class="info-close" @click="close">
            <span class="el-icon-close"></span>
          </div>
          <div class="info-content">
            <div class="info-item">
              <span class="key">省</span>
              <el-select v-model="params.provinceId" placeholder="" @change="Areachange">
                <el-option v-for="item in provinces" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div class="info-item">
              <span class="key">市</span>
              <el-select v-model="params.cityId" placeholder="" @change="CityChange">
                <el-option v-for="item in citys" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div class="info-item">
              <span class="key">县</span>
              <el-select v-model="params.countryId" placeholder="" @change="CountyChange">
                <el-option v-for="item in countrys" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div class="info-item">
              <span class="key">乡镇</span>
              <el-select v-model="params.townId" placeholder="" @change="TownChange">
                <el-option v-for="item in towns" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div class="info-item">
              <span class="key">村</span>
              <el-select v-model="params.villageId" placeholder="" @change="VillageChange">
                <el-option v-for="item in villages" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div class="info-item">
              <span class="key">机构编号</span>
              <el-input v-model="params.jgbh" placeholder=""></el-input>
            </div>
            <div class="info-item">
              <span class="key">机构名称</span>
              <el-input v-model="params.jddw" placeholder=""></el-input>
            </div>
            <div class="info-item">
              <span class="key">机构级别</span>
              <el-select v-model="params.jgjb" placeholder="">
                <el-option v-for="item in jgjbs" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div class="info-item">
              <span class="key">地址</span>
              <el-input v-model="params.address" placeholder=""></el-input>
            </div>
            <div class="info-item">
              <span class="key">机构简介</span>
              <el-input v-model="params.jgjj" placeholder=""></el-input>
            </div>
            <div class="info-item">
              <span class="key">服务总数</span>
              <el-input v-model="params.fwzs" placeholder=""></el-input>
            </div>
            <div class="info-item">
              <span class="key">机构床位数</span>
              <el-input v-model="params.jgcws" placeholder=""></el-input>
            </div>
          </div>
          <div class="info-btns">
            <el-button size="small" @click="close">取消</el-button>
            <el-button size="small" class="blue" @click="submit">保存</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {
      params: {
        id: '',
        xzjdmc: '',
        cwhmc: '',
        jddw: '',
        jdr: '',
        zeys: '',
        zeysqm: '',
        jdrq: '',
        lrrq: '',
        jgbm: '',
        jgjb: '',
        jgbh: '',
        province: '',
        city: '',
        country: '',
        countryId: '',
        town: '',
        village: '',
        xqbh: '',
        shbh: '',
        ytjbh: '',
        cwhmcid: '',
        bchaory: '',
        bchaoryqm: '',
        bchaosqry: '',
        bchaosqryqm: '',
        hyry: '',
        shry: '',
        shryqm: '',
        xcgry: '',
        xcgryqm: '',
        ncgry: '',
        ncgryqm: '',
        hyryqm: '',
        xdtry: '',
        xdtryqm: '',
        bchaory1: '',
        hyry1: '',
        beiy1: '',
        beiy2: '',
        beiy3: '',
        ptcode: '',
        pttoken: '',
        ptuser: '',
        username: '',
        label: '',
        value: '',
        tjjglist: '',
        qmtype: '',
        zjhm: '',
        name: '',
        openid: '',
        ocrtype: '',
        tjly: '',
        countyid: '',
        townid: '',
        townname: '',
        flag: '',
        lytype: '',
        mxtype: '',
        ssjgbh: '',
        sjjgbh: '',
        type: '',
        hsjcryqm: '',
        xcgryqmflag: '',
        ncgryqmflag: '',
        bchaoryqmflag: '',
        shryqmflag: '',
        xdtqmflag: '',
        hsjcqmflag: '',
        ptsqms: '',
        tjms: '',
        tbms: '',
        pdssjg: '',
        villagename: '',
        start: '',
        length: '',
        sjjgmc: '',
        sjscms: '',
        scgz: '',
        ptdnflag: '',
        pdfdc: '',
        kysbid: '',
        cyjgbh: '',
        lng: '',
        lat: '',
        usercode: '',
        dwzb: '',
        sbbm: '',
        ptjgid: '',
        fileData: ''
      },
      provinces: [],
      citys: [],
      countrys: [],
      towns: [],
      villages: [],
      jgjbs: [{
        value: '1',
        label: '省'
      }, {
        value: '2',
        label: '市'
      }, {
        value: '3',
        label: '县'
      }, {
        value: '4',
        label: '乡'
      }, {
        value: '5',
        label: '村'
      }]
    }
  },
  created () {
    this.pchange()
  },
  methods: {
    close () {
      this.$emit('close')
    },
    async submit () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-181140492
      try {
        if (this.params.jgbh == null || this.params.jgbh === '') {
          this.params.jgbh = this.$parent.params.jgbh
        }
        const { data: res } = await this.$http.post('/api/jkct/zhgw/savejg', this.params)
        console.log('机构保存 ', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.$message.success('保存成功')
        this.$emit('close', 'loading')
      } catch (error) {
        console.log('机构保存 请求失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    async pchange () {
      try {
        this.params.cityId = ''
        const res = await this.$http.get('/api/jkct/getProvinces')
        console.log('省份获取 ', res)
        if (res.status !== 200) return this.$message.error(res.msg || res.message)
        this.provinces = res.data.map(item => {
          return {
            label: item.proviceName,
            value: item.proviceId
          }
        })
      } catch (error) {
        console.log('省份获取 请求失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    async Areachange () {
      this.params.cityId = ''
      this.params.countryId = ''
      this.params.townId = ''
      this.params.villageId = ''
      console.log(this.params.province)
      const arr = this.provinces.filter(item => item.value === this.params.provinceId)
      if (arr.length > 0) {
        this.params.province = arr[0].label
      }
      const provinceCode = this.params.provinceId
      try {
        const res = await this.$http.get('/api/jkct/getCityArea', { params: { provinceCode } })
        console.log('市获取 ', res)
        if (res.status !== 200) return this.$message.error(res.msg || res.message)
        this.citys = res.data.map(item => {
          return {
            label: item.cityName,
            value: item.cityId
          }
        })
      } catch (error) {
        console.log('市获取 请求失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    async CityChange () {
      this.params.countryId = ''
      this.params.townId = ''
      this.params.villageId = ''
      const arr = this.citys.filter(item => item.value === this.params.cityId)
      if (arr.length > 0) {
        this.params.city = arr[0].label
      }
      console.log(this.params.city)
      const cityCode = this.params.cityId
      try {
        const res = await this.$http.get('/api/jkct/getCountyArea', { params: { cityCode } })
        console.log('县获取 ', res)
        if (res.status !== 200) return this.$message.error(res.msg || res.message)
        this.countrys = res.data.map(item => {
          return {
            label: item.countyName,
            value: item.countyId
          }
        })
      } catch (error) {
        console.log('县获取 请求失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    async CountyChange () {
      this.params.townId = ''
      this.params.villageId = ''
      const arr = this.countrys.filter(item => item.value === this.params.countryId)
      if (arr.length > 0) {
        this.params.country = arr[0].label
      }
      const countyCode = this.params.countryId
      try {
        const res = await this.$http.get('/api/jkct/getTownArea', { params: { countyCode } })
        console.log('乡镇获取 ', res)
        if (res.status !== 200) return this.$message.error(res.msg || res.message)
        this.towns = res.data.map(item => {
          return {
            label: item.townName,
            value: item.townId
          }
        })
      } catch (error) {
        console.log('乡镇获取 请求失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    async TownChange () {
      this.params.villageId = ''
      const arr = this.towns.filter(item => item.value === this.params.townId)
      if (arr.length > 0) {
        this.params.town = arr[0].label
      }
      const townCode = this.params.townId
      try {
        const res = await this.$http.get('/api/jkct/getVillageArea', { params: { townCode } })
        console.log('村获取 ', res)
        if (res.status !== 200) return this.$message.error(res.msg || res.message)
        this.villages = res.data.map(item => {
          return {
            label: item.villageName,
            value: item.villageId
          }
        })
      } catch (error) {
        console.log('村获取 请求失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    VillageChange () {
      const arr = this.villages.filter(item => item.value === this.params.villageId)
      if (arr.length > 0) {
        this.params.village = arr[0].label
        this.params.villageId = arr[0].value
      }
    }
  }
}
</script>

<style lang="less" scoped>
.popup {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 888;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .82);
  box-sizing: border-box;

  .popup-box {
    display: flex;
    flex-direction: column;
    width: 1160px;
  }

  .popup-title {
    display: flex;
    flex-direction: column;

    .popup-top {
      position: relative;
      width: 87px;
      height: 6px;
      transform: skew(30deg);
      background-color: #03BCD7;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: -6px;
        width: 10px;
        height: 6px;
        background-color: #081C38;
        transform: skew(-50deg);
      }
    }

    .popup-bottom {
      overflow: hidden;
      position: relative;
      width: 148px;
      height: 30px;
      background: linear-gradient(97deg, #0177CD 0%, #024073 100%);
      transform: skew(30deg);

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: -30px;
        width: 20px;
        height: 30px;
        background-color: #081C38;
        transform: skew(-55deg);
      }

      .popup-bottom-text {
        height: 30px;
        line-height: 30px;
        padding-left: 20px;
        box-sizing: border-box;
        transform: skew(-30deg);
        color: #fff;
        font-size: 20px;
        font-weight: bold;
      }
    }
  }

  .popup-content {
    width: 100%;
    height: 520px;
    margin-top: 11px;
    padding: 10px;
    background: linear-gradient(93deg, rgba(10, 71, 123, .27) 0%, rgba(17, 94, 156, .27) 100%);
    border: 4px solid rgba(1, 124, 219, .27);
    box-sizing: border-box;

    .popup-content-box {
      overflow: hidden;
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      padding: 10px 30px;
      border: 4px solid rgba(1, 124, 219, .27);
      box-sizing: border-box;

      .info-close {
        position: absolute;
        top: -15px;
        right: -40px;
        width: 100px;
        height: 45px;
        line-height: 60px;
        text-align: center;
        transform: rotate(30deg);
        background: linear-gradient(117deg, #1E58A6 0%, #1C4EA1 53%, #2866B1 100%);
        box-shadow: inset 0px -13px 0px 1px #2661AB;
        color: #fff;
        font-size: 24px;
        box-sizing: border-box;
        cursor: pointer;

        span {
          transform: rotate(-30deg);
        }
      }

      .info-content {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        height: calc(100% - 50px);

        .info-item {
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 50%;
          margin: 10px 0;

          .key {
            width: 110px;
            color: #01EEFD;
            font-size: 18px;
          }

          .el-input {
            width: 200px;
          }

          .el-select {
            width: 200px;
          }
        }
      }

      .info-btns {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 60px;

        .el-button {
          margin: 0 60px;
          background-color: #33465C;
          border: none;
          color: #fff;
        }

        .blue {
          background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
        }
      }
    }
  }
}
</style>
