<template>
  <div class="see">
    <div class="see-status">
      <span>预定待提交</span>
    </div>
    <div class="see-title">
      <div class="title">
        <span>预定详情</span>
      </div>
      <el-input placeholder="姓名/身份证号" suffix-icon="el-icon-search" class="input">
      </el-input>
    </div>
    <div class="see-box">
      <div class="see-box-title">
        <span>老人信息</span>
      </div>
      <div class="see-content">
        <div class="see-item">
          <div class="see-key">
            <span>老人姓名：李明明</span>
          </div>
        </div>
        <div class="see-item">
          <div class="see-key">
            <span>身份证/护照号：1307301999808084658</span>
          </div>
        </div>
        <div class="see-item">
          <div class="see-key">
            <span>床位号：504/01</span>
          </div>
        </div>
      </div>
      <div class="see-box-title1">
        <span>预定信息</span>
      </div>
      <div class="see-content">
        <div class="see-item">
          <div class="see-key">
            <span>预定人姓名：陈菊英</span>
          </div>
        </div>
        <div class="see-item">
          <div class="see-key">
            <span>预定人电话：1776808982</span>
          </div>
        </div>
        <div class="see-item">
          <div class="see-key">
            <span>与老人关系：父子</span>
          </div>
        </div>
        <div class="see-item">
          <div class="see-key">
            <span>预定床位号：501-2</span>
          </div>
        </div>
        <div class="see-item">
          <div class="see-key">
            <span>开始日期：2020-02-09</span>
          </div>
        </div>
        <div class="see-item">
          <div class="see-key">
            <span>预定时长(天)：4</span>
          </div>
        </div>
        <div class="see-item see-item1">
          <div class="see-key">
            <span>定金应收：4000.00 </span>
          </div>
        </div>
      </div>
      <div class="see-box-title1">
        <span>预定协议</span>
      </div>
      <div class="see-img">
        <img src="" alt="" class="img">
        <img src="" alt="" class="img">
        <img src="" alt="" class="img">
      </div>
    </div>
    <div class="see-btns">
      <el-button size="small" class="gray" @click="close">清空</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      input: '',
      options: [{
        value: '',
        label: ''
      }],
      value: ''
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="less">
.see {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0 40px;
  box-sizing: border-box;
  z-index: 88;
  background-color: #081C38;

  .see-status {
    position: absolute;
    top: 18%;
    right: 50px;
    color: #FF0000;
    font-size: 20px;
  }

  .see-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;

    .title {
      height: 38px;
      line-height: 38px;
      text-align: center;
      color: #01EEFD;
      font-size: 20px;
    }

    .input {
      width: 246px;

      /deep/.el-input__inner {
        border: none;
        background: linear-gradient(153deg, #0A477B 0%, #0E67AD 100%);
        color: #fff;
        font-size: 18px;
      }

      /deep/.el-input__inner::-webkit-input-placeholder {
        color: #fff;
      }

      /deep/.el-input__suffix {
        color: #fff;
        font-size: 18px;
      }
    }
  }

  .see-box {
    height: calc(100% - 140px);

    .see-box-title {
      height: 40px;
      line-height: 40px;
      padding: 0 10px;
      box-sizing: border-box;
      color: #01EEFD;
      font-size: 18px;
    }

    .see-content {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 10px 20px;
      box-sizing: border-box;

      .see-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 25%;
        margin-bottom: 20px;

        .see-key {
          white-space: nowrap;
          color: #01EEFD;
          font-size: 18px;
        }

        .el-input {
          width: 180px;
        }
      }

      .see-item1 {
        width: 50%;
      }
    }

    .see-box-title1 {
      height: 40px;
      line-height: 40px;
      border-bottom: 1px solid #01EEFD;
      box-sizing: border-box;
      color: #01EEFD;
      font-size: 20px;
    }

    .see-upload {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 60px;
      padding: 0 10px;
      box-sizing: border-box;

      .el-button {
        background-color: #04B4D0;
        border: none;
        color: #fff;
        font-size: 18px;
      }

      .see-tip {
        margin-left: 30px;
        color: #fff;
        font-size: 22px;
      }
    }

    .see-img {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 240px;

      .img {
        width: 141px;
        height: 199px;
        margin-left: 20px;
        background-color: #fff;
      }
    }
  }

  .see-btns {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 60px;

    .el-button {
      margin-right: 30px;
      background-color: #024A83;
      border: none;
      color: #fff;
    }

    .gray {
      background-color: #33465C;
    }
  }

}
</style>
