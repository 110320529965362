<template>
  <div class="add">
    <div class="add-title">
      <div class="title">
        <span>新增预定</span>
      </div>
      <el-input placeholder="姓名/身份证号" suffix-icon="el-icon-search" class="input">
      </el-input>
    </div>
    <div class="add-box" v-show="tabIndex === 0">
      <div class="add-status">
        <div class="add-status-item">
          <span class="add-status-icon">
            <span class="span active-span">1</span>
            <span class="text active-text">选择服务</span>
          </span>
        </div>
        <div class="add-status-line"></div>
        <div class="add-status-item">
          <span class="add-status-icon">
            <span class="span">2</span>
            <span class="text">订单确认</span>
          </span>
        </div>
      </div>
      <div class="add-info">
        <div class="add-info-left">
          <img src="../../../../img/emr/user.png" alt="" class="img">
          <div class="info">
            <span>姓名：陈菊英</span>
            <span>房间号：501/501-01</span>
            <span>手机号：19996131427</span>
          </div>
        </div>
        <div class="add-info-right">
          <div class="title">
            <span>家属信息</span>
          </div>
          <div class="table">
            <div class="tr">
              <span class="td">家属姓名</span>
              <span class="td">与老人关系</span>
              <span class="td">联系方式</span>
            </div>
            <div class="tr">
              <span class="td">张三</span>
              <span class="td">父子</span>
              <span class="td">177627382737</span>
            </div>
            <div class="tr">
              <span class="td">李四</span>
              <span class="td">妻子</span>
              <span class="td">177627382737</span>
            </div>
          </div>
        </div>
      </div>
      <div class="add-box-title">
        <span>选择床位</span>
        <span class="span">床位费合计：17000.00</span>
      </div>
      <div class="add-line">
        <div class="add-item">
          <span class="add-key">床位</span>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="add-item">
          <span class="add-key">床位单价</span>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="add-item">
          <span class="add-key">入住天数</span>
          <el-input placeholder="请输入"></el-input>
        </div>
        <div class="add-item">
          <span class="add-key">入住日期</span>
          <el-date-picker type="daterange" range-separator="至" start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </div>
      </div>

      <div class="add-box-title">
        <span>餐饮膳食</span>
        <span class="span">伙食费合计：9000.00</span>
      </div>
      <div class="add-line">
        <div class="add-item">
          <span class="add-key">套餐单价</span>
          <el-input placeholder="请输入"></el-input>
        </div>
      </div>

      <div class="add-box-title">
        <span>护理服务</span>
        <span class="span">护理费合计：9000.00</span>
      </div>
      <div class="add-line">
        <div class="add-item">
          <span class="add-key">护理费</span>
          <el-input placeholder="请输入"></el-input>
        </div>
      </div>
      <div class="add-table">
        <div class="add-tr add-tr1">
          <span class="add-td">服务项目</span>
          <span class="add-td add-td1">服务内容</span>
          <span class="add-td">合计</span>
        </div>
        <div class="add-tr add-tr2">
          <span class="add-td border-right">修饰洗浴</span>
          <span class="add-td add-td1 border-right">喂食、饮水</span>
          <span class="add-td">2</span>
        </div>
        <div class="add-tr add-tr2">
          <span class="add-td border-right">穿脱衣</span>
          <span class="add-td add-td1 border-right">口腔清洁、梳头、洗脚</span>
          <span class="add-td">2</span>
        </div>
        <div class="add-tr add-tr2">
          <span class="add-td border-right">如厕排泄</span>
          <span class="add-td add-td1 border-right">提醒如厕、辅助如厕</span>
          <span class="add-td">2</span>
        </div>
        <div class="add-tr">
          <span class="add-td border-right">移动</span>
          <span class="add-td add-td1 border-right">协助站立</span>
          <span class="add-td">2</span>
        </div>
      </div>

      <div class="add-box-title">
        <span>其他收费</span>
      </div>
      <div class="add-line">
        <div class="add-item">
          <span class="add-key">押金</span>
          <el-input placeholder="请输入"></el-input>
        </div>
        <div class="add-item">
          <span class="add-key">一次性生活费</span>
          <el-input placeholder="请输入"></el-input>
        </div>
      </div>

      <div class="add-box-title">
        <span>入院费用核定周期设置</span>
      </div>
      <div class="add-line">
        <div class="add-item">
          <span class="add-key">入院费用核定天数 </span>
          <el-input placeholder="请输入"></el-input>
        </div>
      </div>
    </div>
    <div class="add-box" v-show="tabIndex === 1">
      <div class="add-status">
        <div class="add-status-item">
          <span class="add-status-icon">
            <span class="span active-span">1</span>
            <span class="text active-text">选择服务</span>
          </span>
        </div>
        <div class="add-status-line"></div>
        <div class="add-status-item">
          <span class="add-status-icon">
            <span class="span active-span">2</span>
            <span class="text active-text">订单确认</span>
          </span>
        </div>
      </div>
      <div class="add-box-title">
        <span>订单总费用  （2020-05-05 至 2020-07-07）</span>
      </div>

      <div class="add-table">
        <div class="add-tr add-tr1">
          <span class="add-td">序号</span>
          <span class="add-td add-td1">项目名称</span>
          <span class="add-td">小计（元）</span>
        </div>
        <div class="add-tr add-tr2">
          <span class="add-td border-right">1</span>
          <span class="add-td add-td1 border-right">床位费</span>
          <span class="add-td">1000</span>
        </div>
        <div class="add-tr add-tr2">
          <span class="add-td border-right">2</span>
          <span class="add-td add-td1 border-right">护理费</span>
          <span class="add-td">2</span>
        </div>
        <div class="add-tr add-tr2">
          <span class="add-td border-right">3</span>
          <span class="add-td add-td1 border-right">膳食费</span>
          <span class="add-td">2</span>
        </div>
        <div class="add-tr add-tr2">
          <span class="add-td border-right">4</span>
          <span class="add-td add-td1 border-right">押金</span>
          <span class="add-td">2</span>
        </div>
        <div class="add-tr add-tr2">
          <span class="add-td border-right">5</span>
          <span class="add-td add-td1 border-right">一次性生活费</span>
          <span class="add-td">2</span>
        </div>
        <div class="add-tr">
          <span class="add-td add-td2 border-right">合计</span>
          <span class="add-td">2</span>
        </div>
      </div>

      <div class="add-box-title">
        <span>入院费用核定 （2020-05-05 至2020-06-06）</span>
      </div>

      <div class="add-table">
        <div class="add-tr add-tr1">
          <span class="add-td">序号</span>
          <span class="add-td add-td1">项目名称</span>
          <span class="add-td">小计（元）</span>
        </div>
        <div class="add-tr add-tr2">
          <span class="add-td border-right">1</span>
          <span class="add-td add-td1 border-right">床位费</span>
          <span class="add-td">1000</span>
        </div>
        <div class="add-tr add-tr2">
          <span class="add-td border-right">2</span>
          <span class="add-td add-td1 border-right">护理费</span>
          <span class="add-td">2</span>
        </div>
        <div class="add-tr add-tr2">
          <span class="add-td border-right">3</span>
          <span class="add-td add-td1 border-right">膳食费</span>
          <span class="add-td">2</span>
        </div>
        <div class="add-tr add-tr2">
          <span class="add-td border-right">4</span>
          <span class="add-td add-td1 border-right">押金</span>
          <span class="add-td">2</span>
        </div>
        <div class="add-tr add-tr2">
          <span class="add-td border-right">5</span>
          <span class="add-td add-td1 border-right">一次性生活费</span>
          <span class="add-td">2</span>
        </div>
        <div class="add-tr">
          <span class="add-td add-td2 border-right">合计</span>
          <span class="add-td">2</span>
        </div>
      </div>

      <div class="add-box-title">
        <span>上传合同</span>
      </div>

      <div class="add-upload">
        <el-button size="small">上传协议</el-button>
        <span class="add-upload-text">下载预定协议</span>
      </div>
    </div>
    <div class="add-btns">
      <el-button size="small" @click="next" v-if="tabIndex === 0">下一步</el-button>
      <el-button size="small" @click="upper" v-if="tabIndex === 1">上一步</el-button>
      <el-button size="small" @click="upper" v-if="tabIndex === 1">保存暂不提交</el-button>
      <el-button size="small" @click="upper" v-if="tabIndex === 1">保存并提交</el-button>
      <el-button size="small" class="gray" @click="close">返回</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      input: '',
      options: [{
        value: '',
        label: ''
      }],
      value: '',
      tabIndex: 0
    }
  },
  methods: {
    next () {
      this.tabIndex = 1
    },
    upper () {
      this.tabIndex = 0
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="less">
.add {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0 40px;
  box-sizing: border-box;
  z-index: 88;
  background-color: #081C38;

  .add-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;

    .title {
      height: 38px;
      line-height: 38px;
      padding: 0 20px;
      background-color: #04B4D0;
      text-align: center;
      color: #fff;
      font-size: 22px;
    }

    .input {
      width: 246px;

      /deep/.el-input__inner {
        border: none;
        background: linear-gradient(153deg, #0A477B 0%, #0E67AD 100%);
        color: #fff;
        font-size: 18px;
      }

      /deep/.el-input__inner::-webkit-input-placeholder {
        color: #fff;
      }

      /deep/.el-input__suffix {
        color: #fff;
        font-size: 18px;
      }
    }
  }

  .add-box {
    overflow-y: scroll;
    height: calc(100% - 140px);

    &::-webkit-scrollbar {
      width: 0;
    }

    .add-status {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      .add-status-line {
        width: 52px;
        height: 1px;
        margin: 0 12px;
        background-color: #01EEFD;
      }

      .add-status-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 60px;

        .add-status-icon {
          display: flex;
          flex-direction: row;
          align-items: center;
          color: #01EEFD;
          font-size: 18px;

          .span {
            width: 30px;
            height: 30px;
            line-height: 30px;
            margin-right: 10px;
            text-align: center;
            border-radius: 50%;
            border: 1px solid #fff;
            color: #fff;
            font-size: 18px;
          }

          .active-span {
            background-color: #01EEFD;
          }

          .text {
            color: #fff;
            font-size: 18px;
          }

          .active-text {
            color: #01EEFD;
          }
        }
      }
    }

    .add-info {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 15px 0;
      border-top: 1px solid #01EEFD;
      border-bottom: 1px solid #01EEFD;

      .add-info-left {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 50%;

        .img {
          width: 97px;
          height: 97px;
        }

        .info {
          display: flex;
          flex-direction: column;
          margin-left: 16px;
          color: #01EEFD;
          font-size: 20px;
        }
      }

      .add-info-right {
        display: flex;
        flex-direction: row;
        width: 50%;

        .title {
          height: 43px;
          line-height: 43px;
          color: #01EEFD;
          font-size: 20px;
        }

        .table {
          display: flex;
          flex-direction: column;
          width: 540px;
          margin-left: 35px;
          border-top: 1px solid #01EEFD;
          border-left: 1px solid #01EEFD;
          box-sizing: border-box;

          .tr {
            display: flex;
            flex-direction: row;
            align-items: center;

            .td {
              flex: 1;
              height: 43px;
              line-height: 43px;
              text-align: center;
              border-bottom: 1px solid #01EEFD;
              border-right: 1px solid #01EEFD;
              box-sizing: border-box;
              color: #01EEFD;
              font-size: 20px;
            }
          }
        }
      }
    }

    .add-box-title {
      height: 40px;
      line-height: 40px;
      margin-top: 20px;
      border-bottom: 1px solid #01EEFD;
      box-sizing: border-box;
      color: #01EEFD;
      font-size: 20px;

      .span {
        margin-left: 36px;
      }
    }

    .add-line {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 20px;
      box-sizing: border-box;

      .add-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 25%;

        .add-key {
          margin-right: 10px;
          white-space: nowrap;
          color: #01EEFD;
          font-size: 22px;
        }

        .el-select {
          width: 180px;
        }

        .el-input {
          width: 180px;
        }
      }
    }

    .add-table {
      display: flex;
      flex-direction: column;
      width: 870px;
      margin-top: 20px;
      border: 4px solid #073F73;

      .add-tr {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 870px;

        .add-td {
          flex: 1;
          height: 44px;
          line-height: 44px;
          text-align: center;
          color: #01EEFD;
          font-size: 17px;
        }

        .add-td1 {
          flex: 2;
        }

        .add-td2 {
          flex: 3;
        }

        .border-right {
          border-right: 1px solid #01EEFD;
          box-sizing: border-box;
        }
      }

      .add-tr1 {
        background-color: #024276;
      }

      .add-tr2 {
        border-bottom: 1px solid #01EEFD;
        box-sizing: border-box;
      }
    }

    .add-upload {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 60px;

      .el-button {
        background-color: #04B4D0;
        border: none;
        color: #fff;
      }

      .add-upload-text {
        margin-left: 30px;
        color: #fff;
        font-size: 22px;
      }
    }
  }

  .add-btns {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 60px;

    .el-button {
      margin-right: 30px;
      background-color: #024A83;
      border: none;
      color: #fff;
    }

    .gray {
      background-color: #33465C;
    }
  }

}
</style>
