<template>
  <div class="see">
    <div class="see-search">
      <div class="see-item">
        <div class="key">
          <span>接受状态</span>
        </div>
        <el-select v-model="value" placeholder="请选择">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <el-button type="primary">搜索</el-button>
      <el-button type="primary">重置</el-button>
    </div>

    <el-table :data="tableData" height="70%" border>
      <el-table-column label="序号" type="index" width="100">
      </el-table-column>
      <el-table-column prop="任务名称" label="接收人">
      </el-table-column>
      <el-table-column prop="address" label="接收状态">
      </el-table-column>
    </el-table>

    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="query1.pageNum" background :page-size="query1.pageSize" layout="total, prev, pager, next, jumper" :total="query1.total"></el-pagination>

    <el-button class="close" size="small" @click="close">返回</el-button>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data () {
    return {
      tableData: [{
        date: '2016-05-02',
        name: '王小虎',
        address: '待接收'
      }, {
        date: '2016-05-04',
        name: '王小虎',
        address: '已接收'
      }, {
        date: '2016-05-01',
        name: '王小虎',
        address: '已接收'
      }],
      options: [{
        value: '',
        label: ''
      }],
      value: '',
      currentPage4: 4,
      // 分页数据
      query1: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      info: {
        rwmc: '',
        rwid: '',
        tjrxm: '',
        tjrxmbm: '',
        jsrxm: '',
        jsrxmbm: '',
        lxdh: '',
        jszt: '',
        jsztbm: '',
        tjsj: '',
        jsrdh: '',
        name: '',
        sfzh: '',
        rwsl: '',
        rwlb: [
          {
            rwxh: '',
            rwmc: '',
            jszt: '',
            jsztbm: '',
            sfzh: '',
            rwid: '',
            czbh: '',
            ylzsbh: ''
          }
        ],
        xzjdmcid: '',
        xzjdmc: '',
        cwhmcid: '',
        cwhmc: '',
        ylzsbh: '',
        czbh: '',
        flag: '',
        bz1: '',
        bz2: '',
        ssjgbh: '',
        xb: '',
        age: ''
      }
    }
  },
  computed: {
    ...mapState(['loginInfo'])
  },
  created () {
    this.getList()
  },
  methods: {
    // 交接记录明细列表
    async getList () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-100423932
      try {
        // const start = this.query1.pageNum === 1 ? '10' : (this.query1.pageNum - 1) * 10 + 1 + ''
        const start = this.query1.pageNum === 1 ? '0' : (this.query1.pageNum - 1) * 10 + ''
        const obj = {
          start: start,
          length: this.query1.pageSize + '',
          ssjgbh: this.loginInfo.jgbh
        }
        const { data: res } = await this.$http.get('/ykdyljjjlmxs', { params: obj })
        console.log('交接记录明细列表', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.tableData = res.data
        this.query1.total = res.data.length
      } catch (error) {
        console.log('交接记录明细列表请求失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    // 交接记录保存
    async submit () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-100423179
      try {
        const { data: res } = await this.$http.post('/zhyyapi/saveykdrwkb', this.info)
        console.log('交接记录保存', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.$message.success('保存成功')
        this.$emit('close', 'loading')
      } catch (error) {
        console.log('交接记录保存失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
      this.query1.pageNum = val
      this.getList()
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.see {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  background-color: #fff;

  .see-search {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    .see-item {
      display: flex;
      align-items: center;
      margin-right: 30px;

      .key {
        margin-right: 10px;
        white-space: nowrap;
        color: #000;
        font-size: 22px;
      }
    }
  }

  .el-table {
    margin-top: 20px;
  }

  /deep/.el-table .cell {
    text-align: center;
    color: #000;
  }

  .el-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }

  .close {
    position: absolute;
    left: 20px;
    bottom: 20px;
    background-color: #33465C;
    border: none;
    color: #fff;
  }
}
</style>
