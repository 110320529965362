<template>
  <div class="see">
    <div class="son-tab">
      <span class="son-item son-active">查看记录</span>
      <el-input placeholder="姓名/身份证号" suffix-icon="el-icon-search" class="see-search">
      </el-input>
    </div>
    <div class="son-scroll">
      <div class="run-search">
        <span class="item">老人：张三</span>
        <span class="item">上传人：张三</span>
        <span class="item">身份证号：32062371887128122</span>
        <span class="item">上传时间：2020-02-02 15：00</span>
        <span class="item">床位号：501-1</span>
      </div>
      <div class="run-title">
        <span>查房记录内容</span>
      </div>
      <div class="run-title1">
        <span>查房记录内容</span>
      </div>
      <div class="run-line">
        <span>精神</span>
        <span>正常</span>
      </div>
      <div class="run-line">
        <span>饮食</span>
        <span>正常</span>
      </div>
      <div class="run-line">
        <span>睡眠</span>
        <span>正常</span>
      </div>
      <div class="run-line">
        <span>大便</span>
        <span>正常</span>
      </div>
      <div class="run-line">
        <span>小便</span>
        <span>正常</span>
      </div>
      <div class="run-line">
        <span>交流</span>
        <span>正常</span>
      </div>
      <div class="run-line">
        <span>记忆</span>
        <span>正常</span>
      </div>
      <div class="run-title1">
        <span>生命特征</span>
      </div>
      <div class="run-line">
        <span>体温</span>
        <span>正常</span>
      </div>
      <div class="run-line">
        <span>脉搏</span>
        <span>正常</span>
      </div>
      <div class="run-line">
        <span>呼吸</span>
        <span>正常</span>
      </div>
      <div class="run-line">
        <span>血压</span>
        <span>正常</span>
      </div>
      <div class="run-title1">
        <span>医生建议</span>
      </div>
      <div class="run-text">
        <span>身体状态不错！</span>
      </div>
    </div>
    <div class="see-close">
      <el-button size="small" @click="close">返回</el-button>
      <el-button class="see-blue" size="small" @click="close">保存</el-button>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {
      tableData: [{
        date: '2016-05-02',
        name: '王小虎',
        address: '等待外出'
      }, {
        date: '2016-05-04',
        name: '王小虎',
        address: '未返回'
      }, {
        date: '2016-05-01',
        name: '王小虎',
        address: '逾期未返回'
      }],
      currentPage4: 4,
      showSee: false
    }
  },
  methods: {
    openSee () {
      this.showSee = true
    },
    closeSee () {
      this.showSee = false
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.see {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  padding: 0 20px;
  background-color: #081C38;
  box-sizing: border-box;

  .son-tab {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;

    .son-item {
      height: 38px;
      line-height: 38px;
      margin-right: 40px;
      padding: 0 20px;
      text-align: center;
      background-color: #284867;
      color: #fff;
      font-size: 16px;
      cursor: pointer;
    }

    .son-active {
      background-color: #04B4D0;
    }
  }

  .see-search {
    width: 246px;

    /deep/.el-input__inner {
      border: none;
      background: linear-gradient(153deg, #0A477B 0%, #0E67AD 100%);
      color: #fff;
      font-size: 18px;
    }

    /deep/.el-input__inner::-webkit-input-placeholder {
      color: #fff;
    }

    /deep/.el-input__suffix {
      color: #fff;
      font-size: 18px;
    }
  }

  .run-search {
    display: flex;
    align-items: center;
    width: 100%;
    height: 60px;

    .item {
      width: 20%;
      color: #01EEFD;
      font-size: 18px;
    }
  }

  .son-scroll {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 200px);
    padding-left: 12px;
    box-sizing: border-box;

    &::-webkit-scrollbar {
      width: 0;
    }

    .run-title {
      height: 40px;
      line-height: 40px;
      color: #fff;
      font-size: 18px;
    }

    .run-title1 {
      height: 30px;
      line-height: 30px;
      margin-top: 20px;
      color: #fff;
      font-size: 16px;
    }

    .run-line {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100px;
      margin-top: 10px;
      color: #fff;
      font-size: 15px;
    }

    .run-text {
      display: flex;
      align-items: center;
      color: #fff;
      font-size: 16px;
    }
  }

  .see-close {
    position: absolute;
    left: 40px;
    bottom: 0px;
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;

    .el-button {
      background-color: #33465C;
      border: none;
      color: #fff;
    }

    .see-blue {
      margin-left: 50px;
      background-color: #024A83;
    }
  }
}
</style>
