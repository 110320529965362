<template>
  <div class="popup">
    <div class="popup-content">
      <div class="popup-title">
        <span>新增护工</span>
      </div>
      <div class="popup-box">
        <div class="content">
          <div class="close" @click="close">
            <i class="el-icon-close"></i>
          </div>
          <div class="popup-head">
            <div class="popup-item">
              <span>姓名</span>
              <el-input placeholder="请输入"></el-input>
            </div>
            <div class="popup-item">
              <span>所属岗位</span>
              <el-select v-model="value" placeholder="请选择">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
            <el-button>搜索</el-button>
            <el-button>重置</el-button>
          </div>
          <el-table :data="tableData" height="40">
            <el-table-column prop="name" label="头像">
            </el-table-column>
            <el-table-column prop="address" label="姓名">
            </el-table-column>
            <el-table-column prop="address" label="手机号">
            </el-table-column>
            <el-table-column prop="address" label="部门">
            </el-table-column>
            <el-table-column prop="address" label="岗位">
            </el-table-column>
            <el-table-column label="操作" width="140">
              <template>
                <el-button>选择</el-button>
                <!-- <el-button class="red">取消选择</el-button> -->
              </template>
            </el-table-column>
          </el-table>
          <div class="btn">
            <el-button size="small" @click="close">取消</el-button>
            <el-button size="small" @click="submit">确定</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tableData: [{
        date: '2016-05-02',
        name: '王小虎',
        address: '等待外出'
      }, {
        date: '2016-05-04',
        name: '王小虎',
        address: '未返回'
      }, {
        date: '2016-05-01',
        name: '王小虎',
        address: '逾期未返回'
      }],
      input: '',
      options: [{
        value: '',
        label: ''
      }],
      value: ''
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    submit () {
      this.$emit('close', this.input)
    }
  }
}
</script>

<style scoped lang="less">
.popup {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 88;
  background-color: rgba(0, 0, 0, .74);

  .popup-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1042px;
    height: 800px;

    .popup-title {
      width: 147px;
      height: 50px;
      line-height: 60px;
      background-image: url('../../../../img/dialogTitleBg.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      text-align: center;
      color: #fff;
      font-size: 20px;
    }

    .popup-box {
      width: 1042px;
      height: 750px;
      padding: 10px;
      background-color: rgba(3, 27, 44, .8);
      border-radius: 10px;
      border: 4px solid rgba(1, 49, 88, .5);
      box-sizing: border-box;

      .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        width: 100%;
        height: 100%;
        padding: 30px 10px;
        border-radius: 10px;
        border: 4px solid rgba(1, 49, 88, .5);
        box-sizing: border-box;

        .close {
          display: flex;
          align-items: flex-end;
          position: absolute;
          top: 0;
          right: 0;
          width: 50px;
          height: 50px;
          background: linear-gradient(26.565deg, #2057a7 50%, transparent 0);
          transform: rotate(180deg);
          color: #fff;
          font-size: 24px;
          cursor: pointer;
        }

        .popup-head {
          display: flex;
          flex-direction: row;
          align-items: center;
          height: 80px;

          .popup-item {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-right: 30px;
            color: #01EEFD;
            font-size: 22px;

            .el-input {
              width: 180px;
              margin-left: 10px;
            }

            .el-select {
              margin-left: 10px;
            }
          }

          .el-button {
            margin-left: 40px;
            background-color: #04B4D0;
            color: #fff;
          }
        }

        .el-table {
          margin: 10px 0;
          border: 4px solid #073F73;
          background-color: #081C38;

          .el-button {
            width: 90px;
            text-align: center;
            padding-left: 0;
            padding-right: 0;
            background-color: #0192B5;
            color: #fff;
          }

          .red {
            background-color: #FF0000;
          }
        }

        /deep/.el-table__body-wrapper {
          background-color: #081C38;
        }

        /deep/.el-table th.el-table__cell.is-leaf {
          border-bottom: none;
        }

        /deep/.el-table th.el-table__cell {
          background-color: #024276;
        }

        /deep/.el-table .cell {
          text-align: center;
          color: #01EEFD;
        }

        /deep/.el-table tr {
          background-color: #081C38;
        }

        /deep/.el-table td.el-table__cell {
          border-bottom: 1px solid #01EEFD;
        }

        .el-table::before {
          height: 0;
        }

        /deep/.el-table tbody tr:hover>td {
          background-color: transparent;
        }

        .btn {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 240px;
          margin: 0 auto;
        }

        .el-button {
          border-radius: 0;
          border: none;
          background-color: #33465C;
          color: #fff;
        }

        .el-button:nth-child(2) {
          background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
        }
      }
    }
  }
}
</style>
