<template>
  <div class="run">
    <div class="run-search">
      <div class="run-item">
        <div class="key">
          <span>护工姓名</span>
        </div>
        <el-input placeholder="请输入"></el-input>
      </div>
      <div class="run-item">
        <div class="key">
          <span>联系方式</span>
        </div>
        <el-input placeholder="请输入"></el-input>
      </div>
      <div class="run-item">
        <div class="key">
          <span>身份证号</span>
        </div>
        <el-input placeholder="请输入"></el-input>
      </div>
      <div class="run-item">
        <div class="key">
          <span>统计月份</span>
        </div>
        <el-select v-model="value" placeholder="请选择">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <el-button type="primary">搜索</el-button>
      <el-button type="primary">重置</el-button>
    </div>
    <div class="table">
      <el-table :data="tableData" height="100%" border>
      <el-table-column label="序号" type="index" width="100">
      </el-table-column>
      <el-table-column prop="name" label="头像">
      </el-table-column>
      <el-table-column prop="address" label="姓名">
      </el-table-column>
      <el-table-column prop="address" label="联系方式">
      </el-table-column>
      <el-table-column prop="address" label="身份证号">
      </el-table-column>
      <el-table-column prop="address" label="薪资统计">
      </el-table-column>
      <el-table-column label="操作" width="140">
        <template>
          <!-- <div class="flex" @click="openSee">
            <span>薪资明细</span>
          </div> -->
          <el-button size="mini" type="primary"  @click="openSee">薪资明细</el-button>
        </template>
      </el-table-column>
    </el-table>
    </div>

    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage4"
      :page-sizes="[100, 200, 300, 400]"
      :page-size="100"
      background
      layout="total, prev, pager, next, jumper"
      :total="400">
    </el-pagination>

    <See v-if="showSee" @close="closeSee"></See>
  </div>
</template>

<script>
import See from './see.vue'
export default {

  components: {
    See
  },
  data () {
    return {
      tableData: [{
        date: '2016-05-02',
        name: '王小虎',
        address: '等待外出'
      }, {
        date: '2016-05-04',
        name: '王小虎',
        address: '未返回'
      }, {
        date: '2016-05-01',
        name: '王小虎',
        address: '逾期未返回'
      }],
      options: [{
        value: '',
        label: ''
      }],
      value: '',
      currentPage4: 4,
      tabIndex: 0,
      selectIndex: 0,
      showSee: false
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    },
    openAdd () {
      this.showAdd = true
    },
    closeAdd () {
      this.showAdd = false
    },
    openDelete () {
      this.showDelete = true
    },
    closeDelete () {
      this.showDelete = false
    },
    openSee () {
      this.showSee = true
    },
    closeSee () {
      this.showSee = false
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.run {
  width: 100%;
  height: 100%;

  .run-search {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    .run-item {
      display: flex;
      align-items: center;
      margin-right: 40px;

      .key {
        margin-right: 10px;
        white-space: nowrap;
        color: #000;
        font-size: 22px;
      }
    }
  }

  .table {
    width: 100%;
    height: calc(100% - 160px);
  }

  /deep/.el-table .cell {
    text-align: center;
    color: #000;
  }

  .flex {
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      cursor: pointer;
    }
  }

  .el-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
}
</style>
