<template>
  <div class="run">
    <div class="run-search">
      <div class="run-item">
        <div class="key">
          <span>老人姓名</span>
        </div>
        <el-input placeholder="请输入"></el-input>
      </div>
      <div class="run-item">
        <div class="key">
          <span>状态</span>
        </div>
        <el-select v-model="value" placeholder="请选择">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <el-button>搜索</el-button>
      <el-button>重置</el-button>
    </div>
    <div class="table">
      <el-table :data="tableData" height="100%" border>
      <el-table-column label="序号" type="index" width="100">
      </el-table-column>
      <el-table-column prop="address" label="床位">
      </el-table-column>
      <el-table-column prop="address" label="房间">
      </el-table-column>
      <el-table-column prop="address" label="老人">
      </el-table-column>
      <el-table-column prop="address" label="性别">
      </el-table-column>
      <el-table-column prop="address" label="身份证号">
      </el-table-column>
      <el-table-column prop="address" label="预计出院时间">
      </el-table-column>
      <el-table-column prop="address" label="出院原因">
      </el-table-column>
      <el-table-column prop="address" label="状态">
      </el-table-column>
      <el-table-column label="操作" width="220">
        <template slot-scope="scope">
          <!-- <div class="flex">
            <span @click="openPay" v-if="scope.row.address === '待结算'">出院结算</span>
            <span>查看明细</span>
          </div> -->
          <el-button type="primary" size="mini" @click="openPay" v-if="scope.row.address === '待结算'">出院结算</el-button>
          <el-button type="primary" size="mini">查看明细</el-button>
        </template>
      </el-table-column>
    </el-table>
    </div>

    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage4"
      :page-sizes="[100, 200, 300, 400]"
      :page-size="100"
      background
      layout="total, prev, pager, next, jumper"
      :total="400">
    </el-pagination>
    <Pay v-if="showPay" @close="closePay"></Pay>
    <See v-if="showSee" @close="closeSee"></See>
  </div>
</template>

<script>
import Pay from './pay.vue'
import See from './see.vue'
export default {
  components: {
    Pay,
    See
  },
  data () {
    return {
      tableData: [{
        date: '2016-05-02',
        name: '王小虎',
        address: '待结算'
      }, {
        date: '2016-05-04',
        name: '王小虎',
        address: '已结算'
      }, {
        date: '2016-05-01',
        name: '王小虎',
        address: '待结算'
      }],
      options: [{
        value: '',
        label: ''
      }],
      value: '',
      currentPage4: 4,
      tabIndex: 0,
      selectIndex: 0,
      showPay: false,
      showSee: false
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    },
    openAdd () {
      this.showAdd = true
    },
    closeAdd () {
      this.showAdd = false
    },
    openPay () {
      this.showPay = true
    },
    closePay () {
      this.showPay = false
    },
    openSee () {
      this.showSee = true
    },
    closeSee () {
      this.showSee = false
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.run {
  width: 100%;
  height: 100%;

  .run-search {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    .run-item {
      display: flex;
      align-items: center;
      margin-right: 40px;

      .key {
        margin-right: 10px;
        white-space: nowrap;
        color: #000;
        font-size: 22px;
      }
    }
  }

  .table {
    width: 100%;
    height: calc(100% - 180px);
  }

  /deep/.el-table .cell {
    text-align: center;
    color: #000;
  }

  .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      cursor: pointer;
    }
  }

  .el-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
}
</style>
