<template>
  <div class="knowledgeBase">
    <div class="tab">
      <span class="title">入院登记</span>
    </div>
    <div class="content">
      <div class="intervene">
        <div class="run-search">
          <div class="run-item">
            <div class="key">
              <span>老人姓名</span>
            </div>
            <el-input placeholder="请输入" v-model="params.name"></el-input>
          </div>
          <div class="run-item">
            <div class="key">
              <span>身份证号</span>
            </div>
            <el-input placeholder="请输入" v-model="params.sfzh"></el-input>
          </div>
          <div class="run-item">
          <div class="key">
            <span>性别</span>
          </div>
          <el-select v-model="valueOne" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
            </el-select>
          </div>
          <!-- <div class="run-item">
            <div class="key">
              <span>状态</span>
            </div>
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div> -->
          <el-button type="primary" @click="getListByParams">搜索</el-button>
          <el-button type="primary" @click="resetForm">重置</el-button>
        </div>

        <div class="son-add">
          <div class="son-add-icon" @click="openSelect">
            <span class="el-icon-plus"></span>
          </div>
        </div>
        <div class="table">
          <el-table :data="tableData" height="100%" border ref="table">
            <el-table-column type="selection" width="42"></el-table-column>
            <el-table-column prop="czbh" label="长者编号"></el-table-column>
            <el-table-column prop="name" label="姓名"></el-table-column>
            <el-table-column prop="age" label="年龄"></el-table-column>
            <el-table-column prop="lxfs" label="联系方式"></el-table-column>
            <el-table-column prop="gddh" label="固定电话"></el-table-column>
            <el-table-column prop="xb" label="性别">
              <template slot-scope="scope">
                <span>{{ scope.row.xb === '1' ? '男' : '女' }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="csrq" label="出生日期"></el-table-column>
            <el-table-column prop="hjdz" label="户籍地址">
            </el-table-column>
            <el-table-column label="操作" width="310" >
              <template slot-scope="scope">
              <el-button type="primary" size="small" @click="openSee(scope.row.id)">查看</el-button>
              <el-button type="primary" size="small" @click="openVideo">视频通话</el-button>
              <el-button type="primary" size="small" @click="openDelete(scope.row)">删除</el-button>
              <el-button type="primary" size="small" @click="openUpdate(scope.row)">修改</el-button>
              </template>
              <!-- <span @click="openSee(scope.row.id)">查看</span>
                  <span @click="openVideo">视频通话</span>
                  <span @click="openDelete">删除</span>
                  <span @click="openUpdate">修改</span> -->
            </el-table-column>
          </el-table>
        </div>
        <div class="pagination">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="query1.pageNum" background :page-size="query1.pageSize"
            layout="total, prev, pager, next, jumper" :total="query1.total"></el-pagination>
        </div>
      </div>
    </div>
    <Add v-if="showAdd" @close="closeAdd"></Add>
    <Update v-if="showUpdate" :selectInfo="selectInfo" @close="closeUpdate"></Update>
    <Delete v-if="showDelete" :selectInfo="selectInfo" @close="closeDelete"></Delete>
    <See v-if="showSee" :id="id" @close="closeSee"></See>
    <Video v-if="showVideo" @close="closeVideo"></Video>
    <Select v-if="showSelect" @click="openAdd" @close="closeSelect"></Select>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Add from './add.vue'
import Delete from './delete.vue'
import See from './see.vue'
import Update from './update.vue'
import Video from './video.vue'
import Select from './select.vue'
// import axios from 'axios'
export default {
  components: {
    Add,
    Delete,
    See,
    Update,
    Video,
    Select
  },
  data () {
    return {
      tableData: [],
      options: [{
        value: '1',
        label: '男'
      },
      {
        value: '2',
        label: '女'
      }
      ],
      valueOne: '',
      showAdd: false,
      showUpdate: false,
      showSee: false,
      showDelete: false,
      showVideo: false,
      showSelect: false,
      selectInfo: {},
      // 分页数据
      query1: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      id: '',
      params: {
        name: '',
        sfzh: '',
        ylfwjlid: ''
      }
    }
  },
  computed: {
    ...mapState(['loginInfo'])
  },
  created () {
    this.getList()
  },
  filters: {
    tpye (valueOne) {
      if (valueOne === '1') return '男'
      if (valueOne === '2') return '女'
      return ''
    }
  },
  methods: {
    async getList () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-92344223
      try {
        const start = this.query1.pageNum === 1 ? '0' : (this.query1.pageNum - 1) * 10 + ''
        const obj = {
          start: start,
          length: this.query1.pageSize + '',
          ssjgbh: this.loginInfo.jgbh,
          jgbh: this.loginInfo.jgbh,
          sxzt: '0',
          name: this.params.name,
          sfzh: this.params.sfzh,
          xb: this.valueOne
        }
        const { data: res } = await this.$http.get('/ykdrydjs', { params: obj })
        console.log('入院登记列表', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.tableData = res.data
        this.query1.total = res.recordsTotal
        this.$nextTick(() => {
          this.$refs.table.bodyWrapper.scrollTop = 0
        })
      } catch (error) {
        console.log('入院登记列表请求失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    async getListByParams () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-92344223
      try {
        const start = this.query1.pageNum === 1 ? '0' : (this.query1.pageNum - 1) * 10 + ''
        const obj = {
          start: start,
          length: this.query1.pageSize + '',
          ssjgbh: this.loginInfo.jgbh,
          jgbh: this.loginInfo.jgbh,
          sxzt: '0',
          name: this.params.name,
          sfzh: this.params.sfzh
        }
        const { data: res } = await this.$http.get('/ykdrydjs', { params: obj })
        console.log('入院登记列表', res)
        this.params.ylfwjlid = res.data.ylfwjlid
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.tableData = res.data
        this.query1.total = res.recordsTotal
        this.$nextTick(() => {
          this.$refs.table.bodyWrapper.scrollTop = 0
        })
      } catch (error) {
        console.log('入院登记列表请求失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    tabClick (index) {
      this.tabIndex = index
    },
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
      this.query1.pageNum = val
      this.getList()
    },
    openAdd () {
      this.showAdd = true
    },
    closeAdd () {
      this.showAdd = false
    },
    openSee (id) {
      this.id = id + ''
      this.showSee = true
    },
    closeSee () {
      this.id = ''
      this.showSee = false
    },
    openUpdate (info) {
      console.log('update', info)
      this.selectInfo = info
      this.showUpdate = true
    },
    closeUpdate (e) {
      if (e === 'loading') {
        this.getList()
      }
      this.showUpdate = false
    },
    openDelete (info) {
      console.log(info)
      this.selectInfo = info
      this.showDelete = true
    },
    closeDelete (findD) {
      if (findD === '+') {
        this.getList()
      }
      this.showDelete = false
    },
    openVideo () {
      this.showVideo = true
    },
    closeVideo () {
      this.showVideo = false
    },
    openSelect () {
      this.showSelect = true
    },
    closeSelect (e) {
      this.showSelect = false
      if (e) {
        this.showAdd = true
      }
    },
    resetForm () {
      this.params = {
        name: '',
        sfzh: ''
      }
      this.valueOne = ''
    }
  }
}
</script>

<style lang="less" scoped>
.knowledgeBase {
  position: relative;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  padding: 0 10px;
  background-color: #fff;
  box-sizing: border-box;

  .tab {
    display: flex;
    align-items: center;
    width: 100%;
    height: 60px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    span {
      height: 38px;
      line-height: 38px;
      margin-right: 16px;
      padding: 0 12px;
      text-align: center;
      color: #fff;
      font-size: 22px;
      background-color: #D5D5D5;
      cursor: pointer;
    }

    .title {
      background-color: rgb(72, 162, 255);
    }
  }

  .content {
    width: 100%;
    height: calc(100% - 80px);

    .son-add {
      display: flex;
      align-items: center;
      height: 50px;

      .son-add-icon {
        width: 30px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        background-color: rgb(72, 162, 255);
        border-radius: 50%;
        color: #fff;
        font-size: 16px;
        cursor: pointer;
      }

      .son-set {
        width: 120px;
        height: 30px;
        line-height: 30px;
        margin-left: 30px;
        text-align: center;
        background-color: #04B4D0;
        color: #fff;
        font-size: 16px;
        cursor: pointer;
      }
    }

    .run-search {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 100%;
      height: 78px;
      border-bottom: 1px solid #000;
      box-sizing: border-box;

      .run-item {
        display: flex;
        align-items: center;
        margin-right: 40px;

        .key {
          margin-right: 10px;
          white-space: nowrap;
          color: #000;
          font-size: 22px;
        }
      }
    }

    .intervene {
      position: relative;
      width: 100%;
      height: 100%;

      .info-title {
        width: 100%;
        height: 120px;
        border-bottom: 1px solid #01EEFD;
        box-sizing: border-box;
        color: #01EEFD;
        font-size: 20px;
        font-weight: bold;
      }

      .info-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 50px;
        margin-top: 10PX;
        padding: 0 10px;
        box-sizing: border-box;

        .left {
          display: flex;
          align-items: center;

          .search-input {
            width: 260px;
          }

          .el-select,
          .el-input {
            width: 180px;
            margin-right: 20px;
          }

          .date {
            margin-right: 10px;
          }

          .blue,
          .gray {
            margin-right: 29px;
            background-color: #04B4D0;
            color: #fff;
            border: none;
            border-radius: 0;
          }

          .gray {
            background-color: #284867;
          }

          .red {
            background-color: #FF0000;
          }
        }

        .right {
          width: 30px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          border-radius: 50%;
          background-color: #04B4D0;
          color: #fff;
          font-size: 18px;
          font-weight: 600;
          cursor: pointer;
        }
      }

      .table {
        width: 99%;
        height: calc(100% - 180px);

        /deep/.el-table .cell {
          text-align: center;
          color: #000;
        }

        .flex {
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          width: 100%;
          font-size: 16px;

          span {
            color: #0077F9;
            cursor: pointer;
            letter-spacing: 0;
          }
        }
      }

      .pagination {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 10px;
      }
    }
  }
}
</style>
