<template>
  <div class="children-page">
    <SonHead></SonHead>
    <div class="input">
      <div class="item">
        <div class="key">
          <span>姓名</span>
        </div>
        <el-input placeholder="姓名" v-model="searchInfo.sxtj"></el-input>
      </div>
      <div class="item">
        <div class="key">
          <span>身份证号</span>
        </div>
        <el-input placeholder="身份证号" v-model="searchInfo.sxtj"></el-input>
      </div>
      <div class="item">
        <div class="key">
          <span>条形码</span>
        </div>
        <el-input placeholder="条形码" v-model="searchInfo.sxtj"></el-input>
      </div>
      <div class="item">
        <div class="key">
          <span>体检年份</span>
        </div>
        <el-date-picker v-model="searchInfo.tjrq" type="year" placeholder="体检年份">
        </el-date-picker>
      </div>
      <div class="item">
        <div class="key">
          <span>提示信息</span>
        </div>
        <el-input placeholder="提示信息"></el-input>
      </div>
      <div class="item">
        <div class="key">
          <span>体检日期</span>
        </div>
        <el-date-picker v-model="value1" type="daterange" range-separator="至" start-placeholder="开始日期"
          end-placeholder="结束日期" value-format="yyyy-MM-dd" @change="dataChange">
        </el-date-picker>
      </div>
      <div class="item">
        <div class="key">
          <span>是否上传</span>
        </div>
        <el-select v-model="searchInfo.uploadFlag" placeholder="是否上传" class="margin">
          <el-option v-for="item in uploads" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="item">
        <div class="key">
          <span>档案分类</span>
        </div>
        <el-select multiple collapse-tags v-model="rqfl" placeholder="档案分类" class="margin" @change="rqflchange">
          <el-option v-for="item in dafls" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="item">
        <div class="key">
          <span>是否完结</span>
        </div>
        <el-select v-model="searchInfo.sftjwj" placeholder="是否完结" class="margin">
          <el-option v-for="item in sftjwjs" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="item">
        <div class="key">
          <span>是否打印</span>
        </div>
        <el-select v-model="searchInfo.sfdyflag" placeholder="是否打印" class="margin">
          <el-option v-for="item in sfdyflags" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="item">
        <el-button type="primary" @click="getList">
          <span class="el-icon-search"></span>
          <span>搜索</span>
        </el-button>
        <el-button type="primary">
          <span class="el-icon-delete"></span>
          <span>清空</span>
        </el-button>
      </div>
    </div>
    <div class="add">
      <el-button type="primary" @click="openAdd">
        <span class="el-icon-download"></span>
        <span>新增</span>
      </el-button>
      <el-button type="primary">
        <span class="el-icon-download"></span>
        <span>体检数据</span>
      </el-button>
      <el-button type="primary">
        <span class="el-icon-download"></span>
        <span>体检缺项数据</span>
      </el-button>
      <el-button type="primary" @click="openTestResults">
        <span class="el-icon-download"></span>
        <span>体检结果异常</span>
      </el-button>
      <el-button type="primary">
        <span class="el-icon-download"></span>
        <span>批量打印条码</span>
      </el-button>
      <el-button type="primary" @click="openPrint">
        <span class="el-icon-download"></span>
        <span>批量打印报告</span>
      </el-button>
    </div>
    <div class="table">
      <!-- <div class="table-position" @click="openAfterSales">
        <span>售后</span>
      </div> -->
      <el-table :data="tableData" height="100%" border>
        <el-table-column prop="id" label="序号">
        </el-table-column>
        <el-table-column prop="name" label="学生姓名">
        </el-table-column>
        <el-table-column prop="xb" label="性别">
          <template slot-scope="scope">
            <span>{{ scope.row.xb === '1' ? '男' : '女' }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="age" label="年龄">
        </el-table-column>
        <el-table-column label="班级">
        </el-table-column>
        <el-table-column label="视力情况">
          <el-table-column prop="age" label="左眼">
          </el-table-column>
          <el-table-column prop="age" label="右眼">
          </el-table-column>
        </el-table-column>
        <el-table-column prop="age" label="合格情况">
        </el-table-column>
        <el-table-column label="操作" width="200" fixed="right">
          <!-- slot-scope="scope" -->
          <template>
            <div class="flex">
              <el-button type="primary" size="small">编辑</el-button>
              <el-button type="primary" size="small">查看</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="pagination">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="query1.pageNum" background :page-size="query1.pageSize" layout="total, prev, pager, next, jumper"
        :total="query1.total" hide-on-single-page>
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import SonHead from '../../../../../components/son-head/son-head.vue'
export default {
  components: {
    SonHead
  },
  data () {
    return {
      loading: false,
      tableData: [],
      options: [{
        value: '1',
        label: '1'
      }],
      list: ['正常档案', '高血压', '糖尿病', '冠心病', '脑卒中', '残疾人', '慢阻肺', '高血脂', '肾病综合征', '眼底病突', '周围神经病变', '妇女', '儿童', '精神病', '老年人'],
      checkList: [],
      value: '',
      value1: '',
      value3: '',
      radio: '',
      showAdd: false,
      showTestResults: false,
      showPrint: false,
      showUpdate: false,
      showBind: false,
      showInfo: false,
      showParint: false,
      showReport: false,
      showSummary: false,
      showPopover: false,
      showSchedule: false,
      showUpload: false,
      showSynchronous: false,
      showAfterSales: false,
      popover: {
        display: 'flex',
        'flex-direction': 'column',
        'align-items': 'center'
      },
      item: {
        width: '100%',
        height: '40px',
        'line-height': '40px',
        'text-align': 'center',
        cursor: 'pointer'

      },
      searchInfo: {
        sxtj: '',
        betime: '',
        entime: '',
        tjrq: '',
        uploadFlag: '',
        sftjwj: '',
        sfdyflag: '',
        isHypertension: '',
        isDiabetes: '',
        isSmi: '',
        isTuberculosis: '',
        isCa: '',
        isChd: '',
        isMaternal: '',
        isboby: '',
        isaged: '',
        jgbh: '',
        cwhmcid: '',
        sfzh: ''
      },
      rqfl: '',
      uploads: [
        { value: '1', label: '已上传' },
        { value: '0', label: '未上传' }
      ],
      sftjwjs: [
        { value: '1', label: '已完结' },
        { value: '0', label: '未完结' }
      ],
      sfdyflags: [
        { value: '1', label: '已打印' },
        { value: '0', label: '未打印' }
      ],
      dafls: [
        { value: '0', label: '是否高血压', key: 'isHypertension' },
        { value: '1', label: '是否糖尿病', key: 'isDiabetes' },
        { value: '2', label: '是否精神病', key: 'isSmi' },
        { value: '3', label: '是否肺结核', key: 'isTuberculosis' },
        { value: '4', label: '是否脑卒中', key: 'isCa' },
        { value: '5', label: '是否冠心病', key: 'isChd' },
        { value: '6', label: '是否是孕妇', key: 'isMaternal' },
        { value: '7', label: '是否是婴儿', key: 'isboby' },
        { value: '8', label: '是否老年人', key: 'isaged' }
      ],
      // 分页数据
      query1: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      params: {
        tmcode: '',
        sfzh: ''
      },
      selectInfo: {
        tmcode: '',
        sfzh: '',
        grjbxxid: '',
        jktjid: '',
        xzjdmcid: '',
        cwhmcid: ''
      },
      printInfo: {
        name: '',
        xb: '',
        age: '',
        tmcode: ''
      },
      reportInfo: {
        jgbh: '',
        sfzh: '',
        tmcode: '',
        tjlx: ''
      }
    }
  },
  computed: {
    ...mapState(['loginInfo']),
    organs () {
      if (this.loginInfo.jgalllist === null && this.loginInfo.jgalllist.length > 0) return []
      return this.loginInfo.jgalllist
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    // el-table自适应宽度
    flexWidth (prop, tableData, title, num = 0) {
      if (tableData.length === 0) { // 表格没数据不做处理
        return title.length * 30 + 'px'
      }
      let flexWidth = 0// 初始化表格列宽
      let columnContent = ''// 占位最宽的内容
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')
      context.font = '14px Microsoft YaHei'
      if ((prop === '') && title) { // 标题长内容少的，取标题的值,
        columnContent = title
      } else { // 获取该列中占位最宽的内容
        let index = 0
        for (let i = 0; i < tableData.length; i++) {
          const nowTemp = tableData[i][prop] + ''
          const maxTemp = tableData[index][prop] + ''
          const nowTempW = context.measureText(nowTemp).width
          const maxTempW = context.measureText(maxTemp).width
          if (nowTempW > maxTempW) {
            index = i
          }
        }
        columnContent = tableData[index][prop]
        // 比较占位最宽的值跟标题、标题为空的留出四个位置
        const columnW = context.measureText(columnContent).width
        const titleW = context.measureText(title).width
        if (columnW < titleW) {
          columnContent = title || '留七个字'
        }
      }
      // 计算最宽内容的列宽
      const width = context.measureText(columnContent)
      flexWidth = width.width + 60 + num
      return flexWidth + 'px'
    },
    async getList () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-97904037
      this.searchInfo.jgbh = this.searchInfo.cwhmcid
      try {
        this.loading = true
        const start = this.query1.pageNum === 1 ? '0' : (this.query1.pageNum - 1) * 10 + ''
        const obj = {
          start: start,
          length: '10',
          ssjgbh: this.loginInfo.jgbh,
          xzjdmcid: '',
          cwhmcid: this.searchInfo.cwhmcid,
          flag: '',
          sfzh: ''
        }
        const { data: res } = await this.$http.get('/ykdjktjbs', { params: obj, headers: { token: this.loginInfo.token } })
        console.log(res)
        this.loading = false
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.tableData = res.data.map(item => {
          return {
            ...item,
            ...{ showPopover: false }
          }
        })
        this.query1.total = res.recordsTotal
      } catch (error) {
        this.loading = false
        console.log('请求失败', error)
      }
    },
    dataChange (e) {
      console.log(e)
      if (e.length > 0) {
        this.searchInfo.betime = e[0]
        this.searchInfo.entime = e[1]
        console.log(this.searchInfo)
      }
    },
    rqflchange (e) {
      console.log(e)
      if (e.length > 0) {
        for (let index = 0; index < this.dafls.length; index++) {
          this.searchInfo[this.dafls[index].key] = ''
        }
        for (let index = 0; index < e.length; index++) {
          this.searchInfo[this.dafls[e[index]].key] = '1'
        }
        console.log(this.searchInfo)
      }
    },
    async deleteTab (id) {
      console.log(id)
      try {
        const { data: res } = await this.$http.post(`/zhyyapi/ykdgldalist/${id}`)
        console.log(res)
        this.tableData = res.data
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
      } catch (error) {
        console.log('请求失败', error)
      }
    },
    openAdd () {
      this.showAdd = true
    },
    closeAdd () {
      this.showAdd = false
    },
    openTestResults () {
      this.showTestResults = true
    },
    openPrint () {
      this.showPrint = true
    },
    closePrint () {
      this.showPrint = false
    },
    closeTestResults () {
      this.showTestResults = false
    },
    openUpdate (info) {
      this.selectInfo.sfzh = info.sfzh || ''
      this.selectInfo.tmcode = info.tmcode || ''
      this.selectInfo.grjbxxid = info.grjbxxid || ''
      this.selectInfo.jktjid = info.jktjid || ''
      this.selectInfo.xzjdmcid = info.xzjdmcid || ''
      this.selectInfo.cwhmcid = info.cwhmcid || ''
      this.showUpdate = true
    },
    closeUpdate () {
      this.selectInfo.sfzh = ''
      this.selectInfo.tmcode = ''
      this.selectInfo.grjbxxid = ''
      this.selectInfo.jktjid = ''
      this.showUpdate = false
    },
    openBind () {
      this.showBind = true
    },
    closeBind () {
      this.showBind = false
    },
    showAccess (e) {
      console.log('打开档案调阅', e)
      this.showInfo = true
    },
    closeInfo () {
      this.showInfo = false
    },
    openParint (info) {
      this.printInfo.name = info.name || ''
      this.printInfo.xb = info.xb || ''
      this.printInfo.age = info.age || ''
      this.printInfo.tmcode = info.tmcode || ''
      this.showParint = true
    },
    closeParint () {
      this.printInfo.name = ''
      this.printInfo.xb = ''
      this.printInfo.age = ''
      this.printInfo.tmcode = ''
      this.showParint = false
    },
    openReport (info) {
      // this.reportInfo.jgbh = info.tjjgcmhid || info.tjjgbh
      // this.reportInfo.sfzh = info.sfzh || ''
      // this.reportInfo.tmcode = info.tmcode || ''
      // this.reportInfo.tjlx = info.tjlx || ''
      info.showPopover = false
      // this.selectInfo.sfzh = info.sfzh || ''
      // this.selectInfo.tmcode = info.tmcode || ''
      // this.selectInfo.grjbxxid = info.grjbxxid || ''
      // this.selectInfo.jktjid = info.jktjid || ''
      this.selectInfo = info
      console.log('所选人员信息：', info)
      this.showReport = true
    },
    closeReport () {
      // this.reportInfo.jgbh = ''
      // this.reportInfo.sfzh = ''
      // this.reportInfo.tmcode = ''
      // this.reportInfo.tjlx = ''
      this.selectInfo.sfzh = ''
      this.selectInfo.tmcode = ''
      this.selectInfo.grjbxxid = ''
      this.selectInfo.jktjid = ''
      this.showReport = false
    },
    openSummary (item) {
      item.showPopover = false
      this.showSummary = true
    },
    closeSummary () {
      this.showSummary = false
    },
    openSchedule (item) {
      item.showPopover = false
      this.showSchedule = true
    },
    closeSchedule () {
      this.showSchedule = false
    },
    openUpload () {
      this.showUpload = true
    },
    closeUpload () {
      this.showUpload = false
    },
    openSynchronous () {
      this.showSynchronous = true
    },
    closeSynchronous () {
      this.showSynchronous = false
    },
    openAfterSales () {
      this.showAfterSales = true
    },
    closeAfterSales () {
      this.showAfterSales = false
    },
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
      this.query1.pageNum = val
      this.getList()
    }
  }
}
</script>

<style lang="less" scoped>
.children-page {
  width: calc(100% - 10px);
  height: 100%;
  padding: 20px;
  // background-color: #081C38;
  background-color: #fff;
  box-sizing: border-box;

  .swich {
    display: flex;
    flex-direction: column;
    height: calc(100% - 300px);
  }

  .head {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 78px;
    box-sizing: border-box;

    .item {
      display: flex;
      align-items: center;

      .text {
        font-size: 16px;
      }
    }

    .blue {
      margin-right: 20px;
      background-color: #fff;
    }

    .width {
      width: 80px;
      font-size: 18px;
    }

    .el-select {
      width: 300px;
    }

    .el-input {
      width: 246px;
      height: 38px;
      border-radius: 6px;

      /deep/.el-input__inner {
        height: 38px;
        line-height: 38px;
      }
    }

    .postion {
      // background: linear-gradient(153deg, #0A477B 0%, #0E67AD 100%);
      background-color: #fff;
      border: 1px solid #1F72D3;

      /deep/.el-input__inner {
        background-color: transparent;
        color: #000 !important;
        font-size: 18px;
        border: none;

      }
    }

    /deep/ .el-input__suffix {
      color: #fff;
      font-size: 18px;
    }

    /deep/ .el-icon-search {
      display: flex;
      align-items: center;
    }
  }

  .input {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    height: 180px;
    // border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;

    .item {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      margin-right: 20px;

      .key {
        margin-right: 10px;
        white-space: nowrap;
        // color: #01EEFD;
        color: #000;
        font-size: 16px;
      }

      .margin {
        margin-left: 10px;
      }

      .el-select {
        width: 250px;
      }

      /deep/.el-checkbox__label {
        color: #F2F2F2;
        font-size: 18px;
      }

      .green {
        margin: 0;
        margin-right: 20px;
        text-align: center;
        background-color: #04B4D0;
        color: #fff;
        font-size: 18px;
      }
    }

    /deep/.el-radio__inner {
      background-color: transparent;
    }

    /deep/.el-radio__label {
      color: #fff;
      font-size: 14px;
    }

    .search {
      width: 300px;
    }
  }

  .add {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 60px;

    .el-button {
      margin-right: 30px;
    }
  }

  ::v-deep .el-table__body-wrapper {
    &::-webkit-scrollbar {
      // width: 0rem;
    }
  }

  /deep/.el-table .cell {
    text-align: center;
    color: #000;
    font-size: 16px;
  }

  /deep/.el-badge__content.is-fixed {
    top: -2px;
  }

  /deep/.el-input__icon {
    color: #000;
  }

  .name {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;

    .text {
      position: relative;

    }

    .nametage {
      position: absolute;
      top: -10px;
      right: -10px;
      transform: translateX(50%);
      background-color: #1F72D3;
      border-radius: 6px;
      padding: 0 4px;
      color: #fff;
      font-size: 12px;
      transform: scale(.8);
    }
  }

  .flex {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    font-size: 16px;

    span {
      cursor: pointer;
    }

    /deep/.el-button--small {
      font-size: 14px;
    }

    /deep/.el-button+.el-button {
      margin-left: 0;
    }
  }

  .green {
    color: #00BB7E;
  }

  .yellow {
    color: #FF8014;
  }

  .red {
    color: #FF0000;
  }

  .purple {
    color: #073F73;
    cursor: pointer;
  }

  .gray {
    color: #D2D2D2;
    cursor: pointer;
  }

  .table {
    position: relative;
    width: 100%;
    height: calc(100% - 430px);

    .table-position {
      position: absolute;
      top: 80px;
      right: 24px;
      z-index: 88;
      width: 60px;
      height: 60px;
      line-height: 60px;
      text-align: center;
      border-radius: 50%;
      background-color: #3FA1FF;
      box-shadow: 0px 3px 2px 1px #CBCBCB;
      color: #DCE4EC;
      font-size: 18px;
      cursor: pointer;
    }
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80px;

    ::v-deep.el-pagination__total,
    ::v-deep.el-pagination__jump {
      color: #000;

      .el-input__inner {
        color: #000;
        background-color: transparent;
      }
    }

    ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: #01EEFD;
    }
  }
}

.children-page1 {
  position: relative;
}

.children-page2 {
  position: initial;
}

.children-page3 {
  position: initial;
}
</style>
