<template>
  <div class="son">
    <!-- <div class="son-tab">
      <div class="tab-item" :class="{ 'tab-active': index === tabIndex }" v-for="(item, index) in tabs" :key="index"
        @click="tabClick(index)">
        <span>{{ item }}</span>
      </div>
    </div> -->
    <el-tabs v-model="tabIndex" @tab-click="handleClick">
      <el-tab-pane :label="item" :name="index + 1" v-for="(item, index) in tabs" :key="index"></el-tab-pane>
    </el-tabs>
    <div class="son-title">
      <span>{{ tabs[tabIndex] }}</span>
    </div>
    <div class="son-line" v-show="tabIndex === 0">
      <div class="son-item son-item1">
        <div class="key">
          <span>肺：</span>
        </div>
        <el-radio-group v-model="radio">
          <el-radio label="1">正常</el-radio>
          <el-radio label="2">异常</el-radio>
          <el-radio label="3">其他</el-radio>
        </el-radio-group>
        <el-input class="input1"></el-input>
      </div>
      <div class="son-item son-item1">
        <div class="key">
          <span>心脏：</span>
        </div>
        <el-radio-group v-model="radio">
          <el-radio label="1">正常</el-radio>
          <el-radio label="2">异常</el-radio>
          <el-radio label="3">其他</el-radio>
        </el-radio-group>
      </div>
      <div class="son-item son-item1">
        <div class="key">
          <span>腹部：</span>
        </div>
        <el-radio-group v-model="radio">
          <el-radio label="1">正常</el-radio>
          <el-radio label="2">异常</el-radio>
          <el-radio label="3">其他</el-radio>
        </el-radio-group>
      </div>
      <div class="son-item son-item1">
        <div class="key">
          <span>肝脾：</span>
        </div>
        <el-radio-group v-model="radio">
          <el-radio label="1">正常</el-radio>
          <el-radio label="2">异常</el-radio>
          <el-radio label="3">其他</el-radio>
        </el-radio-group>
      </div>
      <div class="son-item son-item4 son-item7">
        <div class="key">
          <span>体检意见：</span>
        </div>
        <el-input type="textarea" placeholder="">
        </el-input>
      </div>
      <div class="son-item son-item6">
        <div class="key">
          <span>核查人员：</span>
        </div>
        <el-input class="input2"></el-input>
        <div class="key">
          <span>核查时间：</span>
        </div>
        <el-date-picker type="date" placeholder="">
        </el-date-picker>
        <div class="key">
          <span>核查完成时间：</span>
        </div>
        <el-date-picker type="date" placeholder="">
        </el-date-picker>
        <div class="key">
          <span>体检日期 ：</span>
        </div>
        <el-date-picker type="date" placeholder="">
        </el-date-picker>
      </div>
    </div>
    <div class="son-line" v-show="tabIndex === 1">
      <div class="son-item son-item2">
        <div class="key">
          <span>皮肤：</span>
        </div>
        <el-radio-group v-model="radio">
          <el-radio label="1">正常</el-radio>
          <el-radio label="2">异常</el-radio>
          <el-radio label="3">其他</el-radio>
        </el-radio-group>
      </div>
      <div class="son-item son-item2">
        <div class="key">
          <span>颈椎：</span>
        </div>
        <el-radio-group v-model="radio">
          <el-radio label="1">正常</el-radio>
          <el-radio label="2">异常</el-radio>
          <el-radio label="3">其他</el-radio>
        </el-radio-group>
      </div>
      <div class="son-item son-item2">
        <div class="key">
          <span>胸部：</span>
        </div>
        <el-radio-group v-model="radio">
          <el-radio label="1">正常</el-radio>
          <el-radio label="2">异常</el-radio>
          <el-radio label="3">其他</el-radio>
        </el-radio-group>
      </div>
      <div class="son-item son-item2">
        <div class="key">
          <span>脊柱四肢：</span>
        </div>
        <el-radio-group v-model="radio">
          <el-radio label="1">正常</el-radio>
          <el-radio label="2">异常</el-radio>
        </el-radio-group>
      </div>
      <div class="son-item son-item2">
        <div class="key">
          <span>其他：</span>
        </div>
        <el-radio-group v-model="radio">
          <el-radio label="1">正常</el-radio>
          <el-radio label="2">异常</el-radio>
          <el-radio label="3">其他</el-radio>
        </el-radio-group>
      </div>
      <div class="son-item son-item2">
        <div class="key">
          <span>头部：</span>
        </div>
        <el-radio-group v-model="radio">
          <el-radio label="1">正常</el-radio>
          <el-radio label="2">异常</el-radio>
        </el-radio-group>
      </div>
      <div class="son-item son-item2">
        <div class="key">
          <span>淋巴结：</span>
        </div>
        <el-radio-group v-model="radio">
          <el-radio label="1">触及</el-radio>
          <el-radio label="2">未触及</el-radio>
        </el-radio-group>
      </div>
      <div class="son-item son-item2">
        <div class="key">
          <span>辅助检查其他：</span>
        </div>
        <el-input></el-input>
      </div>
    </div>
    <div class="son-line" v-show="tabIndex === 2">
      <div class="son-item son-item3">
        <div class="key">
          <span>躯干倾斜角：</span>
        </div>
        <el-input class="input1"></el-input>
      </div>
      <div class="son-item son-item4">
        <div class="key">
          <span>参考结论：</span>
        </div>
        <el-input></el-input>
      </div>
    </div>
    <div class="son-line" v-show="tabIndex === 3">
      <div class="son-item son-item2">
        <div class="key">
          <span>色盲：</span>
        </div>
        <el-radio-group v-model="radio">
          <el-radio label="1">正常</el-radio>
          <el-radio label="2">异常</el-radio>
          <el-radio label="3">其他</el-radio>
        </el-radio-group>
      </div>
      <div class="son-item son-item2">
        <div class="key">
          <span>左眼：</span>
        </div>
        <el-radio-group v-model="radio">
          <el-radio label="1">正常</el-radio>
          <el-radio label="2">异常</el-radio>
          <el-radio label="3">其他</el-radio>
        </el-radio-group>
      </div>
      <div class="son-item son-item2">
        <div class="key">
          <span>色觉：</span>
        </div>
        <el-radio-group v-model="radio">
          <el-radio label="1">正常</el-radio>
          <el-radio label="2">异常</el-radio>
        </el-radio-group>
      </div>
      <div class="son-item son-item2">
        <div class="key">
          <span>右眼：</span>
        </div>
        <el-radio-group v-model="radio">
          <el-radio label="1">正常</el-radio>
          <el-radio label="2">异常</el-radio>
          <el-radio label="3">其他</el-radio>
        </el-radio-group>
      </div>
      <div class="son-item son-item2">
        <div class="key">
          <span>左眼屈光检查：</span>
        </div>
        <el-radio-group v-model="radio">
          <el-radio label="1">正常</el-radio>
          <el-radio label="2">异常</el-radio>
        </el-radio-group>
      </div>
      <div class="son-item son-item2">
        <div class="key">
          <span>左眼屈光检查：</span>
        </div>
        <el-radio-group v-model="radio">
          <el-radio label="1">正常</el-radio>
          <el-radio label="2">异常</el-radio>
        </el-radio-group>
      </div>
      <div class="son-item son-item2">
        <div class="key">
          <span>双眼：</span>
        </div>
        <el-radio-group v-model="radio">
          <el-radio label="1">正常</el-radio>
          <el-radio label="2">异常</el-radio>
          <el-radio label="3">其他</el-radio>
        </el-radio-group>
      </div>
      <div class="son-item son-item2">
        <div class="key">
          <span>左眼裸眼视力：</span>
        </div>
        <el-input class="input"></el-input>
      </div>
      <div class="son-item son-item2">
        <div class="key">
          <span>右眼裸眼视力：</span>
        </div>
        <el-input class="input"></el-input>
      </div>
      <div class="son-item son-item2">
        <div class="key">
          <span>左眼屈光球镜s：</span>
        </div>
        <el-input class="input"></el-input>
      </div>
      <div class="son-item son-item2">
        <div class="key">
          <span>右眼屈光球镜s：</span>
        </div>
        <el-input class="input"></el-input>
      </div>
      <div class="son-item son-item2">
        <div class="key">
          <span>左眼屈光柱镜s：</span>
        </div>
        <el-input class="input"></el-input>
      </div>
      <div class="son-item son-item2">
        <div class="key">
          <span>右眼屈光柱镜s：</span>
        </div>
        <el-input class="input"></el-input>
      </div>
      <div class="son-item son-item2">
        <div class="key">
          <span>左眼屈光轴位A：</span>
        </div>
        <el-input class="input"></el-input>
      </div>
      <div class="son-item son-item2">
        <div class="key">
          <span>右眼屈光轴位A：</span>
        </div>
        <el-input class="input"></el-input>
      </div>
    </div>
    <div class="son-line" v-show="tabIndex === 4">
      <div class="son-item son-item1">
        <div class="key">
          <span>左耳：</span>
        </div>
        <el-radio-group v-model="radio">
          <el-radio label="1">正常</el-radio>
          <el-radio label="2">异常</el-radio>
          <el-radio label="3">其他</el-radio>
        </el-radio-group>
      </div>
      <div class="son-item son-item1">
        <div class="key">
          <span>右耳 ：</span>
        </div>
        <el-radio-group v-model="radio">
          <el-radio label="1">正常</el-radio>
          <el-radio label="2">异常</el-radio>
        </el-radio-group>
      </div>
      <div class="son-item son-item1">
        <div class="key">
          <span>咽（喉部）：</span>
        </div>
        <el-input class="input"></el-input>
      </div>
      <div class="son-item son-item1">
        <div class="key">
          <span>鼻：</span>
        </div>
        <el-radio-group v-model="radio">
          <el-radio label="1">正常</el-radio>
          <el-radio label="2">异常</el-radio>
          <el-radio label="3">其他</el-radio>
        </el-radio-group>
      </div>
    </div>
    <div class="son-line" v-show="tabIndex === 5">
      <div class="son-item son-item2">
        <div class="key">
          <span>口腔牙齿数：</span>
        </div>
        <el-input class="input"></el-input>
      </div>
      <div class="son-item son-item2">
        <div class="key">
          <span>口腔龋齿数：</span>
        </div>
        <el-input class="input"></el-input>
      </div>
      <div class="son-item son-item1">
        <div class="key">
          <span>牙周：</span>
        </div>
        <el-radio-group v-model="radio">
          <el-radio label="1">正常</el-radio>
          <el-radio label="2">异常</el-radio>
          <el-radio label="3">其他</el-radio>
        </el-radio-group>
      </div>
    </div>
    <div class="son-line" v-show="tabIndex === 6">
      <div class="son-item son-item2">
        <div class="key">
          <span>体重：</span>
        </div>
        <el-input class="input"></el-input>
      </div>
      <div class="son-item son-item2">
        <div class="key">
          <span>评价：</span>
        </div>
        <el-radio-group v-model="radio">
          <el-radio label="1">上</el-radio>
          <el-radio label="2">中</el-radio>
          <el-radio label="3">下</el-radio>
        </el-radio-group>
      </div>
      <div class="son-item son-item2">
        <div class="key">
          <span>身长（高）：</span>
        </div>
        <el-input class="input"></el-input>
      </div>
      <div class="son-item son-item2">
        <div class="key">
          <span>评价：</span>
        </div>
        <el-radio-group v-model="radio">
          <el-radio label="1">上</el-radio>
          <el-radio label="2">中</el-radio>
          <el-radio label="3">下</el-radio>
        </el-radio-group>
      </div>
      <div class="son-item son-item5">
        <div class="key">
          <span>体格发育评价：</span>
        </div>
        <el-radio-group v-model="radio">
          <el-radio label="1">偏瘦</el-radio>
          <el-radio label="2">正常</el-radio>
          <el-radio label="3">胖</el-radio>
        </el-radio-group>
      </div>
      <div class="son-item son-item2">
        <div class="key">
          <span>腰围：</span>
        </div>
        <el-input class="input"></el-input>
      </div>
      <div class="son-item son-item2">
        <div class="key">
          <span>臀围：</span>
        </div>
        <el-input class="input"></el-input>
      </div>
    </div>
    <div class="son-line" v-show="tabIndex === 7">
      <div class="son-item son-item2">
        <div class="key">
          <span>收缩压：</span>
        </div>
        <el-input class="input"></el-input>
      </div>
      <div class="son-item son-item2">
        <div class="key">
          <span>舒张压：</span>
        </div>
        <el-input class="input"></el-input>
      </div>
      <div class="son-item son-item2">
        <div class="key">
          <span>脉率：</span>
        </div>
        <el-input class="input"></el-input>
      </div>
    </div>
    <div class="son-line" v-show="tabIndex === 8">
      <div class="son-item son-item2">
        <div class="key">
          <span>听力（左）：</span>
        </div>
        <el-radio-group v-model="radio">
          <el-radio label="1">正常</el-radio>
          <el-radio label="2">异常</el-radio>
          <el-radio label="3">其他</el-radio>
        </el-radio-group>
      </div>
      <div class="son-item son-item2">
        <div class="key">
          <span>听力（右）：</span>
        </div>
        <el-radio-group v-model="radio">
          <el-radio label="1">正常</el-radio>
          <el-radio label="2">异常</el-radio>
          <el-radio label="3">其他</el-radio>
        </el-radio-group>
      </div>
    </div>
    <div class="son-line" v-show="tabIndex === 9">
      <div class="son-item son-item2">
        <div class="key">
          <span>肺活量：</span>
        </div>
        <el-input class="input"></el-input>
      </div>
    </div>
    <div class="son-line" v-show="tabIndex === 10">
      <div class="son-item son-item2">
        <div class="key">
          <span>白细胞：</span>
        </div>
        <el-input class="input"></el-input>
        <div class="key unit">
          <span>10^9/L</span>
        </div>
      </div>
      <div class="son-item son-item2">
        <div class="key">
          <span>血红蛋白：</span>
        </div>
        <el-input class="input"></el-input>
        <div class="key unit">
          <span>g/L</span>
        </div>
      </div>
      <div class="son-item son-item2">
        <div class="key">
          <span>血小板：</span>
        </div>
        <el-input class="input"></el-input>
        <div class="key unit">
          <span>10^9/L</span>
        </div>
      </div>
    </div>
    <div class="son-line" v-show="tabIndex === 11">
      <div class="son-item son-item2">
        <div class="key">
          <span>血清谷丙氨转酶：</span>
        </div>
        <el-input class="input"></el-input>
        <div class="key unit">
          <span>U/L</span>
        </div>
      </div>
      <div class="son-item son-item2">
        <div class="key">
          <span>血清谷草氨转酶：</span>
        </div>
        <el-input class="input"></el-input>
        <div class="key unit">
          <span>U/L</span>
        </div>
      </div>
      <div class="son-item son-item2">
        <div class="key">
          <span>总胆红素：</span>
        </div>
        <el-input class="input"></el-input>
        <div class="key unit">
          <span>umol/L</span>
        </div>
      </div>
    </div>
    <div class="son-line" v-show="tabIndex === 12">
      <div class="son-item son-item2">
        <div class="key">
          <span>尿蛋白：</span>
        </div>
        <el-input class="input"></el-input>
      </div>
      <div class="son-item son-item2">
        <div class="key">
          <span>尿糖：</span>
        </div>
        <el-input class="input"></el-input>
        <div class="key unit">
          <span>g/L</span>
        </div>
      </div>
      <div class="son-item son-item2">
        <div class="key">
          <span>尿酮体：</span>
        </div>
        <el-input class="input"></el-input>
        <div class="key unit">
          <span>%</span>
        </div>
      </div>
      <div class="son-item son-item2">
        <div class="key">
          <span>尿潜血：</span>
        </div>
        <el-input class="input"></el-input>
      </div>
    </div>
    <div class="son-line" v-show="tabIndex === 13">
      <div class="son-item son-item2">
        <div class="key">
          <span>是否评测：</span>
        </div>
        <el-radio-group v-model="radio">
          <el-radio label="1">是</el-radio>
          <el-radio label="2">否</el-radio>
        </el-radio-group>
      </div>
      <div class="son-item son-item2">
        <div class="key">
          <span>心理测评：</span>
        </div>
        <el-radio-group v-model="radio">
          <el-radio label="1">正常</el-radio>
          <el-radio label="2">异常</el-radio>
        </el-radio-group>
      </div>
    </div>
    <!-- <div class="son-title">
      <span>体检报告结论</span>
    </div>
    <el-input type="textarea" placeholder="">
    </el-input>
    <div class="son-button">
      <el-button>确认</el-button>
    </div> -->
    <div class="son-button">
      <el-button>提交</el-button>
      <el-button>打印预览</el-button>
      <el-button>上传</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      options: [{
        value: '1',
        label: '1'
      }],
      value: '',
      sexs: [{
        value: '1',
        label: '男'
      }, {
        value: '2',
        label: '女'
      }],
      sex: '',
      radio: '',
      tabIndex: 0,
      tabs: Object.freeze(['内科', '外科', '脊柱侧弯', '眼科', '耳鼻喉科', '口腔科', '形态指标', '血压', '听力筛查', '肺功能检测', '血常规', '血生化', '尿常规', '心理测评'])
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    },

    handleClick (tab, event) {
      console.log(tab, event)
    }
  }
}
</script>

<style lang="less" scoped>
.son {
  width: 100%;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;

  &::-webkit-scrollbar {
    width: 0;
  }

  /deep/.el-tabs__item {
    font-size: 18px;
  }

  .son-line {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding-bottom: 20px;
    border-bottom: 1px solid #707070;
    box-sizing: border-box;

    .son-item {
      display: flex;
      align-items: center;
      width: calc(25% - 20px);
      margin: 8px 10px;

      .key {
        white-space: nowrap;
        color: #333333;
        font-size: 18px;
      }

      .unit {
        margin-left: 7px;
      }

      .input {
        max-width: 191px;
      }

      .input1 {
        max-width: 191px;
        margin-left: 10px;
      }

      .input2 {
        max-width: 120px;
      }

      .el-radio-group {
        display: flex;
        align-items: center;
      }

      /deep/.el-radio {
        display: flex;
        align-items: center;
      }

      /deep/.el-radio__inner {
        width: 20px;
        height: 20px;
      }

      /deep/.el-radio__label {
        color: #333333;
        font-size: 18px;
      }

      .el-textarea {
        width: calc(100% - 40px);
        height: 80px;

        /deep/.el-textarea__inner {
          width: 100%;
          height: 100%;
          margin: 0 auto;
          resize: none;
        }
      }
    }

    .son-item1 {
      width: calc(50% - 20px);
    }

    .son-item2 {
      width: calc(33.3% - 20px);
    }

    .son-item3 {
      width: calc(30% - 20px);
    }

    .son-item4 {
      width: calc(70% - 20px);
    }

    .son-item5 {
      width: calc(66.7% - 20px);
    }

    .son-item6 {
      width: 100%;

      .key:nth-child(n+2) {
        margin-left: 30px;
      }

      /deep/.el-date-editor {
        width: 180px;
      }
    }

    .son-item7 {
      align-items: flex-start;
    }
  }

  .son-title {
    width: 100%;
    height: 47px;
    line-height: 47px;
    border-bottom: 1px solid #707070;
    box-sizing: border-box;
    color: #333333;
    font-size: 18px;
    font-weight: 600;

    .blue {
      color: #3FA1FF;
      cursor: pointer;
    }
  }

  .son-tab {
    overflow: hidden;
    display: flex;
    width: 100%;
    height: 30px;
    border-radius: 15px;
    background-color: #B0B0B0;

    .tab-item {
      flex: 1;
      height: 30px;
      line-height: 30px;
      text-align: center;
      color: #fff;
      font-size: 18px;
      cursor: pointer;
    }

    .tab-active {
      background-color: #3FA1FF;
    }
  }

  .son-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100px;

    .el-button {
      min-width: 150px;
      margin-left: 15px;
      margin-right: 15px;
      padding-top: 4px;
      padding-bottom: 7px;
      background-color: #3FA1FF;
      border: none;
      color: #fff;
      font-size: 30px;
    }
  }
}
</style>
