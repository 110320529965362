<template>
  <div class="popup">
    <div class="popup-content">
      <div class="popup-title">
        <span>选择</span>
      </div>
      <div class="popup-box">
        <div class="content">
          <div class="close" @click="close">
            <i class="el-icon-close"></i>
          </div>
          <div class="tip">
            <div class="line">
              <span>请选择：</span>
              <el-radio-group v-model="info.type">
                <el-radio label="1">签到</el-radio>
                <el-radio label="2">退号</el-radio>
              </el-radio-group>
            </div>
          </div>
          <div class="btn">
            <el-button size="small" @click="close">取消</el-button>
            <el-button size="small" @click="submit">确定</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    examineInfo: {
      type: Object,
      default () {
        return {
          id: 0,
          createTime: '',
          updateTime: '',
          regsterpayid: '',
          hostcode: '',
          titletype: '',
          title: '',
          count: '',
          doctor: '',
          doctorcode: '',
          fee: '',
          reghour: '',
          reghalf: '',
          specialty: '',
          remark: '',
          extparam1: '',
          extparam: '',
          deptcode: '',
          deptname: '',
          marktype: '',
          cliniclabel: '',
          registerway: '',
          flagused: '',
          flagavailable: '',
          clinicposition: '',
          clinicfee: '',
          appointcount: '',
          regcount: '',
          dqcode: '',
          day: '',
          deptcode1: '',
          deptcode2: '',
          name: '',
          sfzh: '',
          accountid: '',
          type: ''
        }
      }
    }
  },
  data () {
    return {
      info: {
        id: 0,
        createTime: '',
        updateTime: '',
        regsterpayid: '',
        hostcode: '',
        titletype: '',
        title: '',
        count: '',
        doctor: '',
        doctorcode: '',
        fee: '',
        reghour: '',
        reghalf: '',
        specialty: '',
        remark: '',
        extparam1: '',
        extparam: '',
        deptcode: '',
        deptname: '',
        marktype: '',
        cliniclabel: '',
        registerway: '',
        flagused: '',
        flagavailable: '',
        clinicposition: '',
        clinicfee: '',
        appointcount: '',
        regcount: '',
        dqcode: '',
        day: '',
        deptcode1: '',
        deptcode2: '',
        name: '',
        sfzh: '',
        accountid: '',
        type: ''
      }
    }
  },
  created () {
    this.info = this.examineInfo
  },
  methods: {
    close (type) {
      this.$emit('close', type)
    },
    async submit () {
      console.log('审核信息', this.info)
      try {
        // 接口文档 https://app.apifox.com/project/2726972/apis/api-108062784
        const { data: res } = await this.$http.post('/hitapp/YyghjlCheck', this.info)
        console.log('预约挂号信息审核', res)
        if (res.code !== 0) {
          return this.$alert(res.msg || '操作失败', '提示', {
            confirmButtonText: '确定',
            callback: action => {
              this.close(true)
            }
          })
        }
        this.$message.success('操作成功')
        this.close(true)
      } catch (error) {
        console.log('预约挂号信息审核抛出异常', error)
        this.$message.error('操作失败')
        this.$alert('操作失败', '提示', {
          confirmButtonText: '确定',
          callback: action => {
            this.close(true)
          }
        })
      }
    }
  }
}
</script>

<style scoped lang="less">
.popup {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 88;
  background-color: rgba(0, 0, 0, .74);

  .popup-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 859px;
    height: 264px;

    .popup-title {
      width: 147px;
      height: 50px;
      line-height: 60px;
      background-image: url('../../../img/dialogTitleBg.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      text-align: center;
      color: #fff;
      font-size: 20px;
    }

    .popup-box {
      width: 600px;
      height: 300px;
      padding: 10px;
      background-color: rgba(3, 27, 44, .8);
      border-radius: 10px;
      border: 4px solid rgba(1, 49, 88, .5);
      box-sizing: border-box;

      .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        width: 100%;
        height: 100%;
        padding: 30px 10px;
        border-radius: 10px;
        border: 4px solid rgba(1, 49, 88, .5);
        box-sizing: border-box;

        .close {
          display: flex;
          align-items: flex-end;
          position: absolute;
          top: 0;
          right: 0;
          width: 50px;
          height: 50px;
          background: linear-gradient(26.565deg, #2057a7 50%, transparent 0);
          transform: rotate(180deg);
          color: #fff;
          font-size: 24px;
          cursor: pointer;
        }

        .tip {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: calc(100% - 100px);
          color: #01EEFD;
          font-size: 16px;

          .line {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 100%;
            box-sizing: border-box;
            color: #01EEFD;
            font-size: 16px;

            .el-radio {
              color: #fff;
            }

            /deep/.el-radio__label {
              font-size: 18px;
            }

            /deep/.el-radio__inner {
              background-color: transparent;
            }

            /deep/.el-radio__input.is-checked+.el-radio__label {
              color: #01EEFD;
            }

            /deep/.el-radio__input.is-checked .el-radio__inner {
              background-color: #01EEFD;
              border-color: #01EEFD;
            }
          }
        }

        .btn {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 240px;
          margin: 0 auto;
        }

        .el-button {
          border-radius: 0;
          border: none;
          background-color: #33465C;
          color: #fff;
        }

        .el-button:nth-child(2) {
          background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
        }
      }
    }
  }
}
</style>
