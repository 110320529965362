<template>
  <div class="son">
    <div class="son-head">
      <div class="son-head-item">
        <div class="son-head-left">
          <div class="son-head-open"></div>
          <el-input placeholder="请输入搜索内容"></el-input>
        </div>
        <div class="son-head-center">
          <span>切换机构：</span>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="son-head-right">
          <span class="el-icon-success success"></span>
          <div class="div">
            <div class="div1">
              <div class="blue"></div>
            </div>
          </div>
          <span class="el-icon-lock lock"></span>
          <span class="el-icon-view lock"></span>
          <span class="el-icon-rank rank"></span>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <!-- <div class="son-head-item">
        <div class="son-head-title">
          <span>预约管理</span>
        </div>
        <el-button type="primary">
          <span>更多</span>
          <span class="el-icon-arrow-down icon"></span>
        </el-button>
      </div> -->
    </div>
    <div class="son-scroll">
      <div class="son-search">
        <div class="search-item">
          <div class="key">
            <span>学院</span>
          </div>
          <el-select v-model="params.ssxxid" placeholder="" @change="xxChange">
            <el-option v-for="item in xxs" :key="item.ssxxid" :label="item.ssxx" :value="item.ssxxid">
            </el-option>
          </el-select>
        </div>
        <div class="search-item">
          <div class="key">
            <span>年级</span>
          </div>
          <el-select v-model="params.ssnjid" placeholder="请选择" @change="njChange">
            <el-option v-for="item in njs" :key="item.k" :label="item.val" :value="item.k">
            </el-option>
          </el-select>
        </div>
        <div class="search-item">
          <div class="key">
            <span>班级</span>
          </div>
          <el-select v-model="params.ssbjid" placeholder="请选择" @change="bjChange">
            <el-option v-for="item in bjs" :key="item.id" :label="item.val" :value="item.k">
            </el-option>
          </el-select>
        </div>
        <div class="search-item">
          <div class="key">
            <span>体检项目</span>
          </div>
          <el-select v-model="params.tcmc" placeholder="请选择" @change="testChange">
            <el-option v-for="item in tests" :key="item.id" :label="item.tclbmc" :value="item.tclbbm">
            </el-option>
          </el-select>
        </div>
        <div class="search-item">
          <div class="key">
            <span>套餐</span>
          </div>
          <el-select v-model="params.tcmc2" placeholder="请选择">
            <el-option v-for="item in tcmc2s" :key="item.id" :label="item.tcmc" :value="item.id">
            </el-option>
          </el-select>
        </div>
        <div class="search-item">
          <div class="key">
            <span>筛选</span>
          </div>
          <el-input placeholder="请输入" v-model="params.sxtj"></el-input>
        </div>
        <div class="search-item">
          <div class="key">
            <span>体检</span>
          </div>
          <el-select v-model="params.tmdy" placeholder="请选择">
            <el-option v-for="item in tmdys" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="search-item">
          <div class="key">
            <span>核查状态</span>
          </div>
          <el-select v-model="params.hcflag" placeholder="请选择">
            <el-option v-for="item in hczts" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="search-item">
          <div class="key">
            <span>缺项漏项</span>
          </div>
          <el-select v-model="params.qxlx" placeholder="请选择">
            <el-option v-for="item in qxlxs" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="search-item">
          <div class="key">
            <span>状态</span>
          </div>
          <el-select v-model="params.sftj" placeholder="请选择">
            <el-option v-for="item in zts" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>

        <div class="search-item">
          <div class="key">
            <span>体检日期</span>
          </div>
          <el-date-picker v-model="params.tjrq" value-format="yyyy-MM-dd" type="daterange" range-separator="至" start-placeholder="开始日期"
            end-placeholder="结束日期" @change="testDateChange">
          </el-date-picker>
        </div>
        <div class="search-item">
          <div class="key">
            <span>核查日期</span>
          </div>
          <el-date-picker v-model="params.hcrq" type="daterange" range-separator="至" start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </div>
        <div class="search-item">
          <div class="key">
            <span>体检类型</span>
          </div>
          <el-select v-model="params.tjlx" placeholder="请选择" @change="testChange">
            <el-option v-for="item in tests" :key="item.id" :label="item.tclbmc" :value="item.tclbbm">
            </el-option>
          </el-select>
        </div>
        <div class="search-item">
          <div class="key">
            <span>进度</span>
          </div>
          <el-select v-model="params.tjjdid" placeholder="请选择" @change="jdChange">
            <el-option v-for="item in jds" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="search-item">
          <div class="key">
            <span>条码</span>
          </div>
          <el-select v-model="params.tmsfdy" placeholder="请选择" @change="tmChange">
            <el-option v-for="item in tms" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="button">
          <el-button @click="getInfo">
            <span class="el-icon-search"></span>
            <span>查询</span>
          </el-button>
          <el-button @click="clean">
            <span class="el-icon-refresh"></span>
            <span>重置</span>
          </el-button>
          <el-button>
            <span class="el-icon-upload"></span>
            <span>导出</span>
          </el-button>
        </div>
        <div class="search-item search-item1">
          <span class="span">体检异常 </span>
          <span class="span1 span-red">红色</span>
        </div>
        <div class="search-item search-item1">
          <span class="span">缺项漏项 </span>
          <span class="span1 span-yellow">黄色</span>
        </div>
        <div class="search-item search-item1">
          <span class="span">核查标记异常 </span>
          <span class="span1 span-blue">蓝色</span>
        </div>
      </div>

      <div class="son-info">
        <div class="table">
          <el-table :data="tableData" height="100%" style="width: 100%" :row-class-name="tableRowClassName">
            <el-table-column prop="id" label="序号" :width="flexWidth('id', tableData, '序号')">
            </el-table-column>
            <el-table-column prop="name" label="姓名" :width="flexWidth('name', tableData, '姓名')">
            </el-table-column>
            <el-table-column label="性别" :width="flexWidth('xb', tableData, '性别')">
              <template slot-scope="scope">
                <span>{{ scope.row.xb | xbType }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="age" label="年龄" :width="flexWidth('age', tableData, '年龄')">
            </el-table-column>
            <el-table-column prop="tmcode" label="体检编号" :width="flexWidth('tmcode', tableData, '体检编号')">
            </el-table-column>
            <el-table-column prop="ssxx" label="学校" :width="flexWidth('ssxx', tableData, '学校')">
            </el-table-column>
            <el-table-column prop="ssnj" label="年级" :width="flexWidth('ssnj', tableData, '年级')">
            </el-table-column>
            <el-table-column prop="ssbj" label="班级" :width="flexWidth('ssbj', tableData, '班级')">
            </el-table-column>
            <el-table-column prop="tjrq" label="体检日期" :width="flexWidth('tjrq', tableData, '体检日期')">
            </el-table-column>
            <el-table-column label="体检状态" width="100">
              <template slot-scope="scope">
                <span>{{ scope.row.tmdy | testType }}</span>
              </template>
            </el-table-column>
            <el-table-column label="有无异常" width="100">
              <template slot-scope="scope">
                <span>{{ scope.row.tjsfyc | ycType }}</span>
              </template>
            </el-table-column>
            <el-table-column label="核查" width="100">
              <template slot-scope="scope">
                <span>{{ scope.row.hcflag | hcType }}</span>
              </template>
            </el-table-column>

            <el-table-column prop="sfzh" label="身份证号" :width="flexWidth('sfzh', tableData, '身份证号')">
            </el-table-column>
            <el-table-column prop="ssjg" label="所属机构" :width="flexWidth('ssjg', tableData, '所属机构')">
            </el-table-column>
            <el-table-column prop="tjnf" label="体检年份" :width="flexWidth('tjnf', tableData, '体检年份')">
            </el-table-column>
            <el-table-column prop="zz" label="内科" width="100">
              <template slot-scope="scope">
                <span>{{ scope.row.nkjcqk | scheduleType }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="zz" label="外科" width="100">
              <template slot-scope="scope">
                <span>{{ scope.row.wkjcqk | scheduleType }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="zz" label="脊柱侧弯" width="100">
              <template slot-scope="scope">
                <span>{{ scope.row.jzcwjcqk | scheduleType }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="zz" label="眼科" width="100">
              <template slot-scope="scope">
                <span>{{ scope.row.yankejjcqk | scheduleType }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="zz" label="耳鼻喉科" width="100">
              <template slot-scope="scope">
                <span>{{ scope.row.ebhjcqk | scheduleType }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="zz" label="口腔科" width="100">
              <template slot-scope="scope">
                <span>{{ scope.row.kqjcqk | scheduleType }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="zz" label="形态指标" width="100">
              <template slot-scope="scope">
                <span>{{ scope.row.xitcqk | scheduleType }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="zz" label="血压" width="100">
              <template slot-scope="scope">
                <span>{{ scope.row.xyjcqk | scheduleType }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="zz" label="听力筛查" width="100">
              <template>
                <div class="flex">
                  <span class="green">-</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="zz" label="肺功能检测" width="160">
              <template slot-scope="scope">
                <span>{{ scope.row.fggjcqk | scheduleType }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="zz" label="血常规" width="160">
              <template slot-scope="scope">
                <span>{{ scope.row.xcgjcqk | scheduleType }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="zz" label="血生化" width="160">
              <template slot-scope="scope">
                <span>{{ scope.row.xshjcqk | scheduleType }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="zz" label="尿常规" width="160">
              <template>
                <div class="flex">
                  <span class="green">-</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="zz" label="心理测试" width="160">
              <template>
                <div class="flex">
                  <span class="green">-</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="zz" label="体检小结" width="160">
              <template>
                <div class="flex">
                  <span class="green">-</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="210" fixed="right">
              <template slot-scope="scope">
                <div class="flex">
                  <span class="blue" @click="openSee(scope.row)">编辑</span>
                  <span class="blue">同步</span>
                  <span class="blue" @click="openReport(scope.row)">报告预览</span>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="query.pageNum"
      background
      :page-size="query.pageSize"
      layout="total, prev, pager, next, jumper"
      :total="query.total"></el-pagination>
      </div>
    </div>
    <See v-if="showSee" @close="closeSee"></See>
    <Report v-if="showReport" @close="closeReport"></Report>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import See from './see/see.vue'
import Report from './report/report.vue'
export default {
  components: {
    See,
    Report
  },
  data () {
    return {
      options: [{
        value: '',
        label: ''
      }],
      tmdys: [{ label: '未体检', value: '0' },
        { label: '已体检', value: '1' }],
      value: '',
      value1: '',
      value2: '',
      value3: '',
      value4: '',
      value5: '',
      value6: '',
      tjlx: '',
      tests: [],
      tcmc2s: [],
      tcmc: '',
      testTypes: [
        { label: '学生体检', value: '01' }
      ],
      xxs: [],
      ssxx: '',
      bjs: [],
      bj: '',
      ssbjid: '',
      njs: [],
      nj: '',
      jds: [
        { label: '正常检查', value: '0' },
        { label: '尚未检查', value: '1' },
        { label: '无需检查', value: '2' },
        { label: '自愿放弃', value: '3' }
      ],
      jd: '',
      zts: [
        { label: '未提交', value: '0' },
        { label: '体检', value: '1' }
      ],
      sftj: '',
      drs: [
        { label: '是', value: '0' },
        { label: '否', value: '1' }
      ],
      dr: '',
      tjjdid: '',
      tms: [
        { label: '未打印', value: '0' },
        { label: '打印', value: '1' }
      ],
      tm: '',
      tmdy: '',
      ssnjid: '',
      hczts: [
        { label: '异常', value: '0' },
        { label: '无异常', value: '1' }
      ],
      qxlxs: [
        { label: '是', value: '0' },
        { label: '否', value: '1' }
      ],
      params: {
        start: '0',
        length: '10',
        tjjgbh: '',
        sxtj: '',
        ssxxid: '',
        ssnjid: '',
        sftj: '',
        tcmcid: '',
        tjlxid: '',
        tjjd: '',
        tjjdid: '',
        hcrq: '',
        tcmc2: '',
        tmsfdy: '',
        tjrq: '',
        qxlx: '',
        tjlx: '',
        hcflag: '',
        drflag: '',
        begtjsj: '',
        endtjsj: ''
      },
      // 分页数据
      query: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      tableData: [],
      total: 400,
      currentPage: 0,
      showSet: false,
      showAdd: false,
      showSee: false,
      selectInfo: null,
      showReport: false
    }
  },
  filters: {
    xbType (val) {
      if (val === '1') return '男'
      if (val === '2') return '女'
      return '-'
    },
    ycType (val) {
      if (val === '0') return '无异常'
      if (val === '1') return '有异常'
      return '-'
    },
    hcType (val) {
      if (val === '0') return '未核查'
      if (val === '1') return '已核查'
      return '-'
    },
    scheduleType (val) {
      if (val === '' || val === null) return '-'
      const arr = ['正常检查', '尚未检查', '无需检查', '自愿放弃']
      return arr[val]
    },
    testType (val) {
      if (val === '0') return '未体检'
      if (val === '1') return '已体检'
      return '-'
    }
  },
  computed: {
    ...mapState(['loginInfo'])
  },
  created () {
    this.params.tjjgbh = this.loginInfo.jgbh || ''
    this.params.jgbh = this.loginInfo.jgbh || ''
    this.getInfo()
    this.getTestInfo()
    this.getTestInfoTwo()
    this.getBjInfo()
    this.getXxInfo()
    this.getNjInfo()
  },
  methods: {
    tableRowClassName ({ row, rowIndex }) {
      // if (rowIndex === 0) {
      //   return 'row-red' // 第一行的类名
      // } else if (rowIndex === 1) {
      //   return 'row-yellow' // 第二行的类名
      // } else if (rowIndex === 2) {
      //   return 'row-blue' // 第二行的类名
      // }
      if (row.hcjgflag === '1') {
        return 'row-blue'
      } else if (row.tjqk === '1') {
        return 'row-yellow'
      } else if (row.tjsfyc === '1') {
        return 'row-red'
      }
      return ''
    },
    flexWidth (prop, tableData, title, num = 0) {
      if (tableData.length === 0) { // 表格没数据不做处理
        return title.length * 30 + 'px'
      }
      let flexWidth = 0// 初始化表格列宽
      let columnContent = ''// 占位最宽的内容
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')
      context.font = '16px Microsoft YaHei'
      if ((prop === '') && title) { // 标题长内容少的，取标题的值,
        columnContent = title
      } else { // 获取该列中占位最宽的内容
        let index = 0
        for (let i = 0; i < tableData.length; i++) {
          const nowTemp = tableData[i][prop] + ''
          const maxTemp = tableData[index][prop] + ''
          const nowTempW = context.measureText(nowTemp).width
          const maxTempW = context.measureText(maxTemp).width
          if (nowTempW > maxTempW) {
            index = i
          }
        }
        columnContent = tableData[index][prop]
        // 比较占位最宽的值跟标题、标题为空的留出四个位置
        const columnW = context.measureText(columnContent).width
        const titleW = context.measureText(title).width
        if (columnW < titleW) {
          columnContent = title || '留七'
        }
      }
      // 计算最宽内容的列宽
      const width = context.measureText(columnContent)
      flexWidth = width.width + 38 + num
      return flexWidth + 'px'
    },
    async getTestInfo () {
      // 接口文档 https://app.apifox.com/project/2726972/apis/api-160594366
      try {
        const params = {
          start: '0',
          length: '10',
          tjjgbh: this.loginInfo.jgbh || ''
        }
        const { data: res } = await this.$http.post('/zhctapi/findtcxmzd', params)
        console.log('根据机构获取套餐字典', res)
        if (res.code !== 0 || res.data === null) return
        this.tests = res.data
      } catch (err) {
        console.log('根据机构获取套餐字典', err)
      }
    },
    async getTestInfoTwo () {
      try {
        const params2 = {
          start: '0',
          length: '10',
          ssjgbh: this.loginInfo.jgbh || ''
        }
        const { data: res } = await this.$http.post('/zhctapi/getlist', params2)
        console.log('套餐项目列表', res)
        if (res.code !== 0 || res.data === null) return
        this.tcmc2s = res.data
        console.log('+++++++' + this.tcmc2s)
      } catch (err) {
        console.log('套餐项目列表', err)
      }
    },
    clean () {
      this.params.ssxxid = ''
      this.params.ssnjid = ''
      this.params.ssbjid = ''
      this.params.tcmc = ''
      this.params.tcmc2 = ''
      this.params.sxtj = ''
      this.params.tmdy = ''
      this.params.hcflag = ''
      this.params.qxlx = ''
      this.params.sftj = ''
      this.params.tjrq = ''
      this.params.hcrq = ''
      this.params.tjlx = ''
      this.params.tjjdid = ''
      this.params.tmsfdy = ''
      this.getInfo()
    },
    openSee (info) {
      this.selectInfo = JSON.parse(JSON.stringify(info))
      this.showSee = true
    },
    closeSee () {
      this.selectInfo = null
      this.showSee = false
    },
    openReport (info) {
      console.log('---')
      this.selectInfo = JSON.parse(JSON.stringify(info))
      this.showReport = true
    },
    closeReport () {
      this.selectInfo = null
      this.showReport = false
    },
    testChange (e) {
      console.log(e)
      this.params.tcmcid = e
    },
    testTypeChange (e) {
      console.log(e)
      this.params.tjlxid = e
    },
    jdChange (e) {
      console.log(e)
      this.params.tjjd = e
    },
    tmChange (e) {
      console.log(e)
      this.params.tmsfdy = e
    },
    drChange (e) {
      console.log(e)
      this.params.drflag = e
    },
    testDateChange (e) {
      console.log(e)
      this.params.begtjsj = e[0]
      this.params.endtjsj = e[1]
    },
    async getXxInfo () {
      // 接口文档 https://app.apifox.com/project/2726972/apis/api-160594030
      try {
        const params = {
          start: '0',
          length: '10',
          tjjgbh: this.loginInfo.jgbh || ''
        }
        const { data: res } = await this.$http.post('/zhctapi/findssxx', params)
        console.log('根据机构获取学校列表', res)
        if (res.code !== 0 || res.data === null) return
        this.xxs = res.data
        // this.xxs = [
        //   { ssxx: 'dd', ssxxid: '123' }
        // ]
      } catch (err) {
        console.log('根据机构获取学校列表', err)
      }
    },
    xxChange (e) {
      const arr = this.xxs.filter(item => item.ssxxid === this.params.ssxxid)
      this.ssxx = arr[0].ssxx
      console.log(e, this.params.ssxxid, this.ssxx)
    },
    async getBjInfo () {
      // 接口文档 https://app.apifox.com/project/2726972/apis/api-118011488
      try {
        const params = {
          type: 'ssbj'
        }
        const { data: res } = await this.$http.post('/api/jkct/app/finddict', params)
        console.log('班级', res)
        if (res.code !== 0 || res.data === null) return
        this.bjs = res.data
      } catch (err) {
        console.log('班级', err)
      }
    },
    bjChange (e) {
      const arr = this.bjs.filter(item => item.k === this.ssbjid)
      console.log(e, arr)
      this.bj = arr[0].val
      console.log(e, arr)
    },
    async getNjInfo () {
      // 接口文档 https://app.apifox.com/project/2726972/apis/api-118011488
      try {
        const params = {
          type: 'ssnj'
        }
        const { data: res } = await this.$http.post('/api/jkct/app/finddict', params)
        console.log('年级', res)
        if (res.code !== 0 || res.data === null) return
        this.njs = res.data
      } catch (err) {
        console.log('年级', err)
      }
    },
    njChange (e) {
      const arr = this.njs.filter(item => item.k === this.params.ssnjid)
      console.log(e, arr)
      this.nj = arr[0].val
      console.log(e, arr)
    },
    async getInfo () {
      // 接口文档 https://app.apifox.com/project/2726972/apis/api-159158565
      console.log(this.params)
      try {
        this.params.start = this.query.pageNum === 1 ? '0' : (this.query.pageNum - 1) * 10 + ''
        this.params.length = this.query.pageSize + ''
        const { data: res } = await this.$http.get('/ykdzhctxstjbs', { params: this.params })
        console.log('体检列表', res)
        if (res.code !== 0 || res.data === null || res.data.length < 1) {
          return this.$message({
            message: res.msg || '暂无信息',
            type: 'warning'
          })
        }
        this.tableData = res.data
        this.query.total = res.recordsTotal
      } catch (err) {
        console.log('体检列表', err)
      }
    },
    tabClick (index) {
      this.tabIndex = index
    },
    openSet () {
      this.showSet = true
    },
    clsoeSet () {
      this.showSet = false
    },
    openAdd () {
      this.showAdd = true
    },
    closeAdd () {
      this.showAdd = false
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
      this.query.pageNum = val
      this.getInfo()
    }
  }
}
</script>

<style lang="less" scoped>
.son {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #F0F0F0;

  .son-head {
    display: flex;
    align-items: center;
    width: 100%;
    height: 70px;
    margin-bottom: 10px;
    background-color: #fff;
    box-sizing: border-box;

    .son-head-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 0 14px;
      box-sizing: border-box;

      .son-head-left {
        display: flex;
        align-items: center;
        width: 33%;

        .son-head-open {
          width: 23px;
          height: 6px;
          border-top: 2px solid #595959;
          border-bottom: 2px solid #595959;
        }

        .el-input {
          margin-left: 50px;
        }

        /deep/.el-input__inner {
          border-color: #fff;
          font-size: 18px;
        }
      }

      .son-head-center {
        display: flex;
        align-items: center;
        flex: 1;
        color: #595959;
        font-size: 20px;

        /deep/.el-input__inner {
          font-size: 18px;
        }
      }

      .son-head-right {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex: 1;

        .success {
          color: #67C23A;
          font-size: 39px;
        }

        .lock {
          color: #595959;
          font-size: 30px;
        }

        .rank {
          font-size: 34px;
          transform: rotate(45deg);
        }

        .div {
          width: 41px;
          height: 40px;
          padding: 6px 7px;
          border: 1px solid #E6E6E6;
          border-radius: 6px;
          box-sizing: border-box;

          .div1 {
            width: 100%;
            height: 100%;
            padding-top: 3px;
            border: 1px solid #999999;
            box-sizing: border-box;

            .blue {
              width: 100%;
              height: 100%;
              background-color: #409EFF;
            }
          }
        }

        /deep/.el-input__inner {
          text-align: right;
          border: none;
          font-size: 18px;
        }
      }

      .son-head-title {
        width: 117px;
        height: 38px;
        line-height: 38px;
        text-align: center;
        border: 1px solid #409EFF;
        border-radius: 2px;
        box-sizing: border-box;
        color: #409EFF;
        font-size: 16px;
      }

      .el-button {
        width: 100px;

        .icon {
          margin-left: 10px;
        }
      }
    }
  }

  .son-scroll {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 80px);
    padding: 0 10px;
    box-sizing: border-box;

    &::-webkit-scrollbar {
      width: 0;
    }

    .son-search {
      display: flex;
      align-content: space-evenly;
      flex-wrap: wrap;
      width: 100%;
      height: 320px;
      background-color: #fff;
      border-radius: 18px;
      box-sizing: border-box;

      .search-item {
        display: flex;
        align-items: center;
        width: calc(25% - 40px);
        margin: 0 20px;

        .span {
          color: #17212B;
          font-size: 22px;
        }

        .span1 {
          padding: 2px 10px;
          color: #fff;
          font-size: 22px;
        }

        .span-red {
          background-color: #fe8b8b;
        }

        .span-yellow {
          background-color: #fddd82;
        }

        .span-blue {
          background-color: #4b7cf2;
        }
      }

      .search-item1 {
        width: auto;
      }

      .key {
        margin-right: 20px;
        white-space: nowrap;
        color: #17212B;
        font-size: 22px;
      }

      .el-button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        margin-right: 20px;
        padding-top: 0;
        padding-bottom: 0;
        background-color: #3FA1FF;
        color: #fff;
        font-size: 22px;
      }

      .button {
        display: flex;
        align-items: center;
        width: calc(25% - 40px);
      }
    }

    .son-info {
      width: 100%;
      height: calc(100% - 330px);
      margin-top: 10px;
      padding: 0 20px;
      background-color: #fff;
      border-radius: 18px;
      box-sizing: border-box;

      .table {
        width: 100%;
        height: calc(100% - 90px);

        /deep/.row-red {
          background-color: #fe8b8b;
        }

        /deep/.row-yellow {
          background-color: #fddd82;
        }

        /deep/.row-blue {
          background-color: #4b7cf2;
        }
      }

      // 表格样式修改
      .el-table {

        &::before {
          background-color: transparent;
        }

        // 表头首行
        /deep/th.el-table__cell.is-leaf {
          border-bottom: none;
        }

        /deep/td.el-table__cell {
          // border-bottom: 1px solid #01EEFD;
        }

        // 表头背景颜色
        /deep/th.el-table__cell {
          // background-color: #fff;
        }

        /deep/.el-table__cell {
          text-align: center;
          color: #17212B;
        }

        /deep/tr {
          // background-color: #C9D8E7;
        }

        /deep/.el-table__body tr:hover>td.el-table__cell {
          // background-color: transparent !important;
        }

        /deep/.el-checkbox__inner {
          background-color: transparent;
        }

        /deep/.is-indeterminate .el-checkbox__inner,
        /deep/.is-checked .el-checkbox__inner {
          // background-color: #04B4D0;
        }

        // /deep/.el-table__body-wrapper::-webkit-scrollbar {
        //   width: 0;
        // }

        /deep/.el-table__empty-text {
          color: #fff;
        }

        .flex {
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;
          width: 100%;

          .item {
            font-size: 14px;
            cursor: pointer;
          }

          .green {
            color: #17AF12;
          }

          .organ {
            color: #FF6600;
          }

          .red {
            color: #FF0000;
          }

          .blue {
            // color: #399BFB;
            font-size: 16px;
            cursor: pointer;
          }
        }
      }

      // 页码样式修改
      .el-pagination {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: 20px;

        /deep/.el-pagination__total,
        /deep/.el-pagination__jump {
          color: #000000;

          .el-input__inner {
            color: #000000;
            background-color: transparent;
          }
        }

        /deep/.el-pager li:not(.disabled).active {
          background-color: #01EEFD;
        }
      }
    }
  }
}
</style>
