import { render, staticRenderFns } from "./registrationDelete.vue?vue&type=template&id=cfcc97ac&scoped=true&"
import script from "./registrationDelete.vue?vue&type=script&lang=js&"
export * from "./registrationDelete.vue?vue&type=script&lang=js&"
import style0 from "./registrationDelete.vue?vue&type=style&index=0&id=cfcc97ac&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cfcc97ac",
  null
  
)

export default component.exports