<!-- <template>
  <div class="popup">
    <div class="popup-content">
      <div class="popup-title">
        <span>新增/编辑护理服务</span>
      </div>
      <div class="popup-box">
        <div class="content">
          <div class="close" @click="close">
            <i class="el-icon-close"></i>
          </div>
          <div class="info">
            <div class="info-line">
              <span>服务名称</span>
              <el-select v-model="value" placeholder="请选择">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div class="info-line">
              <span>关联表单</span>
              <el-input></el-input>
            </div>
            <div class="info-line">
              <span>服务描述</span>
              <el-input type="textarea" placeholder="">
              </el-input>
            </div>
          </div>
          <div class="btn">
            <el-button size="small" @click="close">取消</el-button>
            <el-button size="small" @click="submit">确定</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tableData: [{
        date: '2016-05-02',
        name: '王小虎',
        address: '等待外出'
      }, {
        date: '2016-05-04',
        name: '王小虎',
        address: '未返回'
      }, {
        date: '2016-05-01',
        name: '王小虎',
        address: '逾期未返回'
      }],
      input: '',
      options: [{
        value: '',
        label: ''
      }],
      value: ''
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    submit () {
      this.$emit('close', this.input)
    }
  }
}
</script>

<style scoped lang="less">
.popup {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 88;
  background-color: rgba(0, 0, 0, .74);

  .popup-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1042px;
    height: 500px;

    .popup-title {
      width: 240px;
      height: 50px;
      line-height: 60px;
      background-image: url('../../../../img/dialogTitleBg.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      text-align: center;
      color: #fff;
      font-size: 20px;
    }

    .popup-box {
      width: 1042px;
      height: 450px;
      padding: 10px;
      background-color: rgba(3, 27, 44, .8);
      border-radius: 10px;
      border: 4px solid rgba(1, 49, 88, .5);
      box-sizing: border-box;

      .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        width: 100%;
        height: 100%;
        padding: 30px 10px;
        border-radius: 10px;
        border: 4px solid rgba(1, 49, 88, .5);
        box-sizing: border-box;

        .close {
          display: flex;
          align-items: flex-end;
          position: absolute;
          top: 0;
          right: 0;
          width: 50px;
          height: 50px;
          background: linear-gradient(26.565deg, #2057a7 50%, transparent 0);
          transform: rotate(180deg);
          color: #fff;
          font-size: 24px;
          cursor: pointer;
        }

        .info {
          display: flex;
          flex-direction: column;
          width: 100%;

          .info-line {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            margin-bottom: 20px;
            color: #01EEFD;
            font-size: 18px;

            .el-select {
              width: 800px;
              margin-left: 28px;
            }

            .el-input {
              width: 800px;
              margin-left: 28px;
            }

            .el-textarea {
              width: 800px;
              margin-left: 28px;
            }

            /deep/.el-textarea__inner {
              width: 800px;
              height: 104px;
              resize: none;
            }
          }

          .info-line:nth-child(3) {
            align-items: initial;
          }
        }

        .btn {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 240px;
          margin: 0 auto;
        }

        .el-button {
          border-radius: 0;
          border: none;
          background-color: #33465C;
          color: #fff;
        }

        .el-button:nth-child(2) {
          background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
        }
      }
    }
  }
}
</style> -->
<template>
  <div class="popup">
    <div class="popup-box">
      <div class="popup-title">
        <div class="popup-top"></div>
        <div class="popup-bottom">
          <div class="popup-bottom-text">
            <span>新增/编辑护理项目</span>
          </div>
        </div>
      </div>

      <div class="popup-content">
        <div class="popup-content-box">
          <div class="info-close" @click="close">
            <span class="el-icon-close"></span>
          </div>
          <div class="info-item">
            <span class="key">项目编码<span style="color: red;">*</span></span>
            <el-input placeholder="请输入"></el-input>
          </div>
          <div class="info-item">
            <span class="key">项目名称<span style="color: red;">*</span></span>
            <el-input placeholder="请输入"></el-input>
          </div>
          <div class="info-item">
            <span class="key">项目金额<span style="color: red;">*</span></span>
            <el-input placeholder="请输入"></el-input>
          </div>
          <div class="info-item">
            <span class="key">项目类别<span style="color: red;">*</span></span>
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="info-item">
            <span class="key">费用种类<span style="color: red;">*</span></span>
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="info-item">
            <span class="key">费用种类<span style="color: red;">*</span></span>
            <el-radio-group v-model="radio">
              <el-radio label="1">启动</el-radio>
              <el-radio label="2">禁用</el-radio>
            </el-radio-group>
          </div>
          <div class="info-item info-item1 info-item2">
            <span class="key">项目简介</span>
            <el-input type="textarea" placeholder="请输入">
            </el-input>
          </div>
          <div class="info-item">
            <span class="key">项目图片</span>
            <el-upload class="avatar-uploader" action="https://jsonplaceholder.typicode.com/posts/"
              :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
              <img v-if="imageUrl" :src="imageUrl" class="avatar">
              <div class="text" v-else>
                <i class="el-icon-plus icon"></i>
                <span>点击上传</span>
              </div>
            </el-upload>
          </div>

          <div class="info-btns">
            <el-button size="small" @click="close">取消</el-button>
            <el-button size="small" class="blue" @click="close">确定</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {

  },
  data () {
    return {
      options: [{
        value: '',
        label: ''
      }],
      value: '',
      radio: '',
      imageUrl: ''
    }
  },
  methods: {
    handleAvatarSuccess (res, file) {
      this.imageUrl = URL.createObjectURL(file.raw)
    },
    beforeAvatarUpload (file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.popup {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 888;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .82);
  box-sizing: border-box;

  .popup-box {
    display: flex;
    flex-direction: column;
    width: 1006px;
  }

  .popup-title {
    display: flex;
    flex-direction: column;

    .popup-top {
      position: relative;
      width: 187px;
      height: 6px;
      transform: skew(30deg);
      background-color: #03BCD7;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: -6px;
        width: 10px;
        height: 6px;
        background-color: #081C38;
        transform: skew(-50deg);
      }
    }

    .popup-bottom {
      overflow: hidden;
      position: relative;
      width: 220px;
      height: 30px;
      background: linear-gradient(97deg, #0177CD 0%, #024073 100%);
      transform: skew(30deg);

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: -30px;
        width: 20px;
        height: 30px;
        background-color: #081C38;
        transform: skew(-55deg);
      }

      .popup-bottom-text {
        height: 30px;
        line-height: 30px;
        padding-left: 20px;
        box-sizing: border-box;
        transform: skew(-30deg);
        color: #fff;
        font-size: 20px;
        font-weight: bold;
      }
    }
  }

  .popup-content {
    width: 100%;
    height: 620px;
    margin-top: 11px;
    padding: 10px;
    background: linear-gradient(93deg, rgba(10, 71, 123, .27) 0%, rgba(17, 94, 156, .27) 100%);
    border: 4px solid rgba(1, 124, 219, .27);
    box-sizing: border-box;

    .popup-content-box {
      overflow: hidden;
      position: relative;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      height: 100%;
      padding: 30px 20px;
      border: 4px solid rgba(1, 124, 219, .27);
      box-sizing: border-box;

      .info-close {
        position: absolute;
        top: -15px;
        right: -40px;
        width: 100px;
        height: 45px;
        line-height: 60px;
        text-align: center;
        transform: rotate(30deg);
        background: linear-gradient(117deg, #1E58A6 0%, #1C4EA1 53%, #2866B1 100%);
        box-shadow: inset 0px -13px 0px 1px #2661AB;
        color: #fff;
        font-size: 24px;
        box-sizing: border-box;

        span {
          transform: rotate(-30deg);
        }
      }

      .info-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 50%;
        color: #04B4D0;
        font-size: 18px;

        .key {
          margin-right: 10px;
          white-space: nowrap;
        }

        .value {
          width: calc(100% - 120px);
          word-wrap: break-word;
        }

        .el-input {
          width: 166px;
        }

        .el-select {
          width: 166px;
        }

        .el-radio {
          color: #fff;
        }

        /deep/.el-radio__label {
          font-size: 18px;
        }

        /deep/.el-radio__inner {
          background-color: transparent;
        }

        /deep/.el-radio__input.is-checked+.el-radio__label {
          color: #01EEFD;
        }

        /deep/.el-radio__input.is-checked .el-radio__inner {
          background-color: #01EEFD;
          border-color: #01EEFD;
        }

        .avatar-uploader {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 140px;
          height: 123px;
          border: 1px solid #fff;

          .text {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            color: #fff;

            .icon {
              font-size: 26px;
            }
          }

          .avatar {
            width: 100%;
            height: 100%;
          }
        }
      }

      .info-item1 {
        width: 100%;

        /deep/.el-textarea__inner {
          width: calc(100% - 140px);
          height: 100px;
          resize: none;
        }
      }

      .info-item2 {
        align-items: flex-start;
      }

      .info-btns {
        position: absolute;
        left: 0;
        bottom: 10px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;

        .el-button {
          margin: 0 50px;
          background-color: #33465C;
          border: none;
          color: #fff;
        }

        .blue {
          background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
        }
      }
    }
  }
}
</style>
