<template>
  <div class="set">
    <div class="set-box">
      <div class="set-head">
        <span>体检角色设置</span>
      </div>
      <div class="set-scroll">
        <div class="set-flex">
          <div class="set-item">
            <div class="set-key">
              <span>体检机构</span>
            </div>
            <el-select v-model="params.tjjggmc" placeholder="请输入">
              <el-option
                v-for="item in tjjggmcs"
                :key="item.tjjggbh"
                :label="item.tjjggmc"
                :value="item.tjjggbh">
              </el-option>
            </el-select>
          </div>
          <div class="set-item">
            <div class="set-key">
              <span>学校</span>
            </div>
            <el-select v-model="params.ssxx" placeholder="请输入">
              <el-option
                v-for="item in ssxxids"
                :key="item.ssxx"
                :label="item.ssxxs"
                :value="item.ssxxid">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="set-flex">
          <div class="set-item">
            <div class="set-key">
              <span>内科</span>
            </div>
            <el-select v-model="params.nk" placeholder="请输入" @change="handleSelectChange">
              <el-option
                v-for="item in yslbs"
                :key="item.id"
                :label="item.nickname"
                :value="item.id">
              </el-option>
            </el-select>
            <el-popover
              placement="bottom"
              width="113"
              trigger="click">
              <img :src="params.ysqm">
              <el-button slot="reference">签名</el-button>
            </el-popover>
          </div>
        </div>
        <div class="set-flex">
          <div class="set-item">
            <div class="set-key">
              <span>外科</span>
            </div>
            <el-select v-model="params.wk" placeholder="请输入" @change="handleSelectChange($event, 'ysqm')">
              <el-option
                v-for="item in yslbs"
                :key="item.id"
                :label="item.nickname"
                :value="item.id">
              </el-option>
            </el-select>
            <el-popover
              placement="bottom"
              width="113"
              trigger="click">
              <img :src="params.ysqm">
              <el-button slot="reference">签名</el-button>
            </el-popover>
          </div>
        </div>
        <div class="set-flex">
          <div class="set-item">
            <div class="set-key">
              <span>脊柱侧弯</span>
            </div>
            <el-select v-model="params.jzcw" placeholder="请输入" @change="handleSelectChange">
              <el-option
                v-for="item in yslbs"
                :key="item.id"
                :label="item.nickname"
                :value="item.id">
              </el-option>
            </el-select>
            <el-popover
              placement="bottom"
              width="113"
              trigger="click">
              <img :src="params.ysqm">
              <el-button slot="reference">签名</el-button>
            </el-popover>
          </div>
        </div>
        <div class="set-flex">
          <div class="set-item">
            <div class="set-key">
              <span>眼科</span>
            </div>
            <el-select v-model="params.yk" placeholder="请输入" @change="handleSelectChange">
              <el-option
                v-for="item in yslbs"
                :key="item.id"
                :label="item.nickname"
                :value="item.id">
              </el-option>
            </el-select>
            <el-popover
              placement="bottom"
              width="113"
              trigger="click">
              <img :src="params.ysqm">
              <el-button slot="reference">签名</el-button>
            </el-popover>
          </div>
        </div>
        <div class="set-flex">
          <div class="set-item">
            <div class="set-key">
              <span>耳鼻喉科</span>
            </div>
            <el-select v-model="params.ebhk" placeholder="请输入" @change="handleSelectChange">
              <el-option
                v-for="item in yslbs"
                :key="item.id"
                :label="item.nickname"
                :value="item.id">
              </el-option>
            </el-select>
            <el-popover
              placement="bottom"
              width="113"
              trigger="click">
              <img :src="params.ysqm">
              <el-button slot="reference">签名</el-button>
            </el-popover>
          </div>
        </div>
        <div class="set-flex">
          <div class="set-item">
            <div class="set-key">
              <span>口腔科</span>
            </div>
            <el-select v-model="params.kqk" placeholder="请输入" @change="handleSelectChange">
              <el-option
                v-for="item in yslbs"
                :key="item.id"
                :label="item.nickname"
                :value="item.id">
              </el-option>
            </el-select>
            <el-popover
              placement="bottom"
              width="113"
              trigger="click">
              <img :src="params.ysqm">
              <el-button slot="reference">签名</el-button>
            </el-popover>
          </div>
        </div>
        <div class="set-flex">
          <div class="set-item">
            <div class="set-key">
              <span>形态指标</span>
            </div>
            <el-select v-model="params.xtzb" placeholder="请输入" @change="handleSelectChange">
              <el-option
                v-for="item in yslbs"
                :key="item.id"
                :label="item.nickname"
                :value="item.id">
              </el-option>
            </el-select>
            <el-popover
              placement="bottom"
              width="113"
              trigger="click">
              <img :src="params.ysqm">
              <el-button slot="reference">签名</el-button>
            </el-popover>
          </div>
        </div>
        <div class="set-flex">
          <div class="set-item">
            <div class="set-key">
              <span>血压</span>
            </div>
            <el-select v-model="params.xy" placeholder="请输入" @change="handleSelectChange">
              <el-option
                v-for="item in yslbs"
                :key="item.id"
                :label="item.nickname"
                :value="item.id">
              </el-option>
            </el-select>
            <el-popover
              placement="bottom"
              width="113"
              trigger="click">
              <img :src="params.ysqm">
              <el-button slot="reference">签名</el-button>
            </el-popover>
          </div>
        </div>
        <div class="set-flex">
          <div class="set-item">
            <div class="set-key">
              <span>听力筛查</span>
            </div>
            <el-select v-model="params.tlsc" placeholder="请输入" @change="handleSelectChange">
              <el-option
                v-for="item in yslbs"
                :key="item.id"
                :label="item.nickname"
                :value="item.id">
              </el-option>
            </el-select>
            <el-popover
              placement="bottom"
              width="113"
              trigger="click">
              <img :src="params.ysqm">
              <el-button slot="reference">签名</el-button>
            </el-popover>
          </div>
        </div>
        <div class="set-flex">
          <div class="set-item">
            <div class="set-key">
              <span>肺功能检测</span>
            </div>
            <el-select v-model="params.fgnjc" placeholder="请输入" @change="handleSelectChange">
              <el-option
                v-for="item in yslbs"
                :key="item.id"
                :label="item.nickname"
                :value="item.id">
              </el-option>
            </el-select>
            <el-popover
              placement="bottom"
              width="113"
              trigger="click">
              <img :src="params.ysqm">
              <el-button slot="reference">签名</el-button>
            </el-popover>
          </div>
        </div>
        <div class="set-flex">
          <div class="set-item">
            <div class="set-key">
              <span>血常规</span>
            </div>
            <el-select v-model="params.xcg" placeholder="请输入" @change="handleSelectChange">
              <el-option
                v-for="item in yslbs"
                :key="item.id"
                :label="item.nickname"
                :value="item.id">
              </el-option>
            </el-select>
            <el-popover
              placement="bottom"
              width="113"
              trigger="click">
              <img :src="params.ysqm">
              <el-button slot="reference">签名</el-button>
            </el-popover>
          </div>
        </div>
        <div class="set-flex">
          <div class="set-item">
            <div class="set-key">
              <span>血生化</span>
            </div>
            <el-select v-model="params.xsh" placeholder="请输入" @change="handleSelectChange">
              <el-option
                v-for="item in yslbs"
                :key="item.id"
                :label="item.nickname"
                :value="item.id">
              </el-option>
            </el-select>
            <el-popover
              placement="bottom"
              width="113"
              trigger="click">
              <img :src="params.ysqm">
              <el-button slot="reference">签名</el-button>
            </el-popover>
          </div>
        </div>
        <div class="set-flex">
          <div class="set-item">
            <div class="set-key">
              <span>尿常规</span>
            </div>
            <el-select v-model="params.ncg" placeholder="请输入" @change="handleSelectChange">
              <el-option
                v-for="item in yslbs"
                :key="item.id"
                :label="item.nickname"
                :value="item.id">
              </el-option>
            </el-select>
            <el-popover
              placement="bottom"
              width="113"
              trigger="click">
              <img :src="params.ysqm">
              <el-button slot="reference">签名</el-button>
            </el-popover>
          </div>
        </div>
        <div class="set-flex">
          <div class="set-item">
            <div class="set-key">
              <span>心理测评</span>
            </div>
            <el-select v-model="params.xlcp" placeholder="请输入" @change="handleSelectChange">
              <el-option
                v-for="item in yslbs"
                :key="item.id"
                :label="item.nickname"
                :value="item.id">
              </el-option>
            </el-select>
            <el-popover
              placement="bottom"
              width="113"
              trigger="click">
              <img :src="params.ysqm">
              <el-button slot="reference">签名</el-button>
            </el-popover>
          </div>
        </div>
      </div>
      <div class="set-bottom">
        <el-button @click="close">取消</el-button>
        <el-button class="blue" @click="close">提交</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tjjggmcs: [],
      ssxxids: [],
      params: {
        tjjggmc: '',
        tjjggbh: '',
        ssxxid: '',
        ssxxs: '',
        jddw: '',
        id: '',
        nk: '',
        wk: '',
        jzcw: '',
        yk: '',
        ebhk: '',
        kqk: '',
        xtzb: '',
        xy: '',
        tlsc: '',
        fgnjc: '',
        xcg: '',
        xsh: '',
        ncg: '',
        xlcp: '',
        ysqm: ''
      },
      yslbs: []
    }
  },
  created () {
    this.params.tjjggbh = this.$parent.params.tjjgbh
    console.log(this.params.tjjggbh + 'jgbh+++++')
    this.getyslbs()
  },
  methods: {
    handleSelectChange (selectedId, key) {
      console.log(selectedId, key)
      // 根据选中的id查找yslbs中对应的ysqm
      const selectedItem = this.yslbs.find(item => item.id === selectedId)
      console.log(selectedItem)
      if (selectedItem) {
        // this.params.ysqm = selectedItem.ysqm
        console.log('图片+++' + selectedItem.ysqm)
        // this.params[key1] = this.$data[key + 's'][selectedItem].img
        this.params[key] = selectedItem.ysqm
      }
    },
    async getyslbs () {
      try {
        const { data: res } = await this.$http.post('/zhctapi/getyslbsbyjgbh', this.params)
        console.log(res + '医生列表++')
        this.yslbs = res.data
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
      } catch (error) {
        console.log('请求失败', error)
      }
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
  .set {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .82);

    .set-box {
      overflow: hidden;
      width: 480px;
      height: 90%;
      padding: 0 17px;
      background-color: #fff;
      border-radius: 6px;
      box-sizing: border-box;

      .set-head {
        position: relative;
        width: 100%;
        height: 60px;
        line-height: 60px;
        padding-left: 12px;
        box-sizing: border-box;
        color: #17212B;
        font-size: 20px;
        font-weight: 600;

        .set-close {
          position: absolute;
          top: 50%;
          right: 18px;
          transform: translateY(-50%);
          color: #fff;
          font-size: 24px;
          cursor: pointer;
        }
      }

      .set-scroll {
        overflow-y: scroll;
        width: 100%;
        height: calc(100% - 140px);

        &::-webkit-scrollbar {
          width: 0;
        }

        .set-flex {
          display: flex;
          flex-direction: column;
          width: 100%;
          border: 1px solid #E3E3E3;
          box-sizing: border-box;

          .set-item {
            display: flex;
            align-items: center;
            width: 100%;
            margin: 10px 0;
            padding-left: 32px;
            padding-right: 8px;
            box-sizing: border-box;

            .set-key {
              width: 100px;
              color: #17212B;
              font-size: 18px;
            }
          }

          .el-button {
            margin-left: 10px;
            font-size: 16px;
          }
        }

        .set-flex:nth-child(n+2) {
          border-top: none;
        }
      }

      .set-bottom {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 80px;

        .el-button {
          min-width: 113px;
          height: 40px;
          line-height: 40px;
          margin: 0 10px;
          padding-top: 0;
          padding-bottom: 0;
          color: #fff;
          font-size: 22px;
          background-color: #999999;
        }

        .blue {
          background-color: #006EFF;
        }
      }
    }
  }
</style>
