<template>
  <div class="see">
    <div class="see-scroll">
      <div class="see-title">
        <span class="span">回访记录</span>
        <el-input placeholder="姓名/身份证号" suffix-icon="el-icon-search" class="see-search">
        </el-input>
      </div>
      <div class="see-box">
        <div class="see-line see-line1">
        <span>咨询类型：父子</span>
      </div>
      <div class="see-line see-line1">
        <span>登记日期：李明明</span>
      </div>
      <div class="see-line see-line1">
        <span>咨询渠道：15808888888</span>
      </div>
      <div class="see-line see-line1">
        <span>登记人：苏州市山塘街北浩弄60号</span>
      </div>
      <div class="see-line see-line1">
        <span>咨询人：2020-02-02</span>
      </div>
      <div class="see-line see-line1">
        <span>计划回访日期：2020-02-02</span>
      </div>
      <div class="see-line see-line1">
        <span>联系方式：出去散散心！</span>
      </div>
      <div class="see-line see-line1">
        <span>回访状态：出去散散心！</span>
      </div>
      <div class="see-line see-line1">
        <span>与老人关系：出去散散心！</span>
      </div>
      </div>

      <div class="see-title">
        <span> 回访记录</span>
      </div>

      <el-timeline>
        <el-timeline-item timestamp="回访记录：老人需要有更好的居住环境，但是目前的条件无法满足其需求！计划回访日期：2020-02-02 16：00">
          10-20  15：00
        </el-timeline-item>
        <el-timeline-item timestamp="回访记录：老人需要有更好的居住环境，但是目前的条件无法满足其需求！计划回访日期：2020-02-02 16：00">
          10-20  15：00
        </el-timeline-item>
        <el-timeline-item timestamp="回访记录：老人需要有更好的居住环境，但是目前的条件无法满足其需求！计划回访日期：2020-02-02 16：00">
          10-20  15：00
        </el-timeline-item>
      </el-timeline>
    </div>
    <div class="see-close">
      <el-button size="small" @click="close">返回</el-button>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.see {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  width: 100%;
  height: 100%;
  padding: 0 30px;
  background-color: #fff;
  box-sizing: border-box;

  .see-scroll {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 50px);

    &::-webkit-scrollbar {
      width: 0;
    }

    .see-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 60px;
      border-bottom: 1px solid #000;
      box-sizing: border-box;
      color: #000;
      font-size: 20px;

      .span {
        width: 130px;
        height: 38px;
        line-height: 38px;
        text-align: center;
        background-color: #409EFF;
        color: #fff;
        font-size: 20px;
      }

      .see-search {
        width: 246px;
      }
    }

    .see-box {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      padding-right: 50%;
      padding-bottom: 10px;
      border-bottom: 1px solid #000;
      box-sizing: border-box;
    }

    .see-line {
      display: flex;
      width: 50%;
      margin-top: 10px;

      .key {
        color: #000;
        font-size: 20px;
      }

      .border {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: space-between;
        width: 776px;
        height: 133px;
        padding: 20px;
        border: 1px solid #000;
        box-sizing: border-box;

        .item {
          width: 25%;
          color: #000;
          font-size: 16px;
        }

        .item1 {
          width: 50%;
        }
      }
    }

    .see-line1 {
      padding-left: 36px;
      box-sizing: border-box;
      color: #000;
      font-size: 16px;
    }

    .see-user {
      display: flex;
      align-items: center;
      margin-top: 20px;
      padding-left: 20px;
      box-sizing: border-box;

      .label {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #000;
        font-size: 16px;

        .top {
          width: 28px;
          height: 28px;
          margin-bottom: 10px;
        }
      }

      .icon {
        margin: 0 24px;
        color: #000;
        font-size: 16px;
      }
    }

    .el-timeline {
      margin-top: 20px;
      margin-left: 70px;

      /deep/.el-timeline-item__content {
        color: #000;
        font-size: 20px;
      }

      /deep/.el-timeline-item__timestamp {
        line-height: 30px;
        color: #000;
        font-size: 20px;
      }

      /deep/.el-timeline-item__tail {
        border-left: 1px dashed #000;
      }

      /deep/.el-timeline-item__node {
        width: 28px;
        height: 28px;
        left: -9px;
        background-image: url('../../../img/emr/user.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
    }
  }

  .see-close {
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;
  }
}
</style>
