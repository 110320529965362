<template>
  <div class="popup">
    <div class="popup-box">
      <div class="popup-title">
        <div class="popup-top"></div>
        <div class="popup-bottom">
          <div class="popup-bottom-text">
            <span>反馈处理</span>
          </div>
        </div>
      </div>

      <div class="popup-content">
        <div class="popup-content-box">
          <div class="info-close" @click="close">
            <span class="el-icon-close"></span>
          </div>
          <div class="info-item">
            <span class="key">问题类型：</span>
            <span class="value">缴费相关</span>
          </div>
          <div class="info-item">
            <span class="key">问题描述：</span>
            <span class="value">线上退款失败</span>
          </div>
          <div class="info-item">
            <span class="key">手机号码：</span>
            <span class="value">15908880760</span>
          </div>
          <div class="info-item">
            <span class="key">邮箱地址：</span>
            <span class="value">15908880760@163.com</span>
          </div>
          <div class="info-item">
            <span class="key">微信昵称：</span>
            <span class="value">七尾Sam</span>
          </div>
          <div class="info-item">
            <span class="key">提交时间：</span>
            <span class="value">020/09/08 10:06</span>
          </div>
          <div class="info-item info-item1">
            <span class="key">提交时间：</span>
            <el-radio-group v-model="radio">
              <el-radio label="1">处理中</el-radio>
              <el-radio label="2">已处理</el-radio>
            </el-radio-group>
          </div>
          <div class="info-item info-item1">
            <span class="key">备注：</span>
            <el-input type="textarea" placeholder="请输入">
            </el-input>
          </div>
          <div class="info-btns">
            <el-button size="small" @click="close">取消</el-button>
            <el-button size="small" class="blue" @click="close">确定</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {

  },
  data () {
    return {
      radio: ''
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.popup {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 888;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .82);
  box-sizing: border-box;

  .popup-box {
    display: flex;
    flex-direction: column;
    width: 1006px;
  }

  .popup-title {
    display: flex;
    flex-direction: column;

    .popup-top {
      position: relative;
      width: 87px;
      height: 6px;
      transform: skew(30deg);
      background-color: #03BCD7;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: -6px;
        width: 10px;
        height: 6px;
        background-color: #081C38;
        transform: skew(-50deg);
      }
    }

    .popup-bottom {
      overflow: hidden;
      position: relative;
      width: 148px;
      height: 30px;
      background: linear-gradient(97deg, #0177CD 0%, #024073 100%);
      transform: skew(30deg);

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: -30px;
        width: 20px;
        height: 30px;
        background-color: #081C38;
        transform: skew(-55deg);
      }

      .popup-bottom-text {
        height: 30px;
        line-height: 30px;
        padding-left: 20px;
        box-sizing: border-box;
        transform: skew(-30deg);
        color: #fff;
        font-size: 20px;
        font-weight: bold;
      }
    }
  }

  .popup-content {
    width: 100%;
    height: 433px;
    margin-top: 11px;
    padding: 10px;
    background: linear-gradient(93deg, rgba(10, 71, 123, .27) 0%, rgba(17, 94, 156, .27) 100%);
    border: 4px solid rgba(1, 124, 219, .27);
    box-sizing: border-box;

    .popup-content-box {
      overflow: hidden;
      position: relative;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      height: 100%;
      padding: 30px 20px;
      border: 4px solid rgba(1, 124, 219, .27);
      box-sizing: border-box;

      .info-close {
        position: absolute;
        top: -15px;
        right: -40px;
        width: 100px;
        height: 45px;
        line-height: 60px;
        text-align: center;
        transform: rotate(30deg);
        background: linear-gradient(117deg, #1E58A6 0%, #1C4EA1 53%, #2866B1 100%);
        box-shadow: inset 0px -13px 0px 1px #2661AB;
        color: #fff;
        font-size: 24px;
        box-sizing: border-box;

        span {
          transform: rotate(-30deg);
        }
      }

      .info-item {
        display: flex;
        flex-direction: row;
        width: 33%;
        color: #04B4D0;
        font-size: 18px;

        .key {
          width: 100px;
        }

        .value {
          width: calc(100% - 110px);
          word-wrap: break-word;
        }

        .el-radio {
          color: #fff;
        }

        /deep/.el-radio__label {
          font-size: 18px;
        }

        /deep/.el-radio__inner {
          background-color: transparent;
        }

        /deep/.el-radio__input.is-checked+.el-radio__label {
          color: #01EEFD;
        }

        /deep/.el-radio__input.is-checked .el-radio__inner {
          background-color: #01EEFD;
          border-color: #01EEFD;
        }
      }

      .info-item1 {
        width: 100%;

        .key {
          color: #01EEFD;
        }

        /deep/.el-textarea__inner {
          width: 530px;
          height: 100px;
          resize: none;
        }
      }

      .info-btns {
        position: absolute;
        left: 0;
        bottom: 10px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;

        .el-button {
          margin: 0 50px;
          background-color: #33465C;
          border: none;
          color: #fff;
        }

        .blue {
          background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
        }
      }
    }
  }
}
</style>
