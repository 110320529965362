<template>
  <div class="run">
    <div class="tab">
      <span class="title">出院登记</span>
    </div>
    <div class="run-search">
      <div class="run-item">
        <div class="key">
          <span>老人姓名</span>
        </div>
        <el-input placeholder="请输入" v-model="params.name"></el-input>
      </div>
      <div class="run-item">
        <div class="key">
          <span>身份证号</span>
        </div>
        <el-input placeholder="请输入" v-model="params.sfzh"></el-input>
      </div>
      <div class="run-item">
        <div class="key">
          <span>性别</span>
        </div>
        <el-select v-model="valueOne" placeholder="请选择">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="run-item">
        <div class="key">
          <span>状态</span>
        </div>
        <el-select v-model="valueTwo" placeholder="请选择">
          <el-option v-for="item in optionsTwo" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <el-button type="primary" @click="getListByParams">搜索</el-button>
      <el-button type="primary" @click="resetForm">重置</el-button>
    </div>

    <div class="son-add">
      <div class="son-add-icon" @click="openAdd">
        <span class="el-icon-plus"></span>
      </div>
    </div>

    <div class="son-table">
      <el-table :data="tableData" border height="100%" ref="table">
      <el-table-column label="序号" type="index" width="100">
      </el-table-column>
      <el-table-column prop="rycwh" label="床位">
      </el-table-column>
      <el-table-column prop="ryfjh" label="房间">
      </el-table-column>
      <el-table-column prop="name" label="老人姓名">
      </el-table-column>
      <el-table-column prop="xb" label="性别">
        <template slot-scope="scope">
        <span>{{ scope.row.xb | tpye }}</span>
      </template>
      </el-table-column>
      <el-table-column prop="sfzh" label="身份证号">
      </el-table-column>
      <el-table-column prop="yjcysj" label="预计出院时间">
      </el-table-column>
      <el-table-column prop="cyyy" label="出院原因">
      </el-table-column>
      <el-table-column prop="cyyycyzt" label="状态">
        <template slot-scope="scope">
        <span>{{ scope.row.cyyycyzt | type }}</span>
      </template>
      </el-table-column>
      <el-table-column label="操作" width="260">
        <template slot-scope="scope">
          <!-- <div class="flex">
            <span @click="openUpdate(scope.row)">编辑</span>
            <span @click="openDelete">删除</span>
            <span @click="openCancel">确认出院</span>
          </div> -->
          <!-- <div class="run" v-if="scope.row.address === '待出院'">
            <span @click="openAdd">编辑</span>
            <span @click="openDelete">删除</span>
            <span @click="openCancel">确认出院</span>
          </div>
          <div class="run" v-else>
            <span @click="openAdd">查看详情</span>
          </div> -->
          <el-button type="primary" size="mini" @click="openUpdate(scope.row)">编辑</el-button>
          <el-button type="danger" size="mini" @click="openDelete(scope.row)">删除</el-button>
          <el-button type="primary" size="mini" @click="openCancel(scope.row)">确认出院</el-button>
        </template>
      </el-table-column>
    </el-table>
    </div>

    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="query1.pageNum" background :page-size="query1.pageSize" layout="total, prev, pager, next, jumper" :total="query1.total" hide-on-single-page></el-pagination>
    <Cancel v-if="showCancel" @close="closeCancel"></Cancel>
    <Delete v-if="showDelete" @close="closeDelete"></Delete>
    <Add v-if="showAdd" @close="closeAdd"></Add>
    <Update v-if="showUpdate" :selectInfo="selectInfo" @close="closeUpdate"></Update>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Add from './add.vue'
import Delete from './delete.vue'
import Cancel from './cancel.vue'
import Update from './update.vue'
export default {
  components: {
    Add,
    Delete,
    Cancel,
    Update
  },
  data () {
    return {
      tableData: [
        // {
        //   id: 0,
        //   czbh: 'string',
        //   lnrzsbh: 'string',
        //   name: 'string',
        //   age: 'string',
        //   ssjgbh: 'string',
        //   ssjgmc: 'string',
        //   rydjid: 'string',
        //   sfzh: 'string',
        //   cyyycyzt: 'string',
        //   cyczry: 'string',
        //   cyczryid: 'string',
        //   cyjlid: 'string',
        //   hzzt: 'string'
        // }
      ],
      options: [{
        value: '1',
        label: '男'
      },
      {
        value: '2',
        label: '女'
      }
      ],
      optionsTwo: [{
        value: '0',
        label: '已出院'
      }
      ],
      valueOne: '',
      valueTwo: '',
      currentPage4: 4,
      tabIndex: 0,
      selectIndex: 0,
      showAdd: false,
      showDelete: false,
      showSee: false,
      showRun: false,
      showSelect: false,
      showCancel: false,
      showUpdate: false,
      selectInfo: {},
      // 分页数据
      query1: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      id: '',
      params: {
        name: '',
        sfzh: '',
        xb: '',
        cyyycyzt: ''
      }
    }
  },
  computed: {
    ...mapState(['loginInfo'])
  },
  created () {
    this.getList()
  },
  filters: {
    tpye (valueOne) {
      if (valueOne === '1') return '男'
      if (valueOne === '2') return '女'
      return ''
    },
    type (valueTwo) {
      if (valueTwo === '0') return '已出院'
      if (valueTwo === '1') return '未出院'
      return ''
    }
  },
  methods: {
    // 首次进入刷新
    async getList () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-92344223
      try {
        const start = this.query1.pageNum === 1 ? '0' : (this.query1.pageNum - 1) * 10 + ''
        const obj = {
          start: start,
          length: this.query1.pageSize + '',
          ssjgbh: this.loginInfo.jgbh,
          jgbh: this.loginInfo.jgbh,
          sxzt: '3'
        }
        const { data: res } = await this.$http.get('/ykdcydjs', { params: obj })
        console.log('出院登记列表', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.tableData = res.data
        this.query1.total = res.recordsTotal
        this.$nextTick(() => {
          this.$refs.table.bodyWrapper.scrollTop = 0
        })
      } catch (error) {
        console.log('出院登记列表请求失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    // 条件查询
    async getListByParams () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-92344223
      try {
        const start = this.query1.pageNum === 1 ? '0' : (this.query1.pageNum - 1) * 10 + ''
        const obj = {
          start: start,
          length: this.query1.pageSize + '',
          ssjgbh: this.loginInfo.jgbh,
          jgbh: this.loginInfo.jgbh,
          sxzt: '0',
          name: this.params.name,
          sfzh: this.params.sfzh,
          xb: this.valueOne,
          cyyycyzt: this.valueTwo
        }
        const { data: res } = await this.$http.get('/ykdcydjs', { params: obj })
        console.log('出院登记列表', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.tableData = res.data
        this.query1.total = res.recordsTotal
        this.$nextTick(() => {
          this.$refs.table.bodyWrapper.scrollTop = 0
        })
      } catch (error) {
        console.log('出院登记列表请求失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    resetForm () {
      this.params = {
        name: '',
        sfzh: ''
      }
      this.valueOne = ''
      this.valueTwo = ''
    },
    tabClick (index) {
      this.tabIndex = index
    },
    openAdd () {
      this.showAdd = true
    },
    closeAdd (e) {
      if (e === 'loading') {
        this.getList()
      }
      this.showAdd = false
    },
    openDelete (info) {
      console.log(info)
      this.selectInfo = info
      this.showDelete = true
    },
    closeDelete (findD) {
      console.log(findD)
      if (findD === '+') {
        this.getList()
      }
      this.showDelete = false
    },
    openSee () {
      this.showSee = true
    },
    closeSee () {
      this.showSee = false
    },
    openRun () {
      this.showRun = true
    },
    closeRun () {
      this.showRun = false
    },
    openSelect () {
      this.showSelect = true
    },
    closeSelect () {
      this.showSelect = false
    },
    openCancel (info) {
      this.selectInfo = info
      this.showCancel = true
    },
    closeCancel (find) {
      console.log(find)
      if (find === 'loading') {
        this.getList()
      }
      this.showCancel = false
    },
    openUpdate (info) {
      this.selectInfo = info
      this.showUpdate = true
    },
    closeUpdate () {
      this.showUpdate = false
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
      this.query1.pageNum = val
      this.getList()
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.run {
  width: 100%;
  height: 100%;
  padding: 0 10px;
  background-color: #fff;
  box-sizing: border-box;

  .tab {
    display: flex;
    align-items: center;
    width: 100%;
    height: 60px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    span {
      height: 38px;
      line-height: 38px;
      margin-right: 16px;
      padding: 0 12px;
      text-align: center;
      color: #fff;
      font-size: 22px;
      background-color: #D5D5D5;
      cursor: pointer;
    }

    .title {
      background-color: #409EFF;
    }
  }

  .son-tab {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    .son-item {
      height: 38px;
      line-height: 38px;
      padding: 0 20px;
      text-align: center;
      background-color: #284867;
      color: #fff;
      font-size: 16px;
      cursor: pointer;
    }

    .son-active {
      background-color: #04B4D0;
    }

    .son-item:first-child {
      margin-right: 40px;
    }
  }

  .son-tab1 {
    border: none;
  }

  .son-add {
    display: flex;
    align-items: center;
    height: 50px;

    .son-add-icon {
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      background-color: #409EFF;
      border-radius: 50%;
      color: #fff;
      font-size: 16px;
      cursor: pointer;
    }

    .son-set {
      width: 120px;
      height: 30px;
      line-height: 30px;
      margin-left: 30px;
      text-align: center;
      background-color: #04B4D0;
      color: #fff;
      font-size: 16px;
      cursor: pointer;
    }
  }

  .run-search {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    .run-item {
      display: flex;
      align-items: center;
      margin-right: 40px;

      .key {
        margin-right: 10px;
        white-space: nowrap;
        color: #000;
        font-size: 22px;
      }
    }
  }

  .son-table {
    width: 100%;
    height: calc(100% - 280px);

    /deep/.el-table .cell {
      text-align: center;
      color: #000;
    }

    .flex {
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      span {
        cursor: pointer;
      }
    }
  }

  .el-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
}
</style>
