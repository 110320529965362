<template>
  <div class="children-page">
    <div class="capsule">
      <div class="capsule-item">
        <div class="title">
          <div class="top"></div>
          <div class="bottom">
            <div class="text">
              <span>服务记录</span>
            </div>
          </div>
          <div class="line"></div>
          <div class="line"></div>
        </div>
        <div class="table">
          <div class="table-title">
            <div class="left"></div>
            <div class="center">
              <span>疫苗服务</span>
            </div>
            <div class="right"></div>
          </div>
          <div class="table-box">
            <div class="position">
              <div class="button">
                <span>更多</span>
              </div>
            </div>
            <div class="tr">
              <span class="td flex1">医生姓名</span>
              <span class="td flex3">服务时间</span>
              <span class="td">接种地点</span>
              <span class="td flex1">操作</span>
            </div>
            <div class="scroll">
              <!-- <div class="tr" v-for="(item, index) in 14" :key="index">
                <span class="td flex1">李明明</span>
                <span class="td flex3">2021-12-25 12:00:00</span>
                <span class="td">古城营镇卫生院</span>
                <span class="td flex flex1">
                  <span class="button">查看</span>
                  <span class="button">删除</span>
                </span>
              </div> -->
            </div>

          </div>
        </div>
      </div>
      <div class="capsule-item">
        <div class="table">
          <div class="table-title">
            <div class="left"></div>
            <div class="center">
              <span>疫苗服务</span>
            </div>
            <div class="right"></div>
          </div>
          <div class="table-box">
            <div class="position">
              <div class="button">
                <span>更多</span>
              </div>
            </div>
            <div class="tr">
              <span class="td flex1">医生姓名</span>
              <span class="td flex3">服务时间</span>
              <span class="td">接种地点</span>
              <span class="td flex1">操作</span>
            </div>
            <div class="scroll">
              <!-- <div class="tr" v-for="(item, index) in 14" :key="index">
                <span class="td flex1">李明明</span>
                <span class="td flex3">2021-12-25 12:00:00</span>
                <span class="td">古城营镇卫生院</span>
                <span class="td flex flex1">
                  <span class="button">查看</span>
                  <span class="button">删除</span>
                </span>
              </div> -->
            </div>

          </div>
        </div>
      </div>
      <div class="capsule-item">
        <div class="table">
          <div class="table-title">
            <div class="left"></div>
            <div class="center">
              <span>诊疗服务</span>
            </div>
            <div class="right"></div>
          </div>
          <div class="table-box">
            <div class="position">
              <div class="button">
                <span>更多</span>
              </div>
            </div>
            <div class="tr">
              <span class="td flex1">医生姓名</span>
              <span class="td flex3">服务时间</span>
              <span class="td">接种地点</span>
              <span class="td">服务方式</span>
            </div>
            <div class="scroll">
              <!-- <div class="tr" v-for="(item, index) in 14" :key="index">
                <span class="td flex1">李明明</span>
                <span class="td flex3">2021-12-25 12:00:00</span>
                <span class="td">古城营镇卫生院</span>
                <span class="td flex">
                  <span class="button">问诊</span>
                  <span class="button">处方</span>
                  <span class="button">视频</span>
                </span>
              </div> -->
            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="capsule">
      <div class="capsule-item capsule-item1">
        <div class="title">
          <div class="top"></div>
          <div class="bottom">
            <div class="text">
              <span>就诊记录</span>
            </div>
          </div>
          <div class="line"></div>
          <div class="line"></div>
        </div>
        <div class="table">
          <div class="table-title">
            <div class="left"></div>
            <div class="center">
              <span>在线问诊</span>
            </div>
            <div class="right"></div>
          </div>
          <div class="table-box">
            <div class="position">
              <div class="button">
                <span>更多</span>
              </div>
            </div>
            <div class="tr">
              <span class="td flex1">患者姓名</span>
              <span class="td flex1">性别</span>
              <span class="td flex1">年龄</span>
              <span class="td flex3">问诊时间</span>
              <span class="td">问诊地点</span>
              <span class="td flex1">操作</span>
            </div>
            <div class="scroll">
              <!-- <div class="tr" v-for="(item, index) in 14" :key="index">
                <span class="td flex1">李明明</span>
                <span class="td flex1">男</span>
                <span class="td flex1">66</span>
                <span class="td flex3">2021-12-25 12:00:00</span>
                <span class="td">古城营镇卫生院</span>
                <span class="td flex1">
                  <span class="button">删除</span>
                </span>
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="capsule-item capsule-item1">
        <div class="table">
          <div class="table-title">
            <div class="left"></div>
            <div class="center">
              <span>在线问诊</span>
            </div>
            <div class="right"></div>
          </div>
          <div class="table-box">
            <div class="position">
              <div class="button">
                <span>更多</span>
              </div>
            </div>
            <div class="tr">
              <span class="td flex1">患者姓名</span>
              <span class="td flex1">性别</span>
              <span class="td flex1">年龄</span>
              <span class="td flex3">复诊时间</span>
              <span class="td">购药地点</span>
              <span class="td">药品名称</span>
              <span class="td flex1">操作</span>
            </div>
            <div class="scroll">
              <!-- <div class="tr" v-for="(item, index) in 14" :key="index">
                <span class="td flex1">李明明</span>
                <span class="td flex1">男</span>
                <span class="td flex1">66</span>
                <span class="td flex3">2021-12-25 12:00:00</span>
                <span class="td">古城营镇卫生院</span>
                <span class="td">阿莫西林、阿莫西林</span>
                <span class="td flex1">
                  <span class="button">删除</span>
                </span>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="capsule capsule1">
      <div class="title">
        <div class="top"></div>
        <div class="bottom">
          <div class="text">
            <span>就诊记录</span>
          </div>
        </div>
        <div class="line"></div>
        <div class="line"></div>
      </div>
      <div class="tab">
        <div class="tab-item tab-active">
          <span>卫生院</span>
        </div>
        <div class="tab-item">
          <span>医生</span>
        </div>
      </div>
      <div class="chart" id="charts"></div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      chartDom: null,
      isCollapse: false,
      activePath: '/runkanban'
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.canvasCharts()
    })
  },
  methods: {
    saveNavState (path) {
      window.sessionStorage.setItem('activePath', path)
      this.activePath = path
    },
    addColor (color1, color2) {
      return new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
        offset: 0,
        color: color1
      }, {
        offset: 1,
        color: color2
      }])
    },

    canvasCharts () {
      this.chartDom = document.getElementById('charts')
      var myChart = this.$echarts.init(this.chartDom)
      var option
      var colors = []
      colors.push(this.addColor('#F7903C', '#423635'))
      colors.push(this.addColor('#02DFF1', '#074261'))
      colors.push(this.addColor('#3CF787', '#074261'))
      // const axisLabel = {
      //   show: true,
      //   textStyle: {
      //     color: '#fff',
      //     fontSize: 13
      //   }
      // }
      const series = []
      const seriesTitles = ['小孩', '妇女', '老年人']
      seriesTitles.forEach(item => {
        series.push(
          {
            type: 'bar',
            name: item,
            itemStyle: {
              normal: {
                barBorderRadius: [4, 4, 0, 0],
                label: {
                  show: true,
                  position: 'top',
                  textStyle: {
                    color: '#ffffff',
                    fontSize: 10
                  }
                }
              }
            },
            barWidth: 18
          }
        )
      })
      // option = {
      //   color: colors,
      //   legend: {
      //     show: true,
      //     data: seriesTitles,
      //     x: 'right',
      //     textStyle: {
      //       color: '#ffffff'
      //     }
      //   },
      //   tooltip: {
      //     trigger: 'axis',
      //     axisPointer: {
      //       type: 'shadow'
      //     }
      //   },
      //   dataset: {
      //     source: [
      //       ['白家庄村卫生室', 43.3, 85.8, 93.7, 58],
      //       ['东阳台村卫生室', 83.1, 73.4, 55.1, 42],
      //       ['西安庄四村卫生室', 86.4, 65.2, 82.5, 33],
      //       ['东安庄一村卫生室', 72.4, 53.9, 39.1, 22],
      //       ['大魏村卫生室', 72.4, 53.9, 39.1, 22],
      //       ['西安庄一村卫生室', 72.4, 53.9, 39.1, 22],
      //       ['小魏村卫生室', 72.4, 53.9, 39.1, 22]
      //     ]
      //   },
      //   xAxis: {
      //     type: 'category',
      //     axisLabel,
      //     axisLine: {
      //       show: true,
      //       lineStyle: {
      //         color: '#447DC3'
      //       }
      //     }
      //   },
      //   yAxis: {
      //     type: 'value',
      //     name: '服务人数',
      //     nameTextStyle: {
      //       color: '#ffffff',
      //       nameLocation: 'start'
      //     },
      //     axisLabel,

      //     axisLine: {
      //       show: true,
      //       lineStyle: {
      //         color: '#447DC3'
      //       }
      //     },
      //     splitLine: {
      //       show: true,
      //       lineStyle: {
      //         color: '#447DC3'
      //       }
      //     }
      //   },
      //   dataZoom: [
      //     {
      //       xAxisIndex: 0, // 这里是从X轴的0刻度开始
      //       disabled: false,
      //       zoomLock: true,
      //       preventDefaultMouseMove: false,
      //       filterMode: 'empty',
      //       type: 'inside',
      //       startValue: 0, // 从头开始。
      //       endValue: 3 // 一次性展示6个。
      //     }

      //   ],
      //   series
      // }
      option = {
        color: colors,
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          x: 'right',
          y: 'top',
          textStyle: {
            fontSize: 12,
            color: '#FFFFFF'
          }
          // padding: [25, 34, 0, 0]
        },
        grid: {
          show: false,
          top: '32%', // 一下数值可为百分比也可为具体像素值
          right: '10%',
          bottom: '20%',
          left: '14%'
        },
        dataZoom: [
          {
            xAxisIndex: 0, // 这里是从X轴的0刻度开始
            disabled: false,
            zoomLock: true,
            preventDefaultMouseMove: false,
            filterMode: 'empty',
            type: 'inside',
            startValue: 0, // 从头开始。
            endValue: 3 // 一次性展示6个。
          }

        ],
        xAxis: {
          type: 'category',
          data: ['白家庄村卫生室', '东阳台村卫生室', '西安庄四村卫生室', '东安庄一村卫生室', '大魏村卫生室', '西安庄一村卫生室', '小魏村卫生室'],
          axisLabel: {
            show: true,
            color: '#fff',
            fontSize: 13,
            interval: 0
            // rotate: -20
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: ['#fff']
            },
            textStyle: {
              color: '#04B4D0',
              fontSize: 13
            }
          },
          axisTick: {
            // y轴刻度线
            show: false
          }
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: true,
            lineStyle: {
              color: ['#fff']
            }
          },
          axisLabel: {
            show: true,
            color: '#fff',
            fontSize: 14
          },
          splitLine: {
            lineStyle: {
              color: '#fff'
            }
          }
        },
        series: [
          {
            name: '小孩',
            data: [120, 200, 150, 80, 70, 110, 90],
            type: 'bar',
            barWidth: '20',
            itemStyle: {
              borderRadius: [4, 4, 0, 0]
            }
          },
          {
            name: '妇女',
            data: [120, 200, 150, 80, 70, 110, 90],
            type: 'bar',
            barWidth: '20',
            itemStyle: {
              borderRadius: [4, 4, 0, 0]
            }
          },
          {
            name: '老年人',
            data: [120, 200, 150, 80, 70, 110, 90],
            type: 'bar',
            barWidth: '20',
            itemStyle: {
              borderRadius: [4, 4, 0, 0]
            }
          }
        ]
      }
      myChart.setOption(option)
    }
  }
}
</script>

<style lang="less" scoped>
.children-page {
  width: 100%;
  height: 100%;

  .capsule {
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    height: 386px;
    margin-bottom: 20px;
    padding: 20px;
    box-sizing: border-box;
    border: 2px solid #016DBD;
    border-radius: 50px;
    background: #0A2F56;
    box-shadow: inset 0px 0px 13px 1px #0174C8;

    .capsule-item {
      display: flex;
      flex-direction: column;
      width: 556px;
    }

    .capsule-item1 {
      width: calc(50% - 20px);
    }

    .title {
      position: relative;
      width: 159px;
      height: 35px;

      .top {
        width: 72px;
        margin-left: 4px;
        border-left: 3px solid transparent;
        border-right: 3px solid transparent;
        border-bottom: 6px solid #03BCD7;
      }

      .bottom {
        position: relative;
        width: 121px;
        height: 30px;
        line-height: 30px;
        background: linear-gradient(97deg, #0177CD 0%, #024073 100%);
        transform: skewX(20deg);
        color: #fff;
        font-size: 16px;
        font-weight: bold;

        &::before {
          position: absolute;
          top: -7px;
          left: -8px;
          content: '';
          width: 0;
          height: 0;
          border-color: transparent transparent #0A2F56 transparent;
          border-width: 6px;
          border-style: solid;
          transform: rotate(-35deg);
        }

        .text {
          width: 100%;
          text-align: center;
          transform: skewX(-20deg);
        }
      }

      .line {
        position: absolute;
        bottom: -2px;
        right: 10px;
        width: 2px;
        height: 35px;
        background-color: #032142;
        transform: rotate(-30deg);
      }

      .line:last-child {
        right: 0;
      }
    }

    .tab {
      position: absolute;
      top: 30px;
      left: 200px;
      display: flex;
      align-items: center;

      .tab-item {
        margin-right: 20px;
        padding: 2px 19px;
        background-color: #7C7C7C;
        color: #fff;
        font-size: 18px;
        cursor: pointer;
      }

      .tab-active {
        background-color: #04B4D0;
      }
    }

    .table {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: 10px;

      .table-title {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left {
          position: relative;
          width: 40px;
          height: 32px;
          margin-left: 3px;
          background: linear-gradient(180deg, #094273 0%, #125F9E 100%);
          border: 1px solid #017CDB;
          border-bottom-color: transparent;
          transform: skewX(-10deg);
          box-sizing: border-box;
        }

        .center {
          width: 263px;
          height: 32px;
          line-height: 32px;
          text-align: center;
          background: linear-gradient(180deg, #094273 0%, #125F9E 100%);
          border: 1px solid #017CDB;
          border-bottom-color: transparent;
          box-sizing: border-box;
          color: #fff;
          font-size: 16px;
        }

        .right {
          position: relative;
          width: 40px;
          height: 32px;
          margin-right: 3px;
          background: linear-gradient(180deg, #094273 0%, #125F9E 100%);
          border: 1px solid #017CDB;
          border-bottom-color: transparent;
          transform: skewX(10deg);
          box-sizing: border-box;
        }
      }

      .table-box {
        position: relative;
        width: 100%;
        height: 270px;
        margin-top: -1px;
        padding-top: 32px;
        border: 1px solid #017CDB;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        box-sizing: border-box;

        .position {
          position: absolute;
          top: 2px;
          right: 10px;
          width: 77px;
          height: 29px;
          line-height: 29px;
          text-align: center;
          background-color: #03BCD7;
          transform: skewX(-20deg);
          color: #fff;
          font-size: 20px;

          &::after {
            position: absolute;
            top: 0;
            right: -5px;
            z-index: 88;
            content: '';
            width: 10px;
            height: 29px;
            background-color: #03BCD7;

            transform: skewX(20deg);
          }

          .button {
            transform: skewX(20deg);
          }
        }

        .scroll {
          overflow-y: scroll;
          width: 100%;
          height: calc(100% - 40px);

          &::-webkit-scrollbar {
            width: 0;
          }
        }

        .tr {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 10px;
          padding: 0 10px;
          box-sizing: border-box;
          color: #01EEFD;
          font-size: 12px;

          .td {
            flex: 2;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .flex {
            display: flex;
            justify-content: space-between;
          }

          .flex1 {
            flex: 1;
          }

          .flex3 {
            flex: 3;
          }

          .button {
            cursor: pointer;
          }
        }
      }
    }

    .chart {
      width: 100%;
      height: calc(100% - 40px);
    }
  }

  .capsule1 {
    align-items: flex-start;
    flex-direction: column;
  }
}
</style>
