<template>
  <div class="page-box">
    <div class="tab">
      <span class="item active">任务看板</span>
    </div>
    <el-input placeholder="姓名/身份证号" suffix-icon="el-icon-search" class="input"></el-input>
    <div class="tab-box">
      <div class="son">
        <div class="son-search">
          <div class="son-item">
            <div class="key">
              <span>负责人</span>
            </div>
            <el-input placeholder="请输入执行人姓名"></el-input>
          </div>
          <div class="son-item">
            <div class="key">
              <span>任务状态</span>
            </div>
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <el-button>搜索</el-button>
          <el-button>重置</el-button>
        </div>
        <div class="son-label">
          <div class="label-item" :class="{ 'label-active': labelIndex === 0 }" @click="labelClick(0)">
            <span>全部(100)</span>
          </div>
          <div class="label-item" :class="{ 'label-active': labelIndex === 1 }" @click="labelClick(1)">
            <span>进行中(100)</span>
          </div>
          <div class="label-item" :class="{ 'label-active': labelIndex === 2 }" @click="labelClick(2)">
            <span>已完成(100)</span>
          </div>
          <div class="label-item" :class="{ 'label-active': labelIndex === 3 }" @click="labelClick(3)">
            <span>预期未完成(100)</span>
          </div>
        </div>
        <div class="son-card">
          <div class="card-item" v-for="(item, index) in 20" :key="index" @click="openTaskDetails">
            <div class="card-line">
              <div class="card-text">
                <span>点餐服务</span>
              </div>
              <img src="../../img/emr/user.png" class="card-img">
            </div>
            <div class="card-line">
              <span>进行中</span>
            </div>
            <div class="card-line">
              <span>截止日期:2022-08-03 18:00:00</span>
            </div>
          </div>
        </div>
        <TaskDetails v-if="showTaskDetails" @close="closeTaskDetails"></TaskDetails>
      </div>
    </div>
  </div>
</template>

<script>
import TaskDetails from './taskDetails.vue'
export default {
  components: {
    TaskDetails
  },
  data () {
    return {
      tabIndex: 0,
      tabs: Object.freeze(['任务看板', '交接记录']),
      options: [{
        value: '',
        label: ''
      }],
      value: '',
      labelIndex: 0,
      showTaskDetails: false
    }
  },
  methods: {
    tabClick (index) {
      const routers = ['taskkanban', 'handoverrecord']
      this.tabIndex = index
      this.$router.push('/smartelderlycare/retirement/' + routers[index])
      console.log(routers[index])
    },
    labelClick (index) {
      this.labelIndex = index
    },
    openTaskDetails () {
      this.showTaskDetails = true
    },
    closeTaskDetails () {
      this.showTaskDetails = false
    }
  }
}
</script>

<style lang="less" scoped>
.page-box {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  background-color: #081C38;

  .tab {
    display: flex;
    align-items: center;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;

    .item {
      width: 130px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      background-color: #284867;
      color: #fff;
      font-size: 22px;
      cursor: pointer;

      &:first-child {
        margin-right: 30px;
      }
    }

    .active {
      background-color: #04B4D0;
    }
  }

  .input {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 246px;

    /deep/.el-input__inner {
      border: none;
      background: linear-gradient(153deg, #0A477B 0%, #0E67AD 100%);
      color: #fff;
      font-size: 18px;
    }

    /deep/.el-input__inner::-webkit-input-placeholder {
      color: #fff;
    }

    /deep/.el-input__suffix {
      color: #fff;
      font-size: 18px;
    }
  }

  .tab-box {
    width: 100%;
    height: calc(100% - 78px);

    .son {
      width: 100%;
      height: 100%;

      .son-search {
        display: flex;
        align-items: center;
        width: 100%;
        height: 78px;
        border-bottom: 1px solid #01EEFD;
        box-sizing: border-box;

        .son-item {
          display: flex;
          align-items: center;
          margin-right: 30px;

          .key {
            margin-right: 10px;
            white-space: nowrap;
            color: #01EEFD;
            font-size: 22px;
          }
        }

        .el-button {
          height: 38px;
          line-height: 38rpx;
          margin-left: 40px;
          border: none;
          background-color: #04B4D0;
          color: #fff;
        }
      }

      .son-label {
        display: flex;
        align-items: center;
        width: 100%;
        height: 79px;

        .label-item {
          height: 30px;
          line-height: 30px;
          margin-right: 30px;
          padding: 0 20px;
          text-align: center;
          background-color: #284867;
          border: 1px solid rgba(0, 66, 255, .28);
          color: #fff;
          font-size: 18px;
          box-sizing: border-box;
          cursor: pointer;
        }

        .label-active {
          background-color: #18CDFD;
        }
      }

      .son-card {
        display: flex;
        flex-wrap: wrap;
        // align-content: space-between;
        justify-content: space-between;
        width: 100%;
        height: calc(100% - 160px);

        .card-item {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 18%;
          height: 130px;
          padding: 10px 29px;
          background-color: #04B4D0;
          border-radius: 6px;
          box-sizing: border-box;
          cursor: pointer;

          .card-line {
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #fff;
            font-size: 16px;

            .card-text {
              color: #fff;
              font-size: 18px;
            }

            .card-img {
              width: 48px;
              height: 48px;
            }
          }
        }
      }
    }
  }

}
</style>
