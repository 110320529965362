<template>
  <div class="write">
    <div class="title">
      <span>新增</span>
    </div>
    <div class="content">
      <div @click="closePopup" class="close">
        <span class="el-icon-close"></span>
      </div>
      <div class="box">
        <div class="item">
          <span class="span">设备类型</span>
          <el-select v-model="params.sblx" placeholder="请选择">
            <el-option v-for="item in types" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <span class="span">设备品牌</span>
          <el-input v-model="params.pp"></el-input>
        </div>
        <div class="item">
          <span class="span">设备SN号</span>
          <el-input v-model="params.sbsn"></el-input>
        </div>
        <div class="item">
          <span class="span">设备型号</span>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <span class="span">所属机构</span>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <span class="span">所属科室</span>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <span class="span">设备状态</span>
          <el-select v-model="params.status" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item item1">
          <span class="span">接口管理</span>
          <el-input class="width"></el-input>
        </div>
        <div class="item">
          <el-switch v-model="radio" active-color="#01EEFD" inactive-color="#D5D5D5" active-text="启动" inactive-text="关闭">
          </el-switch>
        </div>
        <div class="item">
          <span class="span">登记时间</span>
          <el-date-picker v-model="params.djsj" type="date" valueFormat="yyyy-MM-dd" placeholder="">
          </el-date-picker>
        </div>
      </div>
      <div class="btns">
        <el-button @click="closePopup">取消</el-button>
        <el-button class="button" @click="submit">确认</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data () {
    return {
      options: [{
        value: '选项1',
        label: '黄金糕'
      }],
      types: [
        { label: '血压', value: '01' },
        { label: '血糖', value: '02' },
        { label: '定位', value: '03' },
        { label: '安防', value: '04' },
        { label: '健康监测', value: '05' },
        { label: '辅助监测', value: '06' }
      ],
      value: '',
      radio: true,
      params: {
        id: 0,
        bh: '',
        sblx: '',
        pp: '',
        lx: '',
        ks: '',
        djsj: '',
        djr: '',
        status: '',
        bdsb: '',
        ssjgbh: '',
        ssjgmc: '',
        createtime: '',
        updatetime: '',
        sbglid: '',
        tjjgmc: '',
        tjjgbm: '',
        tjjgcmhid: '',
        tjjgcmhmc: ''
      }
    }
  },
  computed: {
    ...mapState(['loginInfo'])
  },
  methods: {
    async submit () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-81591379
      // try {
      //   this.params.ssjgbh = this.loginInfo.jgbh
      //   this.params.ssjgmc = this.loginInfo.jgmc
      //   console.log('请求参数', this.params)
      //   const { data: res } = await this.$http.post('/zhyyapi/ykdsbgladd', this.params)
      //   console.log('设备管理保存', res)
      //   if (res.code !== 0) return this.$message.error(res.msg || res.message)
      //   this.$message.success('添加成功')
      //   this.$emit('close', 'loading')
      // } catch (error) {
      //   console.log('设备管理保存失败', error)
      //   this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      // }
    },
    closePopup () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.write {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 888;
  width: 100%;
  height: 100%;
  padding: 0 300px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, .9);

  .title {
    width: 267px;
    height: 53px;
    padding-top: 5px;
    box-sizing: border-box;
    line-height: 53px;
    text-align: center;
    background-image: url('../../img/ssjk/title.png');
    background-size: 100% 100%;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
  }

  .content {
    position: relative;
    width: 100%;
    min-width: 10rem;
    height: 600px;
    margin-top: 10px;
    padding: 50px 65px;
    background-image: url('../../img/ssjk/background4.png');
    background-size: 100% 100%;
    box-sizing: border-box;

    .close {
      position: absolute;
      top: 22px;
      right: 24px;
      color: #fff;
      font-size: 24px;
      cursor: pointer;
    }

    .content-title {
      margin-top: 20px;
      color: #01EEFD;
      font-size: 18px;
    }

    .box {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
    }

    .item {
      display: flex;
      align-items: center;
      width: 33%;
      margin-top: 20px;
      color: #01EEFD;
      font-size: 16px;

      .span {
        margin-right: 10px;
        white-space: nowrap;
      }

      .span1 {
        width: 108px;
      }

      .el-input,
      .el-select {
        width: 150px;
        height: 38px;
      }

      .width {
        width: calc(100% - 140px);
      }

      ::v-deep .el-switch__core {
        width: 68px !important;
        height: 24px;
        border-radius: 100px;
        border: none;
      }

      ::v-deep .el-switch__core::after {
        width: 20px;
        height: 20px;
        top: 2px;
      }

      ::v-deep .el-switch.is-checked .el-switch__core::after {
        margin-left: -21px;
      }

      /*关闭时文字位置设置*/
      ::v-deep .el-switch__label--right {
        position: absolute;
        z-index: 1;
        right: 6px;
        margin-left: 0px;
        color: rgba(255, 255, 255, 0.9019607843137255);

        span {
          font-size: 12px;
        }
      }

      /* 激活时另一个文字消失 */
      ::v-deep .el-switch__label.is-active {
        display: none;
      }

      /*开启时文字位置设置*/
      ::v-deep .el-switch__label--left {
        position: absolute;
        z-index: 1;
        left: 5px;
        margin-right: 0px;
        color: rgba(255, 255, 255, 0.9019607843137255);

        span {
          font-size: 12px;
        }
      }
    }

    .item1 {
      width: 66.5%;
    }

    .btns {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 240px;
      height: 46px;
      margin: 100px auto 0;

      .el-button {
        height: 30px;
        line-height: 30px;
        padding: 0 10px;
        text-align: center;
        color: #fff;
        font-size: 14px;
        background-color: #33465C;
        border-radius: 0;
        border: none;
      }

      .button {
        background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
      }
    }
  }

  /deep/.el-checkbox__input.is-checked .el-checkbox__inner,
  /deep/.el-radio__input.is-checked .el-radio__inner {
    background-color: #01EEFD;
    border-color: #01EEFD;
  }

  .el-checkbox,
  .el-radio {
    color: #fff;
  }

  /deep/.el-checkbox__input.is-checked+.el-checkbox__label,
  /deep/.el-radio__input.is-checked+.el-radio__label {
    color: #01EEFD;
  }
}
</style>
