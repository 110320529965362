<template>
  <div class="see">
    <div class="see-scroll">
      <div class="see-title">
        <span>申请信息</span>
        <el-input placeholder="姓名/身份证号" suffix-icon="el-icon-search" class="see-search">
        </el-input>
      </div>

      <div class="see-line">
        <div class="key">
          <span>老人信息</span>
        </div>
        <div class="border">
          <div class="item">
            <span>头像：</span>
          </div>
          <div class="item item1">
            <span>身份证号：130730199999885848</span>
          </div>
          <div class="item">
            <span>房间号：1楼501室</span>
          </div>
          <div class="item">
            <span>姓名：张萌萌</span>
          </div>
          <div class="item item1">
            <span>联系电话：15803339264</span>
          </div>
          <div class="item">
            <span>床位号：501-1</span>
          </div>
          <div class="item">
            <span>性别：女</span>
          </div>
          <div class="item item1">
            <span>家庭住址：上海市普陀区1000号</span>
          </div>
        </div>
      </div>
      <div class="see-line see-line1">
        <span>陪同人类型：父子</span>
      </div>
      <div class="see-line see-line1">
        <span>陪同人姓名：李明明</span>
      </div>
      <div class="see-line see-line1">
        <span>陪同人电话：15808888888</span>
      </div>
      <div class="see-line see-line1">
        <span>陪同人地址：苏州市山塘街北浩弄60号</span>
      </div>
      <div class="see-line see-line1">
        <span>外出时间：2020-02-02</span>
      </div>
      <div class="see-line see-line1">
        <span>计划返回时间：2020-02-02</span>
      </div>
      <div class="see-line see-line1">
        <span>外出原因：出去散散心！</span>
      </div>

      <div class="see-title">
        <span>审批流</span>
      </div>

      <div class="see-user">
        <div class="label">
          <img src="../../../img/emr/user.png" class="top">
          <span>李明明</span>
        </div>
        <div class="icon">
          <span class="el-icon-arrow-right"></span>
        </div>
        <div class="label">
          <img src="../../../img/emr/user.png" class="top">
          <span>张晓琴</span>
        </div>
        <div class="icon">
          <span class="el-icon-arrow-right"></span>
        </div>
        <div class="label">
          <img src="../../../img/emr/user.png" class="top">
          <span>张晓琴</span>
        </div>
      </div>

      <div class="see-title">
        <span>日志</span>
      </div>

      <el-timeline>
        <el-timeline-item timestamp="李四 审批拒绝了张三提交的床位更换申请备注：不符合更换要求！李四 审批拒绝了张三提交的床位更换申请备注：不符合更换要求！李四 审批拒绝了张三提交的床位更换申请备注：不符合更换要求！李四 审批拒绝了张三提交的床位更换申请备注：不符合更换要求！李四 审批拒绝了张三提交的床位更换申请备注：不符合更换要求！">
          10-20  15：00
        </el-timeline-item>
        <el-timeline-item timestamp="李四 审批拒绝了张三提交的床位更换申请备注：不符合更换要求！李四 审批拒绝了张三提交的床位更换申请备注：不符合更换要求！李四 审批拒绝了张三提交的床位更换申请备注：不符合更换要求！李四 审批拒绝了张三提交的床位更换申请备注：不符合更换要求！李四 审批拒绝了张三提交的床位更换申请备注：不符合更换要求！">
          10-20  15：00
        </el-timeline-item>
        <el-timeline-item timestamp="李四 审批拒绝了张三提交的床位更换申请备注：不符合更换要求！李四 审批拒绝了张三提交的床位更换申请备注：不符合更换要求！李四 审批拒绝了张三提交的床位更换申请备注：不符合更换要求！李四 审批拒绝了张三提交的床位更换申请备注：不符合更换要求！李四 审批拒绝了张三提交的床位更换申请备注：不符合更换要求！">
          10-20  15：00
        </el-timeline-item>
        <el-timeline-item timestamp="李四 审批拒绝了张三提交的床位更换申请备注：不符合更换要求！李四 审批拒绝了张三提交的床位更换申请备注：不符合更换要求！李四 审批拒绝了张三提交的床位更换申请备注：不符合更换要求！李四 审批拒绝了张三提交的床位更换申请备注：不符合更换要求！李四 审批拒绝了张三提交的床位更换申请备注：不符合更换要求！">
          10-20  15：00
        </el-timeline-item>
        <el-timeline-item timestamp="李四 审批拒绝了张三提交的床位更换申请备注：不符合更换要求！">
          10-20  15：00
        </el-timeline-item>
      </el-timeline>
    </div>
    <div class="see-close">
      <el-button size="small" @click="close">返回</el-button>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.see {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  width: 100%;
  height: 100%;
  padding: 0 30px;
  background-color: #fff;
  box-sizing: border-box;

  .see-scroll {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 50px);

    &::-webkit-scrollbar {
      width: 0;
    }

    .see-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 60px;
      border-bottom: 1px solid #000;
      box-sizing: border-box;
      color: #000;
      font-size: 20px;

      .see-search {
        width: 246px;
      }
    }

    .see-line {
      display: flex;
      margin-top: 20px;

      .key {
        color: #000;
        font-size: 20px;
      }

      .border {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: space-between;
        width: 776px;
        height: 133px;
        padding: 20px;
        border: 1px solid #000;
        box-sizing: border-box;

        .item {
          width: 25%;
          color: #000;
          font-size: 16px;
        }

        .item1 {
          width: 50%;
        }
      }
    }

    .see-line1 {
      padding-left: 36px;
      box-sizing: border-box;
      color: #000;
      font-size: 16px;
    }

    .see-user {
      display: flex;
      align-items: center;
      margin-top: 20px;
      padding-left: 20px;
      box-sizing: border-box;

      .label {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #000;
        font-size: 16px;

        .top {
          width: 28px;
          height: 28px;
          margin-bottom: 10px;
        }
      }

      .icon {
        margin: 0 24px;
        color: #000;
        font-size: 16px;
      }
    }

    .el-timeline {
      margin-top: 20px;
      margin-left: 70px;

      /deep/.el-timeline-item__content {
        color: #000;
        font-size: 20px;
      }

      /deep/.el-timeline-item__timestamp {
        line-height: 30px;
        color: #000;
        font-size: 20px;
      }

      /deep/.el-timeline-item__node {
        width: 28px;
        height: 28px;
        left: -9px;
        background-image: url('../../../img/emr/user.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
    }
  }

  .see-close {
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;
  }
}
</style>
