<template>
  <div class="popup">
    <div class="popup-box">
      <div class="popup-title">
        <div class="popup-top"></div>
        <div class="popup-bottom">
          <div class="popup-bottom-text">
            <span>管辖用户</span>
          </div>
        </div>
      </div>

      <div class="popup-content">
        <div class="popup-content-box">
          <div class="info-close" @click="close">
            <span class="el-icon-close"></span>
          </div>
          <div class="add">
            <div class="icon" @click="openAddUser">
              <span class="el-icon-plus"></span>
            </div>
          </div>
          <el-table :data="tableData" height="400" style="width: 100%">
            <el-table-column type="index" label="序号" width="60">
            </el-table-column>
            <el-table-column prop="username" label="用户名">
            </el-table-column>
            <el-table-column prop="nickname" label="姓名">
            </el-table-column>
            <el-table-column prop="phone" label="手机号">
            </el-table-column>
            <el-table-column prop="username" label="平台账号">
            </el-table-column>
            <el-table-column prop="ptcode" label="平台密码">
            </el-table-column>
            <el-table-column prop="roleId" label="角色">
            </el-table-column>
            <el-table-column prop="jgjb" label="机构级别" >
            </el-table-column>
            <el-table-column prop="provinceids" label="省">
            </el-table-column>
            <el-table-column prop="cityids" label="市">
            </el-table-column>
            <el-table-column prop="countryids" label="县">
            </el-table-column>
            <el-table-column prop="townids" label="乡镇">
            </el-table-column>
            <el-table-column prop="villageids" label="村">
            </el-table-column>
            <el-table-column label="操作">
              <!-- slot-scope="scope" -->
              <template>
                <div class="flex">
                  <span @click="openRunUpdate">编辑</span>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
          <!-- 新增 -->
        <Add v-if="showAddUser" @close="closeAddUser"></Add>
      </div>
    </div>
  </div>
</template>

<script>
import Add from '../userRun/add.vue'
export default {
  components: {
    Add
  },
  data () {
    return {
      params: {
        jgbh: '',
        start: '0',
        length: '10',
        ssjgbh: '',
        lytype: '',
        pwzt: '',
        jgjb: ''
      },
      jgjbs: [{
        value: '1',
        label: '省'
      }, {
        value: '2',
        label: '市'
      }, {
        value: '3',
        label: '县'
      }, {
        value: '4',
        label: '乡'
      }, {
        value: '5',
        label: '村'
      }],
      tableData: [],
      showRunUpdate: false,
      showAddUser: false,
      query1: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      total: 400,
      currentPage: 0
    }
  },
  created () {
    this.getList()
  },
  methods: {
    async getList () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-180630108
      try {
        this.params.start = this.query1.pageNum === 1 ? '0' : (this.query1.pageNum - 1) * 10 + ''
        this.params.length = this.query1.pageSize + ''
        this.params.jgbh = this.$parent.selectInfo.jgbh
        const { data: res } = await this.$http.get('/api/jkct/finduserlist', { params: this.params })
        console.log('用户列表', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        if (res.data === null) return
        this.tableData = res.data
      } catch (error) {
        console.log('用户列表 请求失败', error)
        // this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    async addUser () {
      this.showAddUser = true
    },
    openAddUser () {
      this.showAddUser = true
    },
    closeAddUser (e) {
      this.showAddUser = false
      if (e === 'loading') {
        this.getList()
      }
    },
    openUpdate () {

    },
    openRunUpdate () {
      this.showRunUpdate = true
    },
    closeRunUpdate () {
      this.showRunUpdate = false
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.popup {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 888;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .82);
  box-sizing: border-box;

  .popup-box {
    display: flex;
    flex-direction: column;
    width: 1673px;
  }

  .popup-title {
    display: flex;
    flex-direction: column;

    .popup-top {
      position: relative;
      width: 87px;
      height: 6px;
      transform: skew(30deg);
      background-color: #03BCD7;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: -6px;
        width: 10px;
        height: 6px;
        background-color: #081C38;
        transform: skew(-50deg);
      }
    }

    .popup-bottom {
      overflow: hidden;
      position: relative;
      width: 148px;
      height: 30px;
      background: linear-gradient(97deg, #0177CD 0%, #024073 100%);
      transform: skew(30deg);

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: -30px;
        width: 20px;
        height: 30px;
        background-color: #081C38;
        transform: skew(-55deg);
      }

      .popup-bottom-text {
        height: 30px;
        line-height: 30px;
        padding-left: 20px;
        box-sizing: border-box;
        transform: skew(-30deg);
        color: #fff;
        font-size: 20px;
        font-weight: bold;
      }
    }
  }

  .popup-content {
    width: 100%;
    height: 484px;
    margin-top: 11px;
    padding: 10px;
    background: linear-gradient(93deg, rgba(10, 71, 123, .27) 0%, rgba(17, 94, 156, .27) 100%);
    border: 4px solid rgba(1, 124, 219, .27);
    box-sizing: border-box;

    .popup-content-box {
      overflow: hidden;
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      padding: 30px;
      border: 4px solid rgba(1, 124, 219, .27);
      box-sizing: border-box;

      .info-close {
        position: absolute;
        top: -15px;
        right: -40px;
        width: 100px;
        height: 45px;
        line-height: 60px;
        text-align: center;
        transform: rotate(30deg);
        background: linear-gradient(117deg, #1E58A6 0%, #1C4EA1 53%, #2866B1 100%);
        box-shadow: inset 0px -13px 0px 1px #2661AB;
        color: #fff;
        font-size: 24px;
        box-sizing: border-box;
        cursor: pointer;

        span {
          transform: rotate(-30deg);
        }
      }

      .add {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        height: 40px;

        .icon {
          width: 30px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          border-radius: 50%;
          background-color: #04B4D0;
          color: #fff;
          font-size: 20px;
          cursor: pointer;
        }
      }

      .el-table {
        background-color: #081C38;
        border: 4px solid #073F73;
        box-sizing: border-box;

        &::before {
          background-color: transparent;
        }

        // 表头首行
        /deep/th.el-table__cell.is-leaf {
          border-bottom: none;
        }

        /deep/td.el-table__cell {
          border-bottom: 1px solid #01EEFD;
        }

        // 表头背景颜色
        /deep/th.el-table__cell {
          background-color: #024276;
        }

        /deep/.el-table__cell {
          text-align: center;
          color: #fff;
        }

        /deep/tr {
          background-color: #081C38;
        }

        /deep/.el-table__body tr:hover>td.el-table__cell {
          background-color: transparent !important;
        }

        /deep/.el-checkbox__inner {
          background-color: transparent;
        }

        /deep/.is-indeterminate .el-checkbox__inner,
        /deep/.is-checked .el-checkbox__inner {
          background-color: #04B4D0;
        }

        /deep/.el-table__body-wrapper::-webkit-scrollbar {
          width: 0;
        }

        /deep/.el-table__empty-text {
          color: #fff;
        }

        .flex {
          display: flex;
          flex-direction: row;
          justify-content: center;
          width: 100%;
          color: #fff;

          span {
            cursor: pointer;
          }

          .item {
            font-size: 14px;
            cursor: pointer;
          }

          .icon {
            color: #fff;
            font-size: 24px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
