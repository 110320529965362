<template>
  <div class="page-box">
    <div class="tab">
      <span class="item active">关系管理</span>
    </div>
    <el-input placeholder="姓名/身份证号" suffix-icon="el-icon-search" class="input">
    </el-input>
    <div class="tab-box">
      <div class="son-tab">
        <span class="son-item" :class="{ 'son-active': tabIndex === 0 }" @click="tabClick(0)">推荐人管理</span>
        <span class="son-item" :class="{ 'son-active': tabIndex === 1 }" @click="tabClick(1)">合作组织管理</span>
      </div>
      <div class="run-scroll">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {
      tableData: [{
        date: '2016-05-02',
        name: '王小虎',
        address: '未签约'
      }, {
        date: '2016-05-04',
        name: '王小虎',
        address: '预定中'
      }, {
        date: '2016-05-01',
        name: '王小虎',
        address: '已入院'
      }],
      options: [{
        value: '',
        label: ''
      }],
      value: '',
      currentPage4: 4,
      tabIndex: 0,
      selectIndex: 0,
      showAdd: false,
      showSee: false,
      showInfo: false,
      showDelete: false,
      list: ['血压记录', '血糖记录', '体温记录'],
      listIndex: 0
    }
  },
  methods: {
    tabClick (index) {
      if (index === this.tabIndex) return
      this.tabIndex = index
      const routers = ['tab1', 'tab2']
      this.$router.push('/smartelderlycare/marketsales/relationshiprun/' + routers[index])
    },
    tab (index) {
      this.listIndex = index
    },
    openAdd () {
      this.showAdd = true
    },
    closeAdd () {
      this.showAdd = false
    },
    openSee () {
      this.showSee = true
    },
    closeSee () {
      this.showSee = false
    },
    openInfo () {
      this.showInfo = true
    },
    closeInfo () {
      this.showInfo = false
    },
    openDelete () {
      this.showDelete = true
    },
    closeDelete () {
      this.showDelete = false
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.page-box {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  background-color: #fff;

  .input {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 246px;
  }

  .tab {
    display: flex;
    align-items: center;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    .item {
      width: 130px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      margin-left: 30px;
      background-color: #284867;
      color: #fff;
      font-size: 22px;
      cursor: pointer;

      &:first-child {
        margin-left: 0px;
      }
    }

    .active {
      background-color: #409EFF;
    }
  }

  .tab-box {
    width: 100%;
    height: calc(100% - 78px);

    .run-scroll {
      overflow-y: scroll;
      width: 100%;
      height: calc(100% - 100px);
      margin-top: 10px;

      &::-webkit-scrollbar {
        width: 0;
      }
    }
  }

}

.son-tab {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  height: 78px;
  border-bottom: 1px solid #000;
  box-sizing: border-box;

  .son-item {
    height: 38px;
    line-height: 38px;
    margin-right: 40px;
    padding: 0 20px;
    text-align: center;
    background-color: #284867;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
  }

  .son-active {
    background-color:#409EFF;
  }
}
</style>
