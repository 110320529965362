var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"update"},[_c('div',{staticClass:"update-box"},[_vm._m(0),_c('div',{staticClass:"update-box-top"},[_c('div',{staticClass:"update-item"},[_vm._m(1),_c('el-select',{attrs:{"placeholder":"请选择"},model:{value:(_vm.params.ssjgmc),callback:function ($$v) {_vm.$set(_vm.params, "ssjgmc", $$v)},expression:"params.ssjgmc"}},_vm._l((_vm.options),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1),_c('div',{staticClass:"update-item"},[_vm._m(2),_c('el-input',{attrs:{"placeholder":"请输入内容"},model:{value:(_vm.params.ssjgbh),callback:function ($$v) {_vm.$set(_vm.params, "ssjgbh", $$v)},expression:"params.ssjgbh"}})],1),_c('div',{staticClass:"update-item"},[_vm._m(3),_c('el-input',{attrs:{"placeholder":"请输入内容"},model:{value:(_vm.params.tcmc),callback:function ($$v) {_vm.$set(_vm.params, "tcmc", $$v)},expression:"params.tcmc"}})],1),_c('div',{staticClass:"update-item"},[_vm._m(4),_c('el-input',{attrs:{"placeholder":"请输入内容"},model:{value:(_vm.params.tcmcid),callback:function ($$v) {_vm.$set(_vm.params, "tcmcid", $$v)},expression:"params.tcmcid"}})],1),_c('div',{staticClass:"update-item"},[_vm._m(5),_c('el-switch',{attrs:{"active-value":"1","inactive-value":"0","active-color":"#13ce66","inactive-color":"#ff4949"},model:{value:(_vm.params.flag),callback:function ($$v) {_vm.$set(_vm.params, "flag", $$v)},expression:"params.flag"}})],1)]),_c('div',{staticClass:"update-box-bottom"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.submit}},[_vm._v("保存")]),_c('el-button',{on:{"click":_vm.close}},[_vm._v("取消")])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"update-box-head"},[_c('span',[_vm._v("新增")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"update-key"},[_c('span',[_vm._v("机构名称")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"update-key"},[_c('span',[_vm._v("机构编号")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"update-key"},[_c('span',[_vm._v("项目类型")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"update-key"},[_c('span',[_vm._v("项目类型编码")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"update-key"},[_c('span',[_vm._v("启用状态：")])])
}]

export { render, staticRenderFns }