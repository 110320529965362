<template>
  <div class="children-page">
    <div class="capsule">
      <div class="title">
        <div class="top"></div>
        <div class="bottom">
          <div class="text">
            <span>留言板</span>
          </div>
        </div>
        <div class="line"></div>
        <div class="line"></div>
      </div>
      <div class="content">
        <el-button size="mini">更多</el-button>
        <div class="scroll">
          <div class="item" v-for="(item, index) in 20" :key="index">
            <div class="item-left">
              <img class="img">
              <div class="info">
                <span>李明明</span>
                <span>回想起疫情期间下沉社区的同事、社区工作者以及各行各业为抗击疫情作贡献的人们，你们都是好样的！</span>
              </div>
            </div>
            <div class="item-right">
              <span>2022-08-03  12:00:00</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  mounted () { },
  methods: {
  }
}
</script>

<style lang="less" scoped>
.children-page {
  width: 100%;
  height: 100%;

  .capsule {
    width: 100%;
    height: 100%;
    margin-bottom: 20px;
    padding: 20px;
    box-sizing: border-box;
    border: 2px solid #016DBD;
    border-radius: 50px;
    background: #0A2F56;
    box-shadow: inset 0px 0px 13px 1px #0174C8;

    .title {
      width: 159px;
      height: 35px;

      .top {
        width: 72px;
        margin-left: 4px;
        border-left: 3px solid transparent;
        border-right: 3px solid transparent;
        border-bottom: 6px solid #03BCD7;
      }

      .bottom {
        position: relative;
        width: 121px;
        height: 30px;
        line-height: 30px;
        background: linear-gradient(97deg, #0177CD 0%, #024073 100%);
        transform: skewX(20deg);
        color: #fff;
        font-size: 16px;
        font-weight: bold;

        &::before {
          position: absolute;
          top: -7px;
          left: -8px;
          content: '';
          width: 0;
          height: 0;
          border-color: transparent transparent #0A2F56 transparent;
          border-width: 6px;
          border-style: solid;
          transform: rotate(-35deg);
        }

        .text {
          width: 100%;
          text-align: center;
          transform: skewX(-20deg);
        }
      }

      .line {
        position: absolute;
        bottom: -2px;
        right: 10px;
        width: 2px;
        height: 35px;
        background-color: #032142;
        transform: rotate(-30deg);
      }

      .line:last-child {
        right: 0;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      width: 100%;
      height: calc(100% - 60px);
      margin-top: 10px;
      background: linear-gradient(180deg, #094273 0%, #125F9E 100%);
      border: 1px solid #017CDB;
      box-sizing: border-box;

      .el-button {
        background-color: #03BCD7;
        border: none;
        border-radius: 0;
        color: #fff;
        font-size: 20px;
      }

      .scroll {
        overflow-y: scroll;
        width: 100%;
        height: calc(100% - 70px);
        margin-top: 10px;
        padding: 0 20px;
        box-sizing: border-box;

        &::-webkit-scrollbar {
          width: 0;
        }

        .item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          height: 88px;
          margin-bottom: 10px;
          padding: 7px;
          background-color: #06213D;
          box-sizing: border-box;

          .item-left {
            display: flex;

            .img {
              width: 36px;
              height: 36px;
              background-color: #fff;
              border-radius: 50%;
            }

            .info {
              display: flex;
              flex-direction: column;
              margin-left: 10px;
              color: #01EEFD;
              font-size: 14px;
            }
          }

          .item-right {
            color: #01EEFD;
            font-size: 14px;
          }
        }
      }
    }
  }
}
</style>
