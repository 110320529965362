<template>
  <div class="popup">
    <div class="popup-box">
      <div class="popup-title">
        <div class="popup-top"></div>
        <div class="popup-bottom">
          <div class="popup-bottom-text">
            <span>新增外出</span>
          </div>
        </div>
      </div>

      <div class="popup-content">
        <div class="popup-content-box">
          <div class="info-close" @click="close">
            <span class="el-icon-close"></span>
          </div>
          <div class="info-content">
            <div class="info-item">
              <span class="key">老人姓名</span>
              <el-input placeholder=""></el-input>
            </div>
            <div class="info-item">
              <span class="key">外出时间</span>
              <el-date-picker type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
              </el-date-picker>
            </div>
            <div class="info-item">
              <span class="key">陪同人员类型</span>
              <el-select v-model="value" placeholder="请选择">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
              <span class="key">陪同人员姓名</span>
              <el-select v-model="value" placeholder="请选择">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
              <span class="key">陪同人员电话</span>
              <el-select v-model="value" placeholder="请选择">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div class="info-item">
              <span class="key">陪同人员地址</span>
              <el-input placeholder="请输入" class="input"></el-input>
            </div>
            <div class="info-item">
              <span class="key">外出原因</span>
              <el-input placeholder="请输入" class="input"></el-input>
            </div>
          </div>
          <div class="info-btns">
            <el-button size="small" @click="close">取消</el-button>
            <el-button size="small" class="blue" @click="close">确定</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {

  },
  data () {
    return {
      radio: '',
      imageUrl: '',
      options: [{
        value: '',
        label: ''
      }],
      value: '',
      canvas: null,
      ctx: null,
      isDrawing: false,
      lastX: 0,
      lastY: 0
    }
  },
  mounted () {
    this.canvas = document.getElementById('canvas')
    this.ctx = this.canvas.getContext('2d')
  },
  methods: {
    startDrawing (e) {
      this.isDrawing = true;
      [this.lastX, this.lastY] = [e.offsetX, e.offsetY]
    },
    draw (e) {
      if (!this.isDrawing) return
      this.ctx.beginPath()
      this.ctx.moveTo(this.lastX, this.lastY)
      this.ctx.lineTo(e.offsetX, e.offsetY)
      this.ctx.stroke();
      [this.lastX, this.lastY] = [e.offsetX, e.offsetY]
    },
    stopDrawing () {
      this.isDrawing = false
    },
    reset () {
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)
    },
    saveSignature () {
      this.imageUrl = this.canvas.toDataURL()
      // var link = document.createElement('a')
      // link.href = dataURL
      // link.download = 'signature.png'
      // link.click()
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.popup {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 888;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .82);
  box-sizing: border-box;

  .popup-box {
    display: flex;
    flex-direction: column;
    width: 1160px;
  }

  .popup-title {
    display: flex;
    flex-direction: column;

    .popup-top {
      position: relative;
      width: 87px;
      height: 6px;
      transform: skew(30deg);
      background-color: #03BCD7;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: -6px;
        width: 10px;
        height: 6px;
        background-color: #081C38;
        transform: skew(-50deg);
      }
    }

    .popup-bottom {
      overflow: hidden;
      position: relative;
      width: 148px;
      height: 30px;
      background: linear-gradient(97deg, #0177CD 0%, #024073 100%);
      transform: skew(30deg);

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: -30px;
        width: 20px;
        height: 30px;
        background-color: #081C38;
        transform: skew(-55deg);
      }

      .popup-bottom-text {
        height: 30px;
        line-height: 30px;
        padding-left: 20px;
        box-sizing: border-box;
        transform: skew(-30deg);
        color: #fff;
        font-size: 20px;
        font-weight: bold;
      }
    }
  }

  .popup-content {
    width: 100%;
    height: 460px;
    margin-top: 11px;
    padding: 10px;
    background: linear-gradient(93deg, rgba(10, 71, 123, .27) 0%, rgba(17, 94, 156, .27) 100%);
    border: 4px solid rgba(1, 124, 219, .27);
    box-sizing: border-box;

    .popup-content-box {
      overflow: hidden;
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      padding: 30px 20px;
      border: 4px solid rgba(1, 124, 219, .27);
      box-sizing: border-box;

      .info-close {
        position: absolute;
        top: -15px;
        right: -40px;
        width: 100px;
        height: 45px;
        line-height: 60px;
        text-align: center;
        transform: rotate(30deg);
        background: linear-gradient(117deg, #1E58A6 0%, #1C4EA1 53%, #2866B1 100%);
        box-shadow: inset 0px -13px 0px 1px #2661AB;
        color: #fff;
        font-size: 24px;
        box-sizing: border-box;
        cursor: pointer;

        span {
          transform: rotate(-30deg);
        }
      }

      .info-content {
        display: flex;
        flex-direction: column;
        width: 100%;

        .info-item {
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 100%;
          margin-bottom: 20px;

          .key {
            width: 130px;
            color: #01EEFD;
            font-size: 18px;
          }

          .el-input {
            width: 150px;
          }

          .input {
            width: calc(100% - 200px);
          }

          .el-select {
            width: 150px;
            margin-right: 20px;
          }
        }
      }

      .sign {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        height: 150px;
        padding-left: 90px;
        box-sizing: border-box;

        .canvas {
          width: 497px;
          height: 150px;
          margin-right: 54px;
          background-color: #fff;
        }

        .el-button {
          background-color: #FD5401;
          border: none;
          color: #fff;
        }
      }

      .sign-btns {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        height: 65px;
        padding-left: 90px;
        box-sizing: border-box;

        .el-button {
          margin-right: 20px;
          background-color: #FD5401;
          border: none;
          color: #fff;
        }

        .color {
          background-color: #2B01FD;
        }

        .color1 {
          background-color: #013CFD;
        }
      }

      .show {
        width: 249px;
        height: 88px;
        margin-left: 90px;
        background-color: #fff;
      }

      .info-btns {
        position: absolute;
        left: 0;
        bottom: 40px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;

        .el-button {
          margin: 0 50px;
          background-color: #33465C;
          border: none;
          color: #fff;
        }

        .blue {
          background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
        }
      }
    }
  }
}
</style>
