<!--
 * @Author: 小宝 zksukuo@yunkangda.com
 * @Date: 2024-03-18 11:16:04
 * @LastEditors: 小宝 zksukuo@yunkangda.com
 * @LastEditTime: 2024-04-28 16:30:35
 * @FilePath: \visions\src\views\zhyl\smartPhysicalExamination\systemConfig\testProjectRun\projectType\update.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="update">
    <div class="update-box">
      <div class="update-box-top">
        <div class="update-item">
          <div class="update-key">
            <span>Excel文件：</span>
          </div>
          <el-input v-model="input" placeholder="请输入内容"></el-input>
          <el-button type="primary">文件选择</el-button>
          <el-button type="primary" @click="downloadFile">模板下载</el-button>
        </div>
        <div class="update-item">
          <div class="update-key">
            <span>所属学校：</span>
          </div>
          <el-select v-model="params.ssxx" placeholder="请选择" @change="xxChange">
            <el-option v-for="item in xxs" :key="item.ssxxid" :label="item.ssxx" :value="item.ssxxid">
            </el-option>
          </el-select>
          <div class="update-key">
            <span>体检项目：</span>
          </div>
          <el-select v-model="params.tcmc" placeholder="请选择" @change="testChange">
            <el-option v-for="item in tests" :key="item.tclbbm" :label="item.tclbmc" :value="item.tclbbm">
            </el-option>
          </el-select>
          <div class="update-key">
            <span>体检日期：</span>
          </div>
          <el-date-picker v-model="params.tjrq" type="date" placeholder="选择日期">
          </el-date-picker>
        </div>
        <div class="update-item">
          <div class="update-key">
            <span>体检登记：</span>
          </div>
          <el-checkbox :checked="params.tjdjflag === '1'" @change="select">勾选，则在学生档案导入过程中，自动进行体检登记</el-checkbox>
        </div>
        <div class="update-item">
          <div class="update-key">
            <span>体检类型：</span>
          </div>
          <el-select v-model="params.tjlx" placeholder="请选择" @change="testTypeChange">
            <el-option v-for="item in testTypes" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <div class="update-key">
            <span>年度：</span>
          </div>
          <el-date-picker v-model="params.tjnd" type="year" placeholder="请选择">
          </el-date-picker>
          <div class="update-key">
            <span>学期：</span>
          </div>
          <el-select v-model="params.ssxq" placeholder="请选择" @change="xqChange">
            <el-option v-for="item in xqs" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="update-item">
          <el-button type="primary">分析</el-button>
          <!-- :action="this.$uploadUrl + '/excels/putxstj'" -->
          <el-upload ref="upload" :show-file-list="false" :http-request="upload" :data="{gbh: loginInfo.jgbh, czry: loginInfo.usercode}" action="#" icon="el-icon-upload" >
            <el-button type="primary">导入</el-button>
          </el-upload>
          <el-button type="info">重置</el-button>
        </div>
        <el-table border height="200" :data="tableData">
          <el-table-column prop="id" label="序号" :width="flexWidth('id', tableData, '序号')">
          </el-table-column>
          <el-table-column prop="name" label="姓名" :width="flexWidth('name', tableData, '姓名')">
          </el-table-column>
          <el-table-column label="性别" :width="flexWidth('xb', tableData, '性别')">
            <template slot-scope="scope">
              <span>{{ scope.row.xb === '1' ? '男' : scope.row.xb === '2' ? '女' : scope.row.xb }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="age" label="年龄" :width="flexWidth('age', tableData, '年龄')">
          </el-table-column>
          <el-table-column prop="sfzh" label="身份证号" :width="flexWidth('sfzh', tableData, '身份证号')">
          </el-table-column>
          <el-table-column prop="lxdh" label="联系电话" :width="flexWidth('lxdh', tableData, '联系电话')">
          </el-table-column>
          <el-table-column prop="ssxx" label="学校" :width="flexWidth('ssxx', tableData, '学校')">
          </el-table-column>
          <el-table-column prop="ssbj" label="班级" :width="flexWidth('ssbj', tableData, '班级')">
          </el-table-column>
          <el-table-column prop="ssnj" label="年级" :width="flexWidth('ssnj', tableData, '年级')">
          </el-table-column>
          <el-table-column prop="rxsj" label="入学时间" :width="flexWidth('rxsj', tableData, '入学时间')">
          </el-table-column>
          <el-table-column prop="xuehao" label="学籍号" :width="flexWidth('xuehao', tableData, '学籍号学籍号')">
          </el-table-column>
          <el-table-column prop="drsj" label="导入日期" :width="flexWidth('drsj', tableData, '导入日期')">
          </el-table-column>
          <el-table-column label="状态" :width="flexWidth('drflag', tableData, '状态')">
            <template slot-scope="scope">
              <span>{{ scope.row.drflag === '0' ? '成功' : scope.row.drflag === '1' ? '失败' : '' }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="drxx" label="提示信息" :width="flexWidth('drxx', tableData, '提示信息提示信息')">
          </el-table-column>
        </el-table>
      </div>
      <div class="update-box-bottom">
        <el-button @click="close">取消</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
export default {
  data () {
    return {
      input: '',
      options: [],
      value: '',
      flan: false,
      textarea: '',
      tableData: [],
      xxs: [],
      tests: [],
      testTypes: [
        { label: '学生体检', value: '01' }
      ],
      xqs: [
        { label: '上学期', value: '01' },
        { label: '下学期', value: '02' }
      ],
      params: {
        ssxx: '',
        ssxxid: '',
        tjlx: '',
        tjlxid: '',
        tcmc: '',
        tcmcid: '',
        tjnd: '',
        ssxq: '',
        ssxqid: '',
        tjdjflag: '',
        tjrq: '',

        tjxmlb: '',
        tjjgbh: '',
        tjjggmc: '',
        id: '',
        tjrws: '',
        tjrwsn: '',
        tjrwsv: '',
        tjzrs: '',
        tjrsn: '',
        tjrsv: '',
        flag: '0',
        bz1: '',
        bz2: '',
        updateTime: '',
        createTime: ''
      }
    }
  },
  computed: {
    ...mapState(['loginInfo'])
  },
  created () {
    this.getDate()
    this.getXxInfo()
    this.getTestInfo()
  },
  methods: {
    // el-table自适应宽度
    flexWidth (prop, tableData, title, num = 0) {
      if (tableData.length === 0) { // 表格没数据不做处理
        return title.length * 30 + 'px'
      }
      let flexWidth = 0// 初始化表格列宽
      let columnContent = ''// 占位最宽的内容
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')
      context.font = '14px Microsoft YaHei'
      if ((prop === '') && title) { // 标题长内容少的，取标题的值,
        columnContent = title
      } else { // 获取该列中占位最宽的内容
        let index = 0
        for (let i = 0; i < tableData.length; i++) {
          const nowTemp = tableData[i][prop] + ''
          const maxTemp = tableData[index][prop] + ''
          const nowTempW = context.measureText(nowTemp).width
          const maxTempW = context.measureText(maxTemp).width
          if (nowTempW > maxTempW) {
            index = i
          }
        }
        columnContent = tableData[index][prop]
        // 比较占位最宽的值跟标题、标题为空的留出四个位置
        const columnW = context.measureText(columnContent).width
        const titleW = context.measureText(title).width
        if (columnW < titleW) {
          columnContent = title || '留七个字'
        }
      }
      // 计算最宽内容的列宽
      const width = context.measureText(columnContent)
      flexWidth = width.width + 60 + num
      return flexWidth + 'px'
    },
    getDate () {
      const date = new Date()
      const year = date.getFullYear()
      let month = date.getMonth() + 1
      month = month > 9 ? month : '0' + month
      let day = date.getDate()
      day = day > 9 ? day : '0' + day
      console.log(year)
      this.params.tjnd = year + ''
      this.params.tjrq = year + '-' + month + '-' + day
    },
    async getXxInfo () {
      // 接口文档 https://app.apifox.com/project/2726972/apis/api-160594030
      try {
        const params = {
          start: '0',
          length: '10',
          tjjgbh: this.loginInfo.jgbh || ''
        }
        const { data: res } = await this.$http.post('/zhctapi/findssxx', params)
        console.log('根据机构获取学校列表', res)
        if (res.code !== 0 || res.data === null) return
        this.xxs = res.data
      } catch (err) {
        console.log('根据机构获取学校列表', err)
      }
    },
    xxChange (e) {
      console.log(e)
      this.params.ssxxid = e
    },
    async getTestInfo () {
      // 接口文档 https://app.apifox.com/project/2726972/apis/api-160594366
      try {
        const params = {
          start: '0',
          length: '10',
          tjjgbh: this.loginInfo.jgbh || ''
        }
        const { data: res } = await this.$http.post('/zhctapi/findtcxmzd', params)
        console.log('根据机构获取套餐字典', res)
        if (res.code !== 0 || res.data === null) return
        this.tests = res.data
      } catch (err) {
        console.log('根据机构获取套餐字典', err)
      }
    },
    testChange (e) {
      console.log(e)
      this.params.tcmcid = e
    },
    testTypeChange (e) {
      console.log(e)
      this.params.tjlxid = e
    },
    xqChange (e) {
      console.log(e)
      this.params.ssxqid = e
    },
    select (e) {
      console.log(e)
      this.params.tjdjflag = e ? '1' : '0'
      console.log(this.params.tjdjflag)
    },
    async downloadFile () {
      // const response = await fetch('https://oss.gwtjpt.com/xstj/drmb/名单模版.xlsx')
      // // 内容转变成blob地址
      // const blob = await response.blob()
      // // 创建隐藏的可下载链接
      // const objectUrl = window.URL.createObjectURL(blob)
      // const a = document.createElement('a')
      // a.style.display = 'none'
      // // 地址
      // a.href = objectUrl
      // // 修改文件名
      // // a.download = name
      // // 触发点击
      // document.body.appendChild(a)
      // a.click()
      // // 移除
      // URL.revokeObjectURL(a.href)
      // const link = document.createElement('a')
      // link.href = 'https://oss.gwtjpt.com/xstj/drmb/名单模版.xlsx'
      // link.setAttribute('download', '名单模版')
      // link.click()
      // fetch('https://oss.gwtjpt.com/xstj/drmb/名单模版.xlsx').then(res => {
      //   res.blob()
      // }).then(blob => {
      //   const url = window.URL.createObjectURL(blob)
      //   const link = document.createElement('a')
      //   link.href = url
      //   link.setAttribute('download', '名单模版')
      //   document.body.appendChild(link)
      //   link.click()
      // }).catch(error => {
      //   console.error(error)
      // })

      // axios.post('https://oss.gwtjpt.com/xstj/drmb/名单模版.xlsx', { responseType: 'blob' })
      //   .then(response => {
      //     console.log('----', response)
      //     const url = window.URL.createObjectURL(new Blob([response.data]))
      //     const link = document.createElement('a')
      //     link.href = url
      //     link.setAttribute('download', '名单模版')
      //     document.body.appendChild(link)
      //     link.click()
      //   })
      //   .catch(error => {
      //     console.error(error)
      //   })

      // window.open('https://oss.gwtjpt.com/xstj/drmb/名单模版.xlsx')
      // location.href = '名单模版.xlsx'

      const form = document.createElement('form')
      form.action = 'https://oss.gwtjpt.com/xstj/drmb/名单模版.xlsx'
      form.method = 'get'
      form.style.display = 'none'
      document.body.appendChild(form)
      form.submit()
      document.body.removeChild(form)
    },
    upload (param) {
      console.log(param)
      if (this.params.ssxx === '' || this.params.ssxx === null) {
        return this.$message({
          message: '请完善所属学校选项',
          type: 'warning'
        })
      }
      if (this.params.tcmc === '' || this.params.tcmc === null) {
        return this.$message({
          message: '请完善体检项目选项',
          type: 'warning'
        })
      }
      if (this.params.tjlx === '' || this.params.tjlx === null) {
        return this.$message({
          message: '请完善体检类型选项',
          type: 'warning'
        })
      }
      console.log(this.params.tjrq)
      if (this.params.tjrq === '' || this.params.tjrq === null) {
        return this.$message({
          message: '请完善体检日期选项',
          type: 'warning'
        })
      }
      const formdata = new FormData()
      // 上传文件
      formdata.append('file', param.file)
      // 增加请求参数
      formdata.append('tjjgbh', this.loginInfo.jgbh || '')
      formdata.append('usercode', this.loginInfo.usercode || '')
      formdata.append('ssxxid', this.params.ssxxid || '')
      formdata.append('tcmcid', this.params.tcmcid || '')
      formdata.append('tjlxid', this.params.tjlxid || '')
      formdata.append('tjnd', this.params.tjnd || '')
      formdata.append('ssxqid', this.params.ssxqid || '')
      formdata.append('tjdjflag', this.params.tjdjflag)
      formdata.append('tjrq', this.params.tjrq)
      console.log(formdata)
      axios({
        url: '/excels/putxstj',
        method: 'post',
        data: formdata,
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: progressEvent => {
          console.log(progressEvent.loaded / progressEvent.total * 100)
          console.info(progressEvent.loaded)
          console.info(progressEvent.total)
        }
      }).then(res => {
        if (res.data.code !== 0) {
          return this.$message({
            showClose: true,
            message: res.data.msg || '文件上传失败',
            type: 'error'
          })
        }
        this.$message({
          showClose: true,
          message: '文件上传成功',
          type: 'success'
        })
        if (res.data.data === null) return
        this.tableData = res.data.data
      }).catch(response => {
        this.$message({
          message: '文件上传失败',
          type: 'warning'
        })
      })
    },
    uploadSuccess (res, file, fileList) {
      console.log(res, file, fileList)
      if (res.code === 0) {
        this.$message({
          showClose: true,
          message: '导入成功',
          type: 'success'
        })
      } else {
        this.$message({
          showClose: true,
          message: res.msg,
          type: 'error'
        })
      }
    },
    close () {
      this.$parent.showLead = false
    }
  }
}
</script>

<style lang="less" scoped>
.update {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 888;
  background-color: rgba(0, 0, 0, .8);

  .update-box {
    overflow: hidden;
    width: 70%;
    height: 80%;
    border-radius: 10px;
    background-color: #fff;

    .update-box-top {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;
      height: calc(100% - 120px);
      padding: 20px 30px;
      box-sizing: border-box;

      .update-item {
        display: flex;
        align-items: center;
        width: 100%;

        .update-key {
          white-space: nowrap;
          color: #000;
          font-size: 16px;
        }

        /deep/.el-textarea__inner {
          resize: none;
        }

        .el-button {
          margin: 0 5px;
        }
      }
    }

    .update-box-bottom {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 70px;
    }

    .el-select, .el-input {
      margin-right: 10px;
      min-width: 200px;
    }

    /deep/.el-table .cell {
      font-size: 16px;
    }
  }
}
</style>
