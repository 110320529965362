<template>
  <div class="son">
    <SonHead></SonHead>
    <div class="son-scroll">
      <div class="son-info">
        <div class="info-left">
          <div class="info-item">
            <span class="el-icon-user-solid icon color"></span>
            <span class="text">今日体检人数</span>
            <span class="blue">{{ infos[0].num }}</span>
          </div>
          <div class="info-item">
            <span class="el-icon-warning icon color1"></span>
            <span class="text">体检异常结果</span>
            <span class="blue">{{ infos[1].num }}</span>
          </div>
          <div class="info-item">
            <span class="el-icon-warning icon color1"></span>
            <span class="text">体检数据异常结果</span>
            <span class="blue">{{ infos[2].num }}</span>
          </div>
          <div class="info-item">
            <span class="el-icon-s-custom icon color"></span>
            <span class="text">上传异常人数</span>
            <span class="blue">{{ infos[3].num }}</span>
          </div>
          <div class="info-item">
            <span class="el-icon-upload icon color"></span>
            <span class="text">上传异常人数</span>
            <span class="blue">{{ infos[4].num }}</span>
          </div>
          <div class="info-item">
            <span class="el-icon-user-solid icon color"></span>
            <span class="text">总体检人数</span>
            <span class="blue">{{ infos[5].num }}</span>
          </div>
          <div class="info-item">
            <span class="el-icon-s-custom icon color"></span>
            <span class="text">应体未体人数</span>
            <span class="blue">{{ infos[6].num }}</span>
          </div>
          <div class="info-item">
            <span class="el-icon-s-order icon color2"></span>
            <span class="text">总体检任务数</span>
            <span class="blue">{{ infos[7].num }}</span>
          </div>
        </div>
        <div class="info-right">
          <div class="info-title">
            <span>活动通知</span>
          </div>
          <div class="info-text">
            <span></span>
          </div>
        </div>
      </div>
      <div class="son-tab">
        <div class="tab-item" :class="{ 'tab-active': index === tabIndex }" v-for="(item, index) in tabs" :key="index" @click="tabClick(index)">
          <span class="icon" :class="item.icon"></span>
          <span>{{ item.text }}</span>
        </div>
      </div>
      <Tab1 v-if="tabIndex === 0"></Tab1>
      <Tab2 v-else-if="tabIndex === 1"></Tab2>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Tab1 from './tab1/tab1.vue'
import Tab2 from './tab2/tab2.vue'
const SonHead = () => import('../../../../components/son-head/son-head.vue')
export default {
  components: {
    SonHead,
    Tab1,
    Tab2
  },
  data () {
    return {
      infos: [
        { num: '-' },
        { num: '-' },
        { num: '-' },
        { num: '-' },
        { num: '-' },
        { num: '-' },
        { num: '-' },
        { num: '-' }
      ],
      tabIndex: 0,
      tabs: Object.freeze([
        { icon: 'el-icon-s-help', text: '实时数据统计' },
        { icon: 'el-icon-trophy', text: '体检数据排名' },
        { icon: 'el-icon-s-data', text: '体检数据分析' },
        { icon: 'el-icon-trophy', text: '随访数据排名' },
        { icon: 'el-icon-s-operation', text: '数据筛查' }
      ])
    }
  },
  created () {
    this.getInfo()
  },
  computed: {
    ...mapState(['loginInfo'])
  },
  methods: {
    tabClick (index) {
      if (index > 1) return this.$message.error('未开放')
      this.tabIndex = index
    },
    async getInfo () {
      // 接口地址 https://app.apifox.com/link/project/2726972/apis/api-111114899
      try {
        const { data: res } = await this.$http.post('/dockapi/findHomelist', {
          jgbh: this.loginInfo.jgbh || '',
          username: this.loginInfo.usercode || '',
          lytype: ''
        })
        console.log('首页-展板数据', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        if (res.data.currentRates.length < 1) return
        this.infos = res.data.currentRates
      } catch (error) {
        console.log('首页-展板数据请求失败', error)
      }
    }
  }
}
</script>

<style lang="less" scoped>
  .son {
    overflow: hidden;
    width: 100%;
    height: 100%;
    background-color: #F0F0F0;

    .son-scroll {
      overflow: hidden scroll;
      width: 100%;
      height: calc(100% - 90px);
      padding: 0 20px;
      box-sizing: border-box;

      &::-webkit-scrollbar {
        width: 0;
      }

      .son-info {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 160px;

        .info-left {
          display: flex;
          justify-content: space-evenly;
          width: calc(100% - 334px);
          height: 100%;
          padding:  24px 0;
          background-color: #fff;
          border-radius: 18px;
          box-sizing: border-box;

          .info-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;

            .icon {
              font-size: 39px;
              background-clip: text;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }

            .color {
              background-image: linear-gradient(to bottom, #98CDFF, #2B97FF);
            }

            .color1 {
              background-image: linear-gradient(to bottom, #FF8787, #FF0000);
            }

            .color2 {
              background-image: linear-gradient(to bottom, #5CFFCA, #3FDA97);
            }

            .text {
              color: #68696A;
              font-size: 16px;
            }

            .blue {
              color: #3FA1FF;
              font-size: 24px;
            }
          }
        }

        .info-right {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 314px;
          height: 100%;
          padding: 10px;
          background-color: #E3F1FF;
          border-radius: 18px;
          box-sizing: border-box;

          .info-title {
            color: #409EFF;
            font-size: 24px;
          }

          .info-text {
            white-space: pre-wrap;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 4; /* 设置最大显示行数 */
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            text-indent: 2em;
            color: #409EFF;
            font-size: 20px;
          }
        }
      }

      .son-tab {
        display: flex;
        align-items: center;
        width: 100%;
        height: 80px;
        margin-top: 20px;
        padding-left: 19px;
        border-radius: 18px;
        background-color: #fff;
        box-sizing: border-box;

        .tab-item {
          display: flex;
          align-items: center;
          margin-right: 100px;
          color: #68696A;
          font-size: 24px;
          cursor: pointer;

          .icon {
            font-size: 26px;
          }
        }

        .tab-item:last-child {
          margin-right: 0;
        }

        .tab-active {
          color: #409EFF;
        }
      }
    }
  }
</style>
