<!--
 * @Author: 小宝 zksukuo@yunkangda.com
 * @Date: 2024-03-18 11:16:04
 * @LastEditors: 小宝 zksukuo@yunkangda.com
 * @LastEditTime: 2024-04-03 17:14:32
 * @FilePath: \visions\src\views\zhyl\smartPhysicalExamination\systemConfig\testProjectRun\projectType\update.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="update">
    <div class="update-box">
      <div class="update-box-head">
        <span>修改</span>
      </div>
      <div class="update-box-top">
        <div class="update-item">
          <div class="update-key">
            <span>所属分类：</span>
          </div>
          <el-select v-model="params.tjxmfl" placeholder="请选择" @change="handleSelectChange">
              <el-option v-for="item in tjxmfls" :key="item.tjxmbm" :label="item.tjxmmc" :value="item.tjxmbm">
              </el-option>
            </el-select>
        </div>
        <div class="update-item">
          <div class="update-key">
            <span>项目排序：</span>
          </div>
          <el-input-number v-model="num" controls-position="right" :min="1" :max="10"></el-input-number>
        </div>
        <div class="update-item">
          <div class="update-key">
            <span>项目编码：</span>
          </div>
          <el-input v-model="params.tjxmbm" disabled placeholder=""></el-input>
          <el-input placeholder=""></el-input>
        </div>
        <div class="update-item">
          <div class="update-key">
            <span>项目名称：</span>
          </div>
          <el-input v-model="params.tjxmmc" placeholder=""></el-input>
        </div>
        <div class="update-item">
          <div class="update-key">
            <span>项目单位：</span>
          </div>
          <el-input placeholder="" v-model="params.tjxmdw"></el-input>
        </div>
        <div class="update-item">
          <div class="update-key">
            <span>提示信息：</span>
          </div>
          <el-input placeholder="" v-model="params.tjxmtsxx"></el-input>
        </div>
        <div class="update-item">
          <div class="update-key">
            <span>输入类型：</span>
          </div>
          <el-select v-model="params.tjxmsrlx" placeholder="数值输入">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="update-item">
          <div class="update-key">
            <span>数值精度：</span>
          </div>
          <el-select v-model="params.tjxmsrjd" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="update-item">
          <div class="update-key">
            <span>输入范围：</span>
          </div>
          <el-input-number v-model="params.tjxmsrfwd" controls-position="right"></el-input-number>
          <div class="update-key">
            <span>至</span>
          </div>
          <el-input-number v-model="params.tjxmsrfwg" controls-position="right"></el-input-number>
        </div>
        <div class="update-item">
          <div class="update-key">
            <span>默认值：</span>
          </div>
          <el-input type="textarea" placeholder="请输入内容" v-model="params.tjxmmrz">
          </el-input>
        </div>
      </div>
      <div class="update-box-bottom">
        <el-button type="primary" @click="submit">修改</el-button>
        <el-button @click="close">取消</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      input: '',
      options: [],
      value: '',
      flan: false,
      textarea: '',
      tjxmfls: [],
      num: 1,
      params: {
        id: '',
        tjxmmc: '',
        tjxmbm: '',
        bz1: '',
        bz2: '',
        ssjgbh: '',
        ssjgmc: '',
        tjlx: '',
        tjlxid: '',
        flag: '',
        rsxz: '',
        tjxmfl: '',
        tjxmflbm: '',
        tjxmdw: '',
        tjxmtsxx: '',
        tjxmsrlx: '',
        tjxmsrlxbm: '',
        tjxmsrjd: '',
        tjxmsrfwd: '',
        tjxmsrfwg: '',
        tjxmmrz: '',
        tjxmxxzdid: ''
      },
      params2: {
        start: '0',
        length: '10'
      },
      query: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      }
    }
  },
  created () {
    this.params = this.$parent.selectInfo
    this.getInfo()
    this.handleSelectChange()
  },
  methods: {
    async submit () {
      // 接口文档 https://app.apifox.com/project/2726972/apis/api-159367985
      try {
        const { data: res } = await this.$http.post('/zhctapi/updatetjxmlb', this.params)
        console.log('项目列表保存', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.$message.success('保存成功')
        this.$parent.getInfo()
        this.close()
      } catch (error) {
      }
    },
    close () {
      this.$parent.showUpdate = false
      this.$parent.selectInfo = null
    },
    handleSelectChange (selectedValue) {
      const selectedItem = this.tjxmfls.find(item => item.tjxmbm === selectedValue)
      if (selectedItem) {
        this.params.tjxmflmc = selectedItem.tjxmmc
        this.params.tjxmbm = selectedItem.szm
      }
    },
    async getInfo () {
      // 接口文档 https://app.apifox.com/project/2726972/apis/api-160229909
      try {
        this.params.start = this.query.pageNum === 1 ? '0' : (this.query.pageNum - 1) * 10 + ''
        this.params.length = this.query.pageSize + ''
        const { data: res } = await this.$http.get('/ykdzhcttjxmzds', { params: this.params2 })
        console.log('体检项目字典列表', res)
        if (res.code !== 0) {
          return this.$message({
            message: res.msg || '暂无信息',
            type: 'warning'
          })
        }
        this.tjxmfls = res.data
        this.query.total = res.recordsTotal
      } catch (err) {
        console.log('体检项目字典列表', err)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.update {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 888;
  background-color: rgba(0, 0, 0, .8);

  .update-box {
    overflow: hidden;
    width: 50%;
    height: 70%;
    border-radius: 10px;
    background-color: #fff;

    .update-box-head {
      width: 100%;
      height: 50px;
      line-height: 50px;
      text-align: center;
      background-color: #66b1ff;
      color: #fff;
      font-size: 20px;
    }

    .update-box-top {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      height: calc(100% - 120px);
      padding: 20px 30px;
      box-sizing: border-box;

      .update-item {
        display: flex;
        align-items: center;
        width: calc(50% - 20px);
        margin: 0 10px;

        .update-key {
          white-space: nowrap;
          color: #000;
          font-size: 16px;
        }

        /deep/.el-textarea__inner {
          resize: none;
        }
      }

      .update-item:last-child {
        width: 100%;
      }
    }

    .update-box-bottom {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 70px;
    }
  }
}
</style>
