import { render, staticRenderFns } from "./add.vue?vue&type=template&id=ba8bc390&scoped=true&"
import script from "./add.vue?vue&type=script&lang=js&"
export * from "./add.vue?vue&type=script&lang=js&"
import style0 from "./add.vue?vue&type=style&index=0&id=ba8bc390&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ba8bc390",
  null
  
)

export default component.exports