<template>
  <div class="select">
    <div class="content">
      <div class="title">
        <span>{{ title }}</span>
        <span class="el-icon-close icon" @click="close"></span>
      </div>
      <div class="date">
        <span>排期时间：{{ date }} 周{{ week }}</span>
        <el-select class="select-item" v-model="params.sxw" @change="changeDate" placeholder="上下午选择">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-time-select v-if="value" v-model="params.sjd" :picker-options="times" placeholder="选择时间">
        </el-time-select>
      </div>
      <div class="flex">
        <!-- <div class="item">
          <span class="span">选择时间：</span>
          <el-time-picker size="small" v-model="value1" is-range range-separator="至" start-placeholder="开始时间"
            end-placeholder="结束时间" placeholder="选择时间范围">
          </el-time-picker>
        </div> -->
        <div class="item">
          <span class="span">数量：</span>
          <el-input size="small" placeholder="请输入" v-model="params.sl"></el-input>
        </div>
        <el-button @click="addOne">添加</el-button>
      </div>
      <el-table :data="tableData" height="290" style="width: 100%">
        <el-table-column type="index" width="70" label="序号">
        </el-table-column>
        <el-table-column prop="sjd" label="时间段">
        </el-table-column>
        <el-table-column prop="sxw" label="上午/下午">
        </el-table-column>
        <el-table-column prop="sl" label="数量">
        </el-table-column>
        <el-table-column prop="zt" label="状态">
        </el-table-column>
        <el-table-column prop="fwnr" label="操作" width="120">
          <template>
            <div class="flex">
              <span class="blue" @click="openUpdate">编辑</span>
              <span class="blue">删除</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="button">
        <el-button class="blue">确定</el-button>
        <el-button @click="close">取消</el-button>
      </div>
      <update v-if="showUpdate" @close="closeUpdate"></update>
    </div>
  </div>
</template>

<script>
import update from './update.vue'
export default {
  components: { update },
  data () {
    return {
      value1: '',
      tableData: [],
      title: '',
      showUpdate: false,
      value: '',
      options: [{
        value: '1',
        label: '上午'
      }, {
        value: '2',
        label: '下午'
      }],
      time: '',
      times: '',
      params: {
        parentId: '',
        sjd: '',
        sxw: '',
        sl: ''
      }
    }
  },
  created () {
    this.title = this.$parent.title
    this.getList()
  },
  computed: {
    date () {
      return this.$parent.selectDate
    },
    week () {
      const days = ['日', '一', '二', '三', '四', '五', '六']
      const index = new Date(this.date).getDay()
      return days[index]
    }
  },
  methods: {
    async getList () {
      try {
        this.params.parentId = 1
        const { data: res } = await this.$http.get('/ykdel/getbyparentid', { params: this.params })
        console.log('时间段列表', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.tableData = res.data
        this.query1.total = res.recordsTotal || 0
      } catch (error) {
        console.log('预约列表请求失败', error)
        // this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    async addOne () {
      try {
        this.params.parentId = 1
        const { data: res } = await this.$http.post('/ykdel/savedel', this.params)
        console.log('时间段列表', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.getList()
        this.clean()
        this.query1.total = res.recordsTotal || 0
      } catch (error) {
        console.log('预约列表请求失败', error)
        // this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    changeDate (e) {
      console.log(e)
      this.times = this.$data['time' + e]
    },
    openUpdate (e) {
      this.selectInfo = e
      this.showUpdate = true
    },
    closeUpdate () {
      this.showUpdate = false
    },
    close () {
      this.$emit('close')
    },
    clean () {
      this.params.sjd = ''
      this.params.sl = ''
      this.params.sxw = ''
    }
  }
}
</script>

<style lang="less" scoped>
.select {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 8;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .7);

  .content {
    width: 70%;
    padding: 20px 35px;
    background-color: #fff;
    border-radius: 6px;
    box-sizing: border-box;

    .title,
    .date {
      width: 100%;
      margin-bottom: 10px;
      color: #333;
      font-size: 18px;
      font-weight: 600;

      .icon {
        color: #707070;
        font-size: 20px;
        cursor: pointer;
      }
    }

    .select-item {
      width: 150px;
      margin-left: 20px;
    }

    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .item {
        display: flex;
        align-items: center;
        color: #333333;
        font-size: 16px;

        .span {
          white-space: nowrap;
        }

        /deep/.el-date-editor {
          width: 200px;
        }

        .el-input {
          width: 130px;
        }
      }

      .el-button {
        background-color: #3FA1FF;
        border: none;
        font-size: 18px;
        color: #fff;
      }
    }

    // 表格样式修改
    .el-table {
      margin-top: 10px;
      border: 1px solid #707070;
      box-sizing: border-box;

      &::before {
        background-color: transparent;
      }

      // 表头首行
      /deep/th.el-table__cell.is-leaf {
        border-bottom: none;
      }

      /deep/td.el-table__cell {
        border-bottom: 1px solid #707070;
      }

      // 表头背景颜色
      /deep/th.el-table__cell {
        background-color: #3FA1FF;
      }

      /deep/.el-table__cell {
        text-align: center;
        color: #fff;
      }

      /deep/.el-table__body tr:hover>td.el-table__cell {
        background-color: transparent !important;
      }

      /deep/.el-checkbox__inner {
        background-color: transparent;
      }

      /deep/.is-indeterminate .el-checkbox__inner,
      /deep/.is-checked .el-checkbox__inner {
        background-color: #04B4D0;
      }

      /deep/.el-table__body-wrapper::-webkit-scrollbar {
        width: 0;
      }

      /deep/.el-table__empty-text {
        color: #fff;
      }

      /deep/.el-table__body .el-table__row .cell {
        color: #333;
      }

      .flex {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        width: 100%;

        .blue {
          color: #3FA1FF;
          cursor: pointer;
        }
      }
    }

    .button {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;

      .el-button {
        margin: 0 15px;
        padding-top: 4px;
        padding-bottom: 8px;
        background-color: #757779;
        border: none;
        color: #fff;
        font-size: 30px;
      }

      .blue {
        background-color: #3FA1FF;
      }
    }
  }
}
</style>
