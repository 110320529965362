<template>
  <div class="see">
    <div class="see-search">
      <div class="see-item">
        <div class="key">
          <span>项目分类</span>
        </div>
        <el-select v-model="params.tjxmbh" placeholder="请选择">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="see-item">
        <div class="key">
          <span>项目名称</span>
        </div>
        <el-input placeholder="请输入" v-model="params.tjxmmc"></el-input>
      </div>
      <div class="see-item">
        <div class="key">
          <span>启用状态</span>
        </div>
        <el-select v-model="params.flag" placeholder="请选择">
          <el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <el-button type="primary" @click="getInfo">搜索</el-button>
      <el-button type="primary" @click="cleanAll">重置</el-button>
      <el-button type="primary" @click="close">关闭</el-button>
    </div>

    <div class="son-add">
      <div class="son-add-icon" @click="showAdd = true">
        <span class="el-icon-plus"></span>
      </div>
    </div>

    <div class="table">
      <el-table :data="tableData" height="100%" border>
      <el-table-column label="序号" prop="id">
      </el-table-column>
      <el-table-column prop="tjxmbm" label="项目分类编码">
      </el-table-column>
      <el-table-column prop="tjxmmc" label="项目分类名称">
      </el-table-column>
      <el-table-column prop="ssjgmc" label="所属机构">
      </el-table-column>
      <el-table-column prop="flag" label="启用状态">
        <template slot-scope="scope">
              <span v-if="scope.row.flag === '0'">未启用</span>
              <span v-else-if="scope.row.flag === '1'">启用</span>
          </template>
      </el-table-column>
      <el-table-column prop="bz1" label="备注">
      </el-table-column>
      <el-table-column label="操作" width="200">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="openUpdate(scope.row)">编辑</el-button>
          <el-button size="mini" type="danger" @click="openDelete(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    </div>

    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="query.pageNum"
      background
      :page-size="query.pageSize"
      layout="total, prev, pager, next, jumper"
      :total="query.total"></el-pagination>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  components: {},
  data () {
    return {
      tableData: [
        // {
        //   id: '1',
        //   tjxmmc: '2',
        //   tjxmbm: '3',
        //   bz1: '4',
        //   bz2: '5',
        //   ssjgbh: '6',
        //   ssjgmc: '7',
        //   tjlx: '8',
        //   tjlxid: '9',
        //   flag: '10',
        //   rsxz: '11'
        // }
      ],
      options: [{
        value: '',
        label: ''
      }],
      options1: [{
        value: '1',
        label: '启用'
      }, {
        value: '0',
        label: '未启用'
      }
      ],
      params: {
        start: '0',
        length: '10',
        tjjgbh: '',
        flag: '',
        tjxmbm: '',
        tjxmmc: ''
      },
      // 分页数据
      query: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      flag: '',
      tjxmbm: '',
      tjxmmc: '',
      value: '',
      value1: '',
      currentPage4: 4,
      tabIndex: 0,
      selectIndex: 0,
      showAdd: false,
      showDelete: false,
      showSee: false,
      showUpdate: false,
      selectInfo: null
    }
  },
  computed: {
    ...mapState(['loginInfo'])
  },
  created () {
    this.params.tjjgbh = this.loginInfo.jgbh || ''
    this.getInfo()
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    },
    openUpdate (info) {
      this.selectInfo = info
      this.showUpdate = true
    },
    closeAdd () {
      this.selectInfo = null
      this.showAdd = false
    },
    openDelete (info) {
      this.selectInfo = info
      this.showDelete = true
    },
    closeDelete () {
      this.showDelete = false
    },
    openSee () {
      this.showSee = true
    },
    closeSee () {
      this.showSee = false
    },
    async getInfo () {
      // 接口文档 https://app.apifox.com/project/2726972/apis/api-160229909
      try {
        this.params.start = this.query.pageNum === 1 ? '0' : (this.query.pageNum - 1) * 10 + ''
        this.params.length = this.query.pageSize + ''
        const { data: res } = await this.$http.get('/ykdzhcttjxmzds', { params: this.params })
        console.log('体检项目字典列表', res)
        if (res.code !== 0) {
          return this.$message({
            message: res.msg || '暂无信息',
            type: 'warning'
          })
        }
        this.tableData = res.data
        this.query.total = res.recordsTotal
      } catch (err) {
        console.log('体检项目字典列表', err)
      }
    },
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
      this.query.pageNum = val
      this.getInfo()
    },
    cleanAll () {
      this.params.tjxmmc = ''
      this.params.tjxmbh = ''
      this.params.flag = ''
      this.getInfo()
    },
    close () {
      this.$parent.showSee = false
    }
  }
}
</script>

<style lang="less" scoped>
.see {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 888;
  width: 100%;
  height: 100%;
  background-color: #fff;

  .son-add {
    display: flex;
    align-items: center;
    height: 50px;

    .son-add-icon {
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      background-color: #409EFF;
      border-radius: 50%;
      color: #fff;
      font-size: 16px;
      cursor: pointer;
    }
  }

  .see-search {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    .see-item {
      display: flex;
      align-items: center;
      margin-right: 40px;

      .key {
        margin-right: 10px;
        white-space: nowrap;
        color: #000;
        font-size: 22px;
      }
    }
  }

  .table {
    width: 100%;
    height: calc(100% - 220px);
  }

  /deep/.el-table .cell {
    text-align: center;
    color: #000;
  }

  .see {
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      cursor: pointer;
    }
  }

  .el-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
}
</style>
