<template>
  <div class="son">
    <div class="son-title">
      <div class="title">
        <span>健康百科</span>
      </div>
      <!-- <div class="btns">
        <el-button>导入</el-button>
        <el-button>导出</el-button>
      </div> -->
    </div>
    <div class="son-search">
      <div class="left">
        <el-input class="input" placeholder="请输入文章标题"></el-input>
      </div>
      <div class="btns">
        <el-button>搜索</el-button>
        <el-button class="red">删除</el-button>
      </div>
    </div>
    <div class="son-add">
      <div class="add">
        <span class="el-icon-plus"></span>
      </div>
    </div>
    <el-table :data="tableData" height="400" style="width: 100%">
      <el-table-column type="index" label="序号" width="60"></el-table-column>
      <el-table-column prop="date" label="医院名称">
      </el-table-column>
      <el-table-column prop="name" label="医院编码">
      </el-table-column>
      <el-table-column prop="date" label="小程序ID">
      </el-table-column>
      <el-table-column prop="date" label="小程序名称">
      </el-table-column>
      <el-table-column label="操作" width="80">
        <div class="flex">
          <span class="icon el-icon-edit" @click="openUpdate"></span>
          <span class="icon el-icon-delete-solid" @click="openDelete"></span>
        </div>
      </el-table-column>
    </el-table>
    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
      background :page-size="10" layout="total, prev, pager, next, jumper" :total="total">
    </el-pagination>
    <Update v-if="showUpdate" @close="closeUpdate"></Update>
  </div>
</template>

<script>
import Update from './update.vue'
export default {
  components: {
    Update
  },
  data () {
    return {
      options: [{
        value: '',
        label: ''
      }],
      value: '',
      tableData: [{
        date: '2016-05-03',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-02',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-04',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-01',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-08',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-06',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-07',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-07',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-07',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-07',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-07',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }],
      total: 400,
      currentPage: 0,
      showUpdate: false,
      showDelete: false
    }
  },
  methods: {
    openUpdate () {
      this.showUpdate = true
    },
    closeUpdate () {
      this.showUpdate = false
    },
    openDelete () {
      this.showDelete = true
    },
    closeDelete () {
      this.showDelete = false
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
    }
  }
}
</script>

<style lang="less" scoped>
.son {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #081C38;

  .son-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 78px;
    padding-right: 54px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;

    .title {
      width: 150px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      background-color: #01EEFD;
      color: #fff;
      font-size: 22px;
    }

    .btns {
      display: flex;
      flex-direction: row;

      .el-button {
        width: 90px;
        margin-left: 20px;
        background-color: #04B4D0;
        border: none;
        color: #fff;
      }
    }
  }

  .son-search {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 78px;
    padding-right: 83px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;

    .left {
      display: flex;
      flex-direction: row;

      .input {
        width: 334px;
      }
    }

    .btns {
      display: flex;
      flex-direction: row;

      .el-button {
        width: 90px;
        margin-left: 20px;
        background-color: #04B4D0;
        border: none;
        color: #fff;
      }

      .red {
        background-color: #FF0000;
      }
    }
  }

  .son-add {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 70px;

    .add {
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      background-color: #04B4D0;
      border-radius: 50%;
      color: #fff;
      font-size: 20px;
    }
  }

  // 表格样式修改
  .el-table {
    background-color: #081C38;
    border: 4px solid #073F73;
    box-sizing: border-box;

    &::before {
      background-color: transparent;
    }

    // 表头首行
    /deep/th.el-table__cell.is-leaf {
      border-bottom: none;
    }

    /deep/td.el-table__cell {
      border-bottom: 1px solid #01EEFD;
    }

    // 表头背景颜色
    /deep/th.el-table__cell {
      background-color: #024276;
    }

    /deep/.el-table__cell {
      text-align: center;
      color: #fff;
    }

    /deep/tr {
      background-color: #081C38;
    }

    /deep/.el-table__body tr:hover>td.el-table__cell {
      background-color: transparent !important;
    }

    /deep/.el-checkbox__inner {
      background-color: transparent;
    }

    /deep/.is-indeterminate .el-checkbox__inner, /deep/.is-checked .el-checkbox__inner {
      background-color: #04B4D0;
    }

    /deep/.el-table__body-wrapper::-webkit-scrollbar {
      width: 0;
    }

    /deep/.el-table__empty-text {
      color: #fff;
    }

    .flex {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;

      .item {
        font-size: 14px;
        cursor: pointer;
      }

      .green {
        color: #17AF12;
      }

      .red {
        color: #FF6600;
      }

      .organ {
        color: #FF0000;
      }

      .icon {
        color: #0B75EF;
        font-size: 24px;
        cursor: pointer;
      }
    }
  }

  // 页码样式修改
  .el-pagination {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 20px;

    /deep/.el-pagination__total,
    /deep/.el-pagination__jump {
      color: #01EEFD;

      .el-input__inner {
        color: #fff;
        background-color: transparent;
      }
    }

    /deep/.el-pager li:not(.disabled).active {
      background-color: #01EEFD;
    }
  }
}
</style>
