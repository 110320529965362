import { render, staticRenderFns } from "./medicalReport.vue?vue&type=template&id=bc0c43e2&scoped=true&"
import script from "./medicalReport.vue?vue&type=script&lang=js&"
export * from "./medicalReport.vue?vue&type=script&lang=js&"
import style0 from "./medicalReport.vue?vue&type=style&index=0&id=bc0c43e2&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc0c43e2",
  null
  
)

export default component.exports