<template>
  <div class="see">
    <div class="see-scroll">
      <div class="see-title">
        <span>档案管理</span>
        <el-input placeholder="姓名/身份证号" suffix-icon="el-icon-search" class="see-search">
        </el-input>
      </div>

      <div class="see-line">
        <div class="key">
          <span>老人信息</span>
        </div>
        <div class="border">
          <div class="item">
            <span>老人姓名：张三</span>
          </div>
          <div class="item item1">
            <span>身份证号/护照：3293819982929212</span>
          </div>
          <div class="item">
            <span>床位号：501-1</span>
          </div>
        </div>
      </div>

      <el-table :data="tableData" height="50%">
          <el-table-column label="序号" type="index" width="50">
          </el-table-column>
          <el-table-column prop="name" label="文件名">
          </el-table-column>
          <el-table-column prop="address" label="文件">
          </el-table-column>
          <el-table-column label="操作" width="140">
            <!-- slot-scope="scope" -->
            <template>
              <div class="table-run">
                <span>上传文件</span>
                <span>下载</span>
              </div>
            </template>
          </el-table-column>
        </el-table>

        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage4"
          :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper" :total="400">
        </el-pagination>
    </div>
    <div class="see-close">
      <el-button size="small" @click="close">返回</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tableData: [{
        date: '2016-05-02',
        name: '王小虎',
        address: '未签约'
      }, {
        date: '2016-05-04',
        name: '王小虎',
        address: '预定中'
      }, {
        date: '2016-05-01',
        name: '王小虎',
        address: '已入院'
      }],
      options: [{
        value: '',
        label: ''
      }],
      currentPage4: 4
    }
  },
  methods: {
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.see {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  width: 100%;
  height: 100%;
  padding: 0 30px;
  background-color: #081C38;
  box-sizing: border-box;

  .see-scroll {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 50px);

    &::-webkit-scrollbar {
      width: 0;
    }

    .see-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 60px;
      border-bottom: 1px solid #01EEFD;
      box-sizing: border-box;
      color: #01EEFD;
      font-size: 20px;

      .see-search {
        width: 246px;

        /deep/.el-input__inner {
          border: none;
          background: linear-gradient(153deg, #0A477B 0%, #0E67AD 100%);
          color: #fff;
          font-size: 18px;
        }

        /deep/.el-input__inner::-webkit-input-placeholder {
          color: #fff;
        }

        /deep/.el-input__suffix {
          color: #fff;
          font-size: 18px;
        }
      }
    }

    .see-line {
      display: flex;
      margin-top: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid #01EEFD;

      .key {
        color: #01EEFD;
        font-size: 20px;
      }

      .border {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 776px;
        height: 133px;
        padding: 20px;
        border: 1px solid #01EEFD;
        box-sizing: border-box;

        .item {
          width: 25%;
          color: #01EEFD;
          font-size: 16px;
        }

        .item1 {
          width: 50%;
        }
      }
    }

    .see-line1 {
      padding-left: 36px;
      box-sizing: border-box;
      color: #01EEFD;
      font-size: 16px;
    }

    .see-user {
      display: flex;
      align-items: center;
      margin-top: 20px;
      padding-left: 20px;
      box-sizing: border-box;

      .label {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #01EEFD;
        font-size: 16px;

        .top {
          width: 28px;
          height: 28px;
          margin-bottom: 10px;
        }
      }

      .icon {
        margin: 0 24px;
        color: #01EEFD;
        font-size: 16px;
      }
    }

    .el-timeline {
      margin-top: 20px;
      margin-left: 70px;

      /deep/.el-timeline-item__content {
        color: #01EEFD;
        font-size: 20px;
      }

      /deep/.el-timeline-item__timestamp {
        line-height: 30px;
        color: #01EEFD;
        font-size: 20px;
      }

      /deep/.el-timeline-item__node {
        width: 28px;
        height: 28px;
        left: -9px;
        background-image: url('../../../../img/emr/user.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
    }
  }

  .el-table {
    margin-top: 10px;
    border: 4px solid #073F73;
    background-color: #081C38;
  }

  /deep/.el-table__body-wrapper {
    background-color: #081C38;
  }

  /deep/.el-table th.el-table__cell.is-leaf {
    border-bottom: none;
  }

  /deep/.el-table th.el-table__cell {
    background-color: #024276;
  }

  /deep/.el-table .cell {
    text-align: center;
    color: #01EEFD;
  }

  /deep/.el-table tr {
    background-color: #081C38;
  }

  /deep/.el-table td.el-table__cell {
    border-bottom: 1px solid #01EEFD;
  }

  .el-table::before {
    height: 0;
  }

  /deep/.el-table tbody tr:hover>td {
    background-color: transparent;
  }

  /deep/.el-checkbox__inner {
    background-color: transparent;
  }

  .table-run {
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    span {
      cursor: pointer;
    }
  }

  .el-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;

    /deep/.el-pagination__total {
      color: #01EEFD;
    }

    /deep/.el-pagination__jump {
      color: #01EEFD;
    }

    /deep/.el-input__inner {
      background-color: transparent;
      color: #01EEFD;
    }

    /deep/.number {
      margin-left: 10px;
      background-color: #ADADAD;
      color: #fff;
    }

    /deep/.active {
      background-color: #01EEFD;
    }

    /deep/.el-pagination .btn-prev {
      background-color: #ADADAD !important;
      padding-right: 0;
    }

    /deep/.btn-next {
      margin-left: 10px;
      padding: 0;
    }
  }

  .see-close {
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;

    .el-button {
      background-color: #33465C;
      border: none;
      color: #fff;
    }
  }
}
</style>
