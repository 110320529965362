<template>
  <div class="update">
    <div class="content">
      <div class="box">
        <div class="item">
          <div class="text">
            <span>预约机构</span>
          </div>
          <el-select class="input" v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <div class="text">
            <span>预约套餐</span>
          </div>
          <el-select class="input" v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <div class="text">
            <span>预约人</span>
          </div>
          <el-input class="input" v-model="input" placeholder="请输入内容"></el-input>
        </div>
        <div class="item">
          <div class="text">
            <span>证件号码</span>
          </div>
          <el-input class="input" v-model="input" placeholder="请输入内容"></el-input>
        </div>
        <div class="item">
          <div class="text">
            <span>所属学校</span>
          </div>
          <el-select class="input" v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <div class="text">
            <span>所属班级</span>
          </div>
          <el-select class="input" v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <div class="text">
            <span>预约日期</span>
          </div>
          <el-date-picker class="input" v-model="date" type="date" placeholder="选择日期">
          </el-date-picker>
        </div>
        <div class="item">
          <div class="text">
            <span>状态</span>
          </div>
          <el-select class="input" v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <div class="text">
            <span>状态描述</span>
          </div>
          <el-input class="input" v-model="input" placeholder="请输入内容"></el-input>
        </div>
      </div>
      <div class="button">
        <el-button type="primary" @click="close">确定</el-button>
        <el-button @click="close">取消</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      options: [],
      value: '',
      date: ''
    }
  },
  created () {
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.update {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 8;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .7);

  .content {
    width: 80%;
    height: 80%;
    padding: 20px 35px;
    background-color: #fff;
    border-radius: 6px;
    box-sizing: border-box;

    .box {
      display: flex;
      flex-wrap: wrap;
      align-content: space-evenly;
      justify-content: space-evenly;
      width: 100%;
      height: calc(100% - 100px);

      .item {
        display: flex;
        align-items: center;
        width: 33.3%;

        .text {
          white-space: nowrap;
          color: #17212B;
          font-size: 20px;
        }

        .input {
          width: calc(50% - 10px);
          margin-left: 10px;
        }
      }
    }

    .button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100px;
    }
  }
}
</style>
