<template>
  <div class="careful">
    <div class="careful-head">
      <span @click="close">配置审批流</span>
      <el-input placeholder="姓名/身份证号" suffix-icon="el-icon-search" class="input"></el-input>
    </div>
    <div class="careful-tip">
      <span>审批流名称：床位变更申请审批</span>
    </div>
    <div class="careful-add" @click="openSelect">
      <span>+</span>
    </div>
    <el-table :data="tableData" border height="50%">
      <el-table-column label="序号" type="index" width="70">
      </el-table-column>
      <el-table-column prop="name" label="头像">
      </el-table-column>
      <el-table-column prop="address" label="姓名">
      </el-table-column>
      <el-table-column prop="address" label="手机号">
      </el-table-column>
      <el-table-column prop="address" label="部门">
      </el-table-column>
      <el-table-column prop="address" label="角色">
      </el-table-column>
      <el-table-column label="操作" width="190">
        <template>
          <!-- <div class="table-run">
            <span>删除</span>
            <span>下移</span>
          </div> -->
          <el-button size="mini" type="danger">删除</el-button>
          <el-button size="mini" type="primary">下移</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage4"
      :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper" :total="400" background>
    </el-pagination>
    <Select v-if="showSelect" @close="closeSelect"></Select>
  </div>
</template>

<script>
import Select from './select.vue'
export default {
  components: {
    Select
  },
  data () {
    return {
      tableData: [{
        date: '2016-05-02',
        name: '王小虎',
        address: '等待外出'
      }, {
        date: '2016-05-04',
        name: '王小虎',
        address: '未返回'
      }, {
        date: '2016-05-01',
        name: '王小虎',
        address: '逾期未返回'
      }],
      options: [{
        value: '',
        label: ''
      }],
      value: '',
      currentPage4: 4,
      tabIndex: 0,
      selectIndex: 0,
      showAdd: false,
      showSelect: false,
      showSee: false
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    },
    openAdd () {
      this.showAdd = true
    },
    closeAdd () {
      this.showAdd = false
    },
    openSelect () {
      this.showSelect = true
    },
    closeSelect () {
      this.showSelect = false
    },
    openSee () {
      this.showSee = true
    },
    closeSee () {
      this.showSee = false
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>
<style lang="less" scoped>
.careful {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 888;
  width: 100%;
  height: 100%;
  padding: 0 40px;
  background-color: #fff;
  box-sizing: border-box;

  .careful-head {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 60px;
    padding: 0 10px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;
    color: #000;
    font-size: 20px;

    .input {
      width: 246px;
    }
  }

  .careful-tip {
    height: 40px;
    line-height: 40px;
    color: #000;
    font-size: 20px;
  }

  .careful-add {
    width: 30px;
    height: 30px;
    line-height: 30px;
    margin: 20px 0;
    text-align: center;
    border-radius: 50%;
    background-color: #409EFF;
    color: #fff;
    font-size: 15px;
    cursor: pointer;
  }

  .el-table {
    margin-top: 10px;
  }

  /deep/.el-table .cell {
    text-align: center;
    color: #000;
  }

  .table-run {
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: space-between;

    span {
      cursor: pointer;
    }
  }

  .el-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
}
</style>
