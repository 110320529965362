<template>
  <div class="report">
    <div class="report-head">
      <div class="report-text">
        <span>报告详情</span>
      </div>
    </div>
    <div class="report-scroll">
      <div class="report-title">
        <span>体检人信息</span>
      </div>
      <div class="report-box">
        <div class="report-item">
          <span class="report-key">姓名</span>
          <span class="report-value">赖尚荣</span>
        </div>
        <div class="report-item">
          <span class="report-key">性别</span>
          <span class="report-value">男</span>
        </div>
        <div class="report-item">
          <span class="report-key">年龄</span>
          <span class="report-value">48</span>
        </div>
        <div class="report-item">
          <span class="report-key">证件号码</span>
          <span class="report-value">371890199507070909</span>
        </div>
        <div class="report-item">
          <span class="report-key">套餐名称</span>
          <span class="report-value">男性体检基础A套餐</span>
        </div>
        <div class="report-item">
          <span class="report-key">报告时间</span>
          <span class="report-value">2020-12-12 10:09:09</span>
        </div>
      </div>
      <div class="report-title">
        <span>报告详情</span>
      </div>
      <div class="report-content">
        <div class="report-content-item">
          <div class="report-content-title">
            <span>项目名称 常规检查 项目编号 20900001</span>
          </div>
          <div class="report-list report-list1 ">
            <span class="report-list-item">项目名称</span>
            <span class="report-list-item">检查结果</span>
            <span class="report-list-item">参考值</span>
          </div>
          <div class="report-list">
            <span class="report-list-item">身高</span>
            <span class="report-list-item">175cm</span>
            <span class="report-list-item"></span>
          </div>
          <div class="report-list">
            <span class="report-list-item">体重</span>
            <span class="report-list-item">65kg</span>
            <span class="report-list-item"></span>
          </div>
          <div class="report-list">
            <span class="report-list-item">BMI</span>
            <span class="report-list-item">19.0</span>
            <span class="report-list-item">18.5-22.9</span>
          </div>
          <div class="report-list">
            <span class="report-list-item">血压（收缩压）</span>
            <span class="report-list-item">139mm/Hg</span>
            <span class="report-list-item">90-140</span>
          </div>
          <div class="report-list">
            <span class="report-list-item">血压（舒张压）</span>
            <span class="report-list-item">70mm/Hg</span>
            <span class="report-list-item">60-90</span>
          </div>
        </div>
        <div class="report-content-item">
          <div class="report-content-title">
            <span>项目名称 血常规三项  项目编号 20900001</span>
          </div>
          <div class="report-list report-list1 ">
            <span class="report-list-item">项目名称</span>
            <span class="report-list-item">检查结果</span>
            <span class="report-list-item">参考值</span>
          </div>
          <div class="report-list">
            <span class="report-list-item">血红蛋白</span>
            <span class="report-list-item">300g/L</span>
            <span class="report-list-item">参考范围：115-150g/L</span>
          </div>
          <div class="report-list">
            <span class="report-list-item">红细胞</span>
            <span class="report-list-item">300g/L</span>
            <span class="report-list-item">参考范围：115-150g/L</span>
          </div>
          <div class="report-list">
            <span class="report-list-item">血小板</span>
            <span class="report-list-item">300g/L</span>
            <span class="report-list-item">参考范围：115-150g/L</span>
          </div>
          <div class="report-list">
            <span class="report-list-item">单核细胞绝对值</span>
            <span class="report-list-item">300g/L</span>
            <span class="report-list-item">参考范围：115-150g/L</span>
          </div>
          <div class="report-list">
            <span class="report-list-item">淋巴细胞绝对值</span>
            <span class="report-list-item">300g/L</span>
            <span class="report-list-item">参考范围：115-150g/L</span>
          </div>
          <div class="report-list">
            <span class="report-list-item">淋巴细胞绝对值</span>
            <span class="report-list-item">300g/L</span>
            <span class="report-list-item">参考范围：115-150g/L</span>
          </div>
          <div class="report-list">
            <span class="report-list-item">平均红细胞体积</span>
            <span class="report-list-item">300g/L</span>
            <span class="report-list-item">参考范围：115-150g/L</span>
          </div>
        </div>
      </div>
    </div>
    <div class="report-btns">
      <el-button size="small" class="blue">打印</el-button>
      <el-button size="small" class="blue" @click="close">保存</el-button>
      <el-button size="small" @click="close">取消</el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    paramsInfo: {
      type: Object,
      default () {
        return {
          id: 0,
          // regsterpayid: '',
          hostcode: '',
          title: '',
          remark: '',
          name: '',
          sfzh: '',
          accountid: '',
          wxopenid: '',
          mpopenid: '',
          unioid: '',
          begtime: '',
          endtime: ''
        }
      }
    }
  },
  data () {
    return {
      info: {
        id: 0,
        createTime: '',
        updateTime: '',
        regsterpayid: '',
        hostcode: '',
        titletype: '',
        title: '',
        count: '',
        doctor: '',
        doctorcode: '',
        fee: '',
        reghour: '',
        reghalf: '',
        specialty: '',
        remark: '',
        extparam1: '',
        extparam: '',
        deptcode: '',
        deptname: '',
        marktype: '',
        cliniclabel: '',
        registerway: '',
        flagused: '',
        flagavailable: '',
        clinicposition: '',
        clinicfee: '',
        appointcount: '',
        regcount: '',
        dqcode: '',
        day: '',
        deptcode1: '',
        deptcode2: '',
        name: '',
        sfzh: '',
        accountid: ''
      }
    }
  },
  created () {
    this.getInfo()
    console.log('选择人员信息', this.paramsInfo)
  },
  computed: {
    reghour () {
      const arr = ['全天', '上午', '下午', '晚间']
      if (this.info.reghour) return arr[this.info.reghour]
      return ''
    }
  },
  methods: {
    async getInfo () {
      // 接口文档 https://app.apifox.com/project/2726972/apis/api-106422960
      const { data: res } = await this.$http.post('/hitapp/findyyghjl', this.paramsInfo)
      console.log('预约挂号记录查询', res)
      if (res.code !== 0) return this.$message.error(res.msg)
      if (res.data[0]) {
        this.info = res.data[0]
      }
    },
    doctorType (val) {
      if (val !== null) return ['', '普通号', '专家号'][val]
      return ''
    },
    typeVlaue (val) {
      if (val !== null) return ['可预约', '预约成功', '正在支付', '取消'][val]
      return ''
    },
    async submit () {
      // 接口文档 https://app.apifox.com/project/2726972/apis/api-106414575
      const { data: res } = await this.$http.post('/hitapp/regyyqr', this.paramsInfo)
      console.log('预约挂号信息确认', res)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.$message.success('保存成功')
      this.close()
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.report {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 88;
  background-color: #081C38;

  .report-head {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 70px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;

    .report-text {
      height: 38px;
      line-height: 38px;
      padding: 0 9px;
      background-color: #01EEFD;
      box-sizing: border-box;
      color: #fff;
      font-size: 22px;
    }
  }

  .report-scroll {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 120px);

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  .report-title {
    width: 100%;
    height: 68px;
    line-height: 68px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;
    color: #01EEFD;
    font-size: 20px;
    font-weight: 600;
  }

  .report-box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 20px 10px;
    box-sizing: border-box;

    .report-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 33%;
      height: 70px;
      color: #fff;
      font-size: 18px;

      .report-key {
        margin-right: 20px;
      }

      .report-value {
        display: flex;
        flex-direction: row;
        align-items: center;

        .el-button {
          margin-left: 6px;
          background-color: #01EEFD;
          color: #fff;
          border: none;
        }
      }

      .report-img {
        width: 70px;
        height: 70px;
        border-radius: 50%;
        background-color: #01EEFD;
      }

      .el-input {
        width: 190px;
      }
    }
  }

  .report-content {
    display: flex;
    flex-direction: row;

    .report-content-item {
      display: flex;
      flex-direction: column;
      width: 50%;

      .report-content-title {
        width: 100%;
        height: 65px;
        line-height: 65px;
        padding-left: 10px;
        box-sizing: border-box;
        color: #fff;
        font-size: 18px;
        font-weight: 600;
      }

      .report-list {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 700px;
        height: 33px;
        border-bottom: 1px solid #01EEFD;

        .report-list-item {
          flex: 1;
          text-align: center;
          color: #fff;
          font-size: 16px;
        }
      }

      .report-list1 {
        background-color: #01EEFD;
      }
    }
  }

  .report-btns {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;

    .el-button {
      margin-left: 25px;
      margin-right: 25px;
      background-color: #33465C;
      border: none;
      color: #fff;
      font-size: 18px;
    }

    .blue {
      background: linear-gradient(108deg, #0174C9 0%, #024176 100%);
    }
  }
}
</style>
