var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"children-page"},[_vm._m(0),_c('div',{staticClass:"capsule"},[_vm._m(1),_c('div',{staticClass:"capsule-box"},[_vm._m(2),_c('div',{staticClass:"table"},[_vm._m(3),_c('div',{staticClass:"capsule-scroll"},_vm._l((10),function(item,index){return _c('div',{key:index,staticClass:"tr"},[_c('span',{staticClass:"flex"},[_vm._v("讲座类型")]),_c('span',{staticClass:"flex"},[_vm._v("讲座地点")]),_c('span',{staticClass:"flex flex2"},[_vm._v("讲座时间")])])}),0)])])]),_c('div',{staticClass:"capsule"},[_vm._m(4),_c('div',{staticClass:"video"},_vm._l((6),function(item,index){return _c('div',{key:index,staticClass:"video-item"},[_vm._m(5,true),_vm._m(6,true)])}),0)]),_vm._m(7),_vm._m(8)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"capsule"},[_c('div',{staticClass:"title"},[_c('div',{staticClass:"top"}),_c('div',{staticClass:"bottom"},[_c('div',{staticClass:"text"},[_c('span',[_vm._v("健康知识宣传栏")])])]),_c('div',{staticClass:"line"}),_c('div',{staticClass:"line"})]),_c('div',{staticClass:"capsule-box"},[_c('div',{staticClass:"head"},[_c('span',[_vm._v("今日宣传")])]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"h1"},[_c('span',[_vm._v("高血压患者的饮食禁忌事项介绍")])]),_c('div',{staticClass:"h2"},[_c('span',[_vm._v("1、高血压患者不能吃油腻和辛辣的食物")])]),_c('div',{staticClass:"h2"},[_c('span',[_vm._v("1、高血压患者不能吃油腻和辛辣的食物")])]),_c('div',{staticClass:"h2"},[_c('span',[_vm._v("1、高血压患者不能吃油腻和辛辣的食物")])]),_c('div',{staticClass:"h2"},[_c('span',[_vm._v("1、高血压患者不能吃油腻和辛辣的食物")])]),_c('div',{staticClass:"h2"},[_c('span',[_vm._v("1、高血压患者不能吃油腻和辛辣的食物")])]),_c('div',{staticClass:"h2"},[_c('span',[_vm._v("1、高血压患者不能吃油腻和辛辣的食物")])]),_c('div',{staticClass:"h2"},[_c('span',[_vm._v("1、高血压患者不能吃油腻和辛辣的食物")])]),_c('div',{staticClass:"h2"},[_c('span',[_vm._v("1、高血压患者不能吃油腻和辛辣的食物")])]),_c('div',{staticClass:"h2"},[_c('span',[_vm._v("1、高血压患者不能吃油腻和辛辣的食物")])]),_c('div',{staticClass:"h2"},[_c('span',[_vm._v("1、高血压患者不能吃油腻和辛辣的食物")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('div',{staticClass:"top"}),_c('div',{staticClass:"bottom"},[_c('div',{staticClass:"text"},[_c('span',[_vm._v("健康讲座时间发布")])])]),_c('div',{staticClass:"line"}),_c('div',{staticClass:"line"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"head"},[_c('span',[_vm._v("时间发布")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tr"},[_c('span',{staticClass:"flex"},[_vm._v("讲座类型")]),_c('span',{staticClass:"flex"},[_vm._v("讲座地点")]),_c('span',{staticClass:"flex flex2"},[_vm._v("讲座时间")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('div',{staticClass:"top"}),_c('div',{staticClass:"bottom"},[_c('div',{staticClass:"text"},[_c('span',[_vm._v("健康教育视频")])])]),_c('div',{staticClass:"line"}),_c('div',{staticClass:"line"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"video-title"},[_c('span',[_vm._v("高血压知识讲座")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"start"},[_c('span',{staticClass:"el-icon-caret-right"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"capsule"},[_c('div',{staticClass:"title"},[_c('div',{staticClass:"top"}),_c('div',{staticClass:"bottom"},[_c('div',{staticClass:"text"},[_c('span',[_vm._v("政策发布")])])]),_c('div',{staticClass:"line"}),_c('div',{staticClass:"line"})]),_c('div',{staticClass:"capsule-box"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"h1"},[_c('span',[_vm._v("最新国家医疗政策介绍")])]),_c('div',{staticClass:"h2"},[_c('span',[_vm._v("在各级人民代表大会下设立专门医疗机构管理委员会，管理区域内公立医院，公立医院的领导 和财务都由该委员会确定，并对人大常委会负责。各级卫生行政机构转变职能，强化监督责任 ，非营利医疗机构的财政拨款等不再通过卫生行政机构或投资方，并建立医疗服务价格调整听 证制度。 ")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"capsule1"},[_c('div',{staticClass:"title"},[_c('div',{staticClass:"top"}),_c('div',{staticClass:"bottom"},[_c('div',{staticClass:"text"},[_c('span',[_vm._v("就诊记录")])])]),_c('div',{staticClass:"line"}),_c('div',{staticClass:"line"})]),_c('div',{staticClass:"tab"},[_c('div',{staticClass:"tab-item tab-active"},[_c('span',[_vm._v("机构")])]),_c('div',{staticClass:"tab-item"},[_c('span',[_vm._v("医生")])])]),_c('div',{staticClass:"chart",attrs:{"id":"charts"}})])
}]

export { render, staticRenderFns }