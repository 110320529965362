<template>
  <div class="popup">
    <div class="popup-title">
      <div class="title">
        <span>编辑院区信息</span>
      </div>
      <el-input class="postion" placeholder="姓名/身份证号" suffix-icon="el-icon-search">
      </el-input>
    </div>
    <div class="popup-scroll">
      <div class="popup-line">
        <span>基本信息</span>
      </div>
      <div class="popup-box">
        <div class="popup-item">
          <span>院区名称：</span>
          <el-input placeholder="请输入"></el-input>
        </div>
        <div class="popup-item">
          <span>联系电话：</span>
          <el-input placeholder="请输入"></el-input>
        </div>
        <div class="popup-item">
          <span>公交：</span>
          <el-input placeholder="请输入"></el-input>
        </div>
        <div class="popup-item">
          <span>院区地址：</span>
          <el-input placeholder="请输入"></el-input>
        </div>
        <div class="popup-item">
          <span class="icon el-icon-circle-plus"></span>
          <span>新增联系电话</span>
        </div>
        <div class="popup-item popup-item1">
          <div class="map"></div>
        </div>
        <div class="popup-item popup-item1">
          <div class="table">
            <div class="head">
              <div class="flex flex1">
                <span>序号</span>
              </div>
              <div class="flex flex1">
                <span>楼层索引</span>
              </div>
              <div class="flex flex3">
                <span>楼层</span>
              </div>
              <div class="flex flex1">
                <span class="icon el-icon-circle-plus"></span>
                <span>操作</span>
              </div>
            </div>
            <div class="table-scroll">
              <div class="tr" v-for="(item, index) in 4" :key="index">
                <div class="flex flex1">
                  <span>{{ item }}</span>
                </div>
                <div class="flex flex1">
                  <span>1楼</span>
                </div>
                <div class="flex flex3">
                  <span>总服务台 警务室 收费处 急诊室 综合门诊</span>
                </div>
                <div class="flex flex1 flex2 border">
                  <span class="icon el-icon-circle-plus"></span>
                  <span class="icon el-icon-remove"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="popup-item popup-item1">
          <span>院区状态：</span>
          <el-switch v-model="value" active-color="#30F05D" inactive-color="#ff4949">
          </el-switch>
        </div>
      </div>
    </div>

    <div class="btns">
      <el-button class="blue" @click="close">保存</el-button>
      <el-button @click="close">取消</el-button>
    </div>
  </div>
</template>

<script>
export default {
  components: {

  },
  data () {
    return {
      options: [{
        value: '',
        label: ''
      }],
      imageUrl: '',
      value: ''
    }
  },
  methods: {
    handleAvatarSuccess (res, file) {
      this.imageUrl = URL.createObjectURL(file.raw)
    },
    beforeAvatarUpload (file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.popup {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 888;
  width: 100%;
  height: 100%;
  background-color: #081C38;
  box-sizing: border-box;

  .popup-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;

    .title {
      height: 38px;
      line-height: 38px;
      padding: 0 9px;
      text-align: center;
      background-color: #01EEFD;
      box-sizing: border-box;
      color: #fff;
      font-size: 22px;
    }

    .postion {
      width: 246px;
      border-radius: 6px;
      background: linear-gradient(153deg, #0A477B 0%, #0E67AD 100%);
      border: 1px solid #1F72D3;

      /deep/.el-input__inner {
        background-color: transparent;
        color: #fff !important;
        font-size: 18px;
        border: none;

      }

      /deep/ .el-input__suffix {
        color: #fff;
        font-size: 18px;
      }

      /deep/ .el-icon-search {
        display: flex;
        align-items: center;
      }
    }
  }

  .popup-scroll {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 160px);

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  .popup-line {
    height: 80px;
    line-height: 80px;
    padding-left: 14px;
    box-sizing: border-box;
    color: #04B4D0;
    font-size: 22px;
  }

  .popup-box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 12px;
    box-sizing: border-box;

    .popup-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 33%;
      margin-bottom: 20px;
      color: #01EEFD;
      font-size: 18px;

      .icon {
        margin-right: 7px;
        color: #01EEFD;
        font-size: 24px;
      }

      .el-input {
        width: 180px;
      }

      .avatar-uploader {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 180px;
        height: 106px;
        background-color: #FAFAFA;
      }

      .info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        color: #B5B5B5;
        font-size: 16px;
      }

      /deep/.avatar-uploader-icon {
        margin-bottom: 15px;
        font-size: 28px;
        color: #8c939d;
      }

      .map {
        width: 446px;
        height: 446px;
        background-color: #B6D8F1;
      }

      .table {
        width: 733px;
        height: 216px;
        border: 4px solid #073F73;
        box-sizing: border-box;

        .head {
          display: flex;
          flex-direction: row;
          width: 100%;
          height: 44px;
          background-color: #024276;
        }

        .flex {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          height: 44px;
          text-align: center;
          color: #fff;
          font-size: 16px;
        }

        .flex1 {
          flex: 1;
        }

        .flex2 {
          justify-content: space-around;
        }

        .flex3 {
          flex: 3;
        }

        .table-scroll {
          overflow-y: scroll;
          width: 100%;
          height: calc(100% - 44px);

          &::-webkit-scrollbar {
            width: 0;
          }

          .tr {
            display: flex;
            flex-direction: row;
            width: 100%;
            height: 44px;
            border-bottom: 1px solid #01EEFD;
            box-sizing: border-box;

            // &:last-child {
            //   border-bottom: none;
            // }

            .flex {
              border-right: 1px solid #01EEFD;
              box-sizing: border-box;
            }

            .border {
              border-right: none;
            }
          }

          .icon {
            color: #01EEFD;
            font-size: 24px;
            cursor: pointer;
          }
        }
      }
    }

    .popup-item1 {
      width: 100%;
    }
  }

  .btns {
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 70px;

    .el-button {
      margin: 0 25px;
      background-color: #33465C;
      border: none;
      color: #fff;
    }

    .blue {
      background: linear-gradient(108deg, #0174C9 0%, #024176 100%);
    }
  }
}
</style>
