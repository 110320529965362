<template>
  <div class="page">
    <div class="tip">
      <span>暂未开放！</span>
    </div>
    <div class="close" @click="close">
      <span class="el-icon-error"></span>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    close () {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="less" scoped>
.page {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 1);

  .tip {
    letter-spacing: 4px;
    color: #fff;
    font-size: 30px;
  }

  .close {
    position: absolute;
    top: 40px;
    right: 40px;
    cursor: pointer;
    color: #fff;
    font-size: 36px;
  }
}
</style>
