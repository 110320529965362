<!--
 * @Author: 小宝 zksukuo@yunkangda.com
 * @Date: 2024-05-20 18:02:03
 * @LastEditors: 小宝 zksukuo@yunkangda.com
 * @LastEditTime: 2024-05-21 18:06:14
 * @FilePath: \visions\src\views\zhyl\smartElderlyCare\smartHousePatrol\smartHousePatrol.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!--
 * @Author: 小宝 zksukuo@yunkangda.com
 * @Date: 2024-05-20 18:02:03
 * @LastEditors: 小宝 zksukuo@yunkangda.com
 * @LastEditTime: 2024-05-20 18:16:35
 * @FilePath: \visions\src\views\zhyl\smartElderlyCare\smartHousePatrol\smartHousePatrol.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="son">
    <SonHead></SonHead>
    <div class="son-scroll">
      <div class="son-item" v-for="(item, index) in rList" :key="index" @click="show = true">
        <div class="son-title">
          <span>{{item.xzjdmc}}·{{item.rylcid}}层 </span>
        </div>
        <div class="son-line">
          <img src="" alt="" srcset="" class="son-img">
          <span class="span">{{item.rycw}}床 {{item.name}}</span>
          <span>{{item.hzzt}}</span>
        </div>
        <!-- <div class="son-line">
          <img src="" alt="" srcset="" class="son-img">
          <span class="span">2床 李慧慧</span>
          <span>(睡觉)</span>
        </div> -->
        <div class="son-line">
          <img src="" alt="" srcset="" class="son-img son-img1">
          <span class="span">卫生间</span>
          <span class="green"></span>
        </div>
      </div>
    </div>
    <Popup v-if="show"></Popup>
  </div>
</template>

<script>
import { mapState } from 'vuex'
const SonHead = () => import('../../../../components/son-head/son-head.vue')
const Popup = () => import('./popup.vue')
export default {
  components: {
    SonHead,
    Popup
  },
  data () {
    return {
      params: {
        rylcid: '',
        rylc: '',
        xzjdmc: '',
        xzjdmcid: '',
        hzzt: '',
        name: '',
        cwhmc: ''
      },
      rList: [],
      show: false
    }
  },
  computed: {
    ...mapState(['loginInfo'])
  },
  created () {
    this.getList()
  },
  methods: {
    async getList () {
      try {
        const obj = {
          start: 0,
          length: 10 + '',
          ssjgbh: this.loginInfo.jgbh,
          jgbh: this.loginInfo.jgbh,
          sxzt: '0'
        }
        const { data: res } = await this.$http.get('/ykdrydjs', { params: obj })
        console.log('入院登记列表', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.rList = res.data
      } catch (error) {
        console.log('入院登记列表请求失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    tabClick (index) {
      this.tabIndex = index
    }
  }
}
</script>

<style lang="less" scoped>
  .son {
    width: 100%;
    height: 100%;
    background-color: #F0F0F0;

    .son-scroll {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-content: start;
      width: 100%;
      height: calc(100% - 90px);
      // padding: 0 5px;
      background-color: #F0F0F0;
      box-sizing: border-box;

      .son-item {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        width: 246px;
        height: 170px;
        margin: 5px;
        padding: 0 20px;
        background-color: #fff;
        box-sizing: border-box;
        cursor: pointer;

        .son-title {
          color: #006EFF;
          font-size: 18px;
          font-weight: bold;
        }

        .son-line {
          display: flex;
          flex-direction: row;
          align-items: center;
          color: #333333;
          font-size: 16px;

          .son-img {
            width: 16px;
            height: 16px;
            background-color: #006EFF;
          }

          .son-img1 {
            background-color: #0DD627;
          }

          .span {
            margin-left: 4px;
          }

          .green {
            color: #0DD627;
          }
        }
      }
    }
  }
</style>
