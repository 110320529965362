<template>
  <div class="popup">
    <div class="popup-box">
      <div class="popup-title">
        <div class="popup-top"></div>
        <div class="popup-bottom">
          <div class="popup-bottom-text">
            <span>新增</span>
          </div>
        </div>
      </div>

      <div class="popup-content">
        <div class="popup-content-box">
          <div class="info-close" @click="close">
            <span class="el-icon-close"></span>
          </div>
          <div class="info-item">
            <span class="key">疫苗名称</span>
            <el-input placeholder="请输入"></el-input>
          </div>
          <div class="info-item">
            <span class="key">缩略图</span>
            <el-upload class="avatar-uploader" action="https://jsonplaceholder.typicode.com/posts/"
              :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
              <img v-if="imageUrl" :src="imageUrl" class="avatar">
              <div class="text" v-else>
                <i class="el-icon-plus icon"></i>
                <span>点击上传</span>
              </div>
            </el-upload>
          </div>
          <div class="info-item">
            <span class="key">封面</span>
            <el-upload class="avatar-uploader" action="https://jsonplaceholder.typicode.com/posts/"
              :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
              <img v-if="imageUrl" :src="imageUrl" class="avatar">
              <div class="text" v-else>
                <i class="el-icon-plus icon"></i>
                <span>点击上传</span>
              </div>
            </el-upload>
          </div>

          <div class="info-item info-item1 info-item2">
            <span class="key">疫苗介绍</span>
            <el-input type="textarea" placeholder="请输入">
            </el-input>
          </div>
          <div class="info-btns">
            <el-button size="small" @click="close">取消</el-button>
            <el-button size="small" class="blue" @click="close">确定</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {

  },
  data () {
    return {
      radio: '',
      imageUrl: ''
    }
  },
  methods: {
    handleAvatarSuccess (res, file) {
      this.imageUrl = URL.createObjectURL(file.raw)
    },
    beforeAvatarUpload (file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.popup {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 888;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .82);
  box-sizing: border-box;

  .popup-box {
    display: flex;
    flex-direction: column;
    width: 1006px;
  }

  .popup-title {
    display: flex;
    flex-direction: column;

    .popup-top {
      position: relative;
      width: 87px;
      height: 6px;
      transform: skew(30deg);
      background-color: #03BCD7;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: -6px;
        width: 10px;
        height: 6px;
        background-color: #081C38;
        transform: skew(-50deg);
      }
    }

    .popup-bottom {
      overflow: hidden;
      position: relative;
      width: 148px;
      height: 30px;
      background: linear-gradient(97deg, #0177CD 0%, #024073 100%);
      transform: skew(30deg);

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: -30px;
        width: 20px;
        height: 30px;
        background-color: #081C38;
        transform: skew(-55deg);
      }

      .popup-bottom-text {
        height: 30px;
        line-height: 30px;
        padding-left: 20px;
        box-sizing: border-box;
        transform: skew(-30deg);
        color: #fff;
        font-size: 20px;
        font-weight: bold;
      }
    }
  }

  .popup-content {
    width: 100%;
    height: 586px;
    margin-top: 11px;
    padding: 10px;
    background: linear-gradient(93deg, rgba(10, 71, 123, .27) 0%, rgba(17, 94, 156, .27) 100%);
    border: 4px solid rgba(1, 124, 219, .27);
    box-sizing: border-box;

    .popup-content-box {
      overflow: hidden;
      position: relative;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      height: 100%;
      padding: 30px 20px;
      border: 4px solid rgba(1, 124, 219, .27);
      box-sizing: border-box;

      .info-close {
        position: absolute;
        top: -15px;
        right: -40px;
        width: 100px;
        height: 45px;
        line-height: 60px;
        text-align: center;
        transform: rotate(30deg);
        background: linear-gradient(117deg, #1E58A6 0%, #1C4EA1 53%, #2866B1 100%);
        box-shadow: inset 0px -13px 0px 1px #2661AB;
        color: #fff;
        font-size: 24px;
        box-sizing: border-box;

        span {
          transform: rotate(-30deg);
        }
      }

      .info-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 33%;
        color: #01EEFD;
        font-size: 18px;

        .key {
          margin-right: 10px;
          white-space: nowrap;
        }

        .value {
          width: calc(100% - 120px);
          word-wrap: break-word;
        }

        .el-input {
          width: 166px;
        }

        .el-radio {
          color: #fff;
        }

        /deep/.el-radio__label {
          font-size: 18px;
        }

        /deep/.el-radio__inner {
          background-color: transparent;
        }

        /deep/.el-radio__input.is-checked+.el-radio__label {
          color: #01EEFD;
        }

        /deep/.el-radio__input.is-checked .el-radio__inner {
          background-color: #01EEFD;
          border-color: #01EEFD;
        }

        .avatar-uploader {
          width: 166px;
          height: 73px;
          border: 1px solid #fff;

          .text {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-around;
            width: 100%;
            margin-top: 10px;
            padding-left: 10px;
            box-sizing: border-box;
            color: #fff;

            .icon {
              font-size: 26px;
            }
          }

          .avatar {
            width: 100%;
            height: 100%;
          }
        }
      }

      .info-item1 {
        width: 100%;

        .key {
          color: #01EEFD;
        }

        /deep/.el-textarea__inner {
          width: 800px;
          height: 300px;
          resize: none;
        }
      }

      .info-item2 {
        align-items: flex-start;
      }

      .info-btns {
        position: absolute;
        left: 0;
        bottom: 10px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;

        .el-button {
          margin: 0 50px;
          background-color: #33465C;
          border: none;
          color: #fff;
        }

        .blue {
          background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
        }
      }
    }
  }
}
</style>
