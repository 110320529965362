<template>
  <div class="page-box">
    <div class="tab">
      <span class="item" :class="{ active: tabIndex === index }" v-for="(item, index) in tabs" :key="index"
        @click="tabClick(index)">{{ item }}</span>
    </div>
    <el-input placeholder="姓名/身份证号" suffix-icon="el-icon-search" class="input">
    </el-input>
    <div class="tab-box">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tabIndex: 0,
      tabs: Object.freeze(['委托用药', '健康档案', '查房管理'])
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
      const routers = ['entrustedmedication', 'healthrecords', 'roomrun']
      this.$router.push('/smartelderlycare/medicalcarerun/' + routers[index])
    }
  }
}
</script>

<style lang="less" scoped>
.page-box {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  background-color: #081C38;

  .input {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 246px;

    /deep/.el-input__inner {
      border: none;
      background: linear-gradient(153deg, #0A477B 0%, #0E67AD 100%);
      color: #fff;
      font-size: 18px;
    }

    /deep/.el-input__inner::-webkit-input-placeholder {
      color: #fff;
    }

    /deep/.el-input__suffix {
      color: #fff;
      font-size: 18px;
    }
  }

  .tab {
    display: flex;
    align-items: center;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;

    .item {
      width: 130px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      margin-left: 30px;
      background-color: #284867;
      color: #fff;
      font-size: 22px;
      cursor: pointer;

      &:first-child {
        margin-left: 0px;
      }
    }

    .active {
      background-color: #04B4D0;
    }
  }

  .tab-box {
    width: 100%;
    height: calc(100% - 78px);
  }

}
</style>
