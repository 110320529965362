<template>
  <div class="son">
    <div class="son-title">
      <span class="title-box">用户管理</span>
    </div>
    <div class="son-search">
      <div class="son-item">
        <span class="key">姓名</span>
        <el-input placeholder="请输入" v-model="params.nickname"></el-input>
      </div>
      <div class="son-item">
        <span class="key">平台密码</span>
        <el-input placeholder="请输入" v-model="params.ptcode"></el-input>
      </div>
      <div class="son-item">
        <span class="key">手机号</span>
        <el-input placeholder="请输入" v-model="params.phone"></el-input>
      </div>
      <div class="son-item">
        <span class="key">机构级别</span>
        <el-select v-model="value" placeholder="请选择">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="son-item">
        <span class="key">机构名称</span>
        <el-select v-model="value" placeholder="请选择">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="son-item">
        <span class="key">平台验证</span>
        <el-select v-model="value" placeholder="请选择">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="son-item">
        <el-button type="primary" @click="getListTwo">搜索</el-button>
        <el-button type="primary">清空</el-button>
      </div>
    </div>
    <div class="add">
      <div class="icon" @click="showAdd = true">
        <span class="el-icon-plus"></span>
      </div>
    </div>
    <div class="table">
      <el-table :data="tableData" height="100%" border>
      <el-table-column type="index" label="序号" width="100">
      </el-table-column>
      <el-table-column prop="username" label="用户名">
      </el-table-column>
      <el-table-column prop="nickname" label="姓名">
      </el-table-column>
      <el-table-column prop="ptcode" label="平台密码">
      </el-table-column>
      <el-table-column prop="phone" label="手机号">
      </el-table-column>
      <el-table-column prop="jgjb" label="机构级别">
      </el-table-column>
      <el-table-column prop="jgbh" label="机构编号">
      </el-table-column>
      <el-table-column prop="townids" label="所属机构">
      </el-table-column>
      <el-table-column label="是否绑定">
        <!-- <template slot-scope="scope">
          <div class="green" v-if="scope.row.status === 1">
            <span>是</span>
          </div>
          <div class="red" v-else>
            <span>否</span>
          </div>
        </template> -->
      </el-table-column>
      <el-table-column prop="status" label="状态">
        <!-- <div class="green">
          <span>启用</span>
        </div> -->
        <template slot-scope="scope">
          <span>{{ scope.row.status | type }}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column label="平台验证" width="80">
        <template slot-scope="scope">
          <div class="flex" v-if="scope.row.show">
            <span @click="closeIssynchronous(scope.row)">取消</span>
            <span @click="closeIssynchronous(scope.row)">保存</span>
          </div>
        </template>
      </el-table-column> -->
      <el-table-column label="操作" width="240">
        <template slot-scope="scope">
          <!-- <div class="flex">
            <span @click="openIssynchronous(scope.row)">同步</span>
            <span @click="openUpdate">编辑</span>
            <span @click="openIssynchronous">删除</span>
          </div> -->
          <el-button type="primary" size="mini" @click="openIssynchronous(scope.row)">同步</el-button>
          <el-button type="primary" size="mini" @click="openUpdate">编辑</el-button>
          <el-button type="danger" size="mini" @click="openIssynchronous">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    </div>
    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="query1.pageNum" background :page-size="query1.pageSize"
              layout="total, prev, pager, next, jumper" :total="query1.total"></el-pagination>
    <!-- 编辑 新增 更新 -->
    <Update v-if="showUpdate" @close="closeUpdate"></Update>
    <Add v-if="showAdd"></Add>
  </div>
</template>

<script>
import Update from './update.vue'
import Add from './add.vue'
export default {
  components: {
    Update,
    Add
  },
  data () {
    return {
      issynchronous: false,
      options: [{
        value: '',
        label: ''
      }],
      value: '',
      tableData: [],
      params: {
        jgbh: '',
        start: '0',
        length: '10',
        ssjgbh: '',
        lytype: '',
        pwzt: '',
        nickname: '',
        ptcode: '',
        phone: ''
      },
      // 分页数据
      query1: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      total: 400,
      currentPage: 0,
      showSynchronous: false,
      showUpdate: false,
      showAdd: false
    }
  },
  created () {
    this.params.jgbh = this.$store.state.loginInfo.jgbh || ''
    this.getList()
  },
  filters: {
    type (value) {
      if (value === 1) return '启用'
      return '未启用'
    }
  },
  methods: {
    async getList () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-181142907
      try {
        this.params.start = this.query1.pageNum === 1 ? '0' : (this.query1.pageNum - 1) * 10 + ''
        this.params.length = this.query1.pageSize + ''
        const { data: res } = await this.$http.get('/api/jkct/finduserlist', { params: this.params })
        console.log('用户列表', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.tableData = res.data
        this.query1.total = res.recordsTotal
      } catch (error) {
        console.log('用户列表 请求失败', error)
        // this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    async getListTwo () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-181142907
      try {
        this.params.start = this.query1.pageNum === 1 ? '0' : (this.query1.pageNum - 1) * 10 + ''
        this.params.length = this.query1.pageSize + ''
        const { data: res } = await this.$http.get('/api/jkct/finduserlist', { params: this.params })
        console.log('用户列表', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.tableData = res.data
        this.query1.total = res.recordsTotal
      } catch (error) {
        console.log('用户列表 请求失败', error)
        // this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    openIssynchronous (obj) {
      if (obj.status === 1) {
        obj.show = true
      } else {
        this.openSynchronous()
      }
    },
    closeIssynchronous (obj) {
      obj.show = false
    },
    openSynchronous () {
      this.showSynchronous = true
    },
    clsoeSynchronous () {
      this.showSynchronous = false
    },
    openUpdate () {
      this.showUpdate = true
    },
    closeUpdate () {
      this.showUpdate = false
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
      this.query1.pageNum = val
      this.getList()
    }
  }
}
</script>

<style lang="less" scoped>
.son {
  width: 100%;
  height: 100%;
  padding: 0 10px;
  background-color: #fff;
  box-sizing: border-box;

  .son-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 60px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    .title-box {
      height: 38px;
      line-height: 38px;
      padding: 0 9px;
      text-align: center;
      background-color: #409EFF;
      color: #fff;
      font-size: 22px;
    }

  }

  .son-search {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: 140px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    .son-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 70px;
      margin-right: 30px;

      .key {
        width: 110px;
        color: #000;
        font-size: 22px;
      }

      .el-input {
        width: 180px;
      }
    }
  }

  .add {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 70px;

    .icon {
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      border-radius: 50%;
      background-color: #409EFF;
      color: #fff;
      font-size: 20px;
      cursor: pointer;
    }
  }

  .table {
    width: 100%;
    height: calc(100% - 400px);

    /deep/.el-table__cell {
      text-align: center;
      color: #000;
    }

    .flex {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      width: 100%;
      color: #000;

      span {
        cursor: pointer;
      }

      .item {
        font-size: 14px;
        cursor: pointer;
      }

      .icon {
        color: #fff;
        font-size: 24px;
        cursor: pointer;
      }
    }
  }

  .green {
    color: #00FF88;
  }

  .organ {
    color: #FF6600;
  }

  .red {
    color: #FF0000;
  }

  // 页码样式修改
  .el-pagination {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
}
</style>
