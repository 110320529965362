<template>
  <div class="run">
    <div class="run-search">
      <div class="run-item">
        <div class="key">
          <span>老人姓名</span>
        </div>
        <el-input placeholder="请输入"></el-input>
      </div>
      <div class="run-item">
        <div class="key">
          <span>身份证号</span>
        </div>
        <el-input placeholder="请输入"></el-input>
      </div>
      <div class="run-item">
        <div class="key">
          <span>床位</span>
        </div>
        <el-select v-model="value" placeholder="请选择">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="run-item">
        <div class="key">
          <span>入住状态</span>
        </div>
        <el-select v-model="value" placeholder="请选择">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <el-button type="primary">搜索</el-button>
      <el-button type="primary">重置</el-button>
    </div>

    <div class="son-add">
      <div class="son-add-icon" @click="openAdd">
        <span class="el-icon-plus"></span>
      </div>
    </div>

    <div class="table">
      <el-table :data="tableData" height="100%" border>
      <el-table-column label="序号" type="index" width="100">
      </el-table-column>
      <el-table-column prop="name" label="头像">
      </el-table-column>
      <el-table-column prop="address" label="老人姓名">
      </el-table-column>
      <el-table-column prop="address" label="性别">
      </el-table-column>
      <el-table-column prop="address" label="身份证号">
      </el-table-column>
      <el-table-column prop="address" label="床位号">
      </el-table-column>
      <el-table-column prop="address" label="状态"></el-table-column>
      <el-table-column label="操作" width="440">
        <template>
          <!-- <div class="flex" v-if="scope.row.address === '未签约'">
            <span @click="openAdd">编辑</span>
            <span @click="openFileRun">档案管理</span>
            <span @click="openDelete">删除</span>
          </div>
          <div class="flex" v-else-if="scope.row.address === '预定中'">
            <span @click="openAdd">编辑</span>
            <span @click="openFileRun">档案管理</span>
          </div>
          <div class="flex" v-else-if="scope.row.address === '已入院'">
            <span @click="openAdd">编辑</span>
            <span @click="openFileRun">档案管理</span>
            <span @click="openSchedulRun">排班管理</span>
            <span @click="openTask">计划任务</span>
          </div> -->
          <el-button size="mini" type="primary" @click="openAdd">编辑</el-button>
          <el-button size="mini" type="primary" @click="openFileRun">档案管理</el-button>
          <el-button size="mini" type="primary" @click="openSchedulRun">排班管理</el-button>
          <el-button size="mini" type="primary" @click="openTask">计划任务</el-button>
          <el-button size="mini" type="danger" @click="openDelete">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    </div>

    <el-pagination @size-change="handleSizeChange" background @current-change="handleCurrentChange" :current-page="currentPage4"
      :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper" :total="400">
    </el-pagination>
    <Add v-if="showAdd" @close="closeAdd"></Add>
    <Set v-if="showSet" @close="closeSet"></Set>
    <Run v-if="showRun" @close="closeRun"></Run>
    <Delete v-if="showDelete" @close="closeDelete"></Delete>
    <!-- 排班管理 -->
    <SchedulRun v-if="showSchedulRun" @close="closeSchedulRun"></SchedulRun>
    <!-- 档案管理 -->
    <FileRun v-if="showFileRun" @close="closeFileRun"></FileRun>
    <!-- 计划任务 -->
    <Task v-if="showTask" @close="closeTask"></Task>
  </div>
</template>

<script>
import Add from './add.vue'
import Set from './set.vue'
import Run from './run.vue'
import Delete from './delete.vue'
import SchedulRun from './schedulRun.vue'
import FileRun from './fileRun.vue'
import Task from './task.vue'
export default {
  components: {
    Add,
    Set,
    Run,
    Delete,
    SchedulRun,
    FileRun,
    Task
  },
  data () {
    return {
      tableData: [{
        date: '2016-05-02',
        name: '王小虎',
        address: '未签约'
      }, {
        date: '2016-05-04',
        name: '王小虎',
        address: '预定中'
      }, {
        date: '2016-05-01',
        name: '王小虎',
        address: '已入院'
      }],
      options: [{
        value: '',
        label: ''
      }],
      value: '',
      currentPage4: 4,
      tabIndex: 0,
      selectIndex: 0,
      showAdd: false,
      showDelete: false,
      showSet: false,
      showRun: false,
      showSchedulRun: false,
      showFileRun: false,
      showTask: false
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    },
    openAdd () {
      this.showAdd = true
    },
    closeAdd () {
      this.showAdd = false
    },
    openDelete () {
      this.showDelete = true
    },
    closeDelete () {
      this.showDelete = false
    },
    openSet () {
      this.showSet = true
    },
    closeSet () {
      this.showSet = false
    },
    openRun () {
      this.showRun = true
    },
    closeRun () {
      this.showRun = false
    },
    openSchedulRun () {
      this.showSchedulRun = true
    },
    closeSchedulRun () {
      this.showSchedulRun = false
    },
    openFileRun () {
      this.showFileRun = true
    },
    closeFileRun () {
      this.showFileRun = false
    },
    openTask () {
      this.showTask = true
    },
    closeTask () {
      this.showTask = false
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.run {
  width: 100%;
  height: 100%;

  .son-tab {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    .son-item {
      height: 38px;
      line-height: 38px;
      padding: 0 20px;
      text-align: center;
      background-color: #284867;
      color: #fff;
      font-size: 16px;
      cursor: pointer;
    }

    .son-active {
      background-color: #04B4D0;
    }

    .son-item:first-child {
      margin-right: 40px;
    }
  }

  .son-tab1 {
    border: none;
  }

  .son-add {
    display: flex;
    align-items: center;
    height: 50px;

    .son-add-icon {
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      background-color: #409EFF;
      border-radius: 50%;
      color: #fff;
      font-size: 16px;
      cursor: pointer;
    }

    .son-set {
      width: 120px;
      height: 30px;
      line-height: 30px;
      margin-left: 30px;
      text-align: center;
      background-color: #04B4D0;
      color: #fff;
      font-size: 16px;
      cursor: pointer;
    }
  }

  .run-search {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    .run-item {
      display: flex;
      align-items: center;
      margin-right: 40px;

      .key {
        margin-right: 10px;
        white-space: nowrap;
        color: #000;
        font-size: 22px;
      }
    }
  }

  .table {
    width: 100%;
    height: calc(100% - 240px);
  }

  /deep/.el-table .cell {
    text-align: center;
    color: #000;
  }

  .flex {
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    span {
      cursor: pointer;
    }
  }

  .el-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
}
</style>
