<template>
  <div class="write">
    <div class="title">
      <span>停止用药</span>
    </div>
    <div class="content">
      <div @click="closePopup" class="close">
        <span class="el-icon-close"></span>
      </div>
      <div class="box">
        <div class="span">
          <span>停止用药时间</span>
          <el-date-picker type="date" placeholder="请选择">
        </el-date-picker>
        </div>
      </div>
      <div class="btns">
        <el-button @click="closePopup">取消</el-button>
        <el-button class="button">确认</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {}
  },
  methods: {
    closePopup () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.write {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 888;
  width: 100%;
  height: 100%;
  padding: 0 400px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, .9);

  .title {
    width: 260px;
    height: 53px;
    padding-top: 5px;
    box-sizing: border-box;
    line-height: 53px;
    text-align: center;
    background-image: url('../../../img/ssjk/title.png');
    background-size: 100% 100%;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
  }

  .content {
    position: relative;
    width: 100%;
    height: 360px;
    margin-top: 10px;
    padding: 20px 65px;
    background-image: url('../../../img/ssjk/background4.png');
    background-size: 100% 100%;
    box-sizing: border-box;

    .close {
      position: absolute;
      top: 14px;
      right: 20px;
      color: #fff;
      font-size: 20px;
      cursor: pointer;
    }

    .content-title {
      margin-top: 20px;
      color: #01EEFD;
      font-size: 18px;
    }

    .box {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
      height: calc(100% - 100px);
      color: #01EEFD;
      font-size: 18px;

      .span {
        display: flex;
        align-items: center;
        margin-top: 30px;

        span {
          white-space: nowrap;
          margin-right: 10px;
        }

        .el-input {
          width: 246px;
        }

        .img {
          width: 120px;
          height: 120px;
          background-color: #fff;
        }
      }

      .span1 {
        width: 66%;
      }
    }

    .btns {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 240px;
      height: 46px;
      margin: 0 auto;
      margin-top: 30px;

      .el-button {
        height: 30px;
        line-height: 30px;
        padding: 0 10px;
        text-align: center;
        color: #fff;
        font-size: 14px;
        background-color: #33465C;
        border-radius: 0;
        border: none;
      }

      .button {
        background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
      }
    }
  }
}
</style>
