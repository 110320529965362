<template>
  <div class="popup-message">
    <div class="popup-box">
      <div class="popup-head">
        <span>系统提示</span>
      </div>
      <div class="popup-tip">
        <span>导出任务已提交，正在执行，具体详情可点击导出列表查看</span>
      </div>
      <div class="popup-bottom">
        <div class="popup-left">
          <img :src="url" class="popup-img">
          <span>扫码关注，实时提醒进度！</span>
        </div>
        <el-button type="primary" @click="close">继续使用</el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      url: ''
    }
  },
  created () {
    this.url = this.$parent.picurl
  },
  methods: {
    close () {
      this.$parent.showPopup = false
    }
  }
}
</script>
<style lang="less" scoped>
.popup-message {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .7);

  .popup-box {
    width: 50%;
    height: 60%;
    background-color: #fff;
    border-radius: 12px;

    .popup-head {
      display: flex;
      align-items: center;
      width: 100%;
      height: 60px;
      padding: 20px;
      color: #000;
      font-size: 18px;
      ;
    }

    .popup-tip {
      width: 90%;
      height: 100px;
      line-height: 100px;
      margin: 20px auto;
      padding: 0 20px;
      text-align: center;
      border: 1px solid rgb(165, 165, 165);
      box-sizing: border-box;
      font-size: 16px;
      overflow: hidden;
      /*超出文本隐藏*/
      text-overflow: ellipsis;
      /*超出部分省略号显示 */
      white-space: nowrap;
    }

    .popup-bottom {
      display: flex;
      align-items: flex-end;
      justify-content: space-evenly;
      width: 100%;
      height: 200px;
      padding: 0 40px;
      box-sizing: border-box;

      .popup-left {
        display: flex;
        align-items: flex-end;
        font-size: 16px;

        .popup-img {
          width: 100px;
          height: 100px;
          margin-right: 20px;
          background-color: #fff;
        }
      }
    }
  }
}
</style>
