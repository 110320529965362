var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"look"},[_c('div',{staticClass:"look-box"},[_c('div',{staticClass:"close",on:{"click":_vm.close}},[_c('span',{staticClass:"el-icon-close"})]),_c('div',{staticClass:"look-content"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12),_c('div',{staticClass:"buttons"},[_c('el-button',{on:{"click":_vm.close}},[_vm._v("打印")]),_c('el-button',{on:{"click":_vm.close}},[_vm._v("保存")])],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info"},[_c('span',[_vm._v("编号：304076981")]),_c('span',[_vm._v("姓名：李明明")]),_c('span',[_vm._v("性别:男")]),_c('span',[_vm._v("身份证:130429193205164852")]),_c('span',[_vm._v("电话:17367759256")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"head"},[_c('div',{staticClass:"flex flex2"},[_c('span',[_vm._v("项目名称")])]),_c('div',{staticClass:"flex"},[_c('span',[_vm._v("结果")])]),_c('div',{staticClass:"flex"},[_c('span',[_vm._v("提示")])]),_c('div',{staticClass:"flex"},[_c('span',[_vm._v("单位")])]),_c('div',{staticClass:"flex"},[_c('span',[_vm._v("参考范围")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list"},[_c('div',{staticClass:"flex flex2"},[_c('span',[_vm._v("红细胞压积")])]),_c('div',{staticClass:"flex"},[_c('span',[_vm._v("36.2")])]),_c('div',{staticClass:"flex"},[_c('span')]),_c('div',{staticClass:"flex"},[_c('span',[_vm._v("%")])]),_c('div',{staticClass:"flex"},[_c('span',[_vm._v("39--48")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list"},[_c('div',{staticClass:"flex flex2"},[_c('span',[_vm._v("血红蛋白")])]),_c('div',{staticClass:"flex"},[_c('span',[_vm._v("36.2")])]),_c('div',{staticClass:"flex"},[_c('span')]),_c('div',{staticClass:"flex"},[_c('span',[_vm._v("g/L")])]),_c('div',{staticClass:"flex"},[_c('span',[_vm._v("110--160")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list"},[_c('div',{staticClass:"flex flex2"},[_c('span',[_vm._v("淋巴细胞比率")])]),_c('div',{staticClass:"flex"},[_c('span',[_vm._v("36.2")])]),_c('div',{staticClass:"flex"},[_c('span')]),_c('div',{staticClass:"flex"},[_c('span',[_vm._v("%")])]),_c('div',{staticClass:"flex"},[_c('span',[_vm._v("20--40")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list"},[_c('div',{staticClass:"flex flex2"},[_c('span',[_vm._v("平均血红蛋白量")])]),_c('div',{staticClass:"flex"},[_c('span',[_vm._v("36.2")])]),_c('div',{staticClass:"flex"},[_c('span')]),_c('div',{staticClass:"flex"},[_c('span',[_vm._v("pg")])]),_c('div',{staticClass:"flex"},[_c('span',[_vm._v("26--32")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list"},[_c('div',{staticClass:"flex flex2"},[_c('span',[_vm._v("平均血红蛋白浓度")])]),_c('div',{staticClass:"flex"},[_c('span',[_vm._v("310.8")])]),_c('div',{staticClass:"flex"},[_c('span')]),_c('div',{staticClass:"flex"},[_c('span',[_vm._v("g/L")])]),_c('div',{staticClass:"flex"},[_c('span',[_vm._v("320--450")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list"},[_c('div',{staticClass:"flex flex2"},[_c('span',[_vm._v("红细胞平均体积")])]),_c('div',{staticClass:"flex"},[_c('span',[_vm._v("96.2")])]),_c('div',{staticClass:"flex"},[_c('span')]),_c('div',{staticClass:"flex"},[_c('span',[_vm._v("fL")])]),_c('div',{staticClass:"flex"},[_c('span',[_vm._v("80--99")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list"},[_c('div',{staticClass:"flex flex2"},[_c('span',[_vm._v("平均血小板体积")])]),_c('div',{staticClass:"flex"},[_c('span',[_vm._v("8.6")])]),_c('div',{staticClass:"flex"},[_c('span')]),_c('div',{staticClass:"flex"},[_c('span',[_vm._v("fL")])]),_c('div',{staticClass:"flex"},[_c('span',[_vm._v("7.4--10.4")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list"},[_c('div',{staticClass:"flex flex2"},[_c('span',[_vm._v("血小板压积")])]),_c('div',{staticClass:"flex"},[_c('span',[_vm._v("0.08")])]),_c('div',{staticClass:"flex"},[_c('span')]),_c('div',{staticClass:"flex"},[_c('span',[_vm._v("fL")])]),_c('div',{staticClass:"flex"},[_c('span',[_vm._v("0.1--0.28")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list"},[_c('div',{staticClass:"flex flex2"},[_c('span',[_vm._v("血小板分布宽度")])]),_c('div',{staticClass:"flex"},[_c('span',[_vm._v("24.3")])]),_c('div',{staticClass:"flex"},[_c('span')]),_c('div',{staticClass:"flex"},[_c('span',[_vm._v("fL")])]),_c('div',{staticClass:"flex"},[_c('span',[_vm._v("10--17")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list"},[_c('div',{staticClass:"flex flex2"},[_c('span',[_vm._v("P-LCR")])]),_c('div',{staticClass:"flex"},[_c('span',[_vm._v("24.3")])]),_c('div',{staticClass:"flex"},[_c('span')]),_c('div',{staticClass:"flex"},[_c('span',[_vm._v("fL")])]),_c('div',{staticClass:"flex"},[_c('span',[_vm._v("10--17")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info info1"},[_c('span',[_vm._v("检查时间：2022.2.22")]),_c('span',[_vm._v("检验人员：马依依")]),_c('span',[_vm._v("机构：古城营镇卫生院")])])
}]

export { render, staticRenderFns }