<template>
  <div class="details">
    <div class="details-scroll">
      <div class="details-title">
        <span>任务详情</span>
      </div>
      <div class="details-title1">
        <span>任务信息</span>
      </div>
      <div class="details-box">
        <div class="details-item">
          <span class="key">任务名称</span>
          <span class="value">{{ info.rwmc }}</span>
        </div>
        <div class="details-item">
          <span class="key">任务状态</span>
          <span class="value">{{ info.rwzt }}</span>
        </div>
        <div class="details-item">
          <span class="key">负责人</span>
          <span class="value">{{ info.rwfzr }}</span>
        </div>
        <div class="details-item">
          <span class="key">任务时间</span>
          <span class="value">{{ info.rwsj }}</span>
        </div>
        <div class="details-item">
          <span class="key">任务描述</span>
          <span class="value">{{ info.rwms }}</span>
        </div>
      </div>
      <div class="details-title">
        <span>老人信息</span>
      </div>
      <div class="details-info">
        <img src="../../img/emr/user.png" class="details-info-left">
        <div class="details-info-right">
          <span>姓名：{{ info.name }}</span>
          <span>房间号：{{ info.fangj }}</span>
          <span>手机号：{{ info.lxdh }}</span>
        </div>
      </div>
      <div class="details-title">
        <span>子任务</span>
      </div>
      <el-checkbox-group v-model="checkList">
        <el-checkbox :label="index + 1 +''" v-for="(item, index) in info.zrwcode" :key="index">{{ item.zrwmc }}</el-checkbox>
      </el-checkbox-group>
      <div class="details-title">
        <span>老人信息</span>
      </div>
      <el-timeline>
        <el-timeline-item v-for="(item, index) in info.lnxx" :key="index" :timestamp="item.lnfwnr">
          {{ item.lnfwsj }}
        </el-timeline-item>
      </el-timeline>
    </div>
    <div class="details-btns">
      <el-button size="small" @click="close">清空</el-button>
      <el-button type="primary" size="small" @click="submit">提交</el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    sfzh: {
      type: String,
      default: ''
    },
    rwid: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      info: {
        rwmc: '',
        rwid: '',
        rwzt: '',
        rwztbm: '',
        rwfzr: '',
        rwfzrbm: '',
        rwms: '',
        rwsj: '',
        name: '',
        sfzh: '',
        fangj: '',
        chuangw: '',
        fjid: '',
        fjmc: '',
        cwid: '',
        cwmc: '',
        lcid: '',
        lcmc: '',
        lxdh: '',
        jlxx: '',
        xzjdmcid: '',
        xzjdmc: '',
        cwhmcid: '',
        cwhmc: '',
        ylzsbh: '',
        czbh: '',
        flag: '',
        bz1: '',
        bz2: '',
        ssjgbh: '',
        xb: '',
        age: '',
        zrwcode: [
          {
            zrwid: '',
            zrwmc: '',
            zrwbz: ''
          }
        ],
        lnxx: [
          {
            lnfwid: '',
            lnfwsj: '',
            lnfwnr: ''
          }
        ]
      },
      checkList: []
    }
  },
  created () {
    this.getInfo()
  },
  methods: {
    // 任务详情根据id获取
    async getInfo () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-100422195
      try {
        const obj = {
          sfzh: this.sfzh,
          rwid: this.rwid,
          ssjgbh: ''
        }
        const { data: res } = await this.$http.post('/zhyyapi/ykdylrwkb/find', obj)
        console.log('任务详情根据id获取', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.info = res.data
      } catch (error) {
        console.log('任务详情根据id获取请求失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    async submit () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-100420715
      try {
        const { data: res } = await this.$http.post('/zhyyapi/saveykdrwkb', this.info)
        console.log('任务详情保存', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.$message.success('保存成功')
        this.$emit('close', 'loading')
      } catch (error) {
        console.log('任务详情保存失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.details {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: #fff;
  box-sizing: border-box;

  .details-scroll {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 50px);

    &::-webkit-scrollbar {
      width: 0;
    }

    .details-title {
      width: 100%;
      height: 50px;
      line-height: 50px;
      padding-left: 10px;
      border-bottom: 1px solid #000;
      box-sizing: border-box;
      color: #000;
      font-size: 20px;
    }

    .details-title1 {
      width: 100%;
      height: 60px;
      line-height: 60px;
      color: #000;
      font-size: 20px;
    }

    .details-box {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 0 10px;
      box-sizing: border-box;

      .details-item {
        display: flex;
        align-items: center;
        width: 25%;
        margin-bottom: 16px;
        font-size: 20px;

        .key {
          color: #000;
        }

        .value {
          width: calc(100% - 100px);
          margin-left: 10px;
          color: #fff;
        }
      }
    }

    .details-info {
      display: flex;
      align-items: center;
      height: 137px;

      .details-info-left {
        width: 97px;
        height: 97px;
      }

      .details-info-right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 97px;
        margin-left: 16px;
        color: #000;
        font-size: 20px;
      }
    }

    .el-checkbox-group {
      display: flex;
      flex-direction: column;
      margin-left: 82px;

      /deep/.el-checkbox {
        margin-top: 20px;
      }

      /deep/.el-checkbox__inner {
        background-color: transparent;
      }

      /deep/.el-checkbox__label {
        color: #000;
        font-size: 20px;
      }
    }

    .el-timeline {
      margin-top: 20px;
      margin-left: 72px;

      /deep/.el-timeline-item__tail {
        left: 6px;
        border-left: 1px dashed #000;
      }

      /deep/.el-timeline-item__node {
        background-color: #081C38;
        border: 1px solid #000;
      }

      /deep/.el-timeline-item__content {
        color: #000;
        font-size: 20px;
      }

      /deep/.el-timeline-item__timestamp {
        color: #000;
        font-size: 20px;
      }
    }
  }

  .details-btns {
    display: flex;
    align-items: center;
    height: 50px;
  }

}
</style>
