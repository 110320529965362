var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-box"},[_vm._m(0),_c('el-input',{staticClass:"input",attrs:{"placeholder":"姓名/身份证号","suffix-icon":"el-icon-search"}}),_c('div',{staticClass:"tab-box"},[_c('div',{staticClass:"son"},[_c('div',{staticClass:"son-search"},[_c('div',{staticClass:"son-item"},[_vm._m(1),_c('el-input',{attrs:{"placeholder":"请输入执行人姓名"}})],1),_c('div',{staticClass:"son-item"},[_vm._m(2),_c('el-select',{attrs:{"placeholder":"请选择"},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},_vm._l((_vm.options),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1),_c('el-button',[_vm._v("搜索")]),_c('el-button',[_vm._v("重置")])],1),_c('div',{staticClass:"son-label"},[_c('div',{staticClass:"label-item",class:{ 'label-active': _vm.labelIndex === 0 },on:{"click":function($event){return _vm.labelClick(0)}}},[_c('span',[_vm._v("全部(100)")])]),_c('div',{staticClass:"label-item",class:{ 'label-active': _vm.labelIndex === 1 },on:{"click":function($event){return _vm.labelClick(1)}}},[_c('span',[_vm._v("进行中(100)")])]),_c('div',{staticClass:"label-item",class:{ 'label-active': _vm.labelIndex === 2 },on:{"click":function($event){return _vm.labelClick(2)}}},[_c('span',[_vm._v("已完成(100)")])]),_c('div',{staticClass:"label-item",class:{ 'label-active': _vm.labelIndex === 3 },on:{"click":function($event){return _vm.labelClick(3)}}},[_c('span',[_vm._v("预期未完成(100)")])])]),_c('div',{staticClass:"son-card"},_vm._l((20),function(item,index){return _c('div',{key:index,staticClass:"card-item",on:{"click":_vm.openTaskDetails}},[_vm._m(3,true),_vm._m(4,true),_vm._m(5,true)])}),0),(_vm.showTaskDetails)?_c('TaskDetails',{on:{"close":_vm.closeTaskDetails}}):_vm._e()],1)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tab"},[_c('span',{staticClass:"item active"},[_vm._v("任务看板")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"key"},[_c('span',[_vm._v("负责人")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"key"},[_c('span',[_vm._v("任务状态")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-line"},[_c('div',{staticClass:"card-text"},[_c('span',[_vm._v("点餐服务")])]),_c('img',{staticClass:"card-img",attrs:{"src":require("../../img/emr/user.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-line"},[_c('span',[_vm._v("进行中")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-line"},[_c('span',[_vm._v("截止日期:2022-08-03 18:00:00")])])
}]

export { render, staticRenderFns }