<template>
  <div class="son">
    <div class="son-head">
      <div class="son-head-item">
        <div class="son-head-left">
          <div class="son-head-open"></div>
          <el-input placeholder="请输入搜索内容"></el-input>
        </div>
        <div class="son-head-center">
          <span>切换机构：</span>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="son-head-right">
          <span class="el-icon-success success"></span>
          <div class="div">
            <div class="div1">
              <div class="blue"></div>
            </div>
          </div>
          <span class="el-icon-lock lock"></span>
          <span class="el-icon-view lock"></span>
          <span class="el-icon-rank rank"></span>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <!-- <div class="son-head-item">
        <div class="son-head-title">
          <span>预约管理</span>
        </div>
        <el-button type="primary">
          <span>更多</span>
          <span class="el-icon-arrow-down icon"></span>
        </el-button>
      </div> -->
    </div>
    <div class="son-scroll">
      <div class="son-search">
        <div class="search-item">
          <div class="key">
            <span>机构</span>
          </div>
          <el-select v-model="params.tjjggmc" placeholder="请选择" @change="gmChange">
            <el-option v-for="item in tjjggmcs" :key="item.tjjgbh" :label="item.tjjggmc" :value="item.tjjgbh">
            </el-option>
          </el-select>
        </div>
        <div class="search-item">
          <div class="key">
            <span>姓名</span>
            <div>
              <el-input v-model="params.name" placeholder="请输入"></el-input>
            </div>
          </div>
        </div>
        <div class="search-item button">
          <div class="key">
            <span>状态</span>
          </div>
          <el-select v-model="value" placeholder="预约人或证件号码">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="search-item">
          <div class="key">
            <span>登记类型</span>
          </div>
          <el-select v-model="value" placeholder="预约人或证件号码">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="search-item">
          <div class="key">
            <span>所属</span>
          </div>
          <el-select v-model="value" placeholder="预约人或证件号码">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>

        <div class="button">
          <el-button>
            <span class="el-icon-search"></span>
            <span>查询</span>
          </el-button>
          <el-button>
            <span class="el-icon-refresh"></span>
            <span>重置</span>
          </el-button>
          <el-button>导出</el-button>
          <el-button @click="openAdd">新增</el-button>
        </div>
      </div>

      <div class="son-info">
        <div class="table">
          <el-table :data="tableData" height="100%">
            <el-table-column prop="index" fixed="left" label="序号" width="70">
            </el-table-column>
            <el-table-column prop="name" label="姓名" width="100">
            </el-table-column>
            <el-table-column prop="csrq" label="出生日期" width="160">
            </el-table-column>
            <el-table-column prop="xzz" label="家庭住址" width="300">
            </el-table-column>
            <el-table-column prop="lxdh" label="联系电话" width="160">
            </el-table-column>
            <el-table-column prop="sfzh" label="身份证号码" width="160">
            </el-table-column>
            <el-table-column prop="ssxx" label="所属学校" width="160">
            </el-table-column>
            <el-table-column prop="ssbj" label="所属班级" width="160">
            </el-table-column>
            <el-table-column prop="zz" label="过敏史" width="160">
            </el-table-column>
            <el-table-column prop="zz" label="疾病史" width="160">
            </el-table-column>
            <el-table-column prop="zz" label="药物过敏" width="160">
            </el-table-column>
            <el-table-column prop="zz" label="家族病史" width="160">
            </el-table-column>
            <el-table-column prop="zz" label="体检结论" width="160">
            </el-table-column>
            <el-table-column prop="tjrq" label="体检时间" width="160">
            </el-table-column>
            <el-table-column prop="zz" label="体检医生" width="160">
            </el-table-column>
            <el-table-column prop="tjjggmc" label="体检机构" width="160">
            </el-table-column>
            <el-table-column label="操作" fixed="right" width="180">
              <template>
                <el-button type="primary" size="mini">编辑</el-button>
                <el-button type="primary" size="mini">查看</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="query.pageNum"
      background
      :page-size="query.pageSize"
      layout="total, prev, pager, next, jumper"
      :total="query.total"></el-pagination>
      </div>
    </div>
    <Add v-if="showAdd" @close="closeAdd"></Add>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Add from './add.vue'
export default {
  components: {
    Add
  },
  data () {
    return {
      options: [{
        value: '',
        label: ''
      }],
      value: '',
      tableData: [],
      total: 400,
      currentPage: 0,
      showSet: false,
      query: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      name: '',
      csrq: '',
      xzz: '',
      lxdh: '',
      sfzh: '',
      ssxxid: '',
      ssxx: '',
      ssbj: '',
      ssnjid: '',
      ssbjid: '',
      ssnj: '',
      tjrq: '',
      tjjggmc: '',
      tjjggmcs: [],
      tjjgbh: '',
      params: {
        tjjgbh: '',
        tjjggmc: '',
        name: '',
        start: '0',
        length: '10',
        jgjb: '4'
      },
      showAdd: false
    }
  },
  computed: {
    ...mapState(['loginInfo'])
  },
  created () {
    this.params.tjjgbh = this.loginInfo.jgbh || ''
    this.getInfo()
    this.getXxInfo()
  },
  methods: {
    async getInfo () {
      console.log(this.params)
      try {
        this.params.start = this.query.pageNum === 1 ? '0' : (this.query.pageNum - 1) * 10 + ''
        this.params.length = this.query.pageSize + ''
        const { data: res } = await this.$http.get('/ykdzhctxsdnbs', { params: this.params })
        console.log('档案列表', res)
        if (res.code !== 0 || res.data === null || res.data.length < 1) {
          return this.$message({
            message: res.msg || '暂无信息',
            type: 'warning'
          })
        }
        this.tableData = res.data
        this.query.total = res.recordsTotal
      } catch (err) {
        console.log('档案列表', err)
      }
    },
    async getXxInfo () {
      try {
        const { data: res } = await this.$http.get('/ykdjgxxs', { params: this.params })
        console.log('机构列表', res)
        if (res.code !== 0 || res.data === null) return
        this.tjjggmcs = res.data
        // this.xxs = [
        //   { ssxx: 'dd', ssxxid: '123' }
        // ]
      } catch (err) {
        console.log('机构列表', err)
      }
    },
    gmChange (e) {
      const arr = this.tjjggmcs.filter(item => item.tjjgbh === this.params.tjjgbh)
      this.tjjggmc = arr[0].jddw
      console.log(e, this.params.tjjgbh, this.tjjggmc)
    },
    tabClick (index) {
      this.tabIndex = index
    },
    openSet () {
      this.showSet = true
    },
    clsoeSet () {
      this.showSet = false
    },
    openAdd () {
      this.showAdd = true
    },
    closeAdd () {
      this.showAdd = false
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
      this.query.pageNum = val
      this.getInfo()
    }
  }
}
</script>

<style lang="less" scoped>
.son {
  width: 100%;
  height: 100%;
  background-color: #F0F0F0;

  .son-head {
    display: flex;
    align-items: center;
    width: 100%;
    height: 70px;
    margin-bottom: 20px;
    background-color: #fff;
    box-sizing: border-box;

    .son-head-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 0 14px;
      box-sizing: border-box;

      .son-head-left {
        display: flex;
        align-items: center;
        width: 33%;

        .son-head-open {
          width: 23px;
          height: 6px;
          border-top: 2px solid #595959;
          border-bottom: 2px solid #595959;
        }

        .el-input {
          margin-left: 50px;
        }

        /deep/.el-input__inner {
          border-color: #fff;
          font-size: 18px;
        }
      }

      .son-head-center {
        display: flex;
        align-items: center;
        flex: 1;
        color: #595959;
        font-size: 20px;

        /deep/.el-input__inner {
          font-size: 18px;
        }
      }

      .son-head-right {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex: 1;

        .success {
          color: #67C23A;
          font-size: 39px;
        }

        .lock {
          color: #595959;
          font-size: 30px;
        }

        .rank {
          font-size: 34px;
          transform: rotate(45deg);
        }

        .div {
          width: 41px;
          height: 40px;
          padding: 6px 7px;
          border: 1px solid #E6E6E6;
          border-radius: 6px;
          box-sizing: border-box;

          .div1 {
            width: 100%;
            height: 100%;
            padding-top: 3px;
            border: 1px solid #999999;
            box-sizing: border-box;

            .blue {
              width: 100%;
              height: 100%;
              background-color: #409EFF;
            }
          }
        }

        /deep/.el-input__inner {
          text-align: right;
          border: none;
          font-size: 18px;
        }
      }

      .son-head-title {
        width: 117px;
        height: 38px;
        line-height: 38px;
        text-align: center;
        border: 1px solid #409EFF;
        border-radius: 2px;
        box-sizing: border-box;
        color: #409EFF;
        font-size: 16px;
      }

      .el-button {
        width: 100px;

        .icon {
          margin-left: 10px;
        }
      }
    }
  }

  .son-scroll {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 90px);
    padding: 0 10px;
    box-sizing: border-box;

    &::-webkit-scrollbar {
      width: 0;
    }

    .son-search {
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;
      width: 100%;
      height: 130px;
      background-color: #fff;
      border-radius: 18px;
      box-sizing: border-box;

      .search-item {
        display: flex;
        align-items: center;
        width: calc(25% - 40px);

        .el-select {
          width: 220px;
        }

        /deep/.el-date-editor {
          width: 220px;
        }
      }

      .key {
        margin-right: 20px;
        color: #17212B;
        font-size: 22px;
      }

      .el-button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        margin-right: 20px;
        padding-top: 0;
        padding-bottom: 0;
        background-color: #3FA1FF;
        color: #fff;
        font-size: 22px;
      }

      .button {
        display: flex;
        align-items: center;
        width: calc(50% - 80px);
      }
    }

    .son-info {
      width: 100%;
      height: calc(100% - 150px);
      margin-top: 20px;
      padding: 0 20px;
      background-color: #fff;
      border-radius: 18px;
      box-sizing: border-box;

      .table {
        width: 100%;
        height: calc(100% - 90px);

        /deep/.el-table__cell {
          text-align: center;
          color: #000;
        }
      }

      // 页码样式修改
      .el-pagination {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
      }
    }
  }
}
</style>
