<template>
  <div class="schedule">
    <div class="schedule-box">
      <div class="schedule-head">
        <span class="el-icon-close close" @click="close"></span>
      </div>
      <div class="schedule-scroll">
        <el-table :data="tableData" border height="100%">
          <el-table-column type="id" width="70" label="序号">
          </el-table-column>
          <el-table-column prop="tjjggmc" label="机构">
          </el-table-column>
          <el-table-column prop="dcsj" label="导出时间">
          </el-table-column>
          <el-table-column prop="ssxx" label="学校名称">
          </el-table-column>
          <el-table-column prop="ssnj" label="年级">
          </el-table-column>
          <el-table-column prop="ssbj" label="班级">
          </el-table-column>
          <el-table-column prop="rwzrs" label="体检人数">
          </el-table-column>
          <el-table-column prop="rwcgrs" label="已完成人数">
          </el-table-column>
          <el-table-column label="进度">
            <template slot-scope="scope">
              <span>{{ scope.row.rwjdflag | jdType }}</span>
            </template>
          </el-table-column>
          <el-table-column label="状态">
            <template slot-scope="scope">
              <span>{{ scope.row.rwflag | ztType }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="180">
            <!-- slot-scope="scope" -->
            <template>
              <el-button type="primary" size="mini">查看</el-button>
              <el-button type="primary" size="mini">下载</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tableData: [],
      params: {
        start: '0',
        length: '10',
        tjjgbh: ''
      }
    }
  },
  filters: {
    jdType (val) {
      if (val === '0') return '正在执行'
      if (val === '1') return '执行成功'
      if (val === '99') return '执行失败'
      return '-'
    },
    ztType (val) {
      if (val === '0') return '正在执行'
      if (val === '1') return '执行失败'
      if (val === '2') return '执行成功'
      if (val === '3') return '手动停止'
      return '-'
    }
  },
  created () {
    this.getList()
  },
  methods: {
    async getList () {
      // 接口文档 https://app.apifox.com/project/2726972/apis/api-193594256
      try {
        const { data: res } = await this.$http.get('/yykzhctdcrws', { params: this.params })
        console.log('批量导出列表', res)
        if (res.code !== 0 || res.data === null) return
        this.tableData = res.data
        // this.query.total = res.recordsTotal
      } catch (err) {
        console.log('批量导出列表', err)
      }
    },
    close () {
      this.$parent.showList = false
    }
  }
}
</script>

<style lang="less" scoped>
.schedule {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .69);

  .schedule-box {
    width: 95%;
    height: 90%;
    padding: 0 30px;
    background-color: #fff;
    border-radius: 6px;
    box-sizing: border-box;

    .schedule-head {
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      width: 100%;
      height: 65px;
      border-bottom: 1px solid #B1B1B1;
      box-sizing: border-box;
      color: #2A374D;
      font-size: 18px;
      font-weight: 600;

      .close {
        color: #707070;
        font-size: 30px;
        cursor: pointer;
      }
    }

    .schedule-scroll {
      width: 100%;
      height: calc(100% - 90px);

      /deep/.el-table__cell {
        text-align: center;
      }
    }
  }
}
</style>
