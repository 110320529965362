<template>
  <div class="report">
    <div class="content">
      <div class="title">
        <span>学生健康检查表</span>
        <span class="close el-icon-close" @click="close"></span>
      </div>
      <div class="info">
        <span>检查时间：2021-1-4</span>
        <span>学号：15111301108</span>
      </div>
      <div class="scroll">
        <table border cellpadding="0" cellspacing="0" class="table">
          <tr>
            <td class="td" colspan="1">
              <span>姓名</span>
            </td>
            <td class="td" colspan="2">
              <span>XXX</span>
            </td>
            <td class="td" colspan="1">
              <span>性别</span>
            </td>
            <td class="td" colspan="2">
              <span>女</span>
            </td>
            <td class="td" colspan="2">
              <span>生日:XXXX.X.XX</span>
            </td>
            <td class="td" colspan="1">
              <span>婚否</span>
            </td>
            <td class="td" colspan="1">
              <span>未婚</span>
            </td>
            <td class="td1" colspan="1" rowspan="7">
              <img class="image" />
            </td>
          </tr>
          <tr>
            <td class="td" colspan="1">
              <span>学校</span>
            </td>
            <td class="td" colspan="2">
              <span>教育科学学院</span>
            </td>
            <td class="td" colspan="1">
              <span>专业</span>
            </td>
            <td class="td" colspan="2">
              <span>学前教育</span>
            </td>
            <td class="td" colspan="1">
              <span>班级</span>
            </td>
            <td class="td" colspan="3">
              <span>15级3班</span>
            </td>
          </tr>
          <tr>
            <td class="td" colspan="1">
              <span>文化程度</span>
            </td>
            <td class="td" colspan="2">
              <span>本科</span>
            </td>
            <td class="td" colspan="1">
              <span>民族</span>
            </td>
            <td class="td" colspan="2">
              <span>汉族</span>
            </td>
            <td class="td" colspan="1">
              <span>职业</span>
            </td>
            <td class="td" colspan="3">
              <span>学生</span>
            </td>
          </tr>
          <tr>
            <td class="td" colspan="1">
              <span>籍贯</span>
            </td>
            <td class="td" colspan="5">
              <span>XXXXXXXXXXXXXX</span>
            </td>
            <td class="td" colspan="1">
              <span>联系电话</span>
            </td>
            <td class="td" colspan="3">
              <span>XXXXXXXXXXX</span>
            </td>
          </tr>
          <tr>
            <td class="td" colspan="1">
              <span>毕业学校</span>
            </td>
            <td class="td" colspan="9">
              <span>XXXXXXXXXXXXXX</span>
            </td>
          </tr>
          <tr>
            <td class="td" colspan="1">
              <span>既往病史</span>
            </td>
            <td class="td" colspan="9">
              <span>无</span>
            </td>
          </tr>
          <tr>
            <td class="td" colspan="1">
              <span>毕业学校</span>
            </td>
            <td class="td" colspan="9">
              <span>XXXXXXXXXXXXXX</span>
            </td>
          </tr>
          <tr>
            <td class="td" colspan="1" rowspan='2'>
              <span>形态机能</span>
            </td>
            <td class="td" colspan="2">
              <span>身高(cm)</span>
            </td>
            <td class="td" colspan="2">
              <span>161</span>
            </td>
            <td class="td" colspan="2">
              <span>体重(kg)</span>
            </td>
            <td class="td" colspan="3">
              <span>54</span>
            </td>
            <td class="td1" colspan="1" rowspan="2">
              <span></span>
            </td>
          </tr>
          <tr>
            <td class="td" colspan="2">
              <span>体重指数(BMH)</span>
            </td>
            <td class="td" colspan="7">
              <span>正常</span>
            </td>
          </tr>
          <tr>
            <td class="td" colspan="1" rowspan='7'>
              <span>外科</span>
            </td>
            <td class="td" colspan="2">
              <span>皮肤</span>
            </td>
            <td class="td" colspan="5">
              <span>正常</span>
            </td>
            <td class="td" colspan="2">
              <span>建议</span>
            </td>
            <td class="td1" colspan="1" rowspan="7">
              <span>王春华</span>
            </td>
          </tr>
          <tr>
            <td class="td" colspan="2">
              <span>淋巴结</span>
            </td>
            <td class="td" colspan="5">
              <span>正常</span>
            </td>
            <td class="td" colspan="2" rowspan="6">
              <span>合格</span>
            </td>
          </tr>
          <tr>
            <td class="td" colspan="2">
              <span>头部</span>
            </td>
            <td class="td" colspan="5">
              <span>正常</span>
            </td>
          </tr>
          <tr>
            <td class="td" colspan="2">
              <span>颈部</span>
            </td>
            <td class="td" colspan="5">
              <span>正常</span>
            </td>
          </tr>
          <tr>
            <td class="td" colspan="2">
              <span>胸部</span>
            </td>
            <td class="td" colspan="5">
              <span>正常</span>
            </td>
          </tr>
          <tr>
            <td class="td" colspan="2">
              <span>脊柱</span>
            </td>
            <td class="td" colspan="5">
              <span>正常</span>
            </td>
          </tr>
          <tr>
            <td class="td" colspan="2">
              <span>四肢</span>
            </td>
            <td class="td" colspan="5">
              <span>正常</span>
            </td>
          </tr>
          <tr>
            <td class="td" colspan="1" rowspan='5'>
              <span>内科</span>
            </td>
            <td class="td" colspan="2">
              <span>血压(mmHg)</span>
            </td>
            <td class="td" colspan="5">
              <span>121/73</span>
            </td>
            <td class="td" colspan="2">
              <span>建议</span>
            </td>
            <td class="td1" colspan="1" rowspan="5">
              <span>刘东</span>
            </td>
          </tr>
          <tr>
            <td class="td" colspan="2">
              <span>心率(次/分)</span>
            </td>
            <td class="td td2" colspan="5">
              <div class="flex">
                <span>80次/分</span>
                <span>律(齐√、不齐)</span>
                <span>无杂音</span>
              </div>
            </td>
            <td class="td" colspan="2" rowspan="4">
              <span>合格</span>
            </td>
          </tr>
          <tr>
            <td class="td" colspan="2">
              <span>心肺听诊</span>
            </td>
            <td class="td" colspan="5">
              <span>正常</span>
            </td>
          </tr>
          <tr>
            <td class="td" colspan="2">
              <div class="flex">
                <span>腹部</span>
                <span>(肝脾触诊)</span>
              </div>
            </td>
            <td class="td" colspan="5">
              <span>正常</span>
            </td>
          </tr>
          <tr>
            <td class="td" colspan="2">
              <span>神经系统检查</span>
            </td>
            <td class="td" colspan="5">
              <span>正常</span>
            </td>
          </tr>

          <tr>
            <td class="td" colspan="1" rowspan='4'>
              <span>眼科</span>
            </td>
            <td class="td" colspan="2" rowspan='2'>
              <span>视力</span>
            </td>
            <td class="td" colspan="1" rowspan='2'>
              <span>裸眼</span>
            </td>
            <td class="td" colspan="1">
              <span>左：5.0</span>
            </td>
            <td class="td" colspan="3" rowspan='2'>
              <span>矫正</span>
            </td>
            <td class="td" colspan="2">
              <span>左：5.0</span>
            </td>
            <td class="td1" colspan="1" rowspan='2'>
              <span>李锐</span>
            </td>
          </tr>
          <tr>
            <td class="td" colspan="1">
              <span>右：5.0</span>
            </td>
            <td class="td" colspan="2">
              <span>右：5.0</span>
            </td>
          </tr>
          <tr>
            <td class="td" colspan="2">
              <span>色觉</span>
            </td>
            <td class="td" colspan="2">
              <span>正常:√</span>
            </td>
            <td class="td" colspan="3">
              <span>色弱:无</span>
            </td>
            <td class="td" colspan="2">
              <span>色盲:无</span>
            </td>
            <td class="td1" colspan="1">
              <span>王强</span>
            </td>
          </tr>
          <tr>
            <td class="td" colspan="2">
              <span>眼病</span>
            </td>
            <td class="td" colspan="2">
              <span>沙眼:正常</span>
            </td>
            <td class="td" colspan="3">
              <span>结膜炎:正常</span>
            </td>
            <td class="td" colspan="2">
              <span>其他:</span>
            </td>
            <td class="td1" colspan="1">
              <span>王强</span>
            </td>
          </tr>

          <tr>
            <td class="td" colspan="1">
              <span>口腔</span>
            </td>
            <td class="td" colspan="2">
              <span>龋齿:</span>
            </td>
            <td class="td" colspan="2">
              <span>牙垢:正常</span>
            </td>
            <td class="td" colspan="3">
              <span>牙周:正常</span>
            </td>
            <td class="td" colspan="2">
              <span>唇腭:正常</span>
            </td>
            <td class="td1" colspan="1">
              <span>李锐</span>
            </td>
          </tr>

          <tr>
            <td class="td" colspan="1">
              <span>耳鼻咽喉</span>
            </td>
            <td class="td" colspan="2">
              <span>听力</span>
            </td>
            <td class="td" colspan="1">
              <span>正常</span>
            </td>
            <td class="td" colspan="1">
              <span>嗅觉</span>
            </td>
            <td class="td" colspan="2">
              <span>正常</span>
            </td>
            <td class="td" colspan="1">
              <span>咽喉</span>
            </td>
            <td class="td" colspan="2">
              <span>正常</span>
            </td>
            <td class="td1" colspan="1">
              <span>李锐</span>
            </td>
          </tr>

          <tr>
            <td class="td" colspan="1">
              <span>实验室检查</span>
            </td>
            <td class="td" colspan="2">
              <span>肝功能</span>
            </td>
            <td class="td" colspan="2">
              <span>正常</span>
            </td>
            <td class="td" colspan="3">
              <span>结核菌素实验</span>
            </td>
            <td class="td" colspan="2">
              <span>正常</span>
            </td>
            <td class="td1" colspan="1">
              <span>李锐</span>
            </td>
          </tr>

          <tr>
            <td class="td" colspan="1">
              <span>胸透</span>
            </td>
            <td class="td" colspan="9">
              <span>合格</span>
            </td>
            <td class="td1" colspan="1">
              <span></span>
            </td>
          </tr>

          <tr>
            <td class="td" colspan="1">
              <span>体检结论</span>
            </td>
            <td class="td" colspan="10">
              <div class="flex">
                <span>合格</span>
                <span class="span">体检医院（盖章）</span>
              </div>
            </td>
          </tr>
          <tr>
            <td class="td" colspan="1">
              <span>备注</span>
            </td>
            <td class="td" colspan="10">
              <span>无</span>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {

    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.report {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 888;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .8);

  .content {
    overflow: hidden;
    width: 70%;
    height: 99%;
    padding: 0 10px;
    border-radius: 10px;
    background-color: #fff;
    box-sizing: border-box;
  }

  .title {
    position: relative;
    width: 100%;
    height: 60px;
    line-height: 60px;
    text-align: center;
    font-size: 30px;

    .close {
      position: absolute;
      top: 20%;
      right: 20px;
      cursor: pointer;
      font-size: 24px;
    }
  }

  .info {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    font-size: 16px;
  }

  .scroll {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 100px);

    &::-webkit-scrollbar {
      width: 0;
    }

    .table {
      width: 100%;

      tr {
        width: 100%;
      }

      .td {
        padding: 10px 0;
        text-align: center;
        font-size: 16px;
      }

      .td1 {
        min-width: 100px;
        text-align: center;
        font-size: 16px;

        .image {
          width: 90%;
          min-height: 260px;
        }
      }

      .td2 {
        text-align: right;
      }

      .flex {
        display: flex;
        flex-direction: column;

        span {
          width: 100%;
          text-align: center;
        }

        .span {
          text-align: right;
        }
      }
    }
  }
}
</style>
