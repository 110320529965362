<template>
  <div class="son">
    <div class="son-head">
      <div class="son-head-item">
        <div class="son-head-left">
          <div class="son-head-open"></div>
          <el-input placeholder="请输入搜索内容"></el-input>
        </div>
        <div class="son-head-center">
          <span>切换机构：</span>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="son-head-right">
          <span class="el-icon-success success"></span>
          <div class="div">
            <div class="div1">
              <div class="blue"></div>
            </div>
          </div>
          <span class="el-icon-lock lock"></span>
          <span class="el-icon-view lock"></span>
          <span class="el-icon-rank rank"></span>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div class="son-box">
      <div class="son-content">
        <div class="son-content-left">
          <div class="content">
            <el-input placeholder="姓名/身份证号" suffix-icon="el-icon-search" size="small">
            </el-input>
            <div class="scroll">
              <div class="scroll-item" v-for="(item, index) in 10" :key="index">
                <div class="scroll-item-line">
                  <div class="scroll-text">
                    <span>序号：01</span>
                  </div>
                  <div class="scroll-text">
                    <span>姓名：李明明</span>
                  </div>
                </div>
                <div class="scroll-item-line">
                  <div class="scroll-text">
                    <span>年龄：88</span>
                  </div>
                  <div class="scroll-text">
                    <span>性别：男</span>
                  </div>
                </div>
                <div class="scroll-item-line">
                  <div class="scroll-text scroll-text2">
                    <span>体检编号：</span>
                    <span class="label">已做</span>
                  </div>
                </div>
                <div class="scroll-item-line">
                  <div class="scroll-text scroll-text1">
                    <span>所属机构：古城营镇卫生院</span>
                  </div>
                </div>
                <div class="scroll-item-line">
                  <div class="scroll-text scroll-text1">
                    <span>身份证号：130730199808238651</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="son-content-right">
          <div class="son-content-head">
            <el-button size="mini">模板信息</el-button>
            <el-button size="mini">采集图像</el-button>
            <el-button size="mini">保存数据</el-button>
          </div>
          <div class="son-content-body">
            <div class="top">
              <div class="top-left">
                <div class="top-left-item top-left-item1">
                  <div class="top-left-title">
                    <span>临床诊断</span>
                  </div>
                  <div class="top-left-textarea">
                    <el-input type="textarea" v-model="textarea">
                    </el-input>
                  </div>
                </div>
                <div class="top-left-item">
                  <div class="top-left-title">
                    <span>超声提示</span>
                  </div>
                  <div class="top-left-textarea"></div>
                </div>
                <div class="top-left-item">
                  <div class="top-left-title">
                    <span>医生建议</span>
                  </div>
                  <div class="top-left-textarea"></div>
                </div>
              </div>
              <div class="top-right">
                <div class="top-right-box"></div>
              </div>
            </div>
            <div class="bottom">
              <div class="title">
                <span>后台图像/当前图像</span>
              </div>
              <div class="box">
                <div class="box-item" v-for="(item, index) in 3" :key="index" :class="{ 'box-active': index === tabIndex }" @click="tabClick(index)">
                  <div class="box-item-box">
                    <div class="box-item-img"></div>
                    <el-select v-model="value" placeholder="">
                      <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                      </el-option>
                    </el-select>
                  </div>
                  <div class="box-item-text">
                    <span>{{ index + 1 }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      options: [{
        value: '',
        label: ''
      }],
      value: '',
      tabIndex: 0,
      textarea: '肝脏大小形态可，被摸光滑连续，实质回声均匀，肝内血管纹理显示清晰，门静脉未见增宽。胆囊大小形态可，壁光滑，内透声可，肝内外胆管未见明显扩张。'
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    }
  }
}
</script>

<style lang="less" scoped>
.son {
  width: 100%;
  height: 100%;
  background-color: #F0F0F0;

  .son-head {
    display: flex;
    align-items: center;
    width: 100%;
    height: 70px;
    margin-bottom: 10px;
    background-color: #fff;
    box-sizing: border-box;

    .son-head-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 0 14px;
      box-sizing: border-box;

      .son-head-left {
        display: flex;
        align-items: center;
        width: 33%;

        .son-head-open {
          width: 23px;
          height: 6px;
          border-top: 2px solid #595959;
          border-bottom: 2px solid #595959;
        }

        .el-input {
          margin-left: 50px;
        }

        /deep/.el-input__inner {
          border-color: #fff;
          font-size: 18px;
        }
      }

      .son-head-center {
        display: flex;
        align-items: center;
        flex: 1;
        color: #595959;
        font-size: 20px;

        /deep/.el-input__inner {
          font-size: 18px;
        }
      }

      .son-head-right {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex: 1;

        .success {
          color: #67C23A;
          font-size: 39px;
        }

        .lock {
          color: #595959;
          font-size: 30px;
        }

        .rank {
          font-size: 34px;
          transform: rotate(45deg);
        }

        .div {
          width: 41px;
          height: 40px;
          padding: 6px 7px;
          border: 1px solid #E6E6E6;
          border-radius: 6px;
          box-sizing: border-box;

          .div1 {
            width: 100%;
            height: 100%;
            padding-top: 3px;
            border: 1px solid #999999;
            box-sizing: border-box;

            .blue {
              width: 100%;
              height: 100%;
              background-color: #409EFF;
            }
          }
        }

        /deep/.el-input__inner {
          text-align: right;
          border: none;
          font-size: 18px;
        }
      }

      .son-head-title {
        width: 117px;
        height: 38px;
        line-height: 38px;
        text-align: center;
        border: 1px solid #409EFF;
        border-radius: 2px;
        box-sizing: border-box;
        color: #409EFF;
        font-size: 16px;
      }

      .el-button {
        width: 100px;

        .icon {
          margin-left: 10px;
        }
      }
    }
  }

  .son-box {
    width: 100%;
    height: calc(100% - 90px);
    padding: 0 10px;
    box-sizing: border-box;

    .son-content {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 10px;
      background-color: #fff;
      border-radius: 18px;
      box-sizing: border-box;

      .son-content-left {
        width: 290px;
        height: calc(100% - 20px);
        border-right: 1px solid #707070;
        box-sizing: border-box;

        .content {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 280px;
          height: 100%;
          padding: 0 9px;
          padding-top: 18px;
          border-radius: 6px;
          background-color: #25A0FF;
          box-sizing: border-box;

          .el-input {
            width: 100%;

            /deep/.el-input__inner {
              background-color: #fff;
              border-radius: 6px;
              border: 1px solid #1F72D3;
            }

            /deep/.el-input__inner::placeholder {
              color: #999999;
            }

            /deep/.el-input__suffix {
              color: #999999;
            }
          }

          .scroll {
            overflow-y: scroll;
            width: 100%;
            height: calc(100% - 60px);
            margin-top: 10px;

            &::-webkit-scrollbar {
              width: 0;
            }

            // &::-webkit-scrollbar {
            //   width: 6px;
            //   height: 6px;
            //   background-color: transparent;
            //   border-radius: 3px;
            // }

            // &::-webkit-scrollbar-thumb {
            //   background-color: rgba(255, 255, 255, .4);
            //   border-radius: 10px;
            // }

            // &::-webkit-scrollbar-corner {
            //   background-color: transparent;
            // }

            .scroll-item {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              width: 100%;
              height: 136px;
              margin-bottom: 10px;
              padding: 10px;
              background: #fff;
              box-sizing: border-box;

              .scroll-item-line {
                display: flex;
                justify-content: space-between;
                width: 100%;

                .scroll-text {
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  width: 50%;
                  color: #333333;
                  font-size: 14px;
                }

                .scroll-text1 {
                  width: 100%;
                }

                .scroll-text2 {
                  display: flex;
                  justify-content: space-between;
                  width: 100%;
                }

                .label {
                  padding: 1px 5px;
                  background-color: #09B008;
                  color: #fff;
                  font-size: 14px;
                }
              }
            }
          }
        }
      }

      .son-content-right {
        width: calc(100% - 300px);
        height: 100%;
        margin-left: 10px;

        .son-content-head {
          display: flex;
          align-items: center;
          width: 100%;
          height: 45px;
          padding-left: 7px;
          box-sizing: border-box;

          .el-button {
            margin-left: 0;
            margin-right: 20px;
            border: 0;
            border-radius: 0;
            background-color: #25A0FF;
            color: #fff;
            font-size: 16px;
          }
        }

        .son-content-body {
          width: 100%;
          height: calc(100% - 45px);
          padding: 0 5px;
          padding-bottom: 5px;
          background-color: #25A0FF;
          box-sizing: border-box;

          .top {
            display: flex;
            width: 100%;
            height: calc(100% - 180px);

            .top-left {
              display: flex;
              flex-direction: column;
              width: 330px;
              height: 100%;

              .top-left-item {
                width: 100%;
                height: 25%;

                .top-left-title {
                  width: 100%;
                  height: 40px;
                  line-height: 40px;
                  color: #fff;
                  font-size: 20px;
                }

                .top-left-textarea {
                  width: 100%;
                  height: calc(100% - 40px);
                  padding: 10px;
                  background-color: #fff;
                  box-sizing: border-box;

                  .el-textarea {
                    width: 100%;
                    height: 100%;
                  }

                  /deep/.el-textarea__inner {
                    width: 100%;
                    height: 100%;
                    padding: 0;
                    text-indent: 2em;
                    border: none;
                    color: #999999;
                    font-size: 16px;
                    resize: none;

                    &::-webkit-scrollbar {
                      width: 0;
                    }
                  }
                }
              }

              .top-left-item1 {
                height: 50%;
              }
            }

            .top-right {
              width: calc(100% - 340px);
              height: 100%;
              margin-left: 10px;
              padding: 30px;
              background-color: #272727;
              box-sizing: border-box;

              .top-right-box {
                width: 100%;
                height: 100%;
                background-color: #000;
              }
            }
          }

          .bottom {
            width: 100%;
            height: 180px;

            .title {
              width: 100%;
              height: 40px;
              line-height: 40px;
              color: #fff;
              font-size: 16px;
            }

            .box {
              overflow-x: scroll;
              width: 100%;
              height: 140px;
              padding: 10px;
              background-color: #fff;
              box-sizing: border-box;

              &::-webkit-scrollbar {
                width: 0;
                height: 0;
              }

              .box-item {
                display: inline-block;
                width: 110px;
                height: 100%;
                margin-right: 10px;
                cursor: pointer;

                .box-item-box {
                  display: flex;
                  flex-direction: column;
                  justify-content: space-evenly;
                  width: 110px;
                  height: 100px;
                  padding: 0 5px;
                  background-color: #CACACA;
                  box-sizing: border-box;

                  .box-item-img {
                    width: 100%;
                    height: 70px;
                    background-color: #000;
                  }

                  /deep/.el-input {
                    display: flex;
                    align-items: center;
                  }

                  /deep/.el-input__inner {
                    height: 20px;
                    line-height: 20px;
                    padding-left: 0;
                    font-size: 14px;
                  }

                  /deep/.el-input__suffix {
                    top: 50%;
                  }
                }

                .box-item-text {
                  width: 100%;
                  height: 20px;
                  line-height: 20px;
                  text-align: center;
                  color: #333333;
                  font-size: 16px;
                }
              }

              .box-active {
                .box-item-box {
                  background-color: #25A0FF;
                }

                .box-item-text {
                  color: #25A0FF;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
