<template>
  <div class="run">
    <el-tree :data="data" show-checkbox node-key="id" default-expand-all :expand-on-click-node="false">
      <span class="custom-tree-node" slot-scope="{ node, data }">
        <span class="tree-label">{{ node.label }}</span>
        <span class="icons" v-if="data.show != 'hide'">
          <span class="icon el-icon-circle-plus" @click="() => append(data, node)"></span>
          <span class="icon el-icon-edit" @click="() => update(node, data)"></span>
          <span class="icon el-icon-delete-solid" @click="() => remove(node, data)"></span>
        </span>
      </span>
      <span>新增</span>
    </el-tree>
    <Update v-if="showUpdate" @close="closeUpdate"></Update>
  </div>
</template>

<script>
import Update from './update.vue'
export default {
  components: {
    Update
  },
  data () {
    return {
      id: 10,
      data: [
        {
          id: 1,
          label: '无锡市梅村养老院',
          show: 'hide',
          children: [{
            id: 11,
            label: '人事部'
          }, {
            id: 12,
            label: '人事部'
          }, {
            id: 13,
            label: '销售部'
          }, {
            id: 14,
            label: '市场部'
          }]
        }
      ],
      defaultProps: {
        children: 'children',
        label: 'label',
        show: 'show'
      },
      selectIndex: 0,
      showUpdate: false
    }
  },
  methods: {
    append (data) {
      const newChild = { id: this.id++, label: 'testtest', children: [] }
      // console.log(newChild)
      if (!data.children) {
        this.$set(this.data, 'children', [])
      }
      data.children.push(newChild)
    },

    update (node, data) {
      // console.log(node, data)
      const parent = node.parent
      const children = parent.data.children || parent.data
      const index = children.findIndex(d => d.id === data.id)
      // console.log(index)
      this.selectIndex = index
      this.showUpdate = true
    },

    remove (node, data) {
      this.$alert('是否确认删除该部门,删除后将不可恢复', '删除确认', {
        confirmButtonText: '确定',
        callback: action => {
          const parent = node.parent
          const children = parent.data.children || parent.data
          const index = children.findIndex(d => d.id === data.id)
          children.splice(index, 1)
        }
      })
      // this.$alert('该部门关联了若干员工,不支持删除操作', '删除确认', {
      //   confirmButtonText: '确定',
      //   callback: action => {
      //     const parent = node.parent
      //     const children = parent.data.children || parent.data
      //     const index = children.findIndex(d => d.id === data.id)
      //     children.splice(index, 1)
      //   }
      // })
    },

    closeUpdate (e) {
      console.log(e)
      if (e) {
        this.data[0].children[this.selectIndex].label = e
      }
      this.showUpdate = false
    }
  }
}
</script>

<style lang="less" scoped>
.run {
  width: 100%;
  height: 100%;

  .el-tree {
    background-color: transparent;
    color: #000;
    font-size: 18px;

    .el-button {
      padding-top: 4px;
      padding-bottom: 4px;
      color: #000;
      font-size: 16px;
      border-bottom: 1px solid #000;
      box-sizing: border-box;

      span {
        text-decoration: dashed;
      }
    }

    /deep/.el-tree-node__content:hover {
      background-color: transparent;
    }

    /deep/.el-tree-node:focus>.el-tree-node__content {
      background-color: transparent;
    }

    .tree-label {
      display: inline-block;
      width: 140px;
    }
  }

  .tree-button {
    margin-left: 50px;
  }

  .tree-button1 {
    margin-left: 32px;
  }

  /deep/.el-checkbox__inner {
    background-color: transparent;
  }

  /deep/.tree-label {
    width: auto !important;
  }

  .icons {
    // display: flex;
    // flex-direction: row;
    // align-items: center;
  }

  .icon {
    margin-left: 11px;
    color: #000;
  }

  .button {
    position: absolute;
    bottom: 0;
    left: 0;
    border: none;
    background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
    color: #000;
  }
}
</style>
