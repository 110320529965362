<template>
  <div class="son">
    <div class="son-title">
      <span class="title-box">长者专档</span>
      <el-input class="postion" placeholder="姓名/身份证号" suffix-icon="el-icon-search">
      </el-input>
    </div>
    <div class="son-search">
      <div class="son-item">
        <el-input placeholder="省"></el-input>
      </div>
      <div class="son-item">
        <el-select v-model="value" placeholder="省">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="son-item">
        <el-select v-model="value" placeholder="请选择区县">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="son-item">
        <el-select v-model="value" placeholder="请选择街道/镇">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="son-item">
        <el-select v-model="value" placeholder="请选择社区/村">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="son-item">
        <el-input placeholder="请输入长者姓名"></el-input>
      </div>
      <div class="son-item">
        <el-input placeholder="请输入手机号"></el-input>
      </div>
      <div class="son-item">
        <el-select v-model="value" placeholder="是否健在">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="son-item">
        <div class="add" @click="openAdd">
          <span class="el-icon-plus"></span>
        </div>
      </div>

      <el-button>搜索</el-button>
      <el-button>导出</el-button>
      <el-button class="red">删除</el-button>
      <el-button>重置</el-button>
      <el-button>模板导入</el-button>
      <el-button>模板下载</el-button>
    </div>
    <div class="son-table">
      <el-table :data="tableData" border height="100%">
        <el-table-column type="selection" width="55">
        </el-table-column>
        <el-table-column type="index" label="长者编号" width="100">
        </el-table-column>
        <el-table-column prop="name" label="姓名">
        </el-table-column>
        <el-table-column prop="date1" label="性别">
        </el-table-column>
        <el-table-column prop="date" label="年龄">
        </el-table-column>
        <el-table-column prop="date" label="身份证">
        </el-table-column>
        <el-table-column prop="date1" label="联系方式">
        </el-table-column>
        <el-table-column prop="date1" label="固定电话">
        </el-table-column>
        <el-table-column prop="date1" label="出生日期">
        </el-table-column>
        <el-table-column prop="date1" label="户籍地址">
        </el-table-column>
        <el-table-column label="操作" width="420">
          <!-- slot-scope="scope" -->
          <!-- <template>
            <div class="flex">
              <span @click="openBind">绑定</span>
              <span @click="openSee">查看</span>
              <span>视频通话</span>
              <span>删除</span>
              <span>修改</span>
            </div>
          </template> -->
          <el-button type="primary" size="mini" @click="openBind">绑定</el-button>
          <el-button type="primary" size="mini" @click="openSee">绑定</el-button>
          <el-button type="primary" size="mini">视频通话</el-button>
          <el-button type="danger" size="mini">删除</el-button>
          <el-button type="primary" size="mini">修改</el-button>
        </el-table-column>
      </el-table>
    </div>

    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="query1.pageNum"
      background :page-size="query1.pageSize" layout="total, prev, pager, next, jumper"
      :total="query1.total"></el-pagination>
    <Add v-if="showAdd" @close="closeAdd"></Add>
    <See v-if="showSee" @close="closeSee"></See>
    <Bind v-if="showBind" @close="closeBind"></Bind>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Add from './add.vue'
import See from './see.vue'
import Bind from './bind.vue'
export default {
  components: {
    Add,
    See,
    Bind
  },
  data () {
    return {
      issynchronous: false,
      options: [{
        value: '',
        label: ''
      }],
      value: '',
      tableData: [{
        date: '2016-05-03',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄',
        status: 1,
        show: false
      }, {
        date: '2016-05-02',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄',
        status: 0,
        show: false
      }, {
        date: '2016-05-04',
        name: '虎',
        address: '上海市普陀区金沙江路 1518 弄',
        status: 0,
        show: false
      }, {
        date: '2016-05-03',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄',
        status: 1,
        show: false
      }],
      total: 400,
      currentPage: 0,
      showSynchronous: false,
      showUpdate: false,
      showAdd: false,
      showSee: false,
      showBind: false,
      // 分页数据
      query1: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      }
    }
  },
  computed: {
    ...mapState(['loginInfo'])
  },
  created () {
    this.getList()
  },
  methods: {
    async getList () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-92344223
      // https://doc.weixin.qq.com/doc/w3_AAMAewYpAFE8HoMulH1T7eXOuyYQP?scode=ACwAmwdjAAY7a0Y55NAAMAewYpAFE
      // try {
      //   const obj = {
      //     start: this.query1.pageNum - 1 + '',
      //     length: this.query1.pageSize,
      //     ssjgbh: this.loginInfo.jgbh,
      //     jgbh: this.loginInfo.jgbh,
      //     sxzt: '0'
      //   }
      //   const { data: res } = await this.$http.get('/zhyyapi/ykdrydjlist', { params: obj })
      //   console.log('长者专档列表', res)
      //   if (res.code !== 0) return this.$message.error(res.msg || res.message)
      //   this.tableData = res.data
      //   this.query1.total = res.data.length
      // } catch (error) {
      //   console.log('长者专档请求失败', error)
      //   this.$message.error('系统异常，请稍后重试')
      // }

      try {
        const start = this.query1.pageNum === 1 ? '10' : (this.query1.pageNum - 1) * 10 + 1 + ''
        const obj = {
          start: start,
          length: this.query1.pageSize + '',
          ssjgbh: this.loginInfo.jgbh,
          xzjdmcid: this.loginInfo.xzjdmcid,
          cwhmcid: '',
          flag: '',
          isqy: '',
          issglb: '',
          gxyjb: '',
          gxydj: '',
          tnbjb: '',
          gxtdj: ''
        }
        if (this.loginInfo.jgjb === '4') {
          obj.cwhmcid = ''
        } else if (this.loginInfo.jgjb === '5') {
          obj.cwhmcid = this.loginInfo.tjjgcwhmcid
        }
        const { data: res } = await this.$http.get('/zhyyapi/ykdgldalist', { params: obj })
        console.log('长者专档列表', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.tableData = res.data
        this.query1.total = res.data.length
      } catch (error) {
        console.log('长者专档请求失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    openIssynchronous (obj) {
      if (obj.status === 1) {
        obj.show = true
      } else {
        this.openSynchronous()
      }
    },
    closeIssynchronous (obj) {
      obj.show = false
    },
    openAdd () {
      this.showAdd = true
    },
    closeAdd () {
      this.showAdd = false
    },
    openSee () {
      this.showSee = true
    },
    closeSee () {
      this.showSee = false
    },
    openBind () {
      this.showBind = true
    },
    closeBind () {
      this.showBind = false
    },
    openSynchronous () {
      this.showSynchronous = true
    },
    clsoeSynchronous () {
      this.showSynchronous = false
    },
    openUpdate () {
      this.showUpdate = true
    },
    closeUpdate () {
      this.showUpdate = false
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
      this.query1.pageNum = val
      this.getList()
    }
  }
}
</script>

<style lang="less" scoped>
.son {
  width: 100%;
  height: 100%;
  padding: 0 10px;
  background-color: #fff;
  box-sizing: border-box;

  .son-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 60px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    .title-box {
      display: flex;
      flex-direction: row;
      height: 38px;
      line-height: 38px;
      padding: 0 9px;
      text-align: center;
      background-color: rgb(64, 158, 255);
      color: #fff;
      font-size: 22px;

    }

    .postion {
      width: 246px;
      border-radius: 6px;
    }
  }

  .son-search {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    padding-bottom: 20px;
    box-sizing: border-box;

    .son-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 20px;
      margin-right: 10px;

      .key {
        width: 110px;
        color: #01EEFD;
        font-size: 22px;
      }

      .el-input {
        width: 180px;
      }

      .add {
        width: 30px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        border-radius: 50%;
        background-color: rgb(64, 158, 255);
        color: #fff;
        font-size: 20px;
        cursor: pointer;
      }
    }

    .el-button {
      margin-top: 20px;
      margin-right: 30px;
      background-color: rgb(64, 158, 255);
      border: none;
      color: #fff;
    }

    .red {
      background-color: #FF0000;
    }

  }

  .son-table {
    height: calc(100% - 280px);

    /deep/.el-table__cell {
      text-align: center;
      color: #000;
    }

    .flex {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      width: 100%;
      color: #0077F9;

      span {
        cursor: pointer;
      }
    }
  }

  // 页码样式修改
  .el-pagination {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
}
</style>
