<template>
  <div class="children-page">
    <div class="capsule">
      <div class="capsule-top">
        <span>正在处理</span>
        <el-button size="mini">更多</el-button>
      </div>
      <div class="table">
        <div class="tr">
          <span class="flex flex3">编号</span>
          <span class="flex">反馈人</span>
          <span class="flex flex1">性别</span>
          <span class="flex flex1">年龄</span>
          <span class="flex">类型</span>
          <span class="flex">操作</span>
        </div>
        <div class="scroll">
          <div class="tr" v-for="(item, index) in 20" :key="index">
          <span class="flex flex3">编号</span>
          <span class="flex">反馈人</span>
          <span class="flex flex1">性别</span>
          <span class="flex flex1">年龄</span>
          <span class="flex">类型</span>
          <span class="flex flex4">
            <span>查看</span>
            <span>删除</span>
          </span>
        </div>
        </div>
      </div>
    </div>
    <div class="capsule">
      <div class="capsule-top">
        <span>已处理</span>
        <el-button size="mini">更多</el-button>
      </div>
      <div class="table">
        <div class="tr">
          <span class="flex flex3">编号</span>
          <span class="flex">姓名</span>
          <span class="flex flex1">性别</span>
          <span class="flex flex1">年龄</span>
          <span class="flex">地点</span>
          <span class="flex">状态</span>
        </div>
        <div class="scroll">
          <div class="tr" v-for="(item, index) in 20" :key="index">
          <span class="flex flex3">编号</span>
          <span class="flex">反馈人</span>
          <span class="flex flex1">性别</span>
          <span class="flex flex1">年龄</span>
          <span class="flex">类型</span>
          <span class="flex">状态</span>
        </div>
        </div>
      </div>
    </div>
    <div class="capsule">
      <div class="capsule-top">
        <span>未处理</span>
        <el-button size="mini">更多</el-button>
      </div>
      <div class="table">
        <div class="tr">
          <span class="flex flex3">编号</span>
          <span class="flex">姓名</span>
          <span class="flex flex1">性别</span>
          <span class="flex flex1">年龄</span>
          <span class="flex">地点</span>
          <span class="flex">操作</span>
        </div>
        <div class="scroll">
          <div class="tr" v-for="(item, index) in 20" :key="index">
          <span class="flex flex3">编号</span>
          <span class="flex">反馈人</span>
          <span class="flex flex1">性别</span>
          <span class="flex flex1">年龄</span>
          <span class="flex">类型</span>
          <span class="flex">处理</span>
        </div>
        </div>
      </div>
    </div>
    <div class="capsule">
      <div class="capsule-top">
        <span>正在处理</span>
        <el-button size="mini">更多</el-button>
      </div>
      <div class="table">
        <div class="tr">
          <span class="flex flex3">编号</span>
          <span class="flex">姓名</span>
          <span class="flex flex1">性别</span>
          <span class="flex flex1">年龄</span>
          <span class="flex">地点</span>
          <span class="flex">状态</span>
        </div>
        <div class="scroll">
          <div class="tr" v-for="(item, index) in 20" :key="index">
          <span class="flex flex3">编号</span>
          <span class="flex">反馈人</span>
          <span class="flex flex1">性别</span>
          <span class="flex flex1">年龄</span>
          <span class="flex">地点</span>
          <span class="flex">状态</span>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return { }
  },
  mounted () { },
  methods: {
  }
}
</script>

<style lang="less" scoped>
.children-page {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  .capsule {
    width: 32%;
    height: 386px;
    margin-bottom: 20px;
    padding: 20px;
    box-sizing: border-box;
    border: 2px solid #016DBD;
    border-radius: 30px;
    background: #0A2F56;
    box-shadow: inset 0px 0px 13px 1px #0174C8;

    .capsule-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #03BCD7;
      font-size: 18px;
      font-weight: 800;

      .el-button {
        border: none;
        border-radius: 0;
        background-color: #03BCD7;
        color: #fff;
        font-size: 18px;
      }
    }

    .table {
      width: 100%;
      height: calc(100% - 50px);
      margin-top: 20px;

      .tr {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        color: #01EEFD;
        font-size: 14px;

        .flex {
          flex: 2;
          text-align: center;
        }

        .flex3 {
          flex: 3;
        }

        .flex1 {
          flex: 1;
        }

        .flex4 {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }

      .scroll {
        overflow-y: scroll;
        width: 100%;
        height: calc(100% - 40px);

        &::-webkit-scrollbar {
          width: 0;
        }
      }
    }
  }
}
</style>
