<template>
  <div class="see-box">
    <div class="tab">
      <span class="item active">地址管理</span>
    </div>
    <el-input placeholder="姓名/身份证号" suffix-icon="el-icon-search" class="input">
    </el-input>
    <div class="tab-box">
      <div class="run">
        <div class="son-tab">
          <div class="add" @click="openUpdate">
            <span class="el-icon-plus"></span>
          </div>
        </div>

        <el-table :data="tableData" border height="50%">
          <el-table-column label="序号" type="index">
          </el-table-column>
          <el-table-column prop="address" label="巡检地点">
          </el-table-column>
          <el-table-column prop="address" label="二维码">
          </el-table-column>
          <el-table-column label="操作" width="200">
            <template>
              <!-- <div class="run">
                <span @click="openUpdate">编辑</span>
                <span>删除</span>
              </div> -->
              <el-button type="primary" size="mini" @click="openUpdate">编辑</el-button>
              <el-button type="danger" size="mini">删除</el-button>
            </template>
          </el-table-column>
        </el-table>

        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage4"
          :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper" :total="400" background hide-on-single-page>
        </el-pagination>
      </div>
    </div>
    <el-button class="close" size="small" @click="close">返回</el-button>
    <Update v-if="showUpdate" @close="closeUpdate"></Update>
  </div>
</template>

<script>
import Update from './update.vue'
export default {
  components: {
    Update
  },
  data () {
    return {
      tableData: [{
        date: '2016-05-02',
        name: '王小虎',
        address: '待审批'
      }, {
        date: '2016-05-04',
        name: '王小虎',
        address: '审批通过'
      }, {
        date: '2016-05-01',
        name: '王小虎',
        address: '审批拒绝'
      }],
      options: [{
        value: '',
        label: ''
      }],
      value: '',
      currentPage4: 4,
      tabIndex: 0,
      selectIndex: 0,
      showUpdate: false
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    },
    selectClick (index) {
      this.selectIndex = index
    },
    openUpdate () {
      this.showUpdate = true
    },
    closeUpdate () {
      this.showUpdate = false
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.see-box {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  background-color: #fff;

  .input {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 246px;
  }

  .tab {
    display: flex;
    align-items: center;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    .item {
      width: 130px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      margin-left: 30px;
      background-color: #284867;
      color: #fff;
      font-size: 22px;
      cursor: pointer;

      &:first-child {
        margin-left: 0px;
      }
    }

    .active {
      background-color: #409EFF;
    }
  }

  .tab-box {
    width: 100%;
    height: calc(100% - 78px);

    .run {
      width: 100%;
      height: 100%;

      .son-tab {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
        height: 78px;
        border-bottom: 1px solid #000;
        box-sizing: border-box;

        .add {
          width: 30px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          border-radius: 50%;
          background-color: #409EFF;
          color: #fff;
          font-size: 20px;
          cursor: pointer;
        }
      }

      .el-table {
        margin-top: 20px;
      }

      /deep/.el-table .cell {
        text-align: center;
        color: #000;
      }

      .run {
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
          cursor: pointer;
        }
      }

      .el-pagination {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
      }
    }
  }
  .close {
    position: absolute;
    left: 20px;
    bottom: 20px;
  }

}
</style>
