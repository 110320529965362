<template>
  <div class="see">
    <div class="see-title">
      <span class="title">档案管理</span>
      <el-input placeholder="姓名/身份证号" suffix-icon="el-icon-search" class="see-search">
      </el-input>
    </div>
    <div class="see-scroll">
      <div class="line">
        <span class="key">计划类型:</span>
        <el-radio-group v-model="radio">
          <el-radio label="1">日循环</el-radio>
          <el-radio label="2">周循环</el-radio>
          <el-radio label="3">月循环</el-radio>
        </el-radio-group>
      </div>
      <div class="table" v-if="radio === '1'">
        <div class="tr">
          <div class="td">
            <span>上午12时</span>
          </div>
          <div class="td1"></div>
        </div>
        <div class="tr" v-for="(item, index) in 24" :key="index">
          <div class="td">
            <span>上午{{ item }}时</span>
          </div>
          <div class="td1">
            <div class="select" v-if="item === 8" @click="openAddTask">
              <span>上午8点-9点   辅助洗澡</span>
            </div>
          </div>
        </div>
      </div>
      <div class="table" v-else-if="radio === '2'">
        <div class="tr">
          <div class="td">
            <span>上午12时</span>
          </div>
          <div class="td1 border">
            <span>周一</span>
          </div>
          <div class="td1 border">
            <span>周二</span>
          </div>
          <div class="td1 border">
            <span>周三</span>
          </div>
          <div class="td1 border">
            <span>周四</span>
          </div>
          <div class="td1 border">
            <span>周五</span>
          </div>
          <div class="td1 border">
            <span>周六</span>
          </div>
          <div class="td1">
            <span>周日</span>
          </div>
        </div>
        <div class="tr" v-for="(item, index) in 24" :key="index">
          <div class="td">
            <span>上午{{ item }}时</span>
          </div>
          <div class="td1 border">
            <div class="select select1" v-if="item === 8" @click="openAddTask">
              <span>上午8点-9点   辅助洗澡</span>
            </div>
          </div>
          <div class="td1 border"></div>
          <div class="td1 border"></div>
          <div class="td1 border"></div>
          <div class="td1 border"></div>
          <div class="td1 border"></div>
          <div class="td1"></div>
        </div>
      </div>
      <div class="table" v-else-if="radio === '3'">
        <div class="tr">
          <div class="td">
            <span>上午12时</span>
          </div>
          <div class="td1 border">
            <span>1号</span>
          </div>
          <div class="td1 border">
            <span>2号</span>
          </div>
          <div class="td1 border">
            <span>3号</span>
          </div>
          <div class="td1 border">
            <span>4号</span>
          </div>
          <div class="td1 border">
            <span>5号</span>
          </div>
          <div class="td1 border">
            <span>6号</span>
          </div>
          <div class="td1">
            <span>7号</span>
          </div>
        </div>
        <div class="tr" v-for="(item, index) in 24" :key="index">
          <div class="td">
            <span>上午{{ item }}时</span>
          </div>
          <div class="td1 border">
            <div class="select select1" v-if="item === 8" @click="openAddTask">
              <span>上午8点-9点   辅助洗澡</span>
            </div>
          </div>
          <div class="td1 border"></div>
          <div class="td1 border"></div>
          <div class="td1 border"></div>
          <div class="td1 border"></div>
          <div class="td1 border"></div>
          <div class="td1"></div>
        </div>
      </div>
    </div>
    <div class="see-close">
      <el-button size="small" @click="close">返回</el-button>
      <el-button size="small" type="primary" @click="close">保存</el-button>
    </div>
    <AddTask v-if="showAddTask" @close="closeAddTask"></AddTask>
  </div>
</template>

<script>
import AddTask from './addTask.vue'
export default {
  components: {
    AddTask
  },
  data () {
    return {
      radio: '2',
      showAddTask: false
    }
  },
  methods: {
    openAddTask () {
      this.showAddTask = true
    },
    closeAddTask () {
      this.showAddTask = false
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.see {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  width: 100%;
  height: 100%;
  padding: 0 30px;
  background-color: #fff;
  box-sizing: border-box;

  .see-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 60px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;
    color: #000;
    font-size: 20px;

    .title {
      height: 38px;
      line-height: 38px;
      padding: 0 16px;
      background-color: #409EFF;
      color: #fff;
      font-size: 22px;
    }

    .see-search {
      width: 246px;
    }
  }

  .see-scroll {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 120px);

    &::-webkit-scrollbar {
      width: 0;
    }

    .line {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 50px;

      .key {
        margin-right: 10px;
        color: #000;
        font-size: 16px;
      }
    }

    .table {
      display: flex;
      flex-direction: column;
      border: 1px #000 solid;
      box-sizing: border-box;

      .tr {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 40px;
        border-bottom: 1px solid #000;
        box-sizing: border-box;

        .td {
          width: 230px;
          height: 40px;
          line-height: 40px;
          border-right: 1px solid #000;
          text-align: center;
          box-sizing: border-box;
          color: #000;
          font-size: 17px;
        }

        .td1 {
          flex: 1;
          height: 40px;
          line-height: 40px;
          text-align: center;
          color: #000;
          font-size: 17px;

          .select {
            width: 100%;
            height: 40px;
            padding: 0 9px;
            line-height: 40px;
            box-sizing: border-box;
            background-color: #409EFF;
            color: #fff;
            font-size: 14px;
          }

          .select1 {
            text-align: left;
          }
        }

        .border {
          border-right: 1px solid #000;
          box-sizing: border-box;
        }
      }

      .tr:last-child {
        border-bottom: none;
      }
    }
  }

  .see-close {
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;
  }
}
</style>
