<template>
  <div class="popup">
    <div class="popup-content">
      <div class="popup-title">
        <span>新增出院申请</span>
      </div>
      <div class="popup-box">
        <div class="content">
          <div class="close" @click="close">
            <i class="el-icon-close"></i>
          </div>
          <div class="info">
            <div class="info-line">
              <span class="span">姓名</span>
              <el-input placeholder="" v-model="params.name">
              </el-input>
            </div>
            <div class="info-line">
              <span class="span">房间</span>
              <el-input placeholder="" v-model="params.ryfjh">
              </el-input>
            </div>
            <div class="info-line">
              <span class="span">床位</span>
              <el-input placeholder="" v-model="params.rycwh">
              </el-input>
            </div>
            <div class="info-line">
              <span class="span">状态</span>
              <el-input placeholder="" v-model="params.cyyycyzt">
              </el-input>
            </div>
            <div class="info-line">
              <span class="span">老人</span>
              <div @click="openSelect" class="input">
                <span class="el-icon-arrow-down"></span>
              </div>
            </div>
            <div class="info-line">
              <span class="span">出院原因</span>
              <el-input type="textarea" placeholder="" v-model="params.cyyy">
              </el-input>
            </div>
            <div class="info-line">
              <span class="span">预计时间</span>
              <el-date-picker v-model="params.yjcysj" valueFormat="yyyy-MM-dd" type="date" placeholder="选择日期">
              </el-date-picker>
            </div>
          </div>
          <div class="btn">
            <el-button size="small" @click="close">取消</el-button>
            <el-button size="small" @click="submit">确定</el-button>
          </div>
        </div>
      </div>
    </div>
    <!-- <Select v-if="showSelect" @close="closeSelect"></Select> -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
// import Select from './select.vue'
export default {
  components: {
    // Select
  },
  data () {
    return {
      input: '',
      options: [{
        value: '',
        label: ''
      }],
      value: '',
      showSelect: false,
      params: {
        id: 0,
        czbh: '',
        lnrzsbh: '',
        name: '',
        age: '',
        lxfs: '',
        gddh: '',
        xb: '',
        csrq: '',
        hjdz: '',
        ssjgbh: '',
        ssjgmc: '',
        rydjid: '',
        xzjdmcid: '',
        xzjdmc: '',
        cwhmcid: '',
        cwhmc: '',
        sfzh: '',
        sjhm: '',
        rylc: '',
        rylcid: '',
        ryfjid: '',
        ryfjh: '',
        rycwh: '',
        rycwid: '',
        yjcysj: '',
        cyyycyzt: '',
        cyyy: '',
        cysj: '',
        cyczry: '',
        cyczryid: '',
        zyfyid: '',
        cyjlid: '',
        hzzt: '',
        ylfwjlid: ''
      }
    }
  },
  computed: {
    ...mapState(['loginInfo'])
  },
  created () {
    this.params.name = this.$parent.selectInfo.name || ''
    this.params.ryfjh = this.$parent.selectInfo.fj || ''
    this.params.rycwh = this.$parent.selectInfo.cw || ''
    this.params.cyyycyzt = this.$parent.selectInfo.cwzt || ''
    this.params.age = this.$parent.selectInfo.age || ''
    this.params.xb = this.$parent.selectInfo.xb || ''
    this.params.ylfwjlid = this.$parent.selectInfo.ylfwjlid || ''
  },
  methods: {
    openSelect () {
      this.showSelect = true
    },
    closeSelect () {
      this.showSelect = false
    },
    close () {
      this.$parent.showDischarge = false
    },
    async submit () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-111241236
      try {
        this.params.ssjgbh = this.loginInfo.jgbh
        this.params.ssjgmc = this.loginInfo.jgmc
        this.params.xzjdmcid = this.loginInfo.xzjdmcid
        if (this.loginInfo.jgjb === '4') {
          this.params.cwhmcid = ''
        } else if (this.loginInfo.jgjb === '5') {
          this.params.cwhmcid = this.loginInfo.tjjgcwhmcid
        }
        console.log('请求参数', this.params)
        const { data: res } = await this.$http.post('/zhyyapi/ykdcydjnew', this.params)
        console.log('出院登记保存', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.$message.success('添加成功')
        this.$parent.showDischarge = false
      } catch (error) {
        console.log('出院登记保存失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    }
  }
}
</script>

<style scoped lang="less">
.popup {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 88;
  background-color: rgba(0, 0, 0, .74);

  .popup-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    height: 800px;

    .popup-title {
      width: 240px;
      height: 50px;
      line-height: 60px;
      background-image: url('../../../img/dialogTitleBg.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      text-align: center;
      color: #fff;
      font-size: 20px;
    }

    .popup-box {
      width: 600px;
      height: 600px;
      padding: 10px;
      background-color: rgba(3, 27, 44, .8);
      border-radius: 10px;
      border: 4px solid rgba(1, 49, 88, .5);
      box-sizing: border-box;

      .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        width: 100%;
        height: 100%;
        padding: 30px;
        border-radius: 10px;
        border: 4px solid rgba(1, 49, 88, .5);
        box-sizing: border-box;

        .close {
          display: flex;
          align-items: flex-end;
          position: absolute;
          top: 0;
          right: 0;
          width: 50px;
          height: 50px;
          background: linear-gradient(26.565deg, #2057a7 50%, transparent 0);
          transform: rotate(180deg);
          color: #fff;
          font-size: 24px;
          cursor: pointer;
        }

        .info {
          display: flex;
          flex-direction: column;
          width: 100%;

          .info-line {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            margin-bottom: 20px;
            color: #01EEFD;
            font-size: 18px;

            .input {
              display: flex;
              flex-direction: row-reverse;
              align-items: center;
              width: 246px;
              height: 38px;
              margin-left: 28px;
              padding: 0 5px;
              background-color: #fff;
              box-sizing: border-box;
              color: #A1A6BB;
              font-size: 14px;
            }

            .span {
              width: 90px;
            }

            .el-select {
              width: 246px;
              margin-left: 28px;
            }

            .el-input {
              width: 246px;
              margin-left: 28px;
            }

            .el-textarea {
              width: 246px;
              margin-left: 28px;
            }

            /deep/.el-textarea__inner {
              width: 246px;
              height: 104px;
              resize: none;
            }
          }

          .info-line:nth-child(5) {
            align-items: initial;
          }
        }

        .btn {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 240px;
          margin: 0 auto;
        }

        .el-button {
          border-radius: 0;
          border: none;
          background-color: #33465C;
          color: #fff;
        }

        .el-button:nth-child(2) {
          background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
        }
      }
    }
  }
}
</style>
