<template>
  <div class="see">
    <div class="son-tab">
      <span class="son-item son-active">查看体检报告详情</span>
      <el-input placeholder="姓名/身份证号" suffix-icon="el-icon-search" class="see-search">
      </el-input>
    </div>
    <div class="son-scroll">
      <div class="run-search">
        <span class="item">老人：张三</span>
        <span class="item">上传人：张三</span>
        <span class="item">身份证号：32062371887128122</span>
        <span class="item">上传时间：2020-02-02 15：00</span>
        <span class="item">床位号：501-1</span>
      </div>
      <el-table :data="tableData" height="50%">
        <el-table-column prop="name" label="体检项目">
        </el-table-column>
        <el-table-column label="资料">
          <template>
            <div class="icon">
              <span class="el-icon-picture"></span>
            </div>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage4"
        :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper" :total="400">
      </el-pagination>
    </div>
    <!-- <div class="run-search">
      <div class="run-item">
        <div class="key">
          <span>登记日期 ：</span>
        </div>
        <el-date-picker type="date" placeholder="请选择">
        </el-date-picker>
      </div>
      <div class="run-item">
        <div class="key">
          <span>家属姓名</span>
        </div>
        <el-input placeholder="请输入"></el-input>
      </div>
      <el-button>搜索</el-button>
      <el-button>重置</el-button>
    </div>

    <div class="son-add">
      <div class="son-add-icon">
        <span class="el-icon-plus"></span>
      </div>
    </div>

    <el-table :data="tableData" height="50%">
      <el-table-column label="序号" type="index" width="50">
      </el-table-column>
      <el-table-column prop="name" label="药品名称">
      </el-table-column>
      <el-table-column prop="address" label="数量">
      </el-table-column>
      <el-table-column prop="address" label="有效期">
      </el-table-column>
      <el-table-column prop="address" label="经办人">
      </el-table-column>
      <el-table-column prop="address" label="家属">
      </el-table-column>
      <el-table-column label="操作" width="180">
        <template>
          <div class="table-run">
            <span @click="openSee">编辑</span>
            <span @click="openSee">删除</span>
            <span @click="openSee">查看详情</span>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage4"
      :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper" :total="400">
    </el-pagination> -->
    <div class="see-close">
      <el-button size="small" @click="close">返回</el-button>
      <!-- <el-button class="see-blue" size="small" @click="close">保存</el-button> -->
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {
      tableData: [{
        date: '2016-05-02',
        name: '王小虎',
        address: '等待外出'
      }, {
        date: '2016-05-04',
        name: '王小虎',
        address: '未返回'
      }, {
        date: '2016-05-01',
        name: '王小虎',
        address: '逾期未返回'
      }],
      currentPage4: 4,
      showSee: false
    }
  },
  methods: {
    openSee () {
      this.showSee = true
    },
    closeSee () {
      this.showSee = false
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.see {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  padding: 0 20px;
  background-color: #081C38;
  box-sizing: border-box;

  .son-tab {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;

    .son-item {
      height: 38px;
      line-height: 38px;
      margin-right: 40px;
      padding: 0 20px;
      text-align: center;
      background-color: #284867;
      color: #fff;
      font-size: 16px;
      cursor: pointer;
    }

    .son-active {
      background-color: #04B4D0;
    }
  }

  .see-search {
    width: 246px;

    /deep/.el-input__inner {
      border: none;
      background: linear-gradient(153deg, #0A477B 0%, #0E67AD 100%);
      color: #fff;
      font-size: 18px;
    }

    /deep/.el-input__inner::-webkit-input-placeholder {
      color: #fff;
    }

    /deep/.el-input__suffix {
      color: #fff;
      font-size: 18px;
    }
  }

  .son-tab1 {
    display: flex;
    align-items: center;
    width: 100%;
    height: 60px;
    padding-left: 12px;
    box-sizing: border-box;

    .son-item1 {
      margin-right: 40px;
      border-bottom: 1px solid transparent;
      color: #fff;
      font-size: 22px;
      cursor: pointer;
    }

    .son-active1 {
      color: #04B4D0;
      border-bottom: 1px solid #04B4D0;
    }
  }

  .run-search {
    display: flex;
    align-items: center;
    width: 100%;
    height: 60px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;

    .item {
      width: 20%;
      color: #04B4D0;
      font-size: 18px;
    }
  }

  .el-table {
    margin-top: 10px;
    border: 4px solid #073F73;
    background-color: #081C38;
  }

  /deep/.el-table__body-wrapper {
    background-color: #081C38;
  }

  /deep/.el-table th.el-table__cell.is-leaf {
    border-bottom: none;
  }

  /deep/.el-table th.el-table__cell {
    background-color: #024276;
  }

  /deep/.el-table .cell {
    text-align: center;
    color: #01EEFD;
  }

  /deep/.el-table tr {
    background-color: #081C38;
  }

  /deep/.el-table td.el-table__cell {
    border-bottom: 1px solid #01EEFD;
  }

  .el-table::before {
    height: 0;
  }

  /deep/.el-table tbody tr:hover>td {
    background-color: transparent;
  }

  /deep/.el-checkbox__inner {
    background-color: transparent;
  }

  .icon {
    color: #fff;
    font-size: 30px;
  }

  .table-run {
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      cursor: pointer;
    }
  }

  .el-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;

    /deep/.el-pagination__total {
      color: #01EEFD;
    }

    /deep/.el-pagination__jump {
      color: #01EEFD;
    }

    /deep/.el-input__inner {
      background-color: transparent;
      color: #01EEFD;
    }

    /deep/.number {
      margin-left: 10px;
      background-color: #ADADAD;
      color: #fff;
    }

    /deep/.active {
      background-color: #01EEFD;
    }

    /deep/.el-pagination .btn-prev {
      background-color: #ADADAD !important;
      padding-right: 0;
    }

    /deep/.btn-next {
      margin-left: 10px;
      padding: 0;
    }
  }

  .son-scroll {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 200px);
    padding-left: 12px;
    box-sizing: border-box;

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  .see-close {
    position: absolute;
    left: 40px;
    bottom: 0px;
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;

    .el-button {
      background-color: #33465C;
      border: none;
      color: #fff;
    }

    .see-blue {
      margin-left: 50px;
      background-color: #024A83;
    }
  }
}
</style>
