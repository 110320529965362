<template>
  <div class="son">
    <div class="son-head">
      <div class="son-head-item">
        <div class="son-head-left">
          <div class="son-head-open"></div>
          <el-input placeholder="请输入搜索内容"></el-input>
        </div>
        <div class="son-head-center">
          <span>切换机构：</span>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="son-head-right">
          <span class="el-icon-success success"></span>
          <div class="div">
            <div class="div1">
              <div class="blue"></div>
            </div>
          </div>
          <span class="el-icon-lock lock"></span>
          <span class="el-icon-view lock"></span>
          <span class="el-icon-rank rank"></span>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <!-- <div class="son-head-item">
        <div class="son-head-title">
          <span>预约管理</span>
        </div>
        <el-button type="primary">
          <span>更多</span>
          <span class="el-icon-arrow-down icon"></span>
        </el-button>
      </div> -->
    </div>
    <div class="son-scroll">
      <div class="son-search">
        <div class="search-item">
          <div class="key">
            <span>*机构</span>
          </div>
          <el-select v-model="value" placeholder="" size="small">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="search-item">
          <div class="key">
            <span>*套餐</span>
          </div>
          <el-select v-model="value" placeholder="" size="small">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="search-item">
          <div class="key">
            <span>所属</span>
          </div>
          <el-select v-model="value" placeholder="" size="small">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="search-item">
          <div class="key">
            <span>查询日期</span>
          </div>
          <el-date-picker v-model="value" type="daterange" range-separator="至" start-placeholder="开始日期"
            end-placeholder="结束日期" size="small">
          </el-date-picker>
        </div>
        <el-checkbox-group v-model="checkList">
          <el-checkbox v-for="(item, index) in selects" :key="index" :label="index + 1 + ''">{{ item }}</el-checkbox>
        </el-checkbox-group>
        <div class="button">
          <el-radio-group v-model="radio" @change="change">
            <el-radio label="1">全选</el-radio>
            <el-radio label="2">取消</el-radio>
          </el-radio-group>
          <el-button type="primary" size="small">查询</el-button>
          <el-button type="success" size="small">导出</el-button>
          <el-button size="small">重置</el-button>
          <el-button type="text">重置</el-button>
        </div>
      </div>

      <div class="son-info">
        <div class="table">
          <el-table :data="tableData" border height="100%">
            <el-table-column prop="date" fixed="left" label="所属" width="150">
            </el-table-column>
            <el-table-column prop="date" label="体检实验人数" width="150">
            </el-table-column>
            <el-table-column label="心脏异常">
              <el-table-column prop="name" label="人数 %" width="120">
              </el-table-column>
            </el-table-column>
            <el-table-column label="肺异常">
              <el-table-column prop="name" label="人数 %" width="120">
              </el-table-column>
            </el-table-column>
            <el-table-column label="肝脾异常">
              <el-table-column prop="name" label="人数 %" width="120">
              </el-table-column>
            </el-table-column>
            <el-table-column label="腹部异常">
              <el-table-column prop="name" label="人数 %" width="120">
              </el-table-column>
            </el-table-column>
            <el-table-column label="皮肤异常">
              <el-table-column prop="name" label="人数 %" width="120">
              </el-table-column>
            </el-table-column>
            <el-table-column label="头部异常">
              <el-table-column prop="name" label="人数 %" width="120">
              </el-table-column>
            </el-table-column>
            <el-table-column label="颈椎异常">
              <el-table-column prop="name" label="人数 %" width="120">
              </el-table-column>
            </el-table-column>
            <el-table-column label="胸部异常">
              <el-table-column prop="name" label="人数 %" width="120">
              </el-table-column>
            </el-table-column>
            <el-table-column label="脊柱四肢异常">
              <el-table-column prop="name" label="人数 %" width="120">
              </el-table-column>
            </el-table-column>
            <el-table-column label="淋巴结异常">
              <el-table-column prop="name" label="人数 %" width="120">
              </el-table-column>
            </el-table-column>
            <el-table-column label="鼻病">
              <el-table-column prop="name" label="人数 %" width="120">
              </el-table-column>
            </el-table-column>
            <el-table-column label="喉(咽)异常">
              <el-table-column prop="name" label="人数 %" width="120">
              </el-table-column>
            </el-table-column>
            <el-table-column label="牙周疾病">
              <el-table-column prop="name" label="人数 %" width="120">
              </el-table-column>
            </el-table-column>
            <el-table-column label="龋齿">
              <el-table-column prop="name" label="人数 %" width="120">
              </el-table-column>
            </el-table-column>
            <el-table-column label="听力异常">
              <el-table-column prop="name" label="人数 %" width="120">
              </el-table-column>
            </el-table-column>
            <el-table-column label="收缩压异常">
              <el-table-column prop="name" label="人数 %" width="120">
              </el-table-column>
            </el-table-column>
            <el-table-column label="舒张压异常">
              <el-table-column prop="name" label="人数 %" width="120">
              </el-table-column>
            </el-table-column>
            <el-table-column label="脉率异常">
              <el-table-column prop="name" label="人数 %" width="120">
              </el-table-column>
            </el-table-column>
            <el-table-column label="肺活量异常">
              <el-table-column prop="name" label="人数 %" width="120">
              </el-table-column>
            </el-table-column>
            <el-table-column label="结核菌素试验">
              <el-table-column prop="name" label="人数 %" width="120">
              </el-table-column>
            </el-table-column>
            <el-table-column label="视力异常(近视)">
              <el-table-column prop="name" label="人数 %" width="160">
              </el-table-column>
            </el-table-column>
            <el-table-column label="生长发育异常">
              <el-table-column prop="name" label="人数 %" width="120">
              </el-table-column>
            </el-table-column>
            <el-table-column label="贫血异常">
              <el-table-column prop="name" label="人数 %" width="120">
              </el-table-column>
            </el-table-column>
            <el-table-column label="脊柱侧弯异常">
              <el-table-column prop="name" label="人数 %" width="120">
              </el-table-column>
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="150">
              <template>
                <el-button type="text">查看异常数据</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
          background :page-size="10" layout="total, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {
      options: [{
        value: '',
        label: ''
      }],
      value: '',
      radio: '',
      tableData: [{}],
      total: 400,
      currentPage: 0,
      checkList: [],
      selects: Object.freeze(['心脏异常', '肺异常', '肝脾异常', '腹部异常', '皮肤异常', '头部异常', '颈椎异常', '胸部异常', '脊柱四肢异常', '淋巴结异常', '鼻病', '喉(咽)异常', '牙周疾病', '龋齿', '听力异常', '收缩压异常', '舒张压异常', '脉率异常', '肺活量异常', '结核菌素试验', '视力异常(近视)', '生长发育异常', '贫血异常', '脊柱侧弯异常'])
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    },
    openSet () {
      this.showSet = true
    },
    clsoeSet () {
      this.showSet = false
    },
    openAdd () {
      this.showAdd = true
    },
    closeAdd () {
      this.showAdd = false
    },
    change (e) {
      if (e === '1') {
        for (var index = 0; index < this.selects.length; index++) {
          this.checkList.push(index + 1 + '')
        }
      } else {
        this.checkList = []
      }
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
    }
  }
}
</script>

<style lang="less" scoped>
.son {
  width: 100%;
  height: 100%;
  background-color: #F0F0F0;

  .son-head {
    display: flex;
    align-items: center;
    width: 100%;
    height: 70px;
    margin-bottom: 20px;
    background-color: #fff;
    box-sizing: border-box;

    .son-head-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 0 14px;
      box-sizing: border-box;

      .son-head-left {
        display: flex;
        align-items: center;
        width: 33%;

        .son-head-open {
          width: 23px;
          height: 6px;
          border-top: 2px solid #595959;
          border-bottom: 2px solid #595959;
        }

        .el-input {
          margin-left: 50px;
        }

        /deep/.el-input__inner {
          border-color: #fff;
          font-size: 18px;
        }
      }

      .son-head-center {
        display: flex;
        align-items: center;
        flex: 1;
        color: #595959;
        font-size: 20px;

        /deep/.el-input__inner {
          font-size: 18px;
        }
      }

      .son-head-right {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex: 1;

        .success {
          color: #67C23A;
          font-size: 39px;
        }

        .lock {
          color: #595959;
          font-size: 30px;
        }

        .rank {
          font-size: 34px;
          transform: rotate(45deg);
        }

        .div {
          width: 41px;
          height: 40px;
          padding: 6px 7px;
          border: 1px solid #E6E6E6;
          border-radius: 6px;
          box-sizing: border-box;

          .div1 {
            width: 100%;
            height: 100%;
            padding-top: 3px;
            border: 1px solid #999999;
            box-sizing: border-box;

            .blue {
              width: 100%;
              height: 100%;
              background-color: #409EFF;
            }
          }
        }

        /deep/.el-input__inner {
          text-align: right;
          border: none;
          font-size: 18px;
        }
      }

      .son-head-title {
        width: 117px;
        height: 38px;
        line-height: 38px;
        text-align: center;
        border: 1px solid #409EFF;
        border-radius: 2px;
        box-sizing: border-box;
        color: #409EFF;
        font-size: 16px;
      }

      .el-button {
        width: 100px;

        .icon {
          margin-left: 10px;
        }
      }
    }
  }

  .son-scroll {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 90px);
    padding: 0 10px;
    box-sizing: border-box;

    &::-webkit-scrollbar {
      width: 0;
    }

    .son-search {
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;
      width: 100%;
      height: 180px;
      padding: 0 10px;
      background-color: #fff;
      border-radius: 18px;
      box-sizing: border-box;

      .search-item {
        display: flex;
        align-items: center;
        width: calc(25% - 40px);
      }

      .key {
        white-space: nowrap;
        margin-right: 10px;
        color: #17212B;
        font-size: 16px;
      }

      .el-checkbox-group {
        display: flex;
        flex-wrap: wrap;
      }

      .el-radio-group {
        margin-right: 40px;
      }

      .button {
        display: flex;
        align-items: center;
        width: 100%;
        height: 40px;
      }
    }

    .son-info {
      width: 100%;
      height: calc(100% - 200px);
      margin-top: 20px;
      padding: 0 20px;
      background-color: #fff;
      border-radius: 18px;
      box-sizing: border-box;

      .table {
        width: 100%;
        height: calc(100% - 90px);

        /deep/.el-table__cell {
          text-align: center;
          color: #000;
        }
      }

      // 表格样式修改
      .el-table {}

      // 页码样式修改
      .el-pagination {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
      }
    }
  }
}
</style>
