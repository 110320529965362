<template>
  <div class="son">
    <div class="son-line" id="son">
      <div class="son-item">
        <div class="key">
          <span>姓名：</span>
        </div>
        <el-input class="input" v-model="testInfo.name"></el-input>
      </div>
      <div class="son-item">
        <div class="key">
          <span>身份证号码：</span>
        </div>
        <el-input class="input" v-model="testInfo.sfzh"></el-input>
      </div>
      <div class="son-item">
        <div class="key">
          <span>年龄：</span>
        </div>
        <el-input class="input" v-model="testInfo.age"></el-input>
      </div>
      <div class="son-item">
        <div class="key">
          <span>性别：</span>
        </div>
        <el-select v-model="testInfo.xb" placeholder="">
          <el-option v-for="item in sexs" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="son-item">
        <div class="key">
          <span>体检类型：</span>
        </div>
        <el-input class="input"></el-input>
      </div>
      <div class="son-item">
        <div class="key">
          <span>体检项目：</span>
        </div>
        <el-input class="input"></el-input>
      </div>
      <div class="son-item">
        <div class="key">
          <span>体检机构：</span>
        </div>
        <el-select v-model="testInfo.tjjgmc" placeholder="">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="son-item">
        <div class="key">
          <span>学校：</span>
        </div>
        <el-input class="input" v-model="testInfo.ssxx" ></el-input>
      </div>
      <div class="son-item">
        <div class="key">
          <span>年级：</span>
        </div>
        <el-select v-model="testInfo.ssnj">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="son-item">
        <div class="key">
          <span>学期：</span>
        </div>
        <el-select v-model="testInfo.ssxq">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="son-item">
        <div class="key">
          <span>班级：</span>
        </div>
        <el-select v-model="testInfo.ssbj">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="son-item">
        <div class="key">
          <span>体检日期：</span>
        </div>
        <el-date-picker type="date" v-model="testInfo.tjrq">
        </el-date-picker>
      </div>
      <div class="son-item">
        <div class="key">
          <span>联系电话：</span>
        </div>
        <el-input class="input" v-model="testInfo.lxdh"></el-input>
      </div>
      <div class="son-item">
        <div class="key">
          <span>体检编号：</span>
        </div>
        <el-input class="input" v-model="testInfo.tmcode"></el-input>
      </div>
      <div class="son-item">
        <div class="key">
          <span>出生日期：</span>
        </div>
        <el-date-picker type="date" v-model="value">
        </el-date-picker>
      </div>
      <div class="son-item">
        <div class="key">
          <span>民族：</span>
        </div>
        <el-select v-model="value" placeholder="">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="son-item">
        <div class="key">
          <span>核查医生：</span>
        </div>
        <el-date-picker type="date" placeholder="" v-model="testInfo.zjys">
        </el-date-picker>
      </div>
      <div class="son-item">
        <div class="key">
          <span>核查日期：</span>
        </div>
        <el-date-picker type="date" placeholder="" v-model="testInfo.hcrq">
        </el-date-picker>
      </div>
      <div class="son-item">
        <div class="key">
          <span>核查完成日期：</span>
        </div>
        <el-date-picker type="date" placeholder="" v-model="testInfo.hcwcrq">
        </el-date-picker>
      </div>
    </div>
    <div class="son-title"  :class="{'son-red': (testInfo.jwsgms | testInfo.jwscrbs )}">
      <span>既往史</span>
    </div>
    <div class="son-line">
      <div class="son-item son-width">
        <div class="key">
          <span>先天性心脏病：</span>
        </div>
        <el-radio-group v-model="testInfo.jwsxtxxz">
          <el-radio label="0">未见异常</el-radio>
          <el-radio label="1">异常</el-radio>
        </el-radio-group>
      </div>
      <div class="son-item son-item1">
        <div class="key">
          <span>过敏史：</span>
        </div>
        <el-radio-group v-model="testInfo.jwsgms" @change="testChange($event, 'gmsqt')">
          <el-radio label="0">未发现</el-radio>
          <el-radio label="99">其他</el-radio>
        </el-radio-group>
        <el-input class="input1" v-show="testInfo.jwsgms === '99'" v-model="testInfo.gmsqt"></el-input>
      </div>
      <div class="son-item son-item1">
        <div class="key">
          <span>传染史：</span>
        </div>
        <el-radio-group v-model="testInfo.jwscrbs" @change="testChange($event, 'crsqt')">
          <el-radio label="0">未发现</el-radio>
          <el-radio label="99">其他</el-radio>
        </el-radio-group>
        <el-input class="input1" v-show="testInfo.jwscrbs === '99'" v-model="testInfo.crsqt"></el-input>
      </div>
      <div class="son-item son-item1">
        <div class="key">
          <span>结核病史：</span>
        </div>
        <el-radio-group v-model="testInfo.jhbscode" @change="testChange($event, 'jhbsdes')">
          <el-radio label="0">未发现</el-radio>
          <el-radio label="99">其他</el-radio>
        </el-radio-group>
        <el-input class="input1" v-show="testInfo.jhbscode === '99'" v-model="testInfo.jhbsdes"></el-input>
      </div>
      <div class="son-item son-item1">
        <div class="key">
          <span>肝炎病史：</span>
        </div>
        <el-radio-group v-model="testInfo.gybscode" @change="testChange($event, 'gybsdes')">
          <el-radio label="0">未发现</el-radio>
          <el-radio label="99">其他</el-radio>
        </el-radio-group>
        <el-input class="input1" v-show="testInfo.gybscode === '99'" v-model="testInfo.gybsdes"></el-input>
      </div>
      <div class="son-item son-item1">
        <div class="key">
          <span>其他选项：</span>
        </div>
        <el-radio-group v-model="testInfo.jwsqt" @change="testChange($event, 'jwsqtdes')">
          <el-radio label="0">未发现</el-radio>
          <el-radio label="99">其他</el-radio>
        </el-radio-group>
        <el-input class="input1" v-show="testInfo.jwsqt === '99'" v-model="testInfo.jwsqtdes"></el-input>
      </div>
    </div>
    <div class="son-title" :class="{'son-red': (testInfo.nkfei | testInfo.nkxz | testInfo.nkgz | testInfo.nkpz)}">
      <span>内科</span>
    </div>
    <div class="son-line">
      <div class="son-item son-width">
        <div class="key">
          <span>肺：</span>
        </div>
        <el-radio-group v-model="testInfo.nkfei" @change="testChange($event, 'nkfeiqtdes')">
          <el-radio label="0">未见异常</el-radio>
          <el-radio label="1">异常</el-radio>
          <el-radio label="99">其他</el-radio>
        </el-radio-group>
        <el-input class="input1" v-if="testInfo.nkfei === '99'" v-model="testInfo.nkfeiqtdes"></el-input>
      </div>
      <div class="son-item son-width">
        <div class="key">
          <span>心脏：</span>
        </div>
        <el-radio-group v-model="testInfo.nkxz" @change="testChange($event, 'nkxzqtdes')">
          <el-radio label="0">未见异常</el-radio>
          <el-radio label="1">心律异常</el-radio>
          <el-radio label="2">异常心音</el-radio>
          <el-radio label="3">有杂音</el-radio>
          <el-radio label="99">其他</el-radio>
        </el-radio-group>
        <el-input class="input1" v-if="testInfo.nkxz === '99'" v-model="testInfo.nkxzqtdes"></el-input>
      </div>
      <div class="son-item son-width">
        <div class="key">
          <span>肝：</span>
        </div>
        <el-radio-group v-model="testInfo.nkgz">
          <el-radio label="0">未触及</el-radio>
          <el-radio label="1">触及</el-radio>
        </el-radio-group>
      </div>
      <div class="son-item son-width">
        <div class="key">
          <span>脾：</span>
        </div>
        <el-radio-group v-model="testInfo.nkpz">
          <el-radio label="0">未触及</el-radio>
          <el-radio label="1">触及</el-radio>
        </el-radio-group>
      </div>
    </div>
    <div class="son-title" :class="{'son-red': (testInfo.wktb | testInfo.wkjz | testInfo.wkxb | testInfo.wkjzsz | testInfo.wkpf | testInfo.wklbj | testInfo.jzcwflag)}">
      <span>外科</span>
    </div>
    <div class="son-line">
      <div class="son-item son-width">
        <div class="key">
          <span>头部：</span>
        </div>
        <el-radio-group v-model="testInfo.wktb" @change="testChange($event, 'wktbqtdes')">
          <el-radio label="0">未见异常</el-radio>
          <el-radio label="1">头部畸形</el-radio>
          <el-radio label="99">其他</el-radio>
        </el-radio-group>
        <el-input class="input1" v-if="testInfo.wktb === '99'" v-model="testInfo.wktbqtdes"></el-input>
      </div>
      <div class="son-item son-width">
        <div class="key">
          <span>颈椎：</span>
        </div>
        <el-radio-group v-model="testInfo.wkjz" @change="testChange($event, 'wkjzqtdes')">
          <el-radio label="0">未见异常</el-radio>
          <el-radio label="1">颈部偏曲</el-radio>
          <el-radio label="2">甲状腺肿大</el-radio>
          <el-radio label="3">有结节</el-radio>
          <el-radio label="99">其他</el-radio>
        </el-radio-group>
        <el-input class="input1" v-if="testInfo.wkjz === '99'" v-model="testInfo.wkjzqtdes"></el-input>
      </div>
      <div class="son-item son-item2">
        <div class="key">
          <span>胸部：</span>
        </div>
        <el-radio-group v-model="testInfo.wkxb" @change="testChange($event, 'wkxbqtdes')">
          <el-radio label="0">未见异常</el-radio>
          <el-radio label="1">胸廓畸形</el-radio>
          <el-radio label="2">胸廓局部隆起</el-radio>
          <el-radio label="3">胸廓压痛</el-radio>
          <el-radio label="4">异常博动</el-radio>
          <el-radio label="5">器官左右偏</el-radio>
          <el-radio label="99">其他</el-radio>
        </el-radio-group>
        <el-input class="input1" v-if="testInfo.wkxb === '99'" v-model="testInfo.wkxbqtdes"></el-input>
      </div>
      <div class="son-item son-item2">
        <div class="key">
          <span>脊柱四肢：</span>
        </div>
        <el-radio-group v-model="testInfo.wkjzsz" @change="testChange($event, 'wkjzszqtdes')">
          <el-radio label="0">未见异常</el-radio>
          <el-radio label="1">四肢畸形</el-radio>
          <el-radio label="2">杵状指</el-radio>
          <el-radio label="3">关节红肿</el-radio>
          <el-radio label="4">关节疼痛</el-radio>
          <el-radio label="5">活动受限</el-radio>
          <el-radio label="99">其他</el-radio>
        </el-radio-group>
        <el-input class="input1" v-if="testInfo.wkjzsz === '99'" v-model="testInfo.wkjzszqtdes"></el-input>
      </div>
      <div class="son-item son-width">
        <div class="key">
          <span>皮肤：</span>
        </div>
        <el-radio-group v-model="testInfo.wkpf" @change="testChange($event, 'wkpfqtdes')">
          <el-radio label="0">未见异常</el-radio>
          <el-radio label="1">颜色（潮红、苍白、发绀、黄染、色素沉着）</el-radio>
          <el-radio label="2">水肿</el-radio>
          <el-radio label="3">出血</el-radio>
          <el-radio label="4">皮疹</el-radio>
          <el-radio label="5">有瘢痕</el-radio>
          <el-radio label="99">其他</el-radio>
        </el-radio-group>
        <el-input class="input1" v-if="testInfo.wkpf === '99'" v-model="testInfo.wkpfqtdes"></el-input>
      </div>
      <div class="son-item son-item2">
        <div class="key">
          <span>淋巴结：</span>
        </div>
        <el-radio-group v-model="testInfo.wklbj" @change="testChange($event, 'wklbjqtdes')">
          <el-radio label="0">未见异常</el-radio>
          <el-radio label="1">浅表淋巴结-颌下</el-radio>
          <el-radio label="2">浅表淋巴结-颈部</el-radio>
          <el-radio label="3">浅表淋巴结-腋窝</el-radio>
          <el-radio label="99">其他</el-radio>
        </el-radio-group>
        <el-input class="input1" v-if="testInfo.wklbj === '99'" v-model="testInfo.wklbjqtdes"></el-input>
      </div>
      <div class="son-item son-width">
        <div class="key">
          <span>脊柱：</span>
        </div>
        <el-radio-group v-model="testInfo.jzcwflag">
          <el-radio label="0">未见异常</el-radio>
          <el-radio label="1">异常</el-radio>
        </el-radio-group>
      </div>
    </div>
    <div class="son-title" :class="{'son-red': (testInfo.jzcwqgxzjxdzt | testInfo.jzcwqgxzjxydzt | testInfo.jzcwqgxzjydzt | testInfo.jzcwqgxzjydzt)}">
      <span>脊柱侧弯</span>
    </div>
    <div class="son-line">
      <div class="son-item son-width">
        <div class="key">
          <span>躯干旋转角（ATR）胸段：</span>
        </div>
        <el-input class="input1" v-model="testInfo.jzcwqgxzjxd"></el-input>
        <div class="key">
          <span>°</span>
        </div>
        <el-radio-group style="margin-left: 20px" v-model="testInfo.jzcwqgxzjxdzt">
          <el-radio label="0">正常</el-radio>
          <el-radio label="1">异常</el-radio>
        </el-radio-group>
      </div>
      <div class="son-item son-width">
        <div class="key">
          <span>躯干旋转角（ATR）胸腰段：</span>
        </div>
        <el-input class="input1" v-model="testInfo.jzcwqgxzjxyd"></el-input>
        <div class="key">
          <span>°</span>
        </div>
        <el-radio-group style="margin-left: 20px" v-model="testInfo.jzcwqgxzjxydzt">
          <el-radio label="0">正常</el-radio>
          <el-radio label="1">异常</el-radio>
        </el-radio-group>
      </div>
      <div class="son-item son-width">
        <div class="key">
          <span>躯干旋转角（ATR）腰段：</span>
        </div>
        <el-input class="input1" v-model="testInfo.jzcwqgxzjyd"></el-input>
        <div class="key">
          <span>°</span>
        </div>
        <el-radio-group style="margin-left: 20px" v-model="testInfo.jzcwqgxzjydzt">
          <el-radio label="0">正常</el-radio>
          <el-radio label="1">异常</el-radio>
        </el-radio-group>
      </div>
      <div class="son-item son-width">
        <div class="key">
          <span>躯干倾斜角：</span>
        </div>
        <el-input class="input1" v-model="testInfo.jzcwckjl"></el-input>
        <div class="key">
          <span>°</span>
        </div>
        <el-radio-group style="margin-left: 20px" v-model="testInfo.jzcwckjlzt">
          <el-radio label="0">正常</el-radio>
          <el-radio label="1">异常</el-radio>
        </el-radio-group>
      </div>
      <div class="son-item">
        <div class="key">
          <span>体检结论：</span>
        </div>
        <el-input v-model="testInfo.jzcwtjjl"></el-input>
      </div>
    </div>
    <div class="son-title" :class="{'son-red': (testInfo.yankewg | testInfo.yankeyy | testInfo.yankezy | testInfo.yankesm | testInfo.yankesm | testInfo.yankesej)}">
      <span>眼科</span>
    </div>
    <div class="son-line">
      <div class="son-item son-width">
        <div class="key">
          <span>眼外科：</span>
        </div>
        <el-radio-group v-model="testInfo.yankewg" @change="testChange($event, 'ywgqt')">
          <el-radio label="0">未见异常</el-radio>
          <el-radio label="1">异常</el-radio>
          <el-radio label="99">其他</el-radio>
          <el-input class="input1" v-if="testInfo.yankewg === '99'" v-model="testInfo.ywgqt"></el-input>
        </el-radio-group>
      </div>
      <div class="son-item">
        <div class="key">
          <span>右眼视力：</span>
        </div>
        <el-input class="input" v-model="testInfo.yankeyylysl"></el-input>
      </div>
      <div class="son-item son-width1">
        <div class="key">
          <span>左眼视力：</span>
        </div>
        <el-input class="input" v-model="testInfo.yankecylysl"></el-input>
      </div>
      <div class="son-item son-item2">
        <div class="key">
          <span>右眼矫正视力：</span>
        </div>
        <el-input class="input" v-model="testInfo.yankesljzyy"></el-input>
      </div>
      <div class="son-item son-width1">
        <div class="key">
          <span>左眼矫正视力：</span>
        </div>
        <el-input class="input" v-model="testInfo.yankesljzzy"></el-input>
      </div>
      <div class="son-item son-width1">
        <div class="key">
          <span>右眼：</span>
        </div>
        <el-radio-group v-model="testInfo.yankeyy" @change="testChange($event, 'yankeyyqtdes')">
          <el-radio label="0">正常</el-radio>
          <el-radio label="1">异常</el-radio>
          <el-radio label="99">其他</el-radio>
          <el-input class="input1" v-if="testInfo.yankeyy === '99'" v-model="testInfo.yankeyyqtdes"></el-input>
        </el-radio-group>
      </div>
      <div class="son-item son-width1">
        <div class="key">
          <span>左眼：</span>
        </div>
        <el-radio-group v-model="testInfo.yankezy" @change="testChange($event, 'yankezyqtdes')">
          <el-radio label="0">正常</el-radio>
          <el-radio label="1">异常</el-radio>
          <el-radio label="99">其他</el-radio>
          <el-input class="input1" v-if="testInfo.yankezy === '99'" v-model="testInfo.yankezyqtdes"></el-input>
        </el-radio-group>
      </div>
      <div class="son-item son-width1">
        <div class="key">
          <span>色盲：</span>
        </div>
        <el-radio-group v-model="testInfo.yankesm" @change="testChange($event, 'yankesmqtdes')">
          <el-radio label="0">正常</el-radio>
          <el-radio label="1">异常</el-radio>
          <el-radio label="99">其他</el-radio>
          <el-input class="input1" v-if="testInfo.yankesm === '99'" v-model="testInfo.yankesmqtdes"></el-input>
        </el-radio-group>
      </div>
      <div class="son-item son-width1">
        <div class="key">
          <span>色觉：</span>
        </div>
        <el-radio-group v-model="testInfo.yankesej" @change="testChange($event, 'yankesjqtdes')">
          <el-radio label="0">正常</el-radio>
          <el-radio label="1">异常</el-radio>
          <el-radio label="99">其他</el-radio>
          <el-input class="input1" v-if="testInfo.yankesej === '99'" v-model="testInfo.yankesjqtdes"></el-input>
        </el-radio-group>
      </div>
      <!-- <div class="son-item son-item2">
        <div class="key">
          <span>左眼裸眼视力：</span>
        </div>
        <el-input class="input" v-model="testInfo.yankecylysl"></el-input>
      </div>
      <div class="son-item son-item2">
        <div class="key">
          <span>右眼裸眼视力：</span>
        </div>
        <el-input class="input" v-model="testInfo.yankeyylysl"></el-input>
      </div> -->
      <div class="son-item son-item2">
        <div class="key">
          <span>右眼屈光球镜s：</span>
        </div>
        <el-input class="input" v-model="testInfo.yankeyyqgqjs" placeholder="请输入数字"></el-input>
        <el-select v-model="yankeyyqgqjspj" placeholder="评价" @change="pjChange($event, 'yankeyyqgqjspj')">
          <el-option v-for="item in yyqgqjPjs" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="son-item son-item2">
        <div class="key">
          <span>左眼屈光球镜s：</span>
        </div>
        <el-input class="input" v-model="testInfo.yankecyqgqjs" placeholder="请输入数字"></el-input>
        <el-select v-model="yankecyqgqjspj" placeholder="评价" @change="pjChange($event, 'yankecyqgqjspj')">
          <el-option v-for="item in yyqgqjPjs" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="son-item son-item2">
        <div class="key">
          <span>右眼屈光柱镜c：</span>
        </div>
        <el-input class="input" v-model="testInfo.yankeyyqgzjs" placeholder="请输入数字"></el-input>
        <el-select v-model="yankeyyqgzjspj" placeholder="评价" @change="pjChange($event, 'yankeyyqgzjspj')">
          <el-option v-for="item in yyqgzjPjs" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="son-item son-item2">
        <div class="key">
          <span>左眼屈光柱镜c：</span>
        </div>
        <el-input class="input" v-model="testInfo.yankecyqgzjs" placeholder="请输入数字"></el-input>
        <el-select v-model="yankecyqgzjspj" placeholder="评价" @change="pjChange($event, 'yankecyqgzjspj')">
          <el-option v-for="item in yyqgzjPjs" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="son-item son-item2">
        <div class="key">
          <span>右眼屈光轴位A：</span>
        </div>
        <el-input class="input" v-model="testInfo.yankeyyqtzwa" placeholder="请输入数字"></el-input>
      </div>
      <div class="son-item son-item2">
        <div class="key">
          <span>左眼屈光轴位A：</span>
        </div>
        <el-input class="input" v-model="testInfo.yankecyqtzwa" placeholder="请输入数字"></el-input>
      </div>
    </div>
    <div class="son-title"  :class="{'son-red': (testInfo.ebhkzr | testInfo.ebhkyr | testInfo.ebhwedygm | testInfo.ebhwb | testInfo.ebhxj)}">
      <span>耳鼻喉科</span>
    </div>
    <div class="son-line">
      <div class="son-item son-width">
        <div class="key">
          <span>左耳：</span>
        </div>
        <el-radio-group v-model="testInfo.ebhkzr" @change="testChange($event, 'ebhkzrqtdes')">
          <el-radio label="0">未见异常</el-radio>
          <el-radio label="1">听力下降？</el-radio>
          <el-radio label="99">其他</el-radio>
          <el-input class="input1" v-if="testInfo.ebhkzr === '99'" v-model="testInfo.ebhkzrqtdes"></el-input>
        </el-radio-group>
      </div>
      <div class="son-item son-width">
        <div class="key">
          <span>右耳 ：</span>
        </div>
        <el-radio-group v-model="testInfo.ebhkyr" @change="testChange($event, 'ebhkyrqtdes')">
          <el-radio label="0">未见异常</el-radio>
          <el-radio label="1">听力下降？</el-radio>
          <el-radio label="99">其他</el-radio>
          <el-input class="input1" v-if="testInfo.ebhkyr === '99'" v-model="testInfo.ebhkyrqtdes"></el-input>
        </el-radio-group>
      </div>
      <div class="son-item son-width">
        <div class="key">
          <span>外耳道与鼓膜：</span>
        </div>
        <el-radio-group style="display: flex;flex-wrap: wrap" v-model="testInfo.ebhwedygm" @change="testChange($event, 'wedygmqt')">
          <el-radio label="0">正常</el-radio>
          <el-radio label="5">外耳道湿疹</el-radio>
          <el-radio label="6">耵聍</el-radio>
          <el-radio label="7">外耳畸形</el-radio>
          <el-radio label="8">外耳道肿块</el-radio>
          <el-radio label="1">鼓膜穿孔</el-radio>
          <el-radio label="2">真菌性鼓膜炎</el-radio>
          <el-radio label="3">耳痛伴听力障碍</el-radio>
          <el-radio label="4">耳溢液</el-radio>
          <el-radio label="99">其他</el-radio>
          <el-input class="input1" v-if="testInfo.ebhwedygm === '99'" v-model="testInfo.wedygmqt"></el-input>
        </el-radio-group>
      </div>
      <div class="son-item son-width">
        <div class="key">
          <span>外鼻：</span>
        </div>
        <el-radio-group v-model="testInfo.ebhwb" @change="testChange($event, 'wbqt')">
          <el-radio label="0">正常</el-radio>
          <el-radio label="1">外鼻畸形</el-radio>
          <el-radio label="2">外鼻皮肤异常</el-radio>
          <el-radio label="3">鼻出血</el-radio>
          <el-radio label="99">其他</el-radio>
          <el-input class="input1" v-if="testInfo.ebhwb === '99'" v-model="testInfo.wbqt"></el-input>
        </el-radio-group>
      </div>
      <div class="son-item son-width">
        <div class="key">
          <span>嗅觉：</span>
        </div>
        <el-radio-group v-model="testInfo.ebhxj" @change="testChange($event, 'xjqt')">
          <el-radio label="0">正常</el-radio>
          <el-radio label="1">嗅觉减退</el-radio>
          <el-radio label="2">嗅觉丧失</el-radio>
          <el-radio label="3">嗅觉过敏</el-radio>
          <el-radio label="4">嗅觉倒错</el-radio>
          <el-radio label="5">幻嗅</el-radio>
          <el-radio label="99">其他</el-radio>
          <el-input class="input1" v-if="testInfo.ebhxj === '99'" v-model="testInfo.xjqt"></el-input>
        </el-radio-group>
      </div>
    </div>
    <div class="son-title" :class="{'son-red': (testInfo.kqbtt | testInfo.kqkkqyz | testInfo.ebhwedygm )}">
      <span>口腔科</span>
    </div>
    <div class="son-line">
      <div class="son-item son-width">
        <div class="key">
          <span>扁桃体：</span>
        </div>
        <el-radio-group v-model="testInfo.kqbtt" @change="testChange($event, 'bttqt')">
          <el-radio label="0">未见异常</el-radio>
          <el-radio label="1">扁桃体肿大I</el-radio>
          <el-radio label="2">扁桃体肿大II</el-radio>
          <el-radio label="3">扁桃体肿大III</el-radio>
          <el-radio label="99">其他</el-radio>
          <el-input class="input1" v-if="testInfo.kqbtt === '99'" v-model="testInfo.bttqt"></el-input>
        </el-radio-group>
      </div>
      <div class="son-item son-width">
        <div class="key">
          <span>牙周：</span>
        </div>
        <el-radio-group v-model="testInfo.kqkkqyz" @change="testChange($event, 'kqkkqyzqtdes')">
          <el-radio label="0">正常</el-radio>
          <el-radio label="1">牙龈炎</el-radio>
          <el-radio label="99">其他</el-radio>
          <el-input class="input1" v-if="testInfo.kqkkqyz === '99'" v-model="testInfo.kqkkqyzqtdes"></el-input>
        </el-radio-group>
      </div>
      <div class="son-item son-item2">
        <div class="key">
          <span>口腔牙齿数：</span>
        </div>
        <el-input class="input" v-model="testInfo.kqkkqyacs"></el-input>
      </div>
      <div class="son-item son-width1">
        <el-checkbox :checked="testInfo.kqkqy == '1'" @change="checkbox($event, 'kqkqy')">缺齿</el-checkbox>
        <el-checkbox :checked="testInfo.kqkdsy == '1'" @change="checkbox($event, 'kqkdsy')">多生牙</el-checkbox>
      </div>
      <div class="son-item son-item2">
        <div class="key">
          <span>口腔龋齿数：</span>
        </div>
        <el-input class="input" v-model="testInfo.kqkkqrcs"></el-input>
      </div>
      <div class="son-item son-item2">
        <div class="key">
          <span>口腔失齿数：</span>
        </div>
        <el-input class="input" v-model="testInfo.kqscnum"></el-input>
      </div>
      <div class="son-item son-item2">
        <div class="key">
          <span>口腔补齿数：</span>
        </div>
        <el-input class="input" v-model="testInfo.kqbcnum"></el-input>
      </div>
    </div>
    <div class="son-title" :class="{'son-red': (testInfo.sgpj | testInfo.tzpj | testInfo.bmipj | testInfo.ytbpj )}">
      <span>形态指标</span>
    </div>
    <div class="son-line">
      <div class="son-item son-width">
        <div class="key">
          <span>身长（高）：</span>
        </div>
        <el-input class="input" v-model="testInfo.xtzbsg"></el-input>
        <div class="key" style="margin-left: 20px">
          <span>评价：</span>
        </div>
        <el-radio-group v-model="testInfo.sgpj">
          <el-radio label="0">正常</el-radio>
          <el-radio label="1">偏矮</el-radio>
          <el-radio label="2">偏高</el-radio>
        </el-radio-group>
      </div>
      <div class="son-item son-width">
        <div class="key">
          <span>体重：</span>
        </div>
        <el-input class="input" v-model="testInfo.xtzbtz"></el-input>
        <div class="key" style="margin-left: 20px">
          <span>评价：</span>
        </div>
        <el-radio-group v-model="testInfo.tzpj">
          <el-radio label="0">正常</el-radio>
          <el-radio label="1">较轻</el-radio>
          <el-radio label="2">超重</el-radio>
        </el-radio-group>
      </div>
      <div class="son-item son-width">
        <div class="key">
          <span>BMI：</span>
        </div>
        <el-input class="input" v-model="testInfo.bmi"></el-input>
        <div class="key" style="margin-left: 20px">
          <span>评价：</span>
        </div>
        <el-radio-group v-model="testInfo.bmipj">
          <el-radio label="0">正常</el-radio>
          <el-radio label="1">偏瘦</el-radio>
          <el-radio label="2">消瘦</el-radio>
          <el-radio label="3">超重</el-radio>
          <el-radio label="4">肥胖</el-radio>
          <el-radio label="5">发育迟缓</el-radio>
        </el-radio-group>
      </div>
      <div class="son-item son-item2">
        <div class="key">
          <span>腰围：</span>
        </div>
        <el-input class="input" v-model="testInfo.xtzbyw"></el-input>
      </div>
      <div class="son-item son-item2">
        <div class="key">
          <span>臀围：</span>
        </div>
        <el-input class="input" v-model="testInfo.xtzbtww"></el-input>
      </div>
      <div class="son-item son-width">
        <div class="key">
          <span>腰臀比：</span>
        </div>
        <el-input class="input" v-model="testInfo.xtzbytb"></el-input>
        <div class="key" style="margin-left: 20px">
          <span>评价：</span>
        </div>
        <el-radio-group v-model="testInfo.ytbpj">
          <el-radio label="0">正常</el-radio>
          <el-radio label="1">中央性肥胖</el-radio>
        </el-radio-group>
      </div>
    </div>
    <div class="son-title" :class="{'son-red': (testInfo.ssypj | testInfo.szypj | testInfo.mlpj )}">
      <span>血压</span>
    </div>
    <div class="son-line">
      <div class="son-item son-item2">
        <div class="key">
          <span>收缩压：</span>
        </div>
        <el-input class="input" v-model="testInfo.xyssy"></el-input>
        <div class="key" style="margin-left: 20px">
          <span>评价：</span>
        </div>
        <el-radio-group v-model="testInfo.ssypj">
          <el-radio label="0">血压正常</el-radio>
          <el-radio label="1">血压偏低</el-radio>
          <el-radio label="2">血压偏高</el-radio>
        </el-radio-group>
      </div>
      <div class="son-item son-item2">
        <div class="key">
          <span>舒张压：</span>
        </div>
        <el-input class="input" v-model="testInfo.xyszy"></el-input>
        <div class="key" style="margin-left: 20px">
          <span>评价：</span>
        </div>
        <el-radio-group v-model="testInfo.szypj">
          <el-radio label="0">血压正常</el-radio>
          <el-radio label="1">血压偏低</el-radio>
          <el-radio label="2">血压偏高</el-radio>
        </el-radio-group>
      </div>
      <div class="son-item son-item2">
        <div class="key">
          <span>脉率：</span>
        </div>
        <el-input class="input" v-model="testInfo.xyml"></el-input>
        <div class="key" style="margin-left: 20px">
          <span>评价：</span>
        </div>
        <el-radio-group v-model="testInfo.mlpj">
          <el-radio label="0">脉率正常</el-radio>
          <el-radio label="1">脉率偏低</el-radio>
          <el-radio label="2">脉率偏高</el-radio>
        </el-radio-group>
      </div>
    </div>
    <div class="son-title" :class="{'son-red': testInfo.fggpj === '1' }">
      <span>肺功能检测</span>
    </div>
    <div class="son-line">
      <div class="son-item son-item2">
        <div class="key">
          <span>肺活量：</span>
        </div>
        <el-input class="input" v-model="testInfo.fggnjcfhl"></el-input>
        <div class="key">
          <span>L</span>
        </div>
        <div class="key" style="margin-left: 20px">
          <span>肺功能评估指标：</span>
        </div>
        <el-input class="input" v-model="testInfo.fgnpgzb"></el-input>
        <div class="key">
          <span>%</span>
        </div>
        <el-radio-group v-model="testInfo.fggpj" style="margin-left: 20px">
          <el-radio label="0">正常</el-radio>
          <el-radio label="1">肺活量不足</el-radio>
        </el-radio-group>
      </div>
    </div>
    <div class="son-title">
      <span>结核菌素试验</span>
    </div>
    <div class="son-line">
      <div class="son-item son-item2">
        <div class="key">
          <span>PPD结核菌素试验：</span>
        </div>
        <el-radio-group v-model="testInfo.jhjssy">
          <el-radio label="0">阴性(无反应或&lt;5mm)</el-radio>
          <el-radio label="1">一般阳性(≥5mm&lt;10mm)</el-radio>
          <el-radio label="2">中阳性(≥10mm&lt;15mm)</el-radio>
          <el-radio label="3">强阳性（≥15mm）</el-radio>
        </el-radio-group>
      </div>
    </div>
    <div class="son-title">
      <span>血常规</span>
      <span class="blue" @click="openLook">（常规报告）</span>
    </div>
    <div class="son-line">
      <div class="son-item son-item2">
        <div class="key">
          <span>白细胞：</span>
        </div>
        <el-input class="input" v-model="testInfo.xcgbxb"></el-input>
        <div class="key unit">
          <span>10^9/L</span>
        </div>
      </div>
      <div class="son-item son-item2">
        <div class="key">
          <span>红细胞：</span>
        </div>
        <el-input class="input" v-model="testInfo.xcghxb"></el-input>
        <div class="key unit">
          <span>10^12/L</span>
        </div>
      </div>
      <div class="son-item son-item2">
        <div class="key">
          <span>血红蛋白：</span>
        </div>
        <el-input class="input" v-model="testInfo.xcgxhdb"></el-input>
        <div class="key unit">
          <span>g/L</span>
        </div>
      </div>
      <div class="son-item son-item2">
        <div class="key">
          <span>血小板：</span>
        </div>
        <el-input class="input" v-model="testInfo.xcgxxb"></el-input>
        <div class="key unit">
          <span>10^9/L</span>
        </div>
      </div>
    </div>
    <div class="son-title">
      <span>血生化</span>
      <span class="blue" @click="openLook">（常规报告）</span>
    </div>
    <div class="son-line">
      <div class="son-item son-width">
        <div class="key">
          <span>血清谷丙转氨酶：</span>
        </div>
        <el-input class="input" v-model="testInfo.xshxqgb"></el-input>
        <div class="key unit">
          <span>U/L</span>
        </div>
      </div>
      <div class="son-item son-item2">
        <div class="key">
          <span>状态：</span>
        </div>
        <el-radio-group v-model="testInfo.xshflag">
          <el-radio label="0">正常</el-radio>
          <el-radio label="1">异常</el-radio>
        </el-radio-group>
        <el-input class="input1" v-if="testInfo.xshflag === '1'" v-model="testInfo.xshpjdes"></el-input>
      </div>
      <!-- <div class="son-item son-item2">
        <div class="key">
          <span>血清谷草氨转酶：</span>
        </div>
        <el-input class="input" v-model="testInfo.xshxqgc"></el-input>
        <div class="key unit">
          <span>U/L</span>
        </div>
      </div>
      <div class="son-item son-item2">
        <div class="key">
          <span>总胆红素：</span>
        </div>
        <el-input class="input" v-model="testInfo.xshxqdhs"></el-input>
        <div class="key unit">
          <span>umol/L</span>
        </div>
      </div> -->
    </div>
    <div class="son-title"  :class="{'son-red': testInfo.xlcpjg === '2' }">
      <span>心理测评</span>
    </div>
    <div class="son-line">
      <div class="son-item son-item2">
        <div class="key">
          <span>是否评测：</span>
        </div>
        <el-radio-group v-model="testInfo.xlcpsfcp">
          <el-radio label="1">是</el-radio>
          <el-radio label="2">否</el-radio>
        </el-radio-group>
      </div>
      <div class="son-item son-item2">
        <div class="key">
          <span>心理测评：</span>
        </div>
        <el-radio-group v-model="testInfo.xlcpjg">
          <el-radio label="1">正常</el-radio>
          <el-radio label="2">异常</el-radio>
        </el-radio-group>
      </div>
    </div>
    <div class="son-title">
      <span>体检报告结论</span>
    </div>
    <div class="son-tab">
      <div class="tab-item" :class="{ 'tab-active': index === tabIndex }" v-for="(item, index) in tabs" :key="index"
        @click="tabClick(index)">
        <span>{{ item }}</span>
      </div>
    </div>
    <el-input type="textarea" placeholder="">
    </el-input>
    <div class="son-button">
      <el-button @click="dialogVisible = true">核查</el-button>
      <el-button @click="submit">确认</el-button>
    </div>
    <div class="son-button">
      <el-button>提交</el-button>
      <el-button>打印预览</el-button>
      <el-button>上传</el-button>
    </div>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%">
      <div style="height: 70px;">
        <span>确认数据无误，核查通过？</span>
      </div>
      <el-radio-group v-model="hcjgflag">
        <el-radio label="0">数据无误!</el-radio>
        <el-radio label="1">异常标记</el-radio>
      </el-radio-group>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="check">确 定</el-button>
      </span>
    </el-dialog>
    <Look v-if="showLook" @close="closeLook"></Look>
  </div>
</template>

<script>
import Look from './look.vue'
import { mapState } from 'vuex'
export default {
  components: {
    Look
  },
  data () {
    return {
      options: [],
      value: '',
      zz: false,
      sexs: [{
        value: '1',
        label: '男'
      }, {
        value: '2',
        label: '女'
      }],
      sex: '',
      radio: '',
      tabIndex: 0,
      tabs: Object.freeze(['体检异常小结', '建议', '健康指导', '营养指导', '运动指南', '温馨提示']),
      showLook: false,
      testInfo: {
        id: '',
        name: '',
        tjrq: '',
        xuehao: '',
        xb: '',
        sfjh: '',
        ssxx: '',
        ssxxid: '',
        ssbj: '',
        ssbjid: '',
        ssnj: '',
        ssnjid: '',
        whcd: '',
        whcdid: '',
        ssmc: '',
        ssmcid: '',
        jg: '',
        lxdh: '',
        xzz: '',
        hjdz: '',
        jzxx: '',
        lxrid: '',
        lxr: '',
        lxrxm: '',
        lxrdh: '',
        rxnf: '',
        hcrq: '',
        hcwcrq: '',
        jwsqm: '',
        jwsg: '',
        jwsfjj: '',
        jwsxtxxz: '',
        jwss: '',
        jwsfs: '',
        jwsdfb: '',
        jwsgms: '',
        jwscrbs: '',
        jwsqt: '',
        jwsjcqk: '',
        jwsjcys: '',
        jwsjcysid: '',
        jwspj: '',
        jwstjrq: '',
        jwsflag: '',
        tmcode: '',
        tjbh: '',
        nkfei: '',
        nkqm: '',
        nkfeides: '',
        nkfeiqtdes: '',
        nkfeipj: '',
        nkjcqk: '',
        nkjcys: '',
        nkjcysid: '',
        nkxz: '',
        nkxzdes: '',
        nkxzqtdes: '',
        nkxzpj: '',
        nkfub: '',
        nkfubdes: '',
        nkfubqtdes: '',
        nkfubp: '',
        nkgz: '',
        nkgzdes: '',
        nkgzqtdes: '',
        nkgzpj: '',
        nkpz: '',
        nkpzdes: '',
        nkpzqtdes: '',
        nkpzpj: '',
        nktjrq: '',
        nktjjy: '',
        nkflag: '',
        wkqm: '',
        wkpf: '',
        wkpfdes: '',
        wkpfqtdes: '',
        wkpfpj: '',
        wkjz: '',
        wkjzdes: '',
        wkjzqtdes: '',
        wkjzpj: '',
        wkxb: '',
        wkxbdes: '',
        wkxbqtdes: '',
        wkxbpj: '',
        wkjzsz: '',
        wkjzszdes: '',
        wkjzszqtdes: '',
        wkjzszpj: '',
        wktb: '',
        wktbdes: '',
        wktbqtdes: '',
        wktbpj: '',
        wklbj: '',
        wklbjdes: '',
        wklbjqtdes: '',
        wklbjpj: '',
        wkwszq: '',
        wkwszqdes: '',
        wkwszqqtdes: '',
        wkwszqpj: '',
        wkfzjcqtdes: '',
        wkjcys: '',
        wkjcysid: '',
        wkjcqk: '',
        wktjrq: '',
        wktjjy: '',
        wkflag: '',
        jzcwqgxzjxd: '',
        jzcwqgxzjxdzt: '',
        jzcwqgxzjxdztdes: '',
        jzcwqgxzjxydztdes: '',
        jzcwqgxzjydztdes: '',
        jzcwqgxzjxydzt: '',
        jzcwqgxzjydzt: '',
        jzcwjcqk: '',
        jzcwtjjl: '',
        jzcwqm: '',
        jzcwqgxzjxyd: '',
        jzcwqgxzjyd: '',
        jzcwckjl: '',
        jzcwpj: '',
        jzcwys: '',
        jzcwysid: '',
        jzcwtjrq: '',
        jzcwtjjy: '',
        jzcwflag: '',
        yankesm: '',
        yankeqm: '',
        yankesmdes: '',
        yankesmqtdes: '',
        yankezy: '',
        yankezydes: '',
        yankezyqtdes: '',
        yankesej: '',
        yankesjdes: '',
        yankesjqtdes: '',
        yankeyy: '',
        yankeyydes: '',
        yankeyyqtdes: '',
        yankecyqgd: '',
        yankecyqgddes: '',
        yankecyqgdqtdes: '',
        yankeyyqgd: '',
        yankeyyqgddes: '',
        yankeyyqgdqtdes: '',
        yankesy: '',
        yankesydes: '',
        yankesyqtdes: '',
        yankecylysl: '',
        yankeyylysl: '',
        yankewg: '',
        yankewgdes: '',
        yankesljzzy: '',
        yankesljzyy: '',
        yankecyqgqjs: '',
        yankeyyqgqjspj: '',
        yankeyyqgqjspjdes: '',
        yankeyyqgzjspj: '',
        yankeyyqgzjspjdes: '',
        yankecyqgqjspj: '',
        yankecyqgqjspjdes: '',
        yankecyqgzjspj: '',
        yankecyqgzjspjdes: '',
        yankeyyqgqjs: '',
        yankecyqgzjs: '',
        yankeyyqgzjs: '',
        yankecyqtzwa: '',
        yankeyyqtzwa: '',
        yankejcys: '',
        yankejcysid: '',
        yankejjcqk: '',
        yktjrq: '',
        yktjjy: '',
        ykflag: '',
        ebhkzr: '',
        ebhkqm: '',
        ebhkzrdes: '',
        ebhkzrqtdes: '',
        ebhkyr: '',
        ebhkyrdes: '',
        ebhkyrqtdes: '',
        ebhyhb: '',
        ebhbk: '',
        ebhbkdes: '',
        ebhbkqtdes: '',
        ebhjcys: '',
        ebhjcysid: '',
        ebhjcqk: '',
        ebhwedygm: '',
        ebhwedygmdes: '',
        ebhwbdes: '',
        ebhxjdes: '',
        ebhwb: '',
        ebhxj: '',
        ebhktjrq: '',
        ebhktjjy: '',
        ebhkflag: '',
        kqkkqyacs: '',
        kqkqm: '',
        kqkkqrcs: '',
        kqqcsflag: '',
        kqkkqcs: '',
        kqbtt: '',
        kqbttdes: '',
        kqkrrs: '',
        kqkrrb: '',
        kqkhrs: '',
        kqkhrh: '',
        kqkhrb: '',
        kqkkqyz: '',
        kqkkqyzdes: '',
        kqkkqyzqtdes: '',
        kqkjcys: '',
        kqkjcysid: '',
        kqjcqk: '',
        kqktjrq: '',
        kqktjjy: '',
        kqktjjl: '',
        kqkflag: '',
        xtzbtz: '',
        xtzbqm: '',
        xtzbsg: '',
        xtzbtgfypj: '',
        bmi: '',
        xtzbyw: '',
        xtzbtww: '',
        xtzbytb: '',
        xtzbbml: '',
        xitcqk: '',
        xtjcys: '',
        xtjcysid: '',
        xtzbtjrq: '',
        xtzbtjjy: '',
        xtzbflag: '',
        xyssy: '',
        xyqm: '',
        xyszy: '',
        xyml: '',
        xyjcqk: '',
        xyjcys: '',
        xyjcysid: '',
        xytjrq: '',
        xytjjy: '',
        xyflag: '',
        tlsctlz: '',
        tlqm: '',
        tlsctlzdes: '',
        tlsctlzqtdes: '',
        tlsctly: '',
        tlsctlydes: '',
        tlsctlyqtdes: '',
        tljcys: '',
        tljcysid: '',
        tljcqk: '',
        fhlqm: '',
        fggnjcfhl: '',
        fgnpgzb: '',
        fggjcqk: '',
        fggpj: '',
        fggjcys: '',
        fggjcysid: '',
        fgntjrq: '',
        fgntjjy: '',
        fgnflag: '',
        xcgqm: '',
        xcgbxb: '',
        bxbpj: '',
        hxbpj: '',
        xhdbpj: '',
        xxbpj: '',
        xcgpj: '',
        xcgpjdes: '',
        xcgxhdb: '',
        xcgxhdbdes: '',
        xcgxxb: '',
        xcgxxbdes: '',
        xcghxb: '',
        xcghxbdes: '',
        xcgjcqk: '',
        xcgjcys: '',
        xcgjcysid: '',
        ppdqm: '',
        ppdtjrq: '',
        ppdtjjy: '',
        ppdtjdes: '',
        ppdflag: '',
        ppdjcys: '',
        ppdjcysid: '',
        xcgtjrq: '',
        xcgtjjy: '',
        xcgflag: '',
        xshqm: '',
        xshtjrq: '',
        xshtjjy: '',
        xshflag: '',
        xshpjdes: '',
        xshxqgb: '',
        xshxqgbpj: '',
        xshxqgbpjdes: '',
        xshxqgc: '',
        xshxqdhs: '',
        xshbasanzym: '',
        xshjcqk: '',
        xshjcys: '',
        xshjcysid: '',
        jhjssy: '',
        jhjssydes: '',
        jhjssyjcqk: '',
        ncgndb: '',
        ncgnt: '',
        ncgntt: '',
        ncgnqx: '',
        ncgjcys: '',
        ncgjcysid: '',
        ncgjcqk: '',
        xlcpsfcp: '',
        xlcpjg: '',
        xlcpjgdes: '',
        xlcpqk: '',
        xlcpys: '',
        xlcpysid: '',
        tjycxj: '',
        tjycxjys: '',
        tjycxjysid: '',
        tjycyj: '',
        tjjkzd: '',
        tjyyzd: '',
        tjydzn: '',
        tjwxts: '',
        tjtcid: '',
        tjtcmc: '',
        czry: '',
        czryid: '',
        tjjd: '',
        tjjdid: '',
        tjxmlb: '',
        tjxmlbid: '',
        tjzt: '',
        bgkfflag: '',
        unionid: '',
        openid: '',
        dnbh: '',
        gmsqt: '',
        crsqt: '',
        ywgqt: '',
        yktjjl: '',
        wedygmqt: '',
        wbqt: '',
        xjqt: '',
        bttqt: '',
        kqkqy: '',
        kqkdsy: '',
        sgpj: '',
        sgpjdes: '',
        tzpj: '',
        tzpjdes: '',
        bmipj: '',
        bmipjdes: '',
        ytbpj: '',
        ytbpjdes: '',
        ssypj: '',
        ssypjdes: '',
        szypj: '',
        szypjdes: '',
        mlpj: '',
        mlpjdes: '',
        age: '',
        sfzh: '',
        tjjgbh: '',
        tjjgmc: '',
        zjys: '',
        zjysqm: '',
        lssjsg: '',
        lssjtz: '',
        ssjgbh: '',
        ssjgmc: '',
        sfyy: '',
        bgpwd: '',
        bgurl: '',
        csrq: ''
      },
      ppdtjjys: [],
      yankeyyqgqjspj: '',
      yankecyqgqjspj: '',
      yyqgqjPjs: [
        { label: '正常', value: '0' },
        { label: '近视', value: '1' },
        { label: '远视', value: '2' }
      ],
      yankeyyqgzjspj: '',
      yankecyqgzjspj: '',
      yyqgzjPjs: [
        { label: '正常', value: '0' },
        { label: '近视散光', value: '1' },
        { label: '远视散光', value: '2' }
      ],
      fggPjs: [
        { label: '正常', value: '0' },
        { label: '肺活量不足', value: '1' }
      ],
      sgPjs: [
        { label: '正常', value: '0' },
        { label: '偏矮', value: '1' },
        { label: '偏高', value: '2' }
      ],
      tzPjs: [
        { label: '正常', value: '0' },
        { label: '较轻', value: '1' },
        { label: '超重', value: '2' }
      ],
      bimPjs: [
        { label: '正常', value: '0' },
        { label: '偏瘦', value: '1' },
        { label: '肥胖', value: '2' }
      ],
      ytbPjs: [
        { label: '正常', value: '0' },
        { label: '中央性肥胖', value: '1' }
      ],
      xyPjs: [
        { label: '血压正常', value: '0' },
        { label: '血压偏低', value: '1' },
        { label: '血压偏高', value: '2' }
      ],
      mlPjs: [
        { label: '脉率正常', value: '0' },
        { label: '脉率偏低', value: '1' },
        { label: '脉率偏高', value: '2' }
      ],
      tmcode: '',
      dialogVisible: false,
      hcjgflag: ''
    }
  },
  computed: {
    ...mapState(['loginInfo'])
  },
  created () {
    this.getInfo()
  },
  methods: {
    selectChange (e) {
      console.log(e)
      // if (e) {
      //   this.testInfo.jwsxtxxz = '1'
      // } else {
      //   this.testInfo.jwsxtxxz = '0'
      // }
    },
    tabClick (index) {
      this.tabIndex = index
    },
    async getInfo () {
      // 接口文档 https://app.apifox.com/project/2726972/apis/api-159174148
      if (this.$parent.selectTmcode === '') return
      try {
        const { data: res } = await this.$http.post('/zhctapi/findtmjl', { tmcode: this.$parent.selectTmcode })
        console.log('根据条码获取记录2', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.testInfo = res.data
        // this.testInfo.jwsxtxxz = '1'
        // this.testInfo.kqkqy = '1'
        // if (this.testInfo.jwsxtxxz === '1') {
        //   this.testInfo.jwsxtxxz = true
        // } else {
        //   this.testInfo.jwsxtxxz = false
        // }
        this.$nextTick(() => {
          document.getElementById('son').scrollIntoView()
        })
      } catch (error) {
      }

      // // 接口文档 https://app.apifox.com/project/2726972/apis/api-129593483
      // try {
      //   const { data: res } = await this.$http.post('/api/jkct/app/findtjxx', { tmcode: this.$parent.$parent.selectInfo.tmcode, sfzh: this.$parent.$parent.selectInfo.sfzh })
      //   console.log('体检记录--查询单条信息', res)
      //   if (res.code !== 0) return this.$message.error(res.msg || res.message)
      //   this.testInfo = res.data
      // } catch (error) {
      // }
    },
    checkbox (e, key) {
      console.log(e, key)
      this.testInfo[key] = e ? '1' : ''
    },
    testChange (e, key) {
      console.log(e, key)
      if (e !== '99') {
        this.testInfo[key] = ''
      }
    },
    pjChange (e, key) {
      console.log(e, key)
      this.testInfo[key] = e
    },
    openLook () {
      this.showLook = true
    },
    closeLook () {
      this.showLook = false
    },
    async check () {
      // 文档 https://doc.weixin.qq.com/doc/w3_AAMAewYpAFEq8gTvbIPRzyA2OryNy?scode=ACwAmwdjAAYF616P70AAMAewYpAFE
      var date = new Date()
      var year = date.getFullYear()
      var month = date.getMonth() + 1
      var strDate = date.getDate()
      if (month >= 1 && month <= 9) {
        month = '0' + month
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = '0' + strDate
      }
      // this.$alert('确认数据无误，核查通过？', '提示', {
      //   confirmButtonText: '确定',
      //   callback: async action => {
      //     console.log(action)
      //     try {
      //       const { data: res } = await this.$http.post('/zhctapi/savexsctxx', {
      //         ...this.testInfo,
      //         hcrq: year + '-' + month + '-' + strDate,
      //         zjys: this.loginInfo.username || '',
      //         zjysid: this.loginInfo.userid || ''
      //       })
      //       console.log('体检数据保存', res)
      //       if (res.code !== 0) return this.$message.error(res.msg || res.message)
      //       this.$message.success('保存成功')
      //     } catch (error) {
      //       console.log('体检数据保存', error)
      //     }
      //   }
      // })
      // if (this.testInfo.jwsxtxxz) {
      //   this.testInfo.jwsxtxxz = '1'
      // } else {
      //   this.testInfo.jwsxtxxz = '0'
      // }
      try {
        const { data: res } = await this.$http.post('/zhctapi/savexsctxx', {
          ...this.testInfo,
          hcrq: year + '-' + month + '-' + strDate,
          zjys: this.loginInfo.username || '',
          zjysid: this.loginInfo.usercode || '',
          hcjgflag: this.hcjgflag,
          hcflag: '1'
        })
        console.log('体检数据保存', res)
        this.dialogVisible = false
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.$message.success('保存成功')
        this.hcjgflag = ''
        this.$parent.$parent.getInfo()
      } catch (error) {
        console.log('体检数据保存', error)
        this.dialogVisible = false
      }
    },
    async submit () {
      try {
        console.log(this.testInfo)
        const { data: res } = await this.$http.post('/zhctapi/savexsctxx', this.testInfo)
        console.log('体检数据保存', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.$message.success('保存成功')
      } catch (error) {
        console.log('体检数据保存', error)
      }
    }
  }
}
</script>

<style lang="less" scoped>

.son {
  overflow-y: scroll;
  width: 100%;
  height: 100%;

  &::-webkit-scrollbar {
    width: 0;
  }

  .son-line {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .son-item {
      display: flex;
      align-items: center;
      margin: 8px 30px;

      .key {
        white-space: nowrap;
        color: #333333;
        font-size: 18px;
      }

      .unit {
        margin-left: 7px;
      }

      .input {
        max-width: 191px;
      }

      .input1 {
        max-width: 191px;
        margin-left: 10px;
      }

      .el-radio-group {
        display: flex;
        align-items: center;
      }

      /deep/.el-radio {
        display: flex;
        align-items: center;
      }

      /deep/.el-radio__inner {
        width: 20px;
        height: 20px;
      }

      /deep/.el-radio__label {
        color: #333333;
        font-size: 18px;
      }

      /deep/.el-checkbox__label {
        color: #333333;
        font-size: 18px;
      }
    }

    .son-width {
      width: calc(100% - 60px);
    }

    .son-width1 {
      width: calc(50% - 60px);
    }

    // .son-item1 {
    //   width: calc(50% - 20px);
    // }

    // .son-item2 {
    //   width: calc(33.3% - 20px);
    // }

    // .son-item3 {
    //   width: calc(30% - 20px);
    // }

    // .son-item4 {
    //   width: calc(70% - 20px);
    // }

    // .son-item5 {
    //   width: calc(66.7% - 20px);
    // }
  }

  .son-title {
    width: 100%;
    height: 47px;
    line-height: 47px;
    border-bottom: 1px solid #707070;
    box-sizing: border-box;
    color: #333333;
    font-size: 18px;
    font-weight: 600;

    .blue {
      color: #3FA1FF;
      cursor: pointer;
    }
  }

  .son-red {
    color: red;
  }

  .son-tab {
    display: flex;
    width: calc(100% - 40px);
    height: 40px;
    margin: 19px auto 10px;
    background-color: #B0B0B0;

    .tab-item {
      flex: 1;
      height: 40px;
      line-height: 40px;
      text-align: center;
      color: #fff;
      font-size: 18px;
      cursor: pointer;
    }

    .tab-active {
      background-color: #3FA1FF;
    }
  }

  .el-textarea {
    width: calc(100% - 40px);
    height: 180px;

    /deep/.el-textarea__inner {
      width: 100%;
      height: 100%;
      margin: 0 auto;
      resize: none;
    }
  }

  .son-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100px;

    .el-button {
      min-width: 150px;
      margin-left: 15px;
      margin-right: 15px;
      padding-top: 4px;
      padding-bottom: 7px;
      background-color: #3FA1FF;
      border: none;
      color: #fff;
      font-size: 30px;
    }
  }
}
</style>
