<template>
  <div class="see">
    <div class="see-status">
      <span>预定待提交</span>
    </div>
    <div class="see-title">
      <div class="title">
        <span>入院详情</span>
      </div>
      <el-input placeholder="姓名/身份证号" suffix-icon="el-icon-search" class="input">
      </el-input>
    </div>
    <div class="see-box">
      <div class="see-box-title">
        <span>申请信息</span>
      </div>
      <div class="see-content">
        <div class="see-item">
          <div class="see-key">
            <span>申请人：李明明</span>
          </div>
        </div>
        <div class="see-item">
          <div class="see-key">
            <span>申请时间：2002-03-03 15：00</span>
          </div>
        </div>
        <div class="see-item">
          <div class="see-key">
            <span>申请状态：待提交</span>
          </div>
        </div>
      </div>
      <div class="see-box-title1">
        <span>老人信息</span>
      </div>
      <div class="see-content">
        <div class="see-item">
          <div class="see-key">
            <span>老人姓名：陈菊英</span>
          </div>
        </div>
        <div class="see-item">
          <div class="see-key">
            <span>身份证号：329819982933233</span>
          </div>
        </div>
        <div class="see-item">
          <div class="see-key">
            <span>护理等级：</span>
          </div>
        </div>
      </div>
      <div class="see-info">
        <div class="see-info-key">
          <span>家属信息</span>
        </div>
        <div class="see-table">
          <div class="see-tr">
            <span class="see-td">家属姓名</span>
            <span class="see-td">与老人关系</span>
            <span class="see-td">联系方式</span>
          </div>
          <div class="see-tr">
            <span class="see-td">张三</span>
            <span class="see-td">父子</span>
            <span class="see-td">177627382737</span>
          </div>
          <div class="see-tr">
            <span class="see-td">张三</span>
            <span class="see-td">父子</span>
            <span class="see-td">177627382737</span>
          </div>
        </div>
      </div>
      <div class="see-box-title1">
        <span>订单信息</span>
      </div>
      <div class="see-content">
        <div class="see-item">
          <div class="see-key">
            <span>下单人：</span>
          </div>
        </div>
        <div class="see-item">
          <div class="see-key">
            <span>下单时间：</span>
          </div>
        </div>
        <div class="see-item">
          <div class="see-key">
            <span>入住床位：</span>
          </div>
        </div>
        <div class="see-item">
          <div class="see-key">
            <span>入住床入住时段：</span>
          </div>
        </div>
        <div class="see-item">
          <div class="see-key">
            <span>护理服务：</span>
          </div>
        </div>
        <div class="see-item see-item1">
          <div class="see-key">
            <span>订单合同：</span>
          </div>
        </div>
      </div>
      <div class="see-box-title1">
        <span>财务信息</span>
      </div>
      <div class="see-content">
        <div class="see-item">
          <div class="see-key">
            <span>床位费：</span>
          </div>
        </div>
        <div class="see-item">
          <div class="see-key">
            <span>护理费：</span>
          </div>
        </div>
        <div class="see-item">
          <div class="see-key">
            <span>膳食费：</span>
          </div>
        </div>
        <div class="see-item">
          <div class="see-key">
            <span>押金：</span>
          </div>
        </div>
        <div class="see-item">
          <div class="see-key">
            <span>一次性生活费：</span>
          </div>
        </div>
        <div class="see-item see-item1">
          <div class="see-key">
            <span>合计：</span>
          </div>
        </div>
      </div>
    </div>
    <div class="see-btns">
      <el-button size="small" class="gray" @click="close">返回</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      input: '',
      options: [{
        value: '',
        label: ''
      }],
      value: ''
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="less">
.see {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0 40px;
  box-sizing: border-box;
  z-index: 88;
  background-color: #081C38;

  .see-status {
    position: absolute;
    top: 18%;
    right: 50px;
    color: #FF0000;
    font-size: 20px;
  }

  .see-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;

    .title {
      height: 38px;
      line-height: 38px;
      text-align: center;
      color: #01EEFD;
      font-size: 20px;
    }

    .input {
      width: 246px;

      /deep/.el-input__inner {
        border: none;
        background: linear-gradient(153deg, #0A477B 0%, #0E67AD 100%);
        color: #fff;
        font-size: 18px;
      }

      /deep/.el-input__inner::-webkit-input-placeholder {
        color: #fff;
      }

      /deep/.el-input__suffix {
        color: #fff;
        font-size: 18px;
      }
    }
  }

  .see-box {
    overflow-y: scroll;
    height: calc(100% - 140px);

    &::-webkit-scrollbar {
      width: 0;
    }

    .see-box-title {
      height: 40px;
      line-height: 40px;
      padding: 0 10px;
      box-sizing: border-box;
      color: #01EEFD;
      font-size: 18px;
    }

    .see-content {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 10px 20px;
      box-sizing: border-box;

      .see-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 25%;
        margin-bottom: 20px;

        .see-key {
          white-space: nowrap;
          color: #01EEFD;
          font-size: 18px;
        }

        .el-input {
          width: 180px;
        }
      }

      .see-item1 {
        width: 75%;
      }
    }

    .see-box-title1 {
      height: 40px;
      line-height: 40px;
      border-bottom: 1px solid #01EEFD;
      box-sizing: border-box;
      color: #01EEFD;
      font-size: 20px;
    }

    .see-info {
      display: flex;
      flex-direction: row;
      padding-left: 20px;
      box-sizing: border-box;

      .see-info-key {
        color: #01EEFD;
        font-size: 20px;
      }

      .see-table {
        width: 540px;
        height: 135px;
        margin-left: 20px;
        border: 1px solid #01EEFD;
        border-right: none;
        border-bottom: none;
        box-sizing: border-box;

        .see-tr {
          display: flex;
          flex-direction: row;
          align-items: center;
          height: 45px;
          border-bottom: 1px solid #01EEFD;
          box-sizing: border-box;

          .see-td {
            flex: 1;
            height: 45px;
            line-height: 45px;
            border-right: 1px solid #01EEFD;
            box-sizing: border-box;
            text-align: center;
            color: #01EEFD;
            font-size: 20px;
          }
        }
      }
    }
  }

  .see-btns {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 60px;

    .el-button {
      margin-right: 30px;
      background-color: #024A83;
      border: none;
      color: #fff;
    }

    .gray {
      background-color: #33465C;
    }
  }

}
</style>
