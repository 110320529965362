<template>
  <div class="son">
    <div class="son-search">
      <div class="son-item">
        <div class="key">
          <span>负责人</span>
        </div>
        <el-input placeholder="请输入执行人姓名"></el-input>
      </div>
      <div class="son-item">
        <div class="key">
          <span>任务状态</span>
        </div>
        <el-select v-model="value" placeholder="请选择">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <el-button type="primary">搜索</el-button>
      <el-button type="primary">重置</el-button>
    </div>
    <div class="son-label">
      <div class="label-item" :class="{ 'label-active': labelIndex === 0 }" @click="labelClick(0)">
        <span>全部(100)</span>
      </div>
      <div class="label-item" :class="{ 'label-active': labelIndex === 1 }" @click="labelClick(1)">
        <span>进行中(100)</span>
      </div>
      <div class="label-item" :class="{ 'label-active': labelIndex === 2 }" @click="labelClick(2)">
        <span>已完成(100)</span>
      </div>
      <div class="label-item" :class="{ 'label-active': labelIndex === 3 }" @click="labelClick(3)">
        <span>预期未完成(100)</span>
      </div>
    </div>
    <div class="son-scroll">
      <div class="son-card">
        <div class="card-item" v-for="(item, index) in list" :key="index" @click="openTaskDetails(item)">
          <div class="card-line">
            <div class="card-text">
              <span>{{ item.rwmc }}</span>
            </div>
            <img src="../../img/emr/user.png" class="card-img">
          </div>
          <div class="card-line">
            <span>{{ item.rwzt }}</span>
          </div>
          <div class="card-line">
            <span>截止日期:{{ item.rwsj }}</span>
          </div>
        </div>
      </div>
    </div>

    <TaskDetails v-if="showTaskDetails" @close="closeTaskDetails"></TaskDetails>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import TaskDetails from './taskDetails.vue'
export default {
  components: {
    TaskDetails
  },
  data () {
    return {
      options: [{
        value: '',
        label: ''
      }],
      value: '',
      labelIndex: 0,
      showTaskDetails: false,
      params: {
        start: '0',
        length: '10',
        ssjgbh: ''
      },
      list: [],
      sfzh: '',
      rwid: ''
    }
  },
  computed: {
    ...mapState(['loginInfo'])
  },
  created () {
    this.params.ssjgbh = this.loginInfo.jgbh
    this.getList()
  },
  methods: {
    // 任务列表
    async getList () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-100422192
      try {
        const { data: res } = await this.$http.get('/ykdrwkbs', { params: this.params })
        console.log('任务列表', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.list = res.data
      } catch (error) {
        console.log('任务列表请求失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    labelClick (index) {
      this.labelIndex = index
    },
    openTaskDetails (item) {
      this.showTaskDetails = true
      this.sfzh = item.sfzh
      this.rwid = item.rwid
    },
    closeTaskDetails (e) {
      this.showTaskDetails = false
      if (e === 'loading') {
        this.getList()
      }
      this.sfzh = ''
      this.rwid = ''
    }
  }
}
</script>

<style lang="less" scoped>
.son {
  width: 100%;
  height: 100%;

  .son-search {
    display: flex;
    align-items: center;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    .son-item {
      display: flex;
      align-items: center;
      margin-right: 30px;

      .key {
        margin-right: 10px;
        white-space: nowrap;
        color: #000;
        font-size: 22px;
      }
    }

    .el-button {
      height: 38px;
    }
  }

  .son-label {
    display: flex;
    align-items: center;
    width: 100%;
    height: 79px;

    .label-item {
      height: 30px;
      line-height: 30px;
      margin-right: 30px;
      padding: 0 20px;
      text-align: center;
      background-color: #284867;
      border: 1px solid rgba(0, 66, 255, .28);
      color: #fff;
      font-size: 18px;
      box-sizing: border-box;
      cursor: pointer;
    }

    .label-active {
      background-color: #409EFF;
    }
  }

  .son-scroll {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 160px);

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  .son-card {
    display: flex;
    flex-wrap: wrap;
    align-content: start;
    width: 100%;

    .card-item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 18%;
      height: 130px;
      margin-left: calc((100% - (5 * 130px)) / 100);
      margin-right: calc((100% - (5 * 130px)) / 100);
      margin-bottom: 20px;
      padding: 10px 29px;
      background-color: #04B4D0;
      border-radius: 6px;
      box-sizing: border-box;
      cursor: pointer;

      .card-line {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #fff;
        font-size: 16px;

        .card-text {
          color: #fff;
          font-size: 18px;
        }

        .card-img {
          width: 48px;
          height: 48px;
        }
      }
    }
  }
}
</style>
