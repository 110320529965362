<template>
  <div class="son" :class="{ son1: showSee }">
    <div class="son-head">
      <div class="son-head-item">
        <div class="son-head-left">
          <div class="son-head-open"></div>
          <el-input placeholder="请输入搜索内容"></el-input>
        </div>
        <div class="son-head-center">
          <span>切换机构：</span>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="son-head-right">
          <span class="el-icon-success success"></span>
          <div class="div">
            <div class="div1">
              <div class="blue"></div>
            </div>
          </div>
          <span class="el-icon-lock lock"></span>
          <span class="el-icon-view lock"></span>
          <span class="el-icon-rank rank"></span>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <!-- <div class="son-head-item">
        <div class="son-head-title">
          <span>查体管理</span>
        </div>
        <el-button type="primary">
          <span>更多</span>
          <span class="el-icon-arrow-down icon"></span>
        </el-button>
      </div> -->
    </div>
    <div class="son-scroll">
      <div class="son-search">
        <div class="search-item">
          <div class="key">
            <span>机构</span>
          </div>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="search-item">
          <div class="key">
            <span>套餐</span>
          </div>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="search-item">
          <div class="key">
            <span>体检编号</span>
          </div>
          <el-input placeholder="请输入" suffix-icon="el-icon-caret-bottom"></el-input>
        </div>
        <div class="search-item">
          <div class="key">
            <span>姓名</span>
          </div>
          <el-select v-model="value" placeholder="姓名或证件号码">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="search-item">
          <div class="key">
            <span>状态</span>
          </div>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="search-item">
          <div class="key">
            <span>检查日期</span>
          </div>
          <el-date-picker v-model="value1" type="daterange" range-separator="至" start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </div>
        <div class="search-item">
          <div class="key">
            <span>核查日期</span>
          </div>
          <el-date-picker v-model="value1" type="daterange" range-separator="至" start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </div>
        <div class="search-item">
          <div class="key">
            <span>登记类型</span>
          </div>
          <el-input placeholder="请输入" suffix-icon="el-icon-caret-bottom"></el-input>
        </div>
        <div class="search-item">
          <div class="key">
            <span>所属</span>
          </div>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="button">
          <el-button>
            <span class="el-icon-search"></span>
            <span>查询</span>
          </el-button>
          <el-button>
            <span class="el-icon-refresh"></span>
            <span>重置</span>
          </el-button>
          <el-button>
            <span class="el-icon-upload"></span>
            <span>导出</span>
          </el-button>
          <el-button @click="openAdd">
            <span class="el-icon-plus"></span>
            <span>新增</span>
          </el-button>
        </div>
      </div>

      <div class="son-info">
        <div class="son-info-title">
          <div class="info-item info-item5">
            <span>序号</span>
          </div>
          <div class="info-item info-item1">
            <span>机构</span>
          </div>
          <div class="info-item info-item2">
            <span>套餐</span>
          </div>
          <div class="info-item info-item1">
            <span>体检编号</span>
          </div>
          <div class="info-item">
            <span>姓名</span>
          </div>
          <div class="info-item info-item6">
            <span>证件号码</span>
          </div>
          <div class="info-item info-item3">
            <span>检查日期</span>
          </div>
          <div class="info-item info-item3">
            <span>核查日期</span>
          </div>
          <div class="info-item info-item7">
            <span>状态</span>
          </div>
          <div class="info-item info-item1">
            <span>所属学校</span>
          </div>
          <div class="info-item ">
            <span>所属班级</span>
          </div>
          <div class="info-item info-item4">
            <span>操作</span>
          </div>
        </div>
        <div class="son-info-scroll">
          <!-- <div class="scroll-list">
            <div class="info-item info-item5">
              <span>1</span>
            </div>
            <div class="info-item info-item1">
              <span>古城营镇卫生院</span>
            </div>
            <div class="info-item info-item2">
              <span>儿童入园（所）健康检查</span>
            </div>
            <div class="info-item info-item1">
              <span>1580333654956</span>
            </div>
            <div class="info-item">
              <span>李明明</span>
            </div>
            <div class="info-item info-item6">
              <span>1***************38</span>
            </div>
            <div class="info-item info-item3">
              <span>2023-08-03</span>
            </div>
            <div class="info-item info-item3">
              <span>2023-08-03</span>
            </div>
            <div class="info-item info-item7">
              <span class="green">体检已完成</span>
            </div>
            <div class="info-item info-item1">
              <span>石家庄第一中学</span>
            </div>
            <div class="info-item">
              <span>203班</span>
            </div>

            <div class="info-item info-item4">
              <span class="span" @click="openSchedule">进度</span>
              <span class="span" @click="openSee">查看</span>
              <span class="span" @click="openReport">报告</span>
              <el-popover popper-class="popper" placement="bottom-start" width="86" trigger="click">
                <div class="popover" :style="style">
                  <div class="popover-item" :style="style1">
                    <span>查看上传</span>
                  </div>
                  <div class="popover-item" :style="style1">
                    <span>查看同步</span>
                  </div>
                  <div class="popover-item" :style="style1">
                    <span>同步</span>
                  </div>
                  <div class="popover-item" :style="style1">
                    <span>上传</span>
                  </div>
                  <div class="popover-item" :style="style1">
                    <span>删除</span>
                  </div>
                  <div class="popover-item" :style="style2">
                    <span>汇总</span>
                  </div>
                </div>
                <span class="span" slot="reference">
                  <span>更多</span>
                  <span class="el-icon-caret-bottom"></span>
                </span>
              </el-popover>

            </div>
          </div> -->
        </div>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
          background :page-size="10" layout="total, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </div>
    <Add v-if="showAdd" @close="closeAdd"></Add>
    <Schedule v-if="showSchedule" @close="closeSchedule"></Schedule>
    <See v-if="showSee" @close="closeSee"></See>
    <Report v-if="showReport" @close="closeReport"></Report>
  </div>
</template>

<script>
import Add from '../physicalExaminationInfoRecordRun/add.vue'
import Schedule from '../physicalExaminationInfoRecordRun/schedule.vue'
import See from '../physicalExaminationInfoRecordRun/see/see.vue'
import Report from '../physicalExaminationInfoRecordRun/report.vue'
export default {
  components: {
    Add,
    Schedule,
    See,
    Report
  },
  data () {
    return {
      tabIndex: 0,
      tabs: Object.freeze(['内科', '外科', '脊柱侧弯', '眼科', '耳鼻喉科', '口腔科', '形态指标', '血压', '听力筛查', '肺功能检测', '血常规', '血生化', '尿常规', '心理测评']),
      options: [{
        value: '',
        label: ''
      }],
      value: '',
      value1: '',
      total: 400,
      currentPage: 0,
      showSet: false,
      style: {
        width: '100%',
        height: '186px'
      },
      style1: {
        width: '100%',
        height: '31px',
        'line-height': '31px',
        'text-align': 'center',
        'border-bottom': '1px solid #D9D9D9',
        color: '#333333',
        'font-size': '14px'
      },
      style2: {
        width: '100%',
        height: '31px',
        'line-height': '31px',
        'text-align': 'center',
        color: '#333333',
        'font-size': '14px'
      },
      showAdd: false,
      showSchedule: false,
      showSee: false,
      showReport: false
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    },
    openAdd () {
      this.showAdd = true
    },
    closeAdd () {
      this.showAdd = false
    },
    openSchedule () {
      this.showSchedule = true
    },
    closeSchedule () {
      this.showSchedule = false
    },
    openSee () {
      this.showSee = true
    },
    closeSee () {
      this.showSee = false
    },
    openReport () {
      this.showReport = true
    },
    closeReport () {
      this.showReport = false
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
    }
  }
}
</script>

<style lang="less" scoped>
.son {
  width: 100%;
  height: 100%;
  background-color: #F0F0F0;

  .son-tab {
    overflow: hidden;
    display: flex;
    width: 100%;
    height: 30px;
    margin-top: 20px;
    border-radius: 15px;
    background-color: #B0B0B0;

    .tab-item {
      flex: 1;
      height: 30px;
      line-height: 30px;
      text-align: center;
      color: #fff;
      font-size: 18px;
      cursor: pointer;
    }

    .tab-active {
      background-color: #3FA1FF;
    }
  }

  .son-head {
    display: flex;
    align-items: center;
    width: 100%;
    height: 70px;
    margin-bottom: 10px;
    background-color: #fff;
    box-sizing: border-box;

    .son-head-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 0 14px;
      box-sizing: border-box;

      .son-head-left {
        display: flex;
        align-items: center;
        width: 33%;

        .son-head-open {
          width: 23px;
          height: 6px;
          border-top: 2px solid #595959;
          border-bottom: 2px solid #595959;
        }

        .el-input {
          margin-left: 50px;
        }

        /deep/.el-input__inner {
          border-color: #fff;
          font-size: 18px;
        }
      }

      .son-head-center {
        display: flex;
        align-items: center;
        flex: 1;
        color: #595959;
        font-size: 20px;

        /deep/.el-input__inner {
          font-size: 18px;
        }
      }

      .son-head-right {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex: 1;

        .success {
          color: #67C23A;
          font-size: 39px;
        }

        .lock {
          color: #595959;
          font-size: 30px;
        }

        .rank {
          font-size: 34px;
          transform: rotate(45deg);
        }

        .div {
          width: 41px;
          height: 40px;
          padding: 6px 7px;
          border: 1px solid #E6E6E6;
          border-radius: 6px;
          box-sizing: border-box;

          .div1 {
            width: 100%;
            height: 100%;
            padding-top: 3px;
            border: 1px solid #999999;
            box-sizing: border-box;

            .blue {
              width: 100%;
              height: 100%;
              background-color: #409EFF;
            }
          }
        }

        /deep/.el-input__inner {
          text-align: right;
          border: none;
          font-size: 18px;
        }
      }

      .son-head-title {
        width: 117px;
        height: 38px;
        line-height: 38px;
        text-align: center;
        border: 1px solid #409EFF;
        border-radius: 2px;
        box-sizing: border-box;
        color: #409EFF;
        font-size: 16px;
      }

      .el-button {
        width: 100px;

        .icon {
          margin-left: 10px;
        }
      }
    }
  }

  .son-scroll {
    width: 100%;
    height: calc(100% - 80px);
    padding: 0 10px;
    box-sizing: border-box;

    .son-search {
      display: flex;
      justify-content: space-evenly;
      align-content: space-evenly;
      flex-wrap: wrap;
      width: 100%;
      height: 170px;
      background-color: #fff;
      border-radius: 18px;
      box-sizing: border-box;

      .search-item {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: calc(25% - 40px);

        .el-select {
          width: 250px;
        }

        .el-input {
          width: 250px;
        }

        /deep/.el-date-editor {
          width: 250px;
        }
      }

      .key {
        margin-right: 20px;
        color: #17212B;
        font-size: 22px;
      }

      .el-button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        padding-top: 0;
        padding-bottom: 0;
        background-color: #3FA1FF;
        color: #fff;
        font-size: 30px;
      }

      .button {
        display: flex;
        align-items: center;
        width: calc(75% - 60px);
      }
    }

    .son-info {
      width: 100%;
      height: calc(100% - 180px);
      margin-top: 10px;
      padding: 0 10px;
      background-color: #fff;
      border-radius: 18px;
      box-sizing: border-box;

      .son-info-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 58px;
        border-bottom: 1px solid #333333;
        box-sizing: border-box;

        .info-left {
          display: flex;
          align-items: center;
          height: 100%;
          color: #17212B;
          font-size: 20px;

          .el-button {
            margin-left: 20px;
            background-color: #3FA1FF;
            border: none;
            color: #fff;
          }
        }

        .info-right {
          width: 70px;
          height: 58px;
          line-height: 58px;
          text-align: center;
          color: #17212B;
          font-size: 20px;
        }
      }

      .son-info-scroll {
        overflow-y: scroll;
        width: 100%;
        height: calc(100% - 150px);
        margin: 13px 0;

        &::-webkit-scrollbar {
          width: 0;
        }

        .scroll-list {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          height: 40px;
          margin-bottom: 10px;
          background-color: #C9D8E7;
          box-sizing: border-box;

          .info-item {
            font-size: 16px;
          }
        }
      }

      .info-item {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100px;
        text-align: center;
        color: #17212B;
        font-size: 20px;

        .red {
          color: #FF0000;
        }

        .green {
          color: #29CF00;
        }
      }

      .info-item1 {
        width: 160px;
      }

      .info-item2 {
        width: 230px;
      }

      .info-item3 {
        width: 140px;
      }

      .info-item4 {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        width: 200px;

        .span {
          cursor: pointer;
          color: #399BFB;
        }

        .popper {
          padding: 0 !important;
        }

        .popover {
          width: 86px;
          height: 186px;
          background-color: red;

          .popover-item {
            width: 100%;
            height: 31px;
            line-height: 31px;
            text-align: center;
            border-bottom: 1px solid #D9D9D9;
            color: #333333;
            font-size: 14px;
          }

          .popover-item:last-child {
            border: none;
          }
        }
      }

      .info-item5 {
        width: 55px;
      }

      .info-item6 {
        width: 190px;
      }

      .info-item7 {
        width: 110px;
      }

      // 页码样式修改
      .el-pagination {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        /deep/.el-pagination__total,
        /deep/.el-pagination__jump {
          color: #000000;

          .el-input__inner {
            color: #fff;
            background-color: transparent;
          }
        }

        /deep/.el-pager li {
          background-color: #ADADAD;
          color: #fff;
        }

        /deep/.el-pager li:not(.disabled).active {
          background-color: #0077F9;
        }
      }
    }
  }
}

.son1 {
  position: relative;
}
</style>
