<template>
  <div class="son">
    <div class="son-title">
      <span class="title-box">机构管理</span>
    </div>
    <div class="son-search">
      <div class="son-item">
        <span class="key">机构名称</span>
        <el-input placeholder="请输入" v-model="params.jddw"></el-input>
      </div>
      <div class="son-item">
        <el-button type="primary" @click="getInfo">搜索</el-button>
        <el-button type="primary">清空</el-button>
        <el-button type="primary" @click="showAdd = true">新增</el-button>
      </div>

    </div>
    <div class="table">
      <el-table :data="tableData" height="100%" border>
      <el-table-column type="index" label="序号" width="100">
      </el-table-column>
      <el-table-column prop="jgbh" label="机构编号">
      </el-table-column>
      <el-table-column label="机构级别">
        <template slot-scope="scope">
          <span>{{ scope.row.jgjb | type }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="jddw" label="机构名称">
      </el-table-column>
      <el-table-column prop="province" label="省名称">
      </el-table-column>
      <el-table-column prop="city" label="市名称">
      </el-table-column>
      <el-table-column prop="country" label="县(区)名称">
      </el-table-column>
      <el-table-column prop="town" label="乡镇社区名称">
      </el-table-column>
      <el-table-column prop="village" label="村街道名称">
      </el-table-column>
      <el-table-column prop="address" label="地址">
      </el-table-column>
      <el-table-column prop="jgjj" label="机构简介">
      </el-table-column>
      <el-table-column prop="fwzs" label="服务总数">
      </el-table-column>
      <el-table-column prop="jgcws" label="机构床位数">
      </el-table-column>
      <el-table-column label="操作" width="180">
        <!-- slot-scope="scope" -->
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="openUpdate(scope.row)">编辑</el-button>
          <el-button type="primary" size="mini" @click="openRun(scope.row)">管辖用户</el-button>
        </template>
      </el-table-column>
    </el-table>
    </div>
    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="query1.pageNum" background :page-size="query1.pageSize"
              layout="total, prev, pager, next, jumper" :total="query1.total"></el-pagination>
    <!-- 编辑 更新 -->
    <Update v-if="showUpdate" @close="closeUpdate" ></Update>
    <!-- 管辖用户 -->
    <Run v-if="showRun" @close="closeRun"></Run>
    <!-- 新增 -->
    <Add v-if="showAdd" @close="closeAdd"></Add>
  </div>
</template>

<script>
import Update from './update.vue'
import Run from './run.vue'
import Add from './add.vue'
export default {
  components: {
    Update,
    Run,
    Add
  },
  data () {
    return {
      issynchronous: false,
      options: [{
        value: '',
        label: ''
      }],
      value: '',
      params: {
        nowtime: '2024-06-03T13:29:31.348Z',
        ip: '127.0.0.1',
        jgbh: '',
        jgjb: '',
        start: '0',
        length: '10',
        jddw: ''
      },
      selectInfo: {},
      tableData: [],
      showUpdate: false,
      showRun: false,
      showAdd: false,
      // cascaderProps: {
      //   lazy: true,
      //   lazyLoad (node, resolve) {
      //     const level = node.level
      //     console.log('level', level)
      //     let nodes = []
      //     if (level === 0) {
      //       nodes = [
      //         {
      //           label: '河北省',
      //           value: '1',
      //           leaf: false
      //         },
      //         {
      //           label: '河南省',
      //           value: '2',
      //           leaf: false
      //         }
      //       ]
      //     } else if (level === 1) {
      //       console.log(node.value)
      //       if (node.value === '1') {
      //         nodes = [
      //           {
      //             label: '石家庄市',
      //             value: '11',
      //             leaf: false
      //           }
      //         ]
      //       } else if (node.value === '2') {
      //         nodes = [
      //           {
      //             label: '开封市',
      //             value: '21',
      //             leaf: false
      //           }
      //         ]
      //       }
      //     } else if (level === 2) {
      //       console.log(node.value)
      //       if (node.value === '11') {
      //         nodes = [
      //           {
      //             label: '赵县',
      //             value: '111',
      //             leaf: true
      //           }
      //         ]
      //       } else if (node.value === '21') {
      //         nodes = [
      //           {
      //             label: '鼓楼区',
      //             value: '211',
      //             leaf: true
      //           }
      //         ]
      //       }
      //     }
      //     resolve(nodes)
      //   }
      // },
      // 分页数据
      query1: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      total: 400,
      currentPage: 0
    }
  },
  created () {
    this.params.jgbh = this.$store.state.loginInfo.jgbh || ''
    this.getInfo()
  },
  filters: {
    type (value) {
      if (!value) return '-'
      const arr = ['省', '市', '县', '乡', '村']
      return arr[value - 1]
    }
  },
  methods: {
    async getInfo () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-180630108
      try {
        this.params.start = this.query1.pageNum === 1 ? '0' : (this.query1.pageNum - 1) * 10 + ''
        this.params.length = this.query1.pageSize + ''
        const { data: res } = await this.$http.get('/api/jkct/findjglist', { params: this.params })
        console.log('机构列表', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        if (res.data === null) return
        this.tableData = res.data
        this.query1.total = res.recordsTotal
      } catch (error) {
        console.log('机构列表 请求失败', error)
        // this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    openRun (obj) {
      console.log('----------' + obj)
      this.selectInfo = obj
      this.showRun = true
    },
    closeRun () {
      this.showRun = false
    },
    openUpdate (info) {
      console.log('+++++++++' + info)
      this.selectInfo = info
      this.showUpdate = true
    },
    closeUpdate () {
      this.showUpdate = false
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
      this.query1.pageNum = val
      this.getInfo()
    },
    closeAdd (e) {
      if (e === 'loading') {
        this.getInfo()
      }
      this.showAdd = false
    }
  }
}
</script>

<style lang="less" scoped>
.son {
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding: 0 10px;
  box-sizing: border-box;

  .son-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 60px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    .title-box {
      height: 38px;
      line-height: 38px;
      padding: 0 9px;
      text-align: center;
      background-color: #409EFF;
      color: #fff;
      font-size: 22px;
    }

  }

  .son-search {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: 70px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    .son-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 20px;
      margin-right: 30px;

      .key {
        width: 110px;
        color: #000;
        font-size: 22px;
      }

      .el-input {
        width: 180px;
      }
    }
  }

  .table {
    width: 100%;
    height: calc(100% - 260px);

    /deep/.el-table__cell {
      text-align: center;
      color: #000;
    }

    .flex {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      width: 100%;
      color: #000;

      span {
        cursor: pointer;
      }

      .item {
        font-size: 14px;
        cursor: pointer;
      }

      .icon {
        color: #fff;
        font-size: 24px;
        cursor: pointer;
      }
    }
  }

  .green {
    color: #00FF88;
  }

  .organ {
    color: #FF6600;
  }

  .red {
    color: #FF0000;
  }

  // 页码样式修改
  .el-pagination {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
}
</style>
