<template>
  <div class="popup">
    <div class="tab">
      <span class="tab-item" :class="{ 'tab-active': index === tabIndex }" v-for="(item, index) in tabs" :key="index"
        @click="tabClick(index)">{{ item }}</span>
    </div>
    <template v-if="tabIndex === 0">
      <div class="popup-line">
        <span>基本信息</span>
      </div>
      <div class="popup-list">
        <div class="item">
          <span>姓名</span>
          <el-input></el-input>
        </div>
        <div class="item">
          <span>联系方式</span>
          <el-input></el-input>
        </div>
        <div class="item">
          <span>设备厂家</span>
          <el-select v-model="value" placeholder="">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <span>设备编号</span>
          <el-input></el-input>
        </div>
      </div>
    </template>

    <template v-else-if="tabIndex === 1">
      <div class="popup-line">
        <span>基本信息</span>
      </div>
      <div class="popup-list">
        <div class="item">
          <span>设备名称</span>
          <el-input></el-input>
        </div>
        <div class="item">
          <span>SIM卡号</span>
          <el-input></el-input>
        </div>
        <div class="item item1">
          <span>紧急呼叫模式</span>
          <el-select v-model="value" placeholder="" class="select">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>

      <div class="popup-list">
        <div class="item">
          <span>计步监测</span>
          <el-switch v-model="value1" active-color="#6C6C6C" inactive-color="#30F05D">
          </el-switch>
        </div>
        <div class="item">
          <span>睡眠监测</span>
          <el-switch v-model="value1" active-color="#6C6C6C" inactive-color="#30F05D">
          </el-switch>
        </div>
        <div class="item item1">
          <span>设备蓝牙</span>
          <el-switch v-model="value1" active-color="#6C6C6C" inactive-color="#30F05D">
          </el-switch>
        </div>
      </div>

      <div class="popup-list">
        <div class="item item1">
          <span>体温上报频率</span>
          <el-radio-group v-model="radio">
            <el-radio label="0">5分钟</el-radio>
            <el-radio label="1">15分钟</el-radio>
            <el-radio label="2">30分钟</el-radio>
            <el-radio label="3">60分钟</el-radio>
          </el-radio-group>
        </div>
        <div class="item item1">
          <span>体温定时测量</span>
          <el-switch v-model="value1" active-color="#6C6C6C" inactive-color="#30F05D">
          </el-switch>
        </div>
      </div>

      <div class="popup-list">
        <div class="item item1">
          <span>心率监测频率</span>
          <el-radio-group v-model="radio">
            <el-radio label="0">5分钟</el-radio>
            <el-radio label="1">15分钟</el-radio>
            <el-radio label="2">30分钟</el-radio>
            <el-radio label="3">60分钟</el-radio>
          </el-radio-group>
        </div>
        <div class="item item1">
          <span>心率阈值</span>
          <el-input></el-input>
          <el-input></el-input>
        </div>
      </div>

    </template>

    <template v-else-if="tabIndex === 2">
      <div class="popup-line popup-line1">
        <span>亲情密码</span>
        <div class="add" @click="add">
          <span class="el-icon-plus"></span>
        </div>
      </div>
      <div class="scroll">
        <div class="popup-list border-bottom" v-for="(item, index) in adds" :key="index">
          <div class="item">
            <span>联系人姓名</span>
            <el-input></el-input>
          </div>
          <div class="item">
            <span>联系人电话</span>
            <el-input></el-input>
          </div>
          <div class="item item1">
            <span>是否SOS密码</span>
            <el-radio-group v-model="radio">
              <el-radio label="0">是</el-radio>
              <el-radio label="1">否</el-radio>
            </el-radio-group>

            <span class="delete">删除</span>
          </div>
        </div>
      </div>

    </template>

    <div class="btns">
      <el-button size="mini" @click="close">返回</el-button>
      <el-button v-if="tabIndex !== 0" size="mini" @click="back">上一步</el-button>
      <el-button v-if="tabIndex !== 2" size="mini" class="next" @click="next">下一步</el-button>
      <el-button v-if="tabIndex === 2" size="mini" class="next" @click="submit">完成</el-button>
    </div>

  </div>
</template>

<script>
export default {
  data () {
    return {
      tabIndex: 0,
      tabs: Object.freeze(['绑定设备号', '设备设置', '设备亲情号']),
      options: [{
        value: '',
        label: ''
      }],
      value: '',
      value1: '',
      radio: '',
      adds: 1
    }
  },
  methods: {
    add () {
      this.adds++
    },
    back () {
      this.tabIndex--
    },
    next () {
      this.tabIndex++
    },
    submit () {
      this.close()
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.popup {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 88;
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: #081C38;
  box-sizing: border-box;

  .tab {
    display: flex;
    align-items: center;
    height: 40px;
    background-color: #7C7C7C;

    .tab-item {
      flex: 1;
      height: 40px;
      line-height: 40px;
      text-align: center;
      color: #fff;
      font-size: 18px;
      // cursor: pointer;
    }

    .tab-active {
      background-color: #10D0DC;
    }
  }

  .popup-line {
    width: calc(100% - 24px);
    height: 45px;
    line-height: 45px;
    margin-top: 20px;
    margin-left: 24px;
    padding-left: 18px;
    padding-right: 45px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;
    color: #01EEFD;
    font-size: 18px;
    font-weight: bold;
  }

  .popup-line1 {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: space-between;

    .add {
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      background-color: #04B4D0;
      border-radius: 50%;
      color: #fff;
      cursor: pointer;
    }
  }

  .scroll {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 180px);

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  .popup-list {
    display: flex;
    align-items: center;
    margin-top: 20px;
    width: calc(100% - 42px);
    margin-left: 42px;
    box-sizing: border-box;

    .item {
      width: 25%;
      margin-bottom: 20px;
      color: #01EEFD;
      font-size: 16px;

      .el-input,
      .el-select {
        width: 170px;
        margin-left: 10px;
      }

      .select {
        width: 362px;
      }

      .el-switch {
        margin-left: 16px;
      }

      .el-radio-group {
        margin-left: 20px;

        /deep/.el-radio__label {
          color: #FFFFFF;
          font-size: 18px;
        }

        /deep/.el-radio__inner {
          background-color: transparent;
        }

        /deep/.el-radio__input.is-checked .el-radio__inner {
          background-color: #01EEFD;
          border-color: #01EEFD;
        }
      }

      .delete {
        margin-left: 118px;
      }
    }

    .item1 {
      width: 50%;
    }
  }

  .border-bottom {
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;
  }

  .btns {
    position: absolute;
    left: 40px;
    bottom: 20px;
  }

  .el-button {
    margin-right: 42px;
    background-color: #33465C;
    border: none;
    border-radius: 0;
    color: #fff;
  }

  .next {
    background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
  }
}
</style>
