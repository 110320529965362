<template>
  <div class="page-box">
    <div class="tab">
      <span class="item active">交接记录</span>
    </div>
    <el-input placeholder="姓名/身份证号" suffix-icon="el-icon-search" class="input"></el-input>
    <div class="tab-box">
      <div class="son">
        <div class="son-search">
          <div class="son-item">
            <div class="key">
              <span>任务名称</span>
            </div>
            <el-input placeholder="请输入任务名称"></el-input>
          </div>
          <div class="son-item">
            <div class="key">
              <span>提交人姓名</span>
            </div>
            <el-input placeholder="请输入"></el-input>
          </div>
          <div class="son-item">
            <div class="key">
              <span>接收人姓名</span>
            </div>
            <el-input placeholder="请输入"></el-input>
          </div>
          <div class="son-item">
            <div class="key">
              <span>联系方式</span>
            </div>
            <el-input placeholder="请输入"></el-input>
          </div>
          <div class="son-item">
            <div class="key">
              <span>接受状态</span>
            </div>
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="son-item">
            <div class="key">
              <span>提交时间</span>
            </div>
            <el-date-picker type="date" placeholder="选择日期">
            </el-date-picker>
          </div>
          <el-button type="primary">搜索</el-button>
          <el-button type="primary">重置</el-button>
        </div>
        <div class="son-table">
          <el-table :data="tableData" border height="100%" ref="table">
          <el-table-column label="序号" type="index" width="100">
          </el-table-column>
          <el-table-column prop="date" label="提交时间">
          </el-table-column>
          <el-table-column prop="name" label="提交人">
          </el-table-column>
          <el-table-column prop="address" label="地接收人">
          </el-table-column>
          <el-table-column prop="address" label="接收人联系方式">
          </el-table-column>
          <el-table-column prop="address" label="任务数量">
          </el-table-column>
          <el-table-column label="操作">
            <template>
              <el-button type="primary" size="small" @click="openSee">查看明细</el-button>
            </template>
          </el-table-column>
        </el-table>
        </div>

        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="query1.pageNum" background :page-size="query1.pageSize" layout="total, prev, pager, next, jumper"
          :total="query1.total">
        </el-pagination>
        <See v-if="showSee" @close="closeSee"></See>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import See from './see.vue'
export default {
  components: {
    See
  },
  data () {
    return {
      tabIndex: 0,
      tabs: Object.freeze(['任务看板', '交接记录']),
      tableData: [],
      options: [{
        value: '',
        label: ''
      }],
      value: '',
      showSee: false,
      // 分页数据
      query1: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      params: {
        start: '0',
        length: '10',
        ssjgbh: ''
      }
    }
  },
  computed: {
    ...mapState(['loginInfo'])
  },
  created () {
    this.params.ssjgbh = this.loginInfo.jgbh
    this.getList()
  },
  methods: {
    tabClick (index) {
      const routers = ['taskkanban', 'handoverrecord']
      this.tabIndex = index
      this.$router.push('/smartelderlycare/retirement/' + routers[index])
      console.log(routers[index])
    },
    // 交接记录列表
    async getList () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-100423698
      try {
        // const start = this.query1.pageNum === 1 ? '10' : (this.query1.pageNum - 1) * 10 + 1 + ''
        const start = this.query1.pageNum === 1 ? '0' : (this.query1.pageNum - 1) * 10 + ''
        this.params.start = start
        this.params.length = this.query1.pageSize + ''
        const { data: res } = await this.$http.get('/ykdyljjjls', { params: this.params })
        console.log('交接记录列表', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.tableData = res.data
        this.query1.total = res.recordsTotal
        this.$nextTick(() => {
          this.$refs.table.bodyWrapper.scrollTop = 0
        })
      } catch (error) {
        console.log('交接记录列表请求失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
      this.query1.pageNum = val
      this.getList()
    },
    openSee () {
      this.showSee = true
    },
    closeSee () {
      this.showSee = false
    }
  }
}
</script>

<style lang="less" scoped>
.page-box {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  background-color: #fff;
  font-family: Helvetica Neue,Helvetica,PingFang SC,Hiragino Sans GB,Microsoft YaHei,"\5FAE\8F6F\96C5\9ED1",Arial,sans-serif;

  .tab {
    display: flex;
    align-items: center;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    .item {
      width: 130px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      background-color: #284867;
      color: #fff;
      font-size: 22px;
      cursor: pointer;

      &:first-child {
        margin-right: 30px;
      }
    }

    .active {
      background-color: #409EFF;
    }
  }

  .input {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 246px;
  }

  .tab-box {
    width: 100%;
    height: calc(100% - 78px);

    .son {
      position: relative;
      width: 100%;
      height: 100%;

      .son-search {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
        height: 136px;

        .son-item {
          display: flex;
          align-items: center;
          margin-right: 30px;

          .key {
            margin-right: 10px;
            white-space: nowrap;
            color: #606266;
            font-size: 16px;
          }
        }
      }

      .son-table {
        width: 100%;
        height: calc(100% - 260px);
      }

      /deep/.el-table .cell {
        text-align: center;
        color: #000;
      }

      .see {
        color: #0077F9;
        cursor: pointer;
      }

      .el-pagination {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
      }

    }
  }

}
</style>
