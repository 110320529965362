<template>
  <div class="son" :class="{ son1: showSee }">
    <div class="son-head">
      <div class="son-head-item">
        <div class="son-head-left">
          <div class="son-head-open"></div>
          <el-input placeholder="请输入搜索内容"></el-input>
        </div>
        <div class="son-head-center">
          <span>切换机构：</span>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="son-head-right">
          <span class="el-icon-success success"></span>
          <div class="div">
            <div class="div1">
              <div class="blue"></div>
            </div>
          </div>
          <span class="el-icon-lock lock"></span>
          <span class="el-icon-view lock"></span>
          <span class="el-icon-rank rank"></span>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div class="son-scroll">
      <div class="son-search">
        <div class="search-item">
          <div class="key">
            <span>姓名</span>
          </div>
          <el-input placeholder="请输入"></el-input>
        </div>
        <div class="search-item">
          <div class="key">
            <span>身份证号</span>
          </div>
          <el-input placeholder="请输入"></el-input>
        </div>
        <div class="search-item">
          <div class="key">
            <span>年龄</span>
          </div>
          <el-input placeholder="请输入"></el-input>
        </div>
        <div class="search-item">
          <div class="key">
            <span>性别</span>
          </div>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="search-item">
          <div class="key">
            <span>所属机构</span>
          </div>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="search-item">
          <div class="key">
            <span>条纹码</span>
          </div>
          <el-input placeholder="请输入" suffix-icon="el-icon-caret-bottom"></el-input>
        </div>
        <div class="search-item">
          <div class="key">
            <span>体检年份</span>
          </div>
          <el-date-picker v-model="value1" type="date" range-separator="至" start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </div>
        <div class="search-item">
          <div class="key">
            <span>体检日期</span>
          </div>
          <el-date-picker v-model="value1" type="daterange" range-separator="至" start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </div>
        <div class="button">
          <el-button type="primary">
            <span class="el-icon-search"></span>
            <span>搜索</span>
          </el-button>
          <el-button>
            <span class="el-icon-delete"></span>
            <span>清空</span>
          </el-button>
        </div>
      </div>

      <div class="son-btn">
        <el-button icon="el-icon-refresh" circle size="small"></el-button>
        <el-button icon="el-icon-menu" circle size="small"></el-button>
        <el-button icon="el-icon-search" circle size="small"></el-button>
      </div>

      <div class="son-info">
        <div class="son-info-scroll">
          <el-table :data="tableData" border height="100%">
            <el-table-column fixed type="index" label="序号" width="80">
            </el-table-column>
            <el-table-column prop="name" label="姓名" width="120">
            </el-table-column>
            <el-table-column prop="province" label="身份证号" width="120">
            </el-table-column>
            <el-table-column prop="city" label="年龄" width="120">
            </el-table-column>
            <el-table-column prop="address" label="性别" width="120">
              <template>
                <el-tag>男</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="zip" label="所属机构" width="120">
            </el-table-column>
            <el-table-column prop="zip" label="条纹码" width="120">
            </el-table-column>
            <el-table-column prop="zip" label="体检年份" width="120">
            </el-table-column>
            <el-table-column prop="zip" label="内科" width="120">
              <template>
                <el-tag>已同步</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="zip" label="外科" width="120">
              <template>
                <el-tag>已同步</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="zip" label="脊柱侧弯" width="120">
              <template>
                <el-tag>已同步</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="zip" label="眼科" width="120">
              <template>
                <el-tag>已同步</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="zip" label="耳鼻喉科" width="120">
              <template>
                <el-tag>已同步</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="zip" label="口腔科" width="120">
              <template>
                <el-tag>已同步</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="zip" label="形态指标" width="120">
              <template>
                <el-tag>已同步</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="zip" label="血压测量" width="120">
              <template>
                <el-tag>已同步</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="zip" label="听力筛查" width="120">
              <template>
                <el-tag>已同步</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="zip" label="肺功能检测" width="120">
              <template>
                <el-tag>已同步</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="zip" label="血常规" width="120">
              <template>
                <el-tag>已同步</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="zip" label="血生化" width="120">
              <template>
                <el-tag>已同步</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="zip" label="心理测评" width="120">
              <template>
                <el-tag>已同步</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="zip" label="体检小结" width="120">
              <template>
                <el-tag>已同步</el-tag>
              </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="240">
              <template>
                <el-button type="primary" size="mini">编辑</el-button>
                <el-button type="primary" size="mini">同步</el-button>
                <el-button type="primary" size="mini">报告预览</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
          background :page-size="10" layout="total, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </div>
    <Add v-if="showAdd" @close="closeAdd"></Add>
    <Schedule v-if="showSchedule" @close="closeSchedule"></Schedule>
    <See v-if="showSee" @close="closeSee"></See>
    <Report v-if="showReport" @close="closeReport"></Report>
  </div>
</template>

<script>
import Add from '../physicalExaminationInfoRecordRun/add.vue'
import Schedule from '../physicalExaminationInfoRecordRun/schedule.vue'
import See from '../physicalExaminationInfoRecordRun/see/see.vue'
import Report from '../physicalExaminationInfoRecordRun/report.vue'
export default {
  components: {
    Add,
    Schedule,
    See,
    Report
  },
  data () {
    return {
      options: [{
        value: '',
        label: ''
      }],
      value: '',
      value1: '',
      tableData: [{}],
      total: 400,
      currentPage: 0,
      showSet: false,
      showAdd: false,
      showSchedule: false,
      showSee: false,
      showReport: false
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    },
    openAdd () {
      this.showAdd = true
    },
    closeAdd () {
      this.showAdd = false
    },
    openSchedule () {
      this.showSchedule = true
    },
    closeSchedule () {
      this.showSchedule = false
    },
    openSee () {
      this.showSee = true
    },
    closeSee () {
      this.showSee = false
    },
    openReport () {
      this.showReport = true
    },
    closeReport () {
      this.showReport = false
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
    }
  }
}
</script>

<style lang="less" scoped>
.son {
  width: 100%;
  height: 100%;
  background-color: #F0F0F0;

  .son-head {
    display: flex;
    align-items: center;
    width: 100%;
    height: 70px;
    margin-bottom: 10px;
    background-color: #fff;
    box-sizing: border-box;

    .son-head-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 0 14px;
      box-sizing: border-box;

      .son-head-left {
        display: flex;
        align-items: center;
        width: 33%;

        .son-head-open {
          width: 23px;
          height: 6px;
          border-top: 2px solid #595959;
          border-bottom: 2px solid #595959;
        }

        .el-input {
          margin-left: 50px;
        }

        /deep/.el-input__inner {
          border-color: #fff;
          font-size: 18px;
        }
      }

      .son-head-center {
        display: flex;
        align-items: center;
        flex: 1;
        color: #595959;
        font-size: 20px;

        /deep/.el-input__inner {
          font-size: 18px;
        }
      }

      .son-head-right {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex: 1;

        .success {
          color: #67C23A;
          font-size: 39px;
        }

        .lock {
          color: #595959;
          font-size: 30px;
        }

        .rank {
          font-size: 34px;
          transform: rotate(45deg);
        }

        .div {
          width: 41px;
          height: 40px;
          padding: 6px 7px;
          border: 1px solid #E6E6E6;
          border-radius: 6px;
          box-sizing: border-box;

          .div1 {
            width: 100%;
            height: 100%;
            padding-top: 3px;
            border: 1px solid #999999;
            box-sizing: border-box;

            .blue {
              width: 100%;
              height: 100%;
              background-color: #409EFF;
            }
          }
        }

        /deep/.el-input__inner {
          text-align: right;
          border: none;
          font-size: 18px;
        }
      }

      .son-head-title {
        width: 117px;
        height: 38px;
        line-height: 38px;
        text-align: center;
        border: 1px solid #409EFF;
        border-radius: 2px;
        box-sizing: border-box;
        color: #409EFF;
        font-size: 16px;
      }

      .el-button {
        width: 100px;

        .icon {
          margin-left: 10px;
        }
      }
    }
  }

  .son-scroll {
    width: 100%;
    height: calc(100% - 80px);
    padding: 0 10px;
    box-sizing: border-box;

    .son-search {
      display: flex;
      justify-content: space-evenly;
      align-content: space-evenly;
      flex-wrap: wrap;
      width: 100%;
      height: 170px;
      background-color: #fff;
      border-radius: 18px;
      box-sizing: border-box;

      .search-item {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: calc(25% - 40px);

        .el-select {
          width: 250px;
        }

        .el-input {
          width: 250px;
        }

        /deep/.el-date-editor {
          width: 250px;
        }
      }

      .key {
        margin-right: 20px;
        color: #17212B;
        font-size: 22px;
      }

      .button {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 0 30px;
        box-sizing: border-box;
      }
    }

    .son-btn {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      height: 50px;
      margin-top: 10px;
      padding: 0 20px;
      background-color: #fff;
      box-sizing: border-box;
    }

    .son-info {
      width: 100%;
      height: calc(100% - 250px);
      margin-top: 10px;
      padding: 0 10px;
      background-color: #fff;
      border-radius: 18px;
      box-sizing: border-box;

      .son-info-scroll {
        width: 100%;
        height: calc(100% - 100px);
        padding: 10px 0;
        box-sizing: border-box;

        &::-webkit-scrollbar {
          width: 0;
        }

        /deep/.el-table__cell {
          text-align: center;
          color: #000;
        }
      }

      // 页码样式修改
      .el-pagination {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
      }
    }
  }
}

.son1 {
  position: relative;
}
</style>
