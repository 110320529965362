<template>
  <div class="add">
    <div class="add-title">
      <div class="title">
        <span>新增预定</span>
      </div>
      <el-input placeholder="姓名/身份证号" suffix-icon="el-icon-search" class="input">
      </el-input>
    </div>
    <div class="add-box" v-show="tabIndex === 0">
      <div class="add-status">
        <div class="add-status-item">
          <span class="add-status-icon">
            <span class="span active-span">1</span>
            <span class="text active-text">选择服务</span>
          </span>
        </div>
        <div class="add-status-line"></div>
        <div class="add-status-item">
          <span class="add-status-icon">
            <span class="span">2</span>
            <span class="text">订单确认</span>
          </span>
        </div>
      </div>
      <div class="add-info">
        <div class="add-info-left">
          <img src="../../../img/emr/user.png" alt="" class="img">
          <div class="info">
            <span>姓名：{{ selectInfo.name || '-' }}</span>
            <div class="line">
              <div class="line-item">
                <span>楼层:</span>
                <el-select size="small" v-model="info.rylcid" placeholder="请选择" @change="lcChange">
                  <el-option v-for="item in lcs" :key="item.id" :label="item.louce" :value="item.louceid">
                  </el-option>
                </el-select>
              </div>
              <div class="line-item">
                <span>房间:</span>
                <el-select size="small" v-model="info.ryfjid" placeholder="请选择" @change="fjChange">
                  <el-option v-for="item in fjs" :key="item.id" :label="item.fjh" :value="item.fjid">
                  </el-option>
                </el-select>
              </div>
            </div>
            <span>手机号：{{ selectInfo.lxdh || '-' }}</span>
            <span>证件号码：{{ selectInfo.sfzh || '-' }}</span>
          </div>
        </div>
        <div class="add-info-center">
          <div class="item">
            <span>入院诊断</span>
            <el-input v-model="info.ryzd" size="small" placeholder="请输入"></el-input>
          </div>
          <div class="item">
            <span>主管医生</span>
            <el-select size="small" v-model="info.zgysbm" placeholder="请选择" @change="ysChange">
              <el-option v-for="item in yss" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="item">
            <span>主管护士</span>
            <el-select size="small" v-model="info.zghsbm" placeholder="请选择" @change="hsChange">
              <el-option v-for="item in hss" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="item">
            <span>主管护工</span>
            <el-select size="small" v-model="info.zghgbm" placeholder="请选择" @change="hgChange">
              <el-option v-for="item in hgs" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="add-info-right">
          <div class="title">
            <span>家属信息</span>
          </div>
          <div class="table">
            <el-table :data="info.yljsxx" height="120" :header-cell-style="{ background: '#024276' }">
              <el-table-column label="家属姓名">
                <template slot-scope="scope">
                  <el-input :class="{ border: !isInputs }" :disabled="isInputs" v-model="scope.row.jxxm"></el-input>
                </template>
              </el-table-column>
              <el-table-column prop="jsgx" label="与老人关系">
                <template slot-scope="scope">
                  <el-select v-model="scope.row.jxgx" placeholder="请选择" :disabled="isInputs"
                    :class="{ border: !isInputs }">
                    <el-option v-for="item in jsgxs" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column label="联系方式">
                <template slot-scope="scope">
                  <el-input :class="{ border: !isInputs }" :disabled="isInputs" v-model="scope.row.jxlxfs"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="120">
                <template>
                  <!-- slot-scope="scope" -->
                  <div class="flex">
                    <span>编辑</span>
                    <span>删除</span>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="adds" @click="updates">
            <span>添加</span>
          </div>
        </div>
      </div>
      <div class="add-box-title">
        <span>选择床位</span>
        <span class="span">床位费合计：{{ info.cwfhj || '-' }}</span>
      </div>
      <div class="add-line">
        <div class="add-item">
          <span class="add-key">床位</span>
          <el-select size="small" v-model="info.rycwid" placeholder="请选择" @change="cwChange">
            <el-option v-for="item in cws" :key="item.id" :label="item.cwh" :value="item.cwhid">
            </el-option>
          </el-select>
        </div>
        <div class="add-item">
          <span class="add-key">床位单价</span>
          <!-- <el-select v-model="info.cwdj" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select> -->
          <el-input v-model="info.cwdj" size="small" placeholder="请输入"></el-input>
        </div>
        <div class="add-item">
          <span class="add-key">入住天数</span>
          <el-input placeholder="请输入" size="small" v-model="info.xzcwrzts"></el-input>
        </div>
        <div class="add-item">
          <span class="add-key">入住日期</span>
          <el-date-picker size="small" v-model="datas" type="daterange" range-separator="至" start-placeholder="开始日期"
            end-placeholder="结束日期" value-format="yyyy-MM-dd" @change="dateChange">
          </el-date-picker>
        </div>
      </div>

      <div class="add-box-title">
        <span>餐饮膳食</span>
        <span class="span">伙食费合计：{{ info.cyhsfhj || '-' }}</span>
      </div>
      <div class="add-line">
        <div class="add-item">
          <span class="add-key">套餐单价</span>
          <el-input placeholder="请输入" size="small" v-model="info.cytcdj"></el-input>
        </div>
      </div>

      <div class="add-box-title">
        <span>护理服务</span>
        <span class="span">护理费合计：{{ info.hlfhj || '-' }}</span>
      </div>
      <div class="add-line">
        <div class="add-item">
          <span class="add-key">护理费</span>
          <el-input placeholder="请输入" size="small"></el-input>
        </div>
      </div>
      <div class="add-table">
        <!-- <div class="add-tr add-tr1">
          <span class="add-td">服务项目</span>
          <span class="add-td add-td1">服务内容</span>
          <span class="add-td">合计</span>
        </div>
        <div class="add-tr add-tr2">
          <span class="add-td border-right">修饰洗浴</span>
          <span class="add-td add-td1 border-right">喂食、饮水</span>
          <span class="add-td">2</span>
        </div>
        <div class="add-tr add-tr2">
          <span class="add-td border-right">穿脱衣</span>
          <span class="add-td add-td1 border-right">口腔清洁、梳头、洗脚</span>
          <span class="add-td">2</span>
        </div>
        <div class="add-tr add-tr2">
          <span class="add-td border-right">如厕排泄</span>
          <span class="add-td add-td1 border-right">提醒如厕、辅助如厕</span>
          <span class="add-td">2</span>
        </div>
        <div class="add-tr">
          <span class="add-td border-right">移动</span>
          <span class="add-td add-td1 border-right">协助站立</span>
          <span class="add-td">2</span>
        </div> -->
        <el-table :data="info.fwlbhlfw" height="100%" :header-cell-style="{ background: '#024276' }">
          <el-table-column label="服务项目">
            <template slot-scope="scope">
              <el-input :class="{ border: !isInputs }" :disabled="isInputs" v-model="scope.row.fwxm"></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="jsgx" label="服务内容">
            <template slot-scope="scope">
              <el-input :class="{ border: !isInputs }" :disabled="isInputs" v-model="scope.row.fwnr"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="费用类型">
            <!-- <template slot-scope="scope">
                    <el-input :class="{ border: !isInputs }" :disabled="isInputs" v-model="scope.row.fwnr"></el-input>
                  </template> -->
          </el-table-column>
          <el-table-column label="合计">
            <template slot-scope="scope">
              <el-input :class="{ border: !isInputs }" :disabled="isInputs" v-model="scope.row.fwjg"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="120">
            <template>
              <!-- slot-scope="scope" -->
              <div class="flex">
                <span>编辑</span>
                <span>删除</span>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="add-box-title">
        <span>其他收费</span>
      </div>
      <div class="add-line">
        <div class="add-item">
          <span class="add-key">押金</span>
          <el-input placeholder="请输入" size="small" v-model="info.qtsfyj"></el-input>
        </div>
        <div class="add-item">
          <span class="add-key">一次性生活费</span>
          <el-input placeholder="请输入" size="small" v-model="info.qtsfycxshf"></el-input>
        </div>
      </div>

      <div class="add-box-title">
        <span>入院费用核定周期设置</span>
      </div>
      <div class="add-line">
        <div class="add-item">
          <span class="add-key">入院费用核定天数 </span>
          <el-input placeholder="请输入" size="small" v-model="info.ryfyhdts"></el-input>
        </div>
      </div>
    </div>
    <div class="add-box" v-show="tabIndex === 1">
      <div class="add-status">
        <div class="add-status-item">
          <span class="add-status-icon">
            <span class="span active-span">1</span>
            <span class="text active-text">选择服务</span>
          </span>
        </div>
        <div class="add-status-line"></div>
        <div class="add-status-item">
          <span class="add-status-icon">
            <span class="span active-span">2</span>
            <span class="text active-text">订单确认</span>
          </span>
        </div>
      </div>
      <div class="add-box-title">
        <span>订单总费用 （{{ dateAll[0] || '-' }} 至 {{ dateAll[1] || '-' }}）</span>
      </div>

      <div class="add-table">
        <div class="add-tr add-tr1">
          <span class="add-td">序号</span>
          <span class="add-td add-td1">项目名称</span>
          <span class="add-td">小计（元）</span>
        </div>
        <div class="add-tr add-tr2">
          <span class="add-td border-right">1</span>
          <span class="add-td add-td1 border-right">床位费</span>
          <span class="add-td">1000</span>
        </div>
        <div class="add-tr add-tr2">
          <span class="add-td border-right">2</span>
          <span class="add-td add-td1 border-right">护理费</span>
          <span class="add-td">2</span>
        </div>
        <div class="add-tr add-tr2">
          <span class="add-td border-right">3</span>
          <span class="add-td add-td1 border-right">膳食费</span>
          <span class="add-td">2</span>
        </div>
        <div class="add-tr add-tr2">
          <span class="add-td border-right">4</span>
          <span class="add-td add-td1 border-right">押金</span>
          <span class="add-td">2</span>
        </div>
        <div class="add-tr add-tr2">
          <span class="add-td border-right">5</span>
          <span class="add-td add-td1 border-right">一次性生活费</span>
          <span class="add-td">2</span>
        </div>
        <div class="add-tr">
          <span class="add-td add-td2 border-right">合计</span>
          <span class="add-td">2</span>
        </div>
      </div>

      <div class="add-box-title">
        <span>入院费用核定 （{{ dateAll[0] || '-' }} 至 {{ dateAll[1] || '-' }}）</span>
      </div>

      <div class="add-table">
        <div class="add-tr add-tr1">
          <span class="add-td">序号</span>
          <span class="add-td add-td1">项目名称</span>
          <span class="add-td">小计（元）</span>
        </div>
        <div class="add-tr add-tr2">
          <span class="add-td border-right">1</span>
          <span class="add-td add-td1 border-right">床位费</span>
          <span class="add-td">1000</span>
        </div>
        <div class="add-tr add-tr2">
          <span class="add-td border-right">2</span>
          <span class="add-td add-td1 border-right">护理费</span>
          <span class="add-td">2</span>
        </div>
        <div class="add-tr add-tr2">
          <span class="add-td border-right">3</span>
          <span class="add-td add-td1 border-right">膳食费</span>
          <span class="add-td">2</span>
        </div>
        <div class="add-tr add-tr2">
          <span class="add-td border-right">4</span>
          <span class="add-td add-td1 border-right">押金</span>
          <span class="add-td">2</span>
        </div>
        <div class="add-tr add-tr2">
          <span class="add-td border-right">5</span>
          <span class="add-td add-td1 border-right">一次性生活费</span>
          <span class="add-td">2</span>
        </div>
        <div class="add-tr">
          <span class="add-td add-td2 border-right">合计</span>
          <span class="add-td">2</span>
        </div>
      </div>

      <div class="add-box-title">
        <span>上传合同</span>
      </div>

      <div class="add-upload">
        <el-button size="small">上传协议</el-button>
        <span class="add-upload-text">下载预定协议</span>
      </div>
    </div>
    <div class="add-btns">
      <el-button size="small" @click="next" v-if="tabIndex === 0">下一步</el-button>
      <el-button size="small" @click="upper" v-if="tabIndex === 1">上一步</el-button>
      <el-button size="small" @click="upper" v-if="tabIndex === 1">保存暂不提交</el-button>
      <el-button size="small" @click="submit" v-if="tabIndex === 1">保存并提交</el-button>
      <el-button size="small" class="gray" @click="close">返回</el-button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    selectInfo: {
      type: Object
    }
  },
  data () {
    return {
      jsgxs: Object.freeze([{
        value: '1',
        label: '配偶 '
      }, {
        value: '2',
        label: '⽗母 '
      }, {
        value: '3',
        label: '⼦⼥ '
      }, {
        value: '4',
        label: '兄弟姐妹 '
      }, {
        value: '5',
        label: '其他 '
      }]),
      input: '',
      options: [{
        value: '',
        label: ''
      }],
      tabIndex: 0,
      isInput: true,
      isInputs: true,
      news: {
        name: '',
        lxdh: '',
        sfzh: ''
      },
      lcs: [],
      value: '',
      louceid: '',
      fjs: [],
      value1: '',
      fjid: '',
      cws: [],
      value2: '',
      dateAll: '',
      datas: '',
      day: '',
      info: {
        id: 0,
        czbh: '',
        lnrzsbh: '',
        name: '',
        age: '',
        lxfs: '',
        gddh: '',
        xb: '',
        csrq: '',
        hjdz: '',
        ssjgbh: '',
        ssjgmc: '',
        createtime: '',
        updatetime: '',
        rydjid: '',
        xzjdmcid: '',
        xzjdmc: '',
        cwhmcid: '',
        cwhmc: '',
        sfzh: '',
        sfzpic: '',
        sjhm: '',
        minz: '',
        hyzk: '',
        whcd: '',
        zhiye: '',
        xjdz: '',
        znsl: '',
        sfjz: '',
        zfjzlx: '',
        zyjjly: '',
        rylc: '',
        rylcid: '',
        ryfjid: '',
        ryfj: '',
        rycw: '',
        rycwid: '',
        ryzd: '',
        ryzdbm: '',
        zgys: '',
        zgysbm: '',
        zghs: '',
        zghsbm: '',
        zghg: '',
        zghgbm: '',
        ydrname: '',
        ydrdh: '',
        ylrgx: '',
        // yljsxx: [],
        ydcwh: '',
        ydcwid: '',
        kssj: '',
        ydsc: '',
        djys: '',
        // ydxylb: [],
        ydzt: '',
        xzcwcwh: '',
        xzcwmc: '',
        xzcwcwdj: '',
        xzcwrzts: '',
        xzcwrzsj: '',
        xzcwjssj: '',
        cyhsfy: '',
        hlfwfy: '',
        hlfwlist: '',
        qtsfyj: '',
        qtsfycxshf: '',
        ryfyhdts: '',
        ddzfylb: '',
        ryfylb: '',
        scht: '',
        ydxy: '',
        hzzt: '',
        cysj: '',
        cyyy: '',
        cyczry: '',
        cyczryid: ''
      },
      yss: [],
      hss: [],
      hgs: []
    }
  },
  computed: {
    ...mapState(['loginInfo'])
  },
  created () {
    this.getYsList()
    this.getHsList()
    this.getHgList()
    this.getLcList()
    this.news = this.selectInfo
    console.log('选择用户信息', this.selectInfo)
    this.info.name = this.selectInfo.name || ''
    this.info.sfzh = this.selectInfo.sfzh || ''
    this.info.sjhm = this.selectInfo.lxdh || ''
  },
  methods: {
    async getYsList () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-122319876
      try {
        const obj = {
          jgbh: this.loginInfo.jgbh || '',
          cwhmcid: this.loginInfo.tjjgcwhmcid || '',
          lytype: '1'
        }
        const { data: res } = await this.$http.post('/users/getyslb', obj)
        console.log('获取医生列表', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.yss = res.data
      } catch (error) {
        console.log('获取医生列表请求失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    ysChange (e) {
      console.log(this.yss.filter(item => item.value === e)[0].label, this.info.zgysbm)
      this.info.zgys = this.yss.filter(item => item.value === e)[0].label
    },
    async getHsList () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-122319876
      try {
        const obj = {
          jgbh: this.loginInfo.jgbh || '',
          cwhmcid: this.loginInfo.tjjgcwhmcid || '',
          lytype: '2'
        }
        const { data: res } = await this.$http.post('/users/getyslb', obj)
        console.log('获取护士列表', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.hss = res.data
      } catch (error) {
        console.log('获取护士列表请求失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    hsChange (e) {
      console.log(this.hss.filter(item => item.value === e)[0].label, this.info.zghsbm)
      this.info.zghs = this.hss.filter(item => item.value === e)[0].label
    },
    async getHgList () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-122319876
      try {
        const obj = {
          jgbh: this.loginInfo.jgbh || '',
          cwhmcid: this.loginInfo.tjjgcwhmcid || '',
          lytype: '3'
        }
        const { data: res } = await this.$http.post('/users/getyslb', obj)
        console.log('获取护工列表', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.hgs = res.data
      } catch (error) {
        console.log('获取护工列表请求失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    hgChange (e) {
      console.log(this.hss.filter(item => item.value === e)[0].label, this.info.zghgbm)
      this.info.zghg = this.hgs.filter(item => item.value === e)[0].label
    },
    async getLcList () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-117949643
      try {
        const { data: res } = await this.$http.post('/zhyyapi/findlcwsfjlb', {
          ssjgbh: this.loginInfo.jgbh,
          jgbh: this.loginInfo.jgbh,
          lytype: 'lc',
          louceid: '',
          cwid: '',
          fjid: ''
        })
        console.log('楼层列表', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.lcs = res.data
      } catch (error) {
        console.log('楼层列表失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    lcChange (e) {
      this.info.rylc = this.lcs.filter(item => item.louceid === e)[0].louce
      console.log(this.lcs.filter(item => item.louceid === e)[0].louce, this.info.rylcid)
      this.info.ryfj = ''
      this.info.ryfjid = ''
      this.info.rycw = ''
      this.info.rycwid = ''
      this.getFjList()
    },
    async getFjList () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-117949643
      try {
        const { data: res } = await this.$http.post('/zhyyapi/findlcwsfjlb', {
          ssjgbh: this.loginInfo.jgbh,
          jgbh: this.loginInfo.jgbh,
          lytype: 'fj',
          louceid: this.info.rylcid,
          cwid: '',
          fjid: ''
        })
        console.log('房间列表', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.fjs = res.data
      } catch (error) {
        console.log('房间列表失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    fjChange (e) {
      console.log('-------', e)
      this.info.ryfj = this.fjs.filter(item => item.fjid === e)[0].fjh
      console.log(this.fjs.filter(item => item.fjid === e)[0].fjh, this.info.ryfjid)
      this.info.rycw = ''
      this.info.rycwid = ''
      this.getCwList()
    },
    async getCwList () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-117949643
      try {
        const { data: res } = await this.$http.post('/zhyyapi/findlcwsfjlb', {
          ssjgbh: this.loginInfo.jgbh,
          jgbh: this.loginInfo.jgbh,
          lytype: 'fjcw',
          louceid: this.info.rylcid,
          cwid: '',
          fjid: this.info.ryfjid
        })
        console.log('床位列表', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.cws = res.data
      } catch (error) {
        console.log('床位列表失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    cwChange (e) {
      this.info.rycw = this.cws.filter(item => item.cwhid === e)[0].cwh
      console.log(this.cws.filter(item => item.cwhid === e)[0].cwh, this.info.rycwid)
    },
    add () {
      this.isInput = false
    },
    updates () {
      console.log('是否包含yljsxx', 'yljsxx' in this.info)
      if (!('yljsxx' in this.info)) return
      const obj = {
        id: '',
        jxxm: '',
        jxlxfs: '',
        jxgx: '',
        jxgxbm: '',
        jxqt: ''
      }
      if (this.info.yljsxx === null) {
        this.info.yljsxx = []
      }
      console.log(this.info.yljsxx)
      this.info.yljsxx.unshift(obj)
      this.isInputs = false
    },
    dateChange (e) {
      console.log(e)
      this.info.xzcwrzsj = e[0]
      this.info.xzcwjssj = e[1]
      this.info.xzcwrzts = this.days(new Date(e[0]), new Date(e[1])) + ''
      console.log(this.dateAll)
    },
    days (start, end) {
      return Math.ceil(Math.abs(start - end) / 86400000)
    },
    next () {
      this.tabIndex = 1
    },
    upper () {
      this.tabIndex = 0
    },
    async submit () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-111492378
      console.log('请求参数', this.info)
      try {
        this.info.ssjgbh = this.loginInfo.jgbh
        const { data: res } = await this.$http.post('/zhyyapi/ykdrydjadd', this.info)
        console.log('保存', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.$message.success(res.msg)
      } catch (error) {
        console.log('保存失败', error)
      }
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="less">
.add {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0 40px;
  box-sizing: border-box;
  z-index: 1;
  background-color: #081C38;

  .add-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;

    .title {
      height: 38px;
      line-height: 38px;
      padding: 0 20px;
      background-color: #04B4D0;
      text-align: center;
      color: #fff;
      font-size: 20px;
    }

    .input {
      width: 246px;

      /deep/.el-input__inner {
        border: none;
        background: linear-gradient(153deg, #0A477B 0%, #0E67AD 100%);
        color: #fff;
        font-size: 16px;
      }

      /deep/.el-input__inner::-webkit-input-placeholder {
        color: #fff;
      }

      /deep/.el-input__suffix {
        color: #fff;
        font-size: 16px;
      }
    }
  }

  .add-box {
    overflow-y: scroll;
    height: calc(100% - 140px);

    &::-webkit-scrollbar {
      width: 0;
    }

    .add-status {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      .add-status-line {
        width: 52px;
        height: 1px;
        margin: 0 12px;
        background-color: #01EEFD;
      }

      .add-status-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 50px;

        .add-status-icon {
          display: flex;
          flex-direction: row;
          align-items: center;
          color: #01EEFD;
          font-size: 16px;

          .span {
            width: 30px;
            height: 30px;
            line-height: 30px;
            margin-right: 10px;
            text-align: center;
            border-radius: 50%;
            border: 1px solid #fff;
            color: #fff;
            font-size: 16px;
          }

          .active-span {
            background-color: #01EEFD;
          }

          .text {
            color: #fff;
            font-size: 16px;
          }

          .active-text {
            color: #01EEFD;
          }
        }
      }
    }

    .add-info {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 15px 0;
      border-top: 1px solid #01EEFD;
      border-bottom: 1px solid #01EEFD;

      .add-info-left {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 30%;

        .img {
          width: 97px;
          height: 97px;
        }

        .info {
          display: flex;
          flex-direction: column;
          margin-left: 16px;
          color: #01EEFD;
          font-size: 16px;

          .line {
            display: flex;
            align-items: center;
            margin: 5px 0;

            .line-item {
              display: flex;
              align-items: center;
              color: #01EEFD;
              font-size: 16px;
            }

            .el-select {
              width: 120px;
            }
          }
        }
      }

      .add-info-center {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 20%;
        height: 100%;

        .item {
          display: flex;
          align-items: center;
          width: 100%;
          padding: 0 5px;
          box-sizing: border-box;
          color: #01EEFD;
          font-size: 16px;

          .el-input {
            width: calc(100% - 200px);
          }
        }
      }

      .add-info-right {
        display: flex;
        flex-direction: row;
        width: 50%;

        .title {
          height: 43px;
          line-height: 43px;
          color: #01EEFD;
          font-size: 16px;
        }

        .table {
          display: flex;
          flex-direction: column;
          width: 540px;
          margin-left: 35px;
          box-sizing: border-box;

          .border {
            border: 1px solid #01EEFD;
          }

          .flex {
            display: flex;
            justify-content: space-evenly;
            width: 100%;

            span {
              cursor: pointer;
            }
          }

          /deep/.el-input__inner {
            background-color: transparent;
            text-align: center;
            border: none;
            color: #01EEFD;
          }

          ::v-deep .el-table__body-wrapper {
            &::-webkit-scrollbar {
              width: 0px;
            }
          }

          ::v-deep .el-table th.gutter {
            display: none;
            width: 0;
          }

          ::v-deep .el-table colgroup col[name='gutter'] {
            display: none;
            width: 0;
          }

          ::v-deep .el-table__body {
            width: 100% !important;
          }

          .el-table {
            background-color: #092B50;
            border: 4px #073F73 solid;
          }

          // 表头文字颜色
          /deep/.el-table__header-wrapper {
            thead {
              th {
                div {
                  color: #01EEFD;
                  font-size: 14px;
                  font-weight: 400;
                }
              }
            }
          }

          //表格内容颜色
          /deep/.el-table__body tbody tr {
            color: #fff;
            background-color: #092B50;
          }

          /deep/.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
            background-color: #092B50;
          }

          /deep/.el-table tbody tr:hover>td {
            background-color: transparent !important
          }

          // 改变边框颜色
          .el-table--border,
          .el-table--group {
            border: 2px solid #ddd !important;
          }

          /**
  改变表格内竖线颜色
   */
          .el-table--border td,
          .el-table--border th,
          .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
            border-right: 1px solid #ddd !important;
          }

          /deep/.el-table td {
            border-bottom: 1px solid #01EEFD;
          }

          /deep/.el-table thead tr th {
            border-color: transparent;
          }

          /deep/ .el-table--border::after,
          /deep/ .el-table--group::after,
          /deep/ .el-table::before {
            background-color: #073F73;
          }

          /deep/.el-table--border {
            border: 1px solid #073F73 !important;
          }

          /deep/.el-table .cell {
            text-align: center;
            color: #01EEFD;
          }

          /deep/.el-checkbox__inner {
            background-color: transparent;
          }

          ::v-deep .el-table__empty-text {
            color: #01EEFD;
          }

          .tr {
            display: flex;
            flex-direction: row;
            align-items: center;

            .td {
              flex: 1;
              height: 43px;
              line-height: 43px;
              text-align: center;
              border-bottom: 1px solid #01EEFD;
              border-right: 1px solid #01EEFD;
              box-sizing: border-box;
              color: #01EEFD;
              font-size: 16px;

              /deep/.el-input__inner {
                text-align: center;
                background-color: transparent;
                border: none;
                color: #01EEFD;
                font-size: 16px;
                cursor: default;
              }
            }
          }
        }

        .adds {
          width: 70px;
          height: 40px;
          line-height: 40px;
          margin-left: 10px;
          text-align: center;
          background: #1E58A6;
          border: none;
          color: #fff;
          cursor: pointer;
        }
      }
    }

    .add-box-title {
      height: 40px;
      line-height: 40px;
      margin-top: 20px;
      border-bottom: 1px solid #01EEFD;
      box-sizing: border-box;
      color: #01EEFD;
      font-size: 16px;

      .span {
        margin-left: 36px;
      }
    }

    .add-line {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 10px;
      box-sizing: border-box;

      .add-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 25%;

        .add-key {
          margin-right: 10px;
          white-space: nowrap;
          color: #01EEFD;
          font-size: 16px;
        }

        .el-select {
          width: 180px;
        }

        .el-input {
          width: 180px;
        }
      }
    }

    .add-table {
      display: flex;
      flex-direction: column;
      width: 870px;
      margin-top: 20px;
      border: 4px solid #073F73;

      .add-tr {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 870px;

        .add-td {
          flex: 1;
          height: 44px;
          line-height: 44px;
          text-align: center;
          color: #01EEFD;
          font-size: 17px;
        }

        .add-td1 {
          flex: 2;
        }

        .add-td2 {
          flex: 3;
        }

        .border-right {
          border-right: 1px solid #01EEFD;
          box-sizing: border-box;
        }
      }

      .add-tr1 {
        background-color: #024276;
      }

      .add-tr2 {
        border-bottom: 1px solid #01EEFD;
        box-sizing: border-box;
      }
    }

    .add-upload {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 60px;

      .el-button {
        background-color: #04B4D0;
        border: none;
        color: #fff;
      }

      .add-upload-text {
        margin-left: 30px;
        color: #fff;
        font-size: 16px;
      }
    }
  }

  .add-btns {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 60px;

    .el-button {
      margin-right: 30px;
      background-color: #024A83;
      border: none;
      color: #fff;
    }

    .gray {
      background-color: #33465C;
    }
  }

  .flex {
    display: flex;
    justify-content: space-evenly;
    width: 100%;

    span {
      cursor: pointer;
    }
  }

  ::v-deep .el-table__body-wrapper {
    &::-webkit-scrollbar {
      width: 0px;
    }
  }

  ::v-deep .el-table th.gutter {
    display: none;
    width: 0;
  }

  ::v-deep .el-table colgroup col[name='gutter'] {
    display: none;
    width: 0;
  }

  ::v-deep .el-table__body {
    width: 100% !important;
  }

  .el-table {
    background-color: #092B50;
    border: 4px #073F73 solid;
  }

  // 表头文字颜色
  /deep/.el-table__header-wrapper {
    thead {
      th {
        div {
          color: #01EEFD;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }

  //表格内容颜色
  /deep/.el-table__body tbody tr {
    color: #fff;
    background-color: #092B50;
  }

  /deep/.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
    background-color: #092B50;
  }

  /deep/.el-table tbody tr:hover>td {
    background-color: transparent !important
  }

  // 改变边框颜色
  .el-table--border,
  .el-table--group {
    border: 2px solid #ddd !important;
  }

  /**
  改变表格内竖线颜色
   */
  .el-table--border td,
  .el-table--border th,
  .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
    border-right: 1px solid #ddd !important;
  }

  /deep/.el-table td {
    border-bottom: 1px solid #01EEFD;
  }

  /deep/.el-table thead tr th {
    border-color: transparent;
  }

  /deep/ .el-table--border::after,
  /deep/ .el-table--group::after,
  /deep/ .el-table::before {
    background-color: #073F73;
  }

  /deep/.el-table--border {
    border: 1px solid #073F73 !important;
  }

  /deep/.el-table .cell {
    text-align: center;
    color: #01EEFD;
  }

  /deep/.el-checkbox__inner {
    background-color: transparent;
  }

  ::v-deep .el-table__empty-text {
    color: #01EEFD;
  }
}
</style>
