<!--
 * @Author: 小宝 zksukuo@yunkangda.com
 * @Date: 2024-04-15 08:23:45
 * @LastEditors: 小宝 zksukuo@yunkangda.com
 * @LastEditTime: 2024-04-23 13:58:08
 * @FilePath: \visions\src\views\zhyl\smartPhysicalExamination\physicalExaminationRun\physicalExaminationInfoRecordRun\report\tab1\tab1.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="tab">
    <div class="tab-scroll">
      <table border="1" class="table" cellspacing="0" cellpadding="0">
        <tr class="tr">
          <td class="td title" colspan="10">学生健康体检表</td>
        </tr>
        <tr class="tr">
          <td class="td" colspan="1">学生信息</td>
          <td class="td" colspan="2">体检年度</td>
          <td class="td" colspan="2">{{ info.tjrq || '' }}</td>
          <td class="td" colspan="1">体检学期</td>
          <td class="td" colspan="4">上学期</td>
        </tr>
        <tr class="tr">
          <td class="td" colspan="1"></td>
          <td class="td" colspan="2"></td>
          <td class="td" colspan="2"></td>
          <td class="td" colspan="1"></td>
          <td class="td" colspan="4"></td>
        </tr>
        <tr class="tr">
          <td class="td" colspan="1"></td>
          <td class="td" colspan="2">学校</td>
          <td class="td" colspan="2">{{ info.ssxx || '' }}</td>
          <td class="td" colspan="1">年级班级</td>
          <td class="td" colspan="4">{{ info.ssnj || '-' }} / {{ info.ssbj || '-' }}</td>
        </tr>
        <tr class="tr">
          <td class="td" colspan="1"></td>
          <td class="td" colspan="2">姓名</td>
          <td class="td" colspan="2">{{ info.name || '' }}</td>
          <td class="td" colspan="1">性别</td>
          <td class="td" colspan="4">{{ info.xb | sexType }}</td>
        </tr>
        <tr class="tr">
          <td class="td" colspan="1"></td>
          <td class="td td1" colspan="3">检查项目</td>
          <td class="td td1" colspan="4">检查结果</td>
          <td class="td td1" colspan="2">体检医生</td>
        </tr>

        <tr class="tr">
          <td class="td" colspan="1" rowspan="60">医疗机构填写</td>
          <td class="td" colspan="1" rowspan="3">既往史</td>
          <td class="td" colspan="2">过敏史</td>
          <td class="td" colspan="4">{{ info.jwsgms | gmsType }}</td>
          <td class="td" colspan="2" rowspan="3">{{ info.jwsjcys || '' }}</td>
        </tr>
        <tr class="tr">
          <td class="td" colspan="2">传染病史</td>
          <td class="td" colspan="4">{{ info.jwscrbs | crbsType }}</td>
        </tr>
        <tr class="tr">
          <td class="td" colspan="2">先天性心脏病</td>
          <td class="td" colspan="4">{{ info.jwsxtxxz | xxxxzbType }}</td>
        </tr>

        <tr class="tr">
          <td class="td" colspan="1" rowspan="4">内科</td>
          <td class="td" colspan="2">心</td>
          <td class="td" colspan="4">{{ info.nkxz | nkxzType }}</td>
          <td class="td" colspan="2" rowspan="4">{{ info.nkjcys }}</td>
        </tr>
        <tr class="tr">
          <td class="td" colspan="2">肺</td>
          <td class="td" colspan="4">{{ info.nkfei | nkfeiType }}</td>
        </tr>
        <tr class="tr">
          <td class="td" colspan="2">肝</td>
          <td class="td" colspan="4">{{ info.nkgz | nkgzType }}</td>
        </tr>
        <tr class="tr">
          <td class="td" colspan="2">脾</td>
          <td class="td" colspan="4">{{ info.nkpz | nkpzType }}</td>
        </tr>

        <tr class="tr">
          <td class="td" colspan="1" rowspan="6">形态</td>
          <td class="td" colspan="2">身高</td>
          <td class="td" colspan="4">{{ info.xtzbsg || '' }}</td>
          <td class="td" colspan="2" rowspan="6">{{ info.xtjcys || '' }}</td>
        </tr>
        <tr class="tr">
          <td class="td" colspan="2">体重</td>
          <td class="td" colspan="4">{{ info.xtzbtz || '' }}</td>
        </tr>
        <tr class="tr">
          <td class="td" colspan="2">BMI</td>
          <td class="td" colspan="4">{{ info.bmi || '' }}</td>
        </tr>
        <tr class="tr">
          <td class="td" colspan="2">腰围</td>
          <td class="td" colspan="4">{{ info.xtzbyw || '' }}</td>
        </tr>
        <tr class="tr">
          <td class="td" colspan="2">臀围</td>
          <td class="td" colspan="4">{{ info.xtzbtww || '' }}</td>
        </tr>
        <tr class="tr">
          <td class="td" colspan="2">腰臀比</td>
          <td class="td" colspan="4">{{ info.xtzbytb || '' }}</td>
        </tr>

        <tr class="tr">
          <td class="td" colspan="1" rowspan="3">血压</td>
          <td class="td" colspan="2">收缩压</td>
          <td class="td" colspan="4">{{ info.xyssy || '' }}</td>
          <td class="td" colspan="2" rowspan="3">{{ info.xyjcys || '' }}</td>
        </tr>
        <tr class="tr">
          <td class="td" colspan="2">舒张压</td>
          <td class="td" colspan="4">{{ info.xyszy || '' }}</td>
        </tr>
        <tr class="tr">
          <td class="td" colspan="2">脉搏</td>
          <td class="td" colspan="4">{{ info.xyml || '' }}</td>
        </tr>

        <tr class="tr">
          <td class="td" colspan="1">肺活量</td>
          <td class="td" colspan="2">肺活量</td>
          <td class="td" colspan="4">{{ info.fggnjcfhl || '' }}</td>
          <td class="td" colspan="2">{{ info.fggjcys || '' }}</td>
        </tr>

        <tr class="tr">
          <td class="td" colspan="1" rowspan="3">脊柱侧弯</td>
          <td class="td" colspan="2">躯干旋转角（ATR）胸段</td>
          <td class="td" colspan="4">{{ info.jzcwqgxzjxdzt | jzcwqgxzjxdztType }}</td>
          <td class="td" colspan="2" rowspan="3">{{ info.jzcwys || '' }}</td>
        </tr>
        <tr class="tr">
          <td class="td" colspan="2">躯干旋转角（ATR）胸腰段</td>
          <td class="td" colspan="4">{{ info.jzcwqgxzjxydzt | jzcwqgxzjxdztType }}</td>
        </tr>
        <tr class="tr">
          <td class="td" colspan="2">躯干旋转角（ATR）腰段</td>
          <td class="td" colspan="4">{{ info.jzcwqgxzjydzt | jzcwqgxzjxdztType }}</td>
        </tr>

        <tr class="tr">
          <td class="td" colspan="1" rowspan="6">外科</td>
          <td class="td" colspan="2">头部</td>
          <td class="td" colspan="4">{{ info.wktb | wktbType }}</td>
          <td class="td" colspan="2" rowspan="6">{{ info.wkjcys || '' }}</td>
        </tr>
        <tr class="tr">
          <td class="td" colspan="2">颈部</td>
          <td class="td" colspan="4">{{ info.wkjz | wkjzType }}</td>
        </tr>
        <tr class="tr">
          <td class="td" colspan="2">胸部</td>
          <td class="td" colspan="4">{{ info.wkxb | wkxbType }}</td>
        </tr>
        <tr class="tr">
          <td class="td" colspan="2">四肢</td>
          <td class="td" colspan="4">{{ info.wkjzsz | wkjzszType }}</td>
        </tr>
        <tr class="tr">
          <td class="td" colspan="2">皮肤</td>
          <td class="td" colspan="4">{{ info.wkpf | wkpfType }}</td>
        </tr>
        <tr class="tr">
          <td class="td" colspan="2">淋巴结</td>
          <td class="td" colspan="4">{{ info.wklbj | wklbjType }}</td>
        </tr>

        <tr class="tr">
          <td class="td" colspan="1" rowspan="5">耳鼻喉</td>
          <td class="td" colspan="2">听力（左耳）</td>
          <td class="td" colspan="4">{{ info.ebhkzr | ebhkzrType }}</td>
          <td class="td" colspan="2" rowspan="5">{{ info.ebhjcys || '' }}</td>
        </tr>
        <tr class="tr">
          <td class="td" colspan="2">听力（右耳）</td>
          <td class="td" colspan="4">{{ info.ebhkyr | ebhkyrType }}</td>
        </tr>
        <tr class="tr">
          <td class="td" colspan="2">外耳道与鼓膜</td>
          <td class="td" colspan="4">{{ info.ebhwedygm | ebhwedygmType }}</td>
        </tr>
        <tr class="tr">
          <td class="td" colspan="2">外鼻</td>
          <td class="td" colspan="4">{{ info.ebhwb | ebhwbType }}</td>
        </tr>
        <tr class="tr">
          <td class="td" colspan="2">嗅觉</td>
          <td class="td" colspan="4">{{ info.ebhxj | ebhxjType }}</td>
        </tr>

        <tr class="tr">
          <td class="td" colspan="1" rowspan="11">眼科</td>
          <td class="td" colspan="2">眼外观</td>
          <td class="td" colspan="4">{{ info.yankewg | yankewgType }}</td>
          <td class="td" colspan="2" rowspan="11">{{ info.yankejcys || '' }}</td>
        </tr>
        <tr class="tr">
          <td class="td" colspan="1" rowspan="2">视力</td>
          <td class="td" colspan="1">右眼</td>
          <td class="td" colspan="4">{{ info.yankeyylysl || '' }}</td>
        </tr>
        <tr class="tr">
          <td class="td" colspan="1">左眼</td>
          <td class="td" colspan="4">{{ info.yankecylysl || '' }}</td>
        </tr>
        <tr class="tr">
          <td class="td" colspan="1" rowspan="2">矫正视力</td>
          <td class="td" colspan="1">右眼</td>
          <td class="td" colspan="4">{{ info.yankesljzyy || '' }}</td>
        </tr>
        <tr class="tr">
          <td class="td" colspan="1">左眼</td>
          <td class="td" colspan="4">{{ info.yankesljzzy || '' }}</td>
        </tr>
        <tr class="tr">
          <td class="td" colspan="1" rowspan="6">屈光度</td>
          <td class="td" colspan="1">球镜（右眼）</td>
          <td class="td" colspan="4">{{ info.yankeyyqgqjs || '' }}</td>
        </tr>
        <tr class="tr">
          <td class="td" colspan="1">柱镜（右眼）</td>
          <td class="td" colspan="4">{{ info.yankeyyqgzjs || '' }}</td>
        </tr>
        <tr class="tr">
          <td class="td" colspan="1">轴向值（右眼）</td>
          <td class="td" colspan="4">{{ info.yankeyyqtzwa || '' }}</td>
        </tr>
        <tr class="tr">
          <td class="td" colspan="1">球镜（左眼）</td>
          <td class="td" colspan="4">{{ info.yankecyqgqjs || '' }}</td>
        </tr>
        <tr class="tr">
          <td class="td" colspan="1">柱镜（左眼）</td>
          <td class="td" colspan="4">{{ info.yankecyqgzjs || '' }}</td>
        </tr>
        <tr class="tr">
          <td class="td" colspan="1">轴向值（左眼）</td>
          <td class="td" colspan="4">{{ info.yankecyqtzwa || '' }}</td>
        </tr>

        <tr class="tr">
          <td class="td" colspan="1" rowspan="4">口腔（扁桃体）</td>
          <td class="td" colspan="2">扁桃体</td>
          <td class="td" colspan="4">{{ info.kqbtt | kqbttType }}</td>
          <td class="td" colspan="2" rowspan="4">{{ info.kqkjcys || '' }}</td>
        </tr>
        <tr class="tr">
          <td class="td" colspan="2">口腔牙齿数</td>
          <td class="td" colspan="4">{{ info.kqkkqyacs || '' }}</td>
        </tr>
        <tr class="tr">
          <td class="td" colspan="2">口腔龋齿数</td>
          <td class="td" colspan="4">{{ info.kqkkqrcs || '' }}</td>
        </tr>
        <tr class="tr">
          <td class="td" colspan="2">牙周</td>
          <td class="td" colspan="4">{{ info.kqkkqyz | kqkkqyzType }}</td>
        </tr>

        <tr class="tr">
          <td class="td" colspan="1" rowspan="7">实验室</td>
          <td class="td" colspan="2">PPD结核菌素试验</td>
          <td class="td" colspan="4">{{ info.jhjssy | jhjssyType }}</td>
          <td class="td" colspan="2" rowspan="7">{{ info.ppdjcys || '' }}</td>
        </tr>
        <tr class="tr">
          <td class="td" colspan="2">血常规-红细胞</td>
          <td class="td" colspan="4">{{ info.xcghxb || '' }}</td>
        </tr>
        <tr class="tr">
          <td class="td" colspan="2">血常规-白细胞</td>
          <td class="td" colspan="4">{{ info.xcgbxb || '' }}</td>
        </tr>
        <tr class="tr">
          <td class="td" colspan="2">血常规-血红蛋白</td>
          <td class="td" colspan="4">{{ info.xcgxhdb || '' }}</td>
        </tr>
        <tr class="tr">
          <td class="td" colspan="2">血常规-血小板</td>
          <td class="td" colspan="4">{{ info.xcgxxb || '' }}</td>
        </tr>
        <tr class="tr">
          <td class="td" colspan="2">丙氨酸氨基转移酶</td>
          <td class="td" colspan="4"></td>
        </tr>
        <tr class="tr">
          <td class="td" colspan="2">其他化验</td>
          <td class="td" colspan="4"></td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
let thatThis = null
export default {
  data () {
    return {
      info: {
        id: '',
        name: '',
        tjrq: '',
        xuehao: '',
        xb: '',
        sfjh: '',
        ssxx: '',
        ssxxid: '',
        ssbj: '',
        ssbjid: '',
        ssnj: '',
        ssnjid: '',
        whcd: '',
        whcdid: '',
        ssmc: '',
        ssmcid: '',
        jg: '',
        lxdh: '',
        xzz: '',
        hjdz: '',
        jzxx: '',
        lxrid: '',
        lxr: '',
        lxrxm: '',
        lxrdh: '',
        rxnf: '',
        hcrq: '',
        hcwcrq: '',
        jwsqm: '',
        jwsg: '',
        jwsfjj: '',
        jwsxtxxz: '',
        jwss: '',
        jwsfs: '',
        jwsdfb: '',
        jwsgms: '',
        jwscrbs: '',
        jwsqt: '',
        jwsjcqk: '',
        jwsjcys: '',
        jwsjcysid: '',
        jwspj: '',
        jwstjrq: '',
        jwsflag: '',
        tmcode: '',
        tjbh: '',
        nkfei: '',
        nkqm: '',
        nkfeides: '',
        nkfeiqtdes: '',
        nkfeipj: '',
        nkjcqk: '',
        nkjcys: '',
        nkjcysid: '',
        nkxz: '',
        nkxzdes: '',
        nkxzqtdes: '',
        nkxzpj: '',
        nkfub: '',
        nkfubdes: '',
        nkfubqtdes: '',
        nkfubp: '',
        nkgz: '',
        nkgzdes: '',
        nkgzqtdes: '',
        nkgzpj: '',
        nkpz: '',
        nkpzdes: '',
        nkpzqtdes: '',
        nkpzpj: '',
        nktjrq: '',
        nktjjy: '',
        nkflag: '',
        wkqm: '',
        wkpf: '',
        wkpfdes: '',
        wkpfqtdes: '',
        wkpfpj: '',
        wkjz: '',
        wkjzdes: '',
        wkjzqtdes: '',
        wkjzpj: '',
        wkxb: '',
        wkxbdes: '',
        wkxbqtdes: '',
        wkxbpj: '',
        wkjzsz: '',
        wkjzszdes: '',
        wkjzszqtdes: '',
        wkjzszpj: '',
        wktb: '',
        wktbdes: '',
        wktbqtdes: '',
        wktbpj: '',
        wklbj: '',
        wklbjdes: '',
        wklbjqtdes: '',
        wklbjpj: '',
        wkwszq: '',
        wkwszqdes: '',
        wkwszqqtdes: '',
        wkwszqpj: '',
        wkfzjcqtdes: '',
        wkjcys: '',
        wkjcysid: '',
        wkjcqk: '',
        wktjrq: '',
        wktjjy: '',
        wkflag: '',
        jzcwqgxzjxd: '',
        jzcwqgxzjxdzt: '',
        jzcwqgxzjxydzt: '',
        jzcwqgxzjydzt: '',
        jzcwjcqk: '',
        jzcwtjjl: '',
        jzcwqm: '',
        jzcwqgxzjxyd: '',
        jzcwqgxzjyd: '',
        jzcwckjl: '',
        jzcwpj: '',
        jzcwys: '',
        jzcwysid: '',
        jzcwtjrq: '',
        jzcwtjjy: '',
        jzcwflag: '',
        yankesm: '',
        yankeqm: '',
        yankesmdes: '',
        yankesmqtdes: '',
        yankezy: '',
        yankezydes: '',
        yankezyqtdes: '',
        yankesej: '',
        yankesjdes: '',
        yankesjqtdes: '',
        yankeyy: '',
        yankeyydes: '',
        yankeyyqtdes: '',
        yankecyqgd: '',
        yankecyqgddes: '',
        yankecyqgdqtdes: '',
        yankeyyqgd: '',
        yankeyyqgddes: '',
        yankeyyqgdqtdes: '',
        yankesy: '',
        yankesydes: '',
        yankesyqtdes: '',
        yankecylysl: '',
        yankeyylysl: '',
        yankewg: '',
        yankewgdes: '',
        yankesljzzy: '',
        yankesljzyy: '',
        yankecyqgqjs: '',
        yankeyyqgqjspj: '',
        yankeyyqgzjspj: '',
        yankecyqgqjspj: '',
        yankecyqgzjspj: '',
        yankeyyqgqjs: '',
        yankecyqgzjs: '',
        yankeyyqgzjs: '',
        yankecyqtzwa: '',
        yankeyyqtzwa: '',
        yankejcys: '',
        yankejcysid: '',
        yankejjcqk: '',
        yktjrq: '',
        yktjjy: '',
        ykflag: '',
        ebhkzr: '',
        ebhkqm: '',
        ebhkzrdes: '',
        ebhkzrqtdes: '',
        ebhkyr: '',
        ebhkyrdes: '',
        ebhkyrqtdes: '',
        ebhyhb: '',
        ebhbk: '',
        ebhbkdes: '',
        ebhbkqtdes: '',
        ebhjcys: '',
        ebhjcysid: '',
        ebhjcqk: '',
        ebhwedygm: '',
        ebhwedygmdes: '',
        ebhwbdes: '',
        ebhxjdes: '',
        ebhwb: '',
        ebhxj: '',
        ebhktjrq: '',
        ebhktjjy: '',
        ebhkflag: '',
        kqkkqyacs: '',
        kqkqm: '',
        kqkkqrcs: '',
        kqqcsflag: '',
        kqkkqcs: '',
        kqbtt: '',
        kqbttdes: '',
        kqkrrs: '',
        kqkrrb: '',
        kqkhrs: '',
        kqkhrh: '',
        kqkhrb: '',
        kqkkqyz: '',
        kqkkqyzdes: '',
        kqkkqyzqtdes: '',
        kqkjcys: '',
        kqkjcysid: '',
        kqjcqk: '',
        kqktjrq: '',
        kqktjjy: '',
        kqktjjl: '',
        kqkflag: '',
        xtzbtz: '',
        xtzbqm: '',
        xtzbsg: '',
        xtzbtgfypj: '',
        BMI: '',
        xtzbyw: '',
        xtzbtww: '',
        xtzbytb: '',
        xtzbbml: '',
        xitcqk: '',
        xtjcys: '',
        xtjcysid: '',
        xtzbtjrq: '',
        xtzbtjjy: '',
        xtzbflag: '',
        xyssy: '',
        xyqm: '',
        xyszy: '',
        xyml: '',
        xyjcqk: '',
        xyjcys: '',
        xyjcysid: '',
        xytjrq: '',
        xytjjy: '',
        xyflag: '',
        tlsctlz: '',
        tlqm: '',
        tlsctlzdes: '',
        tlsctlzqtdes: '',
        tlsctly: '',
        tlsctlydes: '',
        tlsctlyqtdes: '',
        tljcys: '',
        tljcysid: '',
        tljcqk: '',
        fhlqm: '',
        fggnjcfhl: '',
        fgnpgzb: '',
        fggjcqk: '',
        fggpj: '',
        fggjcys: '',
        fggjcysid: '',
        fgntjrq: '',
        fgntjjy: '',
        fgnflag: '',
        xcgqm: '',
        xcgbxb: '',
        bxbpj: '',
        hxbpj: '',
        xhdbpj: '',
        xxbpj: '',
        xcgpj: '',
        xcgpjdes: '',
        xcgxhdb: '',
        xcgxhdbdes: '',
        xcgxxb: '',
        xcgxxbdes: '',
        xcghxb: '',
        xcghxbdes: '',
        xcgjcqk: '',
        xcgjcys: '',
        xcgjcysid: '',
        ppdqm: '',
        ppdtjrq: '',
        ppdtjjy: '',
        ppdtjdes: '',
        ppdflag: '',
        ppdjcys: '',
        ppdjcysid: '',
        xcgtjrq: '',
        xcgtjjy: '',
        xcgflag: '',
        xshqm: '',
        xshtjrq: '',
        xshtjjy: '',
        xshflag: '',
        xshpjdes: '',
        xshxqgb: '',
        xshxqgbpj: '',
        xshxqgbpjdes: '',
        xshxqgc: '',
        xshxqdhs: '',
        xshbasanzym: '',
        xshjcqk: '',
        xshjcys: '',
        xshjcysid: '',
        jhjssy: '',
        jhjssyjcqk: '',
        ncgndb: '',
        ncgnt: '',
        ncgntt: '',
        ncgnqx: '',
        ncgjcys: '',
        ncgjcysid: '',
        ncgjcqk: '',
        xlcpsfcp: '',
        xlcpjg: '',
        xlcpjgdes: '',
        xlcpqk: '',
        xlcpys: '',
        xlcpysid: '',
        tjycxj: '',
        tjycxjys: '',
        tjycxjysid: '',
        tjycyj: '',
        tjjkzd: '',
        tjyyzd: '',
        tjydzn: '',
        tjwxts: '',
        tjtcid: '',
        tjtcmc: '',
        czry: '',
        czryid: '',
        tjjd: '',
        tjjdid: '',
        tjxmlb: '',
        tjxmlbid: '',
        tjzt: '',
        bgkfflag: '',
        unionid: '',
        openid: '',
        dnbh: '',
        gmsqt: '',
        crsqt: '',
        ywgqt: '',
        yktjjl: '',
        wedygmqt: '',
        wbqt: '',
        xjqt: '',
        bttqt: '',
        kqkqy: '',
        kqkdsy: '',
        sgpj: '',
        tzpj: '',
        bmipj: '',
        ytbpj: '',
        ssypj: '',
        szypj: '',
        mlpj: '',
        age: '',
        sfzh: '',
        tjjgbh: '',
        tjjgmc: '',
        zjys: '',
        ssjgbh: '',
        ssjgmc: '',
        sfyy: '',
        bgpwd: '',
        bgurl: '',
        csrq: ''
      }
    }
  },
  filters: {
    sexType (val) {
      if (val === '' || val === null) return ''
      if (val === '1') return '男'
      if (val === '2') return '女'
    },
    gmsType (val) {
      if (val === '' || val === null) return ''
      if (val === '0') return '未发现'
      if (val === '99') return thatThis.info.gmsqt
      return val
    },
    crbsType (val) {
      if (val === '' || val === null) return ''
      if (val === '0') return '未发现'
      if (val === '99') return thatThis.info.crsqt
      return val
    },
    xxxxzbType (val) {
      if (val === '' || val === null) return ''
      if (val === '0') return '未见异常'
      if (val === '1') return '异常'
      return val
    },
    nkxzType (val) {
      if (val === '' || val === null) return ''
      if (val === '99') return thatThis.info.nkxzqtdes
      const arr = ['未见异常', '心律异常', '异常心音', '有杂音']
      return arr[val]
    },
    nkfeiType (val) {
      if (val === '' || val === null) return ''
      if (val === '99') return thatThis.info.nkfeiqtdes
      const arr = ['未见异常', '异常']
      return arr[val]
    },
    nkgzType (val) {
      if (val === '' || val === null) return ''
      const arr = ['未触及', '触及']
      return arr[val]
    },
    nkpzType (val) {
      if (val === '' || val === null) return ''
      const arr = ['未触及', '触及']
      return arr[val]
    },
    jzcwqgxzjxdztType (val) {
      if (val === '' || val === null) return ''
      const arr = ['正常', '异常']
      return arr[val]
    },
    wktbType (val) {
      if (val === '' || val === null) return ''
      if (val === '99') return thatThis.info.wktbqtdes
      const arr = ['未见异常', '畸形']
      return arr[val]
    },
    wkjzType (val) {
      if (val === '' || val === null) return ''
      if (val === '99') return thatThis.info.wkjzqtdes
      const arr = ['未见异常', '颈部偏曲', '甲状腺肿大', '有结节']
      return arr[val]
    },
    wkxbType (val) {
      if (val === '' || val === null) return ''
      if (val === '99') return thatThis.info.wkxbqtdes
      const arr = ['未见异常', '胸廓畸形', '胸廓局部隆起', '胸廓压痛', '异常搏动', '器官左右偏']
      return arr[val]
    },
    wkjzszType (val) {
      if (val === '' || val === null) return ''
      if (val === '99') return thatThis.info.wkjzszqtdes
      const arr = ['未见异常', '畸形', '杵状指', '关节红肿', '关节疼痛', '活动受限']
      return arr[val]
    },
    wkpfType (val) {
      if (val === '' || val === null) return ''
      if (val === '99') return thatThis.info.wkpfqtdes
      const arr = ['未见异常', '颜色（潮红、苍白、发绀、黄染、色素沉着）', '水肿', '出血', '皮疹', '有瘢痕']
      return arr[val]
    },
    wklbjType (val) {
      if (val === '' || val === null) return ''
      if (val === '99') return thatThis.info.wkpfqtdes
      const arr = ['未见异常', '浅表淋巴结-颌下', '浅表淋巴结-颈部', '浅表淋巴结-腋窝']
      return arr[val]
    },
    ebhkzrType (val) {
      if (val === '' || val === null) return ''
      if (val === '99') return thatThis.info.ebhkzrqtdes
      const arr = ['未见异常', '听力下降？']
      return arr[val]
    },
    ebhkyrType (val) {
      if (val === '' || val === null) return ''
      if (val === '99') return thatThis.info.ebhkyrqtdes
      const arr = ['未见异常', '听力下降？']
      return arr[val]
    },
    ebhwedygmType (val) {
      if (val === '' || val === null) return ''
      if (val === '99') return thatThis.info.wedygmqt
      const arr = ['未见异常', '鼓膜穿孔', '真菌性鼓膜炎', '耳痛伴听力障碍', '耳溢液']
      return arr[val]
    },
    ebhwbType (val) {
      if (val === '' || val === null) return ''
      if (val === '99') return thatThis.info.wbqt
      const arr = ['未见异常', '外鼻畸形', '外鼻皮肤异常', '鼻出血']
      return arr[val]
    },
    ebhxjType (val) {
      if (val === '' || val === null) return ''
      if (val === '99') return thatThis.info.xjqt
      const arr = ['未见异常', '嗅觉减退', '嗅觉丧失', '嗅觉过敏', '嗅觉倒错', '幻嗅']
      return arr[val]
    },
    yankewgType (val) {
      if (val === '' || val === null) return ''
      if (val === '99') return thatThis.info.ywgqt
      const arr = ['未见异常', '眼睑闭合不全', '睑内翻', '睑外翻', '结膜炎', '睑腺炎', '角膜炎']
      return arr[val]
    },
    kqbttType (val) {
      if (val === '' || val === null) return ''
      if (val === '99') return thatThis.info.bttqt
      const arr = ['未见异常', '扁桃体肿大I', '扁桃体肿大II', '扁桃体肿大III']
      return arr[val]
    },
    kqkkqyzType (val) {
      if (val === '' || val === null) return ''
      if (val === '99') return thatThis.info.kqkkqyzqtdes
      const arr = ['未见异常', '牙龈炎']
      return arr[val]
    },
    jhjssyType (val) {
      if (val === '' || val === null) return ''
      const arr = ['阴性', '阳性', '强阳性']
      return arr[val]
    }
  },
  created () {
    thatThis = this
    this.getInfo()
  },
  methods: {
    async getInfo () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-159174148
      try {
        const obj = {
          tmcode: this.$parent.info.tmcode
        }
        const { data: res } = await this.$http.post('/zhctapi/findtmjl', obj)
        console.log('根据条码获取记录', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.info = res.data
      } catch (error) {
        console.log('根据条码获取记录', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    yhChange (e) {
      console.log(e)
      if (e !== '5') {
        this.info.ywgmsDes = ''
      }
    },
    ssChange (e) {
      console.log(e)
    },
    jzsChange (e) {
      if (e !== '12') {
        this.info.jzsfqDes = ''
      }
    },
    ycbsChange (e) {
      if (e !== '2') {
        this.info.ycbDes = ''
      }
    },
    cjqkChange (e) {
      if (e !== '8') {
        this.info.cjqkDes = ''
      }
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.tab {
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;

  .tab-scroll {
    overflow-y: scroll;
    width: 100%;
    height: 100%;

    &::-webkit-scrollbar {
      width: 0;
    }

    .table {
      width: 100%;

      .tr {}

      .td {
        padding: 10px 0;
        text-align: center;
        color: #000;
        font-size: 16px;
      }

      .td1 {
        font-size: 20px;
        font-weight: 600;
      }

      .title {
        font-size: 26px;
      }
    }
  }

}
</style>
