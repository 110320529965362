var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"popup"},[_c('div',{staticClass:"popup-box"},[_vm._m(0),_c('div',{staticClass:"popup-content"},[_c('div',{staticClass:"popup-content-box"},[_c('div',{staticClass:"info-close",on:{"click":_vm.close}},[_c('span',{staticClass:"el-icon-close"})]),_vm._m(1),_vm._m(2),_vm._m(3),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"height":"120"}},[_c('el-table-column',{attrs:{"prop":"name","label":"护理服务"}}),_c('el-table-column',{attrs:{"label":"操作"}},[[_c('div',[_c('span',[_vm._v("移除")])])]],2)],1),_vm._m(4),_vm._m(5),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"height":"140"}},[_c('el-table-column',{attrs:{"prop":"name","label":"护理服务"}}),_c('el-table-column',{attrs:{"label":"操作"}},[[_c('div',[_c('span',[_vm._v("移除")])])]],2)],1),_vm._m(6),_c('div',{staticClass:"info-btns"},[_c('el-button',{attrs:{"size":"small"},on:{"click":_vm.close}},[_vm._v("取消")]),_c('el-button',{staticClass:"blue",attrs:{"size":"small"},on:{"click":_vm.close}},[_vm._v("确定")])],1)],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"popup-title"},[_c('div',{staticClass:"popup-top"}),_c('div',{staticClass:"popup-bottom"},[_c('div',{staticClass:"popup-bottom-text"},[_c('span',[_vm._v("配置服务")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"status"},[_c('span',[_vm._v("待审批")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"head"},[_c('span',{staticClass:"span"},[_vm._v("项目名称：")]),_c('span',{staticClass:"span"},[_vm._v("项目描述： ")]),_c('span',{staticClass:"span"},[_vm._v("配置服务：")]),_c('span',{staticClass:"span"},[_vm._v("护理等级：轻度")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"add"},[_c('span',[_vm._v("新增")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('span',[_vm._v("护理等级：中度")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"add"},[_c('div',{staticClass:"icon"},[_c('span',{staticClass:"el-icon-plus"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title title1"},[_c('span',[_vm._v("护理等级：重度")])])
}]

export { render, staticRenderFns }