<!--
 * @Author: 小宝 zksukuo@yunkangda.com
 * @Date: 2024-01-04 15:48:04
 * @LastEditors: 小宝 zksukuo@yunkangda.com
 * @LastEditTime: 2024-01-05 14:14:59
 * @FilePath: \visions\src\views\zhyl\reservationService\vaccineAppointment\bookingRun\see.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="see">
    <div class="see-box">
      <div class="see-title">
        <span>核定</span>
      </div>
      <div class="see-table">
        <el-table :data="info.ymbmlb" height="100%" :header-cell-style="{ background: '#024276' }">
          <el-table-column prop="ymbm" label="疫苗编码">
          </el-table-column>
          <el-table-column prop="ymmc" label="疫苗名称">
          </el-table-column>
          <el-table-column prop="lxdh" label="规格">
          </el-table-column>
          <el-table-column prop="ymjg" label="价格">
          </el-table-column>
          <el-table-column prop="sccj" label="厂家">
          </el-table-column>
        </el-table>
      </div>
      <div class="see-line">
        <div class="see-line-item">
          <span>期望接种时间:{{ info.qwjzsj || '' }}</span>
        </div>
        <div class="see-line-item">
          <span>期望接种时间段:{{ info.qwjzsjd || '' }}</span>
        </div>
      </div>
      <div class="see-line">
        <div class="see-line-item">
          <span>核定接种时间:{{ info.hdjzsj || '' }}</span>
        </div>
        <div class="see-line-item">
          <span>核定接种时间段:{{ info.hdjzsjd || '' }}</span>
        </div>
      </div>
      <div class="see-line">
        <span>核定人:{{ info.hdry || '' }}</span>
      </div>
      <div class="see-button">
        <el-button type="primary" @click="close">确认</el-button>
        <el-button @click="close">取消</el-button>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    param: {
      type: Object
    }
  },
  data () {
    return {
      tableData: [],
      info: {
        id: 0,
        yyjlid: '',
        name: '',
        sfzh: '',
        lxdh: '',
        ymbms: '',
        ymbmlb: [],
        yysj: '',
        qwjzsj: '',
        qwjzsjd: '',
        hdjzsj: '',
        hdjzsjd: '',
        hdry: '',
        hdrybm: '',
        hdsj: '',
        ymfl: '',
        ymflbm: '',
        qyzt: '',
        unionid: '',
        openId: '',
        mpopenid: '',
        ssjgbh: '',
        ssjgmc: '',
        createtime: '',
        updatetime: '',
        tjjgmc: '',
        tjjgbm: '',
        tjjgcmhid: '',
        tjjgcmhmc: ''
      }
    }
  },
  created () {
    console.log(this.param)
    this.getInfo()
  },
  methods: {
    async getInfo () {
      // 接口文档 https://app.apifox.com/project/2726972/apis/api-138827602
      try {
        const { data: res } = await this.$http.post('/zhyyapi/ykdzhyyymyyjlfind', {
          yyjlid: this.param.yyjlid || '',
          ssjgbh: this.param.ssjgbh || ''
        })
        console.log('根据id获取 Copy', res)
        if (res.code !== 0) return this.$message.error(res.msg)
        this.info = res.data[0]
      } catch (error) {
        console.log(error)
      }
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.see {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .7);

  .see-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 60%;
    height: 70%;
    padding: 20px;
    background-color: #081C38;
    box-sizing: border-box;
  }

  .see-title {
    width: 100%;
    height: 60px;
    line-height: 60px;
    margin-bottom: 20px;
    border-bottom: 1px solid #fff;
    box-sizing: border-box;
    color: #fff;
    font-size: 20px;
  }

  .see-line {
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;
    color: #fff;
    font-size: 18px;

    .see-line-item {
      width: 30%;
      color: #fff;
      font-size: 18px;
    }
  }

  .see-table {
    width: 100%;
    height: calc(100% - 330px);

    ::v-deep .el-table__body-wrapper {
      &::-webkit-scrollbar {
        width: 0px;
      }
    }

    ::v-deep .el-table th.gutter {
      display: none;
      width: 0;
    }

    ::v-deep .el-table colgroup col[name='gutter'] {
      display: none;
      width: 0;
    }

    ::v-deep .el-table__body {
      width: 100% !important;
    }

    .el-table {
      background-color: #092B50;
      border: 4px #073F73 solid;
    }

    // 表头文字颜色
    /deep/.el-table__header-wrapper {
      thead {
        th {
          div {
            color: #fff;
            font-size: 17px;
            font-weight: 400;
          }
        }
      }
    }

    //表格内容颜色
    /deep/.el-table__body tbody tr {
      color: #fff;
      background-color: #092B50;
    }

    /deep/.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
      background-color: #092B50;
    }

    /deep/.el-table tbody tr:hover>td {
      background-color: transparent !important
    }

    // 改变边框颜色
    .el-table--border,
    .el-table--group {
      border: 2px solid #ddd !important;
    }

    /**
  改变表格内竖线颜色
   */
    .el-table--border td,
    .el-table--border th,
    .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
      border-right: 1px solid #ddd !important;
    }

    /deep/.el-table td {
      border-bottom: 1px solid #01EEFD;
    }

    /deep/.el-table thead tr th {
      border-color: transparent;
    }

    /deep/ .el-table--border::after,
    /deep/ .el-table--group::after,
    /deep/ .el-table::before {
      background-color: #073F73;
    }

    /deep/.el-table--border {
      border: 1px solid #073F73 !important;
    }

    /deep/.el-table .cell {
      text-align: center;
      color: #fff;
    }

    /deep/.el-checkbox__inner {
      background-color: transparent;
    }
  }

  .see-button {
    display: flex;
    align-items: center;
    width: 100%;
    height: 60px;
  }
}
</style>
