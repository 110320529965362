<template>
  <div class="update">
    <div class="update-top">
      <span class="update-text">薪资明细</span>
      <el-input placeholder="姓名/身份证号" suffix-icon="el-icon-search" class="update-input"></el-input>
    </div>
    <div class="update-scroll">
      <div class="info">
        <div class="info-box">
          <span class="info-line">护工姓名：张国峰</span>
          <span class="info-line">联系方式：17768927182</span>
          <span class="info-line">身份证号：212871288881212</span>
          <span class="info-line">统计月份：2020-08</span>
        </div>
      </div>
      <el-table :data="tableData" border height="50%">
        <el-table-column label="序号" type="index" width="70">
        </el-table-column>
        <el-table-column prop="name" label="日期">
        </el-table-column>
        <el-table-column prop="address" label="获得积分"></el-table-column>
        <el-table-column prop="address" label="获得薪资"></el-table-column>
      </el-table>
    </div>
    <div class="update-btns">
      <el-button size="small" @click="close">返回</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tableData: [{
        date: '2016-05-02',
        name: '王小虎',
        address: '等待外出'
      }, {
        date: '2016-05-04',
        name: '王小虎',
        address: '未返回'
      }, {
        date: '2016-05-01',
        name: '王小虎',
        address: '逾期未返回'
      }, {
        date: '2016-05-01',
        name: '王小虎',
        address: '逾期未返回'
      }, {
        date: '2016-05-01',
        name: '王小虎',
        address: '逾期未返回'
      }, {
        date: '2016-05-01',
        name: '王小虎',
        address: '逾期未返回'
      }]
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.update {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0 40px;
  background-color: #fff;
  box-sizing: border-box;

  .update-top {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 60px;
    padding: 0 30px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    .update-text {
      color: #000;
      font-size: 22px;
    }

    .update-input {
      width: 246px;
    }
  }

  .update-scroll {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 120px);

    &::-webkit-scrollbar {
      width: 0;
    }

    .info {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      height: 194px;
      padding: 0 30px;
      border-bottom: 1px solid #000;
      box-sizing: border-box;

      .info-box {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        width: 50%;
        height: 194px;

        .info-line {
          color: #000;
          font-size: 16px;
        }
      }

      .info-btns {
        display: flex;
        flex-direction: row;
        margin-top: 20px;
      }
    }

    .el-table {
      width: 700px;
      margin-top: 10px;
    }

    .input {
      width: 180px;
    }

    /deep/.el-table .cell {
      text-align: center;
      color: #000;
    }
  }

  .update-btns {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 50px;
    padding-left: 30px;
    box-sizing: border-box;
  }
}
</style>
