<template>
  <div class="add">
    <div class="add-title">
      <span class="title">新增老人</span>
      <el-input placeholder="姓名/身份证号" suffix-icon="el-icon-search" class="input"></el-input>
    </div>
    <div class="add-tab">
      <span class="add-item" :class="{ 'add-active': tabIndex === index }" v-for="(item, index) in tabs" :key="index"
        @click="tabClick(index)">{{ item }}</span>
    </div>
    <div class="add-scroll">
      <div class="add-box" v-show="tabIndex === 0">
        <div class="add-box-title">
          <span>基本信息</span>
        </div>
        <div class="add-line">
          <div class="add-left">
            <div class="add-line-item">
              <span class="add-key">老人姓名：</span>
              <el-input placeholder="请输入"></el-input>
            </div>
            <div class="add-line-item">
              <span class="add-key">出生年月：</span>
              <el-select v-model="value" placeholder="请选择">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div class="add-line-item">
              <span class="add-key">籍贯：</span>
              <el-select v-model="value" placeholder="请选择">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div class="add-line-item">
              <span class="add-key">汉族：</span>
              <el-select v-model="value" placeholder="请选择">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div class="add-line-item">
              <span class="add-key">身份证号：</span>
              <el-input placeholder="请输入"></el-input>
            </div>
            <div class="add-line-item">
              <span class="add-key">政治面貌：</span>
              <el-input placeholder="请输入"></el-input>
            </div>
            <div class="add-line-item">
              <span class="add-key">社保卡号：</span>
              <el-input placeholder="请输入"></el-input>
            </div>
            <div class="add-line-item">
              <span class="add-key">最高学历：</span>
              <el-select v-model="value" placeholder="请选择">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
          </div>
          <el-upload class="add-right" action="https://jsonplaceholder.typicode.com/posts/" :show-file-list="false"
            :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
            <img v-if="imageUrl" :src="imageUrl" class="avatar">
            <div class="add-icon" v-else>
              <i class="el-icon-plus avatar-uploader-icon"></i>
              <span class="add-text">上传头像</span>
            </div>
          </el-upload>
        </div>
        <div class="add-line">
          <div class="add-line-item">
            <span class="add-key">身份证号：</span>
            <el-radio-group v-model="radio">
              <el-radio label="1">是</el-radio>
              <el-radio label="2">否</el-radio>
            </el-radio-group>
          </div>
          <div class="add-line-item">
            <span class="add-key">婚姻状况：</span>
            <el-radio-group v-model="radio">
              <el-radio label="1">已婚</el-radio>
              <el-radio label="2">离婚</el-radio>
              <el-radio label="3">丧偶</el-radio>
            </el-radio-group>
          </div>
          <div class="add-line-item add-line-item1">
            <span class="add-key">受教育程度：</span>
            <el-radio-group v-model="radio">
              <el-radio label="1">文盲</el-radio>
              <el-radio label="2">略识文字</el-radio>
              <el-radio label="3">能读写</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="add-line">
          <div class="add-line-item add-line-item1">
            <span class="add-key">户口所在地：</span>
            <el-input class="input" placeholder="请输入"></el-input>
          </div>
          <div class="add-line-item add-line-item1">
            <span class="add-key">现居住地址：</span>
            <el-input class="input" placeholder="请输入"></el-input>
          </div>
        </div>
      </div>
      <div class="add-box" v-show="tabIndex === 1">
        <div class="add-box-title">
          <span>疾病介绍</span>
        </div>
        <div class="add-box-title1">
          <span>现病史</span>
        </div>
        <el-checkbox-group v-model="checkList">
          <el-checkbox v-for="(item, index) in lists1" :key="index" :label="index + 1 + ''">{{ item }}</el-checkbox>
        </el-checkbox-group>
        <div class="add-box-title1">
          <span>既往病史</span>
        </div>
        <el-checkbox-group v-model="checkList">
          <el-checkbox v-for="(item, index) in lists1" :key="index" :label="index + 1 + ''">{{ item }}</el-checkbox>
        </el-checkbox-group>
        <div class="add-box-title1">
          <span>既往病史</span>
        </div>
        <el-input type="textarea" :rows="2" placeholder="">
        </el-input>
        <div class="add-box-title1">
          <span>老人自理情况</span>
        </div>
        <el-table :data="tableData" border height="50%">
          <el-table-column label="序号" type="index" width="50">
          </el-table-column>
          <el-table-column prop="name" label="维度">
          </el-table-column>
          <el-table-column label="操作" width="320">
            <!-- slot-scope="scope" -->
            <template slot-scope="scope">
              <el-radio-group v-model="scope.row.radio" @input="change">
                <el-radio label="1">正常</el-radio>
                <el-radio label="2">轻度依赖</el-radio>
                <el-radio label="3">重度依赖</el-radio>
              </el-radio-group>
            </template>
          </el-table-column>
        </el-table>
        <div class="add-box-title1">
          <span>其他特殊情况说明</span>
        </div>
        <el-input type="textarea" :rows="2" placeholder="">
        </el-input>
        <div class="add-box-title1">
          <span>老人自理情况</span>
        </div>
        <el-table border :data="tableData1">
          <el-table-column prop="name" label="体检项目">
          </el-table-column>
          <el-table-column prop="name" label="资料">
          </el-table-column>
          <el-table-column label="操作">
            <!-- slot-scope="scope" -->
            <template>
              <el-button type="primary" size="mini">上传资料</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="add-box" v-show="tabIndex === 2">
        <div class="add-icons">
          <span class="el-icon-plus"></span>
        </div>
        <el-table :data="tableData" height="300">
          <el-table-column label="序号" type="index" width="50">
          </el-table-column>
          <el-table-column prop="name" label="姓名">
          </el-table-column>
          <el-table-column prop="name" label="性别">
          </el-table-column>
          <el-table-column prop="name" label="身份证号">
          </el-table-column>
          <el-table-column prop="name" label="手机号码">
          </el-table-column>
          <el-table-column prop="name" label="联系地址">
          </el-table-column>
          <el-table-column prop="name" label="与老人关系">
          </el-table-column>
          <el-table-column label="操作" width="120">
            <!-- slot-scope="scope" -->
            <template>
              <div class="run">
                <span>编辑</span>
                <span>删除</span>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="add-btns">
      <el-button size="small" @click="close">返回</el-button>
      <el-button size="small" type="primary">保存</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tabIndex: 0,
      tabs: Object.freeze(['基本信息', '健康信息', '家属信息']),
      checkList: [],
      lists1: Object.freeze(['高血压', '脑血管意外', '心肌梗死', '帕金森氏症', '抑郁倾向', '老年性痴呆', '癫痫', '肺气肿', '慢性消化系统疾病', '泌尿系统', '糖尿病', '皮肤病', '肿瘤', '肢体骨折', '骨质疏松', '白内障', '青光眼', '弱视', '失视', '听力受损（中）', '听力受损（重）', '失聪']),
      tableData: [{
        date: '2016-05-02',
        name: '进食',
        address: '等待外出',
        radio: ''
      }, {
        date: '2016-05-04',
        name: '洗澡',
        address: '未返回',
        radio: ''
      }, {
        date: '2016-05-01',
        name: '修饰',
        address: '逾期未返回',
        radio: ''
      }, {
        date: '2016-05-01',
        name: '穿衣',
        address: '逾期未返回',
        radio: ''
      }, {
        date: '2016-05-01',
        name: '如厕、排泄',
        address: '逾期未返回',
        radio: ''
      }, {
        date: '2016-05-01',
        name: '移动',
        address: '逾期未返回',
        radio: ''
      }, {
        date: '2016-05-01',
        name: '认知能力',
        address: '逾期未返回',
        radio: ''
      }, {
        date: '2016-05-01',
        name: '情绪能力',
        address: '逾期未返回',
        radio: ''
      }, {
        date: '2016-05-01',
        name: '视觉能力',
        address: '逾期未返回',
        radio: ''
      }, {
        date: '2016-05-01',
        name: '听力',
        address: '逾期未返回',
        radio: ''
      }],
      tableData1: [{
        date: '2016-05-02',
        name: '肝功能+HbsAg',
        address: '等待外出',
        radio: ''
      }, {
        date: '2016-05-04',
        name: '血脂全套',
        address: '未返回',
        radio: ''
      }, {
        date: '2016-05-01',
        name: '血、尿、粪常规',
        address: '逾期未返回',
        radio: ''
      }, {
        date: '2016-05-01',
        name: '血糖',
        address: '逾期未返回',
        radio: ''
      }, {
        date: '2016-05-01',
        name: '如厕、排泄',
        address: '逾期未返回',
        radio: ''
      }, {
        date: '2016-05-01',
        name: '心电图',
        address: '逾期未返回',
        radio: ''
      }, {
        date: '2016-05-01',
        name: '胸透',
        address: '逾期未返回',
        radio: ''
      }, {
        date: '2016-05-01',
        name: '脑CT',
        address: '逾期未返回',
        radio: ''
      }, {
        date: '2016-05-01',
        name: 'B超',
        address: '逾期未返回',
        radio: ''
      }, {
        date: '2016-05-01',
        name: '体检表',
        address: '逾期未返回',
        radio: ''
      }],
      input: '',
      options: [{
        value: '',
        label: ''
      }],
      value: '',
      imageUrl: '',
      radio: '2'
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    },
    change (e) {
      console.log(e)
      this.radio = e + ''
    },
    handleAvatarSuccess (res, file) {
      this.imageUrl = URL.createObjectURL(file.raw)
    },
    beforeAvatarUpload (file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="less">
.add {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 88;
  padding: 0 40px;
  background-color: #fff;
  box-sizing: border-box;

  .add-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    border-bottom: 1px solid #000;

    .title {
      height: 38px;
      line-height: 38px;
      padding: 0 16px;
      background-color: #409EFF;
      color: #fff;
      font-size: 22px;
    }

    .input {
      width: 246px;
    }
  }

  .add-tab {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 60px;
    padding: 0 12px;
    box-sizing: border-box;

    .add-item {
      margin-right: 56px;
      border-bottom: 1px solid transparent;
      box-sizing: border-box;
      color: #000;
      font-size: 22px;
      cursor: pointer;
    }

    .add-active {
      border-bottom: 1px solid #409EFF;
      color: #409EFF;
    }
  }

  .add-scroll {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 190px);

    &::-webkit-scrollbar {
      width: 0;
    }

    .add-box {
      display: flex;
      flex-direction: column;
      padding: 0 12px;
      box-sizing: border-box;

      .add-box-title {
        height: 30px;
        line-height: 30px;
        color: #000;
        font-size: 18px;
      }

      .add-box-title1 {
        height: 30px;
        line-height: 30px;
        color: #000;
        font-size: 16px;
      }

      .add-line {
        display: flex;
        flex-direction: row;

        .add-left {
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          width: calc(100% - 200px);
        }

        .add-line-item {
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 25%;
          margin-bottom: 10px;
          color: #000;
          font-size: 18px;

          .add-key {
            white-space: nowrap;
          }

          .el-input {
            width: 180px;
          }

          .input {
            width: 500px;
          }
        }

        .add-line-item1 {
          width: 50%;
        }

        .add-right {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 180px;
          height: 106px;
          margin-left: 20px;
          background-color: #FAFAFA;

          .add-icon {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          }

          .avatar-uploader-icon {
            color: #B5B5B5;
            font-size: 20px;
          }

          .add-text {
            margin-top: 15px;
            color: #B5B5B5;
            font-size: 16px;
          }
        }
      }

      .el-checkbox {
        margin-bottom: 10px;
      }

      /deep/.el-textarea__inner {
        width: 770px;
        height: 60px;
        resize: none;
      }

      .el-table {
        margin-top: 10px;
      }

      /deep/.el-table .cell {
        text-align: center;
        color: #000;
      }

      .el-radio-group {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      .add-icons {
        width: 30px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        border-radius: 50%;
        background-color: #04B4D0;
        color: #fff;
        font-size: 20px;
      }
    }
  }

  .add-btns {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 50px;
  }
}
</style>
