<template>
  <div class="run">
    <div class="tab">
      <span class="title">预约登记</span>
    </div>
    <div class="run-search">
      <div class="run-item">
        <div class="key">
          <span>老人姓名</span>
        </div>
        <el-input placeholder="请输入" v-model="params.name"></el-input>
      </div>
      <div class="run-item">
        <div class="key">
          <span>身份证号</span>
        </div>
        <el-input placeholder="请输入" v-model="params.sfzhm"></el-input>
      </div>
      <div class="run-item">
        <div class="key">
          <span>预定床位</span>
        </div>
        <el-select v-model="value" placeholder="请选择">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="run-item">
        <div class="key">
          <span>预定状态</span>
        </div>
        <el-select v-model="value" placeholder="请选择">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <el-button type="primary" @click="getList">搜索</el-button>
      <el-button type="primary">重置</el-button>
    </div>

    <div class="son-add">
      <div class="son-add-icon" @click="openAdd">
        <span class="el-icon-plus"></span>
      </div>
    </div>

    <div class="son-table">
      <el-table :data="tableData" border height="100%" ref="table">
      <el-table-column label="序号" type="index" width="100">
      </el-table-column>
      <el-table-column prop="ydzt" label="预定状态">
        <template slot-scope="scope">
          <div>
            <span>{{ scope.row.address | type }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="老人姓名">
      </el-table-column>
      <el-table-column label="性别">
        <template slot-scope="scope">
          <div>
            <span>{{ scope.row.xb === '1' ? '男' : '女' }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="sfzhm" label="身份证号">
      </el-table-column>
      <el-table-column prop="ydrname" label="预定人姓名">
      </el-table-column>
      <el-table-column prop="gddh" label="联系电话">
      </el-table-column>
      <el-table-column prop="ylrgx" label="与老人关系">
      </el-table-column>
      <el-table-column label="操作" width="360">
        <template slot-scope="scope">
          <div class="flex" v-if="scope.row.address === '等待外出'">
            <!-- <span @click="openSelect">编辑</span>
            <span>提交预定</span>
            <span @click="openDelete">删除</span>
            <span @click="openSee">详情</span> -->

            <el-button type="primary" size="mini" @click="openSelect">编辑</el-button>
            <el-button type="primary" size="mini">提交预定</el-button>
            <el-button type="danger" size="mini" @click="openDelete">删除</el-button>
            <el-button type="primary" size="mini" @click="openSee">详情</el-button>
          </div>
          <div class="flex" v-else>
            <!-- <span @click="openAdd">转入院</span>
            <span @click="openCancel">取消预定</span>
            <span @click="openSee">详情</span> -->

            <el-button type="primary" size="mini" @click="openAdd">转入院</el-button>
            <el-button type="primary" size="mini" @click="openCancel">取消预定</el-button>
            <el-button type="primary" size="mini" @click="openSee">详情</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    </div>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="query1.pageNum" background :page-size="query1.pageSize"
            layout="total, prev, pager, next, jumper" :total="query1.total"></el-pagination>
    <See v-if="showSee" @close="closeSee"></See>
    <Run v-if="showRun" @close="closeRun"></Run>
    <Delete v-if="showDelete" @close="closeDelete"></Delete>
    <Select v-if="showSelect" @close="closeSelect"></Select>
    <Add v-if="showAdd" @close="closeAdd"></Add>
    <Cancel v-if="showCancel" @close="closeCancel"></Cancel>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Add from './add.vue'
import See from './see.vue'
import Run from './run.vue'
import Delete from './delete.vue'
import Select from './select.vue'
import Cancel from './cancel.vue'
export default {
  components: {
    Add,
    See,
    Run,
    Delete,
    Select,
    Cancel
  },
  data () {
    return {
      tableData: [],
      options: [{
        value: '',
        label: ''
      }],
      value: '',
      currentPage4: 4,
      tabIndex: 0,
      selectIndex: 0,
      showAdd: false,
      showDelete: false,
      showSee: false,
      showRun: false,
      showSelect: false,
      showCancel: false,
      // 分页数据
      query1: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      params: {
        name: '',
        sfzhm: '',
        // 分页起始
        start: '1',
        // 分页长度
        length: '10',
        ssjgbh: '',
        xzjdmcid: '',
        cwhmcid: '',
        flag: '',
        isqy: '',
        issglb: '',
        gxyjb: '',
        gxydj: '',
        tnbjb: '',
        gxtdj: ''
      }
    }
  },
  computed: {
    ...mapState(['loginInfo'])
  },
  created () {
    this.params.ssjgbh = this.loginInfo.jgbh
    this.params.xzjdmcid = this.loginInfo.xzjdmcid
    if (this.loginInfo.jgjb === '4') {
      this.params.cwhmcid = ''
    } else if (this.loginInfo.jgjb === '5') {
      this.params.cwhmcid = this.loginInfo.tjjgcwhmcid
    }
    this.getList()
  },
  filters: {
    type (val) {
      const arr = ['取消', '完成', '待提交', '待下单', '待评估']
      if (val !== null || val !== '') return arr[val]
      return ''
    }
  },
  methods: {
    async getList () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-92344223
      try {
        const start = this.query1.pageNum === 1 ? '0' : (this.query1.pageNum - 1) * 10 + 1 + ''
        const obj = {
          start: start,
          length: this.query1.pageSize + '',
          ssjgbh: this.loginInfo.jgbh,
          jgbh: this.loginInfo.jgbh,
          sxzt: '4',
          name: this.params.name || '',
          sfzhm: this.params.sfzhm || ''
        }
        const { data: res } = await this.$http.get('/ykdjmjkdnjls', { params: obj })
        console.log('预约登记列表', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.tableData = res.data
        this.query1.total = res.recordsTotal
        this.$nextTick(() => {
          this.$refs.table.bodyWrapper.scrollTop = 0
        })
      } catch (error) {
        console.log('预约登记列表请求失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    tabClick (index) {
      this.tabIndex = index
    },
    openAdd () {
      this.showAdd = true
    },
    closeAdd () {
      this.showAdd = false
    },
    openDelete () {
      this.showDelete = true
    },
    closeDelete () {
      this.showDelete = false
    },
    openSee () {
      this.showSee = true
    },
    closeSee () {
      this.showSee = false
    },
    openRun () {
      this.showRun = true
    },
    closeRun () {
      this.showRun = false
    },
    openSelect () {
      this.showSelect = true
    },
    closeSelect () {
      this.showSelect = false
    },
    openCancel () {
      this.showCancel = true
    },
    closeCancel () {
      this.showCancel = false
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
      this.query1.pageNum = val
      this.getList()
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.run {
  width: 100%;
  height: 100%;
  padding: 0 10px;
  background-color: #fff;
  box-sizing: border-box;

  .tab {
    display: flex;
    align-items: center;
    width: 100%;
    height: 60px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    span {
      height: 38px;
      line-height: 38px;
      margin-right: 16px;
      padding: 0 12px;
      text-align: center;
      color: #fff;
      font-size: 22px;
      background-color: #D5D5D5;
      cursor: pointer;
    }

    .title {
      background-color: #409EFF;
    }
  }

  .son-tab {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    .son-item {
      height: 38px;
      line-height: 38px;
      padding: 0 20px;
      text-align: center;
      background-color: #284867;
      color: #fff;
      font-size: 16px;
      cursor: pointer;
    }

    .son-active {
      background-color: #04B4D0;
    }

    .son-item:first-child {
      margin-right: 40px;
    }
  }

  .son-tab1 {
    border: none;
  }

  .son-add {
    display: flex;
    align-items: center;
    height: 50px;

    .son-add-icon {
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      background-color: #409EFF;
      border-radius: 50%;
      color: #fff;
      font-size: 16px;
      cursor: pointer;
    }

    .son-set {
      width: 120px;
      height: 30px;
      line-height: 30px;
      margin-left: 30px;
      text-align: center;
      background-color: #04B4D0;
      color: #fff;
      font-size: 16px;
      cursor: pointer;
    }
  }

  .run-search {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    .run-item {
      display: flex;
      align-items: center;
      margin-right: 40px;

      .key {
        margin-right: 10px;
        white-space: nowrap;
        color: #000;
        font-size: 22px;
      }
    }
  }

  .son-table {
    width: 100%;
    height: calc(100% - 280px);

    /deep/.el-table .cell {
      text-align: center;
      color: #000;
    }

    .flex {
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      span {
        cursor: pointer;
      }
  }
  }

  .el-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
}
</style>
