var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"update"},[_c('div',{staticClass:"update-box"},[_vm._m(0),_c('div',{staticClass:"update-box-top"},[_c('div',{staticClass:"update-item"},[_vm._m(1),_c('el-input',{attrs:{"placeholder":""}})],1),_c('div',{staticClass:"update-item"},[_vm._m(2),_c('el-input',{attrs:{"placeholder":""}})],1),_c('div',{staticClass:"update-item"},[_vm._m(3),_c('el-select',{attrs:{"placeholder":""},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},_vm._l((_vm.options),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1),_c('div',{staticClass:"update-item"},[_vm._m(4),_c('el-select',{attrs:{"placeholder":""},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},_vm._l((_vm.options),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1),_c('div',{staticClass:"update-item"},[_vm._m(5),_c('el-input',{attrs:{"type":"textarea","placeholder":""}})],1)]),_c('div',{staticClass:"update-box-bottom"},[_c('el-button',{on:{"click":_vm.close}},[_vm._v("返回")]),_c('el-button',{attrs:{"type":"warning"}},[_vm._v("重置")]),_c('el-button',{attrs:{"type":"primary"}},[_vm._v("保存")])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"update-box-head"},[_c('span',[_vm._v("编辑：核查(exxxxxxxxxxxxxxxxxxxx)")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"update-key"},[_c('span',[_vm._v("*名称：")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"update-key"},[_c('span',[_vm._v("*编码：")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"update-key"},[_c('span',[_vm._v("*所属机构：")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"update-key"},[_c('span',[_vm._v("*状态：")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"update-key"},[_c('span',[_vm._v("备注：")])])
}]

export { render, staticRenderFns }