<template>
  <div class="select">
    <div class="popup-content">
      <div class="popup-title">
        <span>选择老人</span>
      </div>
      <div class="popup-box">
        <div class="content">
          <div class="close" @click="close">
            <i class="el-icon-close"></i>
          </div>
          <div class="box">
            <div class="search">
              <span>老人姓名</span>
              <el-input placeholder="请输入" v-model="params.name">
              </el-input>
              <span>身份证号</span>
              <el-input placeholder="请输入" v-model="params.sfzh">
              </el-input>
              <el-button @click="getList">搜索</el-button>
              <el-button @click="reset">重置</el-button>
              <el-button @click="openAdd">新增</el-button>
            </div>
            <el-table :data="tableData" height="80%" :header-cell-style="{ background: '#024276' }" ref="table" @selection-change="seleOne">
              <el-table-column type="selection" width="50">
              </el-table-column>
              <el-table-column prop="name" label="姓名">
              </el-table-column>
              <el-table-column prop="sfzh" label="身份证">
              </el-table-column>
              <el-table-column prop="xb" label="性别">
                <template slot-scope="scope">
                  <span>{{ scope.row.xb === '1' ? '男' : '女' }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="age" label="年龄">
              </el-table-column>
              <el-table-column label="操作">
                <template slot-scope="scope">
                <el-button @click="openAddReservation(scope.row)">选择</el-button>
                </template>
                <!-- <el-button class="red">取消选择</el-button> -->
              </el-table-column>
            </el-table>
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="query1.pageNum" background :page-size="query1.pageSize"
              layout="total, prev, pager, next, jumper" :total="query1.total"></el-pagination>
          </div>
          <div class="btn">
            <el-button size="small" @click="close">取消</el-button>
            <el-button size="small" @click="close">确定</el-button>
          </div>
        </div>
      </div>
    </div>
    <Add v-if="showAdd" @close="closeAdd"></Add>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Add from './add.vue'
export default {
  components: {
    Add
  },
  data () {
    return {
      tableData: [],
      options: [{
        value: '1',
        label: '1'
      }],
      value: '',
      showAdd: false,
      showDelete: false,
      showAddReservation: false,
      // 分页数据
      query1: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      params: {
        // 分页起始
        start: '0',
        // 分页长度
        length: '10',
        ssjgbh: '',
        xzjdmcid: '',
        cwhmcid: '',
        flag: '',
        isqy: '',
        issglb: '',
        gxyjb: '',
        gxydj: '',
        tnbjb: '',
        gxtdj: '',
        name: '',
        sfzh: ''
      }
    }
  },
  computed: {
    ...mapState(['loginInfo'])
  },
  mounted () {
    // this.params.ssjgbh = this.params.xzjdmcid = this.params.cwhmcid = this.loginInfo.jgbh
    this.params.xzjdmcid = this.loginInfo.xzjdmcid
    if (this.loginInfo.jgjb === '4') {
      this.params.cwhmcid = ''
    } else if (this.loginInfo.jgjb === '5') {
      this.params.cwhmcid = this.loginInfo.tjjgcwhmcid
    }
    this.getList()
  },
  methods: {
    // https://app.apifox.com/project/2726972/apis/api-84415436
    async getList () {
      try {
        const start = this.query1.pageNum === 1 ? '0' : (this.query1.pageNum - 1) * 10 + ''
        const obj = {
          start: start,
          length: this.query1.pageSize + '',
          ssjgbh: this.loginInfo.jgbh,
          jgbh: this.loginInfo.jgbh,
          sxzt: '0',
          name: this.params.name,
          sfzh: this.params.sfzh
        }
        const { data: res } = await this.$http.get('/ykdrydjs', { params: obj })
        console.log('入院登记列表', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.tableData = res.data
        this.query1.total = res.recordsTotal
        this.$nextTick(() => {
          this.$refs.table.bodyWrapper.scrollTop = 0
        })
      } catch (error) {
        console.log('入院登记列表请求失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    reset () {
      this.params.name = this.params.sfzh = ''
    },
    openAddReservation (find) {
      console.log(find)
      this.$emit('copyList', find)
      this.showAddReservation = true
      this.$parent.showSelect = false
    },
    seleOne (e) {
      console.log(e)
    },
    closeAddReservation () {
      this.showAddReservation = false
    },
    openAdd () {
      // this.showAdd = true
      this.close('openAdd')
    },
    closeAdd () {
      this.showAdd = false
    },
    openDelete () {
      this.showDelete = true
    },
    closeDelete () {
      this.showDelete = false
    },
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
      this.query1.pageNum = val
      this.getList()
    },
    close (a) {
      this.$emit('close', a)
    }
  }
}
</script>

<style scoped lang="less">
.select {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: rgba(0, 0, 0, .74);

  .popup-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1048px;
    height: 665px;

    .popup-title {
      width: 160px;
      height: 50px;
      line-height: 60px;
      background-image: url('../../../img/dialogTitleBg.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      text-align: center;
      color: #fff;
      font-size: 20px;
    }

    .popup-box {
      width: 100%;
      height: 615px;
      padding: 10px;
      background-color: rgba(3, 27, 44, .8);
      border-radius: 10px;
      border: 4px solid rgba(1, 49, 88, .5);
      box-sizing: border-box;

      .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        width: 100%;
        height: 100%;
        padding: 30px;
        border-radius: 10px;
        border: 4px solid rgba(1, 49, 88, .5);
        box-sizing: border-box;

        .close {
          display: flex;
          align-items: flex-end;
          position: absolute;
          top: 0;
          right: 0;
          width: 50px;
          height: 50px;
          background: linear-gradient(26.565deg, #2057a7 50%, transparent 0);
          transform: rotate(180deg);
          color: #fff;
          font-size: 24px;
          cursor: pointer;
        }

        .box {
          width: 100%;
          height: calc(100% - 50px);

          .search {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            color: #01EEFD;
            font-size: 18px;

            .el-select {
              margin-left: 10px;
              margin-right: 40px;
            }

            .el-input {
              width: 180px;
              margin-left: 10px;
              margin-right: 30px;
            }

            .el-button {
              margin-left: 30px;
              background-color: #04B4D0;
              color: #fff;
            }
          }

          ::v-deep.el-pagination__total,
          ::v-deep.el-pagination__jump {
            color: #01EEFD;

            .el-input__inner {
              color: #fff;
              background-color: transparent;
            }
          }

          ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
            background-color: #01EEFD;
          }

          ::v-deep .el-table__body-wrapper {
            &::-webkit-scrollbar {
              width: 0;
            }
          }

          .el-pagination {
            display: flex;
            justify-content: center;
            margin-top: 10px;
          }

          ::v-deep .el-table th.gutter {
            display: none;
            width: 0;
          }

          ::v-deep .el-table colgroup col[name='gutter'] {
            display: none;
            width: 0;
          }

          ::v-deep .el-table__body {
            width: 100% !important;
          }

          .el-table {
            margin-top: .25rem;
            background-color: #092B50;
            border: .05rem #073F73 solid;

            .el-button {
              width: 90px;
              text-align: center;
              padding-left: 0;
              padding-right: 0;
              background-color: #0192B5;
              color: #fff;
            }

            .red {
              background-color: #FF0000;
            }
          }

          // 表头文字颜色
          /deep/.el-table__header-wrapper {
            thead {
              th {
                div {
                  color: #01EEFD;
                  font-size: .2125rem;
                  font-weight: 400;
                }
              }
            }
          }

          //表格内容颜色
          /deep/.el-table__body tbody tr {
            color: #fff;
            background-color: #092B50;
          }

          /deep/.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
            background-color: #092B50;
          }

          /deep/.el-checkbox__input.is-checked .el-checkbox__inner {
            background-color: #01EEFD;
            border-color: #01EEFD;
          }

          /deep/.el-table tbody tr:hover>td {
            background-color: transparent !important
          }

          // 改变边框颜色
          .el-table--border,
          .el-table--group {
            border: .025rem solid #ddd !important;
          }

          /**
  改变表格内竖线颜色
   */
          .el-table--border td,
          .el-table--border th,
          .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
            border-right: .0125rem solid #ddd !important;
          }

          /deep/.el-table td {
            border-bottom: .0125rem solid #01EEFD;
          }

          /deep/.el-table thead tr th {
            border-color: transparent;
          }

          /deep/ .el-table--border::after,
          /deep/ .el-table--group::after,
          /deep/ .el-table::before {
            background-color: #073F73;
          }

          /deep/.el-table--border {
            border: .0125rem solid #073F73 !important;
          }

          /deep/.el-table .cell {
            text-align: center;
            color: #01EEFD;
          }

          /deep/.el-checkbox__inner {
            background-color: transparent;
          }
        }

        .btn {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 240px;
          margin: 0 auto;
        }

        .el-button {
          border-radius: 0;
          border: none;
          background-color: #33465C;
          color: #fff;
        }

        .el-button:nth-child(2) {
          background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
        }
      }
    }
  }
}
</style>
