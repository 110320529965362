<!--
 * @Author: 小宝 zksukuo@yunkangda.com
 * @Date: 2023-12-07 11:35:16
 * @LastEditors: 小宝 zksukuo@yunkangda.com
 * @LastEditTime: 2024-03-18 10:46:54
 * @FilePath: \visions\src\views\zhyl\smartPhysicalExamination\statisticAnalysis\medicalReport\medicalReport.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="son">
    <div class="son-title">
      <span>学生健康体检导引单</span>
    </div>
    <div class="son-tmcode">
      <img id="tmcode" />
    </div>
    <table class="son-table son-table1" border cellpadding="0" cellspacing="0">
      <tr>
        <td class="son-td son-center son-td1">学校</td>
        <td class="son-td son-td2">某地实验小学</td>
        <td class="son-td son-center son-td1">年级班级</td>
        <td class="son-td">小学一年级/1班</td>
      </tr>
      <tr>
        <td class="son-td son-center">姓名</td>
        <td class="son-td son-td2">张三</td>
        <td class="son-td son-center">性别</td>
        <td class="son-td">男</td>
      </tr>
      <tr>
        <td class="son-td son-center">身份证号</td>
        <td class="son-td son-td2">123456789123456789</td>
        <td class="son-td son-center">出生日期</td>
        <td class="son-td">2024-03-18</td>
      </tr>
      <tr>
        <td class="son-td son-center">监护人</td>
        <td class="son-td son-td2"></td>
        <td class="son-td son-center">联系电话</td>
        <td class="son-td"></td>
      </tr>
    </table>

    <table class="son-table" border cellpadding="0" cellspacing="0">
      <tr class="tr">
        <td class="son-td son-td1 son-center" colspan="1">序号</td>
        <td class="son-td son-td1 son-center" colspan="1">检查项目</td>
        <td class="son-td son-td1 son-center" colspan="1">医生签名</td>
        <td class="son-td son-center" colspan="3">备注</td>
      </tr>

    </table>
    <el-checkbox-group v-model="checkList" class="son-scroll">
      <table class="son-table" border cellpadding="0" cellspacing="0">
        <tr class="tr">
          <td class="son-td son-td1 son-center" colspan="1">
            <el-checkbox label="1"></el-checkbox>
          </td>
          <td class="son-td son-td1 son-center" colspan="1">问诊</td>
          <td class="son-td son-td1 son-center" colspan="1"></td>
          <td class="son-td son-center" colspan="3"></td>
        </tr>
        <tr class="tr">
          <td class="son-td son-td1 son-center" colspan="1">
            <el-checkbox label="2"></el-checkbox>
          </td>
          <td class="son-td son-td1 son-center" colspan="1">形态</td>
          <td class="son-td son-td1 son-center" colspan="1"></td>
          <td class="son-td son-center" colspan="3"></td>
        </tr>
        <tr class="tr">
          <td class="son-td son-td1 son-center" colspan="1">
            <el-checkbox label="3"></el-checkbox>
          </td>
          <td class="son-td son-td1 son-center" colspan="1">内科</td>
          <td class="son-td son-td1 son-center" colspan="1"></td>
          <td class="son-td son-center" colspan="3"></td>
        </tr>
        <tr class="tr">
          <td class="son-td son-td1 son-center" colspan="1">
            <el-checkbox label="4"></el-checkbox>
          </td>
          <td class="son-td son-td1 son-center" colspan="1">血压</td>
          <td class="son-td son-td1 son-center" colspan="1"></td>
          <td class="son-td son-center" colspan="3"></td>
        </tr>
        <tr class="tr">
          <td class="son-td son-td1 son-center" colspan="1">
            <el-checkbox label="5"></el-checkbox>
          </td>
          <td class="son-td son-td1 son-center" colspan="1">肺活量</td>
          <td class="son-td son-td1 son-center" colspan="1"></td>
          <td class="son-td son-center" colspan="3"></td>
        </tr>
        <tr class="tr">
          <td class="son-td son-td1 son-center" colspan="1">
            <el-checkbox label="6"></el-checkbox>
          </td>
          <td class="son-td son-td1 son-center" colspan="1">外壳</td>
          <td class="son-td son-td1 son-center" colspan="1"></td>
          <td class="son-td son-center" colspan="3"></td>
        </tr>
        <tr class="tr">
          <td class="son-td son-td1 son-center" colspan="1">
            <el-checkbox label="7"></el-checkbox>
          </td>
          <td class="son-td son-td1 son-center" colspan="1">耳鼻喉</td>
          <td class="son-td son-td1 son-center" colspan="1"></td>
          <td class="son-td son-center" colspan="3"></td>
        </tr>
        <tr class="tr">
          <td class="son-td son-td1 son-center" colspan="1">
            <el-checkbox label="8"></el-checkbox>
          </td>
          <td class="son-td son-td1 son-center" colspan="1">眼科</td>
          <td class="son-td son-td1 son-center" colspan="1"></td>
          <td class="son-td son-center" colspan="3"></td>
        </tr>
        <tr class="tr">
          <td class="son-td son-td1 son-center" colspan="1">
            <el-checkbox label="9"></el-checkbox>
          </td>
          <td class="son-td son-td1 son-center" colspan="1">视力</td>
          <td class="son-td son-td1 son-center" colspan="1"></td>
          <td class="son-td son-center" colspan="3"></td>
        </tr>
        <tr class="tr">
          <td class="son-td son-td1 son-center" colspan="1">
            <el-checkbox label="10"></el-checkbox>
          </td>
          <td class="son-td son-td1 son-center" colspan="1">屈光</td>
          <td class="son-td son-td1 son-center" colspan="1"></td>
          <td class="son-td son-center" colspan="3"></td>
        </tr>
        <tr class="tr">
          <td class="son-td son-td1 son-center" colspan="1">
            <el-checkbox label="11"></el-checkbox>
          </td>
          <td class="son-td son-td1 son-center" colspan="1">口腔</td>
          <td class="son-td son-td1 son-center" colspan="1"></td>
          <td class="son-td son-center" colspan="3"></td>
        </tr>
        <tr class="tr">
          <td class="son-td son-td1 son-center" colspan="1">
            <el-checkbox label="12"></el-checkbox>
          </td>
          <td class="son-td son-td1 son-center" colspan="1">实验室</td>
          <td class="son-td son-td1 son-center" colspan="1"></td>
          <td class="son-td son-center" colspan="3"></td>
        </tr>
      </table>
    </el-checkbox-group>
    <div class="son-tmcode son-tmcode1">
      <img id="tmcode" />
    </div>
  </div>
</template>

<script>
import JsBarcode from 'jsbarcode'
export default {
  data () {
    return {
      checkList: []
    }
  },
  mounted () {
    JsBarcode('#tmcode', '259280570373', {
      format: 'CODE128',
      width: 2,
      height: 50,
      lineColor: '#000',
      displayValue: true,
      margin: 2
    })
  }
}
</script>

<style lang="less" scoped>
.son {
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding: 20px;
  box-sizing: border-box;

  &::-webkit-scrollbar {
    width: 0;
  }

  .son-title {
    width: 100%;
    height: 50px;
    line-height: 50px;
    text-align: center;
    color: #000;
    font-size: 22px;
    font-weight: bold;
  }

  .son-tmcode {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }

  .son-tmcode1 {
    margin-top: 20px;
  }

  .son-table {
    width: 80%;
    margin: 0 auto;

    .son-td {
      padding: 10px;
      box-sizing: border-box;
      color: #000;
      font-size: 16px;
    }

    .son-td1 {
      width: 200px;
    }

    .son-td2 {
      width: 400px;
    }

    .son-center {
      text-align: center;
    }
  }

  .son-table1 {
    margin-bottom: 20px;
  }

  .son-scroll {
    // overflow-y: scroll;
    // width: 80%;
    // height: calc(100% - 400px);
    margin: 0 auto;
    margin-top: -2px;
    z-index: 2;

    &::-webkit-scrollbar {
      width: 0;
    }

    .son-table {
      // width: 100%;
    }

    /deep/.el-checkbox__label {
      font-size: 16px;
    }
  }
}
</style>
