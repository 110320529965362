<template>
  <div class="son" :class="{ son1: !showAdd }">
    <div class="son-head">
      <div class="son-head-item">
        <div class="son-head-left">
          <div class="son-head-open"></div>
          <el-input placeholder="请输入搜索内容"></el-input>
        </div>
        <div class="son-head-center">
          <span>切换机构：</span>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="son-head-right">
          <span class="el-icon-success success"></span>
          <div class="div">
            <div class="div1">
              <div class="blue"></div>
            </div>
          </div>
          <span class="el-icon-lock lock"></span>
          <span class="el-icon-view lock"></span>
          <span class="el-icon-rank rank"></span>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div class="son-content">
      <div class="son-box">
        <div class="son-search">
          <div class="search-item">
            <div class="key">
              <span>学院</span>
            </div>
            <el-select v-model="value" placeholder="全部">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="search-item">
            <div class="key">
              <span>专业</span>
            </div>
            <el-select v-model="value" placeholder="全部">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="search-item">
            <div class="key">
              <span>班级</span>
            </div>
            <el-select v-model="value" placeholder="全部">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="search-item">
            <div class="key">
              <span>年度</span>
            </div>
            <el-select v-model="value" placeholder="姓名或证件号码">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="search-item search-item1">
            <div class="key">
              <span>体检日期预约日期</span>
            </div>
            <el-date-picker v-model="value1" type="daterange" range-separator="至" start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>
            <el-checkbox v-model="checked">校生</el-checkbox>
            <el-checkbox v-model="checked">毕业</el-checkbox>
          </div>
        </div>
        <div class="son-scroll">
          <div class="tip">
            <span> 全不选</span>
          </div>
          <div class="chart" id="echart">

          </div>
          <div class="search">
            <div class="item">
              <span class="span">当前预约（条)</span>
              <el-input placeholder=""></el-input>
            </div>
            <div class="item">
              <span class="span">病史统计（条)</span>
              <el-input placeholder=""></el-input>
            </div>
            <div class="item">
              <span class="span">建议/备注</span>
              <el-input placeholder=""></el-input>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {
      options: [{
        value: '',
        label: ''
      }],
      value: '',
      value1: '',
      checked: false,
      total: 400,
      currentPage: 0,
      showSet: false,
      style: {
        width: '100%',
        height: '186px'
      },
      style1: {
        width: '100%',
        height: '31px',
        'line-height': '31px',
        'text-align': 'center',
        'border-bottom': '1px solid #D9D9D9',
        color: '#333333',
        'font-size': '14px'
      },
      style2: {
        width: '100%',
        height: '31px',
        'line-height': '31px',
        'text-align': 'center',
        color: '#333333',
        'font-size': '14px'
      },
      showAdd: false,
      showSee: false
    }
  },
  created () {
    this.$nextTick(() => {
      this.canvas()
    })
    window.addEventListener('resize', this.getresize)
  },
  methods: {
    getresize () {
      setTimeout(() => {
        this.myChart.resize()
      }, 500)
    },
    canvas () {
      var chartDom = document.getElementById('echart')
      this.myChart = this.$echarts.init(chartDom)
      const option = {
        color: ['#D72A26', '#2B4755', '#44A1A9', '#E57D60', '#7DC8AF', '#649F84', '#D9821C', '#C3A098', '#6D6F73', '#4F6570', '#C1CCD3', '#D72A26'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          // orient: 'horizontal',
          // x: '70%',
          // y: '10%',
          // x: 'right',
          textStyle: {
            fontSize: 12,
            color: '#606266'
          }
          // padding: [25, 34, 0, 0]
        },
        dataZoom: [
          {
            xAxisIndex: 0, // 这里是从X轴的0刻度开始
            disabled: false,
            zoomLock: true,
            preventDefaultMouseMove: false,
            filterMode: 'empty',
            type: 'inside',
            startValue: 0, // 从头开始。
            endValue: 12 // 一次性展示6个。
          }

        ],
        grid: {
          show: false,
          top: '10%',
          right: '4%',
          bottom: '7%',
          left: '4%'
        },
        xAxis: {
          type: 'category',
          data: ['未完成', '已检', '完成', '正常', '异常'],
          axisLabel: {
            show: true,
            color: '#909090',
            fontSize: 13,
            interval: 0
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#909090'
            },
            textStyle: {
              color: '#909090',
              fontSize: 13
            }
          },
          axisTick: {
            // y轴刻度线
            show: false
          }
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: true,
            lineStyle: {
              color: '#909090'
            }
          },
          axisLabel: {
            show: true,
            color: '#909090',
            fontSize: 14
          },
          splitLine: {
            lineStyle: {
              color: '#909090'
            }
          }
        },
        series: [
          {
            name: '内科',
            data: [140, 100, 50, 70, 10],
            type: 'bar',
            barWidth: '14px'
          },
          {
            name: '外科',
            data: [120, 200, 150, 80, 70],
            type: 'bar',
            barWidth: '14px'
          },
          {
            name: '眼科',
            data: [120, 200, 150, 80, 70],
            type: 'bar',
            barWidth: '14px'
          },
          {
            name: '五官',
            data: [120, 200, 150, 80, 70],
            type: 'bar',
            barWidth: '14px'
          },
          {
            name: '脊柱',
            data: [120, 200, 150, 80, 70],
            type: 'bar',
            barWidth: '14px'
          },
          {
            name: 'PPD',
            data: [120, 200, 150, 80, 70],
            type: 'bar',
            barWidth: '14px'
          },
          {
            name: '血压心电',
            data: [120, 200, 150, 80, 70],
            type: 'bar',
            barWidth: '14px'
          },
          {
            name: '色觉',
            data: [120, 200, 150, 80, 70],
            type: 'bar',
            barWidth: '14px'
          },
          {
            name: '肝功能',
            data: [120, 200, 150, 80, 70],
            type: 'bar',
            barWidth: '14px'
          },
          {
            name: '男',
            data: [120, 200, 150, 80, 70],
            type: 'bar',
            barWidth: '14px'
          },
          {
            name: '女',
            data: [120, 200, 150, 80, 70],
            type: 'bar',
            barWidth: '14px'
          },
          {
            name: '总数',
            data: [120, 200, 150, 80, 70],
            type: 'bar',
            barWidth: '14px'
          }
        ]
      }
      option && this.myChart.setOption(option)
    }
  }
}
</script>

<style lang="less" scoped>
.son {
  width: 100%;
  height: 100%;
  background-color: #F0F0F0;

  .son-head {
    display: flex;
    align-items: center;
    width: 100%;
    height: 70px;
    margin-bottom: 10px;
    background-color: #fff;
    box-sizing: border-box;

    .son-head-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 0 14px;
      box-sizing: border-box;

      .son-head-left {
        display: flex;
        align-items: center;
        width: 33%;

        .son-head-open {
          width: 23px;
          height: 6px;
          border-top: 2px solid #595959;
          border-bottom: 2px solid #595959;
        }

        .el-input {
          margin-left: 50px;
        }

        /deep/.el-input__inner {
          border-color: #fff;
          font-size: 18px;
        }
      }

      .son-head-center {
        display: flex;
        align-items: center;
        flex: 1;
        color: #595959;
        font-size: 20px;

        /deep/.el-input__inner {
          font-size: 18px;
        }
      }

      .son-head-right {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex: 1;

        .success {
          color: #67C23A;
          font-size: 39px;
        }

        .lock {
          color: #595959;
          font-size: 30px;
        }

        .rank {
          font-size: 34px;
          transform: rotate(45deg);
        }

        .div {
          width: 41px;
          height: 40px;
          padding: 6px 7px;
          border: 1px solid #E6E6E6;
          border-radius: 6px;
          box-sizing: border-box;

          .div1 {
            width: 100%;
            height: 100%;
            padding-top: 3px;
            border: 1px solid #999999;
            box-sizing: border-box;

            .blue {
              width: 100%;
              height: 100%;
              background-color: #409EFF;
            }
          }
        }

        /deep/.el-input__inner {
          text-align: right;
          border: none;
          font-size: 18px;
        }
      }

      .son-head-title {
        width: 117px;
        height: 38px;
        line-height: 38px;
        text-align: center;
        border: 1px solid #409EFF;
        border-radius: 2px;
        box-sizing: border-box;
        color: #409EFF;
        font-size: 16px;
      }

      .el-button {
        width: 100px;

        .icon {
          margin-left: 10px;
        }
      }
    }
  }

  .son-content {
    width: 100%;
    height: calc(100% - 90px);
    padding: 0 10px;
    box-sizing: border-box;

    .son-box {
      width: 100%;
      height: 100%;
      padding: 0 20px;
      background-color: #fff;
      border-radius: 18px;
      box-sizing: border-box;
    }

    .son-search {
      display: flex;
      align-content: space-evenly;
      flex-wrap: wrap;
      width: 100%;
      height: 160px;
      margin-bottom: 10px;
      border-bottom: 1px solid #BEBEBE;
      box-sizing: border-box;

      .search-item {
        display: flex;
        align-items: center;
        width: calc(25% - 40px);

        .el-select {
          width: 250px;
        }

        .el-input {
          width: 250px;
        }

        /deep/.el-date-editor {
          width: 250px;
        }
      }

      .search-item1 {
        width: 100%;

        .el-checkbox {
          display: flex;
          align-items: center;
          margin-left: 20px;
        }

        /deep/.el-checkbox__inner {
          width: 20px;
          height: 20px;
        }

        /deep/.el-checkbox__inner::after {
          top: 4px;
          left: 6px;
        }

        /deep/.el-checkbox__label {
          color: #17212B;
          font-size: 20px;
        }
      }

      .key {
        margin-right: 20px;
        color: #17212B;
        font-size: 22px;
      }
    }

    .son-scroll {
      overflow-y: scroll;
      width: 100%;
      height: calc(100% - 180px);

      &::-webkit-scrollbar {
        width: 0;
      }

      .tip {
        width: 66px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        background-color: #00B597;
        color: #fff;
        font-size: 16px;
      }

      .chart {
        width: 100%;
        height: calc(100% - 80px);
      }

      .search {
        display: flex;
        justify-content: space-around;
        width: 100%;
        height: 50px;

        .item {
          display: flex;
          align-items: center;
          color: #333333;
          font-size: 16px;

          .span {
            margin-right: 10px;
            white-space: nowrap;
          }
        }
      }
    }
  }
}

.son1 {
  position: relative;
}
</style>
