<template>
  <div class="page-box">
    <div class="tab">
      <span class="item active">委托用药</span>
    </div>
    <el-input placeholder="姓名/身份证号" suffix-icon="el-icon-search" class="input">
    </el-input>
    <div class="tab-box">
      <div class="run">
    <div class="son-tab">
      <span class="son-item" :class="{ 'son-active': tabIndex === 0 }" @click="tabClick(0)">用药登记</span>
      <span class="son-item" :class="{ 'son-active': tabIndex === 1 }" @click="tabClick(1)">用药计划</span>
    </div>
    <div class="run-search">
      <div class="run-item">
        <div class="key">
          <span>老人</span>
        </div>
        <el-input placeholder="请输入"></el-input>
      </div>
      <div class="run-item" v-if="tabIndex === 0">
        <div class="key">
          <span>登记时间</span>
        </div>
        <el-date-picker type="date" placeholder="请选择">
        </el-date-picker>
      </div>
      <el-button type="primary">搜索</el-button>
      <el-button type="primary">重置</el-button>
    </div>

    <div class="table">
      <el-table :data="tableData" height="100%" border v-show="tabIndex === 0">
      <el-table-column label="序号" type="index" width="100">
      </el-table-column>
      <el-table-column prop="name" label="老人姓名">
      </el-table-column>
      <el-table-column prop="address" label="用药品种数">
      </el-table-column>
      <el-table-column prop="address" label="登记人">
      </el-table-column>
      <el-table-column prop="address" label="最近登记时间">
      </el-table-column>
      <el-table-column label="操作" width="140">
        <template>
          <!-- <div class="table-run">
            <span @click="openRegistration">用药登记</span>
          </div> -->
          <el-button type="primary" size="mini" @click="openRegistration">用药登记</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-table :data="tableData" height="100%" border v-show="tabIndex === 1">
      <el-table-column label="序号" type="index" width="100">
      </el-table-column>
      <el-table-column prop="name" label="老人姓名">
      </el-table-column>
      <el-table-column prop="address" label="用药品种数">
      </el-table-column>
      <el-table-column label="操作" width="140">
        <template>
          <!-- <div class="table-run">
            <span @click="openSet">用药计划设置</span>
          </div> -->
          <el-button type="primary" size="mini" @click="openSet">用药计划设置</el-button>
        </template>
      </el-table-column>
    </el-table>
    </div>

    <el-pagination @size-change="handleSizeChange" background @current-change="handleCurrentChange" :current-page="currentPage4"
      :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper" :total="400">
    </el-pagination>
    <!-- 用药登记 -->
    <Registration v-if="showRegistration" @close="closeRegistration"></Registration>
    <!-- 用药计划设置 -->
    <Set v-if="showSet" @close="closeSet"></Set>
  </div>
    </div>
  </div>

</template>

<script>
import Registration from './registration'
import Set from './set.vue'
export default {
  components: {
    Registration,
    Set
  },
  data () {
    return {
      tableData: [{
        date: '2016-05-02',
        name: '王小虎',
        address: '等待外出'
      }, {
        date: '2016-05-04',
        name: '王小虎',
        address: '未返回'
      }, {
        date: '2016-05-01',
        name: '王小虎',
        address: '逾期未返回'
      }],
      options: [{
        value: '',
        label: ''
      }],
      value: '',
      currentPage4: 4,
      tabIndex: 0,
      selectIndex: 0,
      showAdd: false,
      showRegistration: false,
      showSet: false
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    },
    openAdd () {
      this.showAdd = true
    },
    closeAdd () {
      this.showAdd = false
    },
    openRegistration () {
      this.showRegistration = true
    },
    closeRegistration () {
      this.showRegistration = false
    },
    openSet () {
      this.showSet = true
    },
    closeSet () {
      this.showSet = false
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.page-box {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0 10px;
  box-sizing: border-box;
  background-color: #fff;

  .input {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 246px;
  }

  .tab {
    display: flex;
    align-items: center;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    .item {
      width: 130px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      margin-left: 30px;
      background-color: #284867;
      color: #fff;
      font-size: 22px;
      cursor: pointer;

      &:first-child {
        margin-left: 0px;
      }
    }

    .active {
      background-color: #409EFF;
    }
  }

  .tab-box {
    width: 100%;
    height: calc(100% - 78px);
  }

}
.run {
  width: 100%;
  height: 100%;

  .son-tab {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    .son-item {
      height: 38px;
      line-height: 38px;
      margin-right: 40px;
      padding: 0 20px;
      text-align: center;
      background-color: #284867;
      color: #fff;
      font-size: 16px;
      cursor: pointer;
    }

    .son-active {
      background-color: #409EFF;
    }
  }

  .son-tab1 {
    border: none;
  }

  .son-add {
    display: flex;
    align-items: center;
    height: 50px;

    .son-add-icon {
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      background-color: #409EFF;
      border-radius: 50%;
      color: #fff;
      font-size: 16px;
      cursor: pointer;
    }
  }

  .run-search {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    .run-item {
      display: flex;
      align-items: center;
      margin-right: 30px;

      .key {
        margin-right: 10px;
        white-space: nowrap;
        color: #000;
        font-size: 22px;
      }
    }
  }

  .table {
    width: 100%;
    height: calc(100% - 260px);

    /deep/.el-table .cell {
      text-align: center;
      color: #000;
    }

    .table-run {
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        cursor: pointer;
      }
    }
  }

  .el-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
}
</style>
